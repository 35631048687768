import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import { NakedButton } from '@symplete/symplete-ui';

import Typography from 'ui/Typography';
import Icon from 'ui/Icon';

import { truncate } from 'shared/utility';

import {
  Root,
  RootButton,
  DeleteButtonRoot,
} from './styledItems';

const colors = {
  focus: 'main-text-inversed',
  subtle: 'main-text',
  disabled: 'main-text-inversed',
};

const Chip = (props) => {
  const {
    onClick,
    onDelete,
    children,
    variant,
    className,
    ellipsis,
    style,
  } = props;

  if (onClick && !onDelete) {
    return (
      <RootButton
        variant={variant}
        onClick={onClick}
        style={style}
      >
        <Typography
          variant="chip"
          color={colors[variant]}
          style={{
            textTransform: 'capitalize',
            cursor: 'inherit',
          }}
          ellipsis
        >
          {
            typeof children === 'string' && ellipsis
            ? (
                truncate(children, { length: 26 })
              )
            : (
                children
              )
          }
        </Typography>
      </RootButton>
    );
  }

  return (
    <Root
      variant={variant}
      hasDeleteButton={onDelete}
      className={classnames({
        [className]: className,
      })}
      style={style}
    >
      <NakedButton onClick={onClick}>
        <Typography
          variant="chip"
          color={colors[variant]}
          style={{
            textTransform: 'capitalize',
            cursor: 'inherit',
          }}
          ellipsis
        >
          {
            typeof children === 'string' && ellipsis
            ? (
                truncate(children, { length: 26 })
              )
            : (
                children
              )
          }
        </Typography>
      </NakedButton>
      {
        onDelete && (
          <DeleteButtonRoot onClick={onDelete}>
            <Icon
              name="x"
              size="m"
              color={colors[variant]}
            />
          </DeleteButtonRoot>
        )
      }
    </Root>
  );
};

Chip.defaultProps = {
  variant: 'focus',
  onClick: null,
  onDelete: null,
  className: null,
  ellipsis: true,
  style: {},
};

const {
  func,
  oneOf,
  string,
  bool,
  shape,
} = PropTypes;

Chip.propTypes = {
  onClick: func,
  onDelete: func,
  className: string,
  variant: oneOf(['focus', 'subtle', 'disabled']),
  children: string.isRequired,
  ellipsis: bool,
  style: shape({}),
};

export default Chip;
