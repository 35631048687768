import React from 'react';
import PropTypes from 'prop-types';

import {
  Root,
  StyledTypography,
} from './styledItems';

const ColorfulLabel = (props) => {
  const {
    title,
    variant,
  } = props;

  return (
    <Root
      color={variant}
    >
      <StyledTypography
        variant="title3"
        tag="span"
        weight={500}
        noMargin
        style={{ textTransform: 'uppercase' }}
        color={variant}
      >
        {title}
      </StyledTypography>
    </Root>
  );
};

ColorfulLabel.defaultProps = {
  variant: 'focus',
};

const {
  string,
} = PropTypes;

ColorfulLabel.propTypes = {
  variant: string,
  title: string.isRequired,
};

export default ColorfulLabel;
