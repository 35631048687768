import React, {
  useCallback,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import {
  Steps,
} from 'antd';

import Modal from 'ui/Modal';
import Button from 'ui/Button';
import Typography from 'ui/Typography';

import RoadmapList from './RoadmapList';
import OverviewVideo from './OverviewVideo';
import OnboardingStep from './OnboardingStep';
import RealtorProfile from './RealtorProfile';
import ChangePasswordForm from './Forms/ChangePasswordForm';
import ContactInformationForm from './Forms/ContactInformationForm';
// import SocialInformationForm from './Forms/SocialInformationForm';
// import SocialIntegration from './SocialIntegration';
import Welcome from './Welcome';

import {
  EmptyElement,
  // InformationWrapper,
} from './styledItems';

const { Step } = Steps;

const noop = () => {};

const OnboardingWizard = (props) => {
  const {
    closeModal,
    changePassword,
    isVisible,
    firstLogin,
    firstName,
    lastName,
    email,
    phone,
    companyName,
    realtorId,
    websiteUrl,
    linkedinUrl,
    twitterUrl,
    changeCompanyName,
    changeUserInformation,
    isAccountCreator,
    getContactIdByEmail,
    refreshUserData,
    updateAvatar,
    // getRealtorDirectoryProfile,
    foundInRealtorDirectory,
  } = props;

  let maxStepsCount = firstLogin ? 7 : 5;

  if (!foundInRealtorDirectory) {
    maxStepsCount -= 1;
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm({
    mode: 'onSubmit',
  });

  const [formsData, setFormsData] = useState({
    passwordForm: {
      password: null,
      confirmPassword: null,
    },
    contactForm: {
      avatar: null,
      firstName,
      email,
      companyName,
      lastName,
      phone,
      realtorId,
    },
    socialForm: {
      websiteUrl,
      linkedinUrl,
      twitterUrl,
    },
  });
  const [currentOnboardingStep, setCurrentOnboardingStep] = useState(1);

  // useEffect(() => {
  //   getRealtorDirectoryProfile();
  // }, []);

  const changeCurrentOnboardingStep = useCallback((type) => (data) => {
    let newStep;

    if (data) {
      setFormsData({
        ...formsData,
        [data.formName]: data,
      });
    }

    if (type === 'next') {
      newStep = currentOnboardingStep + 1;
    } else if (type === 'back') {
      newStep = currentOnboardingStep - 1;
    }

    setCurrentOnboardingStep(newStep);

    localStorage.setItem(
      'currentOnboardingStep',
      JSON.stringify(newStep),
    );
  }, [
    currentOnboardingStep,
    JSON.stringify(formsData),
  ]);

  const restoreCurrentOnboardingStep = useCallback(() => {
    if (firstLogin) {
      setCurrentOnboardingStep(1);

      localStorage.setItem(
        'currentOnboardingStep',
        JSON.stringify(1),
      );
    } else {
      const step = localStorage.getItem('currentOnboardingStep');

      if (!step) {
        setCurrentOnboardingStep(1);

        localStorage.setItem(
          'currentOnboardingStep',
          JSON.stringify(1),
        );

        return;
      }

      setCurrentOnboardingStep(Number(step) || 1);
    }
  }, []);

  const renderItems = useCallback((list) => {
    let steps = [];

    if (firstLogin) {
      steps = [
        {
          content: () => (
            <OnboardingStep
              iconName="greetingsStep"
              title={(
                <span>
                  {`Hi ${firstName}!`}
                  <br />
                  Thanks for signing up and welcome to Symplete
                </span>
              )}
              description="We can’t wait to show you around. We can help to import your contacts and properties
                from other systems and can arrange a time to give you a quick demo of the system
                and main features."
            >
              <Welcome />
            </OnboardingStep>
          ),
        },
        {
          content: () => (
            <OnboardingStep
              iconName="passwordStep"
              title="Set password"
              description="First things first, please create a secure password for your new account."
            >
              <ChangePasswordForm
                control={control}
                getValues={getValues}
                errors={{ ...errors }}
                formsData={formsData?.passwordForm}
              />
            </OnboardingStep>
          ),
        },
      ];
    }

    steps = [
      ...steps,
      {
        content: () => (
          <OnboardingStep
            iconName="informationStep"
            title="Just a quick check."
            description="Please confirm your details below are correct, upload a profile photo and click next!"
          >
            <ContactInformationForm
              control={control}
              errors={errors}
              formsData={formsData?.contactForm}
              defaultEmail={email}
            />
          </OnboardingStep>
        ),
      },
      // {
      //   content: () => (
      //     <OnboardingStep
      //       iconName="overviewStep"
      //       title="Import contacts and connect social media"
      //       description="You can import your contact directly from your phone and add your social pages"
      //     >
      //       <SocialIntegration />
      //       <SocialInformationForm
      //         control={control}
      //         errors={errors}
      //         formsData={formsData?.socialForm}
      //       />
      //     </OnboardingStep>
      //   ),
      // },
      {
        key: 'directory',
        content: () => (
          <OnboardingStep
            iconName="searchStep"
            title="Cool! We found your profile in our realtor directory."
            description="We are building a national database of realtors
              that we will be marketing to buyers and sellers.
              Claim your profile so that we can send you leads, at no extra cost to you."
          >
            <RealtorProfile
              src={formsData?.contactForm?.avatar?.src}
            />
          </OnboardingStep>
        ),
      },
      {
        content: () => (
          <OnboardingStep
            iconName="overviewStep"
            title="We’ve prepared a quick overview."
            description="You can watch it below (2min), or find this video in your settings menu later."
          >
            <OverviewVideo />
          </OnboardingStep>
        ),
      },
      {
        content: () => (
          <OnboardingStep
            iconName="informationStep"
            title="Look what’s coming soon!"
            description={(
              <Typography
                variant="title2"
                tag="span"
                weight="normal"
                style={{
                  width: '510px',
                }}
                align="center"
                noMargin
              >
                {`If there is a feature or product that will
                drastically improve your life as a realtor, `}
                <a href="mailto:featurerequest@symplete.com">
                  tell us
                </a>
                {' so we can add it to the roadmap!'}
              </Typography>
            )}
          >
            <RoadmapList />
          </OnboardingStep>
        ),
      },
      // {
      //   content: () => (
      //     <OnboardingStep
      //       iconName="noteStep"
      //       title="One quick note about Early Access."
      //       description="We have done our best to make sure the system is as bug free and stable as possible.
      //         However, if you have any feedback at all, we would love your help by reporting it."
      //     >
      //       <InformationWrapper>
      //         <Typography
      //           variant="title2"
      //           align="center"
      //           weight="normal"
      //           noMargin
      //         >
      //           Bugs, feedback or questions can be submitted
      //           using the Early Access Premium Support feature top
      //           right of the page.
      //         </Typography>
      //       </InformationWrapper>
      //     </OnboardingStep>
      //   ),
      // },
      {
        content: () => (
          <OnboardingStep
            iconName="finalStep"
            title="You’re all done! Unless…"
            description="You can click Finish or feel free to reach out for help. Here’s to your success! "
          >
            <a href="mailto:support@symplete.com">
              <Button
                variant="secondary"
                icon="mail"
                onClick={noop}
              >
                Contact Support
              </Button>
            </a>
          </OnboardingStep>
        ),
      },
    ];

    if (!foundInRealtorDirectory) {
      steps = steps.filter((step) => step.key !== 'directory');
    }

    if (list) {
      return steps;
    }

    return steps[currentOnboardingStep - 1];
  }, [
    currentOnboardingStep,
    firstName,
    errors,
    maxStepsCount,
    firstLogin,
    foundInRealtorDirectory,
  ]);

  const renderFooterSteps = useCallback(() => (
    <Steps
      progressDot
      current={currentOnboardingStep - 1}
      onChange={changeCurrentOnboardingStep('next')}
    >
      {renderItems(true).map((item) => (
        <Step key={item.title} disabled />
      ))}
    </Steps>
  ), [currentOnboardingStep, changeCurrentOnboardingStep, renderItems]);

  useEffect(() => {
    restoreCurrentOnboardingStep();
  }, []);

  useEffect(() => {
    if (!isAccountCreator) {
      closeModal();
    }
  }, [isAccountCreator]);

  const confirmFieldsAction = useCallback(async (data) => {
    const passwordForm = data.formName === 'passwordForm' ? data : formsData?.passwordForm;
    const contactForm = data.formName === 'contactForm' ? data : formsData?.contactForm;
    const socialForm = data.formName === 'socialForm' ? data : formsData?.socialForm;

    if (
      firstLogin
      && passwordForm?.password) {
      await changePassword(passwordForm?.password, true);
    }

    const contactId = await getContactIdByEmail(email);

    if(contactId) {
      await changeUserInformation(contactId)({
        ...contactForm,
        ...socialForm,
      });
    }

    if (contactForm.avatar) {
      await updateAvatar(contactId)(contactForm.avatar.file);
    }

    await changeCompanyName({
      CompanyName: contactForm.companyName,
    });

    await refreshUserData(contactId);

    closeModal();
  }, [
    JSON.stringify(formsData),
    email,
    changePassword,
    getContactIdByEmail,
    changeUserInformation,
    updateAvatar,
    changeCompanyName,
    refreshUserData,
    closeModal,
  ]);

  return (
    <Modal
      width={735}
      onCancel={handleSubmit(confirmFieldsAction)}
      visible={isVisible}
      closable={!(firstLogin && (currentOnboardingStep === 1 || currentOnboardingStep === 2))}
      footer={[
        currentOnboardingStep !== 1 ? (
          <Button
            variant="secondary"
            onClick={handleSubmit(changeCurrentOnboardingStep('back'))}
          >
            Back
          </Button>
        ) : <EmptyElement />,
        renderFooterSteps(),
        currentOnboardingStep !== maxStepsCount ? (
          <Button
            variant="primary"
            onClick={handleSubmit(changeCurrentOnboardingStep('next'))}

          >
            Next
          </Button>
        ) : (
          <Button
            variant="primary"
            onClick={handleSubmit(confirmFieldsAction)}

          >
            Finish
          </Button>
        ),
      ]}
      justifyFooterContent="space-between"
      alignFooterItems="center"
      className="onboarding-wizard"
    >
      {renderItems()?.content()}
    </Modal>
  );
};

const {
  func,
  bool,
  string,
} = PropTypes;

OnboardingWizard.propTypes = {
  closeModal: func.isRequired,
  changePassword: func.isRequired,
  isVisible: bool.isRequired,
  firstLogin: string.isRequired,
  firstName: string.isRequired,
  lastName: string.isRequired,
  email: string.isRequired,
  phone: string.isRequired,
  companyName: string.isRequired,
  realtorId: string.isRequired,
  websiteUrl: string.isRequired,
  linkedinUrl: string.isRequired,
  twitterUrl: string.isRequired,
  changeCompanyName: func.isRequired,
  changeUserInformation: func.isRequired,
  isAccountCreator: bool.isRequired,
  getContactIdByEmail: func.isRequired,
  refreshUserData: func.isRequired,
  updateAvatar: func.isRequired,
  // getRealtorDirectoryProfile: func.isRequired,
  foundInRealtorDirectory: bool.isRequired,
};

export default OnboardingWizard;
