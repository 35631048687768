import React from 'react';
import PropTypes from 'prop-types';

import AttachmentsExpandable from 'shared/AttachmentsExpandable';

const ListingAgreement = (props) => {
  const {
    propertyId,
    isMyListing,
  } = props;

  if (!isMyListing) {
    return null;
  }

  return (
    <AttachmentsExpandable 
      objectId={propertyId}
      objectType={27}
      attachmentType={19}
      modalTitle="Listing Agreement"
      buttonLabelEmpty="Upload listing agreement"
      buttonLabelComplete="Listing agreement"
    />  
  )

};

ListingAgreement.defaultProps = {
  isMyListing: false,
};

const {
  bool,
  number,
} = PropTypes;

ListingAgreement.propTypes = {
  propertyId: number.isRequired,
  isMyListing: bool,
};

export default ListingAgreement;
