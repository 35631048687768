import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import { NakedButton } from '@symplete/symplete-ui';

import Tooltip from 'ui/Tooltip';

import PropertyMainImage from 'sections/Properties/modules/PropertyMainImage';

import useProperty from 'sections/Properties/hooks/useProperty';
import useAddress from 'sections/Addresses/hooks/useAddress';

const RelatedProperty = (props) => {
  const {
    propertyId,
  } = props;

  const history = useHistory();

  const onClick = useCallback((event) => {
    event.stopPropagation();
    history.push(`/mls/${propertyId}`);
  }, [
    propertyId,
    history,
  ]);

  const property = useProperty(propertyId);

  const address = useAddress(property?.address_id);

  return (
    <NakedButton
      onClick={onClick}
      style={{ width: '100%' }}
    >
      <Tooltip title={address}>
        <PropertyMainImage
          propertyId={propertyId}
          height="60px"
        />
      </Tooltip>
    </NakedButton>
  );
};

RelatedProperty.defaultProps = {

};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

RelatedProperty.propTypes = {

};

export default RelatedProperty;
