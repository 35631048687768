import React from 'react';

import TableHead from 'ui/TableHead';

import Cells from './Cells';

const columns = () => [
    {
      key: 'type',
      dataIndex: 'type',
      title: () => (
        <TableHead>
          Type
        </TableHead>
      ),
      width: 5,
      align: 'center',
      render: Cells.Type,
    },
    {
      key: 'sent_at',
      dataIndex: 'sent_at',
      title: () => (
        <TableHead>
          Send date
        </TableHead>
      ),
      width: 2,
      render: Cells.SendDate,
    },
    {
      key: 'reach',
      dataIndex: 'reach',
      title: () => (
        <TableHead>
          Reach
        </TableHead>
      ),
      width: 2,
      render: Cells.Reach,
    },
    {
      key: 'metrics',
      dataIndex: 'metrics',
      title: () => (
        <TableHead>
          Metrics
        </TableHead>
      ),
      width: 6.5,
      render: (value) => <Cells.Metrics value={value} />,
    },
    {
      key: 'nextStage',
      dataIndex: 'nextStage',
      width: 2.5,
      title: () => (
        <TableHead>
          Next stage
        </TableHead>
      ),
      render: Cells.NextStage,
    },
    {
      key: 'status',
      dataIndex: 'status',
      width: 2.5,
      title: () => (
        <TableHead>
          Status
        </TableHead>
      ),
      render: Cells.Status,
    },
    {
      key: 'option',
      dataIndex: 'option',
      width: 1.0,
      title: () => (
        <TableHead>
          &nbsp;
        </TableHead>
      ),
      render: Cells.Option,
    },
  ];

export default columns;
