import React, {
  useState,
  useCallback,
} from 'react';

import {
  debounce,
} from 'shared/utility';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Textfield from 'ui/Textfield';

import MailboxSelector from 'sections/Integrations/modules/MailboxSelector';
import EmailSelector from 'sections/Integrations/modules/EmailSelector';
import EmailsTable from 'sections/Integrations/modules/EmailsTable';

import MailboxSelectorRoot from 'sections/Communications/components/MailboxSelectorRoot';
import EmailSelectorRoot from 'sections/Communications/components/EmailSelectorRoot';
import EmailTabs from 'sections/Communications/components/EmailTabs';

const Emails = (props) => {
  const [mailbox, setMailbox] = useState(null);
  const [tokenId, setTokenId] = useState(null);
  const [search, setSearch] = useState(null);

  const [tab, setTab] = useState('all');

  const handleSetMailbox = useCallback((value) => {
    setMailbox(value);
    setTab('all');
  }, [
    setMailbox,
    setTab,
  ]);

  return (
    <Flex
      flexDirection="column"
      spacing={4}
    >
      <MailboxSelector
        onChange={handleSetMailbox}
        Root={MailboxSelectorRoot}
      />
      <Flex
        spacing={2}
        alignItems="baseline"
        justifyContent="space-between"
        fullWidth
      >
        <EmailTabs
          tab={tab}
          onChange={setTab}
          mailbox={mailbox}
        />
        <Flex
          spacing={2}
          alignItems="baseline"
        >
          <EmailSelector
            Root={EmailSelectorRoot}
            onChange={setTokenId}
          />
          <Textfield
            icon="search"
            flat
            onChange={(debounce(setSearch, 1000))}
          />
        </Flex>
      </Flex>
      <FlexItem fullWidth>
        <EmailsTable
          tokenId={tokenId}
          mailbox={mailbox}
          search={search}
          status={tab}
        />
      </FlexItem>
    </Flex>
  );
};

export default Emails;
