import { connect } from 'react-redux';

import {
  updateProperty,
  overwritePropertyRelations,
} from 'store/actions/properties';
import PrimaryDetails from './PrimaryDetails';

const mapStateToProps = (state, ownProps) => ({

});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (key, value) => {
    dispatch(updateProperty(ownProps.itemId)({ [key]: value }));
  },
  onRelationOverwrite: (relationType) => (value) => {
    dispatch(overwritePropertyRelations(relationType)(ownProps.itemId)(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryDetails);
