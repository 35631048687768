import { connect } from 'react-redux';

import selectors from 'store/selectors';

import ContactSelect from './ContactSelect';

const mapStateToProps = (state, ownProps) => {
  const contactName = selectors.realtorContacts.contactName(state, ownProps.contactId);

  return {
    displayValue: contactName || null,
  };
};

export default connect(mapStateToProps)(ContactSelect);
