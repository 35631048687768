import styled from 'styled-components';

import Typography from 'ui/Typography';

export const Box = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 7px;
  margin: 10px 0 15px;
  border-radius: 4px;
  border: solid 1px #2ecc71;
  background-color: rgba(46, 204, 113, 0.11);
`;

export const Description = styled(Typography)`
  line-height: 14px;
  margin-bottom: -2px;
`;
