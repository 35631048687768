import styled from 'styled-components';

export const EditableTextContainer = styled.div`
  .displayed-value {
    width: 350px;
    border-radius: 6px;
    transition: all .2s;
    display: ${({ showEditor }) => (showEditor ? 'none' : 'inline-flex')};
    .reminder-item__item-name-number {
      margin-right: 5px;
    }

    &:hover {
      background-color: #e0e7eb;
      cursor: pointer;
    }
  }

  .text-editor {
    display: ${({ showEditor }) => (showEditor ? 'block' : 'none')};
    width: fit-content;
    max-width: 350px;
    .article-edit {
      min-height: 30px;
    }
  }
`;

export default { EditableTextContainer };
