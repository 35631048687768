import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Tabs } from 'antd';
import { get } from 'shared/utility';

import classnames from 'classnames';
import ButtonLink from 'shared/components/ButtonLink';

import {
  securityCodeEnum,
  securityQuestionEnum,
} from 'shared/modals/GeneralEmployee/2FA/shared';
import Typography from 'ui/Typography';

import LoginForm from './LoginForm';
import Accounts from './Accounts';
import './LoginWorkflow.scss';

const titles = [
  'Sign In',
  'Select Account',
];

const LoginWorkflow = (props) => {
  const {
    activeTab,
    error,
    hideWorkspaces,
    history,
    isFetching,
    loginRequest,
    selectWorkspace,
    token,
    triggerRecoverModal,
    twoFactor,
    workspaces,
    externalLogin,
    externalPassword,
    getCompanyLogo,
  } = props;

  const handleSelect = useCallback((workspace) => async () => {
    try {
      const workspaceToken = await selectWorkspace(workspace);

      if (workspaceToken) {
        history.push('/');
      }
    } catch (err) {
      console.warn(err);
    }
  }, [
    history,
    selectWorkspace,
  ]);

  const twoFactorType = get(twoFactor, 'type');

  let title = titles[activeTab - 1];

  if (twoFactorType === securityQuestionEnum) {
    title = 'Please, confirm the below';
  }

  if (twoFactorType === securityCodeEnum) {
    title = 'Enter the 6 digit code from the email you received';
  }

  const renderTabBar = useCallback(() => <div />, []);

  return (
    <div className="login-workflow">
      <Typography
        className="login-workflow__title"
        tag="h2"
        variant="h2"
      >
        {title}
      </Typography>

      <div className="login-workflow__logo">
        <img src="/assets/img/symplete-dark.png" alt="symplete logotype" />
      </div>

      <Tabs
        className="login-workflow__tabs"
        renderTabBar={renderTabBar}
        activeKey={activeTab.toString()}
      >
        <Tabs.TabPane key={1}>
          <LoginForm
            data-test="login-form"
            onSubmit={loginRequest}
            onForgot={triggerRecoverModal}
            isLoading={isFetching}
            error={error}
            twoFactor={twoFactor}
            externalLogin={externalLogin}
            externalPassword={externalPassword}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key={2}>
          <Accounts
            getCompanyLogo={getCompanyLogo}
            workspaces={workspaces}
            onSelect={handleSelect}
            token={token}
          />
        </Tabs.TabPane>
      </Tabs>
      <div>
        <ButtonLink
          className={classnames(
            'login-workflow__back',
            {
              'login-workflow__back--hidden': activeTab === 1,
            },
          )}
          onClick={hideWorkspaces}
        >
          <Typography color="additional">
            Back
          </Typography>
        </ButtonLink>
      </div>
    </div>
  );
};

LoginWorkflow.defaultProps = {
  error: [],
  externalLogin: null,
  externalPassword: null,
  hideWorkspaces: false,
  isFetching: false,
  token: null,
  twoFactor: null,
  workspaces: [],
};

const {
  array,
  bool,
  func,
  number,
  object,
  string,
} = PropTypes;

// TODO - improve proptypes
LoginWorkflow.propTypes = {
  getCompanyLogo: func.isRequired,
  activeTab: number.isRequired,
  error: array, // eslint-disable-line react/forbid-prop-types
  externalLogin: string,
  externalPassword: string,
  hideWorkspaces: bool,
  history: object.isRequired, // eslint-disable-line react/forbid-prop-types
  isFetching: bool,
  loginRequest: func.isRequired,
  selectWorkspace: func.isRequired,
  token: string,
  triggerRecoverModal: func.isRequired,
  twoFactor: object, // eslint-disable-line react/forbid-prop-types
  workspaces: array, // eslint-disable-line react/forbid-prop-types
};

export default LoginWorkflow;
