import { connect } from 'react-redux';

import selectors from 'store/selectors';

import ItemKPIBar from 'ui/KPIBar';

const mapStateToProps = (state, ownProps) => {
  const {
    propertyId,
  } = ownProps;

  const summary = selectors.properties.itemSummary(state, propertyId);

  return {
    values: {
      active_matches: summary?.active_matches || 0,
      total_viewings: summary?.total_viewings || 0,
      upcoming_viewings: summary?.upcoming_viewings || 0,
      active_offers: summary?.active_offers || 0,
    },
    indicators: [
      {
        key: 'active_matches',
        title: 'Active Matches',
        iconName: 'users',
      },
      {
        key: 'total_viewings',
        title: 'Total Showings',
        iconName: 'eye',
      },
      {
        key: 'upcoming_viewings',
        title: 'Upcoming Showings',
        iconName: 'eye',
      },
      {
        key: 'active_offers',
        title: 'Active Offers',
        iconName: 'activity',
      },
    ],
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ItemKPIBar);
