import React, {
  useMemo,
} from 'react';

import Typography from 'ui/Typography';

const getScoreColor = (score) => {
  if (score < 7) return 'unlikely';
  if (score < 8) return 'likely';
  return 'veryLikely';
};

const SymScore = (props) => {
  const {
    score,
  } = props;

  const color = useMemo(() => getScoreColor(+score), [score]);

  return (
    <Typography color={color}>
      {score}
    </Typography>
  );
};

export default SymScore;
