import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'antd';

import Typography from 'ui/Typography';

import useContactName from 'shared/hooks/contacts/useContactName';

const PropertyListingAgentCell = (props) => {
  const {
    listingAgent,
  } = props;

  const name = useContactName(listingAgent);

  if (!name) return <Typography variant="cell">—</Typography>;

  return (
    <Tooltip title={name}>
      <Typography
        ellispis
        style={{ maxWidth: 150 }}
        variant="cell"
      >
        {name}
      </Typography>
    </Tooltip>
  );
};

PropertyListingAgentCell.defaultProps = {
  listingAgent: null,
};

const {
  number,
} = PropTypes;

PropertyListingAgentCell.propTypes = {
  listingAgent: number,
};

export default PropertyListingAgentCell;
