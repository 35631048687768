import React from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import KPIItem from 'ui/KPIItem';
import Tooltip from 'ui/Tooltip';

import { ItemWrapper } from './styledItems';

const metrics = [
  {
    key: 'showingsToBeArranged',
    iconName: 'user-plus',
    tooltip: 'Showings to be arranged',
  },
  {
    key: 'showingsBooked',
    iconName: 'calendar',
    tooltip: 'Showings booked',
  },
  {
    key: 'showingsCompleted',
    iconName: 'check-circle',
    tooltip: 'Completed showings',
  },
  {
    key: 'offers',
    iconName: 'dollar-sign',
    tooltip: 'Offers',
  },
];

const Metrics = (props) => {
  const {
    data,
  } = props;

  const items = metrics.reduce((acc, item) => {
    acc.push({
      ...item,
      value: data[item.key] ?? 0,
    });

    return acc;
  }, []);

  return (
    <Flex spacing={1}>
      {
        items.map((item) => (
          <Tooltip
            title={item.tooltip}
            key={item.key}
          >
            <ItemWrapper>
              <KPIItem
                title={item.value === 'new' ? null : item.value}
                iconName={item.value === 'new' ? 'star' : item.iconName}
                iconType={item.iconType}
                iconColor={item.value === 'new' ? 'new' : 'focus'}
              />
            </ItemWrapper>
          </Tooltip>
        ))
      }
    </Flex>
  );
};

Metrics.defaultProps = {
  data: {},
};

const {
  shape,
} = PropTypes;

Metrics.propTypes = {
  data: shape(),
};

export default Metrics;
