import {
  SET_REALTOR_MATCHING_PROPERTY_REJECTION_MODAL_OPEN,
} from 'store/actionTypes/realtorMatchingProperties';

const openRejectionModal = (id, onSave) => ({
  type: SET_REALTOR_MATCHING_PROPERTY_REJECTION_MODAL_OPEN,
  payload: {
    matchingPropertyId: id,
    onSave,
  },
});

export default openRejectionModal;
