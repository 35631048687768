import {
  useState,
  useCallback,
} from 'react';

import requests from 'api/requests';

const usePropertiesByZip = () => {
  const [properties, setProperties] = useState([]);
  const [count, setCount] = useState(0);
  const [error, setError] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const getProperties = useCallback(async (zip) => {
    setIsLoading(true);
    setError(null);

    try {
      const result = await requests.globalProperties.byZip(zip);

      setProperties(result?.data);
      setCount(result?.data?.length);
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  });

  return {
    properties,
    isLoading,
    count,
    error,
    getProperties,
  };
};

export default usePropertiesByZip;
