import { connect } from 'react-redux';

import selectors from 'store/selectors';

import PropertyLocationCell from './PropertyLocationCell';

const mapStateToProps = (state, ownProps) => {
  const address = selectors.addresses.byId(state, ownProps.addressId);

  return {
    address: address?.address,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyLocationCell);
