import { connect } from 'react-redux'

import List from './List'

const mapStateToProps = (state) => {
  const {

  } = state

  return {

  }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(List)
