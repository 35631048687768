import React, {
  useState,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';
import Button from 'ui/Button';

import Options from 'sections/Leads/modules/OptionsV2';
import LeadDetailsSelector from 'sections/Leads/components/LeadDetailsSelector';
import DaysUntilClose from 'sections/Leads/modules/DaysUntilClose';

import BuyerRequirements from 'sections/Leads/modules/BuyerRequirements';
import SellerDetails from 'sections/Leads/modules/SellerDetails';

const detailsComponents = {
  property: SellerDetails,
  propertyRequirement: BuyerRequirements,
};

const selectorWrapperStyle = {
  width: 240,
  overflow: 'hidden',
};

const LeadDetails = (props) => {
  const {
    leadId,
    contactId,
    options,

    onConvert,
  } = props;

  const [selectedItem, setSelectedItem] = useState(options?.[0] || null);

  useEffect(() => {
    if (!selectedItem && (options.length > 0)) {
      setSelectedItem(options?.[0]);
    }
  }, [
    selectedItem,
    setSelectedItem,
    options,
  ]);

  const DetailsComponent = selectedItem
    ? detailsComponents[selectedItem?.type]
    : () => null;

  return (
    <Flex
      fullWidth
      spacing={2}
      flexDirection="column"
    >
      <Flex
        fullWidth
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          variant="title1"
          noMargin
        >
          {
            selectedItem?.type === 'property'
              ? 'Seller Details'
              : 'Buyer Requirements'
          }
        </Typography>

        <Flex
          spacing={1}
          alignItems="center"
        >
          <DaysUntilClose
            item={selectedItem}
          />
          {
            options?.length > 1 && (
              <div style={selectorWrapperStyle}>
                <LeadDetailsSelector
                  options={options}
                  selectedItem={selectedItem}
                  onSelect={setSelectedItem}
                />
              </div>
            )
          }
          <Button
            type="primary"
            onClick={onConvert(selectedItem)}
          >
            Make Client
          </Button>
          <Options
            leadId={leadId}
            selectedItem={selectedItem}
          />
        </Flex>
      </Flex>

      <DetailsComponent
        itemId={selectedItem?.itemId}
        contactId={contactId}
      />
    </Flex>
  );
};

LeadDetails.defaultProps = {
  options: [],
};

const {
  arrayOf,
  func,
  number,
  oneOf,
  shape,
} = PropTypes;

LeadDetails.propTypes = {
  leadId: number.isRequired,
  contactId: number.isRequired,
  options: arrayOf(shape({
    itemId: number,
    type: oneOf(['property', 'propertyRequirement']),
  })),
  onConvert: func.isRequired,
};

export default LeadDetails;
