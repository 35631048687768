import React from 'react';
import PropTypes from 'prop-types';

import withPropertyData from 'sections/Properties/hocs/withPropertyData';

import LocationCell from 'sections/Leads/modules/LeadsTable/LocationCell';

const Location = (props) => {
  const {
    property,
  } = props;

  return (
    <LocationCell addressId={property.address_id} />
  );
};

Location.defaultProps = {
  property: {},
};

const {
  number,
  shape,
} = PropTypes;

Location.propTypes = {
  property: shape({
    address_id: number,
  }),
};

export default withPropertyData(Location);
