import React from 'react';
import { shape } from 'prop-types';

import { useController } from 'react-hook-form';

import Textfield from 'ui/Textfield';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

const NeighborDetails = (props) => {
  const { control } = props;

  const {
    field: { onChange: onChangeNeighborhoods, value: neighborhoods },
  } = useController({
    name: 'neighborhoods',
    control,
  });

  const {
    field: { onChange: onChangeBeds, value: beds },
  } = useController({
    name: 'beds',
    control,
  });

  const {
    field: { onChange: onChangeBaths, value: baths },
  } = useController({
    name: 'baths',
    control,
  });

  const {
    field: { onChange: onChangeSize, value: size },
  } = useController({
    name: 'size',
    control,
  });

  return (
    <Flex spacing={2} fullWidth>
      <FlexItem xs={12}>
        <Textfield
          placeholder="Preferred neighborhoods"
          onChange={onChangeNeighborhoods}
          value={neighborhoods}
        />
      </FlexItem>
      <FlexItem xs={3}>
        <Textfield
          placeholder="Beds"
          onChange={onChangeBeds}
          value={beds}
        />
      </FlexItem>
      <FlexItem xs={3}>
        <Textfield
          placeholder="Baths"
          onChange={onChangeBaths}
          value={baths}
        />
      </FlexItem>
      <FlexItem xs={3}>
        <Textfield
          placeholder="Size"
          onChange={onChangeSize}
          value={size}
        />
      </FlexItem>
    </Flex>
  );
};

NeighborDetails.propTypes = {
  control: shape({}).isRequired,
};

export default NeighborDetails;
