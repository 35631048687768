import { connect } from 'react-redux';

import {
  updateMatchingProperty,
  addMatchingPropertiesRelations,
} from 'store/actions/realtorMatchingProperties';

import {
  closeRejectionModal,
} from 'store/actions/realtorMatchingPropertyRejectionModal';

import { startsWith } from 'shared/utility';

import MatchingPropertyRejectionForm from './MatchingPropertyRejectionForm';

const getRejectedReasons = (data) => Object.keys(data).reduce((acc, key) => {
    if (startsWith(key, 'reason_id_')) {
      if (data[key]) {
        acc.push(+key.split('reason_id_')[1]);
      }
    }

    return acc;
  }, []);

const mapStateToProps = (state) => {
  const {
    matchingPropertyRejectionModal: {
      matchingPropertyId,
      onSave,
    },
  } = state;

  return {
    matchingPropertyId,
    onSave,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: async (id, data) => {
    const {
      reject_reason_note,
    } = data;

    await dispatch(updateMatchingProperty(id)({
      reject_reason_note,
    }));

    const rejectedReasons = getRejectedReasons(data);

    await dispatch(addMatchingPropertiesRelations('rejectedReasons')(id)(rejectedReasons));

    dispatch(closeRejectionModal());
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  onSubmit: async (data) => {
    const {
      matchingPropertyId,
      onSave,
    } = stateProps;

    await dispatchProps.onSubmit(matchingPropertyId, data);

    if (onSave) {
      onSave();
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(MatchingPropertyRejectionForm);
