import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  VIEWS_FROM_HOME,
} from 'constants/realtor.constants';

import MultiselectTextfield from 'ui/MultiselectTextfield';

const ViewsFromHomeSelect = (props) => {
  const {
    handleChange,
    value,
    defaultView,
  } = props;

  const selectedValue = Array.isArray(value)
    ? value.map((key) => ({
      value: `${key}`,
      label: VIEWS_FROM_HOME[key],
    }))
    : undefined;

  const onChange = useCallback((newValue) => {
    handleChange(newValue.map((item) => item.value));
  }, [
    handleChange,
  ]);

  return (
    <MultiselectTextfield
      label="Views from home"
      onChange={onChange}
      options={VIEWS_FROM_HOME}
      value={selectedValue}
      defaultView={defaultView}
    />
  );
};

ViewsFromHomeSelect.defaultProps = {
  value: undefined,
};

const {
  func,
  arrayOf,
  oneOf,
  number,
} = PropTypes;

ViewsFromHomeSelect.propTypes = {
  handleChange: func.isRequired,
  value: arrayOf(number),
  defaultView: oneOf(['edit', 'display']),
};

export default ViewsFromHomeSelect;
