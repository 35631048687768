import React, {
  useCallback,
  useState,
} from 'react';

import PropTypes from 'prop-types';

import moment from 'moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import Textfield from 'ui/Textfield';

const maxDate = new Date();

const SingleSelect = (props) => {
  const {
    handleChange,
    value,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const onChange = useCallback((date) => {
    const year = moment(date).format('YYYY');
    handleChange(Number(year) || null);
  }, [
    handleChange,
  ]);

  const handleOnFocus = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, [
  ]);

  const dateValue = value ? moment(value, 'YYYY') : null;

  const renderInput = useCallback((inputProps) => (
    <Textfield
      label="Year built"
      type="text"
      onClick={inputProps.onClick}
      value={value}
      onChange={inputProps.onChange}
      onFocus={inputProps.onFocus}
      icon="calendar"
    />
    ), [
    value,
  ]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        views={['year']}
        label="Year built"
        value={dateValue}
        onChange={onChange}
        margin="dense"
        inputVariant="outlined"
        variant="inline"
        disableToolbar
        maxDate={maxDate}
        TextFieldComponent={renderInput}
        PopoverProps={{
          anchorEl,
        }}
        onFocus={handleOnFocus}
        autoOk
      />
    </MuiPickersUtilsProvider>
  );
};

SingleSelect.defaultProps = {
  value: null,
};

const {
  func,
  number,
} = PropTypes;

SingleSelect.propTypes = {
  handleChange: func.isRequired,
  value: number,
};

export default SingleSelect;
