import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'antd';

import Typography from 'ui/Typography';

import Avatar from './Avatar';

import {
  Root,
  Details,
} from './styledItems';

const ContactCell = (props) => {
  const {
    contact: {
      avatarSrc,
      firstName,
      lastName,
    },
    avatarOnly,
  } = props;

  const fullName = `${firstName || ''} ${lastName || ''}`;

  if (avatarOnly) {
    return (
      <Root>
        <Tooltip title={fullName}>
          <Avatar
            src={avatarSrc}
            firstName={firstName}
            lastName={lastName}
          />
        </Tooltip>
      </Root>
    );
  }

  return (
    <Root>
      <Avatar
        src={avatarSrc}
        firstName={firstName}
        lastName={lastName}
      />
      <Details>
        <Tooltip title={fullName}>
          <Typography
            ellipsis
            style={{
              maxWidth: 150,
            }}
            variant="cell"
          >
            {fullName}
          </Typography>
        </Tooltip>
      </Details>
    </Root>
  );
};

ContactCell.defaultProps = {
  avatarOnly: false,
};

const {
  string,
  shape,
  bool,
} = PropTypes;

ContactCell.propTypes = {
  contact: shape({
    avatarSrc: string,
    firstName: string,
    lastName: string,
  }).isRequired,
  avatarOnly: bool,
};

export default ContactCell;
