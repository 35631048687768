import {
  useState,
  useEffect,
} from 'react';

import requests from 'api/requests';

const useThreads = (filters) => {
  const [threads, setThreads] = useState(null);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getThreads = async (mailFilters) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await requests.integrations.emails.threads(mailFilters);

      setThreads(response?.data?.threads);
      setCount(response?.data?.count);
    } catch (newError) {
      setError(newError);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (filters.tokenId) {
      getThreads(filters);
    }
  }, [
    JSON.stringify(filters),
  ]);

  return {
    threads,
    count,
    isLoading,
    error,
    getThreads,
  };
};

export default useThreads;
