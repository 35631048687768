import styled from 'styled-components';

import { NakedButton } from '@symplete/symplete-ui';

export const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledButton = styled(NakedButton)`
&& {
  width: 38px;
  height: 28px;
  margin: 0 2px;
  text-align: center;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  color: ${(props) => (props.color || '#a1bdcb')};
  border-radius: 3px;
  border: solid 1px;
  border-color: ${(props) => (props.color || '#a1bdcb')};
  
}
`;
