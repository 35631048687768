import { connect } from 'react-redux';

import selectors from 'store/selectors';

import ProspectsKanban from './ProspectsKanban';

const mapStateToProps = (state) => {
  const {

  } = state;

  const lanes = selectors.realtorClients.kanban(state);

  return {
    lanes,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ProspectsKanban);
