import React, { useState, useEffect } from 'react';

import styled from 'styled-components';

import HouseDefault from 'assets/icons/property.svg';

import requests from 'api/requests';

const PropertyPhoto = styled.div`
  width: 100%;
  height: ${(props) => (props.height || '105px')};
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${(props) => props.image};
  background-color: ${(props) => props.theme.palette?.['background-flat-dark'] || 'lightgray'};
  background-size: ${(props) => (props.showPlaceholder ? '20%' : 'cover')};
  border-radius: 10px;
`;

// this does look crazy but this is the only way I could make transforming react elements to image work in email template composer
const getImage = async (src, onSuccess, onError) => {
  try {
    const response = await requests.images.base64(src);
    onSuccess(`url("${response.data}")`);
  } catch (error) {
    console.log('error', error)
    onError();
  }
};

export default (props) => {
  const [image, setImage] = useState(`url("${HouseDefault}")`);
  const [isLoading, setIsLoading] = useState(false);

  const {
    src,
  } = props;

  useEffect(() => {
    if (src) {
      setIsLoading(true);
      getImage(
        src,
        (img) => {
          setImage(img);
          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
        },
      );
    }
  }, [
    src,
  ]);

  return (
    <PropertyPhoto
      {...props}
      image={image}
      showPlaceholder={!src || isLoading}
    />
  );
};
