import { connect } from 'react-redux';

import selectors from 'store/selectors';

import { ROLE_LABELS } from 'constants/realtor.constants';
import Contact from './Contact';

const mapStateToProps = (state, ownProps) => {
  const lead = selectors.realtorLeads.byId(state, ownProps.leadId);

  return {
    contactId: lead?.contact_id,
    avatarLabel: ROLE_LABELS[lead.leadRole],
  };
};

export default connect(mapStateToProps)(Contact);
