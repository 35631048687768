import { connect } from 'react-redux';

import {
  updateInspection,
} from 'store/actions/realtorInspections';

import DateCell from 'shared/components/UI/TableCells/RealtorCells/DateCell';

const mapStateToProps = (state, ownProps) => {
  const {
    inspectionId,
  } = ownProps;

  return {
    inspectionId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChangeDate: (date_time, inspectionId) => {
    dispatch(updateInspection(inspectionId)({ date_time }));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onChangeDate: (date_time) => {
    dispatchProps.onChangeDate(date_time, stateProps.inspectionId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(DateCell);
