import React, { useContext, useCallback } from 'react';

import { func, string } from 'prop-types';

import Button from 'ui/Button';

import editorContext from 'ui/TextEditor/context';

const EmailSendButton = (props) => {
  const { onSend, emailTo } = props;

  const { editor } = useContext(editorContext);

  const handleSend = useCallback(
    (data) => {
      onSend(data);

      try {
        editor.commands.setContent('');
      } catch (error) {
        console.error(error);
      }
    },
    [onSend, editor],
  );

  const disabled = !editor?.getText() || !emailTo.length;

  return (
    <Button
      variant="primary"
      icon="send"
      onClick={handleSend}
      disabled={disabled}
    />
  );
};

EmailSendButton.defaultProps = {
  emailTo: '',
};

EmailSendButton.propTypes = {
  onSend: func.isRequired,
  emailTo: string,
};

export default EmailSendButton;
