import { connect } from 'react-redux';

import selectors from 'store/selectors';

import { updateLead } from 'store/actions/realtorLeads';

import TempSelect from 'ui/TempSelect';

const mapStateToProps = (state, ownProps) => {
  const lead = selectors.realtorLeads.byId(state, ownProps.leadId);

  return {
    temp: lead.temp,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onOptionSelect: (temp) => {
    dispatch(updateLead(ownProps.leadId)({ temp }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TempSelect);
