import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';
import Divider from '@material-ui/core/Divider';
import Button from 'ui/Button';

import NotesModal from 'sections/Transactions/modals/InspectionNotesModal';

import InspectionsTable from './InspectionsTable';
import Overview from './Overview';

const Inspections = (props) => {
  const {
    transactionId,
    openNewInspection,
  } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid
          container
          justify="space-between"
          alignItems="center"
        >
          <Grid item style={{ display: 'inline-flex' }}>
            <Overview transactionId={transactionId} />
          </Grid>

          <Grid item>
            <Button
              variant="secondary"
              onClick={openNewInspection}
              icon="plus"
            >
              New
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <InspectionsTable transactionId={transactionId} />
      </Grid>

      <NotesModal />
    </Grid>
  );
};

Inspections.defaultProps = {
  transactionId: null,
};

const {
  number,
  string,
  oneOfType,
  func,
} = PropTypes;

Inspections.propTypes = {
  transactionId: oneOfType([number, string]),
  openNewInspection: func.isRequired,
};

export default Inspections;
