import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import { Button } from './styledItems';

const colors = {
  default: 'rgba(35, 48, 63, 0.87)',
  blue: '#1584ff',
  yellow: '#fadb14',
  grey: '#e1e1e1',
  red: '#ff526b',
};

const ObjectMenuIconButton = (props) => {
  const {
    iconName,
    onClick,
    className,
    color,
    iconSize,
    backgroundColor,
    type,
  } = props;

  const buttonColor = colors[color];

  return (
    <Button
      onClick={onClick}
      iconName={iconName}
      className={classnames({
        [className]: className,
      })}
      color={buttonColor}
      iconSize={iconSize}
      backgroundColor={backgroundColor}
      type={type}
    />
  );
};

ObjectMenuIconButton.defaultProps = {
  className: null,
  color: 'default',
  iconSize: null,
  backgroundColor: null,
  type: 'button',
};

const {
  func,
  string,
  oneOf,
} = PropTypes;

ObjectMenuIconButton.propTypes = {
  /**
   * font awesome icon name
   */
  iconName: string.isRequired,
  className: string,
  onClick: func.isRequired,
  iconSize: string,
  color: oneOf([
    'default',
    'blue',
    'yellow',
    'grey',
    'red',
  ]),
  /**
   * valid css color
   */
  backgroundColor: string,
  /**
   * html button type
   */
  type: string,
};

export default ObjectMenuIconButton;
