import React, {
  useContext,
  useCallback,
} from 'react';

import { NakedButton } from '@symplete/symplete-ui';
import Typography from 'ui/Typography';

import OpportunitiesContext from 'sections/OpportunitiesListing/context/OpportunitiesContext';

import { Root } from './styledItems';

const ViewReserved = () => {
  const {
    view,
    setView,
    cartList,
  } = useContext(OpportunitiesContext);

  const count = cartList?.length;

  const onView = useCallback(() => {
    setView('cart');
  }, [
    setView,
  ]);

  const onBack = useCallback(() => {
    setView('zip');
  }, [
    setView,
  ]);

  return (
    <Root>
      <Typography align="center">
        <Typography
          tag="span"
          weight="bold"
        >
          { count }
        </Typography>
        { count === 1 ? ' opportunity' : ' opportunities'}
        {' '}
        reserved
      </Typography>

      {
        view === 'zip' && (
          <NakedButton onClick={onView}>
            <Typography
              variant="link"
              align="center"
              color="focus"
              noMargin
            >
              View Cart
            </Typography>
          </NakedButton>
        )
      }
      {
        view === 'cart' && (
          <NakedButton onClick={onBack}>
            <Typography
              variant="link"
              align="center"
              color="focus"
              noMargin
            >
              Back to zip area list
            </Typography>
          </NakedButton>
        )
      }
    </Root>
  );
};

export default ViewReserved;
