import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

import {
  Wrapper,
  LabelContainer,
  LabelBody,
  LabelContent,
} from './styledItems';

const BorderLabel = (props) => {
  const {
    children,
    title,
    color,
    textColor,
    width,
    top,
    left,
  } = props;

  return (
    <Wrapper>
      {children}

      <LabelContainer
        width={width}
        top={top}
        left={left}
      >
        <LabelBody color={color}>
          <LabelContent color={color}>
            <Typography
              tag="span"
              overrideFontSize="12px"
              weight="bold"
              color={textColor}
              style={{ textTransform: 'uppercase' }}
            >
              {title}
            </Typography>
          </LabelContent>
        </LabelBody>
      </LabelContainer>
    </Wrapper>
  );
};

BorderLabel.defaultProps = {
  color: 'success',
  textColor: 'contrast',
  width: null,
  top: null,
  left: null,
};

const {
  element,
  string,
} = PropTypes;

BorderLabel.propTypes = {
  children: element.isRequired,
  title: string.isRequired,
  color: string,
  textColor: string,
  width: string,
  top: string,
  left: string,
};

export default BorderLabel;
