import React from 'react';

import { datetime } from '@symplete/symplete-utils';
import { since } from '@symplete/symplete-utils/datetime';

import Typography from 'ui/Typography';
import TableHead from 'ui/TableHead';

import Options from './Options';

const columns = ({ onRemove }) => [
    {
      title: () => (
        <TableHead>
          Service
        </TableHead>
      ),
      width: 2,
      dataIndex: 'scope',
      key: 'scope',
      render: (value, entry) => (value ? (
        <Typography
          variant="cell"
        >
          {value}
        </Typography>
      ) : null),
    },
    {
      title: () => (
        <TableHead>
          Account
        </TableHead>
      ),
      width: 2,
      dataIndex: 'name',
      key: 'name',
      render: (value, entry) => (value ? (
        <Typography
          variant="cell"
        >
          {value}
        </Typography>
      ) : null),
    },
    {
      title: () => (
        <TableHead>
          Active since
        </TableHead>
      ),
      width: 2,
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value, entry) => (value ? (
        <Typography
          variant="cell"
        >
          {datetime(value).format('MMM DD, YYYY')}
          {' '}
          (
          {since(value)}
          {' '}
          d)
        </Typography>
      ) : null),
    },
    {
      title: null,
      dataIndex: 'id',
      key: 'options',
      width: 0.5,
      render: (value) => (
        <Options
          tokenId={value}
          onRemove={onRemove}
        />
      ),
    },
  ];

export default columns;
