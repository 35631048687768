import React, {
  useContext,
  useMemo,
} from 'react';

import { get, isUndefined, isNull } from 'shared/utility';

import Typography from 'ui/Typography';
import Spin from 'ui/Spin';

import OpportunitiesContext from 'sections/OpportunitiesListing/context/OpportunitiesContext';

import formatters from 'sections/OpportunitiesListing/utils/formatters';

const Details = (props) => {
  const {
    entry,
    propertyId,
    propertyKey,
    format,
    customCellFormat,
  } = props;

  const context = useContext(OpportunitiesContext);

  const {
    detailsByProperty,
    isLoadingDetailsByProperty,
    errorByProperty,
    getPropertyDetails,
    view,
  } = context;

  if (errorByProperty[propertyId]) {
    return (
      <Typography
        color="error"
        noMargin
      >
        Can’t fetch - daily quota exceeded
      </Typography>
    );
  }

  if (isLoadingDetailsByProperty[propertyId]) {
    return (
      <Spin />
    );
  }

  const placeholder = useMemo(() => Math.random().toString(36).slice(2, 8), [
    propertyId,
  ]);

  const details = entry?.details ?? detailsByProperty[propertyId]?.details;

  const noDetails = isNull(details) || isUndefined(details);

  if (view === 'cart' && noDetails) {
    getPropertyDetails(propertyId);
  }

  if (view === 'zip' || noDetails) {
    return (
      <Typography
        noMargin
        noWrap
        blur
      >
        {placeholder}
      </Typography>
    );
  }

  if (customCellFormat) {
    return customCellFormat(details);
  }

  const formatter = (() => {
    if (!format || !formatters[format]) return (value) => value;

    if (typeof format === 'function') return (value) => format(value);

    return (value) => formatters[format](value);
  })();

  const value = formatter(JSON.stringify(get(details, propertyKey)));

  return <>{value}</>;
};

export default Details;
