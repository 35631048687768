import store from 'store';

import { openNewClientModal } from 'store/actions/newRealtorClientModal';

import { openNewProspectModal } from 'store/actions/newRealtorProspectModal';

const convert = (leadId) => (objectType) => (objectId) => {
  if (objectType === 'property') {
    store.dispatch(openNewClientModal(objectId, leadId));
  }

  if (objectType === 'propertyRequirement') {
    store.dispatch(openNewProspectModal(objectId, leadId));
  }
};

export default convert;
