import styled from 'styled-components';

const Block = styled.div`
  height: 100%;
  padding: 14px 18px;
  border-radius: 5px;
  border: solid 0.5px #e1e1e1;
`;

export default Block;
