import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import DateFnsUtils from '@date-io/date-fns';

import Typography from 'ui/Typography';

import moment from 'moment';

import {
  MuiPickersUtilsProvider,
  DateTimePicker,
} from '@material-ui/pickers';

import {
  Wrapper,
} from './styledItems';

const DateRangePicker = (props) => {
  const {
    dateFrom,
    dateTo,
    onChange,
    value,
    fromLabel,
    toLabel,
  } = props;

  const onFromChange = useCallback((newFromValue) => {
    onChange([moment(newFromValue), value[1]]);
  }, [
    value,
  ]);

  const onToChange = useCallback((newToValue) => {
    onChange([value[0], moment(newToValue)]);
  }, [
    value,
  ]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Wrapper>
        <DateTimePicker
          label={fromLabel}
          value={value[0]}
          onChange={onFromChange}
          margin="dense"
          inputVariant="outlined"
          variant="inline"
          minDate={dateFrom}
          maxDate={value[1]}
          fullWidth
          showTodayButton
          minutesStep={5}
          style={{ marginTop: 4 }}
        />
        <Typography
          variant="label"
          className="range-date-picker-text"
        >
          to
        </Typography>
        <DateTimePicker
          label={toLabel}
          value={value[1]}
          onChange={onToChange}
          margin="dense"
          inputVariant="outlined"
          variant="inline"
          minDate={value[0]}
          maxDate={dateTo}
          fullWidth
          showTodayButton
          minutesStep={5}
          style={{ marginTop: 4 }}
        />
      </Wrapper>
    </MuiPickersUtilsProvider>
  );
};

DateRangePicker.defaultProps = {
  fromLabel: 'From',
  toLabel: 'To',
};

const {
  arrayOf,
  func,
  string,
} = PropTypes;

DateRangePicker.propTypes = {
  dateFrom: string.isRequired,
  dateTo: string.isRequired,
  onChange: func.isRequired,
  value: arrayOf(string).isRequired,
  fromLabel: string,
  toLabel: string,
};

export default DateRangePicker;
