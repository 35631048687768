import React from 'react';
import PropTypes from 'prop-types';

import Elevation from 'ui/Elevation';
import ErrorBoundary from 'ui/ErrorBoundary';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import Contact from 'sections/Leads/modules/Contact';
import LeadDetails from 'sections/Leads/modules/LeadDetails';

import {
  Root,
} from './styledItems';

const Lead = (props) => {
  const {
    leadId,
  } = props;

  return (
    <ErrorBoundary>
      <Root>
        <Flex
          fullWidth
          spacing={2}
          flexWrap="wrap"
          alignItems="stretch"
        >
          <FlexItem xs={8}>
            <Contact
              leadId={leadId}
            />
          </FlexItem>

          <FlexItem xs={16}>
            <Elevation
              rounded="s"
              padding="m"
              fullHeight
            >
              <LeadDetails leadId={leadId} />
            </Elevation>
          </FlexItem>
        </Flex>
      </Root>
    </ErrorBoundary>
  );
};

const {
  func,
  number,
} = PropTypes;

Lead.propTypes = {
  leadId: number.isRequired,
  showLeadDetails: func.isRequired,
};

export default Lead;
