import React from 'react';
import PropTypes from 'prop-types';

import {
  Tooltip,
} from 'antd';

import Button from 'ui/Button';

const CompleteButton = (props) => {
  const {
    isComplete,
    onClick,
    completeActionText,
    undoActionText,
    completeTooltipText,
    undoTooltipText,
  } = props;

  const icon = isComplete
    ? null
    : 'check';

  const tooltip = isComplete
    ? undoTooltipText
    : completeTooltipText;

  return (
    <Tooltip title={tooltip}>
      <Button
        variant={isComplete ? 'secondary' : 'primary'}
        onClick={onClick}
        icon={icon}
      >
        {
          isComplete
            ? undoActionText
            : completeActionText
        }
      </Button>
    </Tooltip>
  );
};

const noOp = () => {};

CompleteButton.defaultProps = {
  isComplete: false,
  onClick: noOp,
  completeActionText: 'Mark as done',
  undoActionText: 'Undo',
  completeTooltipText: 'Click to complete',
  undoTooltipText: 'Click to undo',
};

const {
  bool,
  func,
  string,
} = PropTypes;

CompleteButton.propTypes = {
  isComplete: bool,
  onClick: func,
  completeActionText: string,
  undoActionText: string,
  completeTooltipText: string,
  undoTooltipText: string,
};

export default CompleteButton;
