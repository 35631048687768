import React, {
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';

const ImprovedDrawer = React.forwardRef((props, ref) => {
  const {
    title,
    width,
    onClose,
    visible,
    afterVisibleChange,
    mask,
    closable,
    placement,
    className,
    outsideDrawerClick,
    children,
  } = props;

  const handleClickOutside = useCallback((event) => {
    outsideDrawerClick(event);
  }, [ref]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <Drawer
      title={title}
      width={width}
      onClose={onClose}
      visible={visible}
      afterVisibleChange={afterVisibleChange}
      mask={mask}
      closable={closable}
      placement={placement}
      className={className}
    >
      <div
        className="outside-click-event-contain"
        ref={ref}
      >
        {children}
      </div>
    </Drawer>
  );
});

ImprovedDrawer.defaultProps = {
  width: null,
  onClose: () => { },
  title: null,
  visible: false,
  afterVisibleChange: () => { },
  mask: true,
  closable: true,
  placement: 'right',
  className: null,
  outsideDrawerClick: () => { },
  children: null,
};

const {
  bool,
  string,
  number,
  func,
  elementType,
} = PropTypes;

ImprovedDrawer.propTypes = {
  title: elementType,
  width: number,
  onClose: func,
  visible: bool,
  afterVisibleChange: func,
  mask: bool,
  closable: bool,
  placement: string,
  className: string,
  outsideDrawerClick: func,
  children: elementType,
};

export default ImprovedDrawer;
