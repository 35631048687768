import { connect } from 'react-redux';
import { debounce } from 'shared/utility';

import selectors from 'store/selectors';

import {
  setFilterItem,
  clearFilter,
  handleCustomFilter,
} from 'store/actions/realtorOpportunitiesFilter';

import {
  getOpportunitiesForStoreFilter,
} from 'store/actions/realtorOpportunities';

import SearchFilter from 'ui/SearchFilter';

const mapStateToProps = (state) => {
  const {
    realtorOpportunitiesFilter,
  } = state;

  const filterKey = selectors.realtorOpportunities.opportunitiesFilterKey(state);

  return {
    searchValue: realtorOpportunitiesFilter.search || '',
    customFilter: filterKey,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSearch: debounce((search) => {
    dispatch(setFilterItem('search')(search));
    dispatch(getOpportunitiesForStoreFilter());
  }, 500),
  handleCustomFilter: (key) => {
    dispatch(clearFilter());
    dispatch(handleCustomFilter(key));
    dispatch(getOpportunitiesForStoreFilter());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchFilter);
