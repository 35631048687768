import { connect } from 'react-redux';
import store from 'store';

import {
  setSelectedLead,
} from 'store/actions/realtorLeads';

import {
  openModal,
} from 'store/actions/modals';

import Lead from './Lead';

const mapStateToProps = (state, ownProps) => {
  const {
    realtorLeads: {
      selectedLead,
    },
  } = state;

  const { leadId } = ownProps.match.params;

  if (selectedLead !== leadId) {
    store.dispatch(setSelectedLead(leadId));
  }

  return {};
};

const mapDispatchToProps = (dispatch) => ({
  showLeadDetails: (leadId) => () => {
    dispatch(openModal('profileDetails')({}, { leadId }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Lead);
