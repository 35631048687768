import updateMatchingProperty from './updateMatchingProperty';
import createMatchingProperty from './createMatchingProperty';

export default {
  updateMatchingProperty,
  updateMatchingBuyer: updateMatchingProperty,
  updateMatch: updateMatchingProperty,
  createMatchingProperty,
  createMatchingBuyer: createMatchingProperty,
  createMatch: createMatchingProperty,
};
