import React from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import KPIItem from 'ui/KPIItem';
import Tooltip from 'ui/Tooltip';

import { ItemWrapper } from './styledItems';

const Metrics = (props) => {
  const {
    data,
    metrics
  } = props;

  if (!metrics) return <></>;

  const items = metrics.reduce((acc, item) => {
    if (data[item.key] != null) {
      acc.push({
        ...item,
        value: data[item.key],
      });
    }

    return acc;
  }, []);

  return (
    <Flex spacing={1}>
      {
        items.map((item) => (
          <Tooltip
            title={item.tooltip}
            key={item.key}
          >
            <ItemWrapper>
              <KPIItem
                title={item.value === 'new' ? null : item.value}
                iconName={item.value === 'new' ? 'star' : item.iconName}
                iconType={item.iconType}
                iconColor={item.value === 'new' ? 'new' : 'focus'}
              />
            </ItemWrapper>
          </Tooltip>
        ))
      }
    </Flex>
  );
};

Metrics.defaultProps = {
  data: {},
  metrics: null
};

const {
  arrayOf,
  shape,
  // string,
} = PropTypes;

Metrics.propTypes = {
  metrics: arrayOf(shape()),
  data: shape(),
};

export default Metrics;
