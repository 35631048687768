import React, {
  useState,
  useCallback,
} from 'react';

import { NakedButton } from '@symplete/symplete-ui';

import Icon from 'ui/Icon';
import Drawer from 'ui/Drawer';
import Tooltip from 'ui/Tooltip';

import FollowUps from 'sections/FollowUps';

const DrawerView = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const openDrawer = useCallback(() => {
    setDrawerOpen(true);
  });
  const closeDrawer = useCallback(() => {
    setDrawerOpen(false);
  });

  return (
    <>
      <Drawer
        visible={isDrawerOpen}
        onClose={closeDrawer}
        width={600}
        bodyStyle={{ height: '100%' }}
      >
        {
          // this is to prevent unnecessary renders when drawer is hidden
          isDrawerOpen && <FollowUps />
        }
      </Drawer>

      <Tooltip
        placement="bottom"
        title="Follow Ups"
      >
        <NakedButton onClick={openDrawer}>
          <Icon
            name="bullhorn"
            color="main-text-inversed"
            size="m"
            type="fontawesome"
          />
        </NakedButton>
      </Tooltip>
    </>
  );
};

export default DrawerView;
