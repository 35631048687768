import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  // setSelectedLeadRows,
  setSelectedLeadProperty,
  getLeadsForStoreFilter,
} from 'store/actions/realtorLeads';

import {
  setFilterItem,
} from 'store/actions/realtorLeadsFilter';

import LeadsTable from './LeadsTable';

const mapStateToProps = (state) => {
  const {
    realtorLeads: {
      leadsByFilterCount,
      selectedLeadRows,
    },
    realtorLeadsFilter: {
      pageNumber,
      pageSize,
      // sortBy,
      // sortDirection,
    },
  } = state;

  const {
    filterId,
  } = selectors.realtorLeads.leadsFilter(state);

  const leadsList = selectors.realtorLeads.leadsList(state, filterId);

  return {
    itemsCount: leadsByFilterCount[filterId],
    leadsList,
    pageNumber,
    pageSize,
    sortBy: 'status',
    sortDirection: 'desc',
    selectedLeadRows,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getLeads: (page, pageSize) => {
    const offset = (page - 1) * pageSize;
    const limit = pageSize;

    dispatch(getLeadsForStoreFilter(offset, limit));
  },
  setFilterItem: (key) => (value) => {
    dispatch(setFilterItem(key)(value));
  },
  // onRowSelect: (selectedLeadIds) => {
  //   dispatch(setSelectedLeadRows(selectedLeadIds));
  // },
  setSelectedProperty: (propertyData) => {
    dispatch(setSelectedLeadProperty(propertyData));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LeadsTable);
