import { connect } from 'react-redux';

import selectors from 'store/selectors';

const withLeadData = (Component) => {
  const mapStateToProps = (state, ownProps) => {
    const {
      itemId,
    } = ownProps;

    const propertyData = selectors.properties.byId(state, itemId);

    return {
      propertyData,
    };
  };

  return connect(mapStateToProps)(Component);
};

export default withLeadData;
