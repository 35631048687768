import React from 'react';

import moment from 'moment';

import Typography from 'ui/Typography';

import UnderConstructionPlaceholder from 'shared/components/UI/UnderConstructionPlaceholder';

import ContactCell from 'sections/Opportunities/components/ViewingsTable/ContactCell';
import NoteCell from 'sections/Opportunities/components/ViewingsTable/NoteCell';
import DeleteButton from 'sections/Opportunities/components/ViewingsTable/DeleteButton';
import AddContactButton from 'sections/Opportunities/components/ViewingsTable/AddContactButton';
import InterestedCell from 'sections/Opportunities/components/ViewingsTable/InterestedCell';
import PropertyCell from 'sections/Opportunities/components/ViewingsTable/PropertyCell';

const columns = ({
  hoveredRecord,
}) => ([
  {
    title: 'Date',
    dataIndex: 'date_time',
    key: 'date',
    width: 100,
    className: 'viewings-table__sm-padding',
    render: (value, entry) => (value ? (
      <Typography variant="cell" style={{ lineHeight: '18px', padding: '5px 0' }}>
        {moment(value).format('MMMM D, YYYY')}
        <br />
        {moment(value).format('hh:mm A')}
      </Typography>
    ) : null),
  },
  {
    title: 'Property',
    width: 200,
    dataIndex: 'property_id',
    key: 'property',
    className: 'viewings-table__md-padding',
    render: (value) => (
      <PropertyCell propertyId={value} />
    ),
    align: 'left',
  },
  {
    title: 'Listing Agent',
    width: 100,
    dataIndex: 'contacts',
    key: 'listing_agent',
    className: 'viewings-table__sm-padding',
    render: (value, entry) => {
      if (Array.isArray(value)) {
        const agent = value.find((contact) => contact.role === 4);
        if (agent) {
          return <ContactCell contactId={agent.id} avatarOnly />;
        }
      }
      return <AddContactButton viewingId={entry.id} field="sellerAgent" />;
    },
    align: 'left',
  },
  {
    title: 'Follow Up',
    width: 100,
    dataIndex: '',
    key: 'reminder',
    render: (value, entry) => (
      <UnderConstructionPlaceholder>
        <Typography variant="cell">
          Follow Up
        </Typography>
      </UnderConstructionPlaceholder>
    ),
    align: 'left',
  },
  {
    title: 'Interested',
    width: 100,
    dataIndex: 'is_interested',
    key: 'is_interested',
    className: 'viewings-table__sm-padding',
    render: (value, entry) => (
      <InterestedCell value={value} viewingId={entry?.id} />
    ),
    align: 'center',
  },
  {
    title: 'Notes',
    width: 60,
    dataIndex: 'notes',
    key: 'notes',
    className: 'viewings-table__sm-padding',
    render: (value, entry) => (
      <NoteCell hasContent={value && value !== '<p></p>'} viewingId={entry?.id} />
    ),
    align: 'center',
  },
  {
    title: '',
    width: 30,
    key: 'delete',
    className: 'viewings-table__sm-padding',
    render: (_, entry) => {
      if (hoveredRecord === entry.key) {
        return <DeleteButton viewingId={entry?.id} />;
      }

      return null;
    },
    align: 'center',
  },
]);

export default columns;
