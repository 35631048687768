import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundary from 'ui/ErrorBoundary';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import Button from 'ui/Button';
import Typography from 'ui/Typography';
import KPIItem from 'ui/KPIItem';

import withModal from 'ui/hocs/withModal';

import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';

import DetailsSection from 'sections/Leads/components/DetailsSection';

import ImageCarousel from 'sections/MLS/modules/ImageCarousel';

import AgreementDetails from 'sections/Clients/modules/AgreementDetails';
import ListingRemarks from 'sections/Clients/modules/ListingRemarks';

import PropertyNotablePoints from 'sections/Properties/modules/PropertyNotablePoints';
import PropertyDisclosures from 'sections/Properties/modules/PropertyDisclosures';

import ListingPriceInput from 'sections/Clients/modules/ListingPriceInput';

import PropertyAddressInput from 'sections/Properties/modules/PropertyAddressInput';
import PropertyParametersMain from 'sections/Properties/modules/PropertyParametersMain';
import PropertyDetails from 'sections/Properties/modules/PropertyDetails';
import PropertyDetailsFullExpandable from 'sections/Properties/modules/PropertyDetailsFullExpandable';

import AdditionalAmenities from 'shared/modules/AdditionalAmenities';

const SellerProperty = (props) => {
  const {
    clientId,
    propertyId,

    // expansion props
    isExpanded,
    toggleModalVisibility,
  } = props;

  const mode = isExpanded ? 'edit' : 'display';

  return (
    <ErrorBoundary>
      <Flex
        container
        spacing={2}
        alignItems="stretch"
        flexWrap="wrap"
      >
        <FlexItem
          xs={8}
        >
          <ImageCarousel propertyId={propertyId} />
        </FlexItem>

        <FlexItem
          xs={16}
        >
          <Flex
            fullWidth
            fullHeight
            flexDirection="column"
            justifyContent="space-between"
            spacing={2}
          >
            <FlexItem fullWidth>
              {
                isExpanded && (
                  <PropertyAddressInput
                    propertyId={propertyId}
                    defaultView={mode}
                    label="Address"
                    placeholder="Enter seller property address"
                  />
                )
              }
              {
                !isExpanded && (
                  <DecoratedFieldWrapper
                    iconName="map-pin"
                    iconType="feather"
                    variant="focus"
                  >
                    <PropertyAddressInput
                      propertyId={propertyId}
                      defaultView={mode}
                      label={null}
                      placeholder="Enter seller property address"
                    />
                  </DecoratedFieldWrapper>
                )
              }
            </FlexItem>

            <FlexItem fullWidth>
              {
                isExpanded && (
                  <ListingPriceInput
                    clientId={clientId}
                    defaultView={mode}
                  />
                )
              }
              {
                !isExpanded && (
                  <DecoratedFieldWrapper
                    iconName="dollar-sign"
                    iconType="feather"
                    variant="focus"
                  >
                    <ListingPriceInput
                      clientId={clientId}
                      defaultView={mode}
                    />
                  </DecoratedFieldWrapper>
                )
              }
            </FlexItem>

            <FlexItem fullWidth>
              {
                !isExpanded
                  ? (
                    <KPIItem
                      iconName="sliders"
                      variant="flat"
                    >
                      <PropertyParametersMain
                        propertyId={propertyId}
                        defaultView={mode}
                      />
                    </KPIItem>
                    )
                  : (
                    <PropertyParametersMain
                      propertyId={propertyId}
                      defaultView={mode}
                    />
                    )
              }
            </FlexItem>

            <Flex
              fullWidth
              spacing={2}
              alignItems="flex-end"
            >
              <Flex flex={1} spacing={2}>
                <FlexItem flex={1}>
                  <Typography
                    variant="title3"
                  >
                    Notable Selling Points
                  </Typography>
                  <PropertyNotablePoints
                    propertyId={propertyId}
                    ellipsis={1}
                  />
                </FlexItem>
                <FlexItem flex={1}>
                  <Typography
                    variant="title3"
                  >
                    Disclosures
                  </Typography>
                  <PropertyDisclosures
                    propertyId={propertyId}
                    ellipsis={1}
                  />
                </FlexItem>
              </Flex>

              <Button
                onClick={toggleModalVisibility}
                variant="primary"
              >
                {isExpanded ? 'Hide Details' : 'View Details'}
              </Button>

            </Flex>
          </Flex>
        </FlexItem>

        {
          isExpanded && (
            <Flex
              fullWidth
              spacing={4}
              flexWrap="wrap"
            >
              <>
                <DetailsSection
                  icon="briefcase"
                  title="Listing Agreement Details"
                >
                  <AgreementDetails
                    clientId={clientId}
                  />
                </DetailsSection>

                <DetailsSection
                  icon="check-circle"
                  title="Property Details"
                >
                  <PropertyDetails
                    propertyId={propertyId}
                    columns={3}
                  />
                </DetailsSection>

                <DetailsSection
                  icon="key"
                  title="Property Features"
                >
                  <Flex
                    fullWidth
                    flexDirection="column"
                    spacing={2}
                  >
                    <AdditionalAmenities
                      itemId={propertyId}
                      itemType="properties"
                    />

                    <PropertyDetailsFullExpandable
                      propertyId={propertyId}
                    />
                  </Flex>
                </DetailsSection>

                <DetailsSection
                  icon="paperclip"
                  title="Listing Remarks"
                >
                  <ListingRemarks
                    clientId={clientId}
                  />
                </DetailsSection>
              </>
            </Flex>
          )
        }
      </Flex>
    </ErrorBoundary>
  );
};

SellerProperty.defaultProps = {
  isExpanded: false,
};

const {
  bool,
  func,
  number,
} = PropTypes;

SellerProperty.propTypes = {
  clientId: number.isRequired,
  propertyId: number.isRequired,
  isExpanded: bool,
  toggleModalVisibility: func.isRequired,
};

export default withModal(SellerProperty);
