import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import WriteNewButton from 'shared/components/WriteNewButtonV2';
import CommentTabsModule from 'shared/components/CommentTabsModule';
import RichTextEditor from 'shared/components/RichTextEditor';
import CommentControls from 'shared/components/CommentControls';
import CommentTypesSelector from 'shared/components/CommentTypesSelector';
import Typography from 'ui/Typography';

import {
  WriteNewWrapper,
} from './styledItems';

import './Comments.scss';

const rootClass = 'comments-module-root';

const commentTypesMap = {
  question: 1,
  action: 2,
  summary: 3,
  problem: 4,
  idea: 5,
};

const commentControls = ({ onSubmit, onCancel }) => (
  <CommentControls
    onSubmit={onSubmit}
    onCancel={onCancel}
    hideCustomerVisibilitySelector
  />
);

class Comments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showEditor: false,
      commentType: null,
    };
  }

  toggleEditor = () => {
    this.setState(({ showEditor }) => ({ showEditor: !showEditor }));
  };

  handleSubmitPost = async (comment) => {
    const {
      addComment,
      prospectId,
    } = this.props;

    const {
      commentType,
    } = this.state;

    const post = {
      comment,
      commentType: commentTypesMap[commentType],
      prospectId,
      isVisibleToCustomer: false,
    };

    const commentResponse = await addComment(post);

    this.setState({
      showEditor: false,
    });

    return commentResponse;
  };

  onCommentTypeChange = (commentType) => {
    this.setState({
      commentType,
    });
  };

  commentTypesSelector = () => (
    <CommentTypesSelector onTypeChange={this.onCommentTypeChange} />
  );

  render() {
    const {
      className,
      prospectId,
    } = this.props;

    const {
      showEditor,
    } = this.state;

    return (
      <>
        <div
          className={classnames(rootClass, {
            [className]: className,
          })}
        >
          <Typography
            variant="h3"
            weight={600}
          >
            Comments
          </Typography>

          {!showEditor && (
            <WriteNewWrapper>
              <WriteNewButton
                onClick={this.toggleEditor}
              >
                Write new comment here
              </WriteNewButton>
            </WriteNewWrapper>
          )}

          {showEditor && (
            <RichTextEditor
              className={`${rootClass}__editor`}
              onClickCancel={this.toggleEditor}
              onClickPost={this.handleSubmitPost}
              ControlsComponent={commentControls}
              CustomToolbar={this.commentTypesSelector}
            />
          )}
          <CommentTabsModule
            commentedObjectType="realtor prospect"
            commentedObjectId={prospectId}
          />
        </div>
      </>
    );
  }
}

Comments.defaultProps = {
  className: '',
};

const {
  func,
  number,
  string,
} = PropTypes;

Comments.propTypes = {
  addComment: func.isRequired,
  className: string,
  prospectId: number.isRequired,
};

commentControls.propTypes = {
  onSubmit: func.isRequired,
  onCancel: func.isRequired,
};

export default Comments;
