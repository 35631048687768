import { currency } from '@symplete/symplete-utils/numbers';

/**
 *
 * @param {number} min
 * @param {number} max
 * @returns {string} display value
 */
const getPriceRangeDisplayValue = (min, max) => {
  if (!min && !max) {
    return '–';
  }

  if (!min && max) {
    return `Up to ${currency.short(max)}`;
  }

  if (!max && min) {
    return `From ${currency.short(min)}`;
  }

  return `${currency.short(min)}—${currency.short(max)}`;
};

export default getPriceRangeDisplayValue;
