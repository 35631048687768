import { connect } from 'react-redux';
import store from 'store';

import selectors from 'store/selectors';

import {
  setSelectedClient,
} from 'store/actions/realtorClients';

import ClientItem from './ClientItem';

const mapStateToProps = (state, ownProps) => {
  const {
    realtorClients: {
      selectedClient,
      selectedView,
    },
  } = state;

  const { id } = ownProps.match.params;

  if (selectedClient !== id) {
    store.dispatch(setSelectedClient(id));
  }

  const client = selectors.realtorClients.byId(state, id);
  const property = selectors.properties.byId(state, client.realtor_property_id);
  const viewingsSummary = property?.viewings_summary;
  const offersSummary = client.offers_summary;

  return {
    selectedClient,
    selectedView,
    summary: {
      offers: offersSummary?.total_offers || 0,
      counters: offersSummary?.total_counters || 0,
      viewings: viewingsSummary?.upcoming_viewings || 0,
    },
  };
};

export default connect(mapStateToProps)(ClientItem);
