import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import IconButton from 'shared/components/IconButton';
import ActiveFilters from 'sections/Prospects/modules/ProspectsTableSearchFilter/ActiveFilters';
import Search from 'sections/Prospects/modules/ProspectsTableSearchFilter/Search';

const ProspectsTableSearchFilter = (props) => {
  const {
    onFilterClick,
  } = props;

  return (
    <div>
      <Grid
        container
        spacing={1}
        alignItems="center"
      >
        <Grid item xs={10}>
          <Search />
        </Grid>
        <Grid item>
          <IconButton
            iconName="filter"
            onClick={onFilterClick}
            variant="ghost"
          />
        </Grid>
      </Grid>
      <ActiveFilters />
    </div>
  );
};

ProspectsTableSearchFilter.defaultProps = {

};

const {
  func,
} = PropTypes;

ProspectsTableSearchFilter.propTypes = {
  onFilterClick: func.isRequired,
};

export default ProspectsTableSearchFilter;
