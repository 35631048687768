import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import Elevation from 'ui/Elevation';

import ErrorBoundary from 'ui/ErrorBoundary';

// import PropertyOverviewV2 from 'sections/Properties/modules/PropertyOverviewV2';
import PropertyOverview from 'sections/Properties/modules/PropertyOverview';
import PropertyPromote from 'sections/Properties/modules/PropertyPromote';
import PropertyEvents from 'sections/Properties/modules/PropertyEvents';

import AssignInterestedPartyModal from 'sections/Properties/modals/AssignInterestedPartyModal';

import {
  Root,
} from './styledItems';

const Property = (props) => {
  const {
    propertyId, // eslint-disable-line no-unused-vars
  } = props;

  return (
    <ErrorBoundary>
      <Root>
        <Flex
          fullWidth
          spacing={2}
          flexWrap="wrap"
          alignItems="stretch"
        >
          <FlexItem xs={20} style={{ position: 'relative' }}>
            <PropertyOverview propertyId={propertyId} />
          </FlexItem>

          <FlexItem xs={4}>
            <PropertyPromote
              propertyId={propertyId}
            />
          </FlexItem>

          <FlexItem fullWidth>
            <Elevation
              rounded="s"
              padding="m"
            >
              <PropertyEvents
                propertyId={propertyId}
              />
            </Elevation>
          </FlexItem>
        </Flex>
      </Root>

      <AssignInterestedPartyModal
        propertyId={propertyId}
      />
    </ErrorBoundary>
  );
};

Property.defaultProps = {
  propertyId: null,
};

const {
  number,
  string,
  oneOfType,
} = PropTypes;

Property.propTypes = {
  propertyId: oneOfType([number, string]),
};

export default Property;
