import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import Typography from 'ui/Typography';

const dateTimeFormat = 'MMMM D hh:mmA';
const timeFormat = 'hh:mmA';

const ViewingCell = (props) => {
  const {
    dateTime,
  } = props;

  if (!dateTime) {
    return (
      <Typography variant="cell">
        —
      </Typography>
    );
  }

  const isDateToday = moment().isSame(dateTime, 'day') ? 'Today' : false;

  const isDateTomorrow = moment().add(1, 'days').isSame(dateTime, 'day') ? 'Tomorrow' : false;

  const getDateTimeText = useCallback(() => {
    if (isDateToday || isDateTomorrow) {
      return moment(dateTime).format(timeFormat);
    }

    return moment(dateTime).format(dateTimeFormat);
  }, [
    dateTime,
    isDateToday,
    isDateTomorrow,
  ]);

  return (
    <Typography variant="cell">
      {
        (isDateToday || isDateTomorrow) && (
          <strong>
            {isDateToday || isDateTomorrow}
            &nbsp;
          </strong>
        )
      }
      {getDateTimeText()}
    </Typography>
  );
};

ViewingCell.defaultProps = {
  dateTime: null,
};

const {
  string,
} = PropTypes;

ViewingCell.propTypes = {
  dateTime: string,
};

export default ViewingCell;
