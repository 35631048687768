import React, { useContext, useState, useCallback, useEffect } from 'react';

import Chip from 'ui/Chip';
import Flex from 'ui/Flex';

import IntegrationItem from 'sections/Integrations/components/IntegrationItem';

import context from 'sections/Integrations/context';

import useGmailAuth from 'sections/Integrations/hooks/useGmailAuth';
import useGoogleContactsAuth from 'sections/Integrations/hooks/useGoogleContactsAuth';
import useCalendarAuth from 'sections/Integrations/hooks/useCalendarAuth';
import useIntegrations from 'sections/Integrations/hooks/useIntegrations';
import useContactsImport from 'sections/Integrations/hooks/useContactsImport';
import useCalendarImport from 'sections/Integrations/hooks/useCalendarImport';

// NB: en la request solo se necesita el tokenId de nuestra tabla nylas_tokens, no el valor posta del token!
const Google = props => {
  const [contactsTokenId, setContactsTokenId] = useState(null);
  const [calendarTokenId, setCalendarTokenId] = useState(null);

  const { scopes } = useContext(context);

  const { auth: gmailAuth } = useGmailAuth(localStorage.getItem('token'));

  const { auth: contactsAuth } = useGoogleContactsAuth(
    localStorage.getItem('token')
  );

  const { auth: calendarAuth } = useCalendarAuth(localStorage.getItem('token'));

  const { integrationsByScope } = useIntegrations();

  const {
    openContactsImportModal,
    modal: ContactsImportModal
  } = useContactsImport({
    name: 'Google',
    icon: 'integrationsGoogle',
    tokenId: contactsTokenId
  });

  const {
    openCalendarImportModal,
    modal: CalendarImportModal
  } = useCalendarImport({
    name: 'Google',
    icon: 'integrationsGoogle',
    tokenId: calendarTokenId
  });

  const googleContactsScope = Array.isArray(integrationsByScope?.contacts)
    ? integrationsByScope.contacts?.find(item => item.provider === 'google')
    : null;

  const googleCalendarScope = Array.isArray(integrationsByScope?.calendar)
    ? integrationsByScope.calendar?.find(item => item.provider === 'google')
    : null;

  // NB: esto funca bien para ambos! ~ setea el id interno del token de la tabla nylas_tokens
  useEffect(() => {
    setContactsTokenId(googleContactsScope?.id);
    console.log('setting contacts token id to: ', googleContactsScope?.id);
  }, [googleContactsScope?.id]);

  useEffect(() => {
    setCalendarTokenId(googleCalendarScope?.id);
    console.log('setting calendar token id to: ', googleCalendarScope?.id);
  }, [googleCalendarScope?.id]);

  const contactsConnected = scopes.google.has('contacts');

  const handleContactsClick = useCallback(() => {
    if (contactsConnected) {
      openContactsImportModal();
    } else {
      contactsAuth();
    }
  }, [contactsConnected, openContactsImportModal, contactsAuth]);

  const calendarConnected = scopes.google.has('calendar');

  const handleCalendarClick = useCallback(() => {
    if (calendarConnected) {
      openCalendarImportModal();
    } else {
      calendarAuth();
    }
  }, [calendarConnected, openCalendarImportModal, calendarAuth]);

  // TODO: REMOVE
  console.log('Google Oauth Token: ', process.env.REACT_APP_GOOGLE_CLIENT_ID);

  return (
    <>
      <IntegrationItem
        name="Google"
        icon="integrationsGoogle"
        items={
          <Flex fullWidth spacing={1} justifyContent="center">
            <Chip
              variant={scopes.google.has('email') ? 'focus' : 'disabled'}
              onClick={gmailAuth}
            >
              Email
            </Chip>
            <Chip
              variant={contactsConnected ? 'focus' : 'disabled'}
              onClick={handleContactsClick}
            >
              Contacts
            </Chip>
            <Chip
              variant={calendarConnected ? 'focus' : 'disabled'}
              onClick={handleCalendarClick}
            >
              Calendar
            </Chip>
          </Flex>
        }
      />
      {ContactsImportModal}
      {CalendarImportModal}
    </>
  );
};

export default Google;
