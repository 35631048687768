import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'ui/Tooltip';
import ContactLogButton from 'sections/ContactLogs/ContactLogButton';

const MakeContact = (props) => {
  const {
    leadId,
    onAfter,
    shouldShow,
  } = props;

  if (!shouldShow) {
    return null;
  }

  return (
    <Tooltip title="This is a new lead. Log your first contact with them to remove this status.">
      <div>
        <ContactLogButton
          buttonProps={{
            variant: 'primary',
          }}
          objectType="realtor lead"
          objectId={leadId}
          onAfter={onAfter}
        >
          Log First Contact
        </ContactLogButton>
      </div>
    </Tooltip>
  );
};

const {
  bool,
  number,
  func,
} = PropTypes;

MakeContact.propTypes = {
  leadId: number.isRequired,
  shouldShow: bool.isRequired,
  onAfter: func.isRequired,
};

export default MakeContact;
