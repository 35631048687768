import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import requests from 'api/requests';

import Button from 'ui/Button';
import Flex from 'ui/Flex';
import Typography from 'ui/Typography';

import useModal from 'ui/hooks/useModal';

const Unpause = (props) => {
  const {
    campaignId,
  } = props;

  const {
    Modal,
    isModalVisible,
    openModal,
    closeModal,
  } = useModal();

  const onUnpause = useCallback(async () => {
    await requests.integrations.emailCampaigns.actions.unpause(campaignId)();

    closeModal();
  }, [
    campaignId,
  ]);

  return (
    <>
      <Button
        variant="secondary"
        onClick={openModal}
        icon="play"
        iconPosition="right"
      >
        Unpause
      </Button>

      <Modal
        width={600}
        visible={isModalVisible}
        onCancel={closeModal}
        title="Unpause campaign"
        footer={[
          <Button
            variant="secondary"
            onClick={closeModal}
          >
            Cancel
          </Button>,
          <Button
            variant="secondary"
            onClick={onUnpause}
          >
            Unpause
          </Button>,
        ]}
      >
        <Flex
          flexDirection="column"
          spacing={2}
        >
          <Typography>
            This will resume sending the email
          </Typography>
        </Flex>
      </Modal>
    </>
  );
};

const {
  number,
} = PropTypes;

Unpause.propTypes = {
  campaignId: number.isRequired,
};

export default Unpause;
