/* eslint-disable react/jsx-no-bind */

import React from 'react';

import { shape } from 'prop-types';

import { useController } from 'react-hook-form';

import { DatePicker, TimePicker } from 'antd';
import FlexItem from 'ui/FlexItem';
import Flex from 'ui/Flex';
import { common } from 'constants';

const RequestDateTime = (props) => {
  const { control } = props;

  const {
    field: { onChange: onChangeRequestDate, value: requestDate },
  } = useController({
    name: 'requestDate',
    control,
  });

  const {
    field: { onChange: onChangeRequestTime, value: requestTime },
  } = useController({
    name: 'requestTime',
    control,
  });

  return (
    <Flex spacing={2} fullWidth>
      <FlexItem xs={6}>
        <DatePicker
          placeholder="Date"
          value={requestDate}
          onChange={(date) => onChangeRequestDate(date)}
          format={common.DATE.V5}
          size="large"
        />
      </FlexItem>
      <FlexItem xs={6}>
        <TimePicker
          placeholder="Time"
          value={requestTime}
          onChange={(time) => onChangeRequestTime(time)}
          size="large"
        />
      </FlexItem>
    </Flex>
  );
};

RequestDateTime.propTypes = {
  control: shape({}).isRequired,
};

export default RequestDateTime;
