import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import {
  Spin,
} from 'antd';

import InfiniteScroll from 'react-infinite-scroller';

import Grid from 'ui/Grid';

import CenterBox from 'shared/components/UI/CenterBox';
import Typography from 'ui/Typography';

import {
  BoardWrapper,
  LaneWrapper,
  LaneHeader,
  LaneContent,
  CardWrapper,
} from './styledItems';

const height = {
  height: '100%',
};

const KanbanView = (props) => {
  const {
    lanes,
    CardComponent,
    onCardClick,
  } = props;

  const colStyle = {
    width: `${100 / (lanes.length || 1)}%`,
    ...height,
  };

  const handleCardClick = useCallback((item) => (event) => {
    onCardClick && onCardClick(item, event);
  }, [
    onCardClick,
  ]);

  return (
    <BoardWrapper>
      <Grid
        container
        spacing={0}
        style={height}
      >
        {
          lanes.map((lane) => (
            <Grid
              item
              key={lane.id}
              style={colStyle}
            >
              <LaneWrapper>
                <LaneHeader>
                  <Typography
                    variant="h3"
                    align="center"
                  >
                    {lane.name}
                  </Typography>
                  <Typography
                    variant="h4"
                    align="center"
                    tag="p"
                  >
                    {lane.subheading}
                  </Typography>
                </LaneHeader>

                <LaneContent>
                  <InfiniteScroll
                    initialLoad
                    loadMore={lane.loadMore}
                    hasMore={lane.listHasMore}
                    loader={<CenterBox><Spin /></CenterBox>}
                    useWindow={false}
                  >
                    {
                      lane.list?.map((item) => (
                        <CardWrapper
                          onClick={handleCardClick(item)}
                          key={item.id}
                          clickable={!!onCardClick}
                        >
                          <CardComponent
                            {...item}
                          />
                        </CardWrapper>
                      ))
                    }
                  </InfiniteScroll>
                </LaneContent>
              </LaneWrapper>
            </Grid>

          ))
        }
      </Grid>
    </BoardWrapper>
  );
};

KanbanView.defaultProps = {
  lanes: [],
  onCardClick: null,
};

const {
  arrayOf,
  bool,
  elementType,
  func,
  number,
  oneOfType,
  shape,
  string,
} = PropTypes;

KanbanView.propTypes = {
  lanes: arrayOf(shape({
    id: string,
    name: string,
    list: arrayOf(shape({
      id: oneOfType([number, string]).isRequired,
    })),
    listHasMore: bool,
    loadMore: func,
  })),
  CardComponent: elementType.isRequired,
  onCardClick: func,
};

export default KanbanView;
