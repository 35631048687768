import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { NakedButton } from '@symplete/symplete-ui';

import datetime from '@symplete/symplete-utils/datetime';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import Textfield from 'ui/Textfield';
import Flex from 'ui/Flex';
import Typography from 'ui/Typography';
import ClickAwayListener from 'ui/ClickAwayListener';

import useEditMode from 'ui/hooks/useEditMode';

const maxDate = new Date();

const Picker = (props) => {
  const {
    handleChange,
    value,
    label,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOnFocus = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const renderInput = useCallback((inputProps) => (
    <Textfield
      label={label}
      type="text"
      onClick={inputProps.onClick}
      value={value}
      onChange={inputProps.onChange}
      onFocus={inputProps.onFocus}
      icon="calendar"
    />
    ), [
      value,
      label,
    ]);

  return (
    <DatePicker
      views={['year']}
      label={label}
      value={value ? datetime(value, 'YYYY') : null}
      onChange={handleChange}
      margin="dense"
      inputVariant="outlined"
      variant="inline"
      disableToolbar
      maxDate={maxDate}
      TextFieldComponent={renderInput}
      onFocus={handleOnFocus}
      PopoverProps={{
        anchorEl,
      }}
      autoOk
    />
  );
};

const YearRangeSelect = (props) => {
  const {
    min,
    max,
    onChange,
    minLabel,
    maxLabel,
    defaultView,
    displayTypographyProps,
    displayLabel,
  } = props;

  const handleChange = useCallback((type) => (date) => {
    const year = datetime(date).format('YYYY');
    const value = Number(year) || null;

    onChange(type)(value);
  }, [
    onChange,
  ]);

  const {
    mode,
    setEditMode,
    setDisplayMode,
  } = useEditMode(defaultView);

  if (mode === 'display') {
    const minDisplayValue = min;

    const maxDisplayValue = max;

    return (
      <Flex spacing={1}>
        {
          displayLabel && (
            <Typography
              variant="title2"
              tag="span"
              noMargin
            >
              {displayLabel}
            </Typography>
          )
        }
        <NakedButton onClick={setEditMode}>
          <Typography
            variant="title2"
            tag="span"
            noMargin
            weight="bold"
            {...displayTypographyProps}
          >
            {!maxDisplayValue && minDisplayValue && 'from '}

            {minDisplayValue}
            {!minDisplayValue && maxDisplayValue && 'to '}

            {minDisplayValue && maxDisplayValue && '–'}

            {maxDisplayValue}

            {!minDisplayValue && !maxDisplayValue && 'Not set'}
          </Typography>
        </NakedButton>
      </Flex>
    );
  }

  return (
    <ClickAwayListener onClickAway={setDisplayMode}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {/* the div here is to make click away listener work properly */}
        <div>
          <Flex spacing={1}>
            <Picker
              handleChange={handleChange('min')}
              value={min}
              label={minLabel}
            />
            <Picker
              handleChange={handleChange('max')}
              value={max}
              label={maxLabel}
            />
          </Flex>
        </div>
      </MuiPickersUtilsProvider>
    </ClickAwayListener>
  );
};

YearRangeSelect.defaultProps = {
  min: null,
  max: null,
  maxLabel: 'Newest',
  minLabel: 'Oldest',
  displayLabel: null,
  displayTypographyProps: {},
  defaultView: 'edit',
};

const {
  func,
  number,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

YearRangeSelect.propTypes = {
  handleChangeMin: func.isRequired,
  handleChangeMax: func.isRequired,
  min: oneOfType([string, number]),
  max: oneOfType([string, number]),
  maxLabel: string,
  minLabel: string,
  displayLabel: string,
  displayTypographyProps: shape(),
  defaultView: oneOf(['display', 'edit']),
};

export default YearRangeSelect;
