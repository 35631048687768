import React, {
  useState,
  useEffect,
  Fragment,
} from 'react';

import PropTypes from 'prop-types';

import { currency } from '@symplete/symplete-utils/numbers';

import moment from 'moment';

import Tag from 'ui/Tag';
import Flex from 'ui/Flex';
import Typography from 'ui/Typography';
import Button from 'ui/Button';

import ContactInfo from './ContactInfo';
import ExpirationTimer from './ExpirationTimer';
import Attachments from './Attachments';

import {
  Root,
  Row,
  TagsContainer,
} from './styledItems';

const Counter = (props) => {
  const {
    offerId,
    offerValue,
    column,
    tags,
    counterName,
    submitTime,
    expirationTime,
    agentContactId,
    onDecline,
    openNewCounterModal,
    openAcceptCounterModal,
    onDetails,
  } = props;

  const [submitDaysAgo, setSubmitDaysAgo] = useState(0);
  const [submitHoursAgo, setSubmitHoursAgo] = useState(0);

  const [expirationDays, setExpirationDays] = useState(0);
  const [expirationHours, setExpirationHours] = useState(0);

  useEffect(() => {
    const duration = moment.duration(moment().diff(submitTime));
    const days = Math.floor(duration.asDays());
    duration.subtract(moment.duration(days, 'days'));

    const hours = duration.hours();

    setSubmitDaysAgo(days);
    setSubmitHoursAgo(hours);
  }, [
    submitTime,
  ]);

  useEffect(() => {
    if (moment(expirationTime).isBefore()) {
      setExpirationDays(0);
      setExpirationHours(0);
      return;
    }

    const duration = moment.duration(moment(expirationTime).diff());
    const days = Math.floor(duration.asDays());
    duration.subtract(moment.duration(days, 'days'));

    const hours = duration.hours();

    setExpirationDays(days < 1 ? 0 : days);
    setExpirationHours(hours < 1 ? 0 : hours);
  }, [
    expirationTime,
  ]);

  return (
    <Root>
      <Row>
        <Typography
          variant="title2"
          color="selected"
          weight={500}
          noMargin
          ellipsis
          style={{ maxWidth: 200 }}
        >
          {offerValue && currency.full(offerValue)}
        </Typography>
        <TagsContainer
          alignItems="center"
          spacing={4}
          spacingStep={2}
        >
          {
            tags && tags.map((item) => (
              <Fragment key={item}>
                <Tag variant="new">
                  {item}
                </Tag>
              </Fragment>
            ))
          }
        </TagsContainer>
      </Row>

      <Row>
        <div>
          <Flex alignItems="center">
            <Typography
              variant="title3"
              weight={500}
              noMargin
              style={{ marginRight: 10, textTransform: 'capitalize' }}
            >
              {counterName}
            </Typography>
            <Attachments objectId={offerId} />
          </Flex>
          <Typography>
            {`Submitted ${moment(submitTime).format('MMM D, HH:mmA')} (${submitDaysAgo}d ${submitHoursAgo}hrs ago)`}
          </Typography>
        </div>

        {
          agentContactId ? (
            <ContactInfo contactId={agentContactId} />
          ) : null
        }
      </Row>

      <Row>
        <ExpirationTimer days={expirationDays} hours={expirationHours} />

        <Flex
          alignItems="center"
          spacing={4}
          spacingStep={2}
        >
          {
            column === 'action' && (
              <>
                <Button
                  variant="secondary"
                  onClick={onDecline}
                >
                  Decline
                </Button>
                <Button
                  variant="secondary"
                  onClick={openNewCounterModal}
                >
                  Counter
                </Button>
                <Button
                  variant="primary"
                  onClick={openAcceptCounterModal}
                >
                  Accept
                </Button>
                <Button
                  variant="secondary"
                  icon="info"
                  onClick={onDetails}
                />
              </>
            )
          }
          {
            column === 'awaitingResponse' && (
              <>
                <Button
                  variant="secondary"
                  onClick={onDecline}
                >
                  Cancel Offer
                </Button>
                <Button
                  variant="secondary"
                  onClick={openNewCounterModal}
                >
                  Log Counter
                </Button>
                <Button
                  variant="primary"
                  onClick={openAcceptCounterModal}
                >
                  Accepted
                </Button>
                <Button
                  variant="secondary"
                  icon="info"
                  onClick={onDetails}
                />
              </>
            )
          }
        </Flex>
      </Row>
    </Root>
  );
};

Counter.defaultProps = {
  tags: [],
  agentContactId: null,
};

const {
  number,
  arrayOf,
  string,
  oneOf,
  func,
} = PropTypes;

Counter.propTypes = {
  offerId: number.isRequired,
  offerValue: number.isRequired,
  tags: arrayOf(string),
  counterName: string.isRequired,
  submitTime: string.isRequired,
  expirationTime: string.isRequired,
  agentContactId: number,
  column: oneOf(['action', 'awaitingResponse']).isRequired,
  onDecline: func.isRequired,
  openNewCounterModal: func.isRequired,
  openAcceptCounterModal: func.isRequired,
  onDetails: func.isRequired,
};

export default Counter;
