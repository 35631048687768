import React, {
  useContext,
  useState,
  useCallback,
} from 'react'

import Flex from 'ui/Flex'
import Typography from 'ui/Typography'
import DropdownTextfield from 'ui/DropdownTextfield'

import OpportunitiesContext from 'sections/OpportunitiesListing/context/OpportunitiesContext';

const options = [
  {
    label: 'Any',
    value: null
  },
  {
    label: 'Less than 1 year',
    value: [0, 1]
  },
  {
    label: '1–2 years',
    value: [1, 2]
  },
  {
    label: '3–4 years',
    value: [3, 4]
  },
  {
    label: '5–6 years',
    value: [5, 6]
  },
  {
    label: '6 years +',
    value: [6, Infinity]
  },
]

const OwnershipDuration = () => {
  const {
    setOwnershipDuration,
  } = useContext(OpportunitiesContext);

  const [value, setValue] = useState(options[0].value);

  const onChange = useCallback((newValue) => {
    setOwnershipDuration(newValue);
    setValue(newValue);
  }, [
    value,
    setOwnershipDuration,
  ]);

  return (
    <Flex
      spacing={2}
      alignItems="center"
    >
      <Typography
        variant="title2"
        tag="p"
        noMargin
        weight="bold"
        noWrap
      >
        Ownership Duration
      </Typography>
      <DropdownTextfield 
        value={value}
        onChange={onChange}
        options={options}
      />
    </Flex>
  )
}

export default OwnershipDuration
