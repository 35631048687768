import React from 'react';

import ArchiveDeleteLeadModal from 'sections/Leads/modals/ArchiveDeleteLeadModal';
import LeadIntoProspectModal from 'sections/Leads/modals/LeadIntoProspectModal';
import ProfileDetailsModal from 'sections/Contacts/modals/ProfileDetailsModal';

import NewPropertyModal from 'sections/Properties/modals/NewPropertyModal';
import NewViewingModal from 'sections/MLS/modals/NewViewingModal';
import EditViewingModal from 'sections/MLS/modals/EditViewingModal';
import ViewingNotesModal from 'sections/MLS/modals/ViewingNotesModal';

import NewClientModal from 'sections/Clients/modals/NewClientModal';

import NewProspectModal from 'sections/Prospects/modals/NewProspectModal';

import NewCounterModal from 'sections/Opportunities/modals/NewCounterModal';
import LogOfferModal from 'sections/Opportunities/modals/LogOfferModal';
import LogOfferModalUniversal from 'sections/Opportunities/modals/LogOfferModalUniversal';
import SearchMLSModal from 'sections/Opportunities/modals/SearchMLSModal';
import OfferDeclineReasonModal from 'sections/Opportunities/modals/OfferDeclineReasonModal';
import AcceptCounterModal from 'sections/Opportunities/modals/AcceptCounterModal';

import NewDocumentRequestModal from 'sections/Transactions/modals/NewDocumentRequestModal';
import NewAddendumChangeModal from 'sections/Transactions/modals/NewAddendumChangeModal';
import NewExtensionRequestModal from 'sections/Transactions/modals/NewExtensionRequestModal';

import LogNewQuestionModal from 'sections/Transactions/modals/LogNewQuestionModal';
import CancelTransactionModal from 'sections/Transactions/modals/CancelTransactionModal';

import NewRepairRequestModal from 'sections/Transactions/modals/NewRepairRequestModal';
import NewInspectionModal from 'sections/Transactions/modals/NewInspectionModal';

import RepairRequestsModal from 'sections/Transactions/modals/RepairRequestsModal';
import AddendumChangesModal from 'sections/Transactions/modals/AddendumChangesModal';
import DocumentRequestsModal from 'sections/Transactions/modals/DocumentRequestsModal';
import UnansweredQuestionsModal from 'sections/Transactions/modals/UnansweredQuestionsModal';
import ExtensionRequestsModal from 'sections/Transactions/modals/ExtensionRequestsModal';

import OfferDetailsModal from 'sections/Offers/modals/OfferDetails';

import NewContactModal from 'sections/Contacts/modals/NewContactModal';
import NewLeadFormModal from 'sections/Leads/modals/NewLeadFormModal';
import NewLeadFormSimpleModal from 'sections/Leads/modals/NewLeadFormSimpleModal';

import EditFollowUpModal from 'sections/FollowUps/modals/EditFollowUpModal';

import AddPhoneNumber from 'sections/Calls/modals/AddPhoneNumber';
import CallDeviceSetup from 'sections/Calls/modals/CallDeviceSetup';
import Call from 'sections/Calls/modals/Call';

import NewCalendarEntry from 'sections/Calendar/modals/NewEntry';
import NewCalendarEvent from 'sections/Calendar/modals/NewEvent';

const RootModals = () => (
  <>
    <NewPropertyModal />
    <SearchMLSModal />
    <ArchiveDeleteLeadModal />
    <LeadIntoProspectModal />
    <ProfileDetailsModal />
    <NewClientModal />
    <NewProspectModal />
    <NewCounterModal />
    <AcceptCounterModal />
    <LogOfferModal />
    <LogOfferModalUniversal />
    <OfferDeclineReasonModal />
    <NewViewingModal />
    <EditViewingModal />
    <ViewingNotesModal />
    <NewDocumentRequestModal />
    <LogNewQuestionModal />
    <CancelTransactionModal />

    <NewRepairRequestModal />
    <NewInspectionModal />
    <NewAddendumChangeModal />
    <NewExtensionRequestModal />

    <RepairRequestsModal />
    <AddendumChangesModal />
    <DocumentRequestsModal />
    <UnansweredQuestionsModal />
    <ExtensionRequestsModal />

    <OfferDetailsModal />

    <NewContactModal />
    <NewLeadFormModal />
    <NewLeadFormSimpleModal />

    <EditFollowUpModal />

    <AddPhoneNumber />
    <CallDeviceSetup />
    <Call />

    <NewCalendarEntry />
    <NewCalendarEvent />
  </>
);

export default RootModals;
