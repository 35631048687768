import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${(props) => props.theme?.palette?.backgroundFlat};
  padding: 5px;
  border-radius: 10px;
`;

export const PictureWrapper = styled.div`
  width: 100px;
`;

export const IndicatorsWrapper = styled.div`
  padding-left: 7px;
`;
