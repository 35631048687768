import store from 'store';

import {
  updateMatchingProperty as updateMatch,
  resetMatchingPropertyFilters,
} from 'store/actions/realtorMatchingProperties';

import {
  getClientById,
} from 'store/actions/realtorClients';

const updateMatchingProperty = (matchId, clientId) => async (updateData) => {
  await store.dispatch(updateMatch(matchId)(updateData));

  clientId && store.dispatch(getClientById(clientId));

  store.dispatch(resetMatchingPropertyFilters());
};

export default updateMatchingProperty;
