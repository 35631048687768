import { connect } from 'react-redux';
import {
  toDoListSetViewedAction,
  toDoListSettingsHideAction,
  todolistSaveSettingsAction,
  setInvitersGroup,
  setInvitersGroupSuccess,
  setInvitersGroupError,
} from 'store/actions/to-do-list';
import SettingsBody from './SettingsBody';

const mapStateToProps = (state) => ({
  employeeSettingsInf: state.todolist.employeeSettingsInf,
  employeeInvitersList: state.todolist.employeeInvitersList,
  employees: state.myCompany.employees,
  EmployeeID: state.auth.loggedUser.EmployeeID,
});
const mapDispatchToProps = {
  toDoListSetViewedAction,
  setInvitersGroup,
  setInvitersGroupSuccess,
  setInvitersGroupError,
  todolistSaveSettingsAction,
  toDoListSettingsHideAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsBody);
