import { connect } from 'react-redux';

import {
  openModal,
} from 'store/actions/modals';

import CommonTools from './CommonTools';

const mapStateToProps = (state) => {};

const mapDispatchToProps = (dispatch) => ({
  onNewEvent: () => {
    dispatch(openModal('newCalendarEvent')());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CommonTools);
