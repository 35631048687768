import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import SettingsHeader from './SettingsHeader';
import SettingsBody from './SettingsBody';
import './ToDoListSettings.scss';

const SETTINGS_WIDTH = 418;

const ToDoListSettings = (props) => {
  const {
    toDoListSettingsIsVisible,
  } = props;

  const headerJSX = (
    <SettingsHeader />
  );

  return (
    <Modal
      className="to-do-list-settings"
      title={headerJSX}
      visible={toDoListSettingsIsVisible}
      closable={false}
      footer={null}
      width={SETTINGS_WIDTH}
    >
      <SettingsBody />
    </Modal>
  );
};

const {
  bool,
} = PropTypes;

ToDoListSettings.propTypes = {
  toDoListSettingsIsVisible: bool.isRequired,
};

export default ToDoListSettings;
