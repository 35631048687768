import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { currency } from '@symplete/symplete-utils/numbers';

import { Tooltip } from 'antd';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';
import Icon from 'ui/Icon';
import useContactName from 'shared/hooks/contacts/useContactName';

import Counter from './Counter';

import {
  Wrapper,
  ImageContainer,
  Card,
} from './styledItems';

const OffersItem = (props) => {
  const {
    contact_id,
    address,
    price,
    opportunityType,
    isInMyListings,
    column,
    counters,
    image,
  } = props;

  const contactName = useContactName(contact_id);

  return (
    <Wrapper>
      <ImageContainer src={image}>
        {
          !image && (
            <Icon
              name="property"
              className="property-icon"
              type="custom"
              color="background-flat"
              size={60}
            />
          )
        }
      </ImageContainer>

      <Card>
        <Flex
          justifyContent="space-between"
        >
          <Tooltip title={address}>
            <Typography
              variant="title2"
              weight={500}
              ellipsis
              style={{ maxWidth: 380 }}
            >
              {address}
            </Typography>
          </Tooltip>

          <Typography
            variant="title2"
            weight={500}
            ellipsis
            style={{ maxWidth: 200 }}
          >
            {price && currency.full(price)}
          </Typography>
        </Flex>

        <Flex
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            noMargin
            ellipsis
            style={{ maxWidth: 380 }}
          >
            {
              contactName && (
                <>
                  Respresenting:&nbsp;
                  <Typography
                    tag="span"
                    color="highlight"
                    noMargin
                  >
                    {`${contactName} (${opportunityType})`}
                  </Typography>
                </>
              )
            }
          </Typography>

          <Icon
            name={isInMyListings ? 'star' : 'thumbs-up'}
            color="contrast"
            base="rounded"
            baseColor={isInMyListings ? 'selected' : '#884ada'}
            size="s"
          />
        </Flex>

        {
          counters.map(({ offerId, rootOfferId }) => (
            <Fragment key={offerId}>
              <Counter
                offerId={offerId}
                rootOfferId={rootOfferId}
                column={column}
              />
            </Fragment>
          ))
        }
      </Card>
    </Wrapper>
  );
};

OffersItem.defaultProps = {
  counters: [],
  isInMyListings: false,
  image: null,
};

const {
  string,
  number,
  oneOf,
  arrayOf,
  shape,
  bool,
} = PropTypes;

OffersItem.propTypes = {
  contact_id: number.isRequired,
  address: string.isRequired,
  price: number.isRequired,
  opportunityType: oneOf(['client', 'prospect']).isRequired,
  counters: arrayOf(shape({})),
  isInMyListings: bool,
  column: oneOf(['action', 'awaitingResponse']).isRequired,
  image: string,
};

export default OffersItem;
