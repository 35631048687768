import React from 'react';

import Options from 'sections/Communications/modules/Marketing/CampaignDetails/Options';

const Option = (value, record) => {
  return (
    <Options campaignId={record.id} onClick={(e) => e.stopPropagation()}/> 
  )
};

export default Option;
