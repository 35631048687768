import { connect } from 'react-redux';

import {
  openAssignInterestedPartyModal,
} from 'store/actions/realtorAssignInterestedPartyModal';

import ListingFocusView from './ListingFocusView';

const mapDispatchToProps = (dispatch) => ({
  onAssignInterestedPartyClick: () => {
    dispatch(openAssignInterestedPartyModal({}));
  },
});

export default connect(null, mapDispatchToProps)(ListingFocusView);
