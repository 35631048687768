import { connect } from 'react-redux';

import selectors from 'store/selectors';

import PropertyColumn from './PropertyColumn';

const mapStateToProps = (state, ownProps) => {
  const {
    propertyId,
    prospectId,
    clientId,
    initialPrice,
    finalPrice,
  } = ownProps;

  const images = selectors.properties.propertyImages(state, propertyId);

  const { address_id } = selectors.properties.byId(state, propertyId);

  const address = selectors.addresses.byId(state, address_id);

  const mainImageIndex = images.findIndex((image) => image.isMain);

  const image = images[mainImageIndex > 0 ? mainImageIndex : 0];

  let dealType;

  if (prospectId) {
    dealType = 'Buyer';
  } else if (clientId) {
    dealType = 'Seller';
  }

  return {
    image,
    address: address?.address,
    cost: finalPrice || initialPrice,
    dealType,
  };
};

export default connect(mapStateToProps)(PropertyColumn);
