import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import get from 'api/requests/realtor/leads/get';

import SaveAsFileButton from 'ui/SaveAsFileButton';

const ExportAs = (props) => {
  const {
    ids,
    variant,
  } = props;

  const text = useMemo(() => {
    if (!ids || ids.length < 1) {
      return 'Export all leads';
    }

    return `Export selected ${ids.length} ${ids.length > 1 ? 'items' : 'item'}`;
  }, [
    ids,
  ]);

  const query = useMemo(() => {
    if (!ids || ids.length < 1) {
      return {
        limit: 1e6,
      };
    }

    return { ids };
  }, [
    ids,
  ]);

  return (
    <SaveAsFileButton
      fetchRequest={get}
      query={query}
      extension={['csv', 'tsv']}
      fileName="Realtor_Leads"
      variant={variant}
    >
      { text }
    </SaveAsFileButton>
  );
};

const {
  arrayOf,
  number,
  string,
} = PropTypes;

ExportAs.defaultProps = {
  ids: null,
  variant: 'naked',
};

ExportAs.propTypes = {
  ids: arrayOf(number),
  variant: string,
};

export default ExportAs;
