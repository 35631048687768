import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateProperty,
} from 'store/actions/properties';

import PropertyParametersArea from 'sections/MLS/inputs/PropertyParametersArea';

const mapStateToProps = (state) => {
  const client = selectors.realtorClients.selected(state);
  const property = selectors.properties.byId(state, client.realtor_property_id);

  return {
    parameters: {
      bedrooms: property.bedrooms,
      bathrooms: property.bathrooms,
      floor_area: property.floor_area,
      plot_area: property.plot_area,
      parking: property.parking,
    },
    propertyId: client.realtor_property_id,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleChangeProperty: (key, value, propertyId) => {
    dispatch(updateProperty(propertyId)({ [key]: value }));
  },
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  handleChangeProperty: (key, value) => {
    dispatchProps.handleChangeProperty(key, value, stateProps.propertyId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PropertyParametersArea);
