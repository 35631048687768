import React, {
  useState,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import { NakedButton } from '@symplete/symplete-ui';

import { isEmpty } from 'shared/utility';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import Tooltip from 'ui/Tooltip';

import BulletedList from 'ui/BulletedList';

import EditModal from './EditModal';

const EditableList = (props) => {
  const {
    title,
    titleProps,
    modalTitle,
    items,
    onChange,
    onDelete,
    onCreate,
    ellipsis,
  } = props;

  let itemsToShow = items;
  let ellipsisItems = [];

  if (ellipsis && items) {
    itemsToShow = items.slice(0, ellipsis);
    ellipsisItems = items.slice(ellipsis);
  }

  const [isModalVisible, setIsModalVisible] = useState(false);

  const openModal = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  return (
    <>
      <NakedButton
        onClick={openModal}
        style={{ textAlign: 'left' }}
      >
        {
          title && (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Typography {...titleProps}>
              {title}
            </Typography>
          )
        }

        {
          isEmpty(items) ? (
            <Typography
              color="disabled"
              fontStyle="italic"
            >
              Click to add
            </Typography>
          ) : (
            <BulletedList
              content={
                itemsToShow.map((item, index) => {
                  if (index + 1 === itemsToShow.length && ellipsisItems.length) {
                    return (
                      <Flex spacing={1}>
                        <Typography
                          noMargin
                          ellipsis
                          style={{
                            maxWidth: 150,
                          }}
                        >
                          {item.title}
                        </Typography>
                        <Tooltip title={
                          ellipsisItems.map((ellipsisItem) => (
                            <Typography noMargin color="mainTextInversed">
                              {ellipsisItem.title}
                            </Typography>
                          ))
                        }
                        >
                          <Typography
                            noMargin
                            color="focus"
                          >
                            +
                            {ellipsisItems.length}
                            {' '}
                            more
                          </Typography>
                        </Tooltip>
                      </Flex>
                    );
                  }
                      return (
                        <Typography
                          noMargin
                          ellipsis
                          style={{
                            maxWidth: 150,
                          }}
                        >
                          {item.title}
                        </Typography>
                      );
                  })
              }
            />
          )
        }
      </NakedButton>

      <EditModal
        isVisible={isModalVisible}
        closeModal={closeModal}
        title={modalTitle}
        items={items}
        onChange={onChange}
        onDelete={onDelete}
        onCreate={onCreate}
      />
    </>
  );
};

EditableList.defaultProps = {
  items: [],
  title: '',
  modalTitle: '',
  titleProps: {
    variant: 'h4',
  },
  ellipsis: null,
};

const {
  arrayOf,
  shape,
  string,
  number,
  func,
} = PropTypes;

EditableList.propTypes = {
  items: arrayOf(shape({
    id: number,
    title: string,
    description: string,
  })),
  title: string,
  modalTitle: string,
  titleProps: shape(),
  onChange: func.isRequired,
  onDelete: func.isRequired,
  onCreate: func.isRequired,
  ellipsis: number,
};

export default EditableList;
