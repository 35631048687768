import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';

import Elevation from 'ui/Elevation';
import Flex from 'ui/Flex';
import Tooltip from 'ui/Tooltip';
import Typography from 'ui/Typography';

const QuoteIcon = ({ ...props }) => (
  <svg
    {...props}
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="7.5"
      cy="7.5"
      r="6.5"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.3066 4.76378C5.05762 5.25676 5.25542 5.85824 5.7484 6.10722C6.24138 6.3562 6.84286 6.1584 7.09184 5.66542L5.3066 4.76378ZM7.49922 7.14315L6.94838 6.30854C6.66795 6.49362 6.49922 6.80716 6.49922 7.14315H7.49922ZM6.49922 8.15C6.49922 8.70228 6.94693 9.15 7.49922 9.15C8.0515 9.15 8.49922 8.70228 8.49922 8.15H6.49922ZM8.49922 10.425C8.49922 9.87271 8.0515 9.425 7.49922 9.425C6.94693 9.425 6.49922 9.87271 6.49922 10.425H8.49922ZM6.49922 10.75C6.49922 11.3023 6.94693 11.75 7.49922 11.75C8.0515 11.75 8.49922 11.3023 8.49922 10.75H6.49922ZM7.09184 5.66542C7.23341 5.3851 7.31651 5.29998 7.34782 5.27521C7.35642 5.26841 7.36052 5.2664 7.36917 5.26366C7.38119 5.25986 7.41907 5.25 7.49922 5.25V3.25C6.99059 3.25 6.51668 3.38252 6.10687 3.70676C5.73192 4.00342 5.49003 4.4006 5.3066 4.76378L7.09184 5.66542ZM7.49922 5.25C7.62153 5.25 7.68237 5.29155 7.72001 5.33209C7.76904 5.38491 7.79922 5.46209 7.79922 5.5357H9.79922C9.79922 4.41203 8.93025 3.25 7.49922 3.25V5.25ZM7.79922 5.5357C7.79922 5.61058 7.79 5.63535 7.78936 5.63717C7.7886 5.63935 7.78716 5.6431 7.78301 5.65016C7.77856 5.65773 7.76904 5.67237 7.75012 5.69482C7.65242 5.81077 7.44501 5.98077 6.94838 6.30854L8.05006 7.97776C8.52843 7.66203 8.97101 7.34973 9.27957 6.98354C9.64664 6.5479 9.79922 6.07362 9.79922 5.5357H7.79922ZM6.49922 7.14315V8.15H8.49922V7.14315H6.49922ZM6.49922 10.425V10.75H8.49922V10.425H6.49922Z"
      fill="currentColor"
    />
  </svg>
);

const PropertyPromote = ({ isMyListing, propertyId }) => (
  <Elevation rounded="s" fullHeight>
    <Flex fullWidth flexDirection="column" alignItems="center" style={{ padding: '10px', minHeight: '350px' }}>
      <Flex fullWidth justifyContent="space-between" alignItems="center">
        <Typography tag="h2" variant="title2" style={{ margin: 0, color: '#23314A', fontWeight: 700 }}>
          Promote Property
        </Typography>
        <Tooltip title="Promote Property">
          <QuoteIcon style={{ color: 'black' }} />
        </Tooltip>
      </Flex>
      <Flex
        fullWidth
        justifyContent="space-between"
        alignItems="center"
        className="property-promote-switch"
        style={{ padding: '18px 0' }}
      >
        <Typography tag="p" variant="caption" style={{ margin: 0, fontSize: '15px' }}>
          On website:
        </Typography>
        <Switch defaultChecked />
      </Flex>
      <Flex fullWidth justifyContent="center">
        <Typography tag="h2" variant="title2">
          Coming Soon
        </Typography>
      </Flex>
    </Flex>
  </Elevation>
  );

PropertyPromote.defaultProps = {
  isMyListing: false,
};

const { bool, number } = PropTypes;

PropertyPromote.propTypes = {
  isMyListing: bool,
  propertyId: number.isRequired,
};

export default PropertyPromote;
