import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  getTransactionById,
} from 'store/actions/realtorTransactions';

import requests from 'api/requests';

import { REALTOR_ROLES } from 'constants/realtor.constants';

import Players from 'shared/modules/Players';

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

const mapStateToProps = (state, ownProps) => {
  const {
    transactionId,
    playersRoles,
  } = ownProps;

  const transaction = selectors.realtorTransactions.byId(state, transactionId);

  const playersData = playersRoles.reduce((accumulator, { role, key }) => {
    if (Array.isArray(transaction[key])) {
      transaction[key].forEach((contactId) => {
        accumulator.push({
          key: `${key}-${contactId}`,
          role,
          contactId,
        });
      });
    } else {
      accumulator.push({
        key,
        role,
      });
    }

    return accumulator;
  }, []);

  return {
    playersData,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAdd: async (contactId, role) => {
    const roleId = (typeof role === 'string') ? getKeyByValue(REALTOR_ROLES, role) : role;
    try {
      await requests.realtor.transactions.addRelations('contacts')(ownProps.transactionId)({ contactId, roleId });
    } catch (error) {
      return;
    }

    dispatch(getTransactionById(ownProps.transactionId));
  },
  onRemove: async (contactId, role) => {
    const roleId = getKeyByValue(REALTOR_ROLES, role);

    try {
      await requests.realtor.transactions.removeRelations('contacts')(ownProps.transactionId)({ contactId, roleId });
    } catch (error) {
      return;
    }

    dispatch(getTransactionById(ownProps.transactionId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Players);
