import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  optimizedClientActions,
//   setSelectedPropertyRows,
} from 'store/actions/realtorClients';

import {
  setFilterItem,
} from 'store/actions/realtorClientsFilter';

import ProspectsTable from './ProspectsTable';

const mapStateToProps = (state) => {
  const {
    realtorClients: {
      realtorClientsByFilterCount,
    },
    realtorClientsFilter: {
      pageNumber,
      pageSize,
      // sortBy,
      // sortDirection,
    },
  } = state;

  const {
    filterId,
  } = selectors.realtorClients.clientsFilter(state);

  const itemsList = selectors.realtorClients.clientsList(state, filterId);

  return {
    itemsCount: realtorClientsByFilterCount[filterId],
    itemsList,
    pageNumber,
    pageSize,
    sortBy: 'id',
    sortDirection: 'desc',
  };
};

const mapDispatchToProps = (dispatch) => ({
  getItems: (page, pageSize) => {
    const offset = (page - 1) * pageSize;
    const limit = pageSize;

    optimizedClientActions.getClientsForStoreFilter(offset, limit);
  },
  setFilterItem: (key) => (value) => {
    dispatch(setFilterItem(key)(value));
  },
  onRowSelect: (selectedIds) => {
    // dispatch(setSelectedPropertyRows(selectedIds));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProspectsTable);
