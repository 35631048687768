import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';
import useModal from 'ui/hooks/useModal';

import CampaignSampleMessageModal from 'sections/Communications/modals/CampaignSampleMessageModal';

const SampleMessage = (props) => {
  const {
    campaignId,
  } = props;

  const {
    isModalVisible,
    openModal,
    closeModal,
  } = useModal();

  return (
    <>
      <Button
        variant="secondary"
        onClick={openModal}
        icon="eye"
      />

      <CampaignSampleMessageModal
        isVisible={isModalVisible}
        closeModal={closeModal}
        campaignId={campaignId}
      />
    </>
  );
};

const {
  number,
} = PropTypes;

SampleMessage.propTypes = {
  campaignId: number.isRequired,
};

export default SampleMessage;
