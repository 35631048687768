import React from 'react';

import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import LeadsList from 'sections/Leads/pages/LeadsList';
import Lead from 'sections/Leads/pages/Lead';
import LeadV2 from 'sections/Leads/pages/LeadV2';

import routes from 'sections/Leads/routes';

import getFeatureVersion from 'shared/utils/getFeatureVersion';

const leadPageVersion = getFeatureVersion('lead page');

const Leads = () => (
  <Switch>
    <Route
      exact
      path={routes.sectionRoot}
      component={LeadsList}
    />
    <Route
      path={routes.lead}
      component={leadPageVersion === 1 ? Lead : LeadV2}
    />

    <Redirect to={routes.sectionRoot} />
  </Switch>
  );

export default Leads;
