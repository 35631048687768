import { connect } from 'react-redux';

import MoveOutDate from './MoveOutDate';

const mapStateToProps = (state) => {
  const {

  } = state;

  return {

  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(MoveOutDate);
