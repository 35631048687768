import React from 'react';
import PropTypes from 'prop-types';

import { NakedButton } from '@symplete/symplete-ui';

import Grid from 'ui/Grid';
import Typography from 'ui/Typography';

import Avatar from './Avatar';

import {
  Card,
  InlineWrapper,
} from './styledItems';

const ContactItem = (props) => {
  const {
    contactId,
    firstName,
    lastName,
    role,
    oneContactSelect,
    isEmptyContactSelector,
    isSelected,
    onAdd,
    onRemove,
    error,
  } = props;

  const selectedButton = isSelected ? (
    <InlineWrapper>
      <Typography
        variant="body2"
        color="descriptor-text"
        noMargin
      >
        {
          role
        }
      </Typography>
      <NakedButton
        onClick={onRemove}
      >
        <Typography
          variant="body2"
          color="selected"
          noMargin
        >
          added
        </Typography>
      </NakedButton>
    </InlineWrapper>
  ) : (
    <Grid
      item
      container
      justify="flex-end"
    >
      <Typography
        variant="body2"
        noMargin
      >
        Contact selected
      </Typography>
    </Grid>
    );

  const oneContactStatusButtons = isEmptyContactSelector ? (
    <InlineWrapper>
      <Typography
        variant="body2"
        color="descriptor-text"
        noMargin
      >
        {
          role
        }
      </Typography>
      <NakedButton
        onClick={onAdd}
      >
        <Typography
          variant="body2"
          color="link"
          noMargin
        >
          + add
        </Typography>
      </NakedButton>
    </InlineWrapper>
  ) : selectedButton;

  return (
    <Card
      error={error}
      isSelected={isSelected}
    >
      <Grid
        container
        wrap="nowrap"
        spacing={1}
      >
        <Grid item>
          <Avatar contactId={contactId} />
        </Grid>

        <Grid
          item
          container
          direction="column"
          justify="space-around"
        >
          <Grid item>
            <Typography
              ellipsis
              variant="body2"
              weight={500}
              noMargin
              style={{ maxWidth: 100 }}
            >
              {`${firstName || ''} ${lastName || ''}`}
            </Typography>
          </Grid>

          {
            (oneContactSelect) ? oneContactStatusButtons : (
              <InlineWrapper>
                <Typography
                  variant="body2"
                  color="descriptor-text"
                  noMargin
                >
                  {
                    role
                  }
                </Typography>
                <NakedButton
                  onClick={isSelected ? onRemove : onAdd}
                >
                  <Typography
                    variant="body2"
                    color={isSelected ? 'selected' : 'link'}
                    noMargin
                  >
                    {
                      isSelected ? 'added' : '+ add'
                    }
                  </Typography>
                </NakedButton>
              </InlineWrapper>
            )
          }
        </Grid>
      </Grid>
    </Card>
  );
};

ContactItem.defaultProps = {
  isSelected: false,
};

const {
  number,
  string,
  bool,
  func,
} = PropTypes;

ContactItem.propTypes = {
  error: bool.isRequired,
  role: string.isRequired,
  contactId: number.isRequired,
  firstName: string.isRequired,
  lastName: string.isRequired,
  oneContactSelect: bool.isRequired,
  isEmptyContactSelector: bool.isRequired,
  isSelected: bool,
  onAdd: func.isRequired,
  onRemove: func.isRequired,
};

export default ContactItem;
