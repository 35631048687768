import { connect } from 'react-redux';

import {
  updateInspection,
} from 'store/actions/realtorInspections';

import {
  getTransactionById,
} from 'store/actions/realtorTransactions';

import SelectCell from 'shared/components/UI/TableCells/SelectCell';

const options = [
  {
    id: 6,
    name: 'completed',
  },
  {
    id: 5,
    name: 'pending',
  },
];

const valuesToColors = {
  6: '#71c59b',
  5: '#97a0ab',
};

const mapStateToProps = (state, ownProps) => {
  const {
    status,
  } = ownProps;

  return {
    options,
    value: status,
    valuesToColors,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: async (value) => {
    await dispatch(updateInspection(ownProps.inspectionId)({ status: value }));
    dispatch(getTransactionById(ownProps.transactionId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectCell);
