import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateContact,
} from 'store/actions/realtorContacts';

import ContactDescription from './ContactDescription';

const mapStateToProps = (state, ownProps) => {
  const {
    contactId,
  } = ownProps;

  const contact = selectors.realtorContacts.byId(state, contactId);

  return {
    content: contact?.description,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (content) => { dispatch(updateContact(ownProps.contactId)({ description: content })); },
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactDescription);
