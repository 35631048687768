import React, {
  useContext,
  useState,
  useCallback,
} from 'react';

import Icon from 'ui/Icon';
import Tooltip from 'ui/Tooltip';
import Typography from 'ui/Typography';
import DropdownRange from 'ui/DropdownRange';
import Flex from 'ui/Flex';

import OpportunitiesContext from 'sections/OpportunitiesListing/context/OpportunitiesContext';

import SymScoreTip from './SymScoreTip';

const options = [4, 5, 6, 7, 8, 9, 10];

const SymScoreRange = () => {
  const { setSymScoreRange } = useContext(OpportunitiesContext);

  const [value, setValue] = useState();

  const onSymScoreRangeChange = useCallback((newValue) => {
    setSymScoreRange(newValue);
    setValue(newValue);
  }, [
    setSymScoreRange,
  ]);

  return (
    <Flex
      spacing={2}
      alignItems="center"
    >
      <Tooltip title={<SymScoreTip />}>
        <Flex spacing={2}>
          <Icon
            size="m"
            color="main-text"
            name="help-circle"
          />
          <Typography
            variant="title2"
            tag="p"
            noMargin
            weight="bold"
          >
            SymScore
          </Typography>
        </Flex>
      </Tooltip>
      <DropdownRange
        value={value}
        onChange={onSymScoreRangeChange}
        minOptions={options}
        maxOptions={options}
        size="s"
      />
    </Flex>
  );
};

export default SymScoreRange;
