import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateSelectedClient,
} from 'store/actions/realtorClients';

const withSelectedClient = (Component) => {
  const mapStateToProps = (state) => {
    const client = selectors.realtorClients.selected(state);

    return {
      client,
    };
  };

  const mapDispatchToProps = (dispatch) => ({
    onChange: (fieldName) => (value) => {
      dispatch(updateSelectedClient({ [fieldName]: value }));
    },
  });

  return connect(mapStateToProps, mapDispatchToProps)(Component);
};

export default withSelectedClient;
