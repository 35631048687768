/* eslint-disable react/jsx-no-bind */
import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import OptionsMenu from 'ui/OptionsMenu';
import MenuItem from 'ui/MenuItem';

const OptionsCell = (props) => {
  const {
    bookShowing,
    archiveProperty,
  } = props;

  const handleClickBookShowing = useCallback((closeMenu) => (event) => {
    event.stopPropagation();
    bookShowing(event);
    closeMenu();
  }, [
    bookShowing,
  ]);

  const handleClickArchiveProperty = useCallback((closeMenu) => (event) => {
    event.stopPropagation();
    archiveProperty(event);
    closeMenu();
  }, [
    archiveProperty,
  ]);

  return (
    <OptionsMenu>
      {
        ({ closeMenu }) => (
          <>
            <MenuItem onClick={handleClickBookShowing(closeMenu)}>
              Book Showing
            </MenuItem>
            <MenuItem onClick={handleClickArchiveProperty(closeMenu)}>
              Archive
            </MenuItem>
          </>
        )
      }
    </OptionsMenu>
  );
};

const {
  func,
} = PropTypes;

OptionsCell.propTypes = {
  bookShowing: func.isRequired,
  archiveProperty: func.isRequired,
};

export default OptionsCell;
