import React from 'react';
import PropTypes from 'prop-types';

import AssignContactModal from 'sections/Contacts/modals/AssignContactModal';

const maxSelectedContactsQty = process.env.REACT_APP_MAX_SELECTED_CONTACTS_QTY || 500;

const selectedContactsQtyWarningDescription = `
  Sending bulk emails to more than ${maxSelectedContactsQty} contacts per day may result in your email address
  being permanently blacklisted by spam filters.
`;

const AssignRecipients = (props) => {
  const {
    isVisible,
    closeModal,
    onSave,
    selectedContacts,
  } = props;

  return (
    <AssignContactModal
      title="Select contacts to send this email to"
      isVisible={isVisible}
      closeModal={closeModal}
      onSave={onSave}
      withAddContact
      selectedContacts={selectedContacts}
      selectedContactsQtyWarningDescription={selectedContactsQtyWarningDescription}
    />
  );
};

AssignRecipients.defaultProps = {
  isVisible: false,
  selectedContacts: [],
};

const {
  arrayOf,
  bool,
  func,
  number,
} = PropTypes;

AssignRecipients.propTypes = {
  isVisible: bool,
  closeModal: func.isRequired,
  onSave: func.isRequired,
  selectedContacts: arrayOf(number),
};

export default AssignRecipients;
