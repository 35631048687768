import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  setSelectedLeadProperty,
} from 'store/actions/realtorLeads';

import ObjectParameters from './ObjectParameters';

const mapStateToProps = (state) => {
  const selectedProperty = selectors.realtorLeads.selectedLeadProperty(state);

  return {
    selectedProperty,
    leadItems: [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSelectedProperty: (propertyData) => {
    dispatch(setSelectedLeadProperty(propertyData));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ObjectParameters);
