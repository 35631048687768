import React from 'react';
import PropTypes from 'prop-types';

import SingleSelect from './SingleSelect';
import RangeSelect from './RangeSelect';

const NumberOfStoriesSelect = (props) => {
  const {
    handleChange,
    handleChangeMin,
    handleChangeMax,
    value,
    minValue,
    maxValue,
    rangeSelect,
  } = props;

  if (rangeSelect) {
    return (
      <RangeSelect
        minValue={minValue}
        maxValue={maxValue}
        handleChangeMin={handleChangeMin}
        handleChangeMax={handleChangeMax}
      />
    );
  }

  return <SingleSelect value={value} handleChange={handleChange} />;
};

NumberOfStoriesSelect.defaultProps = {
  value: null,
  minValue: null,
  maxValue: null,
  rangeSelect: false,
  handleChangeMin: () => {},
  handleChangeMax: () => {},
  handleChange: () => {},
};

const {
  func,
  number,
  bool,
} = PropTypes;

NumberOfStoriesSelect.propTypes = {
  handleChange: func,
  handleChangeMin: func,
  handleChangeMax: func,
  value: number,
  rangeSelect: bool,
  minValue: number,
  maxValue: number,
};

export default NumberOfStoriesSelect;
