import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateInspection,
  closeInspectionNotesModal,
} from 'store/actions/realtorInspections';

import InspectionNotesModal from './InspectionNotesModal';

const mapStateToProps = (state) => {
  const {
    realtorInspections: {
      selectedInspection,
      isInspectionNotesModalVisible,
    },
  } = state;

  const inspection = selectors.realtorInspections.byId(selectedInspection)(state);

  return {
    isVisible: isInspectionNotesModalVisible,
    content: inspection?.notes,
    inspectionId: selectedInspection,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => {
    dispatch(closeInspectionNotesModal());
  },
  onNoteChange: (content, inspectionId) => {
    dispatch(updateInspection(inspectionId)({ notes: content }));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onNoteChange: (content) => {
    dispatchProps.onNoteChange(content, stateProps.inspectionId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(InspectionNotesModal);
