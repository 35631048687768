import React from 'react';

const SettingsHeader = () => (
  <div className="settings-header">
    <span>To Do Settings</span>
  </div>
);


export default SettingsHeader;
