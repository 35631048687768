import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import { NakedButton } from '@symplete/symplete-ui';

import Typography from 'ui/Typography';
import MenuItem from 'ui/MenuItem';

import useMenu from 'ui/hooks/useMenu';

const MailboxSelector = (props) => {
  const {
    onChange,
    Root,
  } = props;

  const anchorEl = useRef(null);

  const {
    openMenu,
    closeMenu,
    Menu,
  } = useMenu(anchorEl);

  const [selectedBox, setSelectedBox] = useState(null);

  useEffect(() => {
    setSelectedBox('inbox');
    onChange('inbox');
  }, [
    onChange,
  ]);

  const onEmailSelect = useCallback((mailbox) => () => {
    setSelectedBox(mailbox);
    onChange(mailbox);
    closeMenu();
  }, [
    onChange,
    closeMenu,
  ]);

  return (
    <>
      {
        Root && (
          <Root
            anchorRef={anchorEl}
            openMenu={openMenu}
            selectedBox={selectedBox}
          />
        )
      }
      {
        !Root && (
          <NakedButton
            ref={anchorEl}
            onClick={openMenu}
            style={{
              display: 'inline-block',
            }}
          >
            <Typography
              tag="span"
              textTransform="capitalize"
            >
              {selectedBox}
            </Typography>
          </NakedButton>
        )
      }
      <Menu>
        <MenuItem
          onClick={onEmailSelect('inbox')}
        >
          Inbox
        </MenuItem>
        <MenuItem
          onClick={onEmailSelect('sent')}
        >
          Sent
        </MenuItem>
      </Menu>
    </>
  );
};

MailboxSelector.defaultProps = {
  Root: null,
};

const {
  func,
} = PropTypes;

MailboxSelector.propTypes = {
  onChange: func.isRequired,
  Root: func,
};

export default MailboxSelector;
