import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { SALE_TYPES } from 'constants/realtor.constants';

import MultiselectTextfield from 'ui/MultiselectTextfield';

const PropertyMultiTypeSelect = (props) => {
  const {
    handleChange,
    value,
    defaultView,
  } = props;

  const selectedValue = Array.isArray(value)
    ? value.map((key) => ({
        value: `${key}`,
        label: SALE_TYPES[key],
      }))
    : undefined;

  const onChange = useCallback((newValue) => {
    handleChange(newValue.map((item) => item.value));
  }, [
    handleChange,
  ]);

  return (
    <MultiselectTextfield
      label="Sale Type"
      onChange={onChange}
      options={SALE_TYPES}
      value={selectedValue}
      defaultView={defaultView}
    />
  );
};

PropertyMultiTypeSelect.defaultProps = {
  value: [],
};

const {
  func,
  arrayOf,
  number,
  oneOf,
} = PropTypes;

PropertyMultiTypeSelect.propTypes = {
  handleChange: func.isRequired,
  value: arrayOf(number),
  defaultView: oneOf(['edit', 'display']),
};

export default PropertyMultiTypeSelect;
