import React from 'react';

import { datetime } from '@symplete/symplete-utils';
import { between } from '@symplete/symplete-utils/datetime';
import { currency } from '@symplete/symplete-utils/numbers';

import { FINANCING_TYPES } from 'constants/realtor.constants';

import Typography from 'ui/Typography';
import Options from './Options';

const columns = () => [
    {
      width: '15%',
      dataIndex: 'amount',
      key: 'amount',
      render: (value, entry) => (value ? (
        <Typography
          variant="cell"
        >
          {currency.short(value)}
        </Typography>
      ) : '-'),
    },
    {
      width: '10%',
      dataIndex: 'closing_date',
      key: 'closing',
      render: (value, entry) => (value ? (
        <Typography
          variant="cell"
        >
          {`${between(datetime(), value)} Days`}
        </Typography>
      ) : '-'),
    },
    {
      width: '10%',
      dataIndex: 'financing_type',
      key: 'financing',
      render: (value, entry) => (value ? (
        <Typography
          variant="cell"
        >
          {FINANCING_TYPES[value]}
        </Typography>
      ) : '-'),
    },
    {
      width: '20%',
      key: 'contigencies',
      render: (value, entry) => (entry ? (
        <Typography
          variant="cell"
        >
          {`${entry.contingency_inspection ? 'Inspection,' : ''}
            ${entry.contingency_loan ? 'Loan,' : ''}
            ${entry.contingency_appraisal ? 'Appraisal' : ''}
            ${between(datetime(), entry.contingency_period_end)} Days
          `}
        </Typography>
      ) : '-'),
    },
    {
      width: '20%',
      key: 'other_party',
      render: (value, entry) => '-',
    },
    {
      width: '10%',
      dataIndex: 'expiry_date',
      key: 'expiry',
      render: (value, entry) => '-',
    },
    {
      width: '10%',
      key: 'agreement',
      render: (value, entry) => '-',
    },
    {
      title: null,
      dataIndex: 'id',
      key: 'options',
      width: '5%',
      render: (value, entry) => (
        <Options
          offerId={value}
          rootOfferId={entry.parent_offer_id}
          propertyId={entry.realtor_property_id}
          offerType={entry.type}
          totalCounters={entry.items?.length}
          offerData={entry}
        />
      ),
    },
  ];

export default columns;
