import { connect } from 'react-redux';

import {
  updateProperty,
} from 'store/actions/properties';

import {
  openNewPropertyViewingModal,
} from 'store/actions/newRealtorPropertyViewingModal';

import ActionButtons from './ActionButtons';

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    propertyId,
  } = ownProps;

  return {
    onClickBook: (event) => {
      event.stopPropagation();
      dispatch(openNewPropertyViewingModal(propertyId));
    },
    onClickMatch: (event) => {
      event.stopPropagation();
    },
    onClickFavorite: (event) => {
      event.stopPropagation();
    },
    onClickArchive: (event) => {
      event.stopPropagation();
      dispatch(updateProperty(propertyId)({
        status: 2,
      }));
    },
  };
};

export default connect(null, mapDispatchToProps)(ActionButtons);
