import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import Typography from 'ui/Typography';

import StageItemLayout from 'sections/Transactions/components/StageItemLayout';

import TransactionStageNotes from 'sections/Transactions/modules/TransactionStageNotes';

import AttachmentsModule from 'shared/AttachmentsV3';

import AddressInput from 'shared/inputs/AddressInput';
import DateInput from 'shared/inputs/DateInput';
import ContactSelect from 'shared/inputs/ContactSelect';

const CloseEscrow = (props) => {
  const {
    transactionId,

    onChange,
    onUserChange,

    escrowAgentContactId,
    titleAgentContactId,
    lawyerContactId,
    signingAddressId,
    signingDate,

    attorneyOrEscrow,

    attachments,
    refreshAttachments,
  } = props;

  const documentsComponent = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h3" noMargin>
          Details
        </Typography>
      </Grid>

      {
        attorneyOrEscrow === 'escrow' ? (
          <>
            <Grid item xs={6}>
              <ContactSelect
                label="Escrow Agent"
                contactId={escrowAgentContactId}
                onChange={onUserChange('escrow agent')}
                useId
              />
            </Grid>
            <Grid item xs={6}>
              <ContactSelect
                label="Title Agent"
                contactId={titleAgentContactId}
                onChange={onUserChange('title agent')}
                useId
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={6}>
            <ContactSelect
              label="Lawyer"
              contactId={lawyerContactId}
              onChange={onUserChange('lawyer')}
              useId
            />
          </Grid>
        )
      }

      <Grid item xs={8}>
        <AddressInput
          label="Signing Address"
          addressId={signingAddressId}
          handleChange={onChange('escrow_signing_address_id')}
          useId
        />
      </Grid>
      <Grid item xs={4}>
        <DateInput
          label="Signing Date"
          value={signingDate}
          handleChange={onChange('escrow_signing_date')}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3" noMargin>
          Final documentation
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <AttachmentsModule
          selectedAttachments={attachments}
          objectType={41}
          rice
          objectId={transactionId}
          attachmentType={18}
          onAfterUpload={refreshAttachments}
          onAfterDelete={refreshAttachments}
        />
      </Grid>
    </Grid>
  );

  const detailsComponent = (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3">
          Notes
        </Typography>
        <TransactionStageNotes
          transactionId={transactionId}
          stage="close escrow"
        />
      </Grid>
    </Grid>
  );

  return (
    <StageItemLayout
      documentsComponent={documentsComponent}
      detailsComponent={detailsComponent}
    />
  );
};

CloseEscrow.defaultProps = {
  escrowAgentContactId: null,
  titleAgentContactId: null,
  lawyerContactId: null,
  signingAddressId: null,
  signingDate: null,
  attachments: [],
};

const {
  arrayOf,
  func,
  number,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

CloseEscrow.propTypes = {
  transactionId: oneOfType([string, number]).isRequired,
  onChange: func.isRequired,
  onUserChange: func.isRequired,
  escrowAgentContactId: number,
  titleAgentContactId: number,
  lawyerContactId: number,
  signingAddressId: number,
  signingDate: string,
  attorneyOrEscrow: oneOf(['escrow', 'attorney']).isRequired,
  attachments: arrayOf(shape({})),
  refreshAttachments: func.isRequired,
};

export default CloseEscrow;
