import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { pretty } from '@symplete/symplete-utils/numbers';

import usePropertyData from 'sections/MLS/hooks/usePropertyData';

import PropertyMainImage from 'sections/Properties/modules/PropertyMainImage';
import PropertyParametersIndicators from 'shared/components/UI/PropertyParameters/PropertyParametersIndicators/';
import Tooltip from 'ui/Tooltip';
import Flex from 'ui/Flex';
import Typography from 'ui/Typography';
import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';

import {
  Wrapper,
  PictureWrapper,
  IndicatorsWrapper,
} from './styledItems';

const PropertyBriefInfo = (props) => {
  const {
    propertyId,
  } = props;

  const propertyData = usePropertyData(propertyId);

  const address = propertyData?.address?.address;

  const price = useMemo(() => {
    if (!propertyData?.expected_price) return null;

    return pretty.dec(propertyData?.expected_price);
  }, [
    propertyData?.expected_price,
  ]);

  return (
    <Wrapper>
      <Flex
        spacing={0}
        alignItems="center"
      >
        <PictureWrapper>
          <PropertyMainImage
            propertyId={propertyId}
            height="85px"
          />
        </PictureWrapper>
        <Flex
          flexDirection="column"
          spacing={1}
        >
          <DecoratedFieldWrapper
            iconName="map-pin"
            iconType="feather"
            variant="focus"
            size="s"
          >
            <Tooltip
              title={address || 'No address'}
            >
              <Typography
                variant="title3"
                weight="bold"
                ellipsis
                noMargin
              >
                {address || 'No address'}
              </Typography>
            </Tooltip>
          </DecoratedFieldWrapper>
          <DecoratedFieldWrapper
            iconName="dollar-sign"
            iconType="feather"
            variant="focus"
            size="s"
          >
            <Typography
              variant="title3"
              weight="bold"
              noMargin
            >
              {price || 'N/A'}
            </Typography>
          </DecoratedFieldWrapper>

          <IndicatorsWrapper>
            <PropertyParametersIndicators
              showDescription={false}
              indicatorProps={{ iconSize: 's' }}
              data={{
                bedrooms: propertyData?.bedrooms,
                bathrooms: propertyData?.bathrooms,
                floorArea: propertyData?.floor_area,
                plotArea: propertyData?.plot_area,
              }}
            />
          </IndicatorsWrapper>
        </Flex>
      </Flex>
    </Wrapper>
  );
};

const { number } = PropTypes;

PropertyBriefInfo.propTypes = {
  propertyId: number.isRequired,
};

export default PropertyBriefInfo;
