import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';

const PageTitle = (props) => {
  const {
    currentSection,
    currentObject,
    currentObjectId,
  } = props;

  if (currentObject?.objectName) {
    return (
      <Flex
        alignItems="center"
        spacing={1}
      >
        <Typography
          variant="title1"
          color="contrast"
          noMargin
          style={{ maxWidth: 450 }}
          ellipsis
        >
          {currentObject.objectName}
        </Typography>
        <Typography
          variant="title1"
          color="contrast"
          noMargin
        >
          {`— ${currentObject.objectType} ID ${currentObjectId}`}
        </Typography>
      </Flex>
    );
  }

  return (
    <Flex alignItems="center">
      <Typography
        variant="title1"
        color="contrast"
        noMargin
      >
        {currentSection}
      </Typography>
    </Flex>
  );
};

PageTitle.defaultProps = {
  currentSection: '',
  currentObject: null,
  currentObjectId: null,
};

const {
  string,
  shape,
} = PropTypes;

PageTitle.propTypes = {
  currentSection: string,
  currentObjectId: string,
  currentObject: shape({
    objectName: string,
    objectType: string,
  }),
};

export default PageTitle;
