import { connect } from 'react-redux';
import {
  toDoListDrawerHideAction,
  changeNumberInListAction,
  toDoListSetViewedAction,
} from 'store/actions/to-do-list';

import ToDoList from './ToDoList';

const mapStateToProps = (state) => {
  const {
    todolist,
  } = state;

  return {
    todolist,
  };
};

const mapDispatchToProps = {
  toDoListDrawerHideAction,
  changeNumberInListAction,
  toDoListSetViewedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ToDoList);
