import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundary from 'ui/ErrorBoundary';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Typography from 'ui/Typography';

import PropertyPriceInput from 'sections/Properties/modules/PropertyPriceInput';
import PropertyTypeSelect from 'sections/Properties/modules/PropertyTypeSelect';
import PropertySaleTypeSelect from 'sections/Properties/modules/PropertySaleTypeSelect';
import PropertyAddressInput from 'sections/Properties/modules/PropertyAddressInput';
import PropertyParametersMain from 'sections/Properties/modules/PropertyParametersMain';
import PropertyDetailsFullExpandable from 'sections/Properties/modules/PropertyDetailsFullExpandable';

import AdditionalAmenities from 'shared/modules/AdditionalAmenities';

import PropertyNotablePoints from 'sections/Properties/modules/PropertyNotablePoints';
import PropertyDisclosures from 'sections/Properties/modules/PropertyDisclosures';

const SellerProperty = (props) => {
  const {
    propertyId,
  } = props;

  return (
    <ErrorBoundary>
      <Flex
        container
        spacing={4}
        alignItems="stretch"
        flexWrap="wrap"
      >
        <FlexItem
          fullWidth
        >
          <Flex
            fullWidth
            fullHeight
            flexDirection="column"
            justifyContent="space-between"
          >
            <Flex
              fullWidth
              flexWrap="wrap"
              spacing={4}
              alignItems="center"
            >
              <Flex
                fullWidth
                spacing={4}
              >
                <FlexItem flex={1}>
                  <PropertyPriceInput
                    propertyId={propertyId}
                    defaultView="edit"
                    label="Proposed list price"
                  />
                </FlexItem>
                <FlexItem flex={1}>
                  <PropertyPriceInput
                    propertyId={propertyId}
                    defaultView="edit"
                    priceType="price_avn"
                    label="Market $AVN"
                  />
                </FlexItem>
                <FlexItem flex={1}>
                  <PropertyPriceInput
                    propertyId={propertyId}
                    defaultView="edit"
                    priceType="price_ideal"
                    label="Seller ideal list price"
                  />
                </FlexItem>
              </Flex>

              <FlexItem xs={18}>
                <PropertyAddressInput
                  propertyId={propertyId}
                  defaultView="edit"
                  placeholder="Enter seller property address"
                />
              </FlexItem>

              <FlexItem xs={6}>
                <PropertySaleTypeSelect
                  propertyId={propertyId}
                />
              </FlexItem>

              <FlexItem xs={6}>
                <PropertyTypeSelect
                  propertyId={propertyId}
                />
              </FlexItem>

              <FlexItem xs={18}>
                <PropertyParametersMain
                  propertyId={propertyId}
                  defaultView="edit"
                />
              </FlexItem>
            </Flex>

          </Flex>
        </FlexItem>

        <Flex
          fullWidth
          spacing={4}
          flexWrap="wrap"
        >
          {/* <FlexItem
            fullWidth
          >
            <Typography
              variant="title2"
            >
              Property Details
            </Typography>
            <PropertyDetails
              propertyId={propertyId}
              columns={3}
            />
          </FlexItem> */}

          <Flex
            fullWidth
            spacing={2}
          >
            <FlexItem flex={1}>
              <Typography
                variant="title3"
              >
                Notable Selling Points
              </Typography>
              <PropertyNotablePoints
                propertyId={propertyId}
                ellipsis={1}
              />
            </FlexItem>
            <FlexItem flex={1}>
              <Typography
                variant="title3"
              >
                Disclosures
              </Typography>
              <PropertyDisclosures
                propertyId={propertyId}
                ellipsis={1}
              />
            </FlexItem>
          </Flex>

          <FlexItem
            fullWidth
          >
            <Typography
              variant="title2"
            >
              Property Features
            </Typography>

            <Flex
              fullWidth
              flexDirection="column"
              spacing={2}
            >
              <AdditionalAmenities
                itemId={propertyId}
                itemType="properties"
              />

              <PropertyDetailsFullExpandable
                propertyId={propertyId}
              />
            </Flex>
          </FlexItem>
        </Flex>
      </Flex>
    </ErrorBoundary>
  );
};

const {
  number,
} = PropTypes;

SellerProperty.propTypes = {
  propertyId: number.isRequired,
};

export default SellerProperty;
