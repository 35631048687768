import React from 'react';
import PropTypes from 'prop-types';

import useCampaign from 'sections/Communications/hooks/useCampaign';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Button from 'ui/Button';
import Typography from 'ui/Typography';
import Icon from 'ui/Icon';

import { useHistory } from 'react-router-dom';
import routes from 'sections/InAppMarketing/routes';

import BasicInfo from 'sections/Communications/modules/Marketing/CampaignDetails/BasicInfo';
import PropertyBriefInfo from 'sections/Properties/modules/PropertyBriefInfo';
import Options from 'sections/Communications/modules/Marketing/CampaignDetails/Options';
import Metrics from 'sections/Communications/modules/Marketing/CampaignDetails/Metrics';
import Recipients from 'sections/Communications/modules/Marketing/CampaignDetails/Recipients';

import Start from 'sections/Communications/modules/Marketing/CampaignDetails/Start';
import Pause from 'sections/Communications/modules/Marketing/CampaignDetails/Pause';
import Unpause from 'sections/Communications/modules/Marketing/CampaignDetails/Unpause';
import SendAgain from 'sections/Communications/modules/Marketing/CampaignDetails/SendAgain';

import SampleMessage from 'sections/Communications/modules/Marketing/CampaignDetails/SampleMessage';

import {
  Root,
} from './styledItems';

const CampaignDetails = (props) => {
  const {
    campaignId,
    setView,
  } = props;

  const {
    campaign,
  } = useCampaign(campaignId);

  const history = useHistory();

  const {
    name,
    template_id,
    type,
    status,
    sent_at,
    recipients,
    objects,
    realtorPropertyId,
    reach,
    metrics,
  } = campaign;

  // on click set route to /marketing
  const toHome = () => {
    history.push(`${routes.sectionRoot}`);
  };

  return (
    <Root>
      <Flex
        spacing={4}
        flexWrap="wrap"
        alignItems="center"
      >
        <Flex
          fullWidth
          flexDirection="row"
          style={{ left: '-15px' }}
        >
          <Icon
            icon="chevron-left"
            name="chevron-left"
            color="focus"
            size="sm"
            onClick={toHome}
          />
          <Typography
            variant="h4"
            color="focus"
            noMargin
            onClick={toHome}
            style={{ cursor: 'pointer' }}
          >
            Back
          </Typography>
        </Flex>

        <FlexItem
          xs={10}
        >
          <BasicInfo
            title={name}
            sentAt={sent_at}
            completed={status === 6}
          />
        </FlexItem>

        <FlexItem
          xs={7}
        >
          {
            realtorPropertyId && (
              <PropertyBriefInfo
                propertyId={realtorPropertyId}
              />
            )
          }
        </FlexItem>

        <FlexItem
          xs={7}
        >
          <Flex
            fullWidth
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            {
              // status new
              status === 7 && (
                <Start campaignId={campaignId} />
              )
            }
            {
              // status in progress
              status === 3 && (
                <Pause campaignId={campaignId} />
              )
            }
            {
              // status pending
              status === 5 && (
                <Unpause campaignId={campaignId} />
              )
            }
            {
              // status completed
              status === 6 && (
                <SendAgain campaignId={campaignId} />
              )
            }

            <SampleMessage campaignId={campaignId} />

            <Button
              icon="edit"
              variant="secondary"
              onClick={() => setView('composer')}
            />
            <Options campaignId={campaignId} />
          </Flex>
        </FlexItem>

        <FlexItem fullWidth>
          <Metrics data={metrics} />
        </FlexItem>

        <FlexItem fullWidth>
          <Recipients
            data={recipients || []}
            total={reach?.total || 0}
          />
        </FlexItem>

      </Flex>
    </Root>
  );
};

CampaignDetails.defaultProps = {
  campaignId: null,
};

const {
  number,
} = PropTypes;

CampaignDetails.propTypes = {
  campaignId: number,
};

export default CampaignDetails;
