import { connect } from 'react-redux';

import { debounce } from 'shared/utility';

// import {
//   setFilterItem,
//   handleCustomFilter,
// } from 'store/actions/crm/crmLeadsFilter';

// import {
//   getLeadsForStoreFilter,
// } from 'store/actions/crm/crmLeads';

import Search from './Search';

const mapStateToProps = (state) => {
  const {

  } = state;

  return {

  };
};

const mapDispatchToProps = (dispatch) => ({
  onSearch: debounce((search) => {
    // dispatch(setFilterItem('search')(search));
    // dispatch(getLeadsForStoreFilter());
  }, 500),
  handleCustomFilter: (key) => {
    // dispatch(handleCustomFilter(key));
    // dispatch(getLeadsForStoreFilter());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
