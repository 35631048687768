import { connect } from 'react-redux';
import {
  toDoListSetViewedAction,
  reminderDeletingAction,
  reminderChangeImportanceAction,
  reminderChangeFulfillmentAction,
  setInviteEmployees,
  setInviteEmployeesSuccess,
  setInviteEmployeesError,
} from 'store/actions/to-do-list';
import {
  mapEmployeesFromId,
} from 'shared/utils/index';
import ReminderItems from './ReminderItems';

const mapStateToProps = (state) => ({
  todolist: state.todolist,
  EmployeeInf: state.auth.loggedUser,
  employees: state.myCompany.employees,
});
const mapDispatchToProps = {
  toDoListSetViewedAction,
  reminderDeletingAction,
  reminderChangeImportanceAction,
  reminderChangeFulfillmentAction,
  mapEmployeesFromId,
  setInviteEmployees,
  setInviteEmployeesSuccess,
  setInviteEmployeesError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReminderItems);
