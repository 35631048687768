import React from 'react';
import PropTypes from 'prop-types';

import './InputWarning.scss';

const InputWarning = (props) => {
  if (!props.children) {
    return null;
  }

  return (
    <p className="input-warning">{props.children}</p>
  );
};

InputWarning.propTypes = {
  children: PropTypes.string,
};

export default InputWarning;
