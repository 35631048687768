import styled from 'styled-components';

export const Wrapper = styled.div`
  input {
    font-size: 14px;
  }
  opacity: 0;
  height: 0;
  width: 0;
`;

export default {
  Wrapper,
};
