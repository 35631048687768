import React, {
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { NakedButton } from '@symplete/symplete-ui';

import Typography from 'ui/Typography';
import Tag from 'ui/Tag';
import Button from 'ui/Button';
import Flex from 'ui/Flex';
import Icon from 'ui/Icon';

import GroupedLabels from './GroupedLabels';
import EditModal from './EditModal';

import {
  StyledTag,
} from './styledItems';

const getLabelNameById = (list, id) => {
  const label = list.find((item) => item.id === id);
  return label?.name;
};

const ContactLabels = (props) => {
  const {
    allContactLabels,
    selectedLabelsId,
    primaryLabel,
    labelToShowInMainView,
    onChange,
    editable,
  } = props;

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const openEditModal = useCallback(() => {
    setIsEditModalVisible(true);
  }, []);

  const closeEditModal = useCallback(() => {
    setIsEditModalVisible(false);
  }, []);

  const secondaryLabels = selectedLabelsId.filter((labelId) => labelId !== labelToShowInMainView);

  return (
    <>
      <Flex
        spacing={1}
      >
        {
          editable && (
            <Tag noPadding>
              <Button
                variant="tertiary"
                color="contrast"
                icon="plus"
                onClick={openEditModal}
                typographyProps={{ variant: 'tag', weight: 'regular' }}
              >
                {selectedLabelsId.length === 0 ? 'Label' : null}
              </Button>
            </Tag>
          )
        }

        {
          labelToShowInMainView && (
            <StyledTag>
              <Flex spacing={1} alignItems="center">
                <Typography
                  variant="tag"
                  color="main-text-inversed"
                  weight="regular"
                  noWrap
                  style={{ textTransform: 'capitalize' }}
                >
                  {getLabelNameById(allContactLabels, labelToShowInMainView) || ''}
                </Typography>

                {
                  editable && (
                    <NakedButton
                      className="contact-type-tag-button"
                      onClick={openEditModal}
                    >
                      <Icon
                        name="x"
                        color="main-text-inversed"
                      />
                    </NakedButton>
                  )
                }
              </Flex>
            </StyledTag>
          )
        }

        {
          (secondaryLabels.length > 0) && (
            <GroupedLabels
              labelsIds={secondaryLabels}
              editable={editable}
              openEditModal={openEditModal}
            />
          )
        }
      </Flex>

      {
        editable && (
          <EditModal
            isVisible={isEditModalVisible}
            onSave={onChange}
            onCancel={closeEditModal}
            selectedValues={selectedLabelsId}
            primaryValue={primaryLabel}
          />
        )
      }
    </>
  );
};

ContactLabels.defaultProps = {
  selectedLabelsId: [],
  primaryLabel: null,
  labelToShowInMainView: null,
  editable: true,
};

const {
  func,
  number,
  arrayOf,
  bool,
  shape,
} = PropTypes;

ContactLabels.propTypes = {
  onChange: func.isRequired,
  selectedLabelsId: arrayOf(number),
  primaryLabel: number,
  labelToShowInMainView: number,
  editable: bool,
  allContactLabels: arrayOf(shape({})).isRequired,
};

export default ContactLabels;
