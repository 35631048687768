import React from 'react';
import PropTypes from 'prop-types';

import Elevation from 'ui/Elevation';
import Table from 'ui/Table';
import Typography from 'ui/Typography';

import columns from './columns';

const Recipients = (props) => {
  const {
    data,
    total,
  } = props;

  return (
    <>
      <Typography
        variant="title2"
        weight={500}
        noMargin
        style={{ marginBottom: 20 }}
      >
        {`${total} Total Recipients`}
      </Typography>
      <Table
        key="email-campaign-recipients-table"
        dataSource={data}
        columns={columns()}
        pagination={{
          total,
          showTotal: false,
        }}
        tableLayout="fixed"
        size="middle"
      />
    </>
  );
};

Recipients.defaultProps = {
  data: [],
  total: 0,
};

const {
  arrayOf,
  shape,
  number,
} = PropTypes;

Recipients.propTypes = {
  data: arrayOf(shape()),
  total: number,
};

export default Recipients;
