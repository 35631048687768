import React, {
  useRef,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import Button from 'ui/Button';
import MenuItem from 'ui/MenuItem';

import useMenu from 'ui/hooks/useMenu';

const ReplyButton = (props) => {
  const {
    onReply,
    emailId,
    from,
    cc,
    replyTo,
  } = props;

  const anchorEl = useRef(null);

  const {
    openMenu,
    closeMenu,
    Menu,
  } = useMenu(anchorEl);

  const onOpenMenu = useCallback((event) => {
    event.stopPropagation();
    openMenu();
  }, [
    openMenu,
  ]);

  const reply = useCallback((event) => {
    event.stopPropagation();
    const emails = [...replyTo, ...from];
    const emailToReplyTo = emails[0]?.email;

    onReply(emailId, [emailToReplyTo]);
    closeMenu();
  }, [
    onReply,
    emailId,
    from,
    replyTo,
    closeMenu,
  ]);

  const replyToAll = useCallback((event) => {
    event.stopPropagation();
    const emails = [...replyTo, ...from, ...cc];
    const emailsToReplyTo = emails.map((item) => item?.email);

    onReply(emailId, emailsToReplyTo);
    closeMenu();
  }, [
    onReply,
    emailId,
    from,
    cc,
    replyTo,
    closeMenu,
  ]);

  const oneSender = from.length < 2 && cc.length < 1;

  return (
    <>
      {
        oneSender ? (
          <Button
            icon="reply"
            iconType="fontawesome"
            variant="flat"
            iconColor="focus"
            size="s"
            onClick={reply}
          />
        ) : (
          <>
            <Button
              buttonRef={anchorEl}
              icon="reply-all"
              iconType="fontawesome"
              variant="flat"
              iconColor="focus"
              size="s"
              onClick={onOpenMenu}
            />
            <Menu>
              <MenuItem
                onClick={reply}
              >
                Reply
              </MenuItem>
              <MenuItem
                onClick={replyToAll}
              >
                Reply to All
              </MenuItem>
            </Menu>
          </>
        )
      }
    </>
  );
};

ReplyButton.defaultProps = {
  cc: [],
  replyTo: [],
};

const {
  func,
  string,
  arrayOf,
  shape,
} = PropTypes;

ReplyButton.propTypes = {
  onReply: func.isRequired,
  emailId: string.isRequired,
  from: arrayOf(shape({
    email: string.isRequired,
  })).isRequired,
  cc: arrayOf(shape({
    email: string.isRequired,
  })),
  replyTo: arrayOf(shape({
    email: string.isRequired,
  })),
};

export default ReplyButton;
