import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundary from 'ui/ErrorBoundary';

import Flex from 'ui/Flex';
import Elevation from 'ui/Elevation';
import Divider from 'ui/Divider';

import ContactInfoConcise from 'sections/Contacts/modules/ContactInfoConcise';

import SinceClient from 'sections/Prospects/modules/SinceClient';
import Comments from 'sections/Prospects/modules/Comments';

import ContactLogButton from 'sections/ContactLogs/ContactLogButton';

import Call from 'sections/Contacts/modules/Call';
import EmailButton from 'sections/Contacts/modules/EmailButton';

const sectionStyle = {
  padding: 10,
};

const avatarProps = {
  label: 'client buyer',
};

const Contact = (props) => {
  const {
    contactId,
    prospectId,
  } = props;

  return (
    <ErrorBoundary>
      <Elevation rounded="s" fullHeight>
        <div style={sectionStyle}>
          <ContactInfoConcise
            contactId={contactId}
            avatarProps={avatarProps}
          />
        </div>
        <Divider />
        <div style={sectionStyle}>
          <Flex
            flexDirection="column"
            spacing={1}
          >
            <Flex
              spacing={2}
              alignItems="center"
            >
              <SinceClient prospectId={prospectId} />
              <ContactLogButton
                objectType="realtor contact"
                objectId={contactId}
                buttonProps={{
                  variant: 'flat',
                }}
              >
                Log Contact
                {contactId}
              </ContactLogButton>
              <Comments clientId={prospectId} />
            </Flex>
            <Flex
              spacing={2}
              alignItems="center"
            >
              <Call contactId={contactId} />
              <EmailButton
                contactId={contactId}
                contactType={avatarProps.label}
                objectId={prospectId}
              />
            </Flex>
          </Flex>
        </div>
      </Elevation>
    </ErrorBoundary>
  );
};

const {
  number,
} = PropTypes;

Contact.propTypes = {
  contactId: number.isRequired,
  prospectId: number.isRequired,
};

export default Contact;
