import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  convert,
} from 'sections/Leads/actions';

import LeadDetails from './LeadDetails';

const mapStateToProps = (state, ownProps) => {
  const options = selectors.realtorLeads.leadDetailsOptions(state, ownProps.leadId);

  const lead = selectors.realtorLeads.byId(state, ownProps.leadId);

  return {
    options,
    contactId: lead?.contact_id,
  };
};

const mapDispatchToProps = {

};

const mergeProps = (state, dispatch, own) => ({
  ...state,
  ...dispatch,
  ...own,
  onConvert: (selectedItem) => () => {
    convert(own.leadId)(selectedItem?.type)(selectedItem?.itemId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(LeadDetails);
