import { connect } from 'react-redux';

import { updatePropertyViewing } from 'store/actions/propertyViewings';

import InterestedCell from './InterestedCell';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (value) => {
    dispatch(updatePropertyViewing(ownProps.viewingId)({ is_interested: value }));
  },
});

export default connect(null, mapDispatchToProps)(InterestedCell);
