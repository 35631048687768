import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const IconContainer = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: ${(props) => props.backgroundColor || '#d4eaff'};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
`;
