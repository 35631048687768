import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import Item from 'ui/Item';
import Container from 'ui/Container';
import ItemContainer from 'ui/ItemContainer';

import Typography from 'ui/Typography';

import Divider from '@material-ui/core/Divider';

import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';
import AvatarWithLeadTypeSelect from 'sections/Leads/modules/AvatarWithLeadTypeSelect';
import SocialLinks from 'components/CRM/ContactView/SocialLinks';
import ContactDescription from 'sections/Leads/modules/ContactDescription';
import FirstContact from 'sections/Leads/modules/FirstContact';
import SourceSelect from 'sections/Leads/modules/SourceSelect';

import ContactInfo from 'sections/Contacts/modules/ContactInfo';

import {
  Name,
  InfoItem,
} from './styledItems';

const Contact = (props) => {
  const {
    leadId,
    contactId,
    firstName,
    lastName,
    email,
    phone,
    socialLinks,
    toolbar,
  } = props;

  return (
    <ContactInfo
      contactId={contactId}
      avatar={(
        <AvatarWithLeadTypeSelect
          contactId={contactId}
          leadId={leadId}
        />
      )}
      toolbar={toolbar}
    />
  );

  return (
    <Container spacing={4}>
      <ItemContainer
        xs={4}
        spacing={2}
      >
        <ItemContainer
          xs={12}
          spacing={2}
        >
          <Item>
            <AvatarWithLeadTypeSelect
              contactId={contactId}
              leadId={leadId}
            />
          </Item>

          <ItemContainer spacing={1}>
            <Item xs={12}>
              <Typography
                variant="heading"
                noMargin
              >
                {`${firstName || ''} ${lastName || ''}`}
              </Typography>
            </Item>

            {
              email && (
                <Item xs={12}>
                  <DecoratedFieldWrapper
                    iconName="mail"
                    iconType="feather"
                    size="sm"
                    variant="focus"
                  >
                    <Typography noMargin>
                      {email}
                    </Typography>
                  </DecoratedFieldWrapper>
                </Item>
              )
            }

            {
              phone && (
                <Item xs={12}>
                  <DecoratedFieldWrapper
                    iconName="phone"
                    iconType="feather"
                    size="sm"
                    variant="focus"
                  >
                    <Typography noMargin>
                      {phone}
                    </Typography>
                  </DecoratedFieldWrapper>
                </Item>
              )
            }
          </ItemContainer>

        </ItemContainer>
        <Item xs={12}>
          <SocialLinks
            firstName={firstName}
            links={socialLinks}
          />
        </Item>
      </ItemContainer>
      <ItemContainer
        xs={8}
        spacing={2}
      >
        <Item xs={12}>
          Tools
        </Item>
        <Item xs={12}>
          Notes
        </Item>
      </ItemContainer>
    </Container>
  );

  // return (
    // <Grid
    //   container
    //   spacing={2}
    // >
    //   <Grid
    //     item
    //     xs={12}
    //     container
    //     spacing={2}
    //   >
    //     <Grid item>

    //     </Grid>
    //     <Grid item>

    //       <Grid container spacing={1}>

    //         <Grid item xs={12}>
    //           <Typography
    //             variant="heading"
    //             noMargin
    //           >
    //             {`${firstName} ${lastName}`}
    //           </Typography>
    //         </Grid>

    //         {
    //           email && (
    //             <Grid item xs={12}>
    //               <DecoratedFieldWrapper
    //                 iconName="at-sign"
    //                 iconType="feather"
    //                 size="sm"
    //                 variant="focus"
    //               >
    //                 <Typography noMargin>
    //                   {email}
    //                 </Typography>
    //               </DecoratedFieldWrapper>
    //             </Grid>
    //           )
    //         }

    //         {
    //           phone && (
    //             <Grid item xs={12}>
    //               <DecoratedFieldWrapper
    //                 iconName="phone"
    //                 iconType="feather"
    //                 size="sm"
    //                 variant="focus"
    //               >
    //                 <Typography noMargin>
    //                   {phone}
    //                 </Typography>
    //               </DecoratedFieldWrapper>
    //             </Grid>
    //           )
    //         }

    //       </Grid>

    //     </Grid>

    //   </Grid>

    //   <Grid
    //     xs={12}
    //     spacing={2}
    //   >
    //     <Divider />
    //   </Grid>

    //   <Grid
    //     item
    //     xs={12}
    //     container
    //     spacing={2}
    //   >
    //     <SocialLinks
    //       firstName={firstName}
    //       links={socialLinks}
    //     />
    //   </Grid>

    //   <Grid
    //     xs={12}
    //   >
    //     <Divider />
    //   </Grid>

    //   <Grid item />

    //   <Grid
    //     item
    //     xs={12}
    //   >
    //     <ContactDescription contactId={contactId} />
    //   </Grid>

    //   <Grid
    //     item
    //     xs={12}
    //   >
    //     <FirstContact contactId={contactId} />
    //   </Grid>
    //   <Grid
    //     item
    //     xs={12}
    //   >
    //     <SourceSelect contactId={contactId} />
    //   </Grid>
    // </Grid>
  // );
};

Contact.defaultProps = {
  leadId: null,
  contactId: null,
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  socialLinks: [
    {
      name: 'facebook',
      url: null,
      icon: ['fab', 'facebook-f'],
    },
    {
      name: 'twitter',
      url: null,
      icon: ['fab', 'twitter'],
    },
    {
      name: 'linkedin',
      url: null,
      icon: ['fab', 'linkedin-in'],
    },
    {
      name: 'instagram',
      url: null,
      icon: ['fab', 'instagram'],
    },
    {
      name: 'website',
      url: null,
      icon: 'globe',
    },
  ],
};

const {
  number,
  string,
  arrayOf,
  shape,
  oneOfType,
} = PropTypes;

Contact.propTypes = {
  leadId: number,
  contactId: number,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  socialLinks: arrayOf(shape({
    name: string,
    url: string,
    icon: oneOfType([
      arrayOf(string),
      string,
    ]),
  })),
};

export default Contact;
