import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  optimizedRealtorInspectionsActions,
} from 'store/actions/realtorInspections';

import {
  setFilterItem,
} from 'store/actions/realtorInspectionsFilter';

import InspectionsTable from './InspectionsTable';

const mapStateToProps = (state, ownProps) => {
  const {
    realtorInspections: {
      inspectionsByFilterCount,
    },
  } = state;

  const {
    filterId,
  } = selectors.realtorInspections.inspectionsFilter(state);

  const inspectionsList = selectors.realtorInspections.inspectionsList(state, filterId);

  return {
    itemsCount: inspectionsByFilterCount[filterId],
    inspectionsList,
    filterId,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getRepairRequests: (filterId) => (page, pageSize) => {
    const {
      transactionId,
    } = ownProps;

    if (!transactionId) {
      return;
    }

    const offset = (page - 1) * pageSize;
    const limit = pageSize;

    optimizedRealtorInspectionsActions.getInspectionsForFilter(filterId, { transactionId }, offset, limit);
  },

  setFilterItem: (key) => (value) => {
    dispatch(setFilterItem(key)(value));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  setFilterItem: () => dispatchProps.setFilterItem,
  getRepairRequests: dispatchProps.getRepairRequests(stateProps.filterId),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(InspectionsTable);
