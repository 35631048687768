import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

import { Tooltip } from 'antd';

const PropertyLocationCell = (props) => {
  const {
    address,
  } = props;

  return (
    <Tooltip title={address}>
      <Typography
        ellipsis
        variant="cell"
        style={{ maxWidth: '100%' }}
      >
        {address}
      </Typography>
    </Tooltip>
  );
};

PropertyLocationCell.defaultProps = {
  address: null,
};

const {
  string,
} = PropTypes;

PropertyLocationCell.propTypes = {
  address: string,
};

export default PropertyLocationCell;
