import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { debounce } from 'shared/utility';

import Flex from 'ui/Flex';
import Spin from 'ui/Spin';

// import Button from 'ui/Button';

import TemplateComposer from 'sections/Communications/modules/Marketing/TemplateComposer';

import useCampaign from 'sections/Communications/hooks/useCampaign';

import CampaignName from './CampaignName';
import SendTest from './SendTest';
import Next from './Next';

const mapRecipients = (campaign = {}) => (campaign.recipients
  ? campaign.recipients.map(({ realtor_contact_id }) => realtor_contact_id)
  : []);

const EmailCampaignEditor = (props) => {
  const {
    campaignId,
    setView,
  } = props;

  const {
    campaign,
    update,
  } = useCampaign(campaignId);

  const updateCampaign = useCallback((key) => debounce((value) => {
    update({
      [key]: value,
    });
  }, 1000), [
    update,
  ]);

  const onAssignRecipients = useCallback(async (recipients) => {
    await update({
      recipients,
    });
    setView('details');
  }, [
    update,
    setView,
  ]);

  if (!campaignId || !campaign?.id) {
    return (
      <Flex
        fullWidth
        justifyContent="center"
      >
        <Spin />
      </Flex>
    );
  }

  return (
    <Flex
      fullWidth
      spacing={3}
      flexDirection="column"
    >
      <Flex
        justifyContent="space-between"
        alignItems="baseline"
        fullWidth
      >
        <CampaignName
          onChange={updateCampaign('name')}
        >
          {campaign?.name}
        </CampaignName>

        <Flex
          spacing={1}
          justifyContent="flex-end"
        >

          <SendTest
            campaignId={campaignId}
          />

          <Next
            onAssignRecipients={onAssignRecipients}
            recipients={mapRecipients(campaign)}
          />

        </Flex>
      </Flex>
      <TemplateComposer
        campaignId={campaignId}
      />
    </Flex>
  );
};

const {
  func,
  number,
} = PropTypes;

EmailCampaignEditor.propTypes = {
  campaignId: number.isRequired,
  setView: func.isRequired,
};

export default EmailCampaignEditor;
