import { connect } from 'react-redux';

import selectors from 'store/selectors';

import Contact from './Contact';

const mapStateToProps = (state, ownProps) => {
  const prospect = selectors.realtorClients.byId(state, ownProps.clientId);

  return {
    contactId: prospect.contact_id,
  };
};

export default connect(mapStateToProps)(Contact);
