import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import createHtmlMarkup from 'shared/utils/createHtmlMarkup';

import EditableTextField from 'shared/components/EditableTextField';

const ImportantViewingNotes = (props) => {
  const {
    onChange,
    client,
  } = props;

  const renderValue = useCallback((value) => (
    <div className="ticket-text__content">
      {createHtmlMarkup(value)}
    </div>
  ), []);

  const text = client.important_viewing_notes === '<p></p>'
    ? ''
    : client.important_viewing_notes;

  return (
    <EditableTextField
      isBordered
      richTextEditor
      onConfirm={onChange('important_viewing_notes')}
      value={text}
      placeholder={'<p style="cursor: pointer;">Notes...</p>'}
      renderValue={renderValue}
    />
  );
};

const {
  func,
  string,
  shape,
} = PropTypes;

ImportantViewingNotes.propTypes = {
  onChange: func.isRequired,
  client: shape({
    important_viewing_notes: string,
  }).isRequired,
};

export default ImportantViewingNotes;
