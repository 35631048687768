/* eslint-disable no-nested-ternary */
import { connect } from 'react-redux';

import moment from 'moment';

import { orderBy } from 'shared/utility';

import selectors from 'store/selectors';

import TransactionProgressColumn from './TransactionProgressColumn';

const stageNameDict = {
  initial_documents_completed_at: 'initial documents',
  seller_disclosure_completed_at: 'seller disclosure',
  inspections_completed_at: 'inspections',
  appraisal_completed_at: 'appraisal',
  loan_completed_at: 'loan',
  final_walkthrough_completed_at: 'final walkthrough',
  close_escrow_completed_at: 'close escrow',
  move_out_date_completed_at: 'move out date',
};

const expiryFieldDict = {
  initial_documents_completed_at: 'initial_documents_expires_at',
  seller_disclosure_completed_at: 'seller_disclosure_expires_at',
  inspections_completed_at: 'inspections_expires_at',
  appraisal_completed_at: 'appraisal_expires_at',
  loan_completed_at: 'loan_expires_at',
  final_walkthrough_completed_at: 'final_walkthrough_expires_at',
  close_escrow_completed_at: 'close_escrow_expires_at',
  move_out_date_completed_at: 'move_out_date_expires_at',
};

const getStageStatuses = (transactionObj) => Object.keys(stageNameDict).reduce((acc, key) => {
    const stageName = stageNameDict[key];

    acc[stageName] = {
      status: '',
      subtitle: '',
    };

    const expiryKey = expiryFieldDict[key];

    const isCompleted = transactionObj[key];
    const expiryDate = transactionObj[expiryKey];

    const daysDiff = moment(expiryDate).diff(moment(), 'days');

    acc[stageName].status = isCompleted
      ? 'success'
      : expiryDate
        ? daysDiff < 0
          ? 'error'
          : daysDiff < 2
            ? 'warning'
            : 'focus'
        : 'focus';

    acc[stageName].subtitle = isCompleted
      ? moment(transactionObj[key]).format('MMMM D')
      : expiryDate
        ? `Exp: ${moment(expiryDate).format('MMMM D')}`
        : 'Exp: enter date';

    acc[stageName].statusSubtitle = isCompleted
      ? null
      : expiryDate
        ? daysDiff < 0
          ? `(${daysDiff * -1} days late)`
          : daysDiff === 0
            ? '(today)'
            : `(${daysDiff} days left)`
        : null;

    return acc;
  }, {});

const mapStateToProps = (state, ownProps) => {
  const {
    transactionId,
  } = ownProps;

  const transaction = selectors.realtorTransactions.byId(state, transactionId);

  const stagesStatuses = getStageStatuses(transaction);

  const attorneyOrEscrow = selectors.appCustomization.settings(state).attorney_state_or_escrow_state;

  const startTabs = [
    {
      name: 'initial documents',
      iconName: 'folder',
      expiryKey: 'initial_documents_expires_at',
    },
    {
      name: 'seller disclosure',
      iconName: 'list',
      expiryKey: 'seller_disclosure_expires_at',
    },
  ];

  const volatileTabs = [
    {
      name: 'inspections',
      iconName: 'search',
      expiryKey: 'inspections_expires_at',
    },
    {
      name: 'appraisal',
      iconName: 'target',
      expiryKey: 'appraisal_expires_at',
    },
    {
      name: 'loan',
      iconName: 'shopping-cart',
      expiryKey: 'loan_expires_at',
    },
    {
      name: 'final walkthrough',
      iconName: 'calendar',
      expiryKey: 'final_walkthrough_expires_at',
    },
  ];

  const endTabs = [
    {
      name: 'close escrow',
      iconName: 'flag',
      expiryKey: 'close_escrow_expires_at',
      displayName: attorneyOrEscrow === 'attorney' ? 'Closing Day' : 'Close Escrow',
    },
    {
      name: 'move out date',
      iconName: 'key',
      expiryKey: 'move_out_date_expires_at',
    },
  ];

  return {
    stagesStatuses,
    tabsCollection: [
      ...startTabs,
      // ...orderBy(volatileTabs, (tab) => transaction[tab.expiryKey], ['asc']),
      ...volatileTabs,
      ...endTabs,
    ],
  };
};

export default connect(mapStateToProps, null)(TransactionProgressColumn);
