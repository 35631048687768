import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  deleteMatching,
} from 'store/actions/realtorMatched';

import {
  createMatchingProperty,
} from 'store/actions/realtorMatchingProperties';

import MatchingBuyerCard from 'shared/components/UI/RealtorObjectCards/MatchingBuyerCard';

const mapStateToProps = (state, ownProps) => {
  const {
    id,
    updateList,
  } = ownProps;

  const {
    realtor_contact_id,
    realtor_property_id,
  } = selectors.realtorMatched.byId(id)(state);

  return {
    matchingId: id,
    contactId: realtor_contact_id,
    realtor_contact_id,
    realtor_property_id,
    updateList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteMatchingAction: (matchingId) => dispatch(deleteMatching(matchingId)),
  createMatchingPropertyAction: (propertyId, contactId) => dispatch(createMatchingProperty({
      realtor_property_id: propertyId,
      realtor_contact_id: contactId,
      status: 1,
    })),
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  onClickNotInterested: async () => {
    await dispatchProps.deleteMatchingAction(
      stateProps.matchingId,
    );

    if (stateProps.updateList) {
      await stateProps.updateList();
    }
  },
  onCLickInterested: async () => {
    await dispatchProps.deleteMatchingAction(
      stateProps.matchingId,
    );
    await dispatchProps.createMatchingPropertyAction(
      stateProps.realtor_property_id,
      stateProps.realtor_contact_id,
    );
    if (stateProps.updateList) {
      await stateProps.updateList();
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(MatchingBuyerCard);
