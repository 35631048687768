import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import { NUMBER_OF_STORIES } from 'constants/realtor.constants';

import { map } from 'shared/utility';

import DropdownTextfield from 'ui/DropdownTextfield';

const options = map(NUMBER_OF_STORIES, (label, key) => ({
  label,
  value: +key,
}));

const style = {
  textTransform: 'capitalize',
};

const PropertyTypeSelect = (props) => {
  const {
    handleChange,
    value,
  } = props;

  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [
    value,
  ]);

  const onChange = useCallback((value) => {
    setCurrentValue(value);
    handleChange(value);
  }, [
    handleChange,
  ]);

  return (
    <DropdownTextfield
      onChange={onChange}
      value={currentValue}
      options={options}
      menuItemStyle={style}
      style={style}
      label="Number of stories"
    />
  );
};

PropertyTypeSelect.defaultProps = {
  value: undefined,
};

const {
  func,
  number,
} = PropTypes;

PropertyTypeSelect.propTypes = {
  handleChange: func.isRequired,
  value: number,
};

export default PropertyTypeSelect;
