import React, {
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { NakedButton } from '@symplete/symplete-ui';

import Icon from 'ui/Icon';

import SocialIconModal from 'shared/components/SocialIconModal';

import {
  Root,
  // SymCardLogo
} from './styledItems';

import './SocialLinks.scss';

const defaultLinkObject = {
  type: null,
  url: null,
};

const SocialLinks = (props) => {
  const {
    firstName,
    links,
    updateLink,
  } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [chosenLink, setChosenLink] = useState(defaultLinkObject);

  const openModal = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalVisible(false);
    setChosenLink(defaultLinkObject);
  }, []);

  const onClickEdit = useCallback((type, url) => () => {
    setChosenLink({ type, url });
    openModal();
  }, []);

  const handleSubmit = useCallback((type) => (value) => {
    updateLink(type, value);
    closeModal();
  }, []);

  return (
    <Root>
      {
        links.map((item) => {
          const {
            name,
            url,
            icon,
          } = item;

          return (
            <NakedButton onClick={onClickEdit(name, url)}>
              <Icon
                name={icon}
                color={url ? 'main-text' : 'placeholder'}
                size="l"
              />
            </NakedButton>
          );
        })
      }

      <SocialIconModal
        visible={isModalVisible}
        title={`Enter ${firstName}'s ${chosenLink.type}`}
        type={chosenLink.type}
        value={chosenLink.url}
        handleClose={closeModal}
        handleSubmit={handleSubmit(chosenLink.type)}
      />
    </Root>
  );
};

SocialLinks.defaultProps = {
  firstName: '',
  links: [
    {
      name: 'facebook',
      url: null,
      icon: ['fab', 'facebook-f'],
    },
    {
      name: 'twitter',
      url: null,
      icon: ['fab', 'twitter'],
    },
    {
      name: 'linkedin',
      url: null,
      icon: ['fab', 'linkedin-in'],
    },
    {
      name: 'instagram',
      url: null,
      icon: ['fab', 'instagram'],
    },
    {
      name: 'website',
      url: null,
      icon: 'globe',
    },
  ],
};

const {
  string,
  shape,
  oneOfType,
  arrayOf,
  func,
} = PropTypes;

SocialLinks.propTypes = {
  firstName: string,
  links: arrayOf(shape({
    name: string,
    url: string,
    icon: oneOfType([
      arrayOf(string),
      string,
    ]),
  })),
  updateLink: func.isRequired,
};

export default SocialLinks;
