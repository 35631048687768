import React from 'react';
import PropTypes from 'prop-types';

import withPropertyData from 'sections/Properties/hocs/withPropertyData';

import Typography from 'ui/Typography';

const PropertyViewings = (props) => {
  const {
    property,
  } = props;

  return (
    <Typography variant="cell">
      {property?.viewings_summary?.total_viewings || 0}
    </Typography>
  );
};

PropertyViewings.defaultProps = {
  property: {},
};

const {
  number,
  shape,
} = PropTypes;

PropertyViewings.propTypes = {
  property: shape({
    viewings_summary: shape({
      total_viewings: number,
    }),
  }),
};

export default withPropertyData(PropertyViewings);
