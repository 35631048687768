import styled from 'styled-components';

export const Root = styled.section`
  padding-bottom: 32px;
  .property-menu-control-button {
    justify-content: start;
  }
`;

export default {
  Root,
};
