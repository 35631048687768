import React from 'react';

import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import OurThemeProvider from 'shared/themes/OurThemeProvider';
import withTheme from 'shared/hocs/withTheme';

import routes from 'sections/InAppMarketing/routes';

import ConnectedAccounts from 'sections/InAppMarketing/pages/ConnectedAccounts';
import EmailMarketingCampaign from 'sections/Communications/pages/EmailMarketingCampaign';
import Marketing from 'sections/Communications/modules/Marketing';

import { defaultTheme } from '@symplete/symplete-ui';
import theme from './theme';

const InAppMarketing = () => {
  const Theme = withTheme(defaultTheme, theme)(OurThemeProvider);

  return (
    <Theme>
      <ConnectedAccounts/>
      <Switch>
        <Route
          exact
          path={routes.sectionRoot}
          component={Marketing}
        />
        <Route
          path={`${routes.campaignDetails}/:campaignId`}
          component={EmailMarketingCampaign}
        />
        <Redirect to={routes.sectionRoot} />
      </Switch>
    </Theme>
  );
};

export default InAppMarketing;
