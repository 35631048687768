import { connect } from 'react-redux';

import selectors from 'store/selectors';

import Price from './Price';

const mapStateToProps = (state, ownProps) => {
  const transaction = selectors.realtorTransactions.byId(state, ownProps.transactionId);

  return {
    initialValue: transaction?.price,
    finalValue: transaction?.final_price,
  };
};

export default connect(mapStateToProps)(Price);
