import { connect } from 'react-redux';

import store from 'store';

import selectors from 'store/selectors';

import { min, max } from 'shared/utility';

import actions from 'sections/Properties/actions';
import showingActions from 'sections/Showings/actions';

// viewings
import {
  closeNewPropertyViewingModal,
} from 'store/actions/newRealtorPropertyViewingModal';

import {
  resetNewPropertyViewingForm,
} from 'store/actions/newRealtorPropertyViewingForm';

// offers
import {
  openLogOfferModal,
} from 'store/actions/realtorLogOfferModal';

import {
  openAcceptCounterModal,
} from 'store/actions/realtorAcceptCounterModal';

import PropertyEvents from './PropertyEvents';

const getOffersIds = (match) => {
  const rootOfferId = match.offers
    ? min(match.offers)
    : null;

  const counterId = match.offers
    ? max(match.offers)
    : null;

  return [counterId, rootOfferId];
};

const getViewingType = (lane) => {
  if (lane === 'showingsUpcoming') return 'upcoming';
  if (lane === 'showingsCompleted') return 'completed';
  return null;
};

const mapStateToProps = (state, ownProps) =>
  // const getMatchingBuyer = (matchId) => selectors.realtorMatchingProperties.byId(matchId)(state);

   ({
    // getMatchingBuyer,
  });
const mapDispatchToProps = (dispatch, ownProps) => ({
  onLogOffer: ({
    buyerContactId,
    // clientId,

    onAfterSubmit,
  }) => {
    dispatch(openLogOfferModal(
      {
        type: 'client',
        propertyId: ownProps.propertyId,
        buyerContactId,
        // clientId,

        onAfterSubmit,
      },
    ));
  },

  onWon: ({
    rootOfferId,
    offerId,
    onSave,
  }) => {
    dispatch(openAcceptCounterModal({
      offerId,
      rootOfferId,
      onAfterSubmit: onSave,
    }));
  },

  onRejectBuyer: ({ matchId, onSave }) => {

  },

  closeNewPropertyViewingModal: () => {
    dispatch(closeNewPropertyViewingModal());
    dispatch(resetNewPropertyViewingForm());
  },

  onCreateShowing: (type) => () => {
    showingActions.onCreateShowing({
      propertyId: ownProps.propertyId,
      type,
      onSave: (showingData) => {
        actions.kanban.createMatch(
          {
            realtor_contact_id: showingData.contacts,
            realtor_property_id: ownProps.propertyId,
            status: 2,
          },
        );

        dispatch(closeNewPropertyViewingModal());
        dispatch(resetNewPropertyViewingForm());
      },
    });
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,

  updateMatchingBuyer: (matchId) => async (updateData, currentData, boardData) => {
    if (currentData?.status < updateData?.status) {
      if (updateData?.status === 2) { // -> viewings
        const {
          realtor_property_id: propertyId,
          realtor_contact_id: contactId,
        } = selectors.realtorMatchingProperties.byId(matchId)(store.getState());

        const viewingType = getViewingType(boardData?.destination?.droppableId);

        showingActions.onCreateShowing({
          contactId,
          propertyId,
          type: viewingType,
          onSave: () => actions.kanban.updateMatch(matchId)(updateData),
        });
      }

      if (updateData?.status === 3) { // -> offers
        const {
          realtor_property_id: propertyId,
          realtor_contact_id: buyerContactId,
        } = selectors.realtorMatchingProperties.byId(matchId)(store.getState());

        dispatchProps.onLogOffer({
          propertyId,
          buyerContactId,
          onAfterSubmit: () => actions.kanban.updateMatch(matchId)(updateData),
        });
      }

      if (updateData?.status === 4) { // -> won
        if (currentData?.status === 3) {
          const match = selectors.realtorMatchingProperties.byId(matchId)(store.getState());

          const [
            counterId,
            rootOfferId,
          ] = getOffersIds(match);

          if (rootOfferId) {
            dispatchProps.onWon({
              rootOfferId,
              offerId: counterId,
              onSave: () => actions.kanban.updateMatch(matchId)(updateData),
            });
          }
        }
      }
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PropertyEvents);
