import store from 'store';

import {
  updateMatchingProperty as updateMatch,
  resetMatchingPropertyFilters,
} from 'store/actions/realtorMatchingProperties';

const updateMatchingProperty = (matchId) => async (updateData) => {
  await store.dispatch(updateMatch(matchId)(updateData));
  store.dispatch(resetMatchingPropertyFilters());
};

export default updateMatchingProperty;
