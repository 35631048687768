/* eslint-disable camelcase */
import { callAlert } from 'ui/Alert';
import { isEmpty } from 'shared/utility';

import getPropertyById from 'store/actions/properties/getPropertyById';
import getProspectById from 'store/actions/realtorProspects/getProspectById';
import { resetNewPropertyViewingForm } from 'store/actions/newRealtorPropertyViewingForm';
import {
  closeNewPropertyViewingModal,
} from 'store/actions/newRealtorPropertyViewingModal';

import createPropertyViewing from './createPropertyViewing';
import getPropertyViewingById from './getPropertyViewingById';
import createSyncedEvent from 'api/requests/syncedEvents/create';
import moment from 'moment';



const createPropertyViewingFromNewPropertyViewingForm = () => async (dispatch, getState) => {
  try {
    console.log(1)

    const {
      newPropertyViewingForm,
      newPropertyViewingModal,
      properties,
      realtorProspects,
    } = getState();
    console.log(2)

    const {
      selectedProperty: defaultSelectedPropertyId,
    } = properties;
    console.log(3)

    const {
      propertyId: propertyIdModal,
    } = newPropertyViewingModal;
    console.log(4)

    const {
      property_id: propertyIdForm,
      date_time,
      contacts,
      buyer,
      buyerAgent,
      sellerAgent,
      title,
      location,
      description,
      eventType
    } = newPropertyViewingForm;
    console.log(5)

    const eventToCreate = {
      description: description,
      title: title,
      scheduled_date: new Date(date_time).toISOString().slice(0,19),
      // scheduled_date: date_time,
      location: location
    }
    console.log(6)
    const event_data = await createSyncedEvent(eventToCreate);
    console.log(7)
    console.log(event_data)
    //const createEvent = dispatch(event_data);
    console.log(8)
    window.location.reload();

    const selectedPropertyId = propertyIdForm || propertyIdModal || defaultSelectedPropertyId;

    if (!selectedPropertyId || (isEmpty(contacts) && (!buyer && !buyerAgent && !sellerAgent))) {
      callAlert.error('Some fields data is missing');
      return;
    }

    const viewingId = await dispatch(createPropertyViewing({
      property_id: selectedPropertyId,
      date_time,
      contacts,
      buyer,
      buyerAgent,
      sellerAgent,
    }));

    callAlert.success('Created successfully');

    dispatch(getPropertyViewingById(viewingId));
    dispatch(getPropertyById(selectedPropertyId));
    if (realtorProspects.selectedProspect) {
      dispatch(getProspectById(realtorProspects.selectedProspect));
    }
    dispatch(closeNewPropertyViewingModal());
    dispatch(resetNewPropertyViewingForm());
  } catch (error) {
    console.error(error);
    callAlert.error('Could not create');
    throw error;
  }
};

export default createPropertyViewingFromNewPropertyViewingForm;
