import React from 'react';
import { shape } from 'prop-types';

import { useController } from 'react-hook-form';

import Textfield from 'ui/Textfield';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

const BuyerDetails = (props) => {
  const { control, propertyData } = props;
  const propertyState = propertyData?.propertyState ?? 'residential';
  const transferState = propertyData?.transferState ?? 'sale';

  const {
    field: { onChange: onChangeCompanyName, value: companyName },
  } = useController({
    name: 'companyName',
    control,
  });

  const {
    field: { onChange: onChangeFirstName, value: buyerFirstName },
  } = useController({
    name: 'buyerFirstName',
    control,
  });

  const {
    field: { onChange: onChangeLastName, value: buyerLastName },
  } = useController({
    name: 'buyerLastName',
    control,
  });

  return (
    <>
      <Flex spacing={2} fullWidth>
        <FlexItem xs={12}>
          <Textfield
            placeholder="Company Name"
            onChange={onChangeCompanyName}
            value={companyName}
          />
        </FlexItem>
        <FlexItem xs={12} />
      </Flex>
      <Flex spacing={2} fullWidth>
        <FlexItem xs={12}>
          <Textfield
            placeholder={propertyState === 'commercial'
            || transferState === 'sale' ? 'Buyer First Name' : 'Renter First Name'}
            onChange={onChangeFirstName}
            value={buyerFirstName}
          />
        </FlexItem>
        <FlexItem xs={12}>
          <Textfield
            placeholder={propertyState === 'commercial'
            || transferState === 'sale' ? 'Buyer Last Name' : 'Renter Last Name'}
            onChange={onChangeLastName}
            value={buyerLastName}
          />
        </FlexItem>
      </Flex>
    </>
  );
};

BuyerDetails.propTypes = {
  control: shape({}).isRequired,
  propertyData: shape({}).isRequired,
};

export default BuyerDetails;
