import React from 'react';
import PropTypes from 'prop-types';

import { NakedButton } from '@symplete/symplete-ui';

import ClickAwayListener from 'ui/ClickAwayListener/index';
import Typography from 'ui/Typography';
import Textfield from 'ui/Textfield';
import Tooltip from 'ui/Tooltip';

import useEditMode from 'ui/hooks/useEditMode';

const CampaignName = (props) => {
  const {
    children,
    onChange,
    defaultView,
  } = props;

  const {
    mode,
    setEditMode,
    setDisplayMode,
  } = useEditMode(defaultView);

  if (mode === 'display') {
    return (
      <Tooltip
        title={children}
        placement="left"
      >
        <NakedButton
          onClick={setEditMode}
        >
          <Typography
            ellipsis
            variant="title1"
            noMargin
          >
            {children}
          </Typography>
        </NakedButton>
      </Tooltip>
    );
  }

  return (
    <ClickAwayListener onClickAway={setDisplayMode}>
      <div>
        <Textfield
          value={children}
          onChange={onChange}
          label="Campaign Name"
        />
      </div>
    </ClickAwayListener>
  );
};

CampaignName.defaultProps = {
  defaultView: 'display',
};

const {
  func,
  oneOf,
  string,
} = PropTypes;

CampaignName.propTypes = {
  children: string.isRequired,
  onChange: func.isRequired,
  defaultView: oneOf(['display', 'edit']),
};

export default CampaignName;
