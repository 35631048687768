import { connect } from 'react-redux';

import {
  setFacebookField,
  getFbLongLivedToken,
} from 'store/actions/socialIntegrations';

import FacebookLogin from './FacebookLogin';

const mapStateToProps = (state) => {
  const {
    socialIntegrations: {
      facebook: {
        userID,
      },
    },
  } = state;

  return {
    userID,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserId: (value) => {
    dispatch(setFacebookField('userID')(value));
  },
  setUserName: (value) => {
    dispatch(setFacebookField('userName')(value));
  },
  setUserPages: (value) => {
    dispatch(setFacebookField('userPages')(value));
  },
  getLongLivedToken: (value) => {
    dispatch(getFbLongLivedToken(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FacebookLogin);
