import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundary from 'ui/ErrorBoundary';

import Flex from 'ui/Flex';
import Elevation from 'ui/Elevation';
import Divider from 'ui/Divider';

import ContactInfoConcise from 'sections/Contacts/modules/ContactInfoConcise';

import SinceClient from 'sections/Clients/modules/SinceClient';
import Comments from 'sections/Clients/modules/Comments';
import Options from 'sections/Clients/modules/OptionsV2';

import ContactLogButton from 'sections/ContactLogs/ContactLogButton';
import Call from 'sections/Contacts/modules/Call';
import EmailButton from 'sections/Contacts/modules/EmailButton';

const sectionStyle = {
  padding: 10,
};

const avatarProps = {
  label: 'client seller',
};

const Contact = (props) => {
  const {
    contactId,
    clientId,
  } = props;

  return (
    <ErrorBoundary>
      <Elevation rounded="s" fullHeight>
        <div style={sectionStyle}>
          <Flex spacing={0.5}>
            <ContactInfoConcise
              contactId={contactId}
              avatarProps={avatarProps}
            />
            <Options
              clientId={clientId}
            />
          </Flex>
        </div>
        <Divider />
        <div style={sectionStyle}>
          <Flex
            flexDirection="column"
            spacing={1}
          >

            <Flex
              spacing={2}
              alignItems="center"
              style={{ marginBottom: 10 }}
            >
              <SinceClient clientId={clientId} />
              <ContactLogButton
                objectType="realtor contact"
                objectId={contactId}
                buttonProps={{
                  variant: 'flat',
                }}
              >
                Log Contact
              </ContactLogButton>
              <Comments clientId={clientId} />
            </Flex>
            <Flex
              spacing={2}
              alignItems="center"
              fullWidth
            >
              <Call
                showText
                contactId={contactId}
              />
              <EmailButton
                showText
                contactId={contactId}
                contactType={avatarProps.label}
                objectId={clientId}
              />
            </Flex>
          </Flex>
        </div>
      </Elevation>
    </ErrorBoundary>
  );
};

const {
  number,
} = PropTypes;

Contact.propTypes = {
  contactId: number.isRequired,
  clientId: number.isRequired,
};

export default Contact;
