import React, {
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { currency } from '@symplete/symplete-utils/numbers';
import { NakedButton } from '@symplete/symplete-ui';

import noOp from 'shared/utils/noOp';

import Typography from 'ui/Typography';
import Tooltip from 'ui/Tooltip';

import PropertyImageCell from 'shared/components/UI/TableCells/RealtorCells/PropertyImageCell';

import usePropertyData from 'sections/MLS/hooks/usePropertyData';

import {
  Root,
} from './styledItems';

const PropertyPreviewTile = (props) => {
  const {
    propertyId,
    onClick,
    isSelected,
  } = props;

  const propertyData = usePropertyData(propertyId);

  const address = propertyData?.address?.address;

  const price = useMemo(() => {
    if (!propertyData?.expected_price) return null;

    return currency.full(propertyData?.expected_price);
  }, [
    propertyData?.expected_price,
  ]);

  const handleClick = useCallback((event) => {
    event.preventDefault();
    onClick();
  }, [
    onClick,
  ]);

  return (
    <Root
      rounded="m"
      hideOverflow
      isSelected={isSelected}
    >
      <NakedButton
        onClick={handleClick}
        style={{ width: '100%' }}
      >
        <PropertyImageCell
          propertyId={propertyId}
          rounded={false}
          height="75px"
        />
        <div style={{ padding: 10 }}>
          {
            address && (
              <Tooltip
                title={address}
              >
                <Typography
                  variant="title3"
                  weight="bold"
                  ellipsis
                >
                  {address || ' '}
                </Typography>
              </Tooltip>
            )
          }

          {
            !address && (
              <Typography
                variant="title3"
                weight="bold"
                ellipsis
              >
                {address || 'No address'}
              </Typography>
            )
          }

          <Typography
            align="center"
            noMargin
          >
            {price || '$?'}
          </Typography>
        </div>
      </NakedButton>
    </Root>
  );
};

PropertyPreviewTile.defaultProps = {
  onClick: noOp,
  isSelected: false,
};

const {
  bool,
  func,
  number,
} = PropTypes;

PropertyPreviewTile.propTypes = {
  onClick: func,
  isSelected: bool,
  propertyId: number.isRequired,
};

export default PropertyPreviewTile;
