import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';

import Typography from 'ui/Typography';
import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';

import ContactAvatar from 'sections/Prospects/modules/ContactAvatar';
import useContactName from 'shared/hooks/contacts/useContactName';

const MainContactInfo = (props) => {
  const {
    contactId,
    email,
    phone,
    role,
  } = props;

  return (
    <Flex
      justifyContent="stretch"
      alignItems="stretch"
      spacing={1}
    >
      <ContactAvatar
        contactId={contactId}
      />
      <Flex
        flexDirection="column"
        justifyContent="space-between"
      >
        <Typography
          variant="title3"
          weight="bold"
          noMargin
        >
          {useContactName(contactId)}
        </Typography>
        {
          role && (
            <Typography
              variant="subtitle2"
              color="descriptor-text"
              noWrap
              noMargin
            >
              {role}
            </Typography>
          )
        }
        {
          email && (
            <DecoratedFieldWrapper
              iconName="mail"
              iconType="feather"
              variant="focus"
              className="information-item"
              size="xs"
            >
              <Typography
                variant="subtitle2"
                noWrap
                noMargin
              >
                {email}
              </Typography>
            </DecoratedFieldWrapper>
          )
        }
        {
          phone && (
            <DecoratedFieldWrapper
              iconName="phone"
              iconType="feather"
              variant="focus"
              className="information-item"
              size="xs"
            >
              <Typography
                variant="subtitle2"
                noWrap
                noMargin
              >
                {phone}
              </Typography>
            </DecoratedFieldWrapper>
          )
        }
      </Flex>
    </Flex>
  );
};

const {
  number,
  string,
} = PropTypes;

MainContactInfo.propTypes = {
  contactId: number.isRequired,
  email: string.isRequired,
  phone: string.isRequired,
  role: string.isRequired,
};

export default MainContactInfo;
