import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import Typography from 'ui/Typography';

import StageItemLayout from 'sections/Transactions/components/StageItemLayout';

import TransactionStageNotes from 'sections/Transactions/modules/TransactionStageNotes';

import CurrencyInput from 'ui/CurrencyInput';

import DateInput from 'shared/inputs/DateInput';

import AttachmentsModule from 'shared/AttachmentsV3';

const InitialDocuments = (props) => {
  const {
    transactionId,
    depositAmount,
    onDepositAmountChange,

    depositReceivedAt,
    onDepositReceivedAtChange,

    attachments,
    refreshAttachments,
  } = props;

  const documentsComponent = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h3" noMargin>
          Documents
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <AttachmentsModule
          selectedAttachments={attachments}
          objectType={41}
          objectId={transactionId}
          attachmentType={13}
          onAfterUpload={refreshAttachments}
          onAfterDelete={refreshAttachments}
        />
      </Grid>
    </Grid>
  );

  const detailsComponent = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3">
              Deposit
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <CurrencyInput
              value={depositAmount}
              onChange={onDepositAmountChange}
              label="Deposit amount"
            />
          </Grid>
          <Grid item xs={6}>
            <DateInput
              decorated
              label="Deposit received"
              value={depositReceivedAt}
              handleChange={onDepositReceivedAtChange}
              onReset={() => onDepositReceivedAtChange(null)}
            />
          </Grid>
        </Grid>

      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3">
          Notes
        </Typography>
        <TransactionStageNotes
          transactionId={transactionId}
          stage="initial documents"
        />
      </Grid>
    </Grid>
  );

  return (
    <StageItemLayout
      documentsComponent={documentsComponent}
      detailsComponent={detailsComponent}
    />
  );
};

InitialDocuments.defaultProps = {

};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

InitialDocuments.propTypes = {

};

export default InitialDocuments;
