import React, {
  useContext,
  useCallback,
  useEffect
} from 'react';

import { toCanvas } from 'html-to-image';

import { currency } from '@symplete/symplete-utils/numbers';

import requests from 'api/requests';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import editorContext from 'ui/TextEditor/context';

import TemplateItemLabel from 'sections/Communications/modules/Marketing/TemplateComposer/TemplateItemLabel';

import useProperty from 'sections/Properties/hooks/useProperty';
import usePropertyMainImage from 'sections/Properties/hooks/usePropertyMainImage';
import useAddress from 'sections/Addresses/hooks/useAddress';

import PropertyBriefInfo from 'sections/Properties/modules/PropertyBriefInfo';

const formatData = (property, address) => {
  const {
    bathrooms,
    bedrooms,
    plot_area,
    floor_area,
    parking,
    expected_price,
  } = property;

  return [
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: `Address: ${address}`,
        },
      ],
    },
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: `Price: ${currency.full(expected_price)}`,
        },
      ],
    },
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: `Bedrooms: ${bedrooms}`,
        },
      ],
    },
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: `Bathrooms: ${bathrooms}`,
        },
      ],
    },
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: `Parking: ${parking}`,
        },
      ],
    },
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: `Floor area: ${floor_area}`,
        },
      ],
    },
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: `Plot area: ${plot_area}`,
        },
      ],
    },
  ];
};

const getRefImage = async (ref) => {
  const element = ref.current;
  const canvas = await toCanvas(element);
  const data = canvas.toDataURL('image/jpg');

  return data;
};

const insertContent = (variant) => (editor) => async (property, address, propertyImageSrc, propertyCardRef) => {
  if (variant === 'details') {
    try {
      editor
        .chain()
        .focus()
        .insertContent(formatData(property, address))
        .run();
    } catch (error) {
      console.log(error);
    }
  }

  if (variant === 'image') {
    if (!propertyImageSrc) return;

    let imageSrc;

    try {
      const response = await requests.images.base64(propertyImageSrc);
      imageSrc = response.data;
    } catch (error) {
      return;
    }

    try {
      editor
        .chain()
        .focus()
        .setImage(imageSrc)
        .run();
    } catch (error) {
      console.log(error);
    }
  }

  if (variant === 'card') {
    let imageSrc;

    try {
      imageSrc = await getRefImage(propertyCardRef);
    } catch (error) {
      return;
    }

    try {
      editor
        .chain()
        .focus()
        .setImage({
          src: imageSrc,
          alt: 'Property card',
          })
        .run();
    } catch (error) {
      console.log(error);
    }
  }
};

const PropertyDetails = (props) => {
  const {
    propertyId,
    location,
  } = props;

  const printRef = React.useRef();

  const property = useProperty(propertyId);

  const propertyMainImage = usePropertyMainImage(propertyId);

  const {
    address_id,
  } = property;

  const address = useAddress(address_id);

  const { editor } = useContext(editorContext);

  const onAdd = (variant) => async () => {
    if (editor) {
      await insertContent(variant)(editor)(property, address, propertyMainImage?.src, printRef);
    }
  };

  // if location is set, manually add card
  useEffect(() => {
    if (location) {
      // delay to allow editor to load
      // TOOD: if image exist, remove
      setTimeout(() => {
        onAdd('card')();
      }, 500);
    }
  }, [location]);

  return (
    <Flex
      spacing={1}
      flexWrap="wrap"
    >
      <TemplateItemLabel
        variant="emailTemplateProperty"
        onClick={onAdd('details')}
      >
        PROPERTY DETAILS
      </TemplateItemLabel>
      {
        propertyMainImage?.src && (
          <TemplateItemLabel
            variant="emailTemplateProperty"
            onClick={onAdd('image')}
          >
            PROPERTY IMAGE
          </TemplateItemLabel>
        )
      }
      {
        true && (
          <>
            <TemplateItemLabel
              variant="emailTemplateProperty"
              onClick={onAdd('card')}
            >
              PROPERTY CARD
            </TemplateItemLabel>
            <FlexItem fullWidth>
              <div ref={printRef}>
                <PropertyBriefInfo
                  propertyId={propertyId}
                />
              </div>
            </FlexItem>
          </>
        )
      }
    </Flex>
  );
};

export default PropertyDetails;
