import styled from 'styled-components';
import DividerUi from 'ui/Divider';

export const ComposerWrapper = styled.div`
  height: 400px;
`;

export const Divider = styled(DividerUi)`
  margin-top: 20px;
`;
