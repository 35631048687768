import React, {
  useCallback,
} from 'react';

import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

import KanbanView from 'shared/components/UI/KanbanView';

import KanbanCard from 'sections/Prospects/modules/KanbanCard';

const ProspectsKanban = (props) => {
  const {
    lanes,
  } = props;

  const history = useHistory();

  const handleCardClick = useCallback((item) => {
    if (item.type === 'client') {
      history.push(`/clients/${item.id}`);
    }
  });

  return (
    <div style={{ height: 700 }}>
      <KanbanView
        lanes={lanes}
        CardComponent={KanbanCard}
        onCardClick={handleCardClick}
      />
    </div>
  );
};

ProspectsKanban.defaultProps = {

};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

ProspectsKanban.propTypes = {

};

export default ProspectsKanban;
