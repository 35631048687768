import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateSelectedProperty,
  overwritePropertyRelations,
} from 'store/actions/properties';

const withSelectedProperty = (Component) => {
  const mapStateToProps = (state) => {
    const {

    } = state;

    const property = selectors.properties.selected(state);

    return {
      property,
    };
  };

  const mapDispatchToProps = (dispatch) => ({
    updateProperty: (propertyId) => (fieldName) => (value) => {
      if (fieldName === 'views') {
        dispatch(overwritePropertyRelations(fieldName)(propertyId)(value));
      } else {
        dispatch(updateSelectedProperty({ [fieldName]: value }));
      }
    },
  });

  const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    onChange: (fieldName) => (value) => {
      dispatchProps.updateProperty(stateProps.property.id)(fieldName)(value);
    },
  });

  return connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
};

export default withSelectedProperty;
