import { connect } from 'react-redux';

import datetime from '@symplete/symplete-utils/datetime';

import { updateOffer } from 'store/actions/realtorOffers';

import DeclineOfferForm from './DeclineOfferForm';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: async (formData) => {
    const {
      offerId,
      onSuccess,
      onError,
    } = ownProps;

    const {
      declineReason,
    } = formData;

    const offerData = {
      declined_at: datetime().format(),
      decline_reason: declineReason,
    };

    try {
      await dispatch(updateOffer(offerId)(offerData));

      if (onSuccess) {
        onSuccess(offerId);
      }
    } catch (error) {
      if (onError) {
        onError(error);
      }
    }
  },
});

export default connect(null, mapDispatchToProps)(DeclineOfferForm);
