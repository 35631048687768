import { connect } from 'react-redux';

import Property from './Property';

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps.match.params;

  return {
    propertyId: id,
  };
};

export default connect(mapStateToProps)(Property);
