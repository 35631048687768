import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';
import FlexItem from 'ui/FlexItem';
import Icon from 'ui/Icon';
import Elevation from 'ui/Elevation';

import Campaigns from 'sections/Communications/modules/Marketing/Campaigns';
import Toolbar from 'sections/Communications/modules/Marketing/Toolbar';
import Create from 'sections/Communications/modules/Marketing/Create';

import NewEmailCampaignProvider from 'sections/Communications/providers/NewEmailCampaignProvider';

import useCampaigns from 'sections/Communications/hooks/useCampaigns';
import useIntegrations from 'sections/Integrations/hooks/useIntegrations';

const Marketing = (props) => {
  const {
    campaignsTotal,
  } = useCampaigns();

  const {
    scopes,
  } = useIntegrations();

  // const hasEmailConnected = scopes?.google?.has('email');
  const hasEmailConnected = true;

  const hasEmailSent = campaignsTotal > 0;

  return (
    <Elevation
      elevation={2}
      rounded={2}
      padding={'10px'}
      fullWidth
    >
      <Flex
        fullWidth
        alignItems="center"
        spacing={2}
        padding={'10px'}
      >
        <NewEmailCampaignProvider>
          <Flex
            fullWidth
            flexDirection="column"
            spacing={3}
          >
            {
              hasEmailConnected && hasEmailSent ? 
                <>
                  <Toolbar />
                  <Campaigns />
                </> : (
                <>
                  <Flex
                    fullWidth
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                  >
                    <Create isDisabled={!hasEmailConnected} />
                  </Flex>
                  <Flex
                    fullWidth
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    flexDirection="column"
                  >
                    <Icon
                      name={'emailMarketingIcon'}
                      type="custom"
                      size="120"
                    />
                    <Typography
                      variant="title2"
                      align="center"
                    >
                      {
                        hasEmailConnected && !hasEmailSent ? 
                          <span>You haven't sent any marketing emails yet. <br/> Click the New button to get started!</span> : 
                          <span>Connect your email address before you can send marketing emails.</span>
                      }
                    </Typography>
                  </Flex>
                </>
              )   
            }
          </Flex>
        </NewEmailCampaignProvider>
      </Flex>
    </Elevation>
  );
};

Marketing.defaultProps = {
};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

Marketing.propTypes = {
};

export default Marketing;
