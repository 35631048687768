import { connect } from 'react-redux';

import { history } from 'store';
import selectors from 'store/selectors';

import {
  openModal,
} from 'store/actions/modals';

import {
  updateLead,
  deleteLead,
} from 'store/actions/realtorLeads';

import {
  convert,
} from 'sections/Leads/actions';

import OptionsV2 from './OptionsV2';

const mapStateToProps = (state, ownProps) => {
  const lead = selectors.realtorLeads.byId(state, ownProps.leadId);

  const name = selectors.realtorContacts.contactName(state, lead?.contact_id, ['firstName', 'lastName']);

  return {
    status: lead.status,
    contactId: lead?.contact_id,
    name,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onStatusChange: (status) => {
    dispatch(updateLead(ownProps.leadId)({ status }));
  },
  onFollowUp: () => {
    dispatch(openModal('followUpRemindersNew')({
      objectTypeId: 26,
      objectId: ownProps.leadId,
    }));
  },
  onCall: (name, contactId) => {
    dispatch(openModal('call')({
      name,
      contactId,
    }));
  },
  onDelete: () => {
    dispatch(deleteLead(ownProps.leadId));
    history.push('/leads');
  },
});

const mergeProps = (state, dispatch, own) => ({
  ...state,
  ...dispatch,
  ...own,
  onCall: () => {
    dispatch.onCall(state.name, state.contactId);
  },
  onConvert: () => {
    convert(own.leadId)(own.selectedItem?.type)(own.selectedItem?.itemId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(OptionsV2);
