import styled from 'styled-components';

export const ReminderInputContainer = styled.div`
  .new-reminder-button {
    display: ${({ showEditor }) => (showEditor ? 'none' : 'block')};
  }

  .text-editor {
    display: ${({ showEditor }) => (showEditor ? 'block' : 'none')};
    width: 522px;
    .article-edit {
      min-height: 30px;
    }
  }
`;

export default { ReminderInputContainer };
