import React, {
  useCallback,
  useState,
} from 'react';

import PropTypes from 'prop-types';

import {
  DragDropContext,
} from 'react-beautiful-dnd';

import Flex from 'ui/Flex';

import useModal from 'ui/hooks/useModal';

import AssignInterestedBuyerModal from 'sections/Properties/modals/AssignInterestedBuyerModal';

import NewOfferModal from 'sections/Properties/modals/NewOfferModal';

import KanbanView from './KanbanView';

const statuses = {
  interestedParties: 1,
  showingsUpcoming: 2,
  showingsCompleted: 2,
  offers: 3,
  rejected: 5,
};

const highlightedContactStatus = ['buyer client'];

const PropertyEvents = (props) => {
  const {
    updateMatchingBuyer,

    onCreateShowing,

    propertyId,
  } = props;

  const {
    openModal: openInterestedBuyerModal,
    closeModal: closeInterestedBuyerModal,
    isModalVisible: isInterestedBuyerModalVisible,
  } = useModal();

  const {
    openModal: openOfferModal,
    closeModal: closeOfferModal,
    isModalVisible: isOfferModalVisible,
  } = useModal();

  const [currentMatch, setCurrentMatch] = useState(null);

  const onMatchingBuyerDrop = useCallback(({
    draggableId: matchingItemId,
    destination,
    source,
  }) => {
    if (destination?.droppableId !== source?.droppableId) {
      const oldStatus = statuses[source?.droppableId];
      const newStatus = statuses[destination?.droppableId];

      setCurrentMatch(matchingItemId);

      if (newStatus === statuses.offers) {
        openOfferModal();
      } else if (newStatus) {
        updateMatchingBuyer(matchingItemId)(
          // update data
          {
            status: newStatus,
          },
          // current data
          {
            status: oldStatus,
          },
          // board data
          {
            source,
            destination,
          },
        );
      }
    }
  }, [
    updateMatchingBuyer,
    setCurrentMatch,
    openOfferModal,
  ]);

  return (
    <>
      <DragDropContext
        onDragEnd={onMatchingBuyerDrop}
      >
        <Flex
          spacing={4}
          flexWrap="wrap"
          fullHeight
        >
          <KanbanView
            propertyId={propertyId}
            onAdd={{
              interestedParties: openInterestedBuyerModal,
              showingsUpcoming: onCreateShowing('upcoming'),
              showingsCompleted: onCreateShowing('completed'),
              offers: openOfferModal,
            }}
          />
        </Flex>
      </DragDropContext>

      <AssignInterestedBuyerModal
        propertyId={propertyId}
        closeModal={closeInterestedBuyerModal}
        isVisible={isInterestedBuyerModalVisible}
        title="Add interested buyers"
        highlightedContactStatus={highlightedContactStatus}
        withAddContact
      />

      <NewOfferModal
        isVisible={isOfferModalVisible}
        closeModal={closeOfferModal}
        propertyId={propertyId}
        matchId={currentMatch}
      />
    </>
  );
};

const {
  func,
  number,
} = PropTypes;

PropertyEvents.propTypes = {
  updateMatchingBuyer: func.isRequired,
  onAddInterestedParty: func.isRequired,
  onCreateShowing: func.isRequired,
  propertyId: number.isRequired,
};

export default PropertyEvents;
