import { connect } from 'react-redux';

import selectors from 'store/selectors';

import { debounce } from 'shared/utility';

import {
  setFilterItem,
  handleCustomFilter,
  clearFilter,
} from 'store/actions/realtorLeadsFilter';

import {
  getLeadsForStoreFilter,
} from 'store/actions/realtorLeads';

import SearchFilter from 'ui/SearchFilter';

const mapStateToProps = (state) => {
  const {
    realtorLeadsFilter,
  } = state;

  const filterKey = selectors.realtorLeads.leadsFilterKey(state);

  return {
    searchValue: realtorLeadsFilter.search || '',
    customFilter: filterKey,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSearch: debounce((search) => {
    dispatch(setFilterItem('search')(search));
    dispatch(getLeadsForStoreFilter());
  }, 500),
  handleCustomFilter: (key) => {
    dispatch(clearFilter());
    dispatch(handleCustomFilter(key));
    dispatch(getLeadsForStoreFilter());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchFilter);
