import React from 'react';

import AttachmentsUpload from 'shared/inputs/InlineAttachmentsUpload';

import withInspectionsAttachments from 'sections/Transactions/modules/Inspections/hocs/withInspectionsAttachments/index';

const AttachmentCell = withInspectionsAttachments(AttachmentsUpload);

export default (props) => (
  <AttachmentCell
    objectId={props.inspectionId}
    multiple
    itemSize="s"
  />
);
