import React, {
  useCallback,
} from 'react';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { debounce } from 'shared/utility';

import TextEditor from 'ui/TextEditor';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Elevation from 'ui/Elevation';
import Spin from 'ui/Spin';
import Typography from 'ui/Typography';
import Textfield from 'ui/Textfield';
import AreaHighlight from 'ui/AreaHighlight';
import Button from 'ui/Button';

import useCampaign from 'sections/Communications/hooks/useCampaign';
import useTemplate from 'sections/Communications/hooks/useTemplate';
import TemplateItems from './TemplateItems';
import PropertyDetails from './PropertyDetails';
import Editor from './Editor';
import TemplateName from './TemplateName';

const TemplateComposer = (props) => {
  const {
    campaignId,
  } = props;

  const {
    campaign,
    update: onCampaignUpdate,
  } = useCampaign(campaignId);

  const {
    template,
    update: onTemplateUpdate,
  } = useTemplate(campaign?.template_id);

  const updateCampaign = useCallback((key) => debounce((value) => {
    onCampaignUpdate({
      [key]: value,
    });
  }, 1000), [
    onCampaignUpdate,
  ]);

  const updateTemplate = useCallback((key) => debounce((value) => {
    onTemplateUpdate({
      [key]: value,
    });
  }, 1000), [
    onTemplateUpdate,
  ]);

  if (!campaignId || !campaign?.id) {
    return (
      <Flex
        fullWidth
        justifyContent="center"
      >
        <Spin />
      </Flex>
    );
  }

  return (
    <TextEditor.ContextProvider
      onChange={updateCampaign('email_body')}
      content={campaign?.email_body || ''}
    >
      <DndProvider
        backend={HTML5Backend}
      >
        <Flex
          spacing={3}
          fullWidth
          alignItems="stretch"
        >
          <FlexItem
            xs={10}
            fullHeight
          >
            <Elevation
              rounded="m"
              padding="m"
              fullHeight
            >
              <Typography
                variant="title3"
              >
                Click to add a smart object to your email
              </Typography>
              <Flex
                fullHeight
                fullWidth
                spacing={2}
                flexDirection="column"
              >
                <TemplateItems.Recipient />
                <TemplateItems.Sender />
                <TemplateItems.Misc />
                {
                  campaign?.realtorPropertyId && (
                    <PropertyDetails
                      propertyId={campaign?.realtorPropertyId}
                    />
                  )
                }
              </Flex>
            </Elevation>
          </FlexItem>
          <FlexItem
            xs={14}
            fullHeight
          >
            <Elevation
              rounded="m"
              padding="m"
              fullHeight
            >
              <Flex
                fullWidth
                flexDirection="column"
                spacing={2}
              >
                <AreaHighlight
                  variant="background-flat"
                >
                  <Flex
                    padding="8px"
                  >
                    <Textfield
                      fullWidth
                      label="Email Subject"
                      value={campaign.email_subject}
                      onChange={updateCampaign('email_subject')}
                    />
                  </Flex>
                </AreaHighlight>
                {
                  template.created_by_type === 0
                  ? (
                    <Typography
                      ellipsis
                      variant="title2"
                    >
                      {template?.name}
                    </Typography>
                    )
                  : (
                    <TemplateName
                      onChange={updateTemplate('name')}
                    >
                      {template?.name}
                    </TemplateName>
                    )
                }
                <Editor />
              </Flex>
            </Elevation>
          </FlexItem>
        </Flex>
      </DndProvider>
    </TextEditor.ContextProvider>
  );
};

export default TemplateComposer;
