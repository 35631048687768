import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Box,
  Description,
} from './styledItems';

const SellerAlert = () => (
  <Box>
    <FontAwesomeIcon
      icon={['far', 'check-circle']}
      color="selected"
      style={{
        color: '#2ecc71',
        fontSize: 15,
        marginRight: 12,
      }}
    />
    <Description
      color="selected"
      weight={500}
      noMargin
    >
      You are the seller agent for this property
    </Description>
  </Box>
);

export default SellerAlert;
