import styled from 'styled-components';

export const NoContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  width: 116px;
  height: 116px;
  background-color: ${(props) => props.theme.palette['background-flat']};
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    filter: contrast(105%);
  }
`;

export const HoverContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  background-color: ${(props) => props.theme.palette['background-overlay-dark']};
  border-radius: 8px;
  padding-bottom: 2px;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 900;
  cursor: pointer;
`;
