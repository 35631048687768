import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';

import {
  Root,
  Center,
} from './styledItems';

const ProgressCircle = (props) => {
  const {
    children,
    progress,
    value,
    label,
    warning,
  } = props;

  return (
    <Root
      progress={progress}
      warning={warning}
    >
      <Center>
        { children }
        { !children && (
        <Flex
          alignItems="center"
          justifyContent="center"
          fullHeight
        >
          <div>
            {
              (value || value === 0) && (
                <Typography
                  align="center"
                  variant="title3"
                  noMargin
                  flat
                >
                  {value}
                </Typography>
              )
            }
            {
              label && (
                <Typography
                  align="center"
                  variant="caption2"
                  noMargin
                  flat
                >
                  {label}
                </Typography>
              )
            }
          </div>
        </Flex>
          )}
      </Center>
    </Root>
  );
};

ProgressCircle.defaultProps = {
  progress: 0,
  children: null,
  value: null,
  label: null,
  warning: null,
};

const {
  element,
  number,
  string,
} = PropTypes;

ProgressCircle.propTypes = {
  /**
   * In 0-1 range
   * */
  progress: number,
  children: element,
  value: string,
  label: string,
  /**
   * Show warning if progress <= provided value
   */
  warning: number,
};

export default ProgressCircle;
