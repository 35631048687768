import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import Button from 'ui/Button';
import Tooltip from 'ui/Tooltip';

const ActionButtons = (props) => {
  const {
    onClickBook,
    onClickMatch,
    onClickFavorite,
    onClickArchive,
  } = props;

  return (
    <Flex
      justifyContent="flex-end"
      spacing={1}
    >
      <Tooltip title="Add Buyer">
        <Button
          variant="primary"
          icon="investment"
          iconType="custom"
          onClick={onClickMatch}
        />
      </Tooltip>
      <Tooltip title="Book Showing">
        <Button
          variant="primary"
          icon="newViewing"
          iconType="custom"
          onClick={onClickBook}
        />
      </Tooltip>
      <Tooltip
        title="Convert to Client"
      >
        <Button
          variant="primary"
          icon="star"
          onClick={onClickFavorite}
        />
      </Tooltip>
      <Tooltip title="Archive">
        <Button
          variant="primary"
          icon="archive"
          onClick={onClickArchive}
        />
      </Tooltip>
    </Flex>
  );
};

const {
  func,
} = PropTypes;

ActionButtons.propTypes = {
  onClickBook: func.isRequired,
  onClickMatch: func.isRequired,
  onClickFavorite: func.isRequired,
  onClickArchive: func.isRequired,
};

export default ActionButtons;
