import React from 'react';
import PropTypes from 'prop-types';

import withSelectedProperty from 'sections/MLS/hocs/withSelectedProperty';

import Textfield from 'ui/Textfield';

import withDebouncedCallbacks from 'shared/hocs/withDebouncedCallbacks';

const Input = withDebouncedCallbacks(Textfield, [[
  'onChange',
  1000,
]]);

const ListingRemarks = (props) => {
  const {
    onChange,
    property,
  } = props;

  return (
    <Input
      textarea
      onChange={onChange('listing_remarks')}
      value={property?.listing_remarks || ''}
    />
  );
};

ListingRemarks.defaultProps = {
};

const {
  func,
  shape,
} = PropTypes;

ListingRemarks.propTypes = {
  onChange: func.isRequired,
  property: shape().isRequired,
};

export default withSelectedProperty(ListingRemarks);
