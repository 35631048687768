import React from 'react';
import PropTypes from 'prop-types';

import {
  Tooltip,
} from 'antd';

import { url } from 'paths';

import { NakedButton } from '@symplete/symplete-ui';

import { last, isBoolean } from 'shared/utility';

import AvatarLogoCrop from '../AvatarLogoCrop'; // eslint-disable-line import/no-cycle

import './ImageUploadWrapper.scss';

const sortByDate = (a, b) => new Date(a.CreatedAt) - new Date(b.CreatedAt);

class ImageUploadWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      avatarFileId: '',
      companyFileId: '',
      firstLoad: true,
    };
  }

  componentDidMount() {
    const { logoUrl } = this.props;
    if (logoUrl !== '') {
      const fileSrc = `${url}${logoUrl}`;
      const src = this.getThumbUrl(fileSrc);
      this.setState({
        companyFileSrc: src,
      });
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const {
      appModule,
      getFileList,
      objectId,
    } = this.props;

    const {
      firstLoad,
    } = this.state;

    if (nextProps && nextProps.objectId) {
      let fileSrc;
      let newFileId;
      let src;

      const { fileLists } = nextProps;

      const fileList = fileLists[appModule];

      if (fileList && fileList.length) {
        const sortedByDataUpload = fileList.sort(sortByDate);

        const filteredByAppModule = sortedByDataUpload.filter((file) => {
          const {
            ContentObjectId,
            ContentType,
          } = file;

          const belongsToModule = ContentType === appModule;
          let belongsToUser = true;

          if (appModule === 'employee-logo' || appModule === 'company-leads-logo') {
            belongsToUser = ContentObjectId === objectId;
          }

          return belongsToModule && belongsToUser;
        });

        const lastAvatar = last(filteredByAppModule);

        if (lastAvatar) {
          fileSrc = `${url}${lastAvatar.Location}`;
          newFileId = lastAvatar.Uuid;
          src = this.getThumbUrl(fileSrc);

          if (appModule === 'employee-logo') {
            this.setState({
              avatarFileSrc: src,
              avatarFileId: newFileId,
            });
          } else {
            this.setState({
              companyFileSrc: src,
              companyFileId: newFileId,
            });
          }

          this.setState({
            firstLoad: false,
          });
        } else if (firstLoad) {
          getFileList();
        }
      } else {
        if (firstLoad) {
          getFileList();
        }

        if (appModule === 'employee-logo') {
          this.setState({
            avatarFileSrc: '',
            avatarFileId: '',
            firstLoad: false,
          });
        } else {
          this.setState({
            companyFileSrc: '',
            companyFileId: '',
            firstLoad: false,
          });
        }
      }
    }
  }

  componentWillUnmount() {
    this.setState({
      avatarFileSrc: '',
      avatarFileId: '',
      companyFileSrc: '',
      companyFileId: '',
    });
  }

  isStandard = () => {
    const {
      isStandard,
    } = this.props;

    return isBoolean(isStandard)
      ? isStandard
      : true;
  }

  getThumbUrl = (thumbUrl) => {
    const token = localStorage.getItem('token');

    return `${thumbUrl}?auth=${token}`;
  }

  deleteFile = (data, isStandard, cb) => {
    const {
      appModule,
      deleteFile,
    } = this.props;

    if (appModule === 'employee-logo') {
      this.setState({
        avatarFileId: '',
        avatarFileSrc: '',
        firstLoad: false,
      });
    } else {
      this.setState({
        companyFileSrc: '',
        companyFileId: '',
        firstLoad: false,
      });
    }

    deleteFile(data, isStandard, cb);
  }

  triggerCropModal = () => {
    const {
      triggerAvatarCropModal,
      visibleModule,
    } = this.props;

    triggerAvatarCropModal(true, visibleModule);
  }

  uploadAttachment = async (attachment) => {
    const {
      createAttachment,
    } = this.props;

    await createAttachment(attachment);
  }

  render() {
    const {
      appModule,
      cropAppModule,
      circularCrop,
      disableCrop,
      employee,
      getFileList,
      objectId,
      imageActionHooks,
      triggerAvatarCropModal,
      visible: modalVisible,
      visibleModule,
      children,
    } = this.props;

    let src;
    let fileId;
    let divClassName;

    const {
      avatarFileSrc,
      avatarFileId,
      companyFileSrc,
      companyFileId,
    } = this.state;

    if (appModule === 'employee-logo') {
      src = avatarFileSrc;
      fileId = avatarFileId;
      divClassName = 'avatar-logo';
    } else {
      src = companyFileSrc;
      fileId = companyFileId;
      divClassName = 'company-logo';
    }

    const visible = modalVisible && cropAppModule === visibleModule;

    return (
      <div className={divClassName}>
        <Tooltip>
          <NakedButton
            onClick={this.triggerCropModal}
          >
            {children}
          </NakedButton>
        </Tooltip>
        <AvatarLogoCrop
          employee={employee}
          imageActionHooks={imageActionHooks}
          appModule={appModule}
          imageObjectId={objectId}
          fileId={fileId}
          src={src}
          circularCrop={circularCrop}
          visible={visible}
          hideModal={triggerAvatarCropModal}
          deleteFile={this.deleteFile}
          getFileList={getFileList}
          disableCrop={disableCrop}
          createAttachment={this.uploadAttachment}
        />
      </div>
    );
  }
}

ImageUploadWrapper.defaultProps = {
  appModule: 'employee-logo',
  circularCrop: true,
  visibleModule: 'user-avatar',
  disableCrop: false,
  fileLists: [],
  isStandard: true,
  cropAppModule: true,
  visible: true,
};

const {
  array,
  bool,
  element,
  func,
  number,
  object,
  shape,
  string,
} = PropTypes;

// TODO - elaborate on proptypes
ImageUploadWrapper.propTypes = {
  appModule: string,
  children: element.isRequired,
  circularCrop: bool,
  createAttachment: func.isRequired,
  cropAppModule: bool,
  deleteFile: func.isRequired,
  disableCrop: bool,
  employee: object.isRequired, // eslint-disable-line react/forbid-prop-types
  fileLists: array, // eslint-disable-line react/forbid-prop-types
  getFileList: func.isRequired,
  isStandard: bool,
  logoUrl: string.isRequired,
  objectId: number.isRequired,
  triggerAvatarCropModal: func.isRequired,
  visible: bool,
  visibleModule: string,

  imageActionHooks: shape({
    onAdd: func,
    onDelete: func,
  }).isRequired,
};

export default ImageUploadWrapper;
