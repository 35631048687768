import { connect } from 'react-redux';

import { currency } from '@symplete/symplete-utils/numbers';

import { debounce } from 'shared/utility';

import selectors from 'store/selectors';

import { updateProperty } from 'store/actions/properties';

import PropertyTab from './PropertyTab';

const mapStateToProps = (state, ownProps) => {
  const {
    itemId,
  } = ownProps;

  const property = selectors.properties.byId(state, itemId);

  const address = selectors.addresses.byId(state, property?.address_id);

  return {
    price: property?.expected_price
      ? currency.short(property.expected_price)
      : 'N/A',
    address: address?.address,
    opportunityName: property?.opportunity_name || '—',
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChangeOpportunityName: debounce((value) => {
    dispatch(updateProperty(ownProps.itemId)({ opportunity_name: value }));
  }, 1000),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyTab);
