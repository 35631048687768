import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'antd';

import Modal from 'ui/Modal';

import MatchingPropertyRejectionForm from 'sections/Prospects/forms/MatchingPropertyRejectionForm';

const formId = 'matching-property-rejection-form';

const MatchingPropertyRejectionModal = (props) => {
  const {
    theme,
    isVisible,
    closeModal,
  } = props;

  return (
    <Modal
      theme={theme}
      width={650}
      visible={isVisible}
      onCancel={closeModal}
      title="Move property to rejected?"
      footer={[
        <Button onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          type="primary"
          htmlType="submit"
          form={formId}
        >
          Save
        </Button>,
      ]}
    >
      <MatchingPropertyRejectionForm formId={formId} />
    </Modal>
  );
};

MatchingPropertyRejectionModal.defaultProps = {
  isVisible: false,
  theme: null,
};

const {
  bool,
  func,
  shape,
} = PropTypes;

MatchingPropertyRejectionModal.propTypes = {
  isVisible: bool,
  theme: shape(),
  closeModal: func.isRequired,
};

export default MatchingPropertyRejectionModal;
