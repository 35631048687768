import React from 'react';
import PropTypes from 'prop-types';

import CounterItemWithButton from 'shared/components/UI/CounterItemWithButton';

import Grid from 'ui/Grid';

import pluralize from 'pluralize';

import { map } from 'shared/utility';

import {
  Root,
} from './styledItems';

const transactionsKpiItems = {
  // expiringThisWeek: (value) => ({
  //   name: 'Expiring This Week',
  //   withActionButton: false,
  // }),
  addendumChange: (value) => ({
    name: `Addendum ${pluralize('Change', value)}`,
    withActionButton: true,
  }),
  repairRequests: (value) => ({
    name: `Repair ${pluralize('Request', value)}`,
    withActionButton: true,
  }),
  documentRequests: (value) => ({
    name: `Document ${pluralize('Request', value)}`,
    withActionButton: true,
  }),
  extensionRequest: (value) => ({
    name: `Extension ${pluralize('Request', value)}`,
    withActionButton: true,
  }),
  unansweredQuestions: (value) => ({
    name: `Unanswered ${pluralize('Question', value)}`,
    withActionButton: true,
  }),
};

const KPIIndicatorBar = (props) => {
  const {
    data,
    indicatorMainAreaActions,
    indicatorAddButtonActions,
  } = props;

  return (
    <Root>
      <Grid container spacing={2}>
        {
          map(data, (value, key) => (
            <Grid item xs="auto" key={key}>
              <CounterItemWithButton
                title={transactionsKpiItems[key](value).name}
                value={value}
                onMainAreaClick={indicatorMainAreaActions(key)}
                withActionButton={transactionsKpiItems[key](value).withActionButton}
                onAddButtonClick={indicatorAddButtonActions(key)}
              />
            </Grid>
          ))
        }
      </Grid>
    </Root>
  );
};

const {
  shape,
  number,
  func,
} = PropTypes;

KPIIndicatorBar.propTypes = {
  data: shape({
    expiringThisWeek: number,
    addendumChange: number,
    repairRequests: number,
    documentRequests: number,
    extensionRequest: number,
    unansweredQuestions: number,
  }).isRequired,
  indicatorMainAreaActions: func.isRequired,
  indicatorAddButtonActions: func.isRequired,
};

export default KPIIndicatorBar;
