import styled from 'styled-components';

import { get } from 'shared/utility';

import { defaultTheme } from '@symplete/symplete-ui';

const getThemeProperty = (themePropertyName, propsPropertyName, propsOverridePropertyName) => (props) => {
  if (props[propsOverridePropertyName]) return props[propsOverridePropertyName];

  const themeValue = get(props.theme, themePropertyName);

  if (themeValue) {
    return themeValue[props[propsPropertyName]] || themeValue.default;
  }

  const defaultThemeValue = get(defaultTheme, themePropertyName);

  return defaultThemeValue[props[propsPropertyName]] || defaultThemeValue.default;
};

export const Root = styled.span`
  font-family: ${getThemeProperty('typography.fontFamily', 'variant', 'overrideFontFamily')}; 
  font-size: ${getThemeProperty('typography.fontSize', 'variant', 'fontSize')}; 
  line-height: ${getThemeProperty('typography.lineHeight', 'variant', 'lineHeight')}; 
  font-weight: ${getThemeProperty('typography.fontWeight', 'variant', 'weight')}; 
  text-decoration: ${getThemeProperty('typography.textDecoration', 'variant', 'textDecoration')}; 
  font-style: ${getThemeProperty('typography.fontStyle', 'variant', 'fontStyle')}; 

  color: ${getThemeProperty('palette', 'color', 'overrideColor')}; 
  ${(props) => props.blur && 'color: transparent; text-shadow:  0 0 10px rgba(0,0,0,0.8);'}

  ${(props) => (props.align ? `text-align: ${props.align}` : '')};
  ${(props) => (props.noWrap ? 'white-space: nowrap' : '')};

  ${(props) => `margin-bottom: ${props.noMargin
    ? '0'
    : getThemeProperty('typography.marginBottom', 'variant')(props)}
  `};

  ${(props) => props.textTransform && `text-transform: ${props.textTransform};`}

  ${(props) => props.textTransform === 'uppercase' && 'letter-spacing: 0.07em;'}

  ${(props) => {
    if (props.ellipsis) {
      return `
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 100%;
      `;
    }
    if (props.multilineEllipsis) {
      return `
        text-overflow: ellipsis;
        overflow: hidden;
      `;
    }
    return '';
  }}

  ${(props) => props.flat && `line-height:  ${getThemeProperty('typography.fontSize', 'variant', 'fontSize')(props)};`}
`;

export default {
  Root,
};
