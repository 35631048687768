import React, {
  useEffect,
  useCallback,
  useState,
} from 'react';

import PropTypes from 'prop-types';

import { Table } from 'antd';

import './ViewingsTable.scss';

const ViewingsTable = (props) => {
  const {
    tableKey,
    getColumns,
    filterViewings,
    allViewings,
  } = props;

  const [viewings, setViewings] = useState([]);

  useEffect(() => {
    setViewings(filterViewings(allViewings));
  }, [
    allViewings,
  ]);

  const [hoveredRecord, setHoveredRecord] = useState(null);

  const onRow = useCallback((record, rowIndex) => ({
    onMouseEnter: (event) => {
      setHoveredRecord(record.key);
    },
    onMouseLeave: (event) => {
      setHoveredRecord(null);
    },
  }), [
    setHoveredRecord,
  ]);

  return (
    <Table
      key={tableKey}
      className="viewings-table"
      dataSource={viewings}
      columns={getColumns({ hoveredRecord })}
      onRow={onRow}
      tableLayout="fixed"
      size="middle"
      pagination={false}
    />
  );
};

ViewingsTable.defaultProps = {
  allViewings: [],
};

const {
  arrayOf,
  func,
  number,
  shape,
  string,
} = PropTypes;

ViewingsTable.propTypes = {
  tableKey: string.isRequired,
  allViewings: arrayOf(shape({
    key: number,
    id: number,
    contacts: arrayOf(number),
    date_time: string,
  })),
  filterViewings: func.isRequired,
  getColumns: func.isRequired,
};

export default ViewingsTable;
