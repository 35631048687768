import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isNumber } from 'shared/utility';

import {
  Icon,
  Modal as AntdModal,
  Spin,
  Tooltip,
  Upload,
} from 'antd';
import { callAlert } from 'ui/Alert';

import Button from 'ui/Button';
import Typography from 'ui/Typography';

import prettysize from 'prettysize';
import printJS from 'print-js';
import FileViewer from 'react-file-viewer';

import processAttachment from 'shared/utils/attachments/processAttachment';

import PreviewModal from './PreviewModal';
import ButtonsRow from './ButtonsRow';

import LinkInput from './LinkInput';

import {
  AttachmentTile,
  PreviewToolbar,
  MediaWrapper,
  AttachmentContainer,
  DetailsWrapper,
  TitleInput,
  AttachmentTileTitleWrapper,
  LinkInputContainer,
  ExternalLinksContainer,
  ExternalLink,
} from './styledItems';

import './Attachments.scss';

const { confirm } = AntdModal;
const DELETE_TITLE = 'Do you want to delete this attachment?';
const placeholderURL = '/assets/img/user-cropped-placeholder.png';

const initialState = {
  previewVisible: false,
  simplePreviewVisible: false,
  previewImage: '',
  previewThumbs: [],
  uploading: false,
  multipleFileList: [],
  uploadedFiles: [],
  crop: {
    aspect: 1,
  },
  name: '',
  hideCrop: true,
  previewExtension: null,
  statePreloader: false,
  previewFileId: null,
  previewedOriginalFileSize: 0,
  previewFileTitle: '',
};

const imageExtensions = {
  bmp: true,
  gif: true,
  jpg: true,
  jpeg: true,
  png: true,
  svg: true,
};

class Attachments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
    };
  }

  componentDidMount() {
    const {
      getFileList,
      objectType,
      objectId,
    } = this.props;

    if (objectType && objectId) {
      getFileList(
        objectType,
        objectId,
      );
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      objectType,
      objectId,
      fileCompressionProgress,
    } = this.props;

    // prevent thums update on progress update
    if (fileCompressionProgress !== nextProps.fileCompressionProgress) {
      return;
    }

    if (isNumber(+objectId) && objectId === nextProps.objectId && objectType === nextProps.objectType) {
      this.setState({
        previewThumbs: [],
        statePreloader: true,
      });

      const previewThumbs = nextProps.fileList.map(processAttachment);

      this.setState({
        previewThumbs,
        statePreloader: false,
      });
    } else {
      this.setState({
        previewThumbs: [],
        statePreloader: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      objectType,
      getFileList,
      objectId,
    } = this.props;

    if (objectId && objectId !== prevProps.objectId) {
      getFileList(
        objectType,
        objectId,
      );
    }
  }

  componentWillUnmount() {
    this.setState(initialState);
  }

  customRequest = (file) => {
    const {
      objectId,
      objectType,
      attachmentType,
    } = this.props;

    const data = {
      attachments: {
        file,
      },
      objectId,
      objectType,
      attachmentType,
    };

    this.uploadFile(data);
  };

  addLink = (url) => {
    const {
      objectId,
      objectType,
      attachmentType,
    } = this.props;

    const data = {
      objectId,
      objectType,
      attachmentType,
      linkUrl: url,
    };

    this.uploadFile(data);
  };

  deleteFile = async (attachmentId) => {
    const {
      deleteFile,
      objectId,
      objectType,
      attachmentType,
      onAfterDelete,
    } = this.props;

    await deleteFile(objectType, objectId, attachmentId);
    if (onAfterDelete) {
      onAfterDelete(attachmentId, attachmentType);
    }
  };

  uploadFile = async (data) => {
    const {
      uploadFile,
      objectId,
      objectType,
      attachmentType,
      onAfterUpload,
    } = this.props;

    const result = await uploadFile({
      objectId,
      objectType,
      attachmentType,
      file: data.attachments?.file?.file,
      linkUrl: data.linkUrl,
    });

    if (onAfterUpload) {
      onAfterUpload(result);
    }
  };

  updateTitle = (value) => {
    const {
      objectId,
      objectType,
      updateAttachmentTitle,
    } = this.props;

    const {
      previewFileId,
    } = this.state;

    updateAttachmentTitle(
      { objectType, objectId, attachmentId: previewFileId },
      value,
    );
  };

  hidePreviewModal = () => {
    this.setState({
      simplePreviewVisible: false,
    });
  };

  showDeleteConfirm = (fileId) => {
    const {
      blockRemove,
    } = this.props;

    if (blockRemove) {
      return;
    }

    const PREVIEW_MODAL_STATE_CLEARANCE_TIMEOUT = 0.1 * 1000;

    confirm({
      title: DELETE_TITLE,
      onOk: () => {
        this.deleteFile(fileId);
        this.setState({
          simplePreviewVisible: false,
        }, () => {
          // Using timeout here so users don't see an error message in file previewer
          setTimeout(() => {
            this.setState({
              previewImage: null,
              previewExtension: null,
            });
          }, PREVIEW_MODAL_STATE_CLEARANCE_TIMEOUT);
        });
      },
      onCancel() { },
    });
  };

  resetPreview = () => {
    this.setState({
      previewImage: null,
      previewFileId: null,
    });
  };

  chooseAttachment = (file) => async () => { // eslint-disable-line consistent-return
    try {
      this.setState({
        previewImage: file.previewUrl,
        previewExtension: file.extension,
        previewFileId: file.attachmentId,
        previewedOriginalFileDownloadUrl: file.url,
        previewedOriginalFileSize: file.size,
        previewFileTitle: file.title,
      });
    } catch (error) {
      callAlert.error('There is a problem with this attachment');
    }
  };

  openPreview = () => {
    const {
      previewFileId,
    } = this.state;

    if (!previewFileId) {
      return;
    }

    this.setState({
      simplePreviewVisible: true,
    });
  };

  handleCancel = () => {
    this.setState(initialState);
    this.deleteUploadedFiles();
  };

  onUploadRemove = (...params) => {
    const {
      previewFileId,
    } = this.state;

    this.showDeleteConfirm(previewFileId);
    return false;
  }

  downloadAttachment = () => {
    const {
      previewedOriginalFileDownloadUrl,
    } = this.state;

    const a = document.createElement('a');
    a.href = previewedOriginalFileDownloadUrl;
    a.download = true;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  renderPreviewFooter = () => {
    const {
      previewedOriginalFileSize,
    } = this.state;

    return (
      <PreviewToolbar className="attachment-preview-toolbar">
        <Button
          variant="secondary"
          onClick={this.onUploadRemove}
          icon="trash2"
        />
        <Button
          onClick={this.downloadAttachment}
          variant="primary"
          icon="download"
        >
          Download
          {' '}
          {prettysize(previewedOriginalFileSize)}
        </Button>
      </PreviewToolbar>
    );
  }

  renderPreview = () => {
    const {
      previewImage: previewImageState,
      simplePreviewVisible,
      previewExtension,
    } = this.state;

    const previewImage = previewImageState || placeholderURL;

    return (
      <PreviewModal
        isVisible={simplePreviewVisible}
        onCancel={this.hidePreviewModal}
        previewFileUrl={previewImage}
        previewFileExtension={previewExtension}
        FooterComponent={this.renderPreviewFooter} // eslint-disable-line react/jsx-no-bind
      />
    );
  }

  printAttachment = (attachmentLink, extension) => () => {
    let type;

    if (imageExtensions[extension]) {
      type = 'image';
    } else if (extension === 'pdf') {
      type = 'pdf';
    }

    if (type) {
      printJS(attachmentLink, type);
    } else {
      const w = window.open(attachmentLink);
      w.print();
    }
  }

  render() {
    const {
      uploadFileLimit,
      preloader,
      fileCompressionProgress,
      fileList,
    } = this.props;

    const {
      previewThumbs,
      statePreloader,
      previewImage: previewImageState,
      previewFileId,
      previewFileTitle,
      previewedOriginalFileDownloadUrl,
      previewExtension,
      simplePreviewVisible,
    } = this.state;

    const isImage = imageExtensions[previewExtension];

    const uploadListType = fileList && fileList.length > 0 ? 'picture-card' : null;

    const uploadButton = fileList && fileList.length > 0
      ? (
        <div>
          <Icon type="plus" />
        </div>
      )
      : (
        <Button
          icon="upload"
          variant="primary"
        >
          Upload
        </Button>
      );

    const loadingTip = fileCompressionProgress
      ? `Compressing file: ${fileCompressionProgress}%`
      : 'Loading...';

    const filesPreviewThumbs = previewThumbs.filter((item) => !item.isExternalLink);
    const externalLinks = previewThumbs.filter((item) => item.isExternalLink);

    return (
      <div>
        {!filesPreviewThumbs.length && uploadFileLimit === 0 ? null : (
          <Spin
            tip={loadingTip}
            spinning={preloader || statePreloader}
          >
            <DetailsWrapper>
              {
                previewFileId && (
                  <TitleInput
                    variant="inline"
                    placeholder="Document title here"
                    type="text"
                    value={previewFileTitle}
                    onChange={this.updateTitle}
                  />
                )
              }
            </DetailsWrapper>
            <MediaWrapper>
              {
                previewFileId && (
                  <ButtonsRow
                    onDelete={this.onUploadRemove}
                    onDownload={this.downloadAttachment}
                    onPrint={this.printAttachment(previewedOriginalFileDownloadUrl, previewExtension)}
                  />
                )
              }
              <AttachmentContainer
                previewUrl={previewImageState || undefined}
                onClick={this.openPreview}
              >
                {
                  !previewImageState && (
                    <Typography
                      fontStyle="italic"
                      noMargin
                    >
                      Select or upload attachment
                    </Typography>
                  )
                }
                {
                  !isImage && previewImageState && !simplePreviewVisible && (
                    <FileViewer
                      filePath={previewImageState}
                      fileType={previewExtension}
                      unsupportedComponent={(
                        <div>
                          Sorry, previews for
                          {' '}
                          <b>{previewExtension}</b>
                          {' '}
                          file type are not supported yet - we are working on it
                        </div>
                      )}
                    />
                  )
                }
              </AttachmentContainer>
              <div
                className="attachments-container"
              >
                {
                  filesPreviewThumbs.map((file) => (
                    <Tooltip title={file.title || file.name}>
                      <AttachmentTile
                        onClick={this.chooseAttachment(file)}
                        thumbUrl={file.thumbUrl}
                      >
                        <AttachmentTileTitleWrapper>
                          <Typography
                            variant="caption2"
                            align="center"
                            noMargin
                            ellipsis
                            style={{ maxWidth: 110 }}
                          >
                            {file.title || ''}
                          </Typography>
                        </AttachmentTileTitleWrapper>
                      </AttachmentTile>
                    </Tooltip>
                  ))
                }
                <Upload
                  className="attachments"
                  listType={uploadListType}
                  customRequest={this.customRequest}
                  fileList={null}
                >
                  {fileList.length >= uploadFileLimit
                    ? null
                    : uploadButton}
                </Upload>
                {this.renderPreview()}
              </div>
            </MediaWrapper>

            <LinkInputContainer>
              <Typography
                noMargin
                weight="bold"
                style={{ margin: '15px 0' }}
              >
                or
              </Typography>
              <LinkInput onEnter={this.addLink} />
            </LinkInputContainer>

            <ExternalLinksContainer>
              {
                externalLinks.map((item) => (
                  <ExternalLink href={item.url} target="_blank" rel="noopener noreferrer">
                    <Typography
                      variant="link"
                    >
                      {item.url}
                    </Typography>
                  </ExternalLink>
                ))
              }
            </ExternalLinksContainer>

          </Spin>
        )}
      </div>
    );
  }
}

Attachments.defaultProps = {
  blockRemove: false,
  getFileList: () => {},
  preloader: false,
  uploadFileLimit: 8,
  fileCompressionProgress: null,
  onAfterUpload: () => {},
  onAfterDelete: () => {},
  fileList: [],
  attachmentType: 12,
};

const {
  arrayOf,
  bool,
  func,
  number,
  shape,
  any,
} = PropTypes;

Attachments.propTypes = {
  blockRemove: bool,
  deleteFile: func.isRequired,
  getFileList: func,
  objectId: number.isRequired,
  preloader: bool,
  uploadFile: func.isRequired,
  uploadFileLimit: number,
  fileCompressionProgress: number,
  objectType: number.isRequired,
  attachmentType: number,
  onAfterUpload: func,
  onAfterDelete: func,
  fileList: arrayOf(shape(any)), // eslint-disable-line react/require-default-props
  updateAttachmentTitle: func.isRequired,
};

export default Attachments;
