import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import { noOp,
  debounce,
  without,
difference, } from 'shared/utility';

import Modal from 'ui/Modal';
import Button from 'ui/Button';
import Flex from 'ui/Flex/Flex';
import FlexItem from 'ui/FlexItem/FlexItem';
import Elevation from 'ui/Elevation';
import Typography from 'ui/Typography';
import Textfield from 'ui/Textfield';
import AreaHighlight from 'ui/AreaHighlight';
import TextEditor from 'ui/TextEditor';
import styled from 'styled-components';
import {
  Spin,
} from 'antd';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import editorContext from 'ui/TextEditor/context';

import routes from 'sections/InAppMarketing/routes';

import MarketingSelect from 'sections/Communications/modals/NewMarketingCampaignModal/MarketingSelect';
import { NewEmailCampaignContext } from 'sections/Communications/providers/context';
import useTemplates from 'sections/Communications/hooks/useTemplates';
import useCampaigns from 'sections/Communications/hooks/useCampaigns';

import PropertiesPreviewSelector from 'sections/MLS/modules/PropertiesPreviewSelector';

import useCampaign from 'sections/Communications/hooks/useCampaign';
import useTemplate from 'sections/Communications/hooks/useTemplate';
import CampaignName from 'sections/Communications/modules/Marketing/EmailCampaignEditor/CampaignName';
import TemplateName from 'sections/Communications/modules/Marketing/TemplateComposer/TemplateName';
import Editor from 'sections/Communications/modules/Marketing/TemplateComposer/Editor';

import Search from 'sections/Contacts/modals/AssignContactModal/Search';
import AddContact from 'sections/Contacts/modals/AssignContactModal/AddContact';
import LabelsSelector from 'sections/Contacts/modals/AssignContactModal/LabelsSelector';
import AllSelector from 'sections/Contacts/modals/AssignContactModal/AllSelector';
import WarningModal from 'sections/Contacts/modals/AssignContactModal/WarningModal';
import useModal from 'ui/hooks/useModal';
import AllContacts from 'sections/Contacts/modals/AssignContactModal/AllContacts';
import ContextProvider from 'sections/Contacts/modals/AssignContactModal/ContextProvider';

import EmailSelector from 'sections/Integrations/modules/EmailSelector';
import requests from 'api/requests';

import TemplateItems from 'sections/Communications/modules/Marketing/TemplateComposer/TemplateItems';
import PropertyDetails from 'sections/Communications/modules/Marketing/TemplateComposer/PropertyDetails';
import PropertyBriefInfo from 'sections/Properties/modules/PropertyBriefInfo';

import { toCanvas } from 'html-to-image';

const processCampaignData = (formData = {}) => ({
    ...formData,
    template_id: formData.templateId,
    email_body: formData.emailBody,
    email_subject: formData.emailSubject,
  });

const NewMarketingCampaignModal = (props) => {
  const {
    isVisible,
    closeModal,
  } = props;

  const history = useHistory();
  const printRef = React.useRef();

  const [propertySelectModalOpen, setPropertySelectModalOpen] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [newTemplateName, setNewTemplateName] = useState('');
  const [templateSave, setTemplateSave] = useState(true);
  const [campaignId, setCampaignId] = useState(null);
  const [campaignBody, setCampaignBody] = useState(null);

  const [orginalSelectedRecipients, setOrginalSelectedRecipients] = useState([]);
  const [selectedRecipients, setSelectedRecipients] = useState([]);

  const [emailTo, setEmailTo] = useState('');
  const [tokenId, setTokenId] = useState(null);

  const maxSelectedContactsQty = process.env.REACT_APP_MAX_SELECTED_CONTACTS_QTY || 500;

  const [emailStep, setEmailStep] = useState(1);
  const [selectedPropertyId, setProperty] = useState(null);

  const [isPropertyImageLoading, setIsPropertyImageLoading] = useState(false);

  const modalHeaders = {
    1: {
      title: 'New Marketing Email',
      subtitle: 'Select a template or create your own email campaign',
    },
    2: {
      title: 'Which property is this email about?',
      subtitle: 'Select a property or create a new one',
    },
    3: {
      title: 'Create your listing promotion email',
      subtitle: '',
    },
    4: {
      title: 'Select recipients for this email',
      subtitle: '',
    },
  };

  const selectedContactsQtyWarningDescription = `
    Sending bulk emails to more than ${maxSelectedContactsQty} contacts per day may result in your email address
    being permanently blacklisted by spam filters.`;

  const {
    handleSubmit,
    onChange: onNewEmailCampaignChange,
  } = useContext(NewEmailCampaignContext);

  const {
    create: createTemplate,
  } = useTemplates();

  const {
    create: createCampaign,
  } = useCampaigns();

  const {
    campaign,
    update,
  } = useCampaign(campaignId);

  const {
    template,
    onTemplateUpdate: onTemplateUpdate,
  } = useTemplate(campaign?.template_id);

  const handleCampaignCreation = handleSubmit(async (formData) => {
    const cleanData = processCampaignData(formData);
    const emailBody = cleanData.email_body;
    let newEmailBody = emailBody;

    // add property card image to email body
    if(typeof newEmailBody != undefined){
      // find the div tag with an id of symplete-image-placeholder and add the imageBody inside
      const imagePlaceholder = emailBody?.match(/<div id='symplete-image-placeholder'><\/div>/);
      let imageSrc = null;

      // add delay of 500 milisecond to allow the image to be rendered
      await new Promise(resolve => setTimeout(resolve, 4000));

      try {
        imageSrc = await getRefImage(printRef);
        console.log('imageSrc', imageSrc)
        imageSrc =
          `<img src="${imageSrc}" alt="property image"/>`;
      } catch (error) {
        console.log(error);
      }

      newEmailBody = emailBody?.replace(imagePlaceholder, imageSrc);
    }

    setCampaignBody(newEmailBody);

    // TODO: update campaign with new tempalte data
    if (campaignId) return

    const newCampaignId = await createCampaign(cleanData);
    setCampaignId(newCampaignId);
  });

  const onChooseTemplate = useCallback((template) => {
    onNewEmailCampaignChange.onEmailTemplateIdSelect(template.id);
    onNewEmailCampaignChange.onEmailBodyChange(template.template);

    setSelectedTemplateId(template.id);
  }, [
    handleCampaignCreation,
    onNewEmailCampaignChange.onEmailTemplateIdSelect,
    onNewEmailCampaignChange.onEmailBodyChange,
    setSelectedTemplateId,
  ]);

  // TODO: only handles updates for fields, should be renamed
  const onCreateTemplate = useCallback(async (templateName, isSave) => {
    setNewTemplateName(templateName);
    setTemplateSave(isSave);
  }, [
    onChooseTemplate,
  ]);

  const {
    Modal: ModalTest,
    isModalVisible: isModalVisibleTest,
    openModal: openModalTest,
    closeModal: closeModalTest,
  } = useModal();

  const {
    isModalVisible: isWarningModalVisible,
    openModal: openWarningModal,
    closeModal: closeWarningModal,
  } = useModal();

  const getRefImage = async (ref) => {
    const element = ref.current;
    const canvas = await toCanvas(element);
    const data = canvas.toDataURL('image/jpg');

    return data;
  };

  // TODO: refactor
  const handleNext = useCallback(async () => {
    if(emailStep === 1) {
      if(newTemplateName) {
        if(selectedTemplateId){
          // TODO: update template name
          // onTemplateUpdate({
          //   name: newTemplateName,
          // });
          setEmailStep(2);
          return;
        }

        const templateId = await createTemplate({
          name: newTemplateName,
          isSaved: templateSave,
        });
        onChooseTemplate({ id: templateId });
      }
      setEmailStep(2);
    }

    if(emailStep === 2) {
      setIsPropertyImageLoading(true);
      onNewEmailCampaignChange.onAddObject([{
        objectType: 27,
        objectId: selectedPropertyId,
      }]);
      await handleCampaignCreation();
      setIsPropertyImageLoading(false);
      setEmailStep(3);
    }

    if(emailStep === 3){
      setEmailStep(4);
    }

    if(emailStep === 4){
      const addedContacts = difference(selectedRecipients, orginalSelectedRecipients);
      const removedContacts = difference(orginalSelectedRecipients, selectedRecipients);

      const selectedContactsQty = addedContacts.length - removedContacts.length;
      if (selectedContactsQty > maxSelectedContactsQty) {
        openWarningModal();
        return;
      }

      assignRecipients(
        {
          addedContacts,
          removedContacts,
        },
      );
    }
  }, [
    selectedTemplateId,
    newTemplateName,
    emailStep,
    selectedPropertyId,
    selectedRecipients,
    campaignId,
    orginalSelectedRecipients,
    templateSave,
    selectedTemplateId,
    setSelectedTemplateId,
    setTemplateSave,
    setNewTemplateName,
    setEmailStep,
    setOrginalSelectedRecipients,
    setCampaignId,
    addContact,
    removeContact,
    setSelectedRecipients,
    update
  ]);

  // TODO: refactor this
  const footer = [
      <Button
        onClick={closeModal}
        variant="secondary"
      >
        Cancel
      </Button>,
      <Button
        variant="primary"
        disabled={!(selectedTemplateId !== null || (newTemplateName !== ''))}
        onClick={handleNext}
      >
        Next
      </Button>,
  ];

  const footer2 = [
    <Button
      onClick={() => setEmailStep(emailStep - 1)}
      variant="secondary"
    >
      Back
    </Button>,
    <Button
      onClick={closeModal}
      variant="secondary"
    >
      Cancel
    </Button>,
    <Button
      variant="primary"
      disabled={!selectedPropertyId || isPropertyImageLoading}
      onClick={handleNext}
    >
      {
      isPropertyImageLoading ? (
        <Spin/>
      ) : (
        "Next"
      )}
    </Button>,
  ];

  const footer3 = [
    <Button
      onClick={() => setEmailStep(emailStep - 1)}
      variant="secondary"
    >
      Back
    </Button>,
    <Button
      onClick={openModalTest}
      variant="secondary"
    >
      Send Test
    </Button>,
    <Button
      variant="primary"
      disabled={campaign.email_subject === null || campaign.email_subject === '' || campaign.email_body === '' || campaign.name === ''}
      onClick={handleNext}
    >
      Next
    </Button>,
  ];

  const footer4 = [
    <Button
      onClick={() => setEmailStep(emailStep - 1)}
      variant="secondary"
    >
      Back
    </Button>,
    <Button
      onClick={closeModal}
      variant="secondary"
    >
      Cancel
    </Button>,
    <Button
      variant="primary"
      disabled={selectedRecipients?.length === 0}
      onClick={handleNext}
    >
      Next
    </Button>,
  ];

  const updateCampaign = useCallback((key) => debounce((value) => {
      update({
        [key]: value,
      });
    }, 1000), [
      update,
    ]);

  const updateTemplate = useCallback((key) => debounce((value) => {
      onTemplateUpdate({
        [key]: value,
      });
    }, 1000), [
      onTemplateUpdate,
    ]);

  const onAssignRecipients = useCallback(async (recipients) => {
      await update({
        recipients,
      });
      history.push(`${routes.campaignDetails}/${campaignId}`);
    }, [
      update
    ]);

  const assignRecipients = useCallback((data) => {
    setSelectedRecipients(data?.addedContacts);
    onAssignRecipients({
      add: data?.addedContacts.map((id) => ({ contactId: id })),
      remove: data?.removedContacts.map((id) => ({ contactId: id })),
    });
  }, [
    setSelectedRecipients,
    onAssignRecipients,
  ]);

  const addContact = useCallback((contactId) => () => {
      setSelectedRecipients([
        ...selectedRecipients,
        contactId,
      ]);
  }, [selectedRecipients]);

  const removeContact = useCallback((contactId) => () => {
    setSelectedRecipients(
      without(selectedRecipients, contactId),
    );
  }, [selectedRecipients]);

  const onSend = useCallback(async () => {
    await requests.integrations.emailCampaigns.actions.sendTest(campaignId)({
      emailTo,
      tokenId,
    });

    closeModalTest();
  }, [
    emailTo,
    campaignId,
  ]);

  const PropertyRef = styled.div`
    position: fixed;
    top: 25%;
    left: 37%;
    max-width: 400px;
    z-index: -100;
  `;

  // TODO: refactor add steps 3 and 4 as a separate component
  return (
    <>
      <Modal
        width={720}
        visible={isVisible}
        onCancel={closeModal}
        destroyOnClose
        footer={emailStep === 1 ? footer : emailStep === 2 ? footer2 : emailStep === 3 ? footer3 : footer4}
        title={modalHeaders[emailStep].title}
        subtitle={modalHeaders[emailStep]?.subtitle}
      >
        { emailStep === 1 && (
            <MarketingSelect
              onChooseTemplate={onChooseTemplate}
              onCreateTemplate={onCreateTemplate}
              selectedTemplateId={selectedTemplateId}
              newTemplateName={newTemplateName}
              setNewTemplateName={templateSave}
            />
          )
        }
        { emailStep === 2 && (
            <PropertiesPreviewSelector
              onSelect={setProperty}
              selected={selectedPropertyId}
            />
          )
        }
        { emailStep === 3 && (
          <>
            <Flex
              spacing={2}
              flexDirection="column"
              fullWidth
            >
              <FlexItem
                fullWidth
              >
                <Textfield
                  value={campaign?.name}
                  onChange={updateCampaign('name')}
                  label="Campaign Name"
                />
              </FlexItem>
              <FlexItem
                fullWidth
              >
                <Textfield
                  fullWidth
                  label="Email Subject"
                  value={campaign.email_subject}
                  onChange={updateCampaign('email_subject')}
                />
              </FlexItem>
              <FlexItem fullWidth spacing={2}>
                <TextEditor.ContextProvider
                  onChange={updateCampaign('email_body')}
                  content={campaignBody}
                >
                  <DndProvider
                    backend={HTML5Backend}
                  >
                    <Flex
                      spacing={3}
                      fullWidth
                      flexDirection='column'
                    >
                      <FlexItem
                        fullWidth
                        fullHeight
                      >
                        <Elevation
                          rounded="m"
                          padding="m"
                          fullWidth
                          fullHeight
                        >
                          <Flex
                            fullWidth
                            flexDirection="column"
                            spacing={2}
                          >
                            <Editor>
                              <TemplateItems.ModalDropdown
                                propertyId={campaign?.realtorPropertyId}
                                location={'modal'}
                               />
                            </Editor>
                          </Flex>
                        </Elevation>
                      </FlexItem>
                    </Flex>
                  </DndProvider>
                </TextEditor.ContextProvider>
              </FlexItem>
            </Flex>
            <ModalTest
              width={600}
              visible={isModalVisibleTest}
              onCancel={closeModalTest}
              title="Send test email"
              footer={[
                <Button
                  variant="secondary"
                  onClick={closeModalTest}
                >
                  Cancel
                </Button>,
                <Button
                  variant="primary"
                  onClick={onSend}
                >
                  Send
                </Button>,
              ]}
            >
              <Flex
                flexDirection="column"
                spacing={2}
              >
                <Textfield
                  fullWidth
                  value={emailTo}
                  onChange={setEmailTo}
                  label="Send to"
                />
                <Flex
                  alignItems="baseline"
                  spacing={0.5}
                >
                  <Typography>Send as:</Typography>
                  <EmailSelector
                    onChange={setTokenId}
                    showAll={false}
                  />
                </Flex>
              </Flex>
            </ModalTest>
          </>
          )
        }
        { emailStep === 4 && (
            <>
              <ContextProvider>
                <>
                  <Flex
                    spacing={3}
                    fullWidth
                    flexWrap="wrap"
                  >
                    <FlexItem fullWidth>
                      <Typography
                        variant="title2"
                        noMargin
                        weight={500}
                      >
                        Select Individuals
                      </Typography>
                    </FlexItem>
                    <Flex
                      spacing={2}
                      fullWidth
                    >
                      <Search />
                      <AddContact
                        title="New Contact"
                        onSuccess={noOp}
                      />
                    </Flex>
                    <Flex
                      spacing={2}
                      flexDirection='column'
                      fullWidth
                    >
                      <Flex
                        spacing={2}
                        alignItems="center"
                        fullWidth
                      >
                        <Typography
                          variant="title2"
                          noMargin
                          weight={500}
                        >
                          Select By Group
                        </Typography>
                      </Flex>
                      <Flex
                        fullWidth
                      >
                        <LabelsSelector />
                      </Flex>
                    </Flex>
                    <FlexItem fullWidth>
                      <Typography
                        variant="title2"
                        noMargin
                        weight={500}
                      >
                        Selected Recipients
                      </Typography>
                      <AllContacts
                        selectedContacts={selectedRecipients}
                        oneContactSelect={false}
                        onAddContact={addContact}
                        onRemoveContact={removeContact}
                      />
                    </FlexItem>
                  </Flex>
                  <WarningModal
                    isVisible={isWarningModalVisible}
                    closeModal={closeWarningModal}
                    description={selectedContactsQtyWarningDescription}
                  />
                </>
              </ContextProvider>
            </>
          )
        }
      </Modal>
      {
        <PropertyRef>
          <FlexItem fullWidth>
            <div ref={printRef}>
              <PropertyBriefInfo
                propertyId={selectedPropertyId}
              />
            </div>
          </FlexItem>
        </PropertyRef>
      }
    </>
  );
};

NewMarketingCampaignModal.defaultProps = {
  isVisible: false,
  theme: {},
  closeModal: noOp,
};

const {
  bool,
  func,
  shape,
} = PropTypes;

NewMarketingCampaignModal.propTypes = {
  isVisible: bool,
  theme: shape(),
  closeModal: func,
};

export default NewMarketingCampaignModal;
