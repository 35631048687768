import React from 'react';
import PropTypes from 'prop-types';

import { Popconfirm } from 'antd';

import styled from 'styled-components';

import { currency } from '@symplete/symplete-utils/numbers';

import { NakedButton } from '@symplete/symplete-ui';

import Grid from 'ui/Grid';

import Typography from 'ui/Typography';

import Person from './PersonItem';
import Property from './PropertyItem';
import AcceptedBlock from './AcceptedBlock';

const Root = styled.div`
  width: 100%;
  padding: 10px;
  background-color: rgba(161, 189, 203, 0.26);
  margin-bottom: 15px;
`;

const Header = (props) => {
  const {
    offerNumber,
    amount,
    delta,
    buyerId,
    buyerAgentId,
    propertyId,
    isClient,
    isProspect,
    isCancelled,
    isDeclined,
    isAccepted,
    onCancel,
    acceptedAmount,
    onUndoAcceptance,
  } = props;

  return (
    <Root>
      <Grid container spacing={2}>
        {
          isClient && (
            <>
              <Grid item container xs={isAccepted ? 6 : 4} spacing={1} alignItems="center">
                <Grid item>
                  <Typography
                    variant="h3"
                    tag="span"
                    weight={500}
                    noMargin
                  >
                    {`Offer #${offerNumber}:`}
                  </Typography>
                </Grid>

                {
                  !isCancelled && !isDeclined && !isAccepted && (
                    <>
                      <Grid item>
                        <Typography
                          variant="h1"
                          tag="span"
                          color="selected"
                          weight={500}
                          noMargin
                        >
                          {amount && currency.full(amount)}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="body2"
                          tag="span"
                          color={delta < 0 ? 'attention' : 'success'}
                          weight={500}
                          noMargin
                        >
                          {!!delta && `${delta < 0 ? '' : '+'}${currency.short(delta)}`}
                        </Typography>
                      </Grid>
                    </>
                  )
                }
                {
                  (isCancelled || isDeclined) && (
                    <Grid item>
                      <Typography
                        variant="h4"
                        tag="span"
                        color="attention"
                        weight={500}
                        noMargin
                      >
                        {isCancelled ? 'Cancelled' : 'Declined'}
                      </Typography>
                    </Grid>
                  )
                }
                {
                  isAccepted && (
                    <Grid item>
                      <AcceptedBlock
                        amount={acceptedAmount}
                        onUndoAcceptance={onUndoAcceptance}
                      />
                    </Grid>
                  )
                }
              </Grid>

              <Grid item container xs={6} spacing={2} justify="center">
                <Grid item>
                  {buyerId && <Person contactId={buyerId} label={{ title: 'buyer', color: '#1584ff' }} />}
                </Grid>
                <Grid item>
                  {buyerAgentId
                    && <Person contactId={buyerAgentId} label={{ title: 'buyer agent', color: '#bd10e0' }} />}
                </Grid>
              </Grid>
            </>
          )
        }

        {
          isProspect && (
            <Grid item xs={10}>
              <Property propertyId={propertyId} amount={amount} />
            </Grid>
          )
        }

        {
          !isCancelled && !isDeclined && !isAccepted && (
            <Grid item container xs={2} justify="flex-end" alignItems="flex-end">
              <Grid item>
                <Popconfirm
                  title="Are you sure to cancel this offer?"
                  onConfirm={onCancel}
                >
                  <NakedButton>
                    <Typography
                      variant="subtitle2"
                      tag="span"
                      noMargin
                      textDecoration="underline"
                      weight={600}
                    >
                      Cancel Offer
                    </Typography>
                  </NakedButton>
                </Popconfirm>
              </Grid>
            </Grid>
          )
        }

      </Grid>
    </Root>
  );
};

Header.defaultProps = {
  buyerId: null,
  buyerAgentId: null,
  propertyId: null,
  isCancelled: false,
  isDeclined: false,
  isAccepted: false,
  delta: null,
  acceptedAmount: null,
};

const {
  number,
  bool,
  func,
} = PropTypes;

Header.propTypes = {
  offerNumber: number.isRequired,
  amount: number.isRequired,
  buyerId: number,
  buyerAgentId: number,
  propertyId: number,
  isClient: bool.isRequired,
  isProspect: bool.isRequired,
  isCancelled: bool,
  isDeclined: bool,
  isAccepted: bool,
  onCancel: func.isRequired,
  delta: number,
  acceptedAmount: number,
  onUndoAcceptance: func.isRequired,
};

export default Header;
