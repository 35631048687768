import React, { useState } from 'react';

import PropTypes from 'prop-types';

import Button from 'ui/Button';
import Flex from 'ui/Flex';
import Typography from 'ui/Typography';

import ChipList from 'ui/ChipList';

import ContactEmailChip from 'sections/Integrations/modules/EmailComposer/ContactEmailChip';

const Recipient = (props) => {
  const {
    emailTo,
    onRemoveEmailTo,
    onAddEmailTo,

    cc,
    onAddCc,
    onRemoveCc,

    bcc,
    onAddBcc,
    onRemoveBcc,
  } = props;

  const [ccVisible, setCcVisible] = useState(false);
  const [bccVisible, setBccVisible] = useState(false);

  return (
    <>
      <Flex fullWidth justifyContent="space-between" alignItems="center">
        <Flex spacing={1} alignItems="center">
          <Typography variant="title2" noMargin>
            To: *
          </Typography>
          <ChipList
            items={emailTo}
            onAdd={onAddEmailTo}
            ellipsis={2}
            CustomChip={({ item }) => (
              <ContactEmailChip
                item={item}
                onDelete={() => onRemoveEmailTo(item)}
              />
            )}
          />
        </Flex>

        <Flex spacing={2}>
          <Button
            variant="secondary"
            color="focus"
            onClick={() => setCcVisible(!ccVisible)}
          >
            Cc:
          </Button>
          <Button
            variant="secondary"
            color="focus"
            onClick={() => setBccVisible(!bccVisible)}
          >
            Bcc:
          </Button>
        </Flex>
      </Flex>

      {ccVisible ? (
        <Flex spacing={1} alignItems="center">
          <Typography variant="title3" noMargin>
            Cc:
          </Typography>
          <Flex fullWidth spacing={1} alignItems="center">
            <ChipList
              items={cc}
              onAdd={onAddCc}
              ellipsis={2}
              CustomChip={({ item }) => (
                <ContactEmailChip
                  item={item}
                  onDelete={() => onRemoveCc(item)}
                />
              )}
            />
          </Flex>
        </Flex>
      ) : null}
      {bccVisible ? (
        <Flex spacing={1} alignItems="center">
          <Typography variant="title3" noMargin>
            Bcc:
          </Typography>
          <Flex fullWidth spacing={1} alignItems="center">
            <ChipList
              items={bcc}
              onAdd={onAddBcc}
              ellipsis={2}
              CustomChip={({ item }) => (
                <ContactEmailChip
                  item={item}
                  onDelete={() => onRemoveBcc(item)}
                />
              )}
            />
          </Flex>
        </Flex>
      ) : null}
    </>
  );
};

Recipient.defaultProps = {
  emailTo: null,
  onRemoveEmailTo: null,
  onAddEmailTo: null,

  cc: null,
  onAddCc: null,
  onRemoveCc: null,

  bcc: null,
  onAddBcc: null,
  onRemoveBcc: null,
};

const { func, string } = PropTypes;

Recipient.propTypes = {
  emailTo: string,
  onRemoveEmailTo: func,
  onAddEmailTo: func,

  cc: string,
  onAddCc: func,
  onRemoveCc: func,

  bcc: string,
  onAddBcc: func,
  onRemoveBcc: func,
};

export default Recipient;
