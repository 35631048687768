import React from 'react';
import PropTypes from 'prop-types';
import {
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';
import AssignModal from 'shared/AssignUser/AssignModal';
import ReminderItem from './ReminderItem';
import './ReminderItems.scss';

class ReminderItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agentAssignModalVisible: false,
      assignModalActiveUsers: [],
      triggerEmployeeID: null,
      triggerReminderID: null,
      toDoListFilteredAssignedEmployee: [],
    };
  }

  componentDidMount() {
    const {
      toDoListSetViewedAction,
    } = this.props;
    toDoListSetViewedAction();
  }

  openAgentAssignModal = (reminderID, EmployeeID, assigneeByIconWithoutUser) => () => {
    const {
      employees,
      todolist,
    } = this.props;

    const activeEmployeesIDArr = assigneeByIconWithoutUser
      .map((assigneeWithoutUserElement) => assigneeWithoutUserElement.id);

    const assignedActiveEmployees = employees
      .filter((activeEmployee) => (
        (activeEmployee.Name !== null) || (activeEmployee.Surname !== null)
      )
      && (activeEmployeesIDArr.includes(activeEmployee.EmployeeID))
      && (activeEmployee.EmployeeID !== EmployeeID));

    const assignedEmployeeList = employees
      .filter((element) => ((element.Name !== null) || (element.Surname !== null))
        && (element.EmployeeID !== EmployeeID));

    const toDoListFilteredAssignedEmployee = assignedEmployeeList
      .filter((verifiableEmployeeElement) => {
        const toDoListEmployeesFilteredSettings = todolist.toDoListEmployeesSettings
          .filter((settingsEmployeeElement) => settingsEmployeeElement.EmployeeID === verifiableEmployeeElement.EmployeeID); // eslint-disable-line max-len

        const toDoListEmployeesFilteredSettingsArray = toDoListEmployeesFilteredSettings
          .map((elem) => elem.InviterID);

        return (toDoListEmployeesFilteredSettings[0].valueAssigningSettings === 2)
          || (
            (toDoListEmployeesFilteredSettings[0].valueAssigningSettings === 3)
            && (toDoListEmployeesFilteredSettingsArray.includes(EmployeeID))
          );
      });

    this.setState({
      agentAssignModalVisible: true,
      triggerEmployeeID: EmployeeID,
      triggerReminderID: reminderID,
      assignModalActiveUsers: assignedActiveEmployees,
      toDoListFilteredAssignedEmployee,
    });
  }

  closeAgentAssignModal = () => {
    this.setState({
      agentAssignModalVisible: false,
    });
  };

  changeFulfillment = (reminderID, isCompleted) => async () => {
    const {
      toDoListSetViewedAction,
      reminderChangeFulfillmentAction,
    } = this.props;
    await reminderChangeFulfillmentAction(reminderID, isCompleted);
    toDoListSetViewedAction();
  }

  changeImportance = (reminderID, isImportant) => async () => {
    const {
      toDoListSetViewedAction,
      reminderChangeImportanceAction,
    } = this.props;
    await reminderChangeImportanceAction(reminderID, isImportant);
    toDoListSetViewedAction();
  }

  deleteReminder = (reminderID) => async () => {
    const {
      reminderDeletingAction,
      toDoListSetViewedAction,
    } = this.props;
    await reminderDeletingAction(reminderID);
    toDoListSetViewedAction();
  }

  onClickOKInviteModel = async () => {
    const {
      toDoListSetViewedAction,
    } = this.props;
    await toDoListSetViewedAction();
  }

  render() {
    const {
      agentAssignModalVisible,
      triggerEmployeeID,
      triggerReminderID,
      toDoListFilteredAssignedEmployee,
      assignModalActiveUsers,
    } = this.state;

    const {
      todolist,
      EmployeeInf,
      setInviteEmployees,
      setInviteEmployeesSuccess,
      setInviteEmployeesError,
      todoReminderList,
    } = this.props;

    const reminderList = todoReminderList.map((element, index) => {
      const checkBoxIndex = index + 1;
      const isShowImportantStar = !!element.isImportant;
      const isShowAssignedBy = !(EmployeeInf.EmployeeID === element.assigneeByID);
      const assigneeByIconAll = todolist.assigneeBy
        .filter((elem) => elem[0].reminderID === element.reminderID);
      const assigneeByIconWithoutUser = assigneeByIconAll[0]
        .filter((elem) => elem.id !== element.EmployeeID);
      return (
        <Draggable
          draggableId={String(element.reminderID)}
          index={index}
          key={element.reminderID}
          disableInteractiveElementBlocking={false}
          type="TODO-LIST"
        >
          {(provided, snapshot) => (
            <ReminderItem
              snapshot={snapshot}
              provided={provided}
              element={element}
              checkBoxIndex={checkBoxIndex}
              isShowAssignedBy={isShowAssignedBy}
              isShowImportantStar={isShowImportantStar}
              assigneeByIconWithoutUser={assigneeByIconWithoutUser}
              EmployeeInf={EmployeeInf}
              openAgentAssignModal={this.openAgentAssignModal}
              changeImportance={this.changeImportance}
              deleteReminder={this.deleteReminder}
              changeReminderTitle={this.changeReminderTitle}
              changeFulfillment={this.changeFulfillment}
            />
          )}
        </Draggable>
      );
    });

    const assignEmployeeData = {
      userData: {
        EmployeeID: triggerEmployeeID,
        reminderID: triggerReminderID,
      },
      data: {
        key: 'EmployeeID',
        searchKey: 'Name',
        activeUsers: assignModalActiveUsers,
        availableUsers: toDoListFilteredAssignedEmployee,
      },
      config: {
        apiUrl: '/api/todolist/changePerformersList',
        start: setInviteEmployees,
        success: setInviteEmployeesSuccess,
        error: setInviteEmployeesError,
      },
    };

    return (
      <Droppable droppableId="column" type="TODO-LIST">
        {(provided) => (
          <div
            className="reminder-items"
            ref={provided.innerRef}
            // the react-beautiful-dnd documentation assumes using spreading at this point
            // https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/api/droppable.md
            {...provided.droppableProps} // eslint-disable-line react/jsx-props-no-spreading
          >
            {reminderList}
            {provided.placeholder}
            <AssignModal
              className="to-do-list-add-user-modal"
              data={assignEmployeeData}
              modalVisible={agentAssignModalVisible}
              closeModal={this.closeAgentAssignModal}
            />
          </div>
        )}
      </Droppable>
    );
  }
}

const {
  shape,
  func,
  arrayOf,
  string,
  array,
  number,
} = PropTypes;

ReminderItems.propTypes = {
  todolist: shape({
    assigneeBy: array.isRequired, // eslint-disable-line react/forbid-prop-types
  }).isRequired,
  todoReminderList: arrayOf({
    EmployeeID: number,
    Name: string,
    Surname: string,
    assigneeByID: number,
    completedAt: string,
    createdAt: string,
    creatorID: number,
    isCompleted: number,
    isImportant: number,
    numberInList: number,
    reminderID: number,
    reminderTitle: string,
  }).isRequired,
  EmployeeInf: shape({
    EmployeeID: number,
  }).isRequired,
  employees: arrayOf({
    employee: shape({
      EmployeeID: number,
      Name: string,
      Surname: string,
    }),
  }).isRequired,
  toDoListSetViewedAction: func.isRequired,
  reminderDeletingAction: func.isRequired,
  reminderChangeImportanceAction: func.isRequired,
  reminderChangeFulfillmentAction: func.isRequired,
  setInviteEmployees: func.isRequired,
  setInviteEmployeesSuccess: func.isRequired,
  setInviteEmployeesError: func.isRequired,
};

export default ReminderItems;
