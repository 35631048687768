import { connect } from 'react-redux';
import store from 'store';

import {
  setSelectedProperty,
} from 'store/actions/properties';

import PropertyItem from './PropertyItem';

const mapStateToProps = (state, ownProps) => {
  const {
    properties: {
      selectedProperty,
    },
  } = state;

  const { id } = ownProps.match.params;

  if (selectedProperty !== id) {
    store.dispatch(setSelectedProperty(id));
  }

  return {
    selectedProperty,
  };
};

export default connect(mapStateToProps)(PropertyItem);
