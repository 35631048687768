import { connect } from 'react-redux';

import { openEditViewingModal } from 'store/actions/editRealtorPropertyViewingModal';

import AddContactButton from './AddContactButton';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: () => {
    dispatch(openEditViewingModal(ownProps.viewingId, { [ownProps.field]: true }));
  },
});

export default connect(null, mapDispatchToProps)(AddContactButton);
