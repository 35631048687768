import React, {
  useCallback,
  useEffect,
  Fragment,
} from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { useDebounce } from 'use-debounce';

import Calendar from 'ui/Calendar';
import Tooltip from 'ui/Tooltip';
import Typography from 'ui/Typography';

import CalendarEvent from 'sections/Calendar/components/CalendarEvent';
import ClickableEventWrapper from 'sections/Calendar/modules/ClickableEventWrapper';

const eventTypesColors = {
  showings: 'calendarShowings',
  listingPitches: 'calendarListingPitches',
  followUpReminders: 'calendarFollowUpReminders',
  closingEscrows: 'calendarClosingEscrows',
  contingenciesDue: 'calendarContingenciesDue',
  synced: 'calendarSynced',
};

const eventTypesTitles = {
  showings: 'Property Showing',
  listingPitches: 'Listing Pitch',
  followUpReminders: 'Follow Up',
  closingEscrows: 'Escrow Close',
  contingenciesDue: 'Contigency',
  synced: 'Synced Event',
};

const GeneralCalendar = (props) => {
  const {
    selectedDate,
    getMonthData,
    monthData,
    onSelect,
  } = props;

  const [debouncedDate] = useDebounce(selectedDate, 500);

  useEffect(() => {
    getMonthData(debouncedDate);
  }, [
    debouncedDate,
  ]);

  const renderEventsList = useCallback((events) => (
    <ul>
      {
        events.map((event) => (
          <li>
            <ClickableEventWrapper objectType={event.objectType} objectId={event.objectId}>
              <Typography color="contrast">
                {`${moment(event.date).format('LT')}. ${event.description}.`}
              </Typography>
            </ClickableEventWrapper>
          </li>
        ))
      }
    </ul>
    ), []);

  const dateCellRender = useCallback((date) => { // eslint-disable-line arrow-body-style
    const dateKey = moment(date).format('MM-DD-YYYY');
    const dateEvents = monthData[dateKey];
    if (!dateEvents) {
      return null;
    }
    const dateEventsKeys = Object.keys(dateEvents);
    return dateEventsKeys.map((key) => {
      const eventsOfTypeAll = dateEvents[key];
      const eventsOfTypeUnique = [...new Map(eventsOfTypeAll.map(item =>
          [item['id'], item])).values()];

      return (
        <Fragment key={key}>
          <Tooltip title={renderEventsList(eventsOfTypeUnique)}>
            <CalendarEvent
              variant={eventTypesColors[key]}
              title={eventTypesTitles[key]}
              itemsCount={eventsOfTypeUnique.length}
            />
          </Tooltip>
        </Fragment>
      );
    });
  }, [
    monthData,
  ]);

  const handleSelect = useCallback((date) => {
    const dateKey = moment(date).format('MM-DD-YYYY');
    const dateEvents = monthData[dateKey];

    const isEmpty = !dateEvents;

    onSelect(date, isEmpty);
  }, [
    onSelect,
    monthData,
  ]);

  return (
    <Calendar
      headerRender={() => null} // eslint-disable-line react/jsx-no-bind
      value={moment(selectedDate)}
      dateCellRender={dateCellRender}
      onSelect={handleSelect}
    />
  );
};

GeneralCalendar.defaultProps = {
  onSelect: () => {},
};

const {
  string,
  func,
  shape,
  objectOf,
} = PropTypes;

GeneralCalendar.propTypes = {
  selectedDate: string.isRequired,
  getMonthData: func.isRequired,
  monthData: objectOf(shape({
    date: string.isRequired,
  })).isRequired,
  onSelect: func,
};

export default GeneralCalendar;
