import React, {
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react';

import Flex from 'ui/Flex';
import MenuItem from 'ui/MenuItem';
import Button from 'ui/Button';
import styled from 'styled-components';

import editorContext from 'ui/TextEditor/context';

const DropDownWrapper = styled.div`
  position: absolute;
  top: 40px;
  width: 121px;
  background-color: white;
  border-radius: 20px;
  padding: 5px;
  border: 1px solid black;
`;

const wildcards = [
  'recipient full name',
  'recipient first name',
  'recipient last name',
  'today’s date',
];

const ModalDropdown = (props) => {
  const {
    propertyId,
    location,
  } = props;

  const { editor } = useContext(editorContext);
  const [isOpen, setIsOpen] = useState(false);

  const printRef = React.useRef();

  const onAdd = useCallback((item) => () => {
    if (editor) {
      editor
        .chain()
        .focus()
        .insertContent(' ')
        .insertContent(
          [
            {
              type: 'templateItem',
              content: [
                {
                  type: 'text',
                  text: item,
                },
              ],
            },
          ],
        )
        .insertContent(' ')
        .run();

      setIsOpen(false);
    }
  }, [
    editor,
  ]);

  const onAddProperty = () => async () => {
    if (editor) {
      await insertContent()(editor)(printRef);
    }
  };

  // hack to update content of email marketing if from modal
  const insertContent = () => (editor) => async (propertyCardRef) => {  
    try {
      editor
        .chain()
        .focus('end')
        .insertContent(' ')
        .focus('start')
        .run();
    } catch (error) {
      console.log(error);
    }
  };

  // if location is set, manually add card
  useEffect(() => {
    if (location) {
      // delay to allow editor to load
      // TOOD: if image exist, remove
      setTimeout(() => {
        onAddProperty()();
      }, 500);
    }
  }, [location]);

  return (
    <Flex
      spacing={1}
      flexWrap="wrap"
      justifyContent="flex-end"
    >
      <Button
        icon='smartLabel'
        iconType='custom'
        color="main-text"
        onClick={() => { setIsOpen(!isOpen) }}
        variant="tertiary"
      />
      {
        isOpen && (
          <DropDownWrapper>
            {
              wildcards.map((item) => (
                <MenuItem onClick={onAdd(item)} key={item}>
                  {/* if has recipient text, remove it and capitalize else sentece case */}
                  {item.includes('recipient') ? item.replace('recipient ', '').replace(/\b\w/g, l => l.toUpperCase()) : item.replace(/\b\w/g, l => l.toUpperCase())}
                </MenuItem>
                  )
              )
            }
          </DropDownWrapper>
          )
      }
    </Flex>
  );
};

export default ModalDropdown;
