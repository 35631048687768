import { connect } from 'react-redux';

import {
  postComment,
} from 'store/actions/comments';

import Comments from './Comments';

const mapStateToProps = (state) => {
  const {
    realtorProspects: {
      selectedProspect,
    },
  } = state;

  return {
    prospectId: Number(selectedProspect),
  };
};

const mapDispatchToProps = (dispatch) => ({
  addComment: (data) => {
    const comment = {
      Comment: data.comment,
      CommentType: data.commentType,
      IsPublic: data.isVisibleToCustomer,
    };

    return dispatch(postComment('realtor prospect')(data.prospectId)(comment));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Comments);
