import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import LeadsTableFilterForm from 'sections/Leads/modals/LeadsTableFilterModal/LeadsTableFilterForm';

import { Modal } from './styledItems';

const prepareSourceTypeArray = (source) => {
  if (source.includes(0)) {
    return [];
  }

  return source;
};

const prepareTempArray = (tempObj) => {
  if (tempObj[1] && tempObj[2] && tempObj[3]) {
    return [];
  }

  return Object.keys(tempObj).filter((key) => !!tempObj[key]);
};

const prepareDateRangeArray = (dateArr) => {
  if (!dateArr[0] && !dateArr[1]) {
    return [];
  }

  return [
    moment(dateArr[0]).startOf('day').format(),
    moment(dateArr[1]).endOf('day').format(),
  ];
};

const prepareFilter = (filter) => ({
  ...filter,
  source: prepareSourceTypeArray(filter.source),
  temp: prepareTempArray(filter.temp),
  createdAtRange: prepareDateRangeArray(filter.createdAtRange),
});

const defaultDetailsFilter = {
  source: [],
  leadType: [],
  leadStatus: [],
  temp: {
    1: true,
    2: true,
    3: true,
  },
};

const LeadsTableFilterModal = (props) => {
  const {
    isVisible,
    onOk,
    closeModal,
    defaultRangeFilter,
  } = props;

  const [filter, setFilter] = useState({
    ...defaultDetailsFilter,
    ...defaultRangeFilter,
  });

  useEffect(() => {
    setFilter((prevState) => ({
      ...prevState,
      ...defaultRangeFilter,
    }));
  }, [defaultRangeFilter]);

  const setFilterItem = useCallback((key) => (value) => {
    setFilter((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const handleOk = useCallback(() => {
    const preparedFilter = prepareFilter(filter);

    onOk(preparedFilter);
    closeModal();
  }, [
    filter,
    onOk,
    closeModal,
  ]);

  const clearAllFilter = useCallback((event) => {
    event.preventDefault();

    setFilter({
      ...defaultDetailsFilter,
      ...defaultRangeFilter,
    });
  }, []);

  return (
    <Modal
      width={600}
      visible={isVisible}
      okText="Show"
      onOk={handleOk}
      onCancel={closeModal}
      destroyOnClose
    >
      <LeadsTableFilterForm
        filter={filter}
        setFilterItem={setFilterItem}
        clearAllFilter={clearAllFilter}
      />
    </Modal>
  );
};

LeadsTableFilterModal.defaultProps = {
  isVisible: false,
};

const {
  arrayOf,
  bool,
  func,
  number,
  shape,
  string,
} = PropTypes;

LeadsTableFilterModal.propTypes = {
  isVisible: bool,
  onOk: func.isRequired,
  closeModal: func.isRequired,
  defaultRangeFilter: shape({
    createdAtRange: arrayOf(string),
    propertyValueRange: arrayOf(number),
  }).isRequired,
};

export default LeadsTableFilterModal;
