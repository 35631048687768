/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */
import React, { useCallback }
  from 'react';
import PropTypes from 'prop-types';

import OptionsMenu from 'ui/OptionsMenu';
import MenuItem from 'ui/MenuItem';

import { useHistory } from 'react-router-dom';

import useCampaign from 'sections/Communications/hooks/useCampaign';
import routes from 'sections/InAppMarketing/routes';

import { callAlert } from 'ui/Alert';

const Options = (props) => {
  const {
    campaignId,
  } = props;

  const {
    duplicate,
    remove,
  } = useCampaign(campaignId);

  const history = useHistory();

  const onClick = useCallback(async () => {
    const newCampaignId = await duplicate();

    if (!newCampaignId) {
      return;
    }

    history.push(`${routes.campaignDetails}/${newCampaignId}`);
  }, [
    duplicate,
  ]);

  const handleDelete = useCallback((closeMenu) => async (event) => {
    event.stopPropagation();
    await remove();
    closeMenu();

    callAlert.success('Campaign deleted successfully');
    history.push(`${routes.sectionRoot}`);
  }, [
    remove,
  ]);

  return (
    <OptionsMenu {...props}>
      {
        ({ closeMenu }) => (
          <>
            <MenuItem
              onClick={onClick}
            >
              Duplicate
            </MenuItem>
            <MenuItem
              onClick={handleDelete(closeMenu)}
            >
              Delete
            </MenuItem>
          </>
        )
      }
    </OptionsMenu>
  );
};

const {
  number,
} = PropTypes;

Options.propTypes = {
  campaignId: number.isRequired,
};

export default Options;
