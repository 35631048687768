import React from 'react';

import Typography from 'ui/Typography';

import Details from 'sections/OpportunitiesListing/modules/OpportunitiesTable/components/Details';
import SymScore from 'sections/OpportunitiesListing/modules/OpportunitiesTable/components/SymScore';
import PriceChange from 'sections/OpportunitiesListing/modules/OpportunitiesTable/components/PriceChange';
import Reserve from 'sections/OpportunitiesListing/modules/OpportunitiesTable/components/Reserve';

const columns = [
  {
    title: 'SymScore',
    dataIndex: 'symScore',
    key: 'symScore',
    render: (value, entry) => (
      <SymScore score={value} />
    ),
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'id',
    render: (value, entry) => (
      <Typography variant="cell" weight="bold">
        {value}
      </Typography>
    ),
  },
  {
    title: 'Avm. Price',
    dataIndex: 'id',
    key: 'id',
    render: (value, entry) => (
      <Details
        entry={entry}
        propertyId={value}
        propertyKey="analytics.avm"
        format="price"
      />
    ),
  },
  {
    title: 'Bed',
    dataIndex: 'id',
    key: 'id',
    render: (value, entry) => (
      <Details
        entry={entry}
        propertyId={value}
        propertyKey="property.property_attributes.beds"
      />
    ),
  },
  {
    title: 'Bath',
    dataIndex: 'id',
    key: 'id',
    render: (value, entry) => (
      <Details
        entry={entry}
        propertyId={value}
        propertyKey="property.property_attributes.baths"
      />
    ),
  },
  {
    title: 'Sqf',
    dataIndex: 'id',
    key: 'id',
    render: (value, entry) => (
      <Details
        entry={entry}
        propertyId={value}
        propertyKey="property.property_attributes.sqft"
        format="sqft"
      />
    ),
  },
  {
    title: 'Lot',
    dataIndex: 'id',
    key: 'id',
    render: (value, entry) => (
      <Details
        entry={entry}
        propertyId={value}
        propertyKey="property.property_attributes.sqft_lot"
        format="sqft"
      />
    ),
  },
  {
    title: 'Purchased',
    dataIndex: 'id',
    key: 'id',
    render: (value, entry) => (
      <Details
        entry={entry}
        propertyId={value}
        propertyKey="property.last_transaction.price"
        format="price"
      />
    ),
  },
  {
    title: 'Price Change',
    dataIndex: 'id',
    key: 'id',
    render: (value, entry) => (
      <PriceChange
        entry={entry}
        propertyId={value}
      />
    ),
  },
  {
    title: 'Owned for',
    dataIndex: 'id',
    key: 'id',
    render: (value, entry) => (
      <Details
        entry={entry}
        propertyId={value}
        propertyKey="analytics.residency_length"
        format="years"
      />
    ),
  },
  {
    title: 'Updated',
    dataIndex: 'id',
    key: 'id',
    render: (value, entry) => (
      <Details
        entry={entry}
        propertyId={value}
        propertyKey="analytics.avm_date"
        format="ago"
      />
    ),
  },
  {
    title: 'Owner',
    dataIndex: 'owner',
    key: 'owner',
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'id',
    render: (value, entry) => (
      <Reserve
        propertyId={value}
        entry={entry}
      />
    ),
  },
];

export default columns;
