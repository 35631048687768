import { connect } from 'react-redux';

const withSelectedLead = (Component) => {
  const mapStateToProps = (state) => {
    const {
      realtorLeads: {
        selectedLead,
      },
    } = state;

    return {
      selectedLead,
    };
  };

  return connect(mapStateToProps)(Component);
};

export default withSelectedLead;
