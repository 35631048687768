import { ago } from '@symplete/symplete-utils/datetime';
import { pretty, currency } from '@symplete/symplete-utils/numbers';

const formatters = {
  price: (value) => currency.full(value),
  priceShort: (value) => currency.short(value),
  sqft: (value) => pretty.int(value),
  years: (value) => (+value !== 1 ? `${value} years` : `${value} year`),
  ago: (value) => ago(value),
};

export default formatters;
