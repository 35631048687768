import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import Typography from 'ui/Typography';
import Icon from 'ui/Icon';

import formatValue from './formatValue';

import {
  Root,
  Image,
  IconWrapper,
} from './styledItems';

const PropertyColumn = (props) => {
  const {
    image,
    address,
    cost,
    dealType,
  } = props;

  return (
    <Root>
      <Grid container>
        <Grid item xs={4}>
          {
            image?.url ? (
              <Image
                shape="square"
                icon="picture"
                src={image?.url}
              />
            ) : (
              <IconWrapper>
                <Icon
                  name="property"
                  type="custom"
                  color="background-flat"
                  size="xl"
                />
              </IconWrapper>
            )
          }
        </Grid>
        <Grid item container xs={8}>
          <Grid item container xs={12}>
            <Typography
              variant="h5"
              weight={400}
              noMargin
            >
              {address}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="h6"
              tag="p"
              noMargin
            >
              {formatValue(cost)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="h6"
              tag="p"
              className="deal-type-text"
              weight={400}
              color="link"
              noMargin
            >
              {dealType}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Root>
  );
};

PropertyColumn.defaultProps = {

};

const {
  number,
  shape,
  string,
} = PropTypes;

PropertyColumn.propTypes = {
  image: shape({
    id: string,
    url: string,
  }).isRequired,
  dealType: string.isRequired,
  address: string.isRequired,
  cost: number.isRequired,
};

export default PropertyColumn;
