import React, {
  useState,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import Spin from 'ui/Spin';
import Elevation from 'ui/Elevation';

import useCampaign from 'sections/Communications/hooks/useCampaign';

import EmailCampaignEditor from 'sections/Communications/modules/Marketing/EmailCampaignEditor';
import CampaignDetails from 'sections/Communications/modules/Marketing/CampaignDetails';

const shouldShowComposer = (campaign) => !campaign?.recipients || campaign?.recipients?.length < 1;

const EmailMarketingCampaign = (props) => {
  const {
    campaignId,
  } = props;

  const {
    campaign,
  } = useCampaign(campaignId);

  const [view, setView] = useState(shouldShowComposer(campaign) ? 'composer' : 'details');

  useEffect(() => {
    setView(shouldShowComposer(campaign) ? 'composer' : 'details');
  }, [
    campaign?.recipients?.length,
  ]);

  if (!campaignId || !campaign?.id) {
 return (
   <Flex
     fullWidth
     justifyContent="center"
   >
     <Spin />
   </Flex>
  );
}

  if (view === 'composer') {
    return (
      <Elevation
        elevation={2}
        rounded={2}
        padding={'10px'}
        fullWidth
      >
        <Flex
          fullWidth
          alignItems="center"
          spacing={2}
          padding={'10px'}
        >
          <EmailCampaignEditor
            campaignId={campaignId}
            setView={setView}
          />
        </Flex>
      </Elevation>
    );
  }

  return (
    <Elevation
      elevation={2}
      rounded={2}
      padding={'10px'}
      fullWidth
    >
      <Flex
        fullWidth
        alignItems="center"
        spacing={2}
        padding={'10px'}
      >
        <CampaignDetails
          campaignId={campaignId}
          setView={setView}
        />
      </Flex>
    </Elevation>
  );
};

const {
  number,
} = PropTypes;

EmailMarketingCampaign.propTypes = {
  campaignId: number.isRequired,
};

export default EmailMarketingCampaign;
