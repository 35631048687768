import {
  useState,
  useEffect,
} from 'react';

import requests from 'api/requests';

const useCart = (reserved) => {
  const [list, setList] = useState([]);
  const [ids, setIds] = useState(new Set());
  const [error, setError] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const getList = async () => {
    setIsLoading(true);

    try {
      const result = await requests.globalProperties.listReserved();

      setList(result.data);

      setIds(new Set(result.data.map((item) => item.property_id)));

      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getList();
  }, [
    reserved,
  ]);

  console.log('ids:', ids);

  return {
    list,
    isLoading,
    error,
    ids,
  };
};

export default useCart;
