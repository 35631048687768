import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

const TypeCell = (props) => {
  const {
    value,
    isReinspection,
  } = props;

  return (
    <>
      {
        isReinspection ? (
          <Typography
            variant="cell"
            weight={500}
            noMargin
            style={{ textTransform: 'capitalize' }}
            color="highlight"
          >
            Re-inspection:
          </Typography>
        ) : null
      }

      <Typography
        variant="cell"
        weight={500}
        noMargin
        style={{ textTransform: 'capitalize' }}
      >
        {value}
      </Typography>
    </>
  );
};

TypeCell.defaultProps = {
  isReinspection: false,
};

const {
  bool,
  string,
} = PropTypes;

TypeCell.propTypes = {
  value: string.isRequired,
  isReinspection: bool,
};

export default TypeCell;
