import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  deletePropertyViewing,
} from 'store/actions/propertyViewings';

import ShowingCard from 'shared/components/UI/RealtorObjectCards/ShowingCard';

const mapStateToProps = (state, ownProps) => {
  const {
    id,
    updateList,
  } = ownProps;

  const showing = selectors.propertyViewings.byId(state, id);

  const {
    contacts,
    date_time,
  } = showing;

  return {
    showingId: id,
    contactId: contacts[0],
    updateList,
    showingDate: date_time,
  };
};

const mapDispatchToProps = (dispatch) => ({
  cancelShowing: (showingId) => {
    dispatch(deletePropertyViewing(showingId));
  },
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  cancelShowing: async () => {
    dispatchProps.cancelShowing(stateProps.showingId);
    if (stateProps.updateList) {
      await stateProps.updateList();
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ShowingCard);
