import { capitalizeWord } from 'shared/utils/strings';

const propertyParamKeys = [
  'property_type_name',
  'bedrooms',
  'bathrooms',
  'floor_area',
];

const propertyParamLabels = {
  bedrooms: 'Bed',
  bathrooms: 'Bath',
  floor_area: 'sqft',
  property_type_name: '',
};

const extractValues = (item) => (acc, key) => {
  if (item[key]) {
    acc.push(`${typeof item[key] === 'string' ? capitalizeWord(item[key]) : item[key]} ${propertyParamLabels[key]}`);
  }

  return acc;
};

const extractRange = (item) => (acc, key) => {
  if (item[`${key}_min`] && item[`${key}_max`]) {
    acc.push(`${item[`${key}_min`]}–${item[`${key}_max`]} ${propertyParamLabels[key]}`);
    return acc;
  }

  if (item[`${key}_min`]) {
    acc.push(`${item[`${key}_min`]} ${propertyParamLabels[key]}`);
    return acc;
  }

  if (item[`${key}_max`]) {
    acc.push(`${item[`${key}_max`]} ${propertyParamLabels[key]}`);
  }

  return acc;
};

const getPropertyParameters = (itemType) => (item) => {
  if (itemType === 'properties') {
    return propertyParamKeys.reduce(extractValues(item), []);
  }

  return propertyParamKeys.reduce(extractRange(item), []);
};

export default getPropertyParameters;
