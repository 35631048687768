import { connect } from 'react-redux';

// import {
//   openNewLeadModal,
// } from 'store/actions/newRealtorLeadModal';

import {
  openModal,
} from 'store/actions/modals';

import LeadsList from './LeadsList';

const mapStateToProps = (state) => {
  const {

  } = state;

  return {

  };
};

const mapDispatchToProps = (dispatch) => ({
  // openNewLeadModal: () => dispatch(openNewLeadModal()),
  openNewLeadModal: () => dispatch(openModal('newLeadFormSimple')()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeadsList);
