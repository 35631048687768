import { useState } from 'react';

const FB_APP_ID = process.env.REACT_APP_FB_APP_ID;
const FB_SDK_SRC = 'https://connect.facebook.net/en_US/sdk.js';
const FB_SDK_VERSION = 'v12.0';
const FB_SCRIPT_ID = 'facebook-jssdk';

// Injects the Facebook SDK into the page
const injectFbSDKScript = () => { // eslint-disable-line consistent-return
  if (document.getElementById(FB_SCRIPT_ID)) {
    return true;
  }

  const fjs = document.getElementsByTagName('script')[0];
  const js = document.createElement('script');
  js.id = FB_SCRIPT_ID;
  js.src = FB_SDK_SRC;
  fjs.parentNode.insertBefore(js, fjs);
};

const useInitFbSDK = () => {
  const [isInitialized, setIsInitialized] = useState(false);

  const scriptInjected = injectFbSDKScript();
  if (scriptInjected) {
    return true;
  }

  // Initializes the SDK once the script has been loaded
  // https://developers.facebook.com/docs/javascript/quickstart/#loading
  window.fbAsyncInit = () => {
    window.FB.init({
      appId: FB_APP_ID,
      cookie: true,
      xfbml: true,
      version: FB_SDK_VERSION,
    });

    setIsInitialized(true);
  };

  return isInitialized;
};

export default useInitFbSDK;
