import React from 'react';
import PropTypes from 'prop-types';

import withPropertyRequirementData from 'sections/Properties/hocs/withPropertyRequirementData';

import LocationCellMultiple from 'sections/Leads/modules/LeadsTable/LocationCellMultiple';

const Location = (props) => {
  const {
    propertyRequirement,
  } = props;

  return (
    <LocationCellMultiple addresses={propertyRequirement.addresses} />
  );
};

Location.defaultProps = {
  propertyRequirement: {},
};

const {
  arrayOf,
  number,
  shape,
} = PropTypes;

Location.propTypes = {
  propertyRequirement: shape({
    addresses: arrayOf(number),
  }),
};

export default withPropertyRequirementData(Location);
