/* eslint-disable consistent-return */
import requests from 'api/requests';
import { callAlert } from 'ui/Alert/Alert';

const onSubmit = async (formData) => {
  const {
    propertyId,

    firstName,
    lastName,
    phone,
    email,

    type,

    neighborhoods,
    beds,
    baths,
    size,

    propertyAddress,
    comments,
  } = formData;

  const contactData = {
    contact_type: type,

    realtor_property_id: propertyId,

    ex_submission_first_name: firstName,
    ex_submission_last_name: lastName,
    ex_submission_phone: phone,
    ex_submission_email: email,

    neighborhoods,
    beds,
    baths,
    size,

    property_address: propertyAddress,
    comments,
  };

  try {
    const response = await requests.external.realtor.contact.create(contactData);

    callAlert.success('Thank you! Your request has been submitted.');
    return response.data;
  } catch (error) {
    callAlert.error('Something went wrong!');
  }
};

export default onSubmit;
