import apiConnector, { endpoints } from 'api';

const list = async () => {
  const endpoint = endpoints.client.integrations.items;
  console.log(`called requests.integrations.list() for endpoint ${endpoint}`)
  try {
    const result = await apiConnector.client.get(endpoint);
    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default list;
