import { connect } from 'react-redux';

import { currency } from '@symplete/symplete-utils/numbers';

import selectors from 'store/selectors';

import {
  getOverallTransactionsSummary,
  getTransactionsForStoreFilter,
} from 'store/actions/realtorTransactions';

import {
  clearFilter,
  handleCustomFilter,
} from 'store/actions/realtorTransactionsFilter';

import KPIBar from 'ui/KPIBar';

const mapStateToProps = (state) => {
  const summary = selectors.realtorTransactions.overallSummary(state);
  const filterKey = selectors.realtorTransactions.transactionsFilterKey(state);

  return {
    values: {
      potential_commission: currency.full(summary?.potential_commission),
      precontingencies: summary?.precontingencies || 0,
      postcontingencies: summary?.postcontingencies || 0,
      all: summary?.total || 0,
    },
    indicators: [
      {
        key: 'potential_commission',
        title: 'Potential Commission',
        iconName: 'dollar-sign',
        flex: 1.5,
        disabled: true,
      },
      {
        key: 'precontingencies',
        title: 'Pre Contingencies',
        iconName: 'arrow-left-circle',
        selected: filterKey === 'precontingencies',
      },
      {
        key: 'postcontingencies',
        title: 'Post Contingencies',
        iconName: 'check-circle',
        selected: filterKey === 'postcontingencies',
      },
      {
        key: 'all',
        title: 'Total Transactions',
        iconName: 'repeat',
        selected: filterKey === 'all',
      },
    ],
  };
};

const mapDispatchToProps = (dispatch) => ({
  getData: getOverallTransactionsSummary,
  onClick: (key) => () => {
    dispatch(clearFilter());
    dispatch(handleCustomFilter(key));
    dispatch(getTransactionsForStoreFilter());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(KPIBar);
