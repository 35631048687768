import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  openNewInspectionModal,
  deleteInspection,
  optimizedRealtorInspectionsActions,
} from 'store/actions/realtorInspections';

import {
  optimizedRealtorTransactionsActions,
} from 'store/actions/realtorTransactions';

import { openModal } from 'store/actions/modals';

import ActionsCell from './ActionsCell';

const mapStateToProps = (state, ownProps) => {
  const {
    realtorTransactions: {
      selectedTransaction,
    },
    realtorInspectionsFilter: {
      pageNumber,
      pageSize,
    },
  } = state;

  const {
    inspectionId,
  } = ownProps;

  const {
    filterId,
  } = selectors.realtorInspections.inspectionsFilter(state);

  const inspection = selectors.realtorInspections.byId(inspectionId)(state);

  return {
    filterId,
    transactionId: selectedTransaction,
    pageNumber,
    pageSize,
    inspection,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  openRepairRequest: (transactionId) => {
    const { inspectionId } = ownProps;

    dispatch(openModal('newRepairRequestModal')({}, {
      transactionId,
      relatedInspectionId: inspectionId,
    }));
  },

  openNewInspection: (transactionId, parentInspection, updateInspectionTable) => {
    const { inspectionId } = ownProps;

    dispatch(openNewInspectionModal({
      transactionId,
      isReinspection: true,
      parentInspectionId: inspectionId,
      defaultInspectionType: parentInspection.inspection_type_id,
      defaultInspectorId: parentInspection.inspector_contact_id,
      // onAfterSubmit: updateInspectionTable,
      onAfterSubmit: () => {
        optimizedRealtorTransactionsActions.getTransactionById(transactionId);
      },
    }));
  },

  deleteInspectionAction: async (filterId, transactionId, pagination) => {
    const { inspectionId } = ownProps;
    await dispatch(deleteInspection(inspectionId));

    const offset = (pagination.pageNumber - 1) * pagination.pageSize;
    const limit = pagination.pageSize;

    optimizedRealtorInspectionsActions.getInspectionsForFilter(
      filterId,
      {
        transactionId,
      },
      offset,
      limit,
    );
    optimizedRealtorTransactionsActions.getTransactionById(transactionId);
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  onAction: (action) => {
    if (action === 'repair') {
      dispatchProps.openRepairRequest(
        stateProps.transactionId,
      );
    }

    if (action === 'reinspection') {
      dispatchProps.openNewInspection(
        stateProps.transactionId,
        stateProps.inspection,
      );
    }

    if (action === 'delete') {
      dispatchProps.deleteInspectionAction(
        stateProps.filterId,
        stateProps.transactionId,
        {
          pageNumber: stateProps.pageNumber,
          pageSize: stateProps.pageSize,
        },
      );
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ActionsCell);
