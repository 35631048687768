import React from 'react';
import PropTypes from 'prop-types';

// import {
//   CONTACT_TYPE_LABELS,
// } from 'constants/realtor.constants';

import Typography from 'ui/Typography';

import {
  Root,
} from './styledItems';

const PropertyStatusCell = (props) => {
  const {
    status,
    width,
  } = props;

  return (
    <Root
      status={status}
      width={width}
    >
      <Typography variant="tag" color="contrast">
        available
      </Typography>
    </Root>
  );
};

PropertyStatusCell.defaultProps = {
  width: null,
};

const {
  number,
  string,
} = PropTypes;

PropertyStatusCell.propTypes = {
  status: number.isRequired,
  /** any acceptable CSS width value */
  width: string,
};

export default PropertyStatusCell;
