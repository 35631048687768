import { connect } from 'react-redux';

import {
  openNewInspectionModal,
  optimizedRealtorInspectionsActions,
} from 'store/actions/realtorInspections';

import {
  optimizedRealtorTransactionsActions,
} from 'store/actions/realtorTransactions';

import selectors from 'store/selectors';

import Inspections from './Inspections';

const mapStateToProps = (state) => {
  const {
    realtorTransactions: {
      selectedTransaction,
    },
    realtorInspectionsFilter: {
      pageNumber,
      pageSize,
    },
  } = state;

  const {
    filterId,
  } = selectors.realtorInspections.inspectionsFilter(state);

  return {
    transactionId: selectedTransaction,
    filterId,
    pageNumber,
    pageSize,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateInspectionTable: (filterId, transactionId, pagination) => () => {
    const offset = (pagination.pageNumber - 1) * pagination.pageSize;
    const limit = pagination.pageSize;

    optimizedRealtorInspectionsActions.getInspectionsForFilter(
      filterId,
      {
        transactionId,
      },
      offset,
      limit,
    );

    optimizedRealtorTransactionsActions.getTransactionById(transactionId);
  },

  onOpenNewInspection: (transactionId, updateInspectionTable) => () => {
    dispatch(openNewInspectionModal({
      transactionId,
      onAfterSubmit: updateInspectionTable,
    }));
  },
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,

  openNewInspection: dispatchProps.onOpenNewInspection(
    stateProps.transactionId,
    dispatchProps.updateInspectionTable(stateProps.filterId, stateProps.transactionId, {
      pageNumber: stateProps.pageNumber,
      pageSize: stateProps.pageSize,
    }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Inspections);
