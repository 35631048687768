import React from 'react';

import { connect } from 'react-redux';

import moment from 'moment';

import selectors from 'store/selectors';

import { orderBy } from 'shared/utility';

import InitialDocuments from 'sections/Transactions/modules/InitialDocuments';
import SellerDisclosure from 'sections/Transactions/modules/SellerDisclosure';
import Inspections from 'sections/Transactions/modules/Inspections';
import Appraisal from 'sections/Transactions/modules/Appraisal';
import Loan from 'sections/Transactions/modules/Loan';
import FinalWalkthrough from 'sections/Transactions/modules/FinalWalkthrough';
import CloseEscrow from 'sections/Transactions/modules/CloseEscrow';
import MoveOutDate from 'sections/Transactions/modules/MoveOutDate';

import TransactionTabs from './TransactionTabs';

const stageNameDict = {
  initial_documents_completed_at: 'initial documents',
  seller_disclosure_completed_at: 'seller disclosure',
  inspections_completed_at: 'inspections',
  appraisal_completed_at: 'appraisal',
  loan_completed_at: 'loan',
  final_walkthrough_completed_at: 'final walkthrough',
  close_escrow_completed_at: 'close escrow',
  move_out_date_completed_at: 'move out date',
};

const expiryFieldDict = {
  initial_documents_completed_at: 'initial_documents_expires_at',
  seller_disclosure_completed_at: 'seller_disclosure_expires_at',
  inspections_completed_at: 'inspections_expires_at',
  appraisal_completed_at: 'appraisal_expires_at',
  loan_completed_at: 'loan_expires_at',
  final_walkthrough_completed_at: 'final_walkthrough_expires_at',
  close_escrow_completed_at: 'close_escrow_expires_at',
  move_out_date_completed_at: 'move_out_date_expires_at',
};

const getStageStatuses = (transactionObj) => Object.keys(stageNameDict).reduce((acc, key) => {
    const stageName = stageNameDict[key];

    acc[stageName] = {
      status: '',
      subtitle: '',
    };

    const expiryKey = expiryFieldDict[key];

    const isCompleted = transactionObj[key];
    const expiryDate = transactionObj[expiryKey];

    const daysDiff = moment(expiryDate).diff(moment(), 'days');

    acc[stageName].status = isCompleted
      ? 'success'
      : expiryDate
        ? daysDiff < 0
          ? 'error'
          : daysDiff < 2
            ? 'warning'
            : 'focus'
        : 'focus';

    acc[stageName].subtitle = isCompleted
      ? moment(transactionObj[key]).format('MMMM D')
      : expiryDate
        ? `Exp: ${moment(expiryDate).format('MMMM D')}`
        : 'Exp: enter date';

    acc[stageName].statusSubtitle = isCompleted
      ? null
      : expiryDate
        ? daysDiff < 0
          ? `(${daysDiff * -1} days late)`
          : daysDiff === 0
            ? '(today)'
            : `(${daysDiff} days left)`
        : null;

    return acc;
  }, {});

const mapStateToProps = (state) => {
  const {
    realtorTransactions: {
      selectedTransaction,
    },
  } = state;

  const transaction = selectors.realtorTransactions.byId(state, selectedTransaction);

  const stagesStatuses = getStageStatuses(transaction);

  const attorneyOrEscrow = selectors.appCustomization.settings(state).attorney_state_or_escrow_state;

  const startTabs = [
    {
      name: 'initial documents',
      iconName: 'folder',
      status: '',
      content: () => (
        <InitialDocuments transactionId={selectedTransaction} />
      ),
      expiryKey: 'initial_documents_expires_at',
    },
    {
      name: 'seller disclosure',
      iconName: 'list',
      status: '',
      content: () => (
        <SellerDisclosure transactionId={selectedTransaction} />
      ),
      expiryKey: 'seller_disclosure_expires_at',
    },
  ];

  const volatileTabs = [
    {
      name: 'inspections',
      iconName: 'search',
      status: '',
      content: () => (
        <Inspections transactionId={selectedTransaction} />
      ),
      expiryKey: 'inspections_expires_at',
    },
    {
      name: 'appraisal',
      iconName: 'target',
      status: '',
      content: () => (
        <Appraisal transactionId={selectedTransaction} />
      ),
      expiryKey: 'appraisal_expires_at',
    },
    {
      name: 'loan',
      iconName: 'shopping-cart',
      status: '',
      content: () => (
        <Loan transactionId={selectedTransaction} />
      ),
      expiryKey: 'loan_expires_at',
    },
    {
      name: 'final walkthrough',
      iconName: 'calendar',
      status: '',
      content: () => (
        <FinalWalkthrough transactionId={selectedTransaction} />
      ),
      expiryKey: 'final_walkthrough_expires_at',
    },
  ];

  const endTabs = [
    {
      name: 'close escrow',
      iconName: 'flag',
      status: '',
      content: () => (
        <CloseEscrow transactionId={selectedTransaction} />
      ),
      expiryKey: 'close_escrow_expires_at',
      displayName: attorneyOrEscrow === 'attorney' ? 'Closing Day' : 'Close Escrow',
    },
    {
      name: 'move out date',
      iconName: 'key',
      status: '',
      content: () => (
        <MoveOutDate transactionId={selectedTransaction} />
      ),
      expiryKey: 'move_out_date_expires_at',
    },
  ];

  const orderedTabs = [
    ...startTabs,
    ...orderBy(volatileTabs, (tab) => transaction[tab.expiryKey], ['asc']),
    ...endTabs,
  ];

  // const tabsWithTodayMarker = orderedTabs.reduce((acc, item) => {
  //   if (!acc.todayFound) {
  //     const expiryDate = transaction[item.expiryKey]
  //     if (expiryDate) {
  //       const isToday = moment(expiryDate).isSame(new Date, 'day')

  //       acc.list.push({
  //         ...item,
  //         isToday
  //       })

  //       if (isToday) {
  //         acc.todayFound = true
  //       }
  //     }
  //     else {
  //       acc.list.push(item)
  //     }
  //   }
  //   else {
  //     acc.list.push(item)
  //   }

  //   return acc
  // }, {
  //   list: [],
  //   todayFound: false
  // })

  return {
    transactionId: selectedTransaction,
    stagesStatuses,
    tabsCollection: orderedTabs,
  };
};

export default connect(mapStateToProps, null)(TransactionTabs);
