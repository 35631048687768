import React from 'react';

import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import getFeatureVersion from 'shared/utils/getFeatureVersion';

import ClientItem from 'sections/Clients/pages/ClientItem';
import ClientItemV2 from 'sections/Clients/pages/ClientItemV2';

import routes from 'sections/Clients/routes';

const version = getFeatureVersion('client seller page');

const Clients = () => (
  <Switch>
    {
        version === 1 && (
          <Route
            path={routes.clientItem}
            component={ClientItem}
          />
        )
      }
    {
        version !== 1 && (
          <Route
            path={routes.clientItem}
            component={ClientItemV2}
          />
        )
      }

    <Redirect to={routes.sectionRoot} />
  </Switch>
  );

export default Clients;
