/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import { Controller } from 'react-hook-form';

import Grid from 'ui/Grid';

import Typography from 'ui/Typography';

import Checkbox from 'ui/Checkbox';

const ReasonSelect = (props) => {
  const {
    control,
    rejectionReasons,
  } = props;

  return (
    <Grid
      container
      spacing={2}
    >
      {
        rejectionReasons.map((item) => (
          <Grid item>
            <Controller
              key={item.id}
              name={`reason_id_${item.id}`}
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                >
                  <Grid item>
                    <Checkbox
                      {...field}
                      checked={field?.value}
                    />
                  </Grid>
                  <Grid>
                    <Typography
                      style={{ textTransform: 'capitalize' }}
                      noMargin
                    >
                      {item.name}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            />
          </Grid>
        ))
      }
    </Grid>
  );
};

ReasonSelect.defaultProps = {
  rejectionReasons: [],
};

const {
  shape,
  arrayOf,
  string,
  number,
} = PropTypes;

ReasonSelect.propTypes = {
  control: shape().isRequired,
  errors: shape().isRequired,
  rejectionReasons: arrayOf(shape({
    id: number,
    name: string,
  })),
};

export default ReasonSelect;
