import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  openNewRepairRequestModal,
} from 'store/actions/realtorRepairRequests';

import {
  openModal,
} from 'store/actions/modals';

import {
  openNewDocumentRequestModal,
} from 'store/actions/realtorDocumentRequests';

import {
  openNewAddendumChangeModal,
} from 'store/actions/realtorAddendumChanges';

import {
  openLogNewQuestionModal,
} from 'store/actions/realtorUnansweredQuestions';

import {
  openNewExtensionRequestModal,
} from 'store/actions/realtorExtensionRequests';

import KPIIndicatorBar from './KPIIndicatorBar';

const openAddendumChangesModal = openModal('addendumChanges');
const openUnansweredQuestionsModal = openModal('unansweredQuestions');
const openDocumentRequestsModal = openModal('documentRequestsModal');
const openExtensionRequestsModal = openModal('extensionRequests');
const openRepairRequestsModal = openModal('repairRequests');

const transactionsKpiTestData = {
  // expiringThisWeek: 'N/A',
  addendumChange: 'N/A',
  repairRequests: 'N/A',
  documentRequests: 'N/A',
  extensionRequest: 'N/A',
  unansweredQuestions: 'N/A',
};

const mapStateToProps = (state, ownProps) => {
  const transaction = selectors.realtorTransactions.byId(state, ownProps.transactionId);

  const repairRequestsSummary = transaction?.repair_requests_summary;
  const addendumChangeSummary = transaction?.addendum_requests_summary;
  const documentRequestSummary = transaction?.document_requests_summary;
  const questionsSummary = transaction?.questions_summary;
  const extensionRequestSummary = transaction?.extension_requests_summary;

  return {
    data: {
      ...transactionsKpiTestData,
      repairRequests: repairRequestsSummary?.total_repair_requests || 0,
      addendumChange: (addendumChangeSummary?.new || 0) + (addendumChangeSummary?.outstanding || 0),
      documentRequests: (documentRequestSummary?.new || 0) + (documentRequestSummary?.outstanding || 0),
      unansweredQuestions: (questionsSummary?.new || 0) + (questionsSummary?.outstanding || 0),
      extensionRequest: (extensionRequestSummary?.new || 0) + (extensionRequestSummary?.outstanding || 0),
    },
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  addendumChangeMainAction: () => {
    dispatch(openAddendumChangesModal(
      {},
      {
        transactionId: ownProps.transactionId,
      },
    ));
  },
  addendumChangeAddAction: () => {
    dispatch(openNewAddendumChangeModal({
      onAfterSubmit: () => {
        dispatch(openAddendumChangesModal(
          {},
          {
            transactionId: ownProps.transactionId,
          },
        ));
      },
    }));
  },

  repairRequestMainAction: () => {
    dispatch(openRepairRequestsModal());
  },
  repairRequestAddAction: () => {
    dispatch(openNewRepairRequestModal({
      transactionId: ownProps.transactionId,
      onAfterSubmit: () => {
        dispatch(openRepairRequestsModal());
      },
    }));
  },

  documentRequestsMainAction: () => {
    dispatch(openDocumentRequestsModal(
      {},
      {
        transactionId: ownProps.transactionId,
      },
    ));
  },
  documentRequestsAddAction: () => {
    dispatch(openNewDocumentRequestModal({
      onAfterSubmit: () => {
        dispatch(openDocumentRequestsModal(
          {},
          {
            transactionId: ownProps.transactionId,
          },
        ));
      },
    }));
  },

  extensionRequestMainAction: () => {
    dispatch(openExtensionRequestsModal(
      {},
      {
        transactionId: ownProps.transactionId,
      },
    ));
  },
  extensionRequestAddAction: () => {
    dispatch(openNewExtensionRequestModal({
      onAfterSubmit: () => {
        dispatch(openExtensionRequestsModal(
          {},
          {
            transactionId: ownProps.transactionId,
          },
        ));
      },
    }));
  },

  unansweredQuestionsMainAction: () => {
    dispatch(openUnansweredQuestionsModal(
      {},
      {
        transactionId: ownProps.transactionId,
      },
    ));
  },
  unansweredQuestionsAddAction: () => {
    dispatch(openLogNewQuestionModal({
      onAfterSubmit: () => {
        dispatch(openUnansweredQuestionsModal(
          {},
          {
            transactionId: ownProps.transactionId,
          },
        ));
      },
    }));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,

  indicatorMainAreaActions: (indicatorKey) => () => {
    if (indicatorKey === 'addendumChange') {
      dispatchProps.addendumChangeMainAction();
    }

    if (indicatorKey === 'repairRequests') {
      dispatchProps.repairRequestMainAction();
    }

    if (indicatorKey === 'documentRequests') {
      dispatchProps.documentRequestsMainAction();
    }

    if (indicatorKey === 'extensionRequest') {
      dispatchProps.extensionRequestMainAction();
    }

    if (indicatorKey === 'unansweredQuestions') {
      dispatchProps.unansweredQuestionsMainAction();
    }
  },

  indicatorAddButtonActions: (indicatorKey) => () => {
    if (indicatorKey === 'addendumChange') {
      dispatchProps.addendumChangeAddAction();
    }

    if (indicatorKey === 'repairRequests') {
      dispatchProps.repairRequestAddAction();
    }

    if (indicatorKey === 'documentRequests') {
      dispatchProps.documentRequestsAddAction();
    }

    if (indicatorKey === 'extensionRequest') {
      dispatchProps.extensionRequestAddAction();
    }

    if (indicatorKey === 'unansweredQuestions') {
      dispatchProps.unansweredQuestionsAddAction();
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(KPIIndicatorBar);
