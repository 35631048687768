import { connect } from 'react-redux';

import LeadPropertiesSelector from './LeadPropertiesSelector';

const mapStateToProps = (state, ownProps) => {
  const {

  } = state;

  return {
    selectedTab: null,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(LeadPropertiesSelector);
