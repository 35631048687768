import apiConnector, { endpoints } from 'api';

const get = async (phoneNumber) => {
  const endpoint = `${endpoints.client.calls.isPhoneACaller}/${phoneNumber}`;
  try {
    const result = await apiConnector.client.get(endpoint);

    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default get;
