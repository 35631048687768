import React, {
  useContext,
} from 'react';

import OpportunitiesContext from 'sections/OpportunitiesListing/context/OpportunitiesContext';

import Table from 'ui/Table';
import Typography from 'ui/Typography';

import columns from './columns';

const tableStyle = {
  minWidth: '100%',
};

const OpportunitiesTable = () => {
  const {
    getPropertiesError,
    isLoadingProperties,
    dataSource,
    // columns,
  } = useContext(OpportunitiesContext);

  if (getPropertiesError) {
    return (
      <Typography color="error">
        Error loading data
      </Typography>
    );
  }

  return (
    <Table
      style={tableStyle}
      columns={columns}
      dataSource={dataSource}
      loading={isLoadingProperties}
    />
  );
};

export default OpportunitiesTable;
