import React from 'react';
import PropTypes from 'prop-types';

import { Root, Initials } from './styledItems';

const getInitials = (firstName = '', lastName = '') => {
  const fname = firstName ? firstName.split('')[0] || '' : '';
  const lname = lastName ? lastName.split('')[0] || '' : '';

  return fname + lname;
};

const Avatar = (props) => {
  const {
    src,
    firstName,
    lastName,
  } = props;

  const initials = getInitials(firstName, lastName);

  return (
    <div>
      <Root src={src}>
        {
          !src && (
            <Initials>{initials}</Initials>
          )
        }
      </Root>
    </div>
  );
};

Avatar.defaultProps = {
  src: null,
  firstName: null,
  lastName: null,
};

const {
  string,
} = PropTypes;

Avatar.propTypes = {
  /**
   * image source
   */
  src: string,
  /**
   * first name
   */
  firstName: string,
  /**
   * last name
   */
  lastName: string,
};

export default Avatar;
