import requests from 'api/requests';

import updateLocalStorage from 'shared/utils/updateLocalStorageWithCustomEvent';

const getFbLongLivedToken = (accessToken) => async (dispatch) => {
  try {
    const response = await requests.socialIntegrations.facebook.getLongLivedToken(accessToken);
    const token = response.data?.token;

    updateLocalStorage('set', 'fbLongLivedUserAccessToken', token);

    return token;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export default getFbLongLivedToken;
