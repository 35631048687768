import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import Button from 'ui/Button';

import MainContactInfo from './MainContactInfo';

import {
  Root,
} from './styledItems';

const MatchingPropertiesBuyerCard = (props) => {
  const {
    contactId,
    onClickArrangeShowing,
  } = props;

  return (
    <Root
      rounded="s"
      padding="8px"
    >
      <Flex
        flexDirection="column"
        alignItems="stretch"
        spacing={1}
      >
        <MainContactInfo contactId={contactId} />
        <Flex justifyContent="space-between">
          <Button
            fullWidth
            variant="primary"
            onClick={onClickArrangeShowing}
          >
            Arrange Showing
          </Button>
        </Flex>
      </Flex>

    </Root>
  );
};

const {
  number,
  func,
} = PropTypes;

MatchingPropertiesBuyerCard.propTypes = {
  contactId: number.isRequired,
  onClickArrangeShowing: func.isRequired,
};

export default MatchingPropertiesBuyerCard;
