import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ToDoListHeader.scss';


const ToDoListHeader = (props) => {
  const {
    toDoListSettingsIsVisible,
    toDoListSettingsHideAction,
    toDoListDrawerHideAction,
    toDoListSettingsShowAction,
  } = props;

  function closeDrawer() {
    toDoListSettingsHideAction();
    toDoListDrawerHideAction();
  }

  function settingsDrawerControl(event) {
    event.preventDefault();
    if (!toDoListSettingsIsVisible) {
      toDoListSettingsShowAction();
    } else {
      toDoListSettingsHideAction();
    }
  }

  return (
    <div className="to-do-list-header">
      <span>To Do List</span>
      <button type="button">
        <FontAwesomeIcon
          icon="angle-right"
          onClick={closeDrawer}
        />
      </button>
      <button
        type="button"
        className="settings-button"
        onClick={settingsDrawerControl}
      >
        <FontAwesomeIcon icon="cog" />
      </button>
      <br />
      <span className="header-explanations">
        Items that are not assigned by or to other users can only be seen by you.
      </span>
    </div>
  );
};

const {
  bool,
  func,
} = PropTypes;

ToDoListHeader.defaultProps = {
  toDoListSettingsIsVisible: false,
};

ToDoListHeader.propTypes = {
  toDoListDrawerHideAction: func.isRequired,
  toDoListSettingsHideAction: func.isRequired,
  toDoListSettingsShowAction: func.isRequired,
  toDoListSettingsIsVisible: bool,
};

export default ToDoListHeader;
