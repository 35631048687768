import React, {
  // useState,
  // useEffect,
  useCallback,
} from 'react';

import Grid from 'ui/Grid';

import PropTypes from 'prop-types';
import PlacesAutocomplete from 'shared/components/UI/PlacesAutocompleteV2';

import AddressChip from 'sections/Addresses/modules/AddressChip';

const PreferredLocationInput = (props) => {
  const {
    handleChange,
    // value,
    addressIds,
    handleRemove,
  } = props;

  // const [currentValue, setCurrentValue] = useState(null);

  // useEffect(() => {
  //   setCurrentValue(value);
  // }, [
  //   value,
  // ]);

  const onChange = useCallback((location) => {
    // setCurrentValue(location?.formatted_address);
    handleChange({
      address: location?.formatted_address,
    });
  }, [
    handleChange,
  ]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <PlacesAutocomplete
          placeholder="Preferred location (city or exact address)"
          variant="outlined"
          // defaultValue={currentValue}
          onSelect={onChange}
          onChange={onChange}
          // selectedValue={currentValue}
          // value={currentValue}
        />
      </Grid>
      {
        addressIds?.length > 0 && (
          <Grid item xs={12}>
            <Grid container spacing={1}>
              {
                addressIds?.map((item) => (
                  <Grid item>
                    <AddressChip
                      addressId={item}
                      onDelete={() => handleRemove([item])}
                    />
                  </Grid>
                ))
              }
            </Grid>
          </Grid>
        )
      }
    </Grid>
  );
};

PreferredLocationInput.defaultProps = {
  addressIds: [],
};

const {
  arrayOf,
  func,
  number,
  // string,
} = PropTypes;

PreferredLocationInput.propTypes = {
  handleChange: func.isRequired,
  handleRemove: func.isRequired,
  // value: string,
  addressIds: arrayOf(number),
};

export default PreferredLocationInput;
