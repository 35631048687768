import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

import {
  ROLE_LABELS,
} from 'constants/realtor.constants';

import {
  Root,
} from './styledItems';

const RoleLabel = (props) => {
  const {
    role,
  } = props;

  return (
    <Root
      role={ROLE_LABELS[role]}
    >
      <Typography variant="tag" color="contrast">
        {ROLE_LABELS[role] || 'good man'}
      </Typography>
    </Root>
  );
};

RoleLabel.defaultProps = {

};

const {
  number,
} = PropTypes;

RoleLabel.propTypes = {
  role: number.isRequired,
};

export default RoleLabel;
