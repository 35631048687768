import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { NakedButton } from '@symplete/symplete-ui';

export const Root = styled(NakedButton)`
&& {
  svg {
    color: $icon-button-color;
    transition: all .2s ease;
  }
  
  &:hover {
    svg {
      opacity: 0.7;
    }
  }
}
`;

export const Icon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme?.palette?.[props.variant] || props.color || '#6b88a4'}
`;

export default {
  Root,
  Icon,
};
