import styled from 'styled-components';

import { lighten } from 'polished';

export const Root = styled.div`
  width: 100%;
  padding: 15px 10px;
  border-radius: 8px;
  background-color: ${(props) => lighten(0.385, props?.theme?.palette?.success)};
`;

export const InnerWrapper = styled.div`
  padding-left: 5px;
`;
