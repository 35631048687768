import React, {
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';
import Modal from 'ui/Modal';
import Typography from 'ui/Typography';

const CompleteTransaction = (props) => {
  const {
    onComplete,
    hasUnfinishedItems,
  } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleModal = useCallback((visible) => () => {
    setIsModalVisible(visible);
  }, []);

  return (
    <>
      <Button
        variant="primary"
        icon="check-circle"
        onClick={toggleModal(true)}
      >
        Complete
      </Button>

      <Modal
        width={600}
        visible={isModalVisible}
        onCancel={toggleModal(false)}
        title="Are you sure?"
        footer={[
          <Button
            onClick={toggleModal(false)}
            variant="secondary"
          >
            Cancel
          </Button>,
          <Button
            onClick={onComplete}
            variant="primary"
          >
            Confirm
          </Button>,
        ]}
      >
        {
          hasUnfinishedItems && (
            <Typography
              variant="title2"
              weight={400}
              noMargin
            >
              Some items have not been marked as done.
            </Typography>
          )
        }
      </Modal>
    </>
  );
};

CompleteTransaction.defaultProps = {
  hasUnfinishedItems: false,
};

const {
  func,
  bool,
} = PropTypes;

CompleteTransaction.propTypes = {
  onComplete: func.isRequired,
  hasUnfinishedItems: bool,
};

export default CompleteTransaction;
