import { connect } from 'react-redux';

import BuyerRequirements from './BuyerRequirements';

const mapStateToProps = (state) => {
  const {

  } = state;

  return {

  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(BuyerRequirements);
