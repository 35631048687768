import React from 'react';

import { datetime } from '@symplete/symplete-utils';

import Typography from 'ui/Typography';

const SendDate = (value, record) => {
  if (!value) {
 return (
   <Typography
     variant="title3"
     tag="p"
   >
     —
   </Typography>
  );
}

  return (
    <>
      <Typography
        variant="title3"
        tag="p"
      >
        {datetime(value).format('MMM DD, YYYY')}
      </Typography>
      <Typography
        variant="title4"
        color="ghost"
      >
        {datetime(value).format('hh:mm A')}
      </Typography>
    </>
  );
};

export default SendDate;
