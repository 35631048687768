import React from 'react';

import { get } from 'shared/utility';

import Typography from 'ui/Typography';

import Details from 'sections/OpportunitiesListing/modules/OpportunitiesTable/components/Details';

import formatters from 'sections/OpportunitiesListing/utils/formatters';

const customCellFormat = (details) => {
  const previous = get(details, 'property.last_transaction.price');

  if (!previous) return <>N/A</>;

  const expected = get(details, 'analytics.avm');

  const change = +expected - +previous;

  const color = change > 0 ? 'success' : 'error';

  return (
    <Typography color={color} noMargin>
      {formatters.priceShort(change)}
    </Typography>
  );
};

const PriceChange = (props) => {
  const {
    propertyId,
    entry,
  } = props;

  return (
    <Details
      entry={entry}
      propertyId={propertyId}
      customCellFormat={customCellFormat}
    />
  );
};

export default PriceChange;
