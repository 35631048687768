import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { NakedButton } from '@symplete/symplete-ui';

const FlagCell = (props) => {
  const {
    onFlagClick,
    isFlagged,
  } = props;

  return (
    <NakedButton onClick={onFlagClick(isFlagged)} style={{ margin: '0 auto' }}>
      <FontAwesomeIcon
        icon="flag"
        style={{
          color: isFlagged ? '#1e2a37' : '#a1bdcb',
          fontSize: 18,
        }}
      />
    </NakedButton>
  );
};

const {
  bool,
  func,
} = PropTypes;

FlagCell.propTypes = {
  onFlagClick: func.isRequired,
  isFlagged: bool.isRequired,
};

export default FlagCell;
