import React from 'react';

import TableHead from 'ui/TableHead'

import TransactionProgressColumn from './TransactionProgressColumn';
import PropertyColumn from './PropertyColumn';
import ToDoCountColumn from './ToDoCountColumn';

const columns = ({
  hoveredRecord,
}) => ([
  {
    title: () => <TableHead>Property</TableHead>,
    width: 5,
    dataIndex: 'realtor_property_id',
    key: 'realtor_property_id',
    render: (value, row) => (
      <PropertyColumn
        propertyId={value}
        prospectId={row.realtor_prospect_id}
        clientId={row.realtor_client_id}
        offerId={row.final_offer_id}
        initialPrice={row.price}
        finalPrice={row.final_price}
      />
    ),
  },
  {
    title: () => <TableHead>To Do</TableHead>,
    dataIndex: 'toDo',
    key: 'toDo',
    width: 1,
    render: (value, row) => (
      <ToDoCountColumn
        newQuestionsCount={row.questions_summary?.new || 0}
        newRepairRequestsCount={row.repair_requests_summary?.new || 0}
        newDocumentRequestCount={row.document_requests_summary?.new || 0}
        newExtensionRequestsCount={row.extension_requests_summary?.new || 0}
        newAddendumRequestsCount={row.addendum_requests_summary?.new || 0}
      />
    ),
  },
  {
    title: () => <TableHead>Progress</TableHead>,
    width: 10,
    dataIndex: 'id',
    key: 'progress',
    render: (value) => (
      <TransactionProgressColumn transactionId={value} />
    ),
  },
]);

export default columns;
