import { connect } from 'react-redux';

// import { openModal as openArchiveDeleteModal } from 'store/actions/archiveDeleteLeadModal';
// import { openModal as openLeadToProspectModal } from 'store/actions/leadIntoProspectModal';

import ActionButtons from './ActionButtons';

const mapDispatchToProps = (dispatch) => ({
  // openModal: (modalType, data) => {
  //   const {
  //     leadId,
  //     connectedItemType,
  //     connectedItemId,
  //   } = data;

  //   if (modalType === 'leadToProspect') {
  //     dispatch(openLeadToProspectModal(leadId, connectedItemType, connectedItemId));
  //   }
  //   if (modalType === 'archiveDelete') {
  //     dispatch(openArchiveDeleteModal(leadId, connectedItemType, connectedItemId));
  //   }
  // },
});

export default connect(null, mapDispatchToProps)(ActionButtons);
