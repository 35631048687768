import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import { currency } from '@symplete/symplete-utils/numbers';

import { NakedButton } from '@symplete/symplete-ui';
import Flex from 'ui/Flex';
import Typography from 'ui/Typography';

import PropertyPhoto from 'shared/components/UI/RealtorPropertyPhoto';

import withMainPropertyImage from 'sections/Properties/hocs/withMainPropertyImage';

import {
  Wrapper,
} from './styledItems';

const PropertyDetails = (props) => {
  const {
    propertyId,
    contactId,
    contactType,
    onLinkClick,
    address,
    price,
    name,
    lastName,
  } = props;

  const history = useHistory();

  const RealtorPropertyPhoto = withMainPropertyImage(PropertyPhoto);

  const handleClientLinkClick = useCallback((event) => {
    event.preventDefault(); // eslint-disable-line no-restricted-globals
    if (onLinkClick) {
      onLinkClick();
    }
    history.push(`/${contactType}s/${contactId}`);
  }, [contactId]);

  return (
    <Wrapper>
      <Flex
        spacing={2}
        spacingStep={6}
        alignItems="stretch"
      >
        <RealtorPropertyPhoto
          propertyId={propertyId}
          className="offer-property-details-photo"
        />
        <Flex
          flexDirection="column"
          justifyContent="space-evenly"
        >
          <Typography
            variant="title2"
            weight={500}
            noMargin
          >
            {address}
          </Typography>

          <Typography
            variant="title3"
            weight="normal"
            noMargin
          >
            {`Original Price: ${ currency.full(price)}`}
          </Typography>

          <Flex spacing={1}>
            <Typography
              variant="title3"
              weight="normal"
              noMargin
            >
              Representing:
            </Typography>

            <NakedButton onClick={handleClientLinkClick}>
              <Typography
                variant="title3"
                color="focus"
                weight="normal"
                noMargin
              >
                {`${name || ''} ${lastName || ''}`}
                {' '}
                (
                {contactType}
                )
              </Typography>
            </NakedButton>
          </Flex>
        </Flex>
      </Flex>
    </Wrapper>

  );
};

PropertyDetails.defaultProps = {
  clientId: null,
  propertyId: null,
  address: null,
  price: 0,
  name: null,
  lastName: null,
  onLinkClick: null,
};

const {
  number,
  func,
  string,
} = PropTypes;

PropertyDetails.propTypes = {
  clientId: number,
  onLinkClick: func,
  propertyId: number,
  address: string,
  price: number,
  name: string,
  lastName: string,
};

export default PropertyDetails;
