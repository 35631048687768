import {
  useState,
  useEffect,
} from 'react';

import { isString } from 'shared/utility';

const getSymScore = (item) => (+item?.score / 100).toFixed(1);

const getOwner = (item) => {
  const ownerObj = isString(item.homeowner) ? JSON.parse(item.homeowner) : item.homeowner;

  return `${ownerObj.first_name || ''}${ownerObj.last_name && ' '}${ownerObj.last_name}`;
};

const getAddress = (item) => {
  const addressObj = isString(item.address) ? JSON.parse(item.address) : item.address;

  return `${addressObj?.address}, ${addressObj?.zip} ${addressObj?.city} ${addressObj?.state}`;
};

const getDataSource = (filters) => (properties = [], details = {}, cartIds = new Set()) => {
  const ds = properties
    .filter((item) => {
      if (!filters.priceRange) return true;

      const itemDetails = item?.details ?? details[item.property_id]?.details;

      if (!itemDetails) return true;

      return (+itemDetails?.analytics?.avm >= filters.priceRange[0]) && (+itemDetails?.analytics?.avm <= filters.priceRange[1]);
    })
    .filter((item) => {
      if (!filters.ownershipDuration) return true;

      const itemDetails = item?.details ?? details[item.property_id]?.details;

      if (!itemDetails) return true;

      return (+itemDetails?.analytics?.residency_length >= filters.ownershipDuration[0]) && (+itemDetails?.analytics?.residency_length < filters.ownershipDuration[1]);
    })
    .map((item) => ({
      id: item?.property_id,
      symScore: getSymScore(item),
      address: getAddress(item),
      owner: getOwner(item),
      details: item?.details,
      reserved: cartIds.has(item?.property_id),
    }))
    .filter((item) => {
      let matchesSymScoreRange = true;
      let matchesAddressFilter = true;
      let matchesOwnerFilter = true;

      if (filters.symScoreRange) {
        matchesSymScoreRange = (+item.symScore >= filters.symScoreRange[0]) && (+item.symScore <= filters.symScoreRange[1]);
      }

      if (filters.address) {
        matchesAddressFilter = item.address.toLowerCase().includes(filters.address.toLowerCase());
      }

      if (filters.owner) {
        matchesOwnerFilter = item.owner.toLowerCase().includes(filters.owner.toLowerCase());
      }

      const matchesFilters = matchesAddressFilter || matchesOwnerFilter;

      return matchesSymScoreRange && matchesFilters;
    });

  return ds;
};

const useTableData = (properties, propertyDetails, cartIds) => {
  const [dataSource, setDataSource] = useState([]);

  const [symScoreRange, setSymScoreRange] = useState(null);
  const [priceRange, setPriceRange] = useState(null);
  const [ownershipDuration, setOwnershipDuration] = useState(null);
  const [filter, setFilter] = useState(null);

  useEffect(() => {
    const ds = getDataSource({
      symScoreRange,
      priceRange,
      address: filter,
      owner: filter,
      ownershipDuration,
    })(properties, propertyDetails, cartIds);

    setDataSource(ds);
  }, [
    properties,
    propertyDetails,
    symScoreRange?.[0],
    symScoreRange?.[1],
    priceRange?.[0],
    priceRange?.[1],
    ownershipDuration?.[0],
    ownershipDuration?.[1],
    filter,
  ]);

  return {
    dataSource,
    setSymScoreRange,
    setPriceRange,
    setFilter,
    setOwnershipDuration,
  };
};

export default useTableData;
