import React from 'react';

import InspectorCell from './components/InspectorCell';
import InspectionDateCell from './components/InspectionDateCell';
import FlagCell from './components/FlagCell';
import TypeCell from './components/TypeCell';
import NoteCell from './components/NoteCell';
import AttachmentsCell from './components/AttachmentsCell';
import ActionsCell from './components/ActionsCell';
import StatusCell from './components/StatusCell';

const columns = ({
  hoveredRecord,
  transactionId,
}) => ([
  {
    title: 'Type',
    width: 200,
    dataIndex: 'inspection_type_id',
    key: 'type',
    render: (value, row) => (
      <TypeCell
        inspectionTypeId={value}
        inspectionId={row?.id}
        isReinspection={row?.is_reinspection}
      />
    ),
  },
  {
    title: 'Inspector',
    dataIndex: 'inspector_contact_id',
    key: 'inspector',
    width: 150,
    render: (value, row) => (
      <InspectorCell
        contactId={value}
        inspectionId={row?.id}
      />
    ),
  },
  {
    title: 'Date & Time',
    width: 100,
    dataIndex: 'date_time',
    key: 'dateTime',
    render: (value, row) => (
      <InspectionDateCell
        date={value}
        inspectionId={row?.id}
      />
    ),
  },
  {
    title: 'Report File',
    width: 150,
    dataIndex: 'id',
    key: 'attachments',
    className: 'realtor-inspections-table__sm-padding',
    render: (value) => (
      <AttachmentsCell
        inspectionId={value}
      />
    ),
  },
  {
    title: 'Notes',
    width: 60,
    dataIndex: 'notes',
    key: 'notes',
    className: 'realtor-inspections-table__sm-padding',
    render: (value, row) => (
      <NoteCell
        hasContent={value && value !== '<p></p>'}
        inspectionId={row?.id}
      />
    ),
    align: 'center',
  },
  {
    title: 'Flagged',
    width: 60,
    dataIndex: 'is_flagged',
    key: 'isFlagged',
    render: (value, row) => (
      <FlagCell
        isFlagged={value}
        inspectionId={row?.id}
      />
    ),
    align: 'center',
  },
  {
    title: 'Status',
    width: 80,
    dataIndex: 'status',
    key: 'status',
    render: (value, row) => (
      <StatusCell
        status={value || 5}
        inspectionId={row?.id}
        transactionId={transactionId}
      />
    ),
  },
  {
    title: '',
    width: 30,
    key: 'delete',
    className: 'realtor-inspections-table__sm-padding',
    render: (_, row) => {
      if (hoveredRecord === row.id) {
        return (
          <ActionsCell
            inspectionId={row.id}
          />
        );
      }

      return null;
    },
    align: 'center',
  },
]);

export default columns;
