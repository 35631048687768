import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateSelectedLead,
} from 'store/actions/realtorLeads';

import PinnedNote from 'shared/components/PinnedNote';

const mapStateToProps = (state) => {
  const selectedLead = selectors.realtorLeads.selected(state);

  return {
    content: selectedLead.pinned_note,
    selectedObjectId: selectedLead.id,
    objectTypeName: 'lead',
  };
};

const mapDispatchToProps = (dispatch) => ({
  onNoteChange: async (content) => {
    await dispatch(updateSelectedLead({ pinned_note: content }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PinnedNote);
