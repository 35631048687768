import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import PriceRange from 'ui/PriceRange';

import withDebouncedCallbacks from 'shared/hocs/withDebouncedCallbacks';

import withPropertyRequirementData from 'sections/Leads/hocs/withPropertyRequirementData';

import PreferredLocationInput from 'sections/Leads/inputs/PreferredLocationInput';
import PropertyMultiTypeSelect from 'sections/Leads/inputs/PropertyMultiTypeSelect';
import LeadPropertyParametersArea from 'sections/Leads/inputs/LeadPropertyParametersArea';
import SaleTypeSelect from 'sections/Leads/inputs/SaleTypeSelect';
import YearBuiltSelect from 'sections/Leads/inputs/YearBuiltSelect';
import NumberOfStoriesSelect from 'sections/Leads/inputs/NumberOfStoriesSelect';
import ViewsFromHomeSelect from 'sections/Leads/inputs/ViewsFromHomeSelect';

import DetailsSection from 'sections/Leads/components/DetailsSection';

const requirementParameters = {
  bedrooms: 'bedrooms_min',
  bathrooms: 'bathrooms_min',
  floor_area: 'floor_area_min',
  plot_area: 'plot_area_min',
  parking: 'parking_min',
};

const relationFields = [
  'propertyTypes',
  'saleTypes',
  'views',
];

const PrimaryRequirements = (props) => {
  const {
    propertyRequirementData,
    onChange,
    onRelationOverwrite,
    onRelationAdd,
    onRelationRemove,
  } = props;

  const PriceInput = withDebouncedCallbacks(
    PriceRange,
    [
      ['onChange', 1000, { leading: false }],
    ],
  );

  const handleFieldChange = useCallback((field) => (value) => {
    if (field === 'budget') {
      onChange('budget_min', value[0]);
      onChange('budget_max', value[1]);
    } else if (relationFields.includes(field)) {
      onRelationOverwrite(field)(value);
    } else {
      onChange(field, value);
    }
  }, [
    onChange,
    onRelationOverwrite,
  ]);

  const onParameterChange = useCallback((key, value) => {
    onChange(requirementParameters[key], value);
  }, [
    onChange,
  ]);

  return (
    <Flex
      spacing={4}
      flexDirection="column"
    >
      <DetailsSection
        icon="map-pin"
        title="Preferred Location"
      >
        <PreferredLocationInput
          handleAdd={onRelationAdd('addresses')}
          handleRemove={onRelationRemove('addresses')}
          addressIds={propertyRequirementData?.addresses}
        />
      </DetailsSection>

      <DetailsSection
        icon="check-circle"
        iconType="feather"
        title="Primary Requirements"
      >
        <Flex
          spacing={4}
          alignItems="center"
          flexWrap="wrap"
        >
          <FlexItem fullWidth>
            <LeadPropertyParametersArea
              parameters={{
                bedrooms: propertyRequirementData?.bedrooms_min,
                bathrooms: propertyRequirementData?.bathrooms_min,
                floor_area: propertyRequirementData?.floor_area_min,
                plot_area: propertyRequirementData?.plot_area_min,
                parking: propertyRequirementData?.parking_min,
              }}
              handleChangeProperty={onParameterChange}
            />
          </FlexItem>

          <FlexItem xs={8}>
            <PriceInput
              onChange={handleFieldChange('budget')}
              value={[propertyRequirementData?.budget_min || 0, propertyRequirementData?.budget_max || 0]}
            />
          </FlexItem>
          <FlexItem xs={16}>
            <Flex spacing={4}>
              <FlexItem flex={1}>
                <PropertyMultiTypeSelect
                  onChange={handleFieldChange('propertyTypes')}
                  value={propertyRequirementData?.property_type}
                />
              </FlexItem>
              <FlexItem flex={1}>
                <SaleTypeSelect
                  multiselect
                  handleChange={handleFieldChange('saleTypes')}
                  value={propertyRequirementData?.sale_types || undefined}
                />
              </FlexItem>
            </Flex>
          </FlexItem>

          <FlexItem xs={8}>
            <ViewsFromHomeSelect
              handleChange={handleFieldChange('views')}
              value={propertyRequirementData?.views || undefined}
            />
          </FlexItem>

          <FlexItem xs={16}>
            <Flex spacing={4}>
              <FlexItem flex={1}>
                <YearBuiltSelect
                  rangeSelect
                  handleChangeMin={handleFieldChange('year_built_min')}
                  handleChangeMax={handleFieldChange('year_built_max')}
                  minValue={propertyRequirementData?.year_built_min}
                  maxValue={propertyRequirementData?.year_built_max}
                />
              </FlexItem>

              <FlexItem flex={1}>
                <NumberOfStoriesSelect
                  rangeSelect
                  handleChangeMin={handleFieldChange('number_of_stories_min')}
                  handleChangeMax={handleFieldChange('number_of_stories_max')}
                  minValue={propertyRequirementData?.number_of_stories_min}
                  maxValue={propertyRequirementData?.number_of_stories_max}
                />
              </FlexItem>
            </Flex>
          </FlexItem>

        </Flex>
      </DetailsSection>

    </Flex>
  );
};

const {
  func,
  number,
  shape,
} = PropTypes;

PrimaryRequirements.propTypes = {
  propertyRequirementData: shape({
    address_id: number,
    expected_price: number,
    property_type: number,
    bedrooms: number,
    bathrooms: number,
    floor_area: number,
    plot_area: number,
    parking: number,
  }).isRequired,
  onChange: func.isRequired,
  onRelationOverwrite: func.isRequired,
  onRelationAdd: func.isRequired,
  onRelationRemove: func.isRequired,
};

export default withPropertyRequirementData(PrimaryRequirements);
