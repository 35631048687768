import React, {
  useState,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import Button from 'ui/Button';
import Menu from 'ui/Menu';
import MenuItem from 'ui/MenuItem';

import ExportAs from 'sections/Leads/modules/ExportAs';

const OptionsLeadsList = (props) => {
  const {
    selected,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const onButtonClick = useCallback((event) => {
    setAnchorEl(event.target);
    setMenuOpen(true);
  }, []);

  const closeMenu = useCallback(() => {
    setMenuOpen(false);
  }, []);

  return (
    <>
      <Button
        icon="menu"
        onClick={onButtonClick}
        variant="secondary"
      />
      <Menu
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={closeMenu}
      >
        <MenuItem>
          <ExportAs ids={null} />
        </MenuItem>
        {
          selected?.length > 0 && (
            <MenuItem>
              <ExportAs ids={selected} />
            </MenuItem>
          )
        }
      </Menu>
    </>
  );
};

OptionsLeadsList.defaultProps = {
  selected: [],
};

const {
  arrayOf,
  number,
} = PropTypes;

OptionsLeadsList.propTypes = {
  selected: arrayOf(number),
};

export default OptionsLeadsList;
