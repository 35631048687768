import React, {
  useState,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import Spin from 'ui/Spin';
import Typography from 'ui/Typography';
import ErrorBoundary from 'ui/ErrorBoundary';
import Table from 'ui/Table';

import ThreadItem from 'sections/Integrations/modules/ThreadItem';
import EmailViewer from 'sections/Integrations/modules/EmailViewer';

import useThreads from 'sections/Integrations/hooks/useThreads';

const pageSize = 5;

const columns = ({ tokenId, toggleViewer }) => [{
  // onCell: openRecord,
  width: 1,
  dataIndex: 'id',
  key: 'id',
  // sorter: true,
  render: (value, entry) => (
    <ThreadItem
      thread={entry}
      showTo={!tokenId}
      onItemClick={toggleViewer}
    />
  ),
}];

const EmailsTable = (props) => {
  const {
    tokenId,
    mailbox,
    search,
    status,
  } = props;

  const [pageNumber, setPageNumber] = useState(1);

  const [isViewerOpen, setViewerOpen] = useState(false);
  const [openThread, setOpenThread] = useState(null);

  const toggleViewer = useCallback((thread = null) => {
    setOpenThread(thread);
    setViewerOpen((prevValue) => !prevValue);
  }, []);

  const {
    threads,
    count: total,
    isLoading,
    error,
  } = useThreads({
    tokenId,
    limit: pageSize,
    offset: pageSize * (pageNumber - 1),
    mailbox,
    search,
    ...(status === 'unread' && { unread: 1 }),
    ...(status === 'read' && { unread: 0 }),
  });

  if (isLoading && !threads) {
    return (
      <Flex
        fullWidth
        justifyContent="center"
      >
        <Spin />
      </Flex>
    );
  }

  if (error) {
  return (
    <Flex
      fullWidth
      justifyContent="center"
    >
      <Typography color="error">
        Cannot load emails :(
      </Typography>
    </Flex>
    );
}

  if (!threads || threads?.length < 1) {
    return (
      <Flex
        fullWidth
        justifyContent="center"
      >
        <Typography color="placeholder">
          No emails
        </Typography>
      </Flex>
    );
  }

  return (
    <>
      <ErrorBoundary>
        <Table
          style={{ maxWidth: '100%' }}
          dataSource={threads}
          columns={columns({ tokenId, toggleViewer })}
          pagination={{
            total,
            onChange: setPageNumber,
            current: pageNumber,
            pageSize,
          }}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <EmailViewer
          isDrawerOpen={isViewerOpen}
          toggleDrawer={toggleViewer}
          thread={openThread}
        />
      </ErrorBoundary>
    </>
  );
};

EmailsTable.defaultProps = {
  tokenId: null,
  mailbox: 'inbox',
  search: undefined,
  status: undefined,
};

const {
  number,
  string,
} = PropTypes;

EmailsTable.propTypes = {
  tokenId: number,
  mailbox: string,
  search: string,
  status: string,
};

export default EmailsTable;
