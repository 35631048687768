import { connect } from 'react-redux';

import {
  openNewClientModal,
} from 'store/actions/newRealtorClientModal';

import {
  openNewProspectModal,
} from 'store/actions/newRealtorProspectModal';

import CreateProspect from './CreateProspect';

const mapStateToProps = (state) => {
  const {
    realtorLeads: {
      selectedProperty: {
        id,
        leadId,
        type,
      },
    },
  } = state;

  return {
    propertyId: id,
    leadId,
    type,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCreateClient: (propertyId, leadId) => {
    dispatch(openNewClientModal(propertyId, leadId));
  },
  onCreateProspect: (propertyRequirementId, leadId) => {
    dispatch(openNewProspectModal(propertyRequirementId, leadId));
  },
});

const mergeProps = (stateProps, dispatchProps) => ({
  type: stateProps.type,
  onClick: () => {
    if (stateProps.type === 'properties') {
      dispatchProps.onCreateClient(stateProps.propertyId, stateProps.leadId);
    } else {
      dispatchProps.onCreateProspect(stateProps.propertyId, stateProps.leadId);
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CreateProspect);
