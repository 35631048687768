import React, {
  useRef,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import Button from 'ui/Button';
import MenuItem from 'ui/MenuItem';
import Divider from 'ui/Divider';

import useMenu from 'ui/hooks/useMenu';

import isSectionUnderConstruction from 'shared/utils/isSectionUnderConstruction';

const OptionsV2 = (props) => {
  const {
    status,
    onStatusChange,
    onDelete,
    onConvert,
    onFollowUp,
    onCall,
  } = props;

  const anchorEl = useRef(null);

  const {
    openMenu,
    closeMenu,
    Menu,
  } = useMenu(anchorEl);

  const handleStatusChange = useCallback((newStatus) => () => {
    onStatusChange(newStatus);
    closeMenu();
  }, [
    onStatusChange,
    closeMenu,
  ]);

  const handleConvert = useCallback(() => {
    onConvert();
    closeMenu();
  }, [
    onConvert,
    closeMenu,
  ]);

  const handleFollowUp = useCallback(() => {
    onFollowUp();
    closeMenu();
  }, [
    onFollowUp,
    closeMenu,
  ]);

  const handleCall = useCallback(() => {
    onCall();
    closeMenu();
  }, [
    onCall,
    closeMenu,
  ]);

  return (
    <>
      <Button
        buttonRef={anchorEl}
        icon="menu"
        onClick={openMenu}
        variant="secondary"
      />
      <Menu>
        <MenuItem
          onClick={handleConvert}
          icon="lock"
        >
          Convert to Client
        </MenuItem>
        {
          !isSectionUnderConstruction('calls') && (
            <MenuItem
              onClick={handleCall}
              icon="phone"
            >
              Call
            </MenuItem>
          )
        }
        <MenuItem
          onClick={handleFollowUp}
          icon="calendar"
        >
          Set Follow Up
        </MenuItem>
        <Divider />
        {
          status === 2 && (
            <MenuItem
              onClick={handleStatusChange(1)}
              icon="activity"
            >
              Set to active
            </MenuItem>
          )
        }
        {
          status !== 2 && (
            <MenuItem
              onClick={handleStatusChange(2)}
              icon="archive"
            >
              Archive lead
            </MenuItem>
          )
        }
        <MenuItem
          onClick={onDelete}
          icon="x"
        >
          Delete lead
        </MenuItem>
      </Menu>
    </>
  );
};

OptionsV2.defaultProps = {
  status: null,
};

const {
  func,
  number,
} = PropTypes;

OptionsV2.propTypes = {
  onStatusChange: func.isRequired,
  onDelete: func.isRequired,
  status: number,
};

export default OptionsV2;
