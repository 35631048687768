import styled from 'styled-components';

import { NakedButton } from '@symplete/symplete-ui';

const buttonHoverColor = 'rgba(0, 0, 0, 0.03)';
const buttonPadding = '12px';

export const MainActionButton = styled(NakedButton)`
&& {
  
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: ${buttonPadding};

  flex: 3;
  &:hover {
    background-color: ${buttonHoverColor};
  }
}
`;

export const AddActionButton = styled(NakedButton)`
&& {
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  padding: ${buttonPadding};

  &:hover {
    background-color: ${buttonHoverColor};
  }
}
`;

export const Root = styled.div`
  display: flex;

  overflow: hidden;

  .information-text {
    margin-right: 8px;
    /* margin: auto 0 auto 0; */
  }

  .information-title-text {
    text-align: left;
    max-width: 65px;
  }
  
  /* padding: 15px; */
  border-radius: 10px;
  background-color: ${(props) => props.theme.palette['background-flat']};
`;

export default {
  Root,
  MainActionButton,
  AddActionButton,
};
