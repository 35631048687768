import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import { datetime } from '@symplete/symplete-utils';
import { last } from 'shared/utility';

import Drawer from 'ui/Drawer';
import Typography from 'ui/Typography';

import EmailItem from 'sections/Integrations/modules/EmailItemViewer';
import EmailComposer from 'sections/Integrations/modules/EmailComposer';

import FwdEmailDrawer from 'sections/Contacts/modules/EmailDrawer';

import useThread from 'sections/Integrations/hooks/useThread';

import {
  ComposerWrapper,
  Divider,
} from './styledItems';

const getForwardedEmailProps = (sourceEmail = {}) => {
  const emailFrom = sourceEmail.from?.[0]?.email || '';
  const emailTo = sourceEmail.to?.[0]?.email || '';

  const emailDate = datetime.unix(sourceEmail.date);
  const emailDateFormatted = emailDate ? emailDate.toString() : '';

  const subject = `Fwd: ${sourceEmail.subject || ''}`;

  const forwardedMessage = `
    <p></p><br>
    ----------Forwarded message----------<br>
    <b>From:</b> ${emailFrom}<br>
    <b>Date:</b> ${emailDateFormatted}<br>
    <b>To:</b> ${emailTo}<br>
    <b>Subject:</b> ${sourceEmail.subject || ''}<br>
    ${sourceEmail.body || ''}
  `;

  return {
    message: forwardedMessage,
    subject,
  };
};

const EmailViewer = (props) => {
  const {
    isDrawerOpen,
    toggleDrawer,
    thread: threadProps,
  } = props;

  const [thread, setThread] = useState(null);
  const [openMessage, setOpenMessage] = useState(null);
  const [inReplyMode, setInReplyMode] = useState(false);
  const [replyToMessageId, setReplyToMessageId] = useState(null);
  const [replyToEmails, setReplyToEmails] = useState(null);

  const [fwdDrawerOpen, setFwdDrawerOpen] = useState(false);
  const [fwdEmail, setFwdEmail] = useState({});

  const tokenId = thread?.sympleteTokenId;

  useEffect(() => {
    setThread(threadProps);
  }, [
    JSON.stringify(threadProps),
  ]);

  useEffect(() => {
    const latestMessageId = last(thread?.message_ids);
    if (latestMessageId) {
      setOpenMessage(latestMessageId);
    }
  }, [
    JSON.stringify(thread?.message_ids),
  ]);

  useEffect(() => {
    if (!isDrawerOpen) {
      setThread(null);
      setReplyToMessageId(null);
      setInReplyMode(false);
      setOpenMessage(null);
    }
  }, [
    isDrawerOpen,
  ]);

  const {
    getThread,
  } = useThread({});

  const fetchThread = async () => {
    const result = await getThread({ tokenId, threadId: thread?.id });
    if (result) {
      setThread(result);
    }
  };

  const onItemClick = useCallback((messageId) => {
    setOpenMessage(messageId);
  }, []);

  const onReply = useCallback((messageID, emails) => {
    setReplyToMessageId(messageID);
    setReplyToEmails(emails);
    setInReplyMode(true);
    setOpenMessage(null);
  }, []);

  const onSendReply = useCallback(() => {
    fetchThread();
    setReplyToMessageId(null);
    setInReplyMode(false);
  }, [
    tokenId,
    thread?.id,
  ]);

  const closeFwdDrawer = useCallback(() => {
    setFwdDrawerOpen(false);
    setFwdEmail({});
  }, []);

  const onForward = useCallback((email) => (event) => {
    event.stopPropagation();
    const forwardedEmail = getForwardedEmailProps(email);
    setFwdEmail(forwardedEmail);
    setFwdDrawerOpen(true);
  }, []);

  const threadMessageIds = thread?.message_ids || [];

  return (
    <Drawer
      visible={isDrawerOpen}
      onClose={toggleDrawer}
      width={1200}
      bodyStyle={{ height: '100%' }}
      autoFocus
    >
      {
        isDrawerOpen
          && (
            <div>
              <Typography
                variant="title1"
              >
                {thread?.subject || ''}
              </Typography>
              {
                inReplyMode && (
                  <>
                    <ComposerWrapper style={{ height: 400 }}>
                      <EmailComposer
                        emailTo={Array.isArray(replyToEmails) ? replyToEmails : [replyToEmails]}
                        subject={thread?.subject || ''}
                        replyToMessageId={replyToMessageId}
                        onSend={onSendReply}
                        emailFromTokenId={tokenId}
                        elementsToShow={['recipient', 'attachments upload']}
                      />
                    </ComposerWrapper>
                    <Divider />
                  </>
                )
              }
              {
                [...threadMessageIds].reverse().map((id) => (
                  <EmailItem
                    emailId={id}
                    tokenId={tokenId}
                    isBodyVisible={id === openMessage}
                    onItemClick={onItemClick}
                    onReply={onReply}
                    onForward={onForward}
                  />
                ))
              }

              <FwdEmailDrawer
                isDrawerOpen={fwdDrawerOpen}
                toggleDrawer={closeFwdDrawer}
                message={fwdEmail?.message}
                subject={fwdEmail?.subject}
                onSend={closeFwdDrawer}
              />
            </div>
          )
      }
    </Drawer>
  );
};

EmailViewer.defaultProps = {
};

const {
  bool,
  func,
  shape,
} = PropTypes;

EmailViewer.propTypes = {
  isDrawerOpen: bool.isRequired,
  toggleDrawer: func.isRequired,
  thread: shape({}).isRequired,
};

export default EmailViewer;
