import styled from 'styled-components';

import Input from 'shared/inputs/FormTextfield';

export const AttachmentTile = styled.div`
  height: 85px;
  width: 135px;
  min-width: 135px;
  margin-right: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(${(props) => props.thumbUrl});
  background-color: rgba(61, 91, 144, 0.3);
  border: 1px solid ${(props) => props?.theme?.palette?.['input-border']};
  cursor: pointer;
  position: relative;
`;

export const AttachmentTileTitleWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 8px;
  border-top: solid 1px ${(props) => props?.theme?.palette?.['input-border']};
  background-color: #fff;
`;

export const PreviewToolbar = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const MediaWrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: ${(props) => props?.theme?.palette?.['background-flat']};
  padding: 5px 8px 8px;
`;

export const AttachmentContainer = styled.div`
  height: 290px;
  width: 100%;
  margin-bottom: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  background-image: url(${(props) => props.previewUrl});
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  .pg-viewer-wrapper {
    width: 100%;

    .document-container {
      width: 100%;
    }

    canvas {
      width: 100%;
    }
  }
`;

export const DetailsWrapper = styled.div`
  min-height: 30px;
  margin-bottom: 15px;
`;

export const TitleInput = styled(Input)`
  font-size: ${(props) => props?.theme?.fontSize?.title2 || '18px'};
`;

export const LinkInputContainer = styled.div`
  min-width: 320px;
  text-align: center;
  margin-bottom: 20px;
`;

export const ExternalLinksContainer = styled.div`
  margin-top: 15px;
  overflow-x: hidden;
`;

export const ExternalLink = styled.a`
  display: block;
  margin-bottom: 5px;
`;
