import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import FormControl from '@material-ui/core/FormControl';

import useStyles from './useStyles';

const LeadTypeSelect = (props) => {
  const {
    value,
    onChange,
  } = props;

  const classes = useStyles();

  const handleChange = useCallback((event) => {
    const valueList = event.target.value;

    onChange(valueList);
  });

  return (
    <FormControl
      variant="outlined"
      fullWidth
    >
      <InputLabel
        margin="dense"
        id="select-lead-type-label"
      >
        Type
      </InputLabel>
      <Select
        multiple
        value={value}
        onChange={handleChange}
        variant="outlined"
        margin="dense"
        labelId="select-lead-type-label"
        label="Type"
        classes={{
          selectMenu: classes.selectMenu,
        }}
      >
        <MenuItem value={1}>
          Buyer
        </MenuItem>
        <MenuItem value={2}>
          Seller
        </MenuItem>
      </Select>
    </FormControl>
  );
};

const {
  arrayOf,
  func,
  number,
} = PropTypes;

LeadTypeSelect.propTypes = {
  value: arrayOf(number).isRequired,
  onChange: func.isRequired,
};

export default LeadTypeSelect;
