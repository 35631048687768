import React from 'react';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Icon from 'ui/Icon';

import PropertyMainImage from 'sections/Properties/modules/PropertyMainImage';

const PROPERTY_RELATED_CAMPAIGN_TYPES = new Set([1, 2]);

const Type = (value, record) => {
  const image = PROPERTY_RELATED_CAMPAIGN_TYPES.has(value)
    ? record.realtorPropertyId
      ? (
        <PropertyMainImage
          height="56px"
          propertyId={record.realtorPropertyId}
        />
        )
      : (
        <Icon
          color="focus"
          name="home"
          size="m"
        />
        )
    : (
      <Icon
        color="focus"
        name="mail"
        size="m"
      />
      );

  return (
    <Flex
      spacing={1}
    >
      <FlexItem
        xs={8}
      >
        {image}
      </FlexItem>
      <FlexItem
        xs={14}
      >
        <Typography
          align="left"
          noMargin
          variant="title2"
          tag="p"
        >
          {record.name || '—'}
        </Typography>
        <Typography
          align="left"
          noMargin
          variant="title3"
          tag="p"
        >
          {record?.templateName}
        </Typography>
      </FlexItem>
    </Flex>
  );
};

export default Type;
