import { connect } from 'react-redux';

import selectors from 'store/selectors';

import BuyerNeedsView from './BuyerNeedsView';

const mapStateToProps = (state) => {
  const prospect = selectors.realtorProspects.selected(state);

  return {
    propetyRequirementId: prospect.realtor_property_requirement_id,
  };
};

export default connect(mapStateToProps)(BuyerNeedsView);
