import React from 'react';
import PropTypes from 'prop-types';

import datetime from '@symplete/symplete-utils/datetime';

import Flex from 'ui/Flex';

import Typography from 'ui/Typography';
import DateInput from 'shared/inputs/DateInput';

const DateInfoItem = (props) => {
  const {
    label,
    value,
    onChange,
    disableFuture,
  } = props;

  const placeholder = value ? null : 'None yet';
  const date = value ? datetime(value) : null;

  return (
    <Flex
      spacing={1}
      alignItems="baseline"
    >
      <DateInput
        variant="inline"
        view="date"
        handleChange={onChange}
        label={label}
        placeholder={placeholder}
        value={date}
        disableFuture={disableFuture}
        autoOk
        customFormat="ddd, DD MMM YYYY"
      />
      <Typography tag="span" noMargin variant="label">
        {date && `(${datetime.duration(date.diff()).humanize(true)})`}
      </Typography>
    </Flex>
  );
};

DateInfoItem.defaultProps = {
  value: null,
  label: null,
  disableFuture: false,
};

const {
  string,
  func,
  bool,
} = PropTypes;

DateInfoItem.propTypes = {
  value: string,
  label: string,
  onChange: func.isRequired,
  disableFuture: bool,
};

export default DateInfoItem;
