import styled from 'styled-components';
import Flex from 'ui/Flex';

export const Wrapper = styled(Flex)`
  padding: 7px 10px;
  border-radius: 10px;
  background-color: ${(props) => props.theme?.palette?.backgroundFlat || 'grey'};
`;

export default {
  Wrapper,
};
