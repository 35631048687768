import { connect } from 'react-redux';
import stableStringify from 'json-stable-stringify';

import selectors from 'store/selectors';

import {
  openFollowUpReminderModal,
} from 'store/actions/followUpReminderModal';

import {
  optimizedFollowUpRemindersActions,
} from 'store/actions/followUpReminders';

import FollowUp from 'shared/components/FollowUp';

const mapStateToProps = (state, ownProps) => {
  const {
    transactionId,
  } = ownProps;

  const transaction = selectors.realtorTransactions.byId(state, transactionId);

  const { address_id } = selectors.properties.byId(state, transaction?.realtor_property_id);

  const address = selectors.addresses.byId(state, address_id);

  return {
    objectTitle: address?.address,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  openLeadRemindersModal: (objectTitle) => dispatch(openFollowUpReminderModal({
    objectTitle,
    title: null,
    comment: null,
    timeIntervalValue: 0,
    timeIntervalType: 2,
    scheduledDate: null,
    isRepeat: false,
  })),

  getLeadReminders: () => {
    const realtorTransactionObjectTypeId = 41;
    const filter = {
      objectTypeId: realtorTransactionObjectTypeId,
      objectId: ownProps.transactionId,
    };

    optimizedFollowUpRemindersActions.getFollowUpRemindersForFilter(stableStringify(filter), filter);
  },
});

const mergeProps = (stateProps, dispatchProps) => ({
  getLeadReminders: () => dispatchProps.getLeadReminders(),
  openLeadRemindersModal: () => dispatchProps.openLeadRemindersModal(stateProps.objectTitle),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(FollowUp);
