import { connect } from 'react-redux';

import { debounce } from 'shared/utility';

import selectors from 'store/selectors';

import { updatePropertyRequirement } from 'store/actions/propertyRequirements';

import getPriceRangeDisplayValue from 'sections/Leads/utils/getPriceRangeDisplayValue';

import PropertyRequirementTab from './PropertyRequirementTab';

const mapStateToProps = (state, ownProps) => {
  const {
    itemId,
  } = ownProps;

  const propertyRequirement = selectors.propertyRequirements.byId(state, itemId);

  const address = selectors.addresses.byId(state, propertyRequirement?.addresses?.[0]);

  const addressPostfix = propertyRequirement?.addresses?.length > 1
    ? ` + ${propertyRequirement?.addresses?.length - 1} more`
    : '';

  return {
    price: propertyRequirement
      ? getPriceRangeDisplayValue(propertyRequirement.budget_min, propertyRequirement.budget_max)
      : 'N/A',
    address: address?.address + addressPostfix,
    opportunityName: propertyRequirement?.opportunity_name || '—',
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChangeOpportunityName: debounce((value) => {
    dispatch(updatePropertyRequirement(ownProps.itemId)({ opportunity_name: value }));
  }, 1000),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyRequirementTab);
