import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { NakedButton } from '@symplete/symplete-ui';

import Grid from 'ui/Grid';
import Typography from 'ui/Typography';

import Offer from 'sections/Opportunities/modules/Offer';

const OffersView = (props) => {
  const {
    onLogOfferClick,
    offers,
    objectType,
    label,
  } = props;

  return (
    <Grid container spacing={4}>

      <Grid item xs={12}>
        <NakedButton onClick={onLogOfferClick}>
          <Typography
            variant="button"
            color="link"
            weight={600}
          >
            {label}
          </Typography>
        </NakedButton>
      </Grid>

      {
        offers.map((offerId, index) => (
          <Fragment key={offerId}>
            <Grid item xs={12}>
              <Offer
                header={null}
                offerId={offerId}
                ordinalNumber={index + 1}
                isClient={objectType === 'client'}
                isProspect={objectType === 'prospect'}
              />
            </Grid>
          </Fragment>
        ))
      }
    </Grid>
  );
};

OffersView.defaultProps = {
  offers: [],
};

const {
  arrayOf,
  func,
  oneOf,
  shape,
  string,
} = PropTypes;

OffersView.propTypes = {
  onLogOfferClick: func.isRequired,
  offers: arrayOf(shape()),
  objectType: oneOf(['client', 'prospect']).isRequired,
  label: string.isRequired,
};

export default OffersView;
