/* eslint-disable camelcase */

import React from 'react';
import { bool, func, shape } from 'prop-types';

import { useForm } from 'react-hook-form';

import Flex from 'ui/Flex';
import Modal from 'ui/Modal';
import Button from 'ui/Button';

import onSubmit from './submitForm';
import ContactType from './ContactType';
import UserDetails from './UserDetails';
import PropertyDetails from './PropertyDetails';

const ContactForm = ({ isVisible, onClose, propertyData }) => {
  const {
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      propertyId: propertyData.id,

      firstName: null,
      lastName: null,
      phone: null,
      email: null,

      type: 'buy',

      neighborhoods: null,
      beds: null,
      baths: null,
      size: null,

      propertyAddress: null,
      comments: null,
    },
  });

  return (
    <Modal
      width={720}
      visible={isVisible}
      onCancel={onClose}
      destroyOnClose
      title="Contact"
      footer={[
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>,
        <Button variant="primary" onClick={handleSubmit(onSubmit)}>
          Submit
        </Button>,
      ]}
    >
      <Flex
        fullWidth
        flexWrap="wrap"
        spacing={1}
      >
        <Flex
          fullWidth
          flexDirection="column"
          fullHeight
          alignItems="stretch"
          spacing={3}
        >
          <Flex fullWidth>
            <Flex
              flex={8}
              flexDirection="column"
              spacing={2}
            >
              <UserDetails control={control} />

              <Flex
                fullWidth
                flexDirection="column"
              >
                <ContactType control={control} />
              </Flex>

              <PropertyDetails control={control} />
            </Flex>
          </Flex>
        </Flex>

      </Flex>
    </Modal>
  );
};

ContactForm.propTypes = {
  isVisible: bool.isRequired,
  onClose: func.isRequired,
  propertyData: shape({}).isRequired,
};

export default ContactForm;
