/* eslint-disable camelcase */

import React from 'react';
import { bool, func, shape } from 'prop-types';

import { useForm } from 'react-hook-form';

import Flex from 'ui/Flex';
import Modal from 'ui/Modal';
import Button from 'ui/Button';

import SubTitle from 'sections/Offers/forms/NewOfferForm/SubTitle';

import onSubmit from './submitForm';
import Financing from './Financing';
import RequestorType from './RequestorType';
import UserDetails from './UserDetails';
import BuyerDetails from './BuyerDetails';
import RequestDateTime from './RequestDateTime';

const RequestShowing = ({ isVisible, onClose, propertyData }) => {
  const propertyState = propertyData?.propertyState ?? 'residential';
  const transferState = propertyData?.transferState ?? 'sale';

  const { handleSubmit, control } = useForm({
    defaultValues: {
      propertyId: propertyData.id,

      firstName: null,
      lastName: null,
      phone: null,
      email: null,

      companyName: null,
      buyerFirstName: null,
      buyerLastName: null,

      type: 'buyer',
      financing: null,

      requestDate: null,
      requestTime: null,
    },
  });

  return (
    <Modal
      width={720}
      visible={isVisible}
      onCancel={onClose}
      destroyOnClose
      title={
        propertyState === 'residential'
        && transferState === 'sale'
          ? 'Request Showing'
          : 'Request Tour'
      }
      footer={[
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>,
        <Button variant="primary" onClick={handleSubmit(onSubmit)}>
          Send Request
        </Button>,
      ]}
    >
      <Flex fullWidth flexWrap="wrap" spacing={1}>
        <Flex
          fullWidth
          flexDirection="column"
          fullHeight
          alignItems="stretch"
          spacing={3}
        >
          <Flex fullWidth>
            <Flex flex={8} flexDirection="column" spacing={2}>
              <UserDetails control={control} />

              <Flex fullWidth flexDirection="column">
                <RequestorType control={control} propertyData={propertyData} />
              </Flex>

              <BuyerDetails control={control} propertyData={propertyData} />
            </Flex>
          </Flex>

          <Flex fullWidth flexDirection="column">
            <SubTitle>Financing</SubTitle>
            <Financing control={control} />
          </Flex>

          <Flex fullWidth>
            <Flex fullWidth flexDirection="column">
              <SubTitle>Preferred showing date & time</SubTitle>
              <RequestDateTime control={control} />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
};

RequestShowing.propTypes = {
  isVisible: bool.isRequired,
  onClose: func.isRequired,
  propertyData: shape({}).isRequired,
};

export default RequestShowing;
