import React, {
  useEffect,
  useCallback,
  useState,
} from 'react';

import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

import Table from 'ui/Table';

import CustomRow from './CustomRow';
import columns from './columns';

import './PropertiesTable.scss';

const sortFieldDict = {
  address: 'address',
  status: 'status',
  onMarket: 'on_market_since',
  expectedPrice: 'expected_price',
  propertyDetails: 'floor_area',
  listingAgent: 'listingAgent',
  viewing: 'nearest_viewing',
};

const sortDirectionDict = {
  ascend: 'asc',
  descend: 'desc',
};

const showTotal = (total, range) => `${range[0]}-${range[1]} of ${total} properties`;

const PropertiesTable = (props) => {
  const {
    propertiesList,
    getProperties,
    setFilterItem,
    itemsCount,
    pageNumber,
    pageSize,
    sortBy,
    sortDirection,
    onRowSelect,
    firstSelfListingPropertyKey,
    firstNotSelfListingPropertyKey,
  } = props;

  const history = useHistory();

  useEffect(() => {
    getProperties(pageNumber, pageSize);

    return () => { // this is the same as componentWillUnmount
      onRowSelect([]);
    };
  }, []);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    onChange: useCallback((selectedKeys, selectedRows) => {
      onRowSelect(selectedRows.map((item) => item.id));
      setSelectedRowKeys(selectedKeys);
    }, []),

    selectedRowKeys,
  };

  const onChange = useCallback((pagination, filter, sorter) => {
    const hasPageChaged = pagination && (pagination.current !== pageNumber || pagination.pageSize !== pageSize); // eslint-disable-line max-len
    const hasSortedChanged = sorter.field && (sortBy !== sortFieldDict[sorter.field] || sortDirection !== sortDirectionDict[sorter.order] || 'asc'); // eslint-disable-line max-len

    const shouldGetNewSortedItems = !hasPageChaged && hasSortedChanged;

    if (shouldGetNewSortedItems) {
      setFilterItem('sortBy')(sortFieldDict[sorter.columnKey]);
      setFilterItem('sortDirection')(sortDirectionDict[sorter.order] || 'asc');
      getProperties(pageNumber, pageSize);
      onRowSelect([]);
      setSelectedRowKeys([]);
    }
  }, [
    onRowSelect,
    setSelectedRowKeys,
  ]);

  const onSort = useCallback((key) => (order) => () => {
    setFilterItem('sortBy')(sortFieldDict[key]);
    setFilterItem('sortDirection')(sortDirectionDict[order] || 'asc');
    getProperties(pageNumber, pageSize);
    onRowSelect([]);
    setSelectedRowKeys([]);
  }, [
    onRowSelect,
    setSelectedRowKeys,
    setFilterItem,
    getProperties,
    pageNumber,
    pageSize,
  ]);

  const onPaginationChange = useCallback((newPageNumber, newPageSize) => {
    setFilterItem('pageNumber')(newPageNumber);
    setFilterItem('pageSize')(newPageSize);
    getProperties(newPageNumber, newPageSize);
    onRowSelect([]);
    setSelectedRowKeys([]);
  }, [
    setFilterItem,
    getProperties,
    onRowSelect,
    setSelectedRowKeys,
  ]);

  const [hoveredRecord, setHoveredRecord] = useState(null);

  const onRow = useCallback((record, rowIndex) => ({
    onClick: (event) => {
      history.push(`/mls/${record.id}`);
    },
    onMouseEnter: (event) => {
      setHoveredRecord(record.key);
    },
    onMouseLeave: (event) => {
      setHoveredRecord(null);
    },
  }), [
    history,
    setHoveredRecord,
  ]);

  const shouldShowDividers = sortBy === 'myListings';

  return (
    <Table
      key="properties-table"
      className={`realtor-properties-table ${shouldShowDividers && 'with-divider-rows'}`}
      dataSource={propertiesList}
      columns={columns({ hoveredRecord, onSort })}
      // rowSelection={{ ...rowSelection }}
      onChange={onChange}
      pagination={{
        total: itemsCount,
        showTotal,
        onChange: onPaginationChange,
        current: pageNumber,
        pageSize,
      }}
      components={{
        body: {
          row: (val) => CustomRow({
            ...val,
            myListingsFirstRowKey: firstSelfListingPropertyKey,
            featuredListingsFirstRowKey: firstNotSelfListingPropertyKey,
            shouldShowDividers,
          }),
        },
      }}
      onRow={onRow}
      tableLayout="fixed"
      size="middle"
    />
  );
};

PropertiesTable.defaultProps = {
  propertiesList: [],
  itemsCount: 0,
  pageNumber: 1,
  pageSize: 10,
  sortBy: 'myListings',
  sortDirection: 'desc',
  firstSelfListingPropertyKey: undefined,
  firstNotSelfListingPropertyKey: undefined,
};

const {
  arrayOf,
  func,
  number,
  oneOf,
  shape,
  string,
} = PropTypes;

PropertiesTable.propTypes = {
  propertiesList: arrayOf(shape({
    key: string,
    // leadId: number,
    // connectedItemId: number,
    // status: string,
    // sourceId: number,
    // personId: number,
    // type: number,
    // expectedSalePrice: number,
    // expectedPurchasePrice: arrayOf(number),
    // propertyType: arrayOf(string),
    // location: number,
    // createdAt: string,
    // children: arrayOf(shape({
    //   key: string,
    //   leadId: number,
    //   connectedItemId: number,
    //   type: number,
    //   expectedSalePrice: number,
    //   expectedPurchasePrice: arrayOf(number),
    //   propertyType: arrayOf(string),
    //   location: number,
    //   createdAt: string,
    // })),
  })),
  getProperties: func.isRequired,
  setFilterItem: func.isRequired,
  itemsCount: number,
  pageNumber: number,
  pageSize: number,
  sortBy: string,
  sortDirection: oneOf(['asc', 'desc']),
  onRowSelect: func.isRequired,
  firstSelfListingPropertyKey: number,
  firstNotSelfListingPropertyKey: number,
};

export default PropertiesTable;
