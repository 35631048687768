import React from 'react';
import PropTypes from 'prop-types';

import { currency } from '@symplete/symplete-utils/numbers';

import { Tooltip } from 'antd';

import Typography from 'ui/Typography';

import {
  Root,
  Image,
} from './styledItems';

const PropertyCell = (props) => {
  const {
    address,
    price,
    image,
  } = props;

  return (
    <Root>
      <Image
        shape="square"
        icon="picture"
        src={image?.url}
      />

      <div>
        <Tooltip title={address}>
          <Typography
            ellipsis
            style={{
              maxWidth: 200,
              marginBottom: 7,
            }}
            variant="cell"
            weight={500}
          >
            {address}
          </Typography>
        </Tooltip>

        <Typography variant="cell">
          {currency.full(price)}
        </Typography>
      </div>
    </Root>
  );
};

PropertyCell.defaultProps = {
  image: null,
};

const {
  shape,
  string,
  number,
} = PropTypes;

PropertyCell.propTypes = {
  image: shape({
    id: string,
    url: string,
  }),
  address: string.isRequired,
  price: number.isRequired,
};

export default PropertyCell;
