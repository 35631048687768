import styled from 'styled-components';

import Flex from 'ui/Flex';

const getHeight = (props) => props.height || '600px';

export const BoardWrapper = styled.div`
  height: ${getHeight};
  flex: 1;
  overflow-x: scroll;

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 0;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 3px;
  }
`;

export const Lane = styled(Flex)`
  position: relative;

  padding-left: 16px;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    content: "";
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  }
`;

export const LaneContent = styled.div`
  width: 100%;
  flex: 1 1 auto;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 5px 5px 5px;

  &::-webkit-scrollbar-track {
    background-color: rgba(128, 128, 128, 0.1);
  }
  &::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(128, 128, 128, 0.2);
    border-radius: 3px;
  }
`;

export const CardWrapper = styled.div`
  margin: 10px 0;
  background-color: white;
  cursor: move !important; /* fallback if grab cursor is unsupported */
`;

export default {
  BoardWrapper,
  CardWrapper,
};
