import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'antd';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';

import ContactAvatar from 'shared/modules/RealtorContactAvatar';

import useContactName from 'shared/hooks/contacts/useContactName';

const Contact = (props) => {
  const {
    contactId,
    email,
  } = props;

  const contactName = useContactName(contactId);

  return (
    <Flex
      spacing={2}
      alignItems="center"
    >
      <ContactAvatar
        contactId={contactId}
        size="sm"
      />
      <Flex spacing={1} flexDirection="column">
        {
          contactName ? (
            <Tooltip title={contactName}>
              <Typography
                ellipsis
                weight="bold"
                variant="cellLarge"
                noWrap
              >
                {contactName}
              </Typography>
            </Tooltip>
          ) : null
        }
        <Tooltip title={email}>
          <Typography
            ellipsis
            variant="cell"
            color="disabled"
            noWrap
          >
            {email}
          </Typography>
        </Tooltip>
      </Flex>
    </Flex>
  );
};

const {
  number,
  string,
} = PropTypes;

Contact.propTypes = {
  contactId: number.isRequired,
  email: string.isRequired,
};

export default Contact;
