import React, {
  useRef,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import Tabs from 'ui/Tabs';
import Tab from 'ui/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabPanel from '@material-ui/lab/TabPanel';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Elevation from 'ui/Elevation';
import Button from 'ui/Button';

import DetailsSection from 'sections/Leads/components/DetailsSection';

import PropertyNotablePointsAndDisclosures from 'sections/MLS/modules/PropertyNotablePointsAndDisclosures';
import ItemKPIBar from 'sections/MLS/modules/ItemKPIBar';
import ListingRemarks from 'sections/MLS/modules/ListingRemarks';
import Sidebar from 'sections/MLS/modules/Sidebar';

import ListingFocus from 'sections/MLS/components/ListingFocus';

import AssignMatchedPartyModal from 'sections/MLS/modals/AssignMatchedPartyModal';
import AssignInterestedPartyModal from 'sections/MLS/modals/AssignInterestedPartyModal';
import TabLabel from 'shared/components/UI/TabLabel';

import AdditionalAmenities from 'shared/modules/AdditionalAmenities';
import PropertyDetails from 'sections/Properties/modules/PropertyDetails';
import PropertyDetailsFullExpandable from 'sections/Properties/modules/PropertyDetailsFullExpandable';

import {
  Root,
} from './styledItems';

const PropertyItem = (props) => {
  const {
    selectedProperty,
  } = props;

  const componentToPrintRef = useRef();

  const [showFillProperty, setFillPropertyVisibility] = useState(false);

  const changePropertyVisibility = useCallback((event) => {
    event.preventDefault();
    setFillPropertyVisibility(!showFillProperty);
  }, [showFillProperty]);

  const [tab, setTab] = useState('propertyDetails');
  const handleTabChange = useCallback((event, value) => {
    setTab(value);
  }, []);

  return (
    <>
      <Root ref={componentToPrintRef}>
        <Flex spacing={2} flexWrap="wrap">
          <FlexItem fullWidth>
            <ItemKPIBar propertyId={selectedProperty} />
          </FlexItem>
          <FlexItem fullWidth>
            <Elevation
              rounded="s"
              padding="s"
            >
              <Sidebar propertyId={selectedProperty} />
            </Elevation>
          </FlexItem>

          <Elevation
            rounded="m"
            padding="0 0 14px 0"
            hideOverflow
            style={{
              width: '-webkit-fill-available',
            }}
          >
            <TabContext value={tab}>
              <Tabs
                variant="secondary"
                value={tab}
                onChange={handleTabChange}
              >
                <Tab
                  variant="secondary"
                  label={(
                    <TabLabel
                      isSelected={tab === 'propertyDetails'}
                      variant="title2"
                      highlightSelected={false}
                    >
                      Property Details
                    </TabLabel>
                  )}
                  value="propertyDetails"
                />
                <Tab
                  variant="secondary"
                  label={(
                    <TabLabel
                      isSelected={tab === 'listingFocus'}
                      variant="title2"
                      highlightSelected={false}
                    >
                      Listing Focus
                    </TabLabel>
                  )}
                  value="listingFocus"
                />
              </Tabs>

              <TabPanel
                value="propertyDetails"
                index={0}
              >
                <Flex spacing={4} flexWrap="wrap">
                  <DetailsSection
                    icon="check-circle"
                    title="Property Details"
                  >
                    <PropertyDetails
                      propertyId={selectedProperty}
                    />
                  </DetailsSection>
                  <DetailsSection
                    icon="key"
                    title="Property Features"
                  >
                    <AdditionalAmenities
                      itemId={selectedProperty}
                      itemType="properties"
                    />
                    <PropertyNotablePointsAndDisclosures
                      propertyId={selectedProperty}
                    />
                  </DetailsSection>

                  <PropertyDetailsFullExpandable
                    propertyId={selectedProperty}
                  />

                  <DetailsSection
                    icon="paperclip"
                    title="Listing Remarks"
                  >
                    <ListingRemarks
                      propertyId={selectedProperty}
                    />
                  </DetailsSection>
                </Flex>
              </TabPanel>
              <TabPanel
                value="listingFocus"
                index={1}
              >
                <ListingFocus />
              </TabPanel>
            </TabContext>
          </Elevation>

        </Flex>
      </Root>

      <AssignMatchedPartyModal />
      <AssignInterestedPartyModal />
    </>
  );
};

PropertyItem.defaultProps = {
  selectedProperty: null,
};

const {
  number,
} = PropTypes;

PropertyItem.propTypes = {
  selectedProperty: number,
};

export default PropertyItem;
