import React from 'react';

import PropTypes from 'prop-types';

import { Tooltip } from 'antd';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';

import ContactAvatar from 'shared/modules/RealtorContactAvatar';

import useContactName from 'shared/hooks/contacts/useContactName';

import AreaHighlight from 'ui/AreaHighlight/AreaHighlight';
import ContactLabels from './ContactLabels';

const ContactCell = (props) => {
  const {
    contactId,
  } = props;

  const contactName = useContactName(contactId);

  if (!contactName) return '—';

  return (
    <Flex
      spacing={2}
      alignItems="center"
    >
      <ContactAvatar
        contactId={contactId}
        size="sm"
      />
      <Flex spacing={1} flexDirection="column">
        <Tooltip title={contactName}>
          <Typography
            ellipsis
            variant="cellLarge"
            noWrap
          >
            {contactName}
          </Typography>
        </Tooltip>
        <Flex spacing={1}>
          {
            contactId === 0 // checking if user contact
            ? (
              <AreaHighlight variant="info">
                <Typography variant="cell2" color="info-text">My Listing</Typography>
              </AreaHighlight>
              )
            : (
              <ContactLabels contactId={contactId} />
              )
          }
        </Flex>
      </Flex>
    </Flex>
  );
};

const {
  number,
} = PropTypes;

ContactCell.propTypes = {
  contactId: number.isRequired,
};

export default ContactCell;
