import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import Button from 'ui/Button';

import useModal from 'ui/hooks/useModal';

import NewPropertyModal from 'sections/Properties/modals/NewPropertyModal';

const CreateProperty = (props) => {
  const {
    onSuccess,
    variant,
  } = props;

  const {
    isModalVisible,
    openModal,
    closeModal,
  } = useModal();

  const handleOpen = useCallback((event) => {
    if (event instanceof Event) { event.preventDefault(); }
    openModal();
  });

  const handleClose = useCallback((event) => {
    if (event instanceof Event) { event.preventDefault(); }
    closeModal();
  });

  return (
    <>
      <Button
        variant={variant || 'secondary'}
        noWrap
        onClick={handleOpen}
        icon="plus"
      >
        add new
      </Button>
      <NewPropertyModal
        closeModal={handleClose}
        onlySave
        onCreateSuccess={onSuccess}
        isVisible={isModalVisible}
      />
    </>
  );
};

const {
  func,
  string,
} = PropTypes;

CreateProperty.propTypes = {
  onSuccess: func.isRequired,
  variant: string,
};

export default CreateProperty;
