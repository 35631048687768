import { connect } from 'react-redux';

import selectors from 'store/selectors';

import actions from 'sections/Properties/actions';

import NewOfferModal from 'sections/Offers/modals/NewOfferModal';

const mapStateToProps = (state, ownProps) => {
  const {
    realtor_contact_id: contactId,
  } = selectors.realtorMatchingProperties.byId(ownProps.matchId)(state);

  const property = selectors.properties.byId(state, ownProps.propertyId);

  return {
    buyer: contactId,
    buyerAgent: property?.listing_agent === 0 ? null : 0,
    seller: property?.seller,
    sellerAgent: property?.listing_agent,

    representing: property?.listing_agent === 0
      ? 'seller'
      : 'buyer',
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSuccess: () => {
    actions.kanban.updateMatch(ownProps.matchId)({ status: 3 });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewOfferModal);
