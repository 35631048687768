import React from 'react';

import CurrencyInput from 'ui/CurrencyInput';
import Flex from 'ui/Flex';

const LeasePriceInput = (props) => (
  <Flex style={{ alignItems: 'center' }}>
    <CurrencyInput {...props} />
    <span style={{ color: '#141736', paddingLeft: '5px', fontSize: '15px' }}> /month</span>
  </Flex>
);

export default LeasePriceInput;
