/* DEPRECATED */

/* eslint-disable camelcase */
import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  closeAssignInterestedPartyModal,
} from 'store/actions/realtorAssignInterestedPartyModal';

import {
  createMatchingProperty,
  removeMatchingProperty,
  resetMatchingPropertyFilters,
} from 'store/actions/realtorMatchingProperties';

import {
  getClientById,
} from 'store/actions/realtorClients';

import AssignInterestedPartyModal from 'shared/modals/AssignInterestedPartyModal';

const mapStateToProps = (state) => {
  const {
    assignInterestedPartiesModal: {
      isVisible,
    },
    realtorClients: {
      selectedClient,
    },
  } = state;

  const client = selectors.realtorClients.byId(state, selectedClient);

  const matches = selectors.realtorMatchingProperties.propertyMatches(client.realtor_property_id)(state);

  return {
    isVisible,
    selectedContacts: matches.map((item) => item.realtor_contact_id),
    propertyId: client.realtor_property_id,
    selectedClient,
    matches,
    withAddContact: true,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeAssignInterestedPartyModal()),

  onSave: async ({
    propertyId,
    addedContacts,
    removedContacts,
    clientId,
    matches,
  }) => {
    const addContacts = addedContacts.map(async (contactId) => {
      await dispatch(createMatchingProperty({
        realtor_property_id: propertyId,
        realtor_contact_id: contactId,
        status: 1,
      }));
    });

    const removeContacts = removedContacts.map(async (contactId) => {
      const match = matches.find((item) => item.realtor_contact_id === contactId);
      await dispatch(removeMatchingProperty(match?.id));
    });

    await Promise.all(addContacts);
    await Promise.all(removeContacts);
    dispatch(getClientById(clientId));

    await dispatch(resetMatchingPropertyFilters());
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onSave: async ({ addedContacts, removedContacts }) => {
    dispatchProps.onSave({
      addedContacts,
      removedContacts,
      propertyId: stateProps.propertyId,
      clientId: stateProps.selectedClient,
      matches: stateProps.matches,
    });
    dispatchProps.closeModal();
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AssignInterestedPartyModal);
