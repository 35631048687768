import React from 'react';

import Typography from 'ui/Typography';

const Reach = (value, record) => (
  <>
    <Typography
      variant="title3"
      tag="p"
    >
      {value?.total}
      {' '}
      {value?.total !== 1 ? 'contacts' : 'contact'}
    </Typography>
    <Typography
      variant="title4"
      color="ghost"
    >
      {value?.sent}
      /
      {value?.total}
      {' '}
      sent
    </Typography>
  </>
  );

export default Reach;
