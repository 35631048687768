import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateProperty,
} from 'store/actions/properties';

import PropertyURL from './PropertyURL';

const mapStateToProps = (state) => {
  const client = selectors.realtorClients.selected(state);
  const property = selectors.properties.byId(state, client.realtor_property_id);

  return {
    url: property?.property_url,
    propertyId: client.realtor_property_id,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: (value, propertyId) => {
    dispatch(updateProperty(propertyId)({ property_url: value }));
  },
});

const mergeProps = (stateProps, dispatchProps) => ({
  url: stateProps.url,
  onChange: (value) => {
    dispatchProps.onChange(value, stateProps.propertyId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PropertyURL);
