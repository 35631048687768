import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import Button from 'ui/Button';

const TableKanbanSwitch = (props) => {
  const {
    onChange,
    currentView,
  } = props;

  const handleChange = useCallback((view) => (value) => {
    onChange(view, value);
  });

  return (
    <Flex spacing={2}>
      <Button
        icon="menu"
        onClick={handleChange('table')}
        variant={currentView === 'table' ? 'tertiary' : 'secondary'}
      />
      <Button
        icon="grid"
        onClick={handleChange('kanban')}
        variant={currentView === 'kanban' ? 'tertiary' : 'secondary'}
      />
    </Flex>
  );
};

TableKanbanSwitch.defaultProps = {
  currentView: 'table',
};

const {
  func,
  oneOf,
} = PropTypes;

TableKanbanSwitch.propTypes = {
  onChange: func.isRequired,
  currentView: oneOf(['kanban', 'table']),
};

export default TableKanbanSwitch;
