import React from 'react';
import PropTypes from 'prop-types';

import { since } from '@symplete/symplete-utils/datetime';

import Typography from 'ui/Typography';
import ErrorBoundary from 'ui/ErrorBoundary';

import withProspectData from 'sections/Prospects/hocs/withProspectData';

const SinceClient = (props) => {
  const {
    prospect,
  } = props;

  const daysSince = since(prospect?.created_at);

  return (
    <ErrorBoundary>
      <div>
        <Typography
          align="center"
          variant="title3"
          noMargin
          flat
        >
          {daysSince}
          {' '}
          {daysSince !== 1 ? 'days' : 'day'}
        </Typography>

        <Typography
          align="center"
          variant="caption2"
          noMargin
          flat
        >
          since client
        </Typography>
      </div>
    </ErrorBoundary>
  );
};

SinceClient.defaultProps = {
  prospect: {},
};

const {
  number,
  oneOfType,
  shape,
  string,
} = PropTypes;

SinceClient.propTypes = {
  prospect: shape({
    created_at: oneOfType([string, number]),
  }),
};

export default withProspectData(SinceClient);
