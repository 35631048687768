import React, { useState, useCallback } from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Slider from '@material-ui/core/Slider';

const StyledSlider = withStyles((theme) => ({
  root: {
    marginBottom: 0,
  },

  rail: {
    backgroundColor: '#d6d7d9',
  },

  valueLabel: {
    top: -28,
    fontFamily: 'Roboto, Arial, Helvtica, sans-serif',
    fontSize: '13px',
    fontWeight: 700,
    color: '#172b4d',
    '& *': {
      background: 'transparent',
    },
  },

  thumb: {
    height: 15,
    width: 15,
    backgroundColor: 'white',
    border: '1px solid currentColor',
    marginTop: -6,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },

  markLabel: {
    top: -5,
    fontSize: '8px',
    color: 'rgba(35, 48, 63, 0.87)',
  },
}))(Slider);

const RangeSlider = (props) => {
  const {
    min,
    max,
    marks,
    formatValueLabel,
    onChange,
    value,
  } = props;

  const [currentValue, setCurrentValue] = useState(value);

  const handleChange = useCallback((event, newValue) => {
    setCurrentValue(newValue);
  });

  const onChangeCommitted = useCallback((event, newValue) => {
    onChange(newValue);
  });

  return (
    <StyledSlider
      min={min}
      max={max}
      marks={marks}
      value={currentValue}
      onChange={handleChange}
      valueLabelDisplay="on"
      valueLabelFormat={formatValueLabel}
      onChangeCommitted={onChangeCommitted}
    />
  );
};

RangeSlider.defaultProps = {
  min: 0,
  max: 100,
  marks: [],
  formatValueLabel: (value) => value,
  value: [0, 100],
};

const {
  arrayOf,
  func,
  number,
  shape,
  string,
} = PropTypes;

RangeSlider.propTypes = {
  /**
   * min value
   */
  min: number,
  /**
   * max value
   */
  max: number,
  /**
   * marks on the slider
   */
  marks: arrayOf(shape({
    value: number,
    label: string,
  })),
  /**
   * function to format label
   * `(value) => string`
   */
  formatValueLabel: func,
  /**
   * on change callback
   * fires on `mouseup` event
   */
  onChange: func.isRequired,
  /**
   * value
   * `[from, to]`
   */
  value: arrayOf(number),
};

export default RangeSlider;
