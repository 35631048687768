import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  Root,
  StyledButton,
} from './styledItems';

const InterestedCell = (props) => {
  const {
    onChange,
    value,
  } = props;

  const handleClick = useCallback((newValue) => (event) => {
    event.stopPropagation();
    onChange(newValue);
  }, [
    onChange,
  ]);

  return (
    <Root>
      <StyledButton
        onClick={handleClick(true)}
        color={value === 1 ? '#2ecc71' : undefined}
      >
        Y
      </StyledButton>

      <StyledButton
        onClick={handleClick(false)}
        color={value === 0 ? '#ff526b' : undefined}
      >
        N
      </StyledButton>
    </Root>
  );
};

const {
  bool,
  func,
} = PropTypes;

InterestedCell.propTypes = {
  onChange: func.isRequired,
  value: bool.isRequired,
};

export default InterestedCell;
