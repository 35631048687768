import styled from 'styled-components';

import { isNumber } from 'shared/utility';

const getProgress = (progress, theme, warning) => {
  if (progress >= 1) return;

  if (progress <= 0) {
    if (!isNumber(warning)) {
      return `
        background-color: ${theme?.palette?.backgroundEmpty || 'gray'};
      `;
    }

    return;
  }

  if (progress <= 0.5) {
    return `
      background-image: 
        linear-gradient(${90 + 360 * progress}deg, transparent 50%, ${theme?.palette?.backgroundEmpty || 'gray'} 50%),
        linear-gradient(90deg, ${theme?.palette?.backgroundEmpty || 'gray'} 50%, transparent 50%);
    `;
  }

  const mainColor = (isNumber(warning) && progress <= warning)
    ? (theme?.palette?.alert || 'red')
    : (theme?.palette?.focus || 'blue');

  return `
    background-image: 
      linear-gradient(${360 * progress - 90}deg, transparent 50%, ${mainColor} 50%),
      linear-gradient(90deg, ${theme?.palette?.backgroundEmpty || 'gray'} 50%, transparent 50%);
  `;
};

export const Root = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  background-color: ${(props) => ((isNumber(props.warning) && props.progress <= props.warning)
      ? (props.theme?.palette?.alert || 'red')
      : (props.theme?.palette?.focus || 'blue'))};
  ${(props) => getProgress(props.progress, props.theme, props.warning)}
`;

export const Center = styled.div`
  background-color: white;
  border-radius: 50%;
  width: 52px;
  height: 52px;
  padding: 0 8px;
`;

export default {
  Root,
  Center,
};
