export default {
  externalAccess: '/api/external-access',

  realtor: {
    leads: 'external/realtor/leads',
    offers: 'external/realtor/offers',
    request: 'external/realtor/request',
    contact: 'external/realtor/contact',
  },

  attachFile: 'external/attach-file',

  general: {
    externalTokenIssuer: 'external/general/issuer',
  },
};
