import React from 'react';
import PropTypes from 'prop-types';
import Grid from 'ui/Grid';

import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';
import ContactAvatar from 'shared/modules/RealtorContactAvatar';
import useContactName from 'shared/hooks/contacts/useContactName';

import {
  Name,
  InfoItem,
} from './styledItems';

const Contact = (props) => {
  const {
    contactId,
    email,
    phone,
  } = props;

  const contactName = useContactName(contactId);

  return (
    <Grid
      xs={12}
      container
      spacing={2}
      alignItems="center"
    >
      <Grid item>
        <ContactAvatar
          contactId={contactId}
        />
      </Grid>
      <Grid item>
        <Name>
          {contactName}
        </Name>
        {
          email && (
            <DecoratedFieldWrapper
              iconName="envelope"
              size="sm"
            >
              <InfoItem>
                {email}
              </InfoItem>
            </DecoratedFieldWrapper>
          )
        }
        {
          phone && (
            <DecoratedFieldWrapper
              iconName="phone"
              size="sm"
              noMargin
            >
              <InfoItem>
                {phone}
              </InfoItem>
            </DecoratedFieldWrapper>
          )
        }
      </Grid>
    </Grid>
  );
};

Contact.defaultProps = {
  contactId: null,
  email: null,
  phone: null,
};

const {
  number,
  string,
} = PropTypes;

Contact.propTypes = {
  contactId: number,
  email: string,
  phone: string,
};

export default Contact;
