import styled from 'styled-components';

import { NakedButton } from '@symplete/symplete-ui';

export const Root = styled.div`
  display: flex;
  width: 100%;
`;

export const Button = styled(NakedButton)`
&& {
  width: auto;
  height: 75px;
  background-color: ${(props) => (props.theme.palette?.background || 'white')};
  border: 2px solid;
  border-color: ${(props) => (
    props.active
      ? props.theme.palette?.selected || 'green'
      : props.theme.palette?.border || 'lightgrey'
  )};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1 1;
  margin: 10px 5px 0;
  padding: 12px 10px;
}
`;
