import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  PROPERTY_TYPES,
} from 'constants/realtor.constants';

import PropertyCard from 'sections/Opportunities/components/PropertyCard';

const mapStateToProps = (state, ownProps) => {
  const {
    id,
  } = ownProps;

  const client = selectors.realtorClients.byId(state, id);

  const {
    contact_id: sellerId,
    realtor_property_id: propertyId,
  } = client;

  const property = selectors.properties.byId(state, propertyId);

  const address = selectors.addresses.byId(state, property?.address_id);

  return {
    sellerId,
    propertyId,

    price: client.listing_price,
    expiryDate: client.listing_end_date,
    createdAt: client.created_at,

    viewingsCount: property.viewings_summary?.total_viewings,
    type: PROPERTY_TYPES[property.property_type],
    address: address?.address,

    // offersCount,
    // viewingsCount,
    // label,
    // notice,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyCard);
