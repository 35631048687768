import styled from 'styled-components';

import Elevation from 'ui/Elevation';

export const Root = styled(Elevation)`
  .information-item {
    margin-left: -6px;
  }
`;

export const TagWrapper = styled.div`
  display: flex;
  width: -webkit-fill-available;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border-radius: 8px;
  background-color: ${(props) => props.theme?.palette?.['background-flat'] || 'gray'};
`;

export default {
  Root,
  TagWrapper,
};
