import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import Typography from 'ui/Typography';
import AdditionalAmenities from 'shared/modules/AdditionalAmenities';

import PropertyDetails from 'sections/Clients/modules/PropertyDetails';
import PropertyDescription from 'sections/Clients/modules/PropertyDescription';
import PropertyURL from 'sections/Clients/modules/PropertyURL';
import ImportantViewingNotes from 'sections/Clients/modules/ImportantViewingNotes';

const PropertyDetailsView = (props) => {
  const {
    propertyId,
  } = props;

  return (
    <Grid container spacing={3}>

      <Grid item xs={12}>
        <Typography
          variant="h3"
        >
          Additional Amenities
        </Typography>
        <AdditionalAmenities
          itemId={propertyId}
          itemType="properties"
        />
      </Grid>

      <Grid item container xs={12} spacing={2}>

        <Grid item xs={8}>
          <Typography variant="h3" noMargin>
            Property Description
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <PropertyURL />
        </Grid>

        <Grid item xs={12}>
          <PropertyDescription />
        </Grid>

      </Grid>

      <Grid item container xs={12} spacing={2}>

        <Grid item xs={12}>
          <Typography variant="h3" noMargin>
            Important Showing Notes
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <ImportantViewingNotes />
        </Grid>

      </Grid>

      <Grid item xs={12}>
        <PropertyDetails />
      </Grid>

    </Grid>
  );
};

const {
  number,
} = PropTypes;

PropertyDetailsView.propTypes = {
  propertyId: number.isRequired,
};

export default PropertyDetailsView;
