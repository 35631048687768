import React from 'react';
import PropTypes from 'prop-types';

import ContactAvatar from 'shared/modules/RealtorContactAvatar';
import MultipleRoleLabel from 'sections/Leads/modules/MultipleRoleLabel';

import {
  Root,
  SelectWrapper,
} from './styledItems';

const AvatarWithLeadTypeSelect = (props) => {
  const {
    contactId,
    leadId,
  } = props;

  return (
    <Root>
      <ContactAvatar
        contactId={contactId}
        size="m"
      />
      <SelectWrapper>
        <MultipleRoleLabel
          leadId={leadId}
        />
      </SelectWrapper>
    </Root>
  );
};

AvatarWithLeadTypeSelect.defaultProps = {
  contactId: null,
  leadId: null,
};

const {
  number,
} = PropTypes;

AvatarWithLeadTypeSelect.propTypes = {
  contactId: number,
  leadId: number,
};

export default AvatarWithLeadTypeSelect;
