import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import Create from 'sections/Communications/modules/Marketing/Create';
import SendSummary from 'sections/Communications/modules/Marketing/SendSummary';
import Metrics from 'sections/Communications/modules/Marketing/CampaignDetails/Metrics';

import Search from 'sections/MLS/modules/PropertiesTableSearchFilter';
import useCampaigns from 'sections/Communications/hooks/useCampaigns';

const Toolbar = (props) => {
  const {
  } = props;
  
  const {
    campaignsList,
  } = useCampaigns({
    limit: 100,
    offset: 0,
  });

  const metrics = campaignsList.reduce((acc, campaign) => {
    const {
      metrics,
    } = campaign;

    Object.keys(metrics).forEach((key) => {
      if (acc[key]) {
        acc[key].count += metrics[key].count;
        acc[key].percentage += parseInt(metrics[key].percentage.replace('%', ''));
      } else {
        acc[key] = {
          count: metrics[key].count,
          percentage: parseInt(metrics[key].percentage.replace('%', '')),
        };
      }
    });

    return acc;
  }, {});

  Object.keys(metrics).forEach((key) => {
    metrics[key].percentage = Math.round(metrics[key].percentage / campaignsList.length);
    metrics[key].percentage = `${metrics[key].percentage}%`;
  });

  return (
    <>
      <Flex
        spacing={2}
        flexWrap="wrap"
        alignItems="center"
        fullWidth
      >
        <FlexItem fullWidth>
          <Metrics data={metrics} />
        </FlexItem>
      </Flex>
      <Flex
        fullWidth
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
      >
        {/* <Search 
          withDropdownFilter={true}
          searchInputPlaceholder="Search all campaigns"
        /> */}
        <Create />
      </Flex>
    </>
  );
};

Toolbar.defaultProps = {

};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

Toolbar.propTypes = {

};

export default Toolbar;
