import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import ListingFocusColumn from 'sections/MLS/components/ListingFocusColumn';

const ListingFocus = (props) => {
  const {
    columnData,
  } = props;

  return (
    <Grid
      container
      spacing={5}
    >
      {
        columnData.map(({
          title,
          description,
          items,
          objectKey,
          itemsCount,
          filterItems,
          onHeaderButtonClick,
          loadMore,
        }) => (
          <Grid item xs={4}>
            <ListingFocusColumn
              onHeaderButtonClick={onHeaderButtonClick}
              title={title}
              filterItems={filterItems}
              description={description}
              items={items}
              itemsCount={itemsCount}
              loadMore={loadMore}
              objectKey={objectKey}
            />
          </Grid>
        ))
      }
    </Grid>
  );
};

const {
  arrayOf,
  func,
  number,
  shape,
  string,
} = PropTypes;

ListingFocus.propTypes = {
  columnData: arrayOf(shape({
    title: string,
    items: arrayOf(shape()),
    filterItems: arrayOf(shape()),
    onHeaderButtonClick: func,
    itemsCount: number,
    loadMore: func,
    objectKey: string,
  })).isRequired,
};

export default ListingFocus;
