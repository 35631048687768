import { connect } from 'react-redux';

import selectors from 'store/selectors';

import CompletedTransactionInfo from './CompletedTransactionInfo';

const mapStateToProps = (state, ownProps) => {
  const transaction = selectors.realtorTransactions.byId(state, ownProps.transactionId);

  return {
    initialPrice: transaction?.price,
    finalPrice: transaction?.final_price,
    completedDate: transaction?.completed_at,
  };
};

export default connect(mapStateToProps)(CompletedTransactionInfo);
