import React from 'react';
import PropTypes from 'prop-types';

import { currency } from '@symplete/symplete-utils/numbers';
import { capitalizeSentence } from 'shared/utils/strings';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';

const PropertyPriceCell = (props) => {
  const {
    value,
    saleType,
  } = props;

  return value ? (
    <Flex
      flexDirection="column"
      spacing={1}
    >
      <Typography
        variant="cellLarge"
      >
        {currency.full(value)}
      </Typography>
      {
        saleType
        ? (
          <Typography
            variant="cell2"
            color="descriptor-text"
          >
            {capitalizeSentence(saleType)}
          </Typography>
          )
        : null
      }
    </Flex>
  )
  : (
    <Typography variant="cell">
      —
    </Typography>
  );
};

PropertyPriceCell.defaultProps = {
  value: '—',
  saleType: null,
};

const {
  number,
  string,
} = PropTypes;

PropertyPriceCell.propTypes = {
  value: number,
  saleType: string,
};

export default PropertyPriceCell;
