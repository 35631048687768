import React, {
  useMemo,
} from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';

import useProperty from 'shared/hooks/properties/useProperty';
import usePropertyRequirements from 'shared/hooks/propertyRequirements/usePropertyRequirements';

import LocationCell from 'sections/Leads/modules/LeadsTable/LocationCell';
import LocationCellMultiple from 'sections/Leads/modules/LeadsTable/LocationCellMultiple';

import getPropertyParameters from './getPropertyParameters';

const PropertyCell = (props) => {
  const {
    itemType,
    itemId,
  } = props;

  const useItem = itemType === 'properties'
    ? useProperty
    : usePropertyRequirements;

  const item = useItem(itemId);

  const parameters = useMemo(() => getPropertyParameters(itemType)(item), [
    item,
  ]);

  return (
    <Flex
      spacing={1}
      flexDirection="column"
    >
      {
        itemType !== 'properties' && (
          <LocationCellMultiple addresses={item?.addresses} />
        )
      }
      {
        itemType === 'properties' && (
          <LocationCell addressId={item?.address_id} />
        )
      }
      {
        parameters.length > 0 && (
          <Typography
            variant="cell2"
            color="descriptor-text"
          >
            {parameters.join(' • ')}
          </Typography>
        )
      }
    </Flex>
  );
};

const {
  number,
  oneOf,
} = PropTypes;

PropertyCell.propTypes = {
  itemType: oneOf(['properties', 'propertyRequirements']).isRequired,
  itemId: number.isRequired,
};

export default PropertyCell;
