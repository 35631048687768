import { connect } from 'react-redux';

import moment from 'moment';

import selectors from 'store/selectors';

import ViewingsTable from 'sections/Opportunities/components/ViewingsTable';

import columns from 'sections/Prospects/components/ViewingsTable/pastViewingsColumns';

const getPastViewings = (startDate) => (items = []) => {
  if (!Array.isArray(items)) {
    return [];
  }
  const viewings = items.filter(({ date_time }) => (
    moment(date_time).isSameOrAfter(startDate, 'day')
    && moment(date_time).isBefore(moment())
  ))
    .sort((a, b) => moment(a.date_time).valueOf() - moment(b.date_time).valueOf());

  return viewings;
};

const mapStateToProps = (state) => {
  const prospect = selectors.realtorProspects.selected(state);

  const { events: prospectViewings } = selectors.realtorProspects.prospectViewings(state, prospect?.id);

  return {
    tableKey: 'past_viewings',
    filterViewings: getPastViewings(prospect.created_at),
    allViewings: prospectViewings,
    getColumns: columns,
  };
};

export default connect(mapStateToProps)(ViewingsTable);
