import { connect } from 'react-redux';

import {
  optimizedRealtorInspectionsTypesActions,
} from 'store/actions/realtorInspectionsTypes';

import TypeCell from './TypeCell';

const mapStateToProps = (state, ownProps) => {
  const {
    inspectionTypeId,
  } = ownProps;

  const {
    realtorInspections: {
      inspectionsTypes,
    },
  } = state;

  if (!inspectionsTypes[inspectionTypeId]) {
    optimizedRealtorInspectionsTypesActions.getInspectionTypes();
  }

  const type = inspectionsTypes
    ? inspectionsTypes[inspectionTypeId]
    : {
      name: '—',
    };

  return {
    value: type?.name,
  };
};

export default connect(mapStateToProps)(TypeCell);
