import { connect } from 'react-redux';

import {
  closeRejectionModal,
} from 'store/actions/realtorMatchingPropertyRejectionModal';

import MatchingPropertyRejectionModal from './MatchingPropertyRejectionModal';

const mapStateToProps = (state) => {
  const {
    matchingPropertyRejectionModal: {
      isVisible,
    },
  } = state;

  return {
    isVisible,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => {
    dispatch(closeRejectionModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MatchingPropertyRejectionModal);
