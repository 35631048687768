import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

const StageItemLayout = (props) => {
  const {
    documentsComponent,
    detailsComponent,
  } = props;

  return (
    <Grid container spacing={8}>
      <Grid item xs={8}>
        {documentsComponent}
      </Grid>
      <Grid item xs={4}>
        {detailsComponent}
      </Grid>
    </Grid>
  );
};

const {
  element,
} = PropTypes;

StageItemLayout.propTypes = {
  documentsComponent: element.isRequired,
  detailsComponent: element.isRequired,
};

export default StageItemLayout;
