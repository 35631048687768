import React, {
  useState,
  useCallback,
  useContext,
} from 'react';

import PropTypes from 'prop-types';

import useModal from 'ui/hooks/useModal';

import Button from 'ui/Button';

// import AssignRecipients from 'sections/Communications/modules/Marketing/AssignRecipients';

import NewMarketingCampaignModal from 'sections/Communications/modals/NewMarketingCampaignModal';

const Create = (props) => {
  // const {
  //   isModalVisible,
  //   openModal,
  //   closeModal,
  // } = useModal();

  const {
    isDisabled,
  } = props;

  const {
    isModalVisible: isNewMarketingCampaignModalVisible,
    openModal: openNewMarketingCampaignModal,
    closeModal: closeNewMarketingCampaignModal,
  } = useModal();

  // const [selectedRecipients, setSelectedRecipients] = useState([]);

  // const [isEmailDrawerVisible, setIsEmailDrawerVisible] = useState(false);

  // const openEmailDrawer = useCallback(() => {
  //   setIsEmailDrawerVisible(true);
  // }, [
  //   setIsEmailDrawerVisible,
  // ]);

  // const closeEmailDrawer = useCallback(() => {
  //   setIsEmailDrawerVisible(false);
  // }, [
  //   setIsEmailDrawerVisible,
  // ]);

  // const assignRecipients = useCallback((data) => {
  //   setSelectedRecipients(data?.addedContacts);
  //   closeModal();
  //   openEmailDrawer();
  // }, [
  //   setSelectedRecipients,
  //   closeModal,
  //   openEmailDrawer,
  // ]);

  return (
    <>
      <Button
        onClick={openNewMarketingCampaignModal}
        variant="primary"
        icon="plus"
        disabled={isDisabled}
      >
        New
      </Button>

      {/* <AssignRecipients
        isVisible={isModalVisible}
        closeModal={closeModal}
        selectedContacts={selectedRecipients}
        onSave={assignRecipients}
      /> */}

      <NewMarketingCampaignModal
        isVisible={isNewMarketingCampaignModalVisible}
        closeModal={closeNewMarketingCampaignModal}
      />
    </>
  );
};

Create.defaultProps = {
  isDisabled: false,
};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

Create.propTypes = {
  isDisabled: bool,
};

export default Create;
