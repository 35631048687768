import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateProperty,
} from 'store/actions/properties';

import PropertyDescription from './PropertyDescription';

const mapStateToProps = (state) => {
  const client = selectors.realtorClients.selected(state);
  const property = selectors.properties.byId(state, client.realtor_property_id);

  return {
    description: property?.property_description,
    propertyId: client.realtor_property_id,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: (value, propertyId) => {
    dispatch(updateProperty(propertyId)({ property_description: value }));
  },
});

const mergeProps = (stateProps, dispatchProps) => ({
  description: stateProps.description,
  onChange: (value) => {
    dispatchProps.onChange(value, stateProps.propertyId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PropertyDescription);
