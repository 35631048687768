import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
// import Grid from 'ui/Grid';
// import Typography from 'ui/Typography';
// import Button from 'ui/Button';

// import Divider from '@material-ui/core/Divider';

import ImageCarousel from 'sections/MLS/modules/ImageCarousel';

import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';

import PinnedNote from 'sections/MLS/modules/PinnedNote';
import Archive from 'sections/MLS/modules/Archive';
import BookViewingButton from 'sections/MLS/modules/BookViewingButton';
import AddBuyerButton from 'sections/MLS/modules/AddBuyerButton';
import ListingAgent from 'sections/MLS/modules/ListingAgent';
import ListingAgreement from 'sections/MLS/modules/ListingAgreement';
import SpecialStatuses from 'sections/MLS/modules/SpecialStatuses';

import PropertyAddressInput from 'sections/Properties/modules/PropertyAddressInput';
import PropertyPriceInput from 'sections/Properties/modules/PropertyPriceInput';
import PropertyParametersMain from 'sections/Properties/modules/PropertyParametersMain';

import FacebookPost from 'sections/MLS/modules/FacebookPost';

// import BrokerCommission from 'sections/MLS/modules/BrokerCommission';
// import PropertySummary from 'sections/MLS/modules/PropertySummary';

const Sidebar = (props) => {
  const {
    propertyId,
  } = props;

  return (
    <Flex spacing={2}>

      <FlexItem xs={8}>
        <ImageCarousel />
      </FlexItem>

      <FlexItem xs={2}>
        <Flex
          spacing={1}
          fullWidth
          flexDirection="column"
          alignItems="center"
        >
          <ListingAgent
            propertyId={propertyId}
          />
          <ListingAgreement
            propertyId={propertyId}
          />
        </Flex>
      </FlexItem>

      <FlexItem xs={14}>
        <Flex
          spacing={2}
          fullWidth
          flexWrap="wrap"
        >
          <Flex
            fullWidth
            justifyContent="flex-end"
            spacing={2}
          >
            <FacebookPost />
            <AddBuyerButton />
            <BookViewingButton />
            <PinnedNote />
            <Archive propertyId={propertyId} />
          </Flex>

          <FlexItem fullWidth>
            <DecoratedFieldWrapper
              iconName="map-pin"
              iconType="feather"
              variant="focus"
            >
              <PropertyAddressInput
                defaultView="display"
                propertyId={propertyId}
                label={null}
              />
            </DecoratedFieldWrapper>
          </FlexItem>
          <FlexItem fullWidth>
            <DecoratedFieldWrapper
              iconName="dollar-sign"
              iconType="feather"
              variant="focus"
            >
              <PropertyPriceInput
                propertyId={propertyId}
                defaultView="display"
              />
            </DecoratedFieldWrapper>
          </FlexItem>
          <FlexItem fullWidth padding="0 0 0 6px">
            <PropertyParametersMain
              propertyId={propertyId}
            />
          </FlexItem>
          <FlexItem fullWidth>
            <SpecialStatuses
              propertyId={propertyId}
            />
          </FlexItem>
        </Flex>
      </FlexItem>

    </Flex>
  );
};

Sidebar.defaultProps = {
  propertyId: null,
};

const {
  // array,
  // arrayOf,
  // bool,
  // element,
  // func,
  // instanceOf,
  number,
  // object,
  // objectOf,
  // oneOf,
  // oneOfType,
  // shape,
  // string,
} = PropTypes;

Sidebar.propTypes = {
  propertyId: number,
};

export default Sidebar;
