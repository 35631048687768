/* eslint-disable react/jsx-no-bind */
import React, { useContext } from 'react';

import Flex from 'ui/Flex';
import Button from 'ui/Button';

import editorContext from './context';

const Toolbar = (props) => {
  const {
    editor,
  } = useContext(editorContext);

  if (!editor) return null;

  return (
    <Flex 
      spacing={1}
      fullWidth
    >
      <Flex
        spacing={4}
        fullWidth
      >
        <Button
          onClick={() => editor.chain().focus().toggleBold().run()}
          icon="bold"
          variant="secondary"
          color={editor.isActive('bold') ? 'focus' : 'black'}
        />
        <Button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          icon="italic"
          variant="secondary"
          color={editor.isActive('italic') ? 'focus' : 'black'}
        />
        <Button
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          icon="underline"
          variant="secondary"
          color={editor.isActive('underline') ? 'focus' : 'black'}
        />
        <Button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          icon="list"
          variant="secondary"
          color={editor.isActive('bulletList') ? 'focus' : 'black'}
        />
      </Flex>
      <Flex
        spacing={1}
        fullWidth
        justifyContent="flex-end"
      >
        {props.children}
      </Flex>
    </Flex>
  );
};

export default Toolbar;
