import React from 'react';

import moment from 'moment';

import Typography from 'ui/Typography';

import UnderConstructionPlaceholder from 'shared/components/UI/UnderConstructionPlaceholder';

import ContactCell from 'sections/Opportunities/components/ViewingsTable/ContactCell';
import ConfirmationCell from 'sections/Opportunities/components/ViewingsTable/ConfirmationCell';
import NoteCell from 'sections/Opportunities/components/ViewingsTable/NoteCell';
import DeleteButton from 'sections/Opportunities/components/ViewingsTable/DeleteButton';
import AddContactButton from 'sections/Opportunities/components/ViewingsTable/AddContactButton';

const columns = ({
  hoveredRecord,
}) => ([
  {
    title: 'Date',
    dataIndex: 'date_time',
    key: 'date',
    width: 100,
    render: (value, entry) => (value ? (
      <Typography variant="cell">
        {moment(value).format('MMMM D, YYYY')}
      </Typography>
    ) : null),
  },
  {
    title: 'Time',
    dataIndex: 'date_time',
    key: 'time',
    width: 80,
    render: (value, entry) => (value ? (
      <Typography variant="cell">
        {moment(value).format('hh:mm A')}
      </Typography>
    ) : null),
  },
  {
    title: 'Buyer',
    width: 150,
    dataIndex: 'contacts',
    key: 'buyer',
    className: 'viewings-table__sm-padding',
    render: (value, entry) => {
      if (Array.isArray(value)) {
        const buyer = value.find((contact) => contact.role === 1);
        if (buyer) {
          return <ContactCell contactId={buyer.id} />;
        }
      }
      return <AddContactButton viewingId={entry.id} field="buyer" />;
    },
    align: 'left',
  },
  {
    title: 'Buyer Agent',
    width: 150,
    dataIndex: 'contacts',
    key: 'buyer_agent',
    className: 'viewings-table__sm-padding',
    render: (value, entry) => {
      if (Array.isArray(value)) {
        const agent = value.find((contact) => contact.role === 3);
        if (agent) {
          return <ContactCell contactId={agent.id} />;
        }
      }
      return <AddContactButton viewingId={entry.id} field="buyerAgent" />;
    },
    align: 'left',
  },
  {
    title: 'Reminder',
    width: 100,
    dataIndex: '',
    key: 'reminder',
    render: (value, entry) => (
      <UnderConstructionPlaceholder>
        <Typography variant="cell">
          Reminder
        </Typography>
      </UnderConstructionPlaceholder>
    ),
    align: 'left',
  },
  {
    title: 'Notes',
    width: 60,
    dataIndex: 'notes',
    key: 'notes',
    className: 'viewings-table__sm-padding',
    render: (value, entry) => (
      <NoteCell hasContent={value && value !== '<p></p>'} viewingId={entry?.id} />
    ),
    align: 'center',
  },
  {
    title: 'Confirmed',
    width: 70,
    dataIndex: 'is_confirmed',
    key: 'is_confirmed',
    className: 'viewings-table__sm-padding',
    render: (value, entry) => (
      <ConfirmationCell value={!!value} viewingId={entry?.id} />
    ),
    align: 'center',
  },
  {
    title: '',
    width: 30,
    key: 'delete',
    className: 'viewings-table__sm-padding',
    render: (_, entry) => {
      if (hoveredRecord === entry.key) {
        return <DeleteButton viewingId={entry?.id} />;
      }

      return null;
    },
    align: 'center',
  },
]);

export default columns;
