import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import GalleryCarousel from 'react-gallery-carousel';

import { NakedButton } from '@symplete/symplete-ui';

import 'react-gallery-carousel/dist/index.css';

const Root = styled.div`
  position: relative;
`;

const Carousel = styled(GalleryCarousel)`
  height: 250px;
  width: 100%;
  border-radius: 10px;

  img {
    border-radius: 10px;
  }
  div {
    border-radius: 10px;
  }
`;

const IconWrapper = styled.div`
  height: 250px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: space-evenly;
  background-color: ${(props) => props.theme.palette?.['background-flat-dark'] || 'lightgray'};
`;

const Label = styled.div`
  position: absolute;
  bottom: 225px;
  border-radius: 4px;
  border: 1px white solid;
  color: white;
  background-color: #50e3c2;
  text-transform: uppercase;
  padding: 1px 10px;
  width: -moz-fit-content;
  width: fit-content;
`;

const EditButton = styled(NakedButton)`
&& {
  position: absolute;
  bottom: 2px;
  left: 3px;
  opacity: 0.75;

  &:hover {
    opacity: 1;
  }
}
`;

const PlaceholderImageWrapper = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #cccccc;
  border-radius: 5px;
`;

const Icon = styled(FontAwesomeIcon)`
  padding: 5px 7px;
  box-sizing: content-box;
  color: white;
  font-size: ${(props) => (props.size === 'lg' ? '74px' : '24px')};
`;

export {
  Root,
  Carousel,
  Label,
  IconWrapper,
  PlaceholderImageWrapper,
  Icon,
  EditButton,
};
