import React, {
  useCallback,
} from 'react';

import { useHistory } from 'react-router-dom';

import { isEmpty } from 'shared/utility';

import Typography from 'ui/Typography';
import TableHead from 'ui/TableHead';

import ContactCell from 'sections/Leads/modules/LeadsTable/ContactCell';

import SocialLinks from 'sections/Contacts/modules/ContactsTable/SocialLinks';
import ContactLabels from 'sections/Contacts/modules/ContactLabels';
import OptionsCell from 'sections/Contacts/modules/OptionsV2';
import ContactPhoneNumbers from 'sections/Contacts/modules/ContactPhoneNumbers';
import ContactEmails from 'sections/Contacts/modules/ContactEmails';

// import formatValue from './formatValue';
// import formatCreationTime from './formatCreationTime';
import formatContactTime from './formatContactTime';
import formatSocialLinks from './formatSocialLinks';

const columns = ({
  hoveredRecord,
  onSort,
}) => {
  const history = useHistory();

  const openRecord = useCallback((record) => ({
    onClick: () => {
      history.push(`/contacts/${record.id}`);
    },
  }), [
    history,
  ]);

  return [
    {
      title: () => (
        <TableHead onSort={onSort('contactName')}>
          Contact
        </TableHead>
      ),
      onCell: openRecord,
      width: 2,
      dataIndex: 'id',
      key: 'contactName',
      // sorter: true,
      render: (value, entry) => (value ? (
        <ContactCell
          contactId={value}
        />
      ) : null),
    },
    {
      title: () => (
        <TableHead>
          Labels
        </TableHead>
      ),
      onCell: openRecord,
      width: 2,
      dataIndex: 'id',
      key: 'labels',
      render: (contactId) => (
        <ContactLabels contactId={contactId} editable={false} />
      ),
    },
    {
      title: () => (
        <TableHead onSort={onSort('role')}>
          Role
        </TableHead>
      ),
      onCell: openRecord,
      width: 1,
      dataIndex: 'role',
      key: 'role',
      render: (value, entry) => (value ? <Typography variant="cell">{value}</Typography> : ''),
    },
    {
      title: () => (
        <TableHead onSort={onSort('email')}>
          Email
        </TableHead>
      ),
      onCell: openRecord,
      width: 2,
      dataIndex: 'id',
      key: 'email',
      render: (value, entry) => (entry.emails
        ? <ContactEmails contactId={value} withEditMode={false} />
        : entry.email_primary),
    },
    {
      title: () => (
        <TableHead onSort={onSort('phone')}>
          Phone
        </TableHead>
      ),
      onCell: openRecord,
      width: 1.5,
      dataIndex: 'id',
      key: 'phone',
      render: (value, entry) => (entry.phone_numbers
        ? <ContactPhoneNumbers contactId={value} withEditMode={false} />
        : entry.phone_primary),
    },
    {
      title: () => (
        <TableHead onSort={onSort('contactTime')}>
          Contact (last/next)
        </TableHead>
      ),
      onCell: openRecord,
      width: 1,
      dataIndex: 'id',
      key: 'contactTime',
      className: 'realtor-contacts-table__sm-padding',
      render: (_, record) => {
        if (hoveredRecord === record.key) {
          const links = formatSocialLinks(record);

          if (!isEmpty(links)) {
            return <SocialLinks links={links} />;
          }
        }

        return formatContactTime(record?.last_contact_date, record?.next_contact_date);
      },
    },
    {
      title: null,
      dataIndex: 'id',
      key: 'options',
      width: 0.5,
      render: (value, entry) => (
        <OptionsCell contactId={value} />
      ),
    },
  ];
};

export default columns;
