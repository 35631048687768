import React from 'react';
import PropTypes from 'prop-types';

import getFeatureVersion from 'shared/utils/getFeatureVersion';

import Button from 'ui/Button';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Elevation from 'ui/Elevation';

import KPIBar from 'sections/MLS/modules/KPIBar';
import PropertiesTableSearchFilter from 'sections/MLS/modules/PropertiesTableSearchFilter';
import PropertiesTable from 'sections/MLS/modules/PropertiesTable';

import PropertiesKPIBar from 'sections/Properties/modules/PropertiesKPIBar';
import PropertiesTableV2 from 'sections/Properties/modules/PropertiesTable';

import {
  Root,
} from './styledItems';

const Properties = (props) => {
  const {
    openNewPropertyModal,
  } = props;

  const version = getFeatureVersion('properties');

  return (
    <Root>
      {
        version === 1
        ? (
          <Flex spacing={2} flexWrap="wrap">
            <FlexItem fullWidth>
              <KPIBar interactive />
            </FlexItem>
            <FlexItem fullWidth>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <FlexItem fullWidth>
                  <PropertiesTableSearchFilter />
                </FlexItem>
                <Button
                  onClick={openNewPropertyModal}
                  variant="primary"
                  icon="plus"
                  noWrap
                >
                  New Property
                </Button>
              </Flex>
            </FlexItem>
            <FlexItem fullWidth>
              <Elevation
                rounded="m"
              >
                <PropertiesTable />
              </Elevation>
            </FlexItem>
          </Flex>
          )
        : (
          <Flex spacing={2} flexWrap="wrap">
            <FlexItem fullWidth>
              <PropertiesKPIBar interactive />
            </FlexItem>
            <FlexItem fullWidth>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <FlexItem fullWidth>
                  <PropertiesTableSearchFilter />
                </FlexItem>
                <Button
                  onClick={openNewPropertyModal}
                  variant="primary"
                  icon="plus"
                  noWrap
                >
                  New Property
                </Button>
              </Flex>
            </FlexItem>
            <FlexItem fullWidth>
              <Elevation
                rounded="m"
              >
                <PropertiesTableV2 />
              </Elevation>
            </FlexItem>
          </Flex>
        )
      }
    </Root>
  );
};

const {
  func,
} = PropTypes;

Properties.propTypes = {
  openNewPropertyModal: func.isRequired,
};

export default Properties;
