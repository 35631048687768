/* eslint-disable react/jsx-no-bind */

import React, { useState } from 'react';

import PropTypes from 'prop-types';

import requests from 'api/requests';

import Button from 'ui/Button';
import useModal from 'ui/hooks/useModal';
import Textfield from 'ui/Textfield';
import Flex from 'ui/Flex';

import { callAlert } from 'ui/Alert';

const PropertySharePublicLink = (props) => {
  const {
    propertyId,
  } = props;

  const [link, setLink] = useState('');

  const {
    isModalVisible,
    openModal,
    closeModal,
    Modal,
  } = useModal();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link);
    closeModal();
    callAlert.success('Link copied to clipboard!');
  };

  const handleClick = async () => {
    try {
      const response = await requests.realtor.properties.share(propertyId);
      setLink(response.data);
      openModal();
    } catch (error) {
      callAlert.error('Something went wrong');
    }
  };

  return (
    <>
      <Button
        variant="flat"
        icon="link"
        onClick={handleClick}
        color="focus"
      >
        Create Public Link
      </Button>

      <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={closeModal}
        title="Copy link to clipboard"
      >
        <Flex
          fullWidth
          spacing={2}
        >
          <Textfield
            value={link}
            icon="link"
            fullWidth
          />
          <Button onClick={copyToClipboard}>Copy</Button>
        </Flex>
      </Modal>
    </>
  );
};

const {
  number,
} = PropTypes;

PropertySharePublicLink.propTypes = {
  propertyId: number.isRequired,
};

export default PropertySharePublicLink;
