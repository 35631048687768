// TODO - fix lint errors
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'shared/utility';

import {
  Col,
  Input,
  List,
  Modal,
  Row,
} from 'antd';

import classNames from 'classnames';
// TODO - fix dependency cycles
import { tokenFetch as fetch } from 'shared/API'; // eslint-disable-line import/no-cycle
import { DELETED_STATUS } from 'components/HR/constants';
import store from 'store'; // eslint-disable-line import/no-cycle
import { url } from 'paths';
import { preventPropagation } from 'shared/utils/index'; // eslint-disable-line import/no-cycle
import AvatarLogoComponent from 'shared/components/UserAvatarV2';

import AddUserForm from '../AddUserForm';

import './AssignModal.scss';

const { Item } = List;
const { Search } = Input;

class AssignModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initUsers: [],
      activeUsers: [],
      availableUsers: [],
      filteredUsers: [],
      key: null,
      searchKey: null,
      searchVal: '',
    };
  }

  componentDidMount() {
    // TODO - fix this nonsense
    const {
      data: {
        data: {
          activeUsers,
          availableUsers,
          key,
          searchKey,
        },
      },
    } = this.props;

    this.setState({
      initUsers: activeUsers,
      activeUsers,
      availableUsers: availableUsers.filter(
        (user) => user.EmployeeStatusID !== DELETED_STATUS,
      ),
      key,
      searchKey,
    });
  }

  componentDidUpdate = (prevProps) => {
    const {
      activeUsers: prevAactiveUsers,
    } = prevProps.data.data;
    const {
      data: {
        data: {
          availableUsers,
          activeUsers,
        },
      },
    } = this.props;
    if (activeUsers !== prevAactiveUsers) {
      this.setState({
        initUsers: activeUsers,
        activeUsers,
        availableUsers: availableUsers.filter(
          (user) => user.EmployeeStatusID !== DELETED_STATUS,
        ),
      });
    }
  }

  addUser = (user) => () => {
    const {
      usersLimit = 0,
    } = this.props;

    const {
      key,
      activeUsers,
    } = this.state;

    const result = activeUsers.filter(
      (active) => active[key] === user[key],
    );

    if (result.length) {
      this.setState({
        activeUsers: activeUsers.filter(
          (active) => active[key] !== user[key],
        ),
      });
    } else {
      if (usersLimit === 0) {
        this.setState({ activeUsers: [...activeUsers, user] });
      }

      if (usersLimit !== 0 && activeUsers.length < usersLimit) {
        this.setState({ activeUsers: [...activeUsers, user] });
      }
    }
  };

  mapUsers = (users, key) => users.map((user) => user[key]);

  handleOk = async () => {
    const {
      data: {
        userData,
        config: {
          normal,
          hook,
          apiUrl,
          start,
          success,
          error,
          save,
        },
        data: {
          id,
        },
      },
      onClickOK,
      closeModal,
    } = this.props;

    const {
      activeUsers,
      initUsers,
      key,
    } = this.state;

    if (save) {
      save(activeUsers)
        .then(() => {
          this.setState({
            initUsers: activeUsers,
            filteredUsers: [],
            searchVal: '',
          });
        })
        .catch(() => store.dispatch(error()));
      closeModal();
      return;
    }

    closeModal();
    // TODO - move API requests to store/actions
    if (normal) {
      hook(activeUsers);
      this.setState({
        initUsers: activeUsers,
        filteredUsers: [],
        searchVal: '',
      });
    } else if (!isEqual(initUsers, activeUsers)) {
      const initList = this.mapUsers(initUsers, key);
      const userList = this.mapUsers(activeUsers, key);
      const newAssignedUsers = userList.filter((user) => !initList.includes(user));
      const removedFromAssignee = initList.filter((user) => !userList.includes(user));

      store.dispatch(start());
      fetch(`${url}${apiUrl}`, {
        method: 'PUT',
        body: JSON.stringify({
          userList,
          newAssignedUsers,
          removedFromAssignee,
          userData,
        }),
      })
        .then((res) => res.json())
        .then((json) => {
          store.dispatch(success(id, json, { initList, userList }));
          this.setState({
            initUsers: activeUsers,
            filteredUsers: [],
            searchVal: '',
          });
        })
        .catch((err) => store.dispatch(error()));
    } else {
      this.setState({
        filteredUsers: [],
        searchVal: '',
      });
    }
    if (onClickOK) {
      await onClickOK();
    }
  };

  handleCancel = () => {
    const {
      closeModal,
    } = this.props;

    const {
      initUsers,
    } = this.state;

    this.setState({
      activeUsers: initUsers,
      filteredUsers: [],
      searchVal: '',
    });

    closeModal();
  }

  filterList = (searchVal) => {
    const {
      availableUsers,
      searchKey,
    } = this.state;

    const filteredUsers = availableUsers.filter((user) => {
      const text = user[searchKey];

      if (!text) {
        return false;
      }

      return text.toLowerCase().includes(searchVal.toLowerCase());
    });

    this.setState({
      searchVal,
      filteredUsers,
    });
  };

  allowPrevent = (event) => {
    const {
      usePrevent,
    } = this.props;

    if (usePrevent) {
      preventPropagation(event);
    }
  };

  render() {
    const {
      data: {
        addUser,
      },
      modalVisible,
      className,
    } = this.props;

    const {
      activeUsers,
      availableUsers,
      filteredUsers,
      key,
      searchVal,
    } = this.state;

    const activeId = activeUsers.map((user) => user[key]);

    return (
      <Modal
        title="Assign user"
        visible={modalVisible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        wrapClassName={
          classNames(
            'add-user-modal',
            {
              [className]: className,
            },
          )
        }
      >
        {addUser && (
          <Row>
            <Col>
              <AddUserForm
                action={addUser}
                availableUsers={availableUsers}
              />
            </Col>
          </Row>
        )}

        <Row>
          <Col>
            <Search
              className="add-user-modal__search"
              placeholder="Enter user name"
              onSearch={this.filterList}
              onChange={(event) => ( // eslint-disable-line react/jsx-no-bind
                this.filterList(event.target.value)
              )}
              value={searchVal}
            />
          </Col>
        </Row>

        <List
          className={classNames(
            'assign-list', 'user-list', 'assign-list__modal',
            {
              'user-list--add': addUser,
            },
          )}
          grid={{ column: 2 }}
          dataSource={
            filteredUsers.length || (!filteredUsers.length && searchVal)
              ? filteredUsers
              : availableUsers
          }
          renderItem={(user) => ( // eslint-disable-line react/jsx-no-bind
            <Item className="assign-list__item">
              <button
                type="button"
                className="assign-list__card"
                style={{
                  border: activeId.includes(user[key])
                    ? '1px solid chartreuse'
                    : null,
                  backgroundColor: activeId.includes(user[key])
                    ? '#e8f5e9'
                    : null,
                }}
                onClick={this.addUser(user)}
              >
                <AvatarLogoComponent
                  userId={user.EmployeeID}
                  userType={1}
                  size="xs"
                />
                <div className="card-content">
                  <div className="user-info__name">
                    {user.Name}
                    {' '}
                    {user.Surname}
                  </div>
                  <div className="user-info__status">{user.Role}</div>
                </div>
              </button>
            </Item>
          )}
        />
      </Modal>
    );
  }
}

AssignModal.defaultProps = {
  onClickOK: null,
  usePrevent: false,
  usersLimit: 0,
  modalVisible: false,
  className: null,
};

const {
  bool,
  number,
  shape,
  arrayOf,
  func,
  string,
} = PropTypes;

AssignModal.propTypes = {
  // limit selected users. set 1 to single select
  usersLimit: number,
  className: string,
  data: shape({
    userData: shape({
      EmployeeID: string,
      reminderID: string,
    }),
    data: shape({
      activeUsers: arrayOf(number),
      availableUsers: arrayOf(number),
      key: string,
      id: number,
    }),
    config: shape({
      apiUrl: string,
      error: func,
      hook: func,
      normal: bool,
      save: func,
      start: func,
      success: func,
    }),
    addUser: func,
  }).isRequired,
  onClickOK: func,
  closeModal: func.isRequired,
  usePrevent: bool,
  modalVisible: bool,
};

export default AssignModal;
