/* eslint-disable react/jsx-no-bind */
import React, {
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { timeDate } from '@symplete/symplete-utils/datetime';

import useShowing from 'sections/Showings/hooks/useShowing';

import Button from 'ui/Button';
import MenuItem from 'ui/MenuItem';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import DateTimeInput from 'shared/inputs/DateTimeInput';

import useModal from 'ui/hooks/useModal';
import useMenu from 'ui/hooks/useMenu';

import { Wrapper, ButtonWrapper } from './styledItems';

const ShowingTime = (props) => {
  const {
    showingId,
    hideOptions,
    cancelShowing,
    matchingProperty,
    rescheduleShowing,
    onClick,
  } = props;

  const anchorEl = useRef(null);

  const [dateTime, setDateTime] = useState(null);

  const {
    openMenu,
    closeMenu,
    Menu,
  } = useMenu(anchorEl);

  const {
    Modal,
    openModal,
    closeModal,
    isModalVisible,
  } = useModal();

  const showing = useShowing(showingId);

  const handleCancelShowing = () => {
    cancelShowing(matchingProperty.id);
    closeMenu();
  };

  const handleMenuClick = () => {
    openModal();
    closeMenu();
  };

  const handleRescheduleShowing = () => {
    rescheduleShowing(dateTime);
    closeMenu();
  };

  const scheduleText = showing?.date_time ? 'Reschedule' : 'Schedule';

  return (
    <Wrapper>
      <Button
        variant="flat"
        dense
        onClick={onClick}
        fullWidth
        color="focus"
        icon="calendar"
        iconColor="focus"
        centered
      >
        {
          showing?.date_time
          ? timeDate(showing?.date_time)
          : 'TBA'
        }
      </Button>

      {
        !hideOptions
          ? (
            <ButtonWrapper>
              <Button
                icon="more-vertical"
                iconSize="s"
                variant="tertiary"
                dense
                buttonRef={anchorEl}
                onClick={openMenu}
              />
            </ButtonWrapper>
          ) : null
      }

      <Menu>
        <MenuItem onClick={handleMenuClick}>
          {`${scheduleText} showing`}
        </MenuItem>
        <MenuItem onClick={handleCancelShowing}>
          Cancel Showing
        </MenuItem>
      </Menu>
      <Modal
        visible={isModalVisible}
        onCancel={closeModal}
        footer={[
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>,
          <Button variant="primary" onClick={handleRescheduleShowing}>
            Save
          </Button>,
        ]}
        title={`${scheduleText} showing`}
      >
        <Flex>
          <FlexItem fullWidth>
            <DateTimeInput
              value={dateTime}
              label="New date and time"
              handleChange={setDateTime}
              disablePast
            />
          </FlexItem>
        </Flex>
      </Modal>
    </Wrapper>
  );
};

ShowingTime.defaultProps = {
  onClick: null,
  cancelShowing: null,
  rescheduleShowing: null,
  matchingProperty: {},
  hideOptions: false,
};

const {
  func,
  number,
  shape,
  bool,
} = PropTypes;

ShowingTime.propTypes = {
  onClick: func,
  showingId: number.isRequired,
  cancelShowing: func,
  rescheduleShowing: func,
  matchingProperty: shape({}),
  hideOptions: bool,
};

export default ShowingTime;
