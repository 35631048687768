import React from 'react';

import withPlayer from 'shared/hocs/withPlayer';

import PlayerItemV2 from './PlayerItemV2';

const Item = (props) => {
  const {
    name,
    surname,
    personTitle,
  } = props;

  const surnameFirstLetter = surname ? surname.split('')[0] || '' : '';

  return (
    <PlayerItemV2
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      name={`${personTitle} ${name} ${surnameFirstLetter}${surnameFirstLetter ? '.' : ''}`.trim()}
    />
  );
};

export default withPlayer(Item);
