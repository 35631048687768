import { connect } from 'react-redux';

import selectors from 'store/selectors';

import actions from 'sections/Prospects/actions';
import showingActions from 'sections/Showings/actions';

import { openSearchMLSModal } from 'store/actions/searchMLSModal';

import {
  createMatchingProperty,
  resetMatchingPropertyFilters,
} from 'store/actions/realtorMatchingProperties';

// viewings
import {
  resetNewPropertyViewingForm,
} from 'store/actions/newRealtorPropertyViewingForm';

// match rejection
import {
  openRejectionModal,
} from 'store/actions/realtorMatchingPropertyRejectionModal';

import {
  closeNewPropertyViewingModal,
} from 'store/actions/newRealtorPropertyViewingModal';

import { getProspectById } from 'store/actions/realtorProspects';

import MatchingPropertiesView from './MatchingPropertiesView';

const getViewingType = (lane) => {
  if (lane === 'showingsUpcoming') return 'upcoming';
  if (lane === 'showingsCompleted') return 'completed';
  return null;
};

const mapStateToProps = (state, ownProps) => {
  const {
    prospectId,
  } = ownProps;

  const {
    newPropertyViewingForm,
  } = state;

  const prospect = selectors.realtorProspects.byId(state, prospectId);
  const getMatchingProperty = (matchId) => selectors.realtorMatchingProperties.byId(matchId)(state);

  return {
    prospectId,
    prospectContactId: prospect?.contact_id,
    getMatchingProperty,

    newPropertyViewingForm,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAssignProperty: () => {
    dispatch(openSearchMLSModal());
  },

  onRejectProperty: ({ matchId, onSave }) => {
    dispatch(openRejectionModal(matchId, onSave));
  },

  closeNewPropertyViewingModal: () => {
    dispatch(closeNewPropertyViewingModal());
    dispatch(resetNewPropertyViewingForm());
  },

  createMatchingProperty: async (matchingPropertyData) => {
    await dispatch(createMatchingProperty(matchingPropertyData));
    await dispatch(getProspectById(ownProps.prospectId));
    dispatch(resetMatchingPropertyFilters());
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,

  updateMatchingProperty: (matchId) => async (updateData, currentData, boardData) => {
    if (currentData?.status < updateData?.status) {
      if (updateData?.status === 2) { // -> viewings
        const {
          realtor_property_id: propertyId,
        } = stateProps.getMatchingProperty(matchId);

        const viewingType = getViewingType(boardData?.destination?.droppableId);

        showingActions.onCreateShowing({
          contactId: stateProps.prospectContactId,
          propertyId,
          onSave: () => actions.kanban.updateMatchingProperty(matchId, ownProps.prospectId)(updateData),
          type: viewingType,
        });
      }

      if (updateData?.status === 5) { // -> rejected
        dispatchProps.onRejectProperty({
          matchId,
          onSave: () => actions.kanban.updateMatchingProperty(matchId, ownProps.prospectId)(updateData),
        });
      }
    }
  },

  onCreateShowing: (type) => () => {
    const { prospectContactId } = stateProps;

    showingActions.onCreateShowing({
      contactId: prospectContactId,
      type,
      onSave: (showingData) => {
        dispatchProps.createMatchingProperty({
          realtor_contact_id: prospectContactId,
          realtor_property_id: showingData.property_id,
          status: 2,
        });

        dispatchProps.closeNewPropertyViewingModal();
      },
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(MatchingPropertiesView);
