import React, { memo } from 'react';

import TextEditor from 'ui/TextEditor';

import Flex from 'ui/Flex';

const Editor = (props) => (
  <Flex
    fullWidth
    fullHeight
    flexDirection="column"
  >
    <TextEditor.Content />
    <TextEditor.Toolbar>
      {props.children}
    </TextEditor.Toolbar>
  </Flex>
  );

export default memo(Editor);
