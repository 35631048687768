import styled from 'styled-components';
import { lighten, darken } from 'polished';

import Typography from 'ui/Typography';

export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 10px 8px;
  border-radius: 10px;
  background-color: ${({
    theme,
    color,
  }) => lighten(0.3, theme?.palette?.[color] || 'grey')
  };
`;

export const StyledTypography = styled(Typography)`
  color: ${({
    theme,
    color,
  }) => darken(0.3, theme?.palette?.[color] || 'grey')};
`;
