import { connect } from 'react-redux';

import {
  updateClient,
  deleteClient,
} from 'store/actions/realtorClients';

import {
  updateProspect,
  deleteProspect,
} from 'store/actions/realtorProspects';

import { // eslint-disable-line import/no-cycle
  optimizedOpportunityActions,
} from 'store/actions/realtorOpportunities';

import ArchiveDeleteModal from './ArchiveDeleteOpportunityModal';

const mapDispatchToProps = (dispatch) => ({
  onArchive: async (itemType, itemId) => {
    if (itemType === 'client') {
      await dispatch(updateClient(itemId)({ status: 2 }));
      return;
    }
    if (itemType === 'prospect') {
      await dispatch(updateProspect(itemId)({ status: 2 }));
    }
  },
  onDelete: async (itemType, itemId) => {
    if (itemType === 'client') {
      await dispatch(deleteClient(itemId));
      return;
    }
    if (itemType === 'prospect') {
      await dispatch(deleteProspect(itemId));
    }
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  onCancel: ownProps.closeModal,
  onSave: (selectedAction) => async () => {
    const {
      itemId,
      itemType,
    } = ownProps;

    if (selectedAction === 'archive') {
      await dispatchProps.onArchive(itemType, itemId);
    }

    if (selectedAction === 'delete') {
      await dispatchProps.onDelete(itemType, itemId);
    }

    optimizedOpportunityActions.getOpportunitiesForStoreFilter();

    ownProps.closeModal();
  },
});

export default connect(null, mapDispatchToProps, mergeProps)(ArchiveDeleteModal);
