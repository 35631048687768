import React, {
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import moment from 'moment';

import { NakedButton } from '@symplete/symplete-ui';
import Typography from 'ui/Typography';

import { Wrapper } from './styledItems';

const DateCell = (props) => {
  const {
    date,
    onChangeDate,
  } = props;

  const [isEditMode, setEditMode] = useState(false);
  const [stateDate, setDate] = useState(null);

  const changeDate = useCallback((newDate) => {
    setDate(newDate);
  }, []);

  const openEditModal = useCallback(() => {
    setEditMode(true);
  }, []);

  const saveNewDate = useCallback(() => {
    setEditMode(false);

    if (stateDate) {
      onChangeDate(stateDate);
      setDate(null);
    }
  }, [
    stateDate,
    date,
  ]);

  return (
    <>
      <NakedButton onClick={openEditModal} style={{ margin: '0' }}>
        {
          date ? (
            <>
              <Typography
                variant="body"
                weight={400}
              >
                {moment(date).format('MMMM D, YYYY')}
              </Typography>
              <Typography
                style={{ display: 'flex' }}
                variant="body"
                weight={500}
                noMargin
              >
                {moment(date).format('h:mm a')}
              </Typography>
            </>
          ) : (
            <Typography
              variant="body"
              weight={400}
            >
              —
            </Typography>
          )
        }
      </NakedButton>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Wrapper>
          <DateTimePicker
            value={date}
            onChange={changeDate}
            onClose={saveNewDate}
            open={isEditMode}
            showTodayButton
            minutesStep={5}
            label="Set new Date"
            margin="dense"
            inputVariant="outlined"
            variant="inline"
          />
        </Wrapper>
      </MuiPickersUtilsProvider>
    </>
  );
};

DateCell.defaultProps = {
  onChangeDate: () => {},
};

const {
  string,
  func,
} = PropTypes;

DateCell.propTypes = {
  date: string.isRequired,
  onChangeDate: func,
};

export default DateCell;
