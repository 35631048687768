import { connect } from 'react-redux';
import {
  toDoListDrawerHideAction,
  toDoListSettingsHideAction,
  toDoListSettingsShowAction,
} from 'store/actions/to-do-list';
import ToDoListHeader from './ToDoListHeader';

const mapStateToProps = (state) => ({
  toDoListSettingsIsVisible: state.todolist.toDoListSettingsIsVisible,
});

const mapDispatchToProps = {
  toDoListDrawerHideAction,
  toDoListSettingsHideAction,
  toDoListSettingsShowAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ToDoListHeader);
