import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import Typography from 'ui/Typography';

const NextViewing = (props) => {
  const {
    dateTime,
  } = props;

  if (!dateTime) {
    return (
      <Typography variant="cell">
        —
      </Typography>
    );
  }

  const isDateToday = moment().isSame(dateTime, 'day');
  const isDateTomorrow = moment().add(1, 'days').isSame(dateTime, 'day');

  const getDateText = useCallback(() => {
    if (isDateToday) {
      return 'Today';
    }
    if (isDateTomorrow) {
      return 'Tomorrow';
    }
    return moment(dateTime).format('ddd, MMM D');
  }, [
    dateTime,
    isDateToday,
    isDateTomorrow,
  ]);

  const getTimeText = useCallback(() => moment(dateTime).format('hh:mmA'), [
    dateTime,
  ]);

  return (
    <Typography variant="cell">
      <Typography
        tag="span"
        weight={isDateToday || isDateTomorrow ? 600 : 400}
      >
        {getDateText()}
      </Typography>
      {' '}
      <Typography tag="span">
        {getTimeText()}
      </Typography>
    </Typography>
  );
};

NextViewing.defaultProps = {
  dateTime: null,
};

const {
  string,
} = PropTypes;

NextViewing.propTypes = {
  dateTime: string,
};

export default NextViewing;
