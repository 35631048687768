import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  getOverallLeadsSummary,
  getLeadsForStoreFilter,
} from 'store/actions/realtorLeads';

import {
  clearFilter,
  handleCustomFilter,
} from 'store/actions/realtorLeadsFilter';

import KPIBar from 'ui/KPIBar';

const mapStateToProps = (state) => {
  const summary = selectors.realtorLeads.overallSummary(state);
  const filterKey = selectors.realtorLeads.leadsFilterKey(state);

  return {
    values: {
      all: summary?.total || 0,
      new: summary?.new || 0,
      buyers: summary?.buyers || 0,
      sellers: summary?.sellers || 0,
      converted_this_week: summary?.converted_this_week || 0,
    },
    indicators: [
      {
        key: 'all',
        title: 'Total',
        iconName: 'users',
        flex: 1,
        selected: filterKey === 'all',
      },
      {
        key: 'new',
        title: 'New Leads',
        iconName: 'user-plus',
        flex: 1,
        selected: filterKey === 'new',
      },
      {
        key: 'buyers',
        title: 'Buyers',
        iconName: 'investment',
        iconType: 'custom',
        flex: 1,
        selected: filterKey === 'buyers',
      },
      {
        key: 'sellers',
        title: 'Sellers',
        iconName: 'briefcase',
        flex: 1,
        selected: filterKey === 'sellers',
      },
      {
        key: 'converted_this_week',
        title: 'Converted This Week',
        iconName: 'repeat',
        flex: 1.5,
        disabled: true,
      },
    ],
  };
};

const mapDispatchToProps = (dispatch) => ({
  getData: getOverallLeadsSummary,
  onClick: (key) => () => {
    dispatch(clearFilter());
    dispatch(handleCustomFilter(key));
    dispatch(getLeadsForStoreFilter());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(KPIBar);
