import { connect } from 'react-redux';

import selectors from 'store/selectors';

import actions from 'sections/Prospects/actions';

import NewOfferModal from 'sections/Offers/modals/NewOfferModal';

const mapStateToProps = (state, ownProps) => {
  const {
    realtor_property_id: propertyId,
    realtor_contact_id: contactId,
  } = selectors.realtorMatchingProperties.byId(ownProps.matchId)(state);

  const property = selectors.properties.byId(state, propertyId);

  return {
    propertyId,

    buyer: contactId,
    buyerAgent: 0,
    seller: property?.seller,
    sellerAgent: property?.listing_agent,

    representing: 'buyer',

    prospectId: ownProps.prospectId,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSuccess: () => {
    actions.kanban.updateMatchingProperty(ownProps.matchId, ownProps.prospectId)({ status: 3 });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewOfferModal);
