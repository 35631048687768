import { connect } from 'react-redux';

import selectors from 'store/selectors';

import Sidebar from './Sidebar';

const mapStateToProps = (state) => {
  const client = selectors.realtorClients.selected(state);
  const property = selectors.properties.byId(state, client.realtor_property_id);
  const address = selectors.addresses.byId(state, property.address_id);

  return {
    propertyId: client.realtor_property_id,
    address: address?.address,
    price: client.listing_price,
  };
};

export default connect(mapStateToProps)(Sidebar);
