import React, { useState, useCallback } from 'react';

import Button from 'ui/Button';
import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import PhoneNumberInput from 'ui/PhoneNumberInput';

import usePhoneValidationCode from 'sections/Calls/hooks/usePhoneValidationCode';

const OutgoingNumberValidator = () => {

  const [phoneNumber, setPhoneNumber] = useState('');

  const onPhoneChange = useCallback((phone) => {
    setPhoneNumber(phone);
  }, []);

  const {
    code,
    getCode,
    isLoading,
    verifiedStatus,
    getVerification,
  } = usePhoneValidationCode(phoneNumber);

  const VerifiedComponent = () => {
    switch(verifiedStatus) {
      case 1:
        return (
          <Typography tag="p" style={{ marginTop: '1rem'}} color="success">
            Your phone, {phoneNumber} is verified. You are able to use through Symplete.
          </Typography>
        );
      case 2:
        return (
          <>
            <Typography tag="p" style={{ marginTop: '1rem'}} color="warning">
              Your phone, {phoneNumber} is still not verified. You must procede with
              the confirmation.
            </Typography>
            <Button
              style={{ alignSelf: 'flex-end' }}
              variant="primary"
              onClick={getVerification}
            >
              Check again
            </Button>
          </>
        );
      default:
        return (
          <>
            <Typography tag="p" style={{ marginTop: '1rem'}}>
              Once you enter 6 digits into your phone, you can check with the following
              button, if it is already verified.
            </Typography>
            <Button
              style={{ alignSelf: 'flex-end' }}
              variant="primary"
              onClick={getVerification}
            >
              Check if {phoneNumber} is verified
            </Button>
          </>
        );
    }
  }

  return (
    <section>
      <Typography>
        We need to validate you phone number to enable in-browser phone calls.
      </Typography>
      <Typography>You are going to receive a call to do that.</Typography>
      <Typography>
        Please enter the 6-digit code on that will be shown here on your phone
        when asked to do so.
      </Typography>
      {
        !code && !isLoading && (
          <Flex flexDirection="column" spacing={2}>
            <Typography noMargin>Your mobile number:</Typography>
            <PhoneNumberInput
              style={{ width: '100%' }}
              value={phoneNumber}
              onChange={onPhoneChange}
            />
            <Button
              variant="primary"
              onClick={getCode}
              style={{ alignSelf: 'flex-end' }}
            >
              Request confirmation call
            </Button>
          </Flex>
        )
      }
      {
        code && !isLoading && (
          <>
            <Typography noMargin>
              Your confirmation code:
            </Typography>
            <Typography
              variant="title2"
              tag="p"
            >
              {code}
            </Typography>
            <Button
              style={{ alignSelf: 'flex-end' }}
              variant="primary"
              onClick={getCode}
            >
              Request another confirmation call
            </Button>
            <VerifiedComponent />
          </>
        )
      }
      {
        isLoading && (
          <Typography variant="title2" tag="p">
            Loading...
          </Typography>
        )
      }
    </section>
  );
};

export default OutgoingNumberValidator;
