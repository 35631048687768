import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import Button from 'ui/Button';
import Flex from 'ui/Flex';

import {
  Root,
} from './styledItems';

const LaneHeader = (props) => {
  const {
    title,
    onAddClick,
  } = props;

  return (
    <Root>
      <Flex
        justifyContent="space-between"
        fullWidth
        alignItems="center"
      >
        <Typography
          variant="title2"
          style={{
            marginLeft: 24,
          }}
          noMargin
        >
          {title}
        </Typography>

        {
          onAddClick && (
            <Button onClick={onAddClick}>
              +
            </Button>
          )
        }
      </Flex>
    </Root>
  );
};

LaneHeader.defaultProps = {
  onAddClick: null,
  title: null,
};

const {
  func,
  string,
} = PropTypes;

LaneHeader.propTypes = {
  title: string,
  onAddClick: func,
};

export default LaneHeader;
