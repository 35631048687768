import { connect } from 'react-redux';

import stableStringify from 'json-stable-stringify';

import datetime from '@symplete/symplete-utils/datetime';

import selectors from 'store/selectors';

import {
  optimizedPropertyViewingActions,
} from 'store/actions/propertyViewings';

import { // eslint-disable-line import/no-cycle
  optimizedRealtorTransactionsActions,
} from 'store/actions/realtorTransactions';

import {
  optimizedFollowUpRemindersActions,
} from 'store/actions/followUpReminders';

import {
  openModal,
} from 'store/actions/modals';

import getFilterByToday from 'shared/utils/getFilterByToday';

import formats from 'shared/formats';

import ScheduleTable from './ScheduleTable';

const formatDateTime = (date) => datetime(date).format(formats.dateTime.full);

const filters = {
  showingsFilter: {
    contact_type: 'not prospects',
  },
  pitchesFilter: {
    contact_type: 'prospects',
  },
  closingEscrowsFilter: {
    no_offset: true,
    date_fields: [
      'close_escrow_expires_at',
    ],
  },
  contingenciesDueFilter: {
    no_offset: true,
    date_fields: [
      'initial_documents_expires_at',
      'seller_disclosure_expires_at',
      'inspections_expires_at',
      'appraisal_expires_at',
      'loan_expires_at',
      'final_walkthrough_expires_at',
      'move_out_date_expires_at',
    ],
  },
  followUpRemindersFilter: {
  },
};

const getFilter = (filterName, date) => ({
    ...filters[filterName],
    ...getFilterByToday(date),
  });

const mapStateToProps = (state, ownProps) => {
  const {
    selectedFilterKeys = ['viewings', 'transactionItems', 'followUpReminders'],
    selectedDate,
  } = ownProps;

  const showingsFilter = getFilter('showingsFilter', selectedDate);
  const pitchesFilter = getFilter('pitchesFilter', selectedDate);
  const closingEscrowsFilter = getFilter('closingEscrowsFilter', selectedDate);
  const contingenciesDueFilter = getFilter('contingenciesDueFilter', selectedDate);
  const followUpRemindersFilter = getFilter('followUpRemindersFilter', selectedDate);

  const closingEscrows = selectors.realtorTransactions.todayContingenciesTransactionsList(
    state,
    stableStringify(closingEscrowsFilter),
    closingEscrowsFilter.date_fields,
    selectedDate,
  );

  const showings = selectors.propertyViewings.todayViewings(state, stableStringify(showingsFilter), 'Showing');
  const listingPitches = selectors.propertyViewings.todayViewings(state, stableStringify(pitchesFilter), 'Pitches');

  const contingenciesDue = selectors.realtorTransactions.todayContingenciesTransactionsList(
    state,
    stableStringify(contingenciesDueFilter),
    contingenciesDueFilter.date_fields,
    selectedDate,
  );

  const followUpReminders = selectors.followUpReminders.todayReminders(state, stableStringify(followUpRemindersFilter));

  let todayData = [];

  if (selectedFilterKeys.includes('viewings')) {
    todayData = todayData.concat(showings, listingPitches);
  }
  if (selectedFilterKeys.includes('transactionItems')) {
    todayData = todayData.concat(closingEscrows, contingenciesDue);
  }
  if (selectedFilterKeys.includes('followUpReminders')) {
    todayData = todayData.concat(followUpReminders);
  }

  const scheduleData = todayData.reduce((acc, item) => {
    const startOfHour = datetime(item.date).startOf('hour');
    acc[formatDateTime(startOfHour)] = acc[formatDateTime(startOfHour)]
      ? (
          [...acc[formatDateTime(startOfHour)], item]
        )
      : (
          [item]
        );

    return acc;
  }, {});

  return {
    scheduleData,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getShowings: (date) => {
    const showingsFilter = getFilter('showingsFilter', date);
    optimizedPropertyViewingActions.getViewingsForFilter(stableStringify(showingsFilter), showingsFilter);
  },

  getPitches: (date) => {
    const pitchesFilter = getFilter('pitchesFilter', date);
    optimizedPropertyViewingActions.getViewingsForFilter(stableStringify(pitchesFilter), pitchesFilter);
  },

  getClosingEscrow: (date) => {
    const closingEscrowsFilter = getFilter('closingEscrowsFilter', date);

    optimizedRealtorTransactionsActions.getTransactionsForFilter(
      stableStringify(closingEscrowsFilter),
      closingEscrowsFilter,
    );
  },

  getContingenciesDue: (date) => {
    const contingenciesDueFilter = getFilter('contingenciesDueFilter', date);

    optimizedRealtorTransactionsActions.getTransactionsForFilter(
      stableStringify(contingenciesDueFilter),
      contingenciesDueFilter,
    );
  },

  getFollowUpReminders: (date) => {
    const remindersFilter = getFilter('followUpReminders', date);

    optimizedFollowUpRemindersActions.getFollowUpRemindersForFilter(
      stableStringify(remindersFilter),
      remindersFilter,
    );
  },

  openAddEventModal: () => {
    dispatch(openModal('newCalendarEntry')({
      modalProps: {
        date: datetime(ownProps.selectedDate),
      },
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleTable);
