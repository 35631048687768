import { connect } from 'react-redux';

import moment from 'moment';

import selectors from 'store/selectors';

import ViewingsTable from 'sections/Opportunities/components/ViewingsTable';

import columns from 'sections/Prospects/components/ViewingsTable/columns';

const getFutureViewings = (items = []) => {
  if (!Array.isArray(items)) {
    return [];
  }
  const viewings = items.filter(({ date_time }) => (
    moment(date_time).isSameOrAfter(moment().add(1, 'days'), 'day')
  ))
    .sort((a, b) => moment(a.date_time).valueOf() - moment(b.date_time).valueOf());

  return viewings;
};

const mapStateToProps = (state) => {
  const prospect = selectors.realtorProspects.selected(state);

  const { events: prospectViewings } = selectors.realtorProspects.prospectViewings(state, prospect?.id);

  return {
    tableKey: 'future_viewings',
    filterViewings: getFutureViewings,
    allViewings: prospectViewings,
    getColumns: columns,
  };
};

export default connect(mapStateToProps)(ViewingsTable);
