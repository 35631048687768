import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updatePropertyRequirement,
} from 'store/actions/propertyRequirements';

import YearBuiltSelect from 'sections/Leads/inputs/YearBuiltSelect';

const mapStateToProps = (state, ownProps) => {
  const propertyRequirementData = selectors.propertyRequirements.byId(state, ownProps.itemId);

  return {
    rangeSelect: true,
    minValue: propertyRequirementData?.year_built_min,
    maxValue: propertyRequirementData?.year_built_max,

  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleChangeMin: (value) => {
    dispatch(updatePropertyRequirement(ownProps.itemId)({ year_built_min: value }));
  },
  handleChangeMax: (value) => {
    dispatch(updatePropertyRequirement(ownProps.itemId)({ year_built_max: value }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(YearBuiltSelect);
