import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  history,
} from 'store';

import {
  updateProspect,
  deleteProspect,
} from 'store/actions/realtorProspects';

import Options from './Options';

const mapStateToProps = (state, ownProps) => {
  const prospect = selectors.realtorProspects.byId(state, ownProps.prospectId);

  return {
    status: prospect.status,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onStatusChange: (status) => {
    dispatch(updateProspect(ownProps.prospectId)({ status }));
  },
  onDelete: () => {
    dispatch(deleteProspect(ownProps.prospectId));
    history.push('/clients');
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Options);
