/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
// TODO Rewrite as a functional component

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Textfield from 'ui/Textfield';
import InlineInput from 'ui/InlineInput';

import { debounce } from 'shared/utility';

import { getPlacePredictions } from 'shared/utils';
import requests from 'api/requests';

import Option from './Option';

import './PlacesAutocomplete.scss';

const rootClass = 'places-autocomplete';

/* eslint-disable react/prop-types */
const InlineInputComponent = React.forwardRef((props, ref) => {
  const {
 defaultValue, onChange, placeholder, error, inputProps,
} = props;

  const [value, setValue] = React.useState(null);

  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = React.useCallback(
    (event) => {
      onChange(event.target.value);
      setValue(event.target.value);
    },
    [onChange],
  );

  return (
    <div ref={ref}>
      <InlineInput
        {...inputProps} // eslint-disable-line react/jsx-props-no-spreading
        onChange={handleChange}
        value={value}
        placeholder={placeholder}
        style={{ width: '100%' }}
        error={error}
      />
    </div>
  );
});
/* eslint-enable react/prop-types */

class PlacesAutocomplete extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      selectedValue: props.selectedValue,
      search: props.selectedValue,
    };

    this.placesService = null;
    this.search = debounce(this.searchPlaces, 300);
  }

  componentDidMount() {
    // eslint-disable-next-line no-undef
    this.placesService = new google.maps.places.AutocompleteService(
      document.createElement('div'),
    );
  }

  componentDidUpdate(prevProps) {
    const { selectedValue } = this.props;
    if (selectedValue && selectedValue !== prevProps.selectedValue) {
      this.setState({
        dataSource: [],
        selectedValue,
        search: selectedValue,
      });
    }
  }

  onSearch = (value) => {
    // this serves to enable adding arbitrary addresses alongside google search results
    this.setState((prev) => {
      const { dataSource } = prev;

      dataSource[0] = {
        formatted_address: value,
        newItem: true,
      };

      return {
        search: value,
        dataSource,
      };
    });
    //

    this.search(value);

    const { onSearch } = this.props;

    if (onSearch) {
      onSearch(value);
    }
  };

  onSelect = async (event, newLocation) => {
    const formattedAddress = await (async () => {
      if (!newLocation) return '';
      const result = await requests.addresses.getPostalCode(newLocation.id);
      return result || newLocation.formatted_address;
    })();

    this.setState({
      search: formattedAddress,
      selectedValue: newLocation || {
        id: '',
        formatted_address: '',
        lat: '',
        lng: '',
      },
    });

    const { onSelect: propOnSelect } = this.props;

    if (propOnSelect) {
      propOnSelect({ ...newLocation, formatted_address: formattedAddress });
    }
  };

  onChange = (value) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  };

  onBlur = () => {
    const {
      onBlur: propsOnBlur,
      reset,
    } = this.props;

    const { selectedValue } = this.state;

    if (propsOnBlur) {
      propsOnBlur(selectedValue);
    }

    if (reset) {
      this.setState({
        selectedValue: {
          id: '',
          formatted_address: '',
          lat: '',
          lng: '',
        },
        dataSource: [],
      });
    }

    const { selectedValue: search } = this.props;

    this.setState({ search });
  };

  searchPlaces = (value) => {
    const request = {
      input: value,
    };

    getPlacePredictions(
      this.placesService,
      request,
      (results) => {
        this.setState((prev) => {
          const dataSource = [{
            formatted_address: prev.search,
            newItem: true,
          }];

          results.forEach((result) => {
            const {
              description, place_id: placeId, structured_formatting: structFormat,
            } = result;
            dataSource.push({
              formatted_address: description,
              id: placeId,
              main_text: structFormat.main_text,
              secondary_text: structFormat.secondary_text,
            });
          });

          dataSource.push({ lastRow: true, formatted_address: 'powered by Google' });

          return { ...prev, dataSource };
        });
      },
    );
  };

  handleInput = (value) => {
    const { onInput } = this.props;

    this.onSearch(value);
    // this.handleSearchDebounce(value);

    if (onInput) {
      onInput(value);

      this.setState({
        selectedValue: {
          id: '',
          formatted_address: value,
          lat: '',
          lng: '',
        },
      });
    }
  };

  renderInput = (params) => {
    const {
      label,
      placeholder,
      focusOnRender,
      inline,
      defaultValue,
      error,
      required,
    } = this.props;

    const { search } = this.state;

    if (inline) {
      return (
        <InlineInputComponent
          {...params} // eslint-disable-line react/jsx-props-no-spreading
          ref={params.InputProps.ref}
          placeholder={placeholder}
          type="text"
          onChange={this.handleInput}
          defaultValue={defaultValue}
          error={error}
        />
      );
    }

    return (
      <Textfield
        {...params} // eslint-disable-line react/jsx-props-no-spreading
        ref={params.InputProps.ref}
        label={search && search?.length > 0 ? label : placeholder}
        onChange={this.handleInput}
        icon="map-pin"
        focusOnRender={focusOnRender}
        error={error}
        required={required}
      />
    );
  };

  getOptionLabel = (option) => option.formatted_address || '';

  getOptionDisabled = (option) => option.lastRow;

  render() {
    const { className, size } = this.props;

    const { search } = this.state;

    const { dataSource } = this.state;

    return (
      <div
        className={classnames(rootClass, {
          [className]: className,
        })}
      >
        <Autocomplete
          freeSolo
          autoComplete
          filterSelectedOptions
          onChange={this.onSelect}
          onBlur={this.onBlur}
          options={dataSource}
          getOptionLabel={this.getOptionLabel}
          renderInput={this.renderInput}
          value={{ formatted_address: search || null }}
          defaultValue={{ formatted_address: search || null }}
          filterOptions={(nonFiltered, params) => nonFiltered} // eslint-disable-line react/jsx-no-bind
          renderOption={Option}
          getOptionDisabled={this.getOptionDisabled}
          size={size}
        />
      </div>
    );
  }
}

PlacesAutocomplete.defaultProps = {
  autoFocus: false,
  className: '',
  createOnBlur: false,
  defaultValue: null,
  error: null,
  focusOnRender: null,
  inline: false,
  inputClassName: '',
  label: '',
  onInput: undefined,
  placeholder: '',
  required: false,
  selectedValue: {
    id: '',
    formatted_address: '',
    lat: '',
    lng: '',
  },
  size: 'medium',
  value: '',
  variant: 'outlined',
};

const {
 bool, func, shape, string, oneOfType,
} = PropTypes;

PlacesAutocomplete.propTypes = {
  autoFocus: bool,
  className: string,
  createOnBlur: bool,
  defaultValue: string,
  error: oneOfType([PropTypes.null, string]),
  focusOnRender: func,
  inline: bool,
  inputClassName: string,
  label: string,
  onBlur: func.isRequired,
  onChange: func.isRequired,
  onInput: func,
  onSearch: func.isRequired,
  onSelect: func.isRequired,
  placeholder: string,
  required: bool,
  reset: func.isRequired,
  selectedValue: shape({
    id: string,
    formatted_address: string,
    lat: string,
    lng: string,
  }),
  size: string,
  value: string,
  variant: string,
};

export default PlacesAutocomplete;
