import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import { NakedButton } from '@symplete/symplete-ui';

import Typography from 'ui/Typography';
import Icon from 'ui/Icon';
import Textfield from 'ui/Textfield';
import Flex from 'ui/Flex';
import Tooltip from 'ui/Tooltip';
import ClickAwayListener from 'ui/ClickAwayListener';

import useEditMode from 'ui/hooks/useEditMode';

const BaseControl = (props) => {
  const {
    noImage,
    value,
    handleChange,
    customIcon,
    icon,
    description,
    title,
    iconColor,
    step,
    iconType,
    label,
    tooltip,

    defaultView,
  } = props;

  const onChangeValue = useCallback((newValue) => {
    handleChange(newValue);
  }, [
    handleChange,
  ]);

  const {
    mode,
    setEditMode,
    setDisplayMode,
  } = useEditMode(defaultView);

  const content = (
    <Flex
      alignItems="center"
      spacing={1.5}
      style={{
        background: '#F2F6FE',
        border: '1px solid #F4FAFF',
        borderRadius: '7px',
        padding: '6px 13px',
      }}
    >
      {
        !noImage && (
          <Icon
            name={customIcon || icon}
            color={iconColor}
            type={iconType}
            size="l"
          />
        )
      }

      {
        title && (
          <Typography
            variant="body"
            weight="bold"
            className="title-text"
            noMargin
          >
            {title}
          </Typography>
        )
      }
      <div>
        {
          mode === 'edit' && (
            <ClickAwayListener onClickAway={setDisplayMode}>
              <Textfield
                value={value}
                onChange={onChangeValue}
                min={0}
                autoWidth
                step={step}
                label={label}
                type="number"
                dense
              />
            </ClickAwayListener>
          )
        }
        {
          mode === 'display' && (
            <NakedButton onClick={setEditMode}>
              <Typography noMargin>
                {value || 0}
              </Typography>
            </NakedButton>
          )
        }
      </div>
      {
        description && (
          <Typography
            variant="body"
            className="description-text"
            noMargin
          >
            {description}
          </Typography>
        )
      }
    </Flex>
  );

  if (tooltip) {
    return (
      <Tooltip title={tooltip}>
        {content}
      </Tooltip>
    );
  }

  return (
    content
  );
};

BaseControl.defaultProps = {
  value: 0,
  handleChange: () => {},
  icon: 'bed',
  customIcon: null,
  iconColor: null,
  label: null,
  description: null,
  noImage: false,
  title: null,
  step: 1,
  iconType: 'feather',
  defaultView: 'edit',
  tooltip: null,
};

const {
  element,
  func,
  number,
  oneOf,
  oneOfType,
  bool,
  string,
} = PropTypes;

BaseControl.propTypes = {
  value: number,
  handleChange: func,
  label: string,
  noImage: bool,
  icon: oneOf([
    'bed',
    'home',
    'car',
    'bath',
    'chart-area',
  ]),
  customIcon: string,
  description: string,
  iconColor: string,
  title: string,
  step: number,
  iconType: string,
  defaultView: oneOf(['edit', 'display']),
  tooltip: oneOfType([string, element]),
};

export default BaseControl;
