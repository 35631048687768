import list from './list';
import send from './send';
import update from './update';
import message from './message';
import thread from './thread';
import threads from './threads';
import updateThread from './updateThread';

export default {
  list,
  send,
  update,
  message,
  thread,
  threads,
  updateThread,
};
