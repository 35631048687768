import {
  useState,
  useEffect,
  useCallback,
} from 'react';

import requests from 'api/requests';

const listCampaigns = (onSuccess, onError) => async (limit = 50, offset = 0) => {
  try {
    const result = await requests.integrations.emailCampaigns.list({ limit, offset });

    onSuccess(result?.data || {});
  } catch (error) {
    onError(error);
  }
};

const getCampaignsSummary = (onSuccess, onError) => async () => {
  try {
    const result = await requests.integrations.emailCampaigns.getSummary();

    onSuccess(result?.data || {});
  } catch (error) {
    onError(error);
  }
};

const createCampaign = (onSuccess, onError) => async (campaignData) => {
  try {
    const result = await requests.integrations.emailCampaigns.create(campaignData);

    onSuccess(result.data.id);
  } catch (error) {
    onError(error);
  }
};

const useCampaigns = (pagination = {}, filters = {}) => {
  const {
    limit = 50,
    offset = 0,
  } = pagination;

  const [campaignsList, setCampaignsList] = useState([]);
  const [campaignsTotal, setCampaignsTotal] = useState(0);
  const [campaignsSummary, setCampaignsSummary] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const [listError, setListError] = useState(null);
  const [createError, setCreateError] = useState(null);
  const [summaryError, setSummaryError] = useState(null);

  const updateCampaigns = ({ list = [], total = 0 }) => {
    setCampaignsList(list);
    setCampaignsTotal(total);
  };

  const list = async (limit, offset) => {
    setListError(null);
    setIsLoading(true);

    await listCampaigns(updateCampaigns, setListError)(limit, offset);

    setIsLoading(false);
  };

  useEffect(() => {
    list(limit, offset);
  }, [
    limit,
    offset,
  ]);

  const getSummary = async () => {
    await getCampaignsSummary(setCampaignsSummary, setSummaryError)();
  };

  useEffect(() => {
    getSummary();
  }, []);

  const create = useCallback(async (campaignData) => {
    setCreateError(null);
    setIsCreating(true);

    let newCampaignId = null;

    await createCampaign(
      (id) => {
        list(limit, offset);
        newCampaignId = id;
      },
      setCreateError,
    )(campaignData);

    setIsCreating(false);

    return newCampaignId;
  }, [
    limit,
    offset,
  ]);

  return {
    campaignsList,
    campaignsTotal,
    campaignsSummary,

    list,
    create,
    getSummary,

    listError,
    createError,
    summaryError,

    isLoading,
    isCreating,
  };
};

export default useCampaigns;
