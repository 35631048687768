import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  openNewPropertyViewingModal,
} from 'store/actions/newRealtorPropertyViewingModal';

import {
  setNewPropertyViewingFormField,
} from 'store/actions/newRealtorPropertyViewingForm';

import MatchingPropertiesBuyerCard from 'shared/components/UI/RealtorObjectCards/MatchingPropertiesBuyerCard';

const mapStateToProps = (state, ownProps) => {
const {
    id,
    updateList,
  } = ownProps;

  const {
    realtor_contact_id,
    realtor_property_id,
  } = selectors.realtorMatchingProperties.byId(id)(state);

  return {
    matchingId: id,
    contactId: realtor_contact_id,
    propertyId: realtor_property_id,
    updateList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  openNewShowingModal: async (contactId, propertyId) => {
    await dispatch(setNewPropertyViewingFormField('buyer')(contactId));
    await dispatch(openNewPropertyViewingModal(propertyId, { sellerAgent: true }));
  },
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  onClickArrangeShowing: async () => {
    dispatchProps.openNewShowingModal(stateProps.contacId, stateProps.propertyId);

    if (stateProps.updateList) {
      await stateProps.updateList();
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(MatchingPropertiesBuyerCard);
