import React, { useCallback, useEffect } from 'react';
import { bool, func, shape } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import getFeatureVersion from 'shared/utils/getFeatureVersion';

import Button from 'ui/Button';
import Modal from 'ui/Modal';
import TabLabel from 'ui/TabLabel';
import Tabs from 'ui/Tabs';
import Tab from 'ui/Tab';
import TabContext from 'ui/TabContext';
import TabPanel from 'ui/TabPanel';

import NewPropertyForm from 'sections/MLS/forms/NewPropertyForm';
import NewPropertyResidentailFormV2 from 'sections/Properties/forms/NewPropertyFormV2/NewPropertyResidentialForm';
import NewPropertyCommercialFormV2 from 'sections/Properties/forms/NewPropertyFormV2/NewPropertyCommercialForm';
import { setNewPropertyFormField } from 'store/actions/newRealtorPropertyForm';

const NewPropertyModal = (props) => {
  const {
    theme,
    isVisible,
    closeModal,
    saveProperty,
    onlySave,
    getCachedData,
    saveAttempt,
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const { property_state: propertyState } = useSelector((state) => state.realtorNewPropertyForm);


  useEffect(() => {
    if (isVisible) {
      getCachedData();
    }
  }, [isVisible]);

  const saveAndOpenProperty = useCallback(async () => {
    const propertyId = await saveProperty();
    if (propertyId) history.push(`/mls/${propertyId}`);
  }, [
    saveProperty,
  ]);

  const version = getFeatureVersion('new property form');
  const handleTabChange = useCallback((event, value) => {
    dispatch(setNewPropertyFormField('property_state')(value));
  }, []);

  return (
    <Modal
      theme={theme}
      width={720}
      visible={isVisible}
      okText="Save"
      onCancel={closeModal}
      destroyOnClose
      title="New Property"
      footer={[
        <Button onClick={closeModal} variant="secondary">
          Cancel
        </Button>,
        <Button variant="primary" onClick={saveProperty}>
          Save
        </Button>,
        onlySave ? null : (
          <Button variant="primary" onClick={saveAndOpenProperty}>
            Save & Open Property
          </Button>
        ),
      ]}
    >
      {
        version === 1
        ? (
          <NewPropertyForm />
        ) : (
          <TabContext value={propertyState}>
            <Tabs
              variant="fullWidth"
              value={propertyState}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
            >
              <Tab
                label={(
                  <TabLabel
                    isSelected={propertyState === 'residential'}
                    variant="title2"
                    weight={400}
                    weightSelected={700}
                  >
                    Residential
                  </TabLabel>
                )}
                value="residential"
                withBottomBorder
              />
              <Tab
                label={(
                  <TabLabel
                    isSelected={propertyState === 'commercial'}
                    variant="title2"
                    weight={400}
                    weightSelected={700}
                  >
                    Commercial
                  </TabLabel>
                )}
                value="commercial"
                withBottomBorder
              />
            </Tabs>

            <TabPanel
              value="residential"
              index={0}
              style={{ paddingLeft: '0px', paddingRight: '0px' }}
            >
              <NewPropertyResidentailFormV2 saveAttempt={saveAttempt} />
            </TabPanel>
            <TabPanel
              value="commercial"
              index={1}
              style={{ paddingLeft: '0px', paddingRight: '0px' }}
            >
              <NewPropertyCommercialFormV2 saveAttempt={saveAttempt} />
            </TabPanel>
          </TabContext>
        )
      }
    </Modal>
  );
};

NewPropertyModal.defaultProps = {
  isVisible: false,
  theme: null,
  saveProperty: () => {},
  onlySave: false,
  saveAttempt: false,
};

NewPropertyModal.propTypes = {
  isVisible: bool,
  theme: shape(),
  closeModal: func.isRequired,
  saveProperty: func,
  onlySave: bool,
  getCachedData: func.isRequired,
  saveAttempt: bool,
};

export default NewPropertyModal;
