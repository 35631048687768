import React from 'react';
import PropTypes from 'prop-types';

import withPropertyData from 'sections/Properties/hocs/withPropertyData';

import Typography from 'ui/Typography';

import { PROPERTY_TYPES } from 'constants/realtor.constants';

import formatValue from '../formatValue';

const PropertyType = (props) => {
  const {
    property,
  } = props;

  return (
    <Typography variant="cell">
      {PROPERTY_TYPES[property.property_type]}
    </Typography>
  );
};

PropertyType.defaultProps = {
  property: {},
};

const {
  number,
  shape,
} = PropTypes;

PropertyType.propTypes = {
  property: shape({
    property_type: number,
  }),
};

export default withPropertyData(PropertyType);
