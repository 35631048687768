/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'ui/Modal';

import NewEventForm from 'sections/Calendar/forms/NewEvent';

const NewEntryFormModal = (props) => {
  const { date, isVisible, closeModal } = props;

  return (
    <Modal
      width={800}
      visible={isVisible}
      onCancel={closeModal}
      footer={null}
      destroyOnClose
      title={`Create new entry ${
        date ? `for ${date.format('MMM DD YYYY')}` : ''
      }`}
    >
      <NewEventForm defaultDate={date} onComplete={closeModal} />
    </Modal>
  );
};

NewEntryFormModal.defaultProps = {
  isVisible: false,
};

const { bool, func } = PropTypes;

NewEntryFormModal.propTypes = {
  isVisible: bool,
  closeModal: func.isRequired,
};

export default NewEntryFormModal;
