import React, { Fragment, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  arrayOf, func, number, oneOf, shape, string,
 } from 'prop-types';

import ErrorBoundary from 'ui/ErrorBoundary';
import Typography from 'ui/Typography';
import TableHead from 'ui/TableHead';
import Icon from 'ui/Icon';

import PropertyCell from 'shared/modules/PropertyCell';

import OptionsCell from './OptionsCell';
import ContactCell from './ContactCell';
import PropertyImageCell from './PropertyImageCell';
import PropertyPriceCell from './PropertyPriceCell';
import Metrics from './Metrics';

const temps = {
  1: 'cold',
  2: 'warm',
  3: 'hot',
};

const PropertiesTableV2 = ({
  propertiesList,
  getProperties,
  setFilterItem,
  itemsCount,
  pageNumber,
  pageSize,
  sortBy,
  sortDirection,
  onRowSelect,
  firstSelfListingPropertyKey,
  firstNotSelfListingPropertyKey,
}) => {
  const history = useHistory();

  const groupedProperties = useMemo(() => {
    const groups = {
      res_sale: [],
      res_lease: [],
      com_sale: [],
      com_lease: [],
    };

    propertiesList.forEach((property) => {
      if (property.property_state === 'residential' && property.transfer_state === 'sale') {
        groups.res_sale.push(property);
      }
      if (property.property_state === 'residential' && property.transfer_state === 'lease') {
        groups.res_lease.push(property);
      }
      if (property.property_state === 'commercial' && property.transfer_state === 'sale') {
        groups.com_sale.push(property);
      }
      if (property.property_state === 'commercial' && property.transfer_state === 'lease') {
        groups.com_lease.push(property);
      }
    });
    return groups;
  }, [propertiesList]);

  useEffect(() => {
    getProperties(pageNumber, pageSize);

    return () => {
      onRowSelect([]);
    };
  }, []);

  const openRecord = (property) => history.push(`/mls/${property.id}`);

  const shouldShowDividers = sortBy === 'myListings';
  const renderGroup = (group) => {
    let title = 'Residential Sale';
    if (group === 'res_lease') {
      title = 'Residential Lease';
    }

    if (group === 'com_sale') {
      title = 'Commercial Sale';
    }

    if (group === 'com_lease') {
      title = 'Commercial Lease';
    }

    return (
      <div style={{
          display: 'flex', padding: '14px 22px', background: '#F2F6FE', color: '#367BED', cursor: 'pointer',
        }}
      >
        <span>
          <FontAwesomeIcon
            icon="angle-down"
            color="#367BED"
            style={{ width: '24px' }}
          />
        </span>
        {title}
        <span style={{
            background: '#D8E5FF',
            width: '32px',
            borderRadius: '10px',
            textAlign: 'center',
            marginLeft: '11px',
          }}
        >
          {groupedProperties[group]?.length}
        </span>
      </div>
    );
  };

  return (
    <table
      id="properties-table"
      className={`realtor-properties-table ${shouldShowDividers
          && 'with-divider-rows'}`}
      style={{ width: '100%' }}
    >
      <thead>
        <th width="8%">
          <TableHead />
        </th>
        <th width="17%">
          <TableHead>
            Property
          </TableHead>
        </th>
        <th width="5%">
          <TableHead>
            Temp
          </TableHead>
        </th>
        <th width="10%">
          <TableHead>
            Price
          </TableHead>
        </th>
        <th width="20%">
          <TableHead>
            Seller/Listing Agent
          </TableHead>
        </th>
        <th width="25%">
          <TableHead>
            Metrics
          </TableHead>
        </th>
        <th width="10%">
          <TableHead>
            Next Priority
          </TableHead>
        </th>
        <th width="5%">
          <TableHead />
        </th>
      </thead>
      <tbody>
        {propertiesList.length > 0 && groupedProperties.com_lease.length < 1 && groupedProperties.com_sale.length < 1
        && groupedProperties.res_lease.length < 1 && groupedProperties.res_sale.length < 1
        && propertiesList.map((property) => (
          <tr key={property.id}>
            <td style={{ padding: '8px' }}>
              <div onClick={() => openRecord(property)}>
                <PropertyImageCell propertyId={property.id} />
              </div>
            </td>
            <td style={{ padding: '8px' }}>
              <div onClick={() => openRecord(property)}>
                <PropertyCell
                  itemType="properties"
                  itemId={property?.id}
                />
              </div>
            </td>
            <td>
              <div onClick={() => openRecord(property)}>
                <ErrorBoundary>
                  {
                    property.temp
                      ? (
                        <Icon
                          name={temps[property.temp]}
                          color={temps[property.temp]}
                          type="custom"
                          size="m"
                        />
                      )
                      : null
                  }
                </ErrorBoundary>
              </div>
            </td>
            <td>
              <div onClick={() => openRecord(property)}>
                <PropertyPriceCell value={property.expected_price} saleType={property.sale_type_name} />
              </div>
            </td>
            <td>
              <div onClick={() => openRecord(property)}>
                <ContactCell contactId={property.listing_agent} />
              </div>
            </td>
            <td>
              <div onClick={() => openRecord(property)}>
                <ErrorBoundary>
                  <Metrics
                    propertyId={property?.id}
                  />
                </ErrorBoundary>
              </div>
            </td>
            <td>
              <div onClick={() => openRecord(property)}>
                <Typography
                  noMargin
                  weight="bold"
                  color="focus"
                  noWrap
                >
                  COMING SOON
                </Typography>
              </div>
            </td>
            <td>
              <OptionsCell propertyId={property?.id} />
            </td>
          </tr>
        ))}
        {Object.keys(groupedProperties).map((group) => groupedProperties[group].length > 0 && (
          <Fragment key={group}>
            <tr>
              <td colSpan={8}>{renderGroup(group)}</td>
            </tr>
            {groupedProperties[group].map((property) => (
              <tr key={property.id}>
                <td style={{ padding: '8px' }}>
                  <div onClick={() => openRecord(property)}>
                    <PropertyImageCell propertyId={property.id} />
                  </div>
                </td>
                <td style={{ padding: '8px' }}>
                  <div onClick={() => openRecord(property)}>
                    <PropertyCell
                      itemType="properties"
                      itemId={property?.id}
                    />
                  </div>
                </td>
                <td>
                  <div onClick={() => openRecord(property)}>
                    <ErrorBoundary>
                      {
                        property.temp
                          ? (
                            <Icon
                              name={temps[property.temp]}
                              color={temps[property.temp]}
                              type="custom"
                              size="m"
                            />
                          )
                          : null
                      }
                    </ErrorBoundary>
                  </div>
                </td>
                <td>
                  <div onClick={() => openRecord(property)}>
                    <PropertyPriceCell value={property.expected_price} saleType={property.sale_type_name} />
                  </div>
                </td>
                <td>
                  <div onClick={() => openRecord(property)}>
                    <ContactCell contactId={property.listing_agent} />
                  </div>
                </td>
                <td>
                  <div onClick={() => openRecord(property)}>
                    <ErrorBoundary>
                      <Metrics
                        propertyId={property?.id}
                      />
                    </ErrorBoundary>
                  </div>
                </td>
                <td>
                  <div onClick={() => openRecord(property)}>
                    <Typography
                      noMargin
                      weight="bold"
                      color="focus"
                      noWrap
                    >
                      COMING SOON
                    </Typography>
                  </div>
                </td>
                <td>
                  <OptionsCell propertyId={property?.id} />
                </td>
              </tr>
            ))}
          </Fragment>
        ))}
      </tbody>
    </table>
  );
};

PropertiesTableV2.defaultProps = {
  propertiesList: [],
  itemsCount: 0,
  pageNumber: 1,
  pageSize: 10,
  sortBy: 'myListings',
  sortDirection: 'desc',
  firstSelfListingPropertyKey: undefined,
  firstNotSelfListingPropertyKey: undefined,
};

PropertiesTableV2.propTypes = {
  propertiesList: arrayOf(
    shape({
      key: string,
    }),
  ),
  getProperties: func.isRequired,
  setFilterItem: func.isRequired,
  itemsCount: number,
  pageNumber: number,
  pageSize: number,
  sortBy: string,
  sortDirection: oneOf(['asc', 'desc']),
  onRowSelect: func.isRequired,
  firstSelfListingPropertyKey: number,
  firstNotSelfListingPropertyKey: number,
};

export default PropertiesTableV2;
