import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import Icon from 'ui/Icon';
import Elevation from 'ui/Elevation';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import {
  Root,
  ActionButtonSection,
} from './styledItems';

const cardList = {
  email: {
    logo: 'emailLogo',
    title: 'Emails',
    subtitle: 'Last campaign',
    loggedOutSubtitle: 'No email connected',
    chips: {
      sent: 'Sent',
      replies: 'Replies',
      newLeads: 'New Leads',
    },
  },
  facebook: {
    logo: 'facebookLogo',
    title: 'Facebook',
    subtitle: 'Last post',
    loggedOutSubtitle: 'Facebook not connected',
    chips: {
      postThisWeek: 'Post this week',
      viewsThisWeek: 'Views this week',
    },
  },
  instagram: {
    logo: 'instagramLogo',
    title: 'Instagram',
    subtitle: 'Last post',
    loggedOutSubtitle: 'Instagram not connected',
    chips: {
      postThisWeek: 'Post this week',
      viewsThisWeek: 'Views this week',
    },
  },
};

const ConnectedAccountCard = (props) => {
  const {
    type,
    hasConnected,
    metrics,
    diffDays,
    loggedInAs,
    actionButton,
  } = props;

  return (
    <Elevation
      rounded="m"
      hideOverflow
    >
      <Root type={type}>
        <Flex
          fullWidth
          spacing={2}
          flexDirection="column"
          alignItems="center"
        >
          <Flex
            fullWidth
            spacing={2}
            padding="0 12px"
            flexDirection="row"
            alignItems="center"
          >
            <FlexItem>
              <Icon
                name={cardList[type].logo}
                type="custom"
                size="50"
              />
            </FlexItem>
            <FlexItem>
              <Flex
                fullWidth
                spacing={0}
                flexDirection="column"
              >
                <FlexItem>
                  <Typography
                    variant="title1"
                    noMargin
                    align="center"
                  >
                    {cardList[type].title}
                  </Typography>
                </FlexItem>
                <FlexItem>
                  <Typography
                    variant="subtitle"
                    noMargin
                    align="left"
                    tag="span"
                    color="descriptor-text"
                  >
                    {hasConnected ? `${cardList[type].subtitle} : ${diffDays}d ago` : cardList[type].loggedOutSubtitle}
                  </Typography>
                </FlexItem>
              </Flex>      
            </FlexItem>
          </Flex>
          {
            hasConnected ? (
              <FlexItem fullWidth>
                <Flex
                  fullWidth
                  spacing={2}
                  justifyContent="space-around"
                  flexDirection="row"
                  alignItems="center"
                >
                  {
                    Object.keys(metrics).map(key => (
                      <FlexItem>
                        <Flex 
                          flexDirection="column"
                          alignItems="center"
                        >
                          <FlexItem>
                            <Typography
                              variant="title2"
                              noMargin
                              weight="bold"
                            >
                              {metrics[key]}
                            </Typography>
                          </FlexItem>
                          <FlexItem>
                            <Typography
                              variant="title2"
                              noMargin
                              weight="400"
                            >
                              {cardList[type].chips[key]}
                            </Typography>
                          </FlexItem>
                        </Flex>
                      </FlexItem>
                    ))
                  }
                </Flex>
              </FlexItem>
            )
            : (
              <FlexItem fullWidth>
                <ActionButtonSection>
                  <Flex fullWidth justifyContent="center">
                    <FlexItem>
                      {actionButton}
                    </FlexItem>
                  </Flex>
                </ActionButtonSection>
              </FlexItem>
            )
          }
        </Flex>
      </Root>
    </Elevation>
  );
};

ConnectedAccountCard.defaultProps = {
  loggedInAs: '',
  hasConnected: false,
  metrics: {},
};

const {
  string,
  bool,
  object,
  integer,
  oneOf,
  element,
} = PropTypes;

ConnectedAccountCard.propTypes = {
  type: oneOf(['facebook', 'instagram', 'email']).isRequired,
  hasConnected: bool,
  metrics: object,
  diffDays: integer,
  loggedInAs: string,
  actionButton: element.isRequired,
};

export default ConnectedAccountCard;
