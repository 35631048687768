import { connect } from 'react-redux';

import selectors from 'store/selectors';

import ReasonMultiSelect from './ReasonMultiSelect';

const mapStateToProps = (state) => {
  const rejectionReasons = selectors.dictionary.rejectedPropertiesReasons(state);

  return {
    rejectionReasons,
  };
};

export default connect(mapStateToProps)(ReasonMultiSelect);
