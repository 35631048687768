import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';

import MainContactInfo from './MainContactInfo';

import {
  Root,
} from './styledItems';

const MatchingBuyerCard = (props) => {
  const {
    contactId,
    controls,
  } = props;

  return (
    <Root>
      <Flex fullWidth spacing={1} flexDirection="column">
        <MainContactInfo contactId={contactId} />
        {controls}
      </Flex>
    </Root>
  );
};

MatchingBuyerCard.defaultProps = {
  controls: null,
};

const {
  element,
  number,
} = PropTypes;

MatchingBuyerCard.propTypes = {
  contactId: number.isRequired,
  controls: element,
};

export default MatchingBuyerCard;
