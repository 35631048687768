import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

import formatValue from './formatValue';

const PropertyPriceCell = (props) => {
  const {
    value,
  } = props;

  return value ? (
    <Typography variant="cell" weight="bold">
      {formatValue(value)}
    </Typography>
  ) : (
    <Typography variant="cell">
      —
    </Typography>
    );
};

PropertyPriceCell.defaultProps = {
  value: null,
};

const {
  number,
} = PropTypes;

PropertyPriceCell.propTypes = {
  value: number,
};

export default PropertyPriceCell;
