import { connect } from 'react-redux';

import selectors from 'store/selectors';

import PropertyDetailsView from './PropertyDetailsView';

const mapStateToProps = (state) => {
  const client = selectors.realtorClients.selected(state);

  return {
    propertyId: client.realtor_property_id,
  };
};

export default connect(mapStateToProps)(PropertyDetailsView);
