import React, {
  useState,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import { between } from '@symplete/symplete-utils/datetime';

import ErrorBoundary from 'ui/ErrorBoundary';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';
import CheckboxLabelled from 'ui/CheckboxLabelled';

import DateInput from 'shared/inputs/DateInput';
import CommissionField from 'shared/inputs/CommissionInput';

import withDebouncedCallbacks from 'shared/hocs/withDebouncedCallbacks';
import withInlineLabel from 'shared/hocs/withInlineLabel';

import {
  DurationField,
} from './styledItems';

const CommissionInput = withDebouncedCallbacks(CommissionField, [[
  'onChange',
  1000,
]]);

const Field = withInlineLabel(Typography);

const AgreementDetails = (props) => {
  const {
    onChange,
    client,
  } = props;

  const [listingDuration, setListingDuration] = useState(0);

  useEffect(() => {
    const diff = client.listing_start_date && client.listing_end_date && between(client.listing_start_date, client.listing_end_date);
    setListingDuration(diff);
  }, [
    client.listing_start_date,
    client.listing_end_date,
  ]);

  return (
    <ErrorBoundary>
      <Flex
        spacing={2}
        flexDirection="column"
      >
        <Field
          variant="inline"
          label="Duration:"
          noMargin
        >
          <Flex spacing={0.5} alignItems="baseline">
            <DurationField>
              <DateInput
                variant="inline"
                label={null}
                handleChange={onChange('listing_start_date')}
                placeholder="11/25/2020"
                value={client.listing_start_date}
                disableToolbar
                autoOk
              />
            </DurationField>
            <div>—</div>
            <DurationField>
              <DateInput
                variant="inline"
                label={null}
                handleChange={onChange('listing_end_date')}
                placeholder="11/25/2020"
                value={client.listing_end_date}
                disableToolbar
                autoOk
              />
            </DurationField>
            {
              (typeof listingDuration === 'number') && (
                <div>
                  {`(${listingDuration} days)`}
                </div>
              )
            }
          </Flex>
        </Field>

        <Flex spacing={2}>
          <CheckboxLabelled
            value={!!client.is_exclusive}
            onChange={onChange('is_exclusive')}
            labelText="Exclusive"
          />
          <CheckboxLabelled
            value={!!client.is_pocket_listing}
            onChange={onChange('is_exclusive')}
            labelText="Off-market Listing"
          />
        </Flex>

        <Flex
          fullWidth
          spacing={3}
          flexWrap="wrap"
        >
          <CommissionInput
            variant="inline"
            label="Buyer Commission:"
            placeholder="0.5"
            onChange={onChange('buyer_agent_commission')}
            onTypeChange={onChange('buyer_agent_commission_type')}
            value={client.buyer_agent_commission}
            commissionType={client.buyer_agent_commission_type}
          />
          <CommissionInput
            variant="inline"
            label="Seller Commission:"
            placeholder="0.5"
            onChange={onChange('seller_agent_commission')}
            onTypeChange={onChange('seller_agent_commission_type')}
            value={client.seller_agent_commission}
            commissionType={client.seller_agent_commission_type}
          />
        </Flex>
      </Flex>
    </ErrorBoundary>
  );
};

const {
  func,
  shape,
} = PropTypes;

AgreementDetails.propTypes = {
  onChange: func.isRequired,
  client: shape.isRequired,
};

export default AgreementDetails;
