import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import withFieldDecorator from 'shared/hocs/withFieldDecorator';
import withInlineLabel from 'shared/hocs/withInlineLabel';

import Textfield from 'ui/Textfield';
import InlineInput from 'ui/InlineInput';

const FormTextfield = (props) => {
  const {
    className,
    defaultValue,
    disabled,
    error,
    helperText,
    label,
    max,
    min,
    multiline,
    onBlur,
    onChange,
    onClick,
    onFocus,
    placeholder,
    required,
    step,
    type,
    value,
    variant,
  } = props;

  const [ownValue, setValue] = useState(value || '');

  useEffect(() => {
    setValue(value);
  }, [value]);

  const handleChange = useCallback((val) => {
    setValue(val);
    onChange(val);
  });

  if (variant === 'inline') {
    return (
      <InlineInput
        disabled={disabled}
        value={ownValue}
        type={type}
        onChange={(event) => {
          handleChange(event.target.value);
        }}
        placeholder={placeholder}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        className={className}
        step={step}
        min={min}
        max={max}
      />
    );
  }

  return (
    <Textfield
      // defaultValue={defaultValue}
      // className={classnames({
      //   [className]: className,
      // })}
      // inputRef={inputRef}
      fullWidth
      disabled={disabled}
      hint={helperText}
      label={label}
      // margin="dense"
      onChange={handleChange}
      type={type}
      value={ownValue || defaultValue}
      // variant={variant}
      textarea={multiline}
      // rows={rows}
      // onKeyPress={onKeyPress}
      // onKeyUp={onKeyUp}
      onClick={onClick}
      onBlur={onBlur}
      onFocus={onFocus}
      step={step}
      // InputProps={{
      //   startAdornment,
      //   endAdornment,
      //   disableUnderline,
      // }}
      error={error}
      // placeholder={placeholder}
      required={required}
    />
  );
};

FormTextfield.defaultProps = {
  className: null,
  defaultValue: null,
  disabled: false,
  // disableUnderline: false,
  // endAdornment: undefined,
  error: false,
  helperText: null,
  // inputRef: null,
  label: null,
  max: null,
  min: null,
  multiline: false,
  onBlur: () => {},
  onChange: () => {},
  onClick: () => {},
  onFocus: () => {},
  // onKeyPress: () => {},
  // onKeyUp: () => {},
  placeholder: '',
  required: false,
  // rows: null,
  // startAdornment: undefined,
  step: 1,
  type: 'text',
  value: null,
  variant: 'outlined',
};

const {
  bool,
  // Element,
  func,
  // instanceOf,
  oneOf,
  // oneOfType,
  number,
  // shape,
  string,
} = PropTypes;

FormTextfield.propTypes = {
  className: string,
  defaultValue: string,
  disabled: bool,
  // disableUnderline: bool,
  // endAdornment: Element,
  error: bool,
  helperText: string,
  // inputRef: oneOfType([
  //   func,
  //   shape({ current: instanceOf(Element) }),
  // ]),
  label: string,
  max: number,
  min: number,
  multiline: bool,
  onBlur: func,
  onChange: func,
  onClick: func,
  onFocus: func,
  // onKeyPress: func,
  // onKeyUp: func,
  placeholder: string,
  required: bool,
  // rows: number,
  // startAdornment: Element,
  step: number,
  type: string,
  value: string,
  variant: oneOf(['inline']),
};

const decoratorProps = {
  iconName: 'edit',
};

export default withFieldDecorator(decoratorProps)(
  withInlineLabel(FormTextfield),
);
