import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import prettysize from 'prettysize';

import { startsWith } from 'shared/utility';

import Flex from 'ui/Flex';
import Button from 'ui/Button';

import useModal from 'ui/hooks/useModal';

import PreviewModal from 'shared/AttachmentsV4/PreviewModal/PreviewModal';

import downloadFile from 'shared/utils/downloadFile';

const getExtension = (filename) => filename.split('.').pop();

const apiUrl = process.env.REACT_APP_SYMPLETE_CLIENT_API;

const getSrc = (tokenId) => (attachmentId) => `${apiUrl}/api/integrations/emailAttachments/${attachmentId}?tokenId=${tokenId}&auth=${localStorage.getItem('token')}`;

const Attachment = (props) => {
  const {
    tokenId,
    attachment,
  } = props;

  const {
    openModal,
    closeModal,
    isModalVisible,
  } = useModal();

  const fileUrl = getSrc(tokenId)(attachment.id);

  const onAttachmentClick = useCallback(() => {
    openModal();
  }, [
    openModal,
    fileUrl,
  ]);

  return (
    <>
      <Flex spacing={1}>
        {
          startsWith(attachment?.content_type, 'image/') && (
            <img
              style={{
                maxWidth: '44px',
                maxHeight: '44px',
                borderRadius: '4px',
              }}
              src={fileUrl}
            />
          )
        }
        <Button
          variant="secondary"
          icon="eye"
          onClick={onAttachmentClick}
        >
          {attachment.filename}
          {' '}
          {prettysize(attachment.size)}
        </Button>
      </Flex>
      <PreviewModal
        isVisible={isModalVisible}
        onCancel={closeModal}
        previewFileUrl={fileUrl}
        previewFileExtension={getExtension(attachment.filename)}
        footer={[
          <Button
            variant="secondary"
            onClick={closeModal}
          >
            Cancel
          </Button>,
          <Button
            variant="primary"
            icon="download"
            iconPosition="right"
            onClick={() => { downloadFile(fileUrl); }}
          >
            Download
            {' '}
            {prettysize(attachment.size)}
          </Button>,
        ]}
      />
    </>
  );
};

const {
  number,
  shape,
  string,
} = PropTypes;

Attachment.propTypes = {
  tokenId: number.isRequired,
  attachment: shape({
    id: string,
    filename: string,
  }).isRequired,
};

export default Attachment;
