import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';
import MenuItem from 'ui/MenuItem';

import withOptionsMenu from 'ui/withOptionsMenu';

import useProperty from 'sections/Properties/hooks/useProperty';
import usePropertyRequirements from 'sections/Properties/hooks/usePropertyRequirements';

import useAddress from 'sections/Addresses/hooks/useAddress';

const Selector = withOptionsMenu(Button);

const PropertyOption = ({ itemId }) => {
  const property = useProperty(itemId);
  const address = useAddress(property.address_id);

  return (
    <>
      {`Seller: ${address || 'unknown address'}`}
    </>
  );
};

const PropertyRequirementOption = ({ itemId }) => {
  const propertyRequirements = usePropertyRequirements(itemId);
  const address = useAddress(propertyRequirements.addresses?.[0]);

  return (
    <>
      {`Buyer: ${address || 'unknown address'}`}
    </>
  );
};

const menuOptionsComponents = {
  property: PropertyOption,
  propertyRequirement: PropertyRequirementOption,
};

const selectedComponents = {
  property: PropertyOption,
  propertyRequirement: PropertyRequirementOption,
};

const LeadDetailsSelector = (props) => {
  const {
    options,
    onSelect,
    selectedItem,
  } = props;

  const SelectedComponent = selectedItem
    ? selectedComponents[selectedItem?.type]
    : () => null;

  return (
    <Selector
      fullWidth
      variant="flat"
      icon={options.length > 1 ? 'chevron-down' : null}
      iconPosition="right"
      typographyProps={{
        ellipsis: true,
      }}
      customOptions={({ closeMenu }) => options.map((item) => {
        const Option = menuOptionsComponents[item.type];

        return (
          <MenuItem
            key={`${item.type}_${item.itemId}`}
            onClick={() => {
              onSelect(item);
              closeMenu();
            }}
          >
            <Option
              itemId={item.itemId}
            />
          </MenuItem>
        );
      })}
    >
      <SelectedComponent
        itemId={selectedItem?.itemId}
      />
    </Selector>
  );
};

LeadDetailsSelector.defaultProps = {
  options: [],
  selectedItem: null,
};

const {
  arrayOf,
  func,
  number,
  oneOf,
  shape,
} = PropTypes;

const selectedItemType = shape({
  itemId: number,
  type: oneOf(['property', 'propertyRequirement']),
});

LeadDetailsSelector.propTypes = {
  options: arrayOf(selectedItemType),
  selectedItem: selectedItemType,
  onSelect: func.isRequired,
};

export default LeadDetailsSelector;
