import React from 'react';
import { Radio, Checkbox, Button } from 'antd';
import AssignModal from 'shared/AssignUser/AssignModal';
import PropTypes from 'prop-types';
import ReminderAssignedAgents from '../../ToDoListBody/ReminderItems/ReminderAssignedAgents';
import './SettingsBody.scss';

class SettingsBody extends React.Component {
  constructor(props) {
    super(props);
    const {
      employeeSettingsInf,
      setInvitersGroup,
      setInvitersGroupSuccess,
      setInvitersGroupError,
    } = this.props;
    this.state = {
      invitersData: {
        data: {
          key: 'EmployeeID',
          searchKey: 'Name',
          activeUsers: [],
          availableUsers: [],
        },
        config: {
          apiUrl: '/api/todolist/saveInvitersGroup',
          start: setInvitersGroup,
          success: setInvitersGroupSuccess,
          error: setInvitersGroupError,
        },
      },
      radioInputValuePrev: employeeSettingsInf.valueAssigningSettings,
      checkboxValuePrev: employeeSettingsInf.isMoveCompleteDown,
      radioInputValue: employeeSettingsInf.valueAssigningSettings,
      checkboxValue: employeeSettingsInf.isMoveCompleteDown,
      agentAssignModalVisible: false,
    };
  }

  componentDidMount() {
    const {
      toDoListSetViewedAction,
    } = this.props;
    toDoListSetViewedAction();
  }

  onChangeCheckbox = () => {
    const {
      checkboxValue,
    } = this.state;
    this.setState({
      checkboxValue: !checkboxValue,
    });
  }

  onChangeRadio = (e) => {
    this.setState({
      radioInputValue: e.target.value,
    });
  };

  onClickCancel = () => {
    const {
      toDoListSettingsHideAction,
    } = this.props;
    const {
      radioInputValuePrev,
      checkboxValuePrev,
    } = this.state;
    this.setState({
      radioInputValue: radioInputValuePrev,
      checkboxValue: checkboxValuePrev,
    }, () => {
      toDoListSettingsHideAction();
    });
  }

  onClickSave = (checkboxValue, radioInputValue) => async () => {
    const {
      todolistSaveSettingsAction,
      toDoListSettingsHideAction,
      toDoListSetViewedAction,
    } = this.props;
    todolistSaveSettingsAction(checkboxValue, radioInputValue);
    await toDoListSetViewedAction();
    toDoListSettingsHideAction();
  }

  onClickOKInviteModel = () => {
    const {
      toDoListSetViewedAction,
    } = this.props;
    this.setState({
      agentAssignModalVisible: false,
    }, async () => {
      await toDoListSetViewedAction();
    });
  }

  onClickAddInviter = () => {
    const {
      agentAssignModalVisible,
    } = this.state;
    const {
      employeeInvitersList,
      employees,
      EmployeeID,
      setInvitersGroup,
      setInvitersGroupSuccess,
      setInvitersGroupError,
    } = this.props;

    const activeInvitersListID = employeeInvitersList
      .map((InviterElementFromDB) => InviterElementFromDB.id);

    const activeInvitersList = employees
      .filter((elem) => activeInvitersListID.includes(elem.EmployeeID));

    const invitersList = employees
      .filter((element) => (
        (element.Name !== null)
        || (element.Surname !== null))
        && (element.EmployeeID !== EmployeeID));

    this.setState({
      invitersData: {
        data: {
          key: 'EmployeeID',
          searchKey: 'Name',
          activeUsers: activeInvitersList,
          availableUsers: invitersList,
        },
        config: {
          apiUrl: '/api/todolist/saveInvitersGroup',
          start: setInvitersGroup,
          success: setInvitersGroupSuccess,
          error: setInvitersGroupError,
        },
        userData: {
          EmployeeID,
        },
      },
      agentAssignModalVisible: !agentAssignModalVisible,
    });
  }

  closeInvitersModal = async () => {
    const {
      toDoListSetViewedAction,
    } = this.props;
    this.setState({
      agentAssignModalVisible: false,
    });
    await toDoListSetViewedAction();
  }

  render() {
    const {
      employeeInvitersList,
    } = this.props;

    const {
      radioInputValue,
      checkboxValue,
      agentAssignModalVisible,
      invitersData,
    } = this.state;

    return (
      <div className="settings-body">
        <div className="settings">
          <div className="settings__descent-element">
            <Checkbox
              className="checkbox"
              onChange={this.onChangeCheckbox}
              checked={checkboxValue}
            />
            <span>Completed items automatically move to bottom</span>
          </div>
          <span className="settings__title">Assigning</span>
          <Radio.Group onChange={this.onChangeRadio} value={radioInputValue}>
            <Radio className="not-allow" value={1}>Allow no one to assign items to me</Radio>
            <Radio className="allow" value={2}>Allow anyone from the company to assign items to me</Radio>
            <Radio className="allow-some" value={3}>Allow only specific people to assign items to me</Radio>
          </Radio.Group>
          <ReminderAssignedAgents
            className="settings__assigned-agents"
            showButton
            onAddClick={this.onClickAddInviter}
            agents={employeeInvitersList}
          />
        </div>
        <div className="buttons">
          <Button
            onClick={this.onClickCancel}
            className="buttons__cansel"
          >
            Cancel
          </Button>
          <Button
            onClick={this.onClickSave(checkboxValue, radioInputValue)}
            className="buttons__save"
            type="primary"
          >
            Save
          </Button>
        </div>
        <AssignModal
          className="to-do-list-add-user-settings-modal"
          data={invitersData}
          modalVisible={agentAssignModalVisible}
          onClickOK={this.onClickOKInviteModel}
          closeModal={this.closeInvitersModal}
        />
      </div>
    );
  }
}

const {
  func,
  arrayOf,
  shape,
  string,
  number,
} = PropTypes;

SettingsBody.propTypes = {
  employeeSettingsInf: shape({
    valueAssigningSettings: number,
    isMoveCompleteDown: number,
  }).isRequired,
  employeeInvitersList: arrayOf({
    EmployeeID: number,
  }).isRequired,
  employees: shape({
    EmployeeID: number,
    Name: string,
    Surname: string,
  }).isRequired,
  EmployeeID: number.isRequired,
  toDoListSetViewedAction: func.isRequired,
  setInvitersGroup: func.isRequired,
  setInvitersGroupSuccess: func.isRequired,
  setInvitersGroupError: func.isRequired,
  todolistSaveSettingsAction: func.isRequired,
  toDoListSettingsHideAction: func.isRequired,
};

export default SettingsBody;
