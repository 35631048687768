/* eslint-disable camelcase */
import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  closeAssignInterestedPartyModal,
} from 'store/actions/realtorAssignInterestedPartyModal';

import {
  createMatchingProperty,
  removeMatchingProperty,
  optimizedMatchingPropertiesActions,
} from 'store/actions/realtorMatchingProperties';

import AssignInterestedPartyModal from 'shared/modals/AssignInterestedPartyModal';

const mapStateToProps = (state) => {
  const {
    assignInterestedPartiesModal: {
      isVisible,
      contactDealRole,
    },
    properties: {
      selectedProperty,
    },
  } = state;

  const matches = selectors.realtorMatchingProperties.propertyMatches(Number(selectedProperty))(state);

  return {
    isVisible,
    selectedContacts: matches.map((item) => item.realtor_contact_id),
    propertyId: selectedProperty,
    matches,
    contactDealRole,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeAssignInterestedPartyModal()),

  onSave: async ({
    propertyId,
    addedContacts,
    removedContacts,
    matches,
  }) => {
    const addContacts = addedContacts.map(async (contactId) => {
      await dispatch(createMatchingProperty({
        realtor_property_id: propertyId,
        realtor_contact_id: contactId,
        status: 1,
      }));
    });

    const removeContacts = removedContacts.map(async (contactId) => {
      const match = matches.find((item) => item.realtor_contact_id === contactId);
      await dispatch(removeMatchingProperty(match?.id));
    });

    await Promise.all(addContacts);
    await Promise.all(removeContacts);

    const {
      filterId,
      filter,
    } = selectors.realtorMatchingProperties.filter({ property_id: Number(propertyId) })();
    optimizedMatchingPropertiesActions.get(filterId, filter);
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onSave: async ({ addedContacts, removedContacts }) => {
    dispatchProps.onSave({
      addedContacts,
      removedContacts,
      propertyId: stateProps.propertyId,
      matches: stateProps.matches,
    });
    dispatchProps.closeModal();
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AssignInterestedPartyModal);
