import React, {
  useEffect,
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from 'antd';

import isSectionUnderConstruction from 'shared/utils/isSectionUnderConstruction';
import checkLoggedEmployeeLicenseAccess from 'shared/utils/checkLoggedEmployeeLicenseAccess';

import {
  NAVIGATION_TABS,
  NAVIGATION_ROUTES,
} from 'constants/navigation.constants';

import { PRODUCT_ICONS } from 'constants/icons.constants';
// import { common } from 'constants';

// import Typography from 'ui/Typography';
import MainMenuItem from 'shared/components/UI/MainMenuItem';

import './MainNavigation.scss';

// import KBGrey from '../../../assets/images/KBGrey.png';

import {
  BorderBox,
} from './styledItems';

const availableLinks = [
  {
    to: NAVIGATION_ROUTES.today,
    icon: PRODUCT_ICONS.today,
    iconType: 'feather',
    title: NAVIGATION_TABS.today,
  },
  {
    marginBox: true,
  },
  {
    to: NAVIGATION_ROUTES.communications,
    icon: PRODUCT_ICONS.communications,
    title: NAVIGATION_TABS.communications,
    iconType: 'feather',
    sectionName: 'communications',
  },
  // {
  //   marginBox: true,
  // },
  // {
  //   to: NAVIGATION_ROUTES.opportunitiesListing,
  //   icon: PRODUCT_ICONS.opportunitiesListing,
  //   iconType: 'feather',
  //   title: NAVIGATION_TABS.opportunitiesListing,
  //   sectionName: 'opportunities',
  // },
  {
    to: NAVIGATION_ROUTES.mls,
    icon: PRODUCT_ICONS.mls,
    iconType: 'feather',
    title: NAVIGATION_TABS.mls,
  },
  {
    to: NAVIGATION_ROUTES.leads,
    icon: PRODUCT_ICONS.leads,
    iconType: 'feather',
    title: NAVIGATION_TABS.leads,
  },
  {
    to: NAVIGATION_ROUTES.opportunities,
    icon: PRODUCT_ICONS.prospects,
    iconType: 'feather',
    title: NAVIGATION_TABS.clients,
  },
  {
    marginBox: true,
  },
  {
    to: NAVIGATION_ROUTES.offers,
    icon: PRODUCT_ICONS.offers,
    iconType: 'custom',
    title: NAVIGATION_TABS.offers,
  },
  {
    to: NAVIGATION_ROUTES.transactions,
    icon: PRODUCT_ICONS.transactions,
    iconType: 'feather',
    title: NAVIGATION_TABS.transactions,
  },
  {
    marginBox: true,
  },
  {
    to: NAVIGATION_ROUTES.inAppMarketing,
    icon: PRODUCT_ICONS.inAppMarketing,
    iconType: 'feather',
    title: NAVIGATION_TABS.inAppMarketing,
  },
];

const SidebarLink = (props) => {
  const {
    disabled,
    iconType,
    icon,
    title,
    to,
    isSmall,
  } = props;

  return (
    <MainMenuItem
      to={to}
      disabled={disabled}
      iconName={icon}
      iconType={iconType}
      isSmall={isSmall}
    >
      {title}
    </MainMenuItem>
  );
};

const MainNavigation = (props) => {
  const {
    // employeesCount,
    // shouldShowCompanySettings,
    plannedSystemMaintenance,
    getPlannedSystemMaintenance,
  } = props;

  const [windowWidth, setWindowWidth] = useState(null);
  const [windowHeight, setWindowHeight] = useState(null);

  const timeBorder = moment().utc().add('hours', 6);

  useEffect(() => {
    getPlannedSystemMaintenance();
  }, [getPlannedSystemMaintenance]);

  const handleWindowResize = useCallback(() => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [
    handleWindowResize,
  ]);

  // eslint-disable-next-line max-len
  const shouldShowSmallMenu = (windowWidth <= 1300 && windowHeight < 800) || (windowWidth > 1300 && windowHeight < 1000);

  const available = availableLinks.map((linkData) => { // eslint-disable-line consistent-return
    const {
      to,
      icon,
      iconType,
      title,
      licenseName,
      marginBox,
      sectionName,
    } = linkData;

    if (isSectionUnderConstruction(sectionName)) return null;

    if (marginBox) {
      return (
        <BorderBox />
      );
    }

    if (!licenseName) {
      return (
        <SidebarLink
          key={to}
          to={to}
          icon={icon}
          iconType={iconType}
          title={title}
          isSmall={shouldShowSmallMenu}
        />
      );
    }

    if (checkLoggedEmployeeLicenseAccess(licenseName)) {
      return (
        <SidebarLink
          key={to}
          to={to}
          icon={icon}
          title={title}
          isSmall={shouldShowSmallMenu}
        />
      );
    }

    return null;
  });

  return (
    <nav className="navigation">
      <div className="navigation__top">
        <div>
          {available}
        </div>
      </div>
      {
        plannedSystemMaintenance.length && moment(plannedSystemMaintenance[0].startTime).isBefore(timeBorder)
          ? (
            <Alert
              message={`We’ll be doing some scheduled system maintenance on
                ${moment(plannedSystemMaintenance[0].startTime).utc().format('MMM Do h:m a')} UTC
                and expect this to take ${plannedSystemMaintenance[0].hours}
                hours ${plannedSystemMaintenance[0].minutes} min.
                support@symplete.com - (805) 309 2548.`}
              type="info"
              showIcon
              closable
            />
          )
          : null
      }
      {/* {companySettings} */}
    </nav>
  );
};

SidebarLink.defaultProps = {
  disabled: false,
  iconType: 'feather',
  icon: '',
  title: '',
  to: '',
  isSmall: false,
};

MainNavigation.defaultProps = {
  // employeesCount: 0,
  // shouldShowCompanySettings: false,
};

const {
  arrayOf,
  bool,
  number,
  string,
  func,
} = PropTypes;

SidebarLink.propTypes = {
  disabled: bool,
  icon: string,
  title: string,
  to: string,
  iconType: string,
  isSmall: bool,
};

MainNavigation.propTypes = {
  plannedSystemMaintenance: arrayOf({
    id: number,
    startTime: string,
    hours: number,
    minutes: number,
    privateNote: string,
    publicComment: string,
    systemMaintenanceStatusId: number,
  }).isRequired,
  getPlannedSystemMaintenance: func.isRequired,
  // employeesCount: number,
  // shouldShowCompanySettings: bool,
};

export default MainNavigation;
