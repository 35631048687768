import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';
import Typography from 'ui/Typography';
import Modal from 'ui/Modal';
import Button from 'ui/Button';

import ItemDetails from 'sections/Opportunities/modules/ItemDetails';

import ActionSelector from './ActionSelector';

const ArchiveDeleteModal = (props) => {
  const {
    isVisible,
    itemId,
    itemType,
    onCancel,
    onSave,
  } = props;

  const [selectedAction, setSelectedAction] = useState('archive');

  const handleActionSelect = useCallback((action) => () => {
    setSelectedAction(action);
  }, []);

  const isBulk = Array.isArray(itemId);

  return (
    <Modal
      width={850}
      visible={isVisible}
      onCancel={onCancel}
      title="Please select an option"
      footer={[
        <Button
          onClick={onCancel}
          variant="secondary"
        >
          Cancel
        </Button>,
        <Button
          onClick={onSave(selectedAction)}
          variant="primary"
        >
          Confirm
        </Button>,
      ]}
    >
      <Grid container spacing={2} direction="column">
        <Grid item xs={12}>
          {
            isBulk ? (
              <Typography>
                This will affect all selected items
              </Typography>
            ) : (
              <ItemDetails
                itemId={itemId}
                itemType={itemType}
              />
            )
          }
        </Grid>
        <Grid item xs={12}>
          <ActionSelector
            selectedAction={selectedAction}
            onChange={handleActionSelect}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

ArchiveDeleteModal.defaultProps = {
  isVisible: false,
};

const {
  bool,
  func,
  number,
  arrayOf,
  oneOfType,
  oneOf,
} = PropTypes;

ArchiveDeleteModal.propTypes = {
  isVisible: bool,
  onSave: func.isRequired,
  onCancel: func.isRequired,
  itemId: oneOfType([number, arrayOf(number)]).isRequired,
  itemType: oneOf(['client', 'prospect']).isRequired,
};

export default ArchiveDeleteModal;
