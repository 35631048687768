import { connect } from 'react-redux';

import selectors from 'store/selectors';

import CommentsModule from 'sections/Contacts/modules/CommentsV2';

const mapStateToProps = (state, ownProps) => {
  const prospect = selectors.realtorProspects.byId(state, ownProps.clientId);

  return {
    contactId: prospect?.contact_id,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(CommentsModule);
