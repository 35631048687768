import styled from 'styled-components';

export const Root = styled.div`
  .items-container {
      margin-top: 20px;
      gap: 20px;
      display: flex;
      flex-direction: column;
  }
`;

export default {
  Root,
};
