import setFilterItem from './setFilterItem';

const setAll = () => (dispatch) => {
  dispatch(setFilterItem('status')(null));
};

const setArchived = () => (dispatch) => {
  dispatch(setFilterItem('status')(2));
};

const setActive = () => (dispatch) => {
  dispatch(setFilterItem('status')(1));
};

const setNew = () => (dispatch) => {
  dispatch(setFilterItem('status')(7));
};

const setBuyers = () => (dispatch) => {
  dispatch(setFilterItem('leadType')(['1']));
};

const setSellers = () => (dispatch) => {
  dispatch(setFilterItem('leadType')(['2']));
};

const keyHandlers = {
  all: setAll,
  new: setNew,
  active: setActive,
  archived: setArchived,
  buyers: setBuyers,
  sellers: setSellers,
};

const handleCustomFilter = (key) => (dispatch) => {
  if (keyHandlers[key]) dispatch(keyHandlers[key]());
};

export default handleCustomFilter;
