import React, {
  useState,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import Button from 'ui/Button';

import AssignRecipients from 'sections/Communications/modules/Marketing/AssignRecipients';

import useModal from 'ui/hooks/useModal';

const Next = (props) => {
  const {
    onAssignRecipients,
    recipients,
  } = props;

  const {
    isModalVisible,
    openModal,
    closeModal,
  } = useModal();

  const [selectedRecipients, setSelectedRecipients] = useState(recipients);

  const assignRecipients = useCallback((data) => {
    setSelectedRecipients(data?.addedContacts);
    onAssignRecipients({
      add: data?.addedContacts.map((id) => ({ contactId: id })),
      remove: data?.removedContacts.map((id) => ({ contactId: id })),
    });
    closeModal();
  }, [
    setSelectedRecipients,
    closeModal,
    onAssignRecipients,
  ]);

  return (
    <>
      <Button
        variant="primary"
        onClick={openModal}
      >
        Next
      </Button>

      <AssignRecipients
        isVisible={isModalVisible}
        closeModal={closeModal}
        selectedContacts={selectedRecipients}
        onSave={assignRecipients}
      />
    </>
  );
};

Next.defaultProps = {

};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

Next.propTypes = {

};

export default Next;
