import React from 'react';
import PropTypes from 'prop-types';

import { currency } from '@symplete/symplete-utils/numbers';

import { makeStyles } from '@material-ui/core/styles';
import Grid from 'ui/Grid';
import Typography from 'ui/Typography';
import RoleLabel from 'sections/Leads/components/RoleLabel';

import PropertyType from './PropertyType';

import {
  Root,
} from './styledItems';

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    padding: '5px 0',
  },
});

const formatValue = (value) => currency.short(value);

const getPrice = (value = '') => {
  if (Array.isArray(value)) {
    return `${formatValue(value[0])}–${formatValue(value[1])}`;
  }

  return formatValue(value);
};

const RealtorLeadItemDetails = (props) => {
  const {
    contactName,
    role,
    expectedSalePrice,
    expectedPurchasePrice,
    propertyType,
    location,
  } = props;

  const classes = useStyles();

  return (
    <Root>
      <Grid
        container
        xs={12}
        spacing={2}
        alignItems="center"
        className={classes.root}
      >
        <Grid item xs={3}>
          <Typography variant="h5" weight={600} noMargin>
            {contactName}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <RoleLabel role={role} />
        </Grid>
        <Grid item xs={2}>
          {
            getPrice(expectedSalePrice || expectedPurchasePrice)
          }
        </Grid>
        <Grid item xs={2}>
          <PropertyType value={propertyType} />
        </Grid>
        <Grid item xs={3}>
          {location}
        </Grid>
      </Grid>
    </Root>
  );
};

const {
  oneOfType,
  arrayOf,
  string,
  number,
} = PropTypes;

RealtorLeadItemDetails.propTypes = {
  contactName: string.isRequired,
  role: number.isRequired,
  propertyType: oneOfType([arrayOf(string), string]).isRequired,
  expectedSalePrice: number.isRequired,
  expectedPurchasePrice: arrayOf(number).isRequired,
  location: string.isRequired,
};

export default RealtorLeadItemDetails;
