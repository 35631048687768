import { connect } from 'react-redux';

import selectors from 'store/selectors';

import { debounce } from 'shared/utility';

import {
  updateTransaction,
} from 'store/actions/realtorTransactions';

import Textfield from 'ui/Textfield';

const stageNameDict = {
  'initial documents': 'initial_documents_notes',
  'seller disclosure': 'seller_disclosure_notes',
  inspections: 'inspections_notes',
  appraisal: 'appraisal_notes',
  loan: 'loan_notes',
  'final walkthrough': 'final_walkthrough_notes',
  'close escrow': 'close_escrow_notes',
  'move out date': 'move_out_date_notes',
};

const mapStateToProps = (state, ownProps) => {
  const {
    transactionId,
    stage,
  } = ownProps;

  const transaction = selectors.realtorTransactions.byId(state, transactionId);

  const key = stageNameDict[stage];

  const value = transaction?.[key];

  return {
    // multiline: true,
    // rows: 5,
    value,
    textarea: true,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: debounce((value) => {
    const key = stageNameDict[ownProps.stage];

    dispatch(updateTransaction(ownProps.transactionId)({
      [key]: value,
    }));
  }, 1000),
});

export default connect(mapStateToProps, mapDispatchToProps)(Textfield);
