import React from 'react';
import PropTypes from 'prop-types';

import { currency } from '@symplete/symplete-utils/numbers';

import Grid from 'ui/Grid';

import PropertyParametersIndicators from 'sections/Opportunities/modules/PropertyParametersIndicators';
import PropertyImage from 'sections/Opportunities/modules/PropertyImage';

import Typography from 'ui/Typography';
import Button from 'ui/Button';

import {
  Wrapper,
  CenteredBox,
} from './styledItems';

const MlsItem = (props) => {
  const {
    id,
    onAction,
    isSelected,
    address,
    expected_price,
  } = props;

  return (
    <Wrapper>
      <Grid container justify="space-between" spacing={1}>
        <Grid item xs={6}>
          <Grid container spacing={1}>
            <Grid item xs={2} style={{ display: 'flex' }}>
              <PropertyImage propertyId={id} />
            </Grid>
            <Grid item xs={10} style={{ display: 'flex' }}>
              <CenteredBox>
                <Typography
                  variant="body"
                  className="address-text"
                  noMargin
                >
                  {address}
                </Typography>

                <Typography
                  variant="body"
                  weight={500}
                  noMargin
                >
                  {currency.full(expected_price)}
                </Typography>
              </CenteredBox>
            </Grid>
          </Grid>

        </Grid>
        <Grid item xs={6} style={{ display: 'flex' }}>
          <CenteredBox>
            <Grid container justify="flex-end" spacing={1}>
              <Grid item style={{ display: 'flex' }}>
                <CenteredBox>
                  <PropertyParametersIndicators propertyId={id} />
                </CenteredBox>
              </Grid>
              <Grid item style={{ display: 'flex' }}>
                <CenteredBox>
                  <Button
                    onClick={onAction(id)(isSelected ? 'add' : 'remove')}
                    variant={isSelected ? 'primary' : 'cancel'}
                    dense
                  >
                    {isSelected ? '+ Add' : 'Remove'}
                  </Button>
                </CenteredBox>
              </Grid>
            </Grid>
          </CenteredBox>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

const {
  number,
  string,
  bool,
  func,
} = PropTypes;

MlsItem.propTypes = {
  id: number.isRequired,
  onAction: func.isRequired,
  isSelected: bool.isRequired,
  address: string.isRequired,
  expected_price: number.isRequired,
};

export default MlsItem;
