import React from 'react';
import PropTypes from 'prop-types';

import { NakedButton } from '@symplete/symplete-ui';
import Grid from 'ui/Grid';

import MatchingBuyerView from 'sections/Clients/modules/MatchingBuyerView';
import Typography from 'ui/Typography';

const ListingFocusView = (props) => {
  const {
    onAssignInterestedPartyClick,
  } = props;

  return (
    <Grid container spacing={4}>

      <Grid item xs={12}>
        <NakedButton onClick={onAssignInterestedPartyClick}>
          <Typography
            variant="button"
            color="link"
            weight={600}
          >
            + Assign a new interested party
          </Typography>
        </NakedButton>
      </Grid>

      <MatchingBuyerView />
    </Grid>
  );
};

const {
  func,
} = PropTypes;

ListingFocusView.propTypes = {
  onAssignInterestedPartyClick: func.isRequired,
};

export default ListingFocusView;
