import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import Elevation from 'ui/Elevation';

import ErrorBoundary from 'ui/ErrorBoundary';

import MatchingBuyerView from 'sections/Clients/modules/MatchingBuyerView';
import SellerProperty from 'sections/Clients/modules/SellerProperty';

import FollowUpRemindersModal from 'shared/modules/FollowUpRemindersModal';

import Contact from 'sections/Clients/modules/Contact';

import {
  Root,
} from './styledItems';

const ClientItem = (props) => {
  const {
    clientId, // eslint-disable-line no-unused-vars
  } = props;

  return (
    <ErrorBoundary>
      <Root>
        <Flex
          fullWidth
          spacing={2}
          flexWrap="wrap"
          alignItems="stretch"
        >
          <FlexItem xs={18}>
            <Elevation
              rounded="s"
              padding="m"
              fullHeight
            >
              <SellerProperty
                clientId={clientId}
                modalProps={{
                  title: 'Property & Agreement Details',
                }}
              />
            </Elevation>
          </FlexItem>

          <FlexItem xs={6}>
            <Contact
              clientId={clientId}
            />
          </FlexItem>

          <FlexItem fullWidth>
            <Elevation
              rounded="s"
              padding="m"
            >
              <MatchingBuyerView
                clientId={clientId}
              />
            </Elevation>
          </FlexItem>
        </Flex>
      </Root>

      <FollowUpRemindersModal
        objectId={clientId}
        objectTypeId={29}
      />
    </ErrorBoundary>
  );
};

ClientItem.defaultProps = {
  clientId: null,
};

const {
  number,
  string,
  oneOfType,
} = PropTypes;

ClientItem.propTypes = {
  clientId: oneOfType([number, string]),
};

export default ClientItem;
