import { connect } from 'react-redux';

import { callAlert } from 'ui/Alert';

import selectors from 'store/selectors';

import {
  getExternalToken,
} from 'store/actions/socialIntegrations';

import FacebookPost from './FacebookPost';

const appUrl = process.env.REACT_APP_CLIENT_APP_URL;

const mapStateToProps = (state, ownProps) => {
  const {
    socialIntegrations: {
      facebook: {
        userPages,
      },
      externalToken,
    },
    properties: {
      selectedProperty,
    },
  } = state;

  const image = selectors.properties.propertyMainImage(state, selectedProperty);
  const propertyImage = image?.previewUrl;

  const property = selectors.properties.byId(state, selectedProperty);
  const address = selectors.addresses.byId(state, property?.address_id)?.address;

  return {
    fbUserPages: userPages || [],
    propertyImage,
    // propertyImage: propertyImage && 'https://i.ibb.co/nbgyZt1/house-2.jpg',
    propertyAddress: address,
    externalToken,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getExternalToken: async () => {
    const token = await dispatch(getExternalToken());
    return token;
  },
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  createFacebookPost: async ({
    pageId,
    text,
    shouldAddLeadFormLink,
    onSuccess,
    onError,
  }) => {
    const {
      fbUserPages,
      propertyImage,
      externalToken,
    } = stateProps;

    const userPage = fbUserPages.find((item) => item?.id === pageId);
    const pageToken = userPage?.access_token;

    let finalText = text;

    if (shouldAddLeadFormLink) {
      let externalAccessToken = externalToken;
      if (!externalAccessToken) {
        externalAccessToken = await dispatchProps.getExternalToken();
      }
      const link = externalAccessToken && `${appUrl}/l/${externalAccessToken}`;
      if (link) {
        finalText = `${text}\n\n${link}`;
      }
    }

    let endpoint;
    const params = {
      access_token: pageToken,
    };

    if (propertyImage) {
      endpoint = `/${pageId}/photos`;
      params.url = propertyImage;
      params.caption = finalText;
    } else {
      endpoint = `/${pageId}/feed`;
      params.message = finalText;
    }

    window.FB.api(
      endpoint,
      'POST',
      params,
      (response) => {
        if (response?.error) {
          callAlert.error('Could not post to Facebook');
          onError();
        } else {
          callAlert.success('Posted to Facebook successfully');
          onSuccess();
        }
      },
    );
  },
  createInstagramPost: async ({
    fbUserAccessToken,
    instagramAccountId,
    text,
    shouldAddLeadFormLink,
    onSuccess,
    onError,
  }) => {
    const {
      propertyImage,
      externalToken,
    } = stateProps;

    if (!propertyImage) {
      callAlert.error('Can\'t post to Instagram without property image');
      onError();
      return;
    }

    let finalText = text;

    if (shouldAddLeadFormLink) {
      let externalAccessToken = externalToken;
      if (!externalAccessToken) {
        externalAccessToken = await dispatchProps.getExternalToken();
      }
      const link = externalAccessToken && `${appUrl}/l/${externalAccessToken}`;
      if (link) {
        finalText = `${text}\n\n${link}`;
      }
    }

    const params = {
      access_token: fbUserAccessToken,
      image_url: propertyImage,
      caption: finalText,
    };

    window.FB.api(
      `/${instagramAccountId}/media`,
      'POST',
      params,
      (response) => {
        if (response?.error) {
          callAlert.error('Could not post to Instagram');
          onError();
        } else {
          window.FB.api(
            `/${instagramAccountId}/media_publish`,
            'POST',
            {
              access_token: fbUserAccessToken,
              creation_id: response.id,
            },
            (res) => {
              if (res?.error) {
                callAlert.error('Could not post to Instagram');
                onError();
              } else {
                callAlert.success('Posted to Instagram successfully');
                onSuccess();
              }
            },
          );
        }
      },
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(FacebookPost);
