import { connect } from 'react-redux';

import { openInspectionNotesModal } from 'store/actions/realtorInspections';

import NoteCell from 'shared/components/UI/TableCells/NoteCell';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: () => {
    dispatch(openInspectionNotesModal(ownProps.inspectionId));
  },
});

export default connect(null, mapDispatchToProps)(NoteCell);
