import {
  useCallback,
} from 'react';

const GOOGLE_OAUTH_AUTH_URL = 'https://accounts.google.com/o/oauth2/v2/auth';

const config = {
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  redirectUri: `${process.env.REACT_APP_SYMPLETE_CLIENT_API}/api/google`,
};

const gmailAuthData = {
  response_type: 'code',
  access_type: 'offline',
  client_id: config.googleClientId,
  redirect_uri: config.redirectUri,
  // Note: this is only for testing to ensure a refresh token is
  // passed every time, but requires the user to approve offline
  // access every time. You should remove this if you don't want
  // your user to have to approve access each time they connect
  prompt: 'consent',
};

const params = new URLSearchParams(gmailAuthData);

const useGoogleAuth = (scope, customDataString) => {
  const auth = useCallback(() => {
    params.set('state', customDataString);

    params.set('scope', scope);

    const googleAuthUri = `${GOOGLE_OAUTH_AUTH_URL}?${params.toString()}`;

    window.location.href = googleAuthUri;
  }, [
    customDataString,
    scope,
  ]);

  return {
    auth,
  };
};

export default useGoogleAuth;
