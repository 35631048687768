import React from 'react';
import PropTypes from 'prop-types';

import withPropertyData from 'sections/Properties/hocs/withPropertyData';

import Typography from 'ui/Typography';

import formatValue from '../formatValue';

const PropertyPrice = (props) => {
  const {
    property,
  } = props;

  return (
    <Typography variant="cell">
      {formatValue(property.expected_price)}
    </Typography>
  );
};

PropertyPrice.defaultProps = {
  property: {},
};

const {
  number,
  shape,
} = PropTypes;

PropertyPrice.propTypes = {
  property: shape({
    expected_price: number,
  }),
};

export default withPropertyData(PropertyPrice);
