import styled from 'styled-components';

export const ItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  min-width: 75px;
  padding: 8px 10px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.palette.backgroundFlat};
`;

export default {
  ItemWrapper,
};
