import React from 'react';
import PropTypes from 'prop-types';

import { NakedButton } from '@symplete/symplete-ui';
import Icon from 'ui/Icon';
import Typography from 'ui/Typography';

import { IconContainer } from './styledItems';

const AgreementButton = (props) => {
  const {
    onClick,
    hasAttachments,
    buttonLabelEmpty,
    buttonLabelComplete,
  } = props;

  return (
    <NakedButton onClick={onClick}>
      <IconContainer>
        <Icon
          name={hasAttachments
            ? 'file-text'
            : 'upload'}
          color="focus"
          size="xl"
        />
        <Typography
          variant="caption"
          color="focus"
        >
          {hasAttachments
            ? buttonLabelComplete
            : buttonLabelEmpty}
        </Typography>
      </IconContainer>
    </NakedButton>
  );
};

const { func } = PropTypes;

AgreementButton.propTypes = {
  onClick: func.isRequired,
};

export default AgreementButton;
