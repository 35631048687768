import { connect } from 'react-redux';

import selectors from 'store/selectors';

import ContactCell from './ContactCell';

const mapStateToProps = (state, ownProps) => {
  const {
    contactId,
  } = ownProps;

  const contact = selectors.realtorContacts.byId(state, contactId);

  return {
    contact: {
      avatarSrc: contact.avatar_url,
      firstName: contact.first_name,
      lastName: contact.last_name,
    },
  };
};

export default connect(mapStateToProps)(ContactCell);
