/* eslint-disable */
//TODO fix eslint error
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

export function withAuth(WrappedComponent) {
  class Auth extends Component {
    render() {
      const {
        history: {
          location: {
            pathname,
          },
        },
        isAuthenticated,
      } = this.props;

      return isAuthenticated ? (
        <WrappedComponent {...this.props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { 
              linkFrom: pathname,
            },
          }}
        />
      );
    }
  }
  return connect(
    (state) => ({
      isAuthenticated: state.auth.isAuthenticated,
    }),
  )(Auth);
}

export function withoutAuth(WrappedComponent) {
  class Auth extends Component {
    render() {
      const {
        isAuthenticated,
        location: {
          state,
        },
      } = this.props;

      return isAuthenticated ? (
        <Redirect to={state ? state.linkFrom : '/'} />
      ) : (
        <WrappedComponent {...this.props} />
      );
    }
  }
  return connect((state) => ({
    isAuthenticated: state.auth.isAuthenticated,
  }))(Auth);
}
