import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { NakedButton } from '@symplete/symplete-ui';
import Typography from 'ui/Typography';

const AddContactButton = (props) => {
  const {
    onClick,
    field,
  } = props;

  const handleClick = useCallback((event) => {
    event.stopPropagation();
    onClick();
  }, [
    onClick,
  ]);

  return (
    <NakedButton onClick={handleClick}>
      <Typography
        variant="button"
        weight={400}
        textDecoration="underline"
      >
        {
          field === 'buyer' && '+ add buyer'
        }
        {
          field === 'buyerAgent' && '+ add buyer agent'
        }
        {
          field === 'sellerAgent' && '+ add listing agent'
        }
      </Typography>
    </NakedButton>
  );
};

const {
  oneOf,
  func,
} = PropTypes;

AddContactButton.propTypes = {
  onClick: func.isRequired,
  field: oneOf(['buyer', 'buyerAgent']).isRequired,
};

export default AddContactButton;
