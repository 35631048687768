import {
  useState,
  useCallback,
} from 'react';

import requests from 'api/requests';

import useActivePhones from 'sections/Calls/hooks/useActivePhones';

const getPhoneValidationCode = async (phoneNumber, { onAlreadyValidated }) => {
  try {
    const result = await requests.calls.outgoingCaller.create(phoneNumber);

    return result?.data;
  } catch (error) {
    if (error?.message === 'already validated') {
      onAlreadyValidated();
    } else {
      throw error;
    }
    return null;
  }
};

const checkPhoneIsCaller = async (phoneNumber) => {
  const result = await requests.calls.isPhoneACaller.get(phoneNumber);
  return result?.data;
};

const usePhoneValidationCode = (phoneNumber) => {
  const [code, setCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [verifiedStatus, setVerifiedStatus] = useState(0);

  const {
    getActivePhones,
  } = useActivePhones();

  const getCode = useCallback(async () => {
    setIsLoading(true);
    const newCode = await getPhoneValidationCode(phoneNumber, { onAlreadyValidated: getActivePhones });
    setCode(newCode);
    setIsLoading(false);
  }, [
    phoneNumber,
    setCode,
    setIsLoading,
  ]);

  const getVerification = useCallback(async () => {
    setIsChecking(true);
    const { isVerified } = await checkPhoneIsCaller(phoneNumber);
    setVerifiedStatus(isVerified ? 1 : 2);
    setIsChecking(false);
  }, [
    phoneNumber,
    setVerifiedStatus,
    setIsChecking,
  ]);

  return {
    code,
    getCode,
    isLoading,
    verifiedStatus,
    getVerification,
    isChecking,
  };
};

export default usePhoneValidationCode;
