import React, {
  Fragment,
} from 'react';

import Flex from 'ui/Flex';
import Tooltip from 'ui/Tooltip';

import useCampaigns from 'sections/Communications/hooks/useCampaigns';

import StatItem from './StatItem';

const statItems = [
  {
    key: 'emails_sent_today',
    title: 'Sent Today',
    countAttention: 489,
    countWarning: 500,
  },
  {
    key: 'emails_sent_this_week',
    title: 'Sent This Week',
    countAttention: 3489,
    countWarning: 3500,
  },
  {
    key: 'emails_sent_this_month',
    title: 'Sent This Month',
    countAttention: 14489,
    countWarning: 15000,
  },
];

const getStatVariant = (value, attentionCount, warningCount) => {
  let variant = 'default';
  if (!value) {
    return variant;
  }

  if (value > attentionCount) {
    variant = 'attention';
  }
  if (value > warningCount) {
    variant = 'warning';
  }
  return variant;
};

const tooltipTitle = `
  Sending bulk emails to more than 500 contacts per day may result in your email address being
  permanently blacklisted by spam filters.
`;

const SendSummary = (props) => {
  const {
    campaignsSummary,
  } = useCampaigns();

  return (
    <Tooltip title={tooltipTitle}>
      <div>
        <Flex
          spacing={1}
          alignItems="center"
        >
          {
            statItems.map((item) => (
              <Fragment key={item.key}>
                <StatItem
                  value={campaignsSummary[item.key] || 0}
                  title={item.title}
                  variant={getStatVariant(campaignsSummary[item.key], item.countAttention, item.countWarning)}
                />
              </Fragment>
            ))
          }
        </Flex>
      </div>
    </Tooltip>
  );
};

SendSummary.defaultProps = {

};

export default SendSummary;
