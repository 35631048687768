import styled from 'styled-components';

export const RootWrapper = styled.header`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 90px;
  background-color: ${(props) => props?.theme?.palette?.navColorSecondary || 'gray'};
`;

export const CustomSectionIconWrapper = styled.div`
  padding: 0 20px;
  display: flex;
`;

export const Border = styled.div`
  border-right: solid 1px ${(props) => props?.theme?.palette?.navColorDivider || 'gray'};
  margin: auto 20px;
  opacity: 0.3;
  height: 28px;
`;

export const CenteredWrapper = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;

export const SectionToolsWrapper = styled.div`
  flex: 1;
  padding: 0 15px;
  display: flex;
  width: 100%;

  a {
    white-space: nowrap
  }
`;

export default {
  RootWrapper,
  CustomSectionIconWrapper,
  SectionToolsWrapper,
  Border,
};
