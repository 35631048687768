import styled from 'styled-components';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';

export const UploadWrapper = styled.div`
  .ant-upload-drag {
    background: ${(props) => props.theme.palette['background-flat']};
    border-radius: 10px;
    text-align: left;
    border: none;
  }

  .ant-upload.ant-upload-drag .ant-upload {
    /* padding: 50px 20px; */
  }
`;

export const RadioButtonsWrapper = styled(FormControl)`
  && {
    height: 100%;
  }
  legend {
    height: 48px;
    margin-bottom: 10px;
    line-height: 52px;
    text-align: center;
  }
`;

export const RadioButtons = styled(RadioGroup)`
  && {
    height: 100%;
    justify-content: space-between;
  }
`;

export const RadioButton = styled(Radio)`
  && {
    height: 66px;
    width: 66px;
    position: relative;
  }
  input {
    position: absolute;
    left: 25px;
    top: 25px;
  }
`;
