import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

import Tooltip from 'ui/Tooltip';

import useAddress from 'shared/hooks/addresses/useAddress';

const LocationCell = (props) => {
  const {
    addressId,
  } = props;

  if (!addressId) return '—';

  const address = useAddress(addressId);

  return (
    <Tooltip title={address}>
      <Typography
        ellipsis
        variant="cellLarge"
      >
        {address}
      </Typography>
    </Tooltip>
  );
};

LocationCell.defaultProps = {
  address: null,
};

const {
  string,
} = PropTypes;

LocationCell.propTypes = {
  address: string,
};

export default LocationCell;
