import { connect } from 'react-redux';

import selectors from 'store/selectors';

import actions from 'sections/Clients/actions';

import NewOfferModal from 'sections/Offers/modals/NewOfferModal';

const mapStateToProps = (state, ownProps) => {
  const {
    realtor_property_id: propertyId,
    realtor_contact_id: contactId,
  } = selectors.realtorMatchingProperties.byId(ownProps.matchId)(state);

  const client = selectors.realtorClients.byId(state, ownProps.clientId);

  return {
    propertyId: propertyId || ownProps.propertyId,

    seller: client.contact_id,
    buyer: contactId,
    sellerAgent: 0,

    representing: 'seller',

    clientId: ownProps.clientId,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSuccess: () => {
    actions.kanban.updateMatch(ownProps.matchId, ownProps.clientId)({ status: 3 });
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(NewOfferModal);
