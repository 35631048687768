import React from 'react';
import PropTypes from 'prop-types';

import { NakedButton } from '@symplete/symplete-ui';

import Grid from 'ui/Grid';
import Typography from 'ui/Typography';

import TodaysViewings from 'sections/Prospects/modules/TodaysViewings';
import FutureViewings from 'sections/Prospects/modules/FutureViewings';
import PastViewings from 'sections/Prospects/modules/PastViewings';

const ViewingsView = (props) => {
  const {
    onCreateViewingClick,
  } = props;

  return (
    <Grid container spacing={4}>

      <Grid item xs={12}>
        <NakedButton onClick={onCreateViewingClick}>
          <Typography
            variant="button"
            color="link"
            weight={600}
          >
            + Book new showing
          </Typography>
        </NakedButton>
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h3"
          weight={500}
        >
          Showings Today
        </Typography>
        <TodaysViewings />
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h3"
          weight={500}
        >
          Scheduled Showings
        </Typography>
        <FutureViewings />
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h3"
          weight={500}
        >
          Completed Showings
        </Typography>
        <PastViewings />
      </Grid>

    </Grid>
  );
};

const {
  func,
} = PropTypes;

ViewingsView.propTypes = {
  onCreateViewingClick: func.isRequired,
};

export default ViewingsView;
