import { connect } from 'react-redux';
import store from 'store';
import moment from 'moment';

import {
  optimizedRealtorMatchedActions,
} from 'store/actions/realtorMatched';

import {
  optimizedMatchingPropertiesActions,
} from 'store/actions/realtorMatchingProperties';

import {
  optimizedPropertyViewingActions,
} from 'store/actions/propertyViewings';

import {
  setNewPropertyViewingFormField,
} from 'store/actions/newRealtorPropertyViewingForm';

import {
  openNewPropertyViewingModal,
} from 'store/actions/newRealtorPropertyViewingModal';

import {
  openModal,
} from 'store/actions/modals';

import {
  openAssignInterestedPartyModal,
} from 'store/actions/realtorAssignInterestedPartyModal';

import ListingFocus from './ListingFocus';

const mapStateToProps = (state) => {
  const {
    realtorMatched: {
      matchedByFilter,
      matchedByFilterCount,
    },
    realtorMatchingProperties: {
      matchingPropertiesByFilter,
      matchingPropertiesCountByFilter,
    },
    propertyViewings: {
      viewingsByFilter,
      viewingsByFilterCount,
    },
    properties: {
      selectedProperty,
    },
  } = state;

  const data = [
    {
      title: 'Matched',
      items: matchedByFilter?.[`${selectedProperty}-property-page-kanban`],
      itemsCount: matchedByFilterCount?.[`${selectedProperty}-property-page-kanban`],
      objectKey: 'matched',
      loadMore: (offset, limit, resetState, filter) => {
        optimizedRealtorMatchedActions.getInfiniteScrollMatchedForFilter(`${selectedProperty}-property-page-kanban`,
          filter,
          offset,
          limit,
          resetState);
      },
      filterItems: [
        {
          label: 'Matched',
          value: 'matched',
          filter: { realtor_property_id: selectedProperty },
        },
        {
          label: 'Not Interested',
          value: 'notInterested',
          filter: { realtor_property_id: selectedProperty },
        },
      ],
      onHeaderButtonClick: (filter) => () => {
        store.dispatch(openModal('matchedParty')({
          callbacks: {
            onAfterClose: () => {
              optimizedRealtorMatchedActions.getInfiniteScrollMatchedForFilter(
                `${selectedProperty}-property-page-kanban`,
                filter, 0, 20, true,
              );
            },
          },
        }));
      },
    },
    {
      title: 'Arrange Showing',
      items: matchingPropertiesByFilter?.[`${selectedProperty}-property-page-kanban`],
      itemsCount: matchingPropertiesCountByFilter?.[`${selectedProperty}-property-page-kanban`],
      objectKey: 'arrangeShowing',
      loadMore: (offset, limit, resetState, filter) => {
        optimizedMatchingPropertiesActions.getInfiniteScrollMatchingPropertiesForFilter(
          `${selectedProperty}-property-page-kanban`,
          filter, offset, limit, true,
        );
      },
      filterItems: [
        {
          label: 'TBA',
          value: 'tba',
          filter: {
            property_id: selectedProperty,
          },
        },
        {
          label: 'Canceled',
          value: 'canceled',
          filter: {
            property_id: selectedProperty,
          },
        },
      ],
      onHeaderButtonClick: (filter) => () => {
        store.dispatch(openAssignInterestedPartyModal({
          onSave: () => {
            optimizedMatchingPropertiesActions.getInfiniteScrollMatchingPropertiesForFilter(
              `${selectedProperty}-property-page-kanban`,
              filter, 0, 20, true,
            );
          },
        }));
      },
    },
    {
      title: 'Showing',
      items: viewingsByFilter?.[`${selectedProperty}-property-page-kanban`],
      itemsCount: viewingsByFilterCount?.[`${selectedProperty}-property-page-kanban`],
      loadMore: (offset, limit, resetState, filter) => {
        optimizedPropertyViewingActions.getInfiniteScrollShowingsForFilter(`${selectedProperty}-property-page-kanban`,
          filter, offset, limit, resetState);
      },
      objectKey: 'showing',
      filterItems: [
        {
          label: 'Upcoming',
          value: 'upcoming',
          filter: {
            property_id: selectedProperty,
            date_from: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
          },
        },
        {
          label: 'Completed',
          value: 'completed',
          filter: {
            property_id: selectedProperty,
            date_to: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
          },
        },
        {
          label: 'Canceled',
          value: 'canceled',
          filter: {
            property_id: selectedProperty,
            show_deleted: true,
          },
        },
      ],
      onHeaderButtonClick: (filter) => () => {
        store.dispatch(setNewPropertyViewingFormField('onSave')(() => {
          optimizedPropertyViewingActions.getInfiniteScrollShowingsForFilter(
            `${selectedProperty}-property-page-kanban`, filter, 0, 20, true,
          );
        }));
        store.dispatch(openNewPropertyViewingModal(selectedProperty, { buyer: true, buyerAgent: true }));
      },
    },
    // {
    //   title: 'Offers',
    //   items: [],
    //   itemsCount: 0,
    //   loadMore: (offset, limit) => { },
    //   objectKey: 'offers',
    //   filterItems: [
    //     {
    //       label: 'Offers',
    //       value: 'offers',
    //       filter: {},
    //     },
    //     {
    //       label: 'Counters',
    //       value: 'counters',
    //       filter: {},
    //     },
    //     {
    //       label: 'Rejected',
    //       value: 'rejected',
    //       filter: {},
    //     },
    //   ],
    //   onHeaderButtonClick: (filter) => () => { },
    // },
  ];

  return {
    columnData: data,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ListingFocus);
