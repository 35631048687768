import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Input, Select } from 'antd';

import InputGroup from 'shared/components/UI/InputGroup';

const { Option } = Select;

const options = [
  {
    key: 'all',
    label: 'All prospects/clients',
  },
  {
    key: 'active',
    label: 'Active prospects/clients',
  },
  {
    key: 'archived',
    label: 'Archived prospects/clients',
  },
  {
    key: 'prospects',
    label: 'Only prospects',
  },
  {
    key: 'clients',
    label: 'Only clients',
  },
];

const Search = (props) => {
  const {
    onSearch,
    handleCustomFilter,
  } = props;

  const handleSearch = useCallback((event) => {
    onSearch(event.target.value);
  });

  return (
    <InputGroup
      compact
    >
      <Select
        defaultValue="all"
        style={{ width: '40%' }}
        onChange={handleCustomFilter}
      >
        {
          options.map((option) => (
            <Option value={option.key}>
              {option.label}
            </Option>
          ))
        }
      </Select>
      <Input
        style={{ width: '60%' }}
        placeholder="Search"
        onChange={handleSearch}
      />
    </InputGroup>
  );
};

Search.defaultProps = {

};

const {
  func,
} = PropTypes;

Search.propTypes = {
  onSearch: func.isRequired,
  handleCustomFilter: func.isRequired,
};

export default Search;
