import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import PlacesAutocomplete from 'shared/components/UI/PlacesAutocompleteV2';

const PropertyAddressInput = (props) => {
  const {
    handleChange,
    value,
  } = props;

  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [
    value,
  ]);

  const onChange = useCallback((location) => {
    setCurrentValue(location?.formatted_address);
    handleChange({
      address: location?.formatted_address,
    });
  }, [
    handleChange,
  ]);

  return (
    <PlacesAutocomplete
      label="Property address"
      variant="outlined"
      placeholder="Property address"
      defaultValue={currentValue}
      onSelect={onChange}
      onChange={onChange}
      selectedValue={currentValue}
      value={currentValue}
    />
  );
};

PropertyAddressInput.defaultProps = {
  value: null,
};

const {
  func,
  string,
} = PropTypes;

PropertyAddressInput.propTypes = {
  handleChange: func.isRequired,
  value: string,
};

export default PropertyAddressInput;
