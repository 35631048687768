import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import {
  useEditor,
} from '@tiptap/react';

import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import Image from '@tiptap/extension-image';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';

import Context from './context';

import TemplateItemNodeReact from './CustomExtensions/TemplateItemNodeReact';
import TemplateItemNode from './CustomExtensions/TemplateItemNode';

const ContextProvider = (props) => {
  const {
    children,
    onChange,
    placeholder,
    content = '',
  } = props;

  const onUpdate = useCallback(({ editor }) => { // eslint-disable-line no-shadow
    onChange(editor.getHTML());
  }, [
    onChange,
  ]);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder,
      }),
      Underline,
      Link, // needs modal to enter link
      Image,
      Image.configure({
        allowBase64: true,
      }),
      Table,
      TableRow,
      TableHeader,
      TableCell,
      TemplateItemNodeReact,
      TemplateItemNode,
    ],
    autofocus: true,
    onUpdate,
    content,
  });

  return (
    <Context.Provider
      value={{
        editor,
      }}
    >
      { children }
    </Context.Provider>
  );
};

ContextProvider.defaultProps = {
  placeholder: 'Start typing here...',
  content: '',
};

const {
  element,
  func,
  string,
} = PropTypes;

ContextProvider.propTypes = {
  children: element.isRequired,
  onChange: func.isRequired,
  placeholder: string,
  content: string,
};

export default ContextProvider;
