import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

const PropertyRejectionReasonCell = (props) => {
  const {
    reason,
  } = props;

  return (
    <Typography variant="cell" style={{ textTransform: 'capitalize' }}>
      {reason}
    </Typography>
  );
};

PropertyRejectionReasonCell.defaultProps = {
  reason: '—',
};

const {
  string,
} = PropTypes;

PropertyRejectionReasonCell.propTypes = {
  reason: string,
};

export default PropertyRejectionReasonCell;
