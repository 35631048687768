import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PropertyParametersIndicators from 'shared/modules/PropertyParametersIndicators';

import { Tooltip } from 'antd';

import PropertyImageCell from 'shared/components/UI/TableCells/RealtorCells/PropertyImageCell';
import PropertyLocationCell from 'shared/components/UI/TableCells/RealtorCells/PropertyLocationCell';
import PropertyStatusCell from 'shared/components/UI/TableCells/RealtorCells/PropertyStatusCell';
import PropertyLastEndedViewingCell from 'shared/components/UI/TableCells/RealtorCells/PropertyLastEndedViewingCell';
import PropertyPriceCell from 'shared/components/UI/TableCells/RealtorCells/PropertyPriceCell';
import PropertyRejectionReasonCell from 'shared/components/UI/TableCells/RealtorCells/PropertyRejectionReasonCell';

import ActionButton from './ActionButton';

const columns = ({
  hoveredRecord,
}) => ([
  {
    title: '',
    dataIndex: 'property_id',
    key: 'image',
    className: 'realtor-properties-table__xs-padding',
    width: 70,
    align: 'center',
    render: (value) => <PropertyImageCell propertyId={value} />,
  },
  {
    title: 'Address',
    dataIndex: 'address_id',
    width: 250,
    key: 'address',
    render: (value, entry) => <PropertyLocationCell addressId={value} />,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 120,
    render: (value) => <PropertyStatusCell status={value} />,
  },
  {
    title: 'Price',
    dataIndex: 'expected_price',
    key: 'price',
    width: 70,
    render: (value) => <PropertyPriceCell value={value} />,
  },
  {
    title: 'Details',
    dataIndex: 'property_id',
    key: 'propertyDetails',
    width: 250,
    render: (value) => <PropertyParametersIndicators propertyId={value} />,
  },
  {
    title: 'Viewed',
    dataIndex: 'id',
    key: 'viewed',
    width: 70,
    render: (value) => <PropertyLastEndedViewingCell propertyId={value} />,
  },
  {
    title: 'Rejection Reason',
    dataIndex: 'rejected_reasons',
    key: 'rejected_reasons',
    width: 100,
    render: (value) => (
      <PropertyRejectionReasonCell reasonIds={value} />
    ),
  },
  {
    title: 'Note',
    dataIndex: 'reject_reason_note',
    key: 'reject_reason_note',
    width: 50,
    render: (value, record) => (value
        ? (
          <Tooltip title={value}>
            <FontAwesomeIcon
              icon="sticky-note"
              size="lg"
              color={value ? 'rgba(0, 25, 52, 0.87)' : '#6b88a4'}
            />
          </Tooltip>
        )
        : '—'),
  },
  {
    title: '',
    key: 'actions',
    width: 50,
    render: (value, record) => {
      if (hoveredRecord === Number(record.id)) {
        return <ActionButton />;
      }

      return null;
    },
  },
]);

export default columns;
