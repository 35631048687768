/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import PropTypes, { bool } from 'prop-types';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Icon from 'ui/Icon';

import PropertyAddressInput from 'shared/inputs/PropertyAddressInput';

import withNewPropertyFormDefaultValue from 'sections/MLS/hocs/withNewPropertyFormDefaultValue';

import TempSelect from './TempSelect';
import AddContact from './AddContact';
import MLSWebsiteRaw from './MLSWebsite';
import ListedSalePriceInputRaw from './ListedSalePriceInput';
import OnMarketSince from './OnMarketSince';
import PropertyTypeSelectRaw from './PropertyTypeSelect';
import SellingAgent from './SellingAgent';
import ListingAgent from './ListingAgent';
import ImagesUpload from './ImagesUpload';
import ListingTypeSelect from './ListingTypeSelect';
import TransferState from './TransferState';
import LeasePriceInput from './LeasePriceInput/index';

const MLSWebsite = withNewPropertyFormDefaultValue(MLSWebsiteRaw)(
  'mls_website',
);
const ListedSalePriceInput = withNewPropertyFormDefaultValue(
  ListedSalePriceInputRaw,
)('expected_price');
const PropertyTypeSelect = withNewPropertyFormDefaultValue(
  PropertyTypeSelectRaw,
)('property_type');

const CommercialForm = (props) => {
  const {
    handleChange,
    selectedListingAgent,
    selectedSeller,
    onMarketSince,
    addressId,
    transferState,
    saveAttempt,
  } = props;

  const [myListing, setMyListing] = useState(true);
  const [listingType, setListingType] = useState(0);

  const toggleSection = (value) => {
    setListingType(value);
    setMyListing(!myListing);
  };

  useEffect(() => {
    if (!myListing) {
      handleChange('listing_agent')(null);
      handleChange('seller')(null);
    } else {
      handleChange('listing_agent')(0);
    }
  }, [myListing]);

  const showContactTitle = () => (myListing
      ? transferState === 'sale'
        ? 'Seller'
        : 'Owner'
      : transferState === 'sale'
      ? 'Listing Agent'
      : 'Leasing Agent');

  return (
    <form>
      <Flex flexDirection="column" spacing={3}>
        <Flex justifyContent="space-between" fullWidth>
          <FlexItem>
            <ListingTypeSelect onChange={toggleSection} value={listingType} />
          </FlexItem>
          <FlexItem>
            <TempSelect onChange={handleChange('temp')} />
          </FlexItem>
        </Flex>

        <Flex fullWidth flexDirection="column" spacing={2}>
          <Flex spacing={2}>
            <FlexItem>
              <Icon name="check-circle" color="focus" />
            </FlexItem>
            <FlexItem>
              <Typography variant="h3" noMargin>
                Property Details
              </Typography>
            </FlexItem>
          </Flex>
        </Flex>
        <Flex fullWidth flexDirection="column" spacing={2} style={{ paddingLeft: '32px' }}>
          <FlexItem fullWidth>
            <TransferState
              value={transferState}
              onChange={handleChange('transfer_state')}
            />
          </FlexItem>
          <FlexItem fullWidth>
            <PropertyAddressInput
              decorated
              useId
              placeholder="Property address"
              addressId={addressId}
              handleChange={handleChange('address_id')}
              error={saveAttempt && !addressId ? 'Address is required' : null}
              required
            />
          </FlexItem>

          <Flex fullWidth spacing={2}>
            <FlexItem fullWidth>
              {
                transferState === 'sale' ? (
                  <ListedSalePriceInput onChange={handleChange('expected_price')} />
                ) : (
                  <LeasePriceInput label="Lease price" onChange={handleChange('expected_price')} />
                )
              }
            </FlexItem>

            <FlexItem fullWidth>
              <PropertyTypeSelect
                handleChange={handleChange('property_type')}
              />
            </FlexItem>
          </Flex>

          <Flex fullWidth spacing={2}>
            <FlexItem fullWidth>
              <MLSWebsite
                icon="globe"
                label="MLS website address"
                onChange={handleChange('mls_website')}
              />
            </FlexItem>
          </Flex>
          {!myListing ? (
            <Flex fullWidth spacing={2}>
              <FlexItem fullWidth>
                <OnMarketSince
                  label="On market since"
                  value={onMarketSince}
                  handleChange={handleChange('on_market_since')}
                />
              </FlexItem>
              <FlexItem fullWidth />
            </Flex>
          ) : null}
        </Flex>

        <Flex spacing={2} fullWidth alignItems="center" flexDirection="column">
          <Flex fullWidth spacing={2}>
            <Flex spacing={2}>
              <FlexItem>
                <Icon name="user" color="focus" />
              </FlexItem>
              <FlexItem>
                <Typography variant="h3" noMargin>
                  {showContactTitle()}
                </Typography>
              </FlexItem>
            </Flex>
          </Flex>

          <Flex spacing={2} fullWidth alignItems="center" style={{ paddingLeft: '32px' }}>
            <FlexItem fullWidth>
              {myListing ? (
                <SellingAgent
                  handleChange={handleChange('seller')}
                  selectedContactId={selectedSeller}
                />
              ) : (
                <ListingAgent
                  handleChange={handleChange('listing_agent')}
                  selectedContactId={selectedListingAgent}
                />
              )}
            </FlexItem>
            <AddContact
              onSuccess={handleChange(myListing ? 'seller' : 'listing_agent')}
            />
          </Flex>
        </Flex>

        <Flex fullWidth flexDirection="column" spacing={2}>
          <Flex spacing={2}>
            <FlexItem>
              <Icon name="image" color="focus" />
            </FlexItem>
            <FlexItem>
              <Typography variant="h3" noMargin>
                Property Photos
              </Typography>
            </FlexItem>
          </Flex>
          <FlexItem fullWidth style={{ paddingLeft: '32px' }}>
            <ImagesUpload
              icon
              label="Drag & drop or click to upload property photos (5 max)"
            />
          </FlexItem>
        </Flex>
      </Flex>
    </form>
  );
};

CommercialForm.defaultProps = {
  selectedListingAgent: null,
  selectedSeller: null,
  onMarketSince: null,
  addressId: null,
  transferState: 'sale',
  saveAttempt: false,
};

const { func, number, string } = PropTypes;

CommercialForm.propTypes = {
  handleChange: func.isRequired,
  selectedSeller: number,
  selectedListingAgent: number,
  onMarketSince: string,
  addressId: number,
  transferState: string,
  saveAttempt: bool,
};

export default CommercialForm;
