import styled from 'styled-components';

import { Input as AntInput } from 'antd';

const InputGroup = styled(AntInput.Group)`
  --antd-wave-shadow-color: ${(props) => (props.theme?.palette ? props.theme?.palette?.highlight : '#1584ff')};
  font-family: ${(props) => props.theme?.typography?.fontFamily?.body || 'Roboto, Arial, Helvetica, sans-serif'};
  
  .ant-input,
  .ant-select-selection {
    
    &-selected-value {
      padding-left: 6px;
      color: ${(props) => props.theme?.palette?.primary || 'black'};
      font-weight: ${(props) => props.theme?.typography?.fontWeight?.bold || 'bold'};
      letter-spacing: normal;
    }

    &:focus,
    &:hover {
      border-color: ${(props) => (props.theme?.palette ? props.theme?.palette?.highlight : '#1584ff')};;
    } 

    &::placeholder {
      font-style: italic;
    }
  }

  > *:first-child {
    border-top-left-radius: 17px !important;
    border-bottom-left-radius: 17px !important;

    > .ant-select-selection {
      border-top-left-radius: 17px !important;
      border-bottom-left-radius: 17px !important;
    }
  }


  > *:last-child {
    border-top-right-radius: 17px !important;
    border-bottom-right-radius: 17px !important;
  }
`;

export default InputGroup;
