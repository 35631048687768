import leads from './leads';
import offers from './offers';
import request from './request';
import contact from './contact';

export default {
  leads,
  offers,
  request,
  contact,
};
