import React from 'react';

import PropTypes from 'prop-types';

import OpportunitiesContext from 'sections/OpportunitiesListing/context/OpportunitiesContext';

import usePropertyDetailsV2 from 'sections/OpportunitiesListing/hooks/usePropertyDetailsV2';
import usePropertiesByZip from 'sections/OpportunitiesListing/hooks/usePropertiesByZip';
import useTableData from 'sections/OpportunitiesListing/hooks/useTableData';
import useReserved from 'sections/OpportunitiesListing/hooks/useReserved';
import useView from 'sections/OpportunitiesListing/hooks/useView';
import useCart from 'sections/OpportunitiesListing/hooks/useCart';

const OpportunitiesProvider = (props) => {
  const {
    children,
  } = props;

  const {
    details: detailsByProperty,
    isLoading: isLoadingDetailsByProperty,
    error: errorByProperty,
    getDetails: getPropertyDetails,
  } = usePropertyDetailsV2();

  const {
    properties,
    getProperties,
    count: propertiesCountInZip,
    error: getPropertiesError,
    isLoading: isLoadingProperties,
  } = usePropertiesByZip();

  const {
    reserved,
    reserve,
    unreserve,
  } = useReserved();

  const {
    view,
    setView,
  } = useView();

  const {
    list: cartList,
    isLoading: cartLoading,
    error: cartError,
    ids: cartIds,
  } = useCart(reserved);

  const propertiesList = view === 'zip'
    ? properties
    : cartList;

  const propertiesCount = view === 'zip'
    ? propertiesCountInZip
    : cartList?.length;

  const {
    dataSource,
    setSymScoreRange,
    setPriceRange,
    setFilter,
    setOwnershipDuration,
  } = useTableData(propertiesList, detailsByProperty, cartIds);

  return (
    <OpportunitiesContext.Provider value={{
      properties,
      detailsByProperty,
      isLoadingDetailsByProperty,
      errorByProperty,
      getPropertyDetails,
      getProperties,
      propertiesCount,
      getPropertiesError,
      isLoadingProperties,
      dataSource,
      setSymScoreRange,
      setPriceRange,
      setOwnershipDuration,
      setFilter,

      reserved,
      reserve,
      unreserve,

      view,
      setView,

      cartList,
      cartLoading,
      cartError,
    }}
    >
      { children }
    </OpportunitiesContext.Provider>
  );
};

const {
  element,
} = PropTypes;

OpportunitiesProvider.propTypes = {
  children: element,
};

export default OpportunitiesProvider;
