import { connect } from 'react-redux';

import selectors from 'store/selectors';

import getDaysUntilClose from 'sections/Leads/utils/getDaysUntilClose';

import ProgressCircle from 'ui/ProgressCircle';

const mapStateToProps = (state, ownProps) => {
  const appSettings = selectors.appCustomization.settings(state);

  const item = ownProps.item?.type === 'property'
    ? selectors.properties.byId(state, ownProps.item?.itemId)
    : selectors.propertyRequirements.byId(state, ownProps.item?.itemId);

  const conversionGoal = appSettings?.lead_conversion_goal_days || 7;

  const days = getDaysUntilClose(item, conversionGoal);

  const progress = days / conversionGoal;

  return {
    progress,
    value: `${days} d`,
    label: 'to close',
    warning: 0,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ProgressCircle);
