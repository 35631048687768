import { connect } from 'react-redux';

import { debounce } from 'shared/utility';

import selectors from 'store/selectors';

import {
  updateTransaction,
  getTransactionAttachments,
} from 'store/actions/realtorTransactions';

import InitialDocuments from './InitialDocuments';

const mapStateToProps = (state, ownProps) => {
  const {
    transactionId,
  } = ownProps;

  const transaction = selectors.realtorTransactions.byId(state, transactionId);

  const depositAmount = transaction?.deposit_amount;
  const depositReceivedAt = transaction?.deposit_received_at;

  const attachments = transaction?.attachments?.['realtor transaction initial document'] || [];

  return {
    depositAmount,
    depositReceivedAt,
    attachments,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDepositAmountChange: debounce((value) => {
    dispatch(updateTransaction(ownProps.transactionId)({
      deposit_amount: value,
    }));
  }, 1000),
  onDepositReceivedAtChange: (value) => {
    dispatch(updateTransaction(ownProps.transactionId)({
      deposit_received_at: value,
    }));
  },
  refreshAttachments: () => {
    dispatch(getTransactionAttachments(ownProps.transactionId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InitialDocuments);
