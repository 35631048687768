import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateTransaction,
} from 'store/actions/realtorTransactions';

import DateTimeInput from 'shared/inputs/DateTimeInput';

const stageNameDict = {
  'initial documents': 'initial_documents_expires_at',
  'seller disclosure': 'seller_disclosure_expires_at',
  inspections: 'inspections_expires_at',
  appraisal: 'appraisal_expires_at',
  loan: 'loan_expires_at',
  'final walkthrough': 'final_walkthrough_expires_at',
  'close escrow': 'close_escrow_expires_at',
  'move out date': 'move_out_date_expires_at',
};

const mapStateToProps = (state, ownProps) => {
  const { stage, transactionId } = ownProps;

  const transaction = selectors.realtorTransactions.byId(state, transactionId);

  const transactionPropName = stageNameDict[stage];

  const expiryDate = transaction[transactionPropName];

  return {
    label: 'Expiry date',
    value: expiryDate,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleChange: (value) => {
    const transactionPropName = stageNameDict[ownProps.stage];

    dispatch(updateTransaction(ownProps.transactionId)({
      [transactionPropName]: value,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DateTimeInput);
