import { connect } from 'react-redux';

import selectors from 'store/selectors';

import Metrics from './Metrics';

const mapStateToProps = (state, ownProps) => {
  const property = selectors.properties.byId(state, ownProps.propertyId);

  return {
    data: {
      showingsToBeArranged: property?.active_matches,
      showingsBooked: property?.viewings_summary?.upcoming_viewings,
      showingsCompleted: property?.viewings_summary?.completed_viewings,
      offers: property?.offers,
    },
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(Metrics);
