import React, {
} from 'react';

import Emails from 'sections/Communications/modules/Emails';

const Communications = (props) => {
  return (
    <Emails />
  );
};

export default Communications;
