import styled from 'styled-components';

import Elevation from 'ui/Elevation';

export const Root = styled(Elevation)`
  .information-item {
    margin-left: -6px;
  }
`;

export default {
  Root,
};
