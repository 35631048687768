import React from 'react';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Typography from 'ui/Typography';

import IntegrationsProvider from 'sections/Integrations/IntegrationsProvider';

import IntegrationsProductivity from 'sections/Integrations/modules/IntegrationsProductivity';
import IntegrationsTable from 'sections/Integrations/modules/IntegrationsTable';

const Integrations = (props) => (
  <IntegrationsProvider>
    <Flex
      flexDirection="column"
      spacing={4}
    >
      <Typography>
        Connect your existing services to Symplete to get even more out of our account.
      </Typography>

      <FlexItem fullWidth>
        <Typography
          variant="title3"
        >
          Productivity
        </Typography>

        <IntegrationsProductivity />
      </FlexItem>

      <FlexItem fullWidth>
        <Typography
          variant="title2"
        >
          Active Integrations
        </Typography>

        <IntegrationsTable />
      </FlexItem>
    </Flex>
  </IntegrationsProvider>
);

export default Integrations;
