import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import { capitalizeWord } from 'shared/utils/strings';

const getLabelNameById = (list, id) => {
  const label = list.find((item) => item.id === id);
  return label?.name;
};

const ContactLabels = (props) => {
  const {
    allContactLabels,
    labelToShowInMainView,
  } = props;

  return (
    <>
      <Typography
        variant="cell2"
        color="descriptor-text"
      >
        {capitalizeWord(getLabelNameById(allContactLabels, labelToShowInMainView)) || ''}
      </Typography>
    </>
  );
};

ContactLabels.defaultProps = {
  labelToShowInMainView: null,
};

const {
  number,
  arrayOf,
  shape,
} = PropTypes;

ContactLabels.propTypes = {
  labelToShowInMainView: number,
  allContactLabels: arrayOf(shape({})).isRequired,
};

export default ContactLabels;
