import { connect } from 'react-redux';
import stableStringify from 'json-stable-stringify';

import selectors from 'store/selectors';

import {
  openFollowUpReminderModal,
} from 'store/actions/followUpReminderModal';

import {
  optimizedFollowUpRemindersActions,
} from 'store/actions/followUpReminders';

import FollowUp from 'shared/components/FollowUp';

const mapStateToProps = (state, ownProps) => {
  const {
    leadId,
  } = ownProps;

  const {
    followUpReminders: {
      remindersCountByFilter,
    },
  } = state;

  const lead = selectors.realtorLeads.byId(state, leadId);

  const {
    first_name,
    last_name,
  } = selectors.realtorContacts.byId(state, lead?.contact_id);

  const realtorLeadObjectTypeId = 26;
  const filter = {
    objectTypeId: realtorLeadObjectTypeId,
    objectId: leadId,
  };

  const followUpsCount = remindersCountByFilter[stableStringify(filter)];

  return {
    objectTitle: `${first_name || ''} ${last_name || ''}`,
    followUpsCount: followUpsCount || 0,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  openLeadRemindersModal: (objectTitle) => dispatch(openFollowUpReminderModal({
    objectTitle,
    title: null,
    comment: null,
    timeIntervalValue: 0,
    timeIntervalType: 2,
    scheduledDate: null,
    isRepeat: false,
  })),

  getLeadReminders: () => {
    const realtorLeadObjectTypeId = 26;
    const filter = {
      objectTypeId: realtorLeadObjectTypeId,
      objectId: ownProps.leadId,
    };

    optimizedFollowUpRemindersActions.getFollowUpRemindersForFilter(stableStringify(filter), filter);
  },
});

const mergeProps = (stateProps, dispatchProps) => ({
  getLeadReminders: () => dispatchProps.getLeadReminders(),
  openLeadRemindersModal: () => dispatchProps.openLeadRemindersModal(stateProps.objectTitle),
  followUpsCount: stateProps.followUpsCount,
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(FollowUp);
