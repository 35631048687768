import styled, { css } from 'styled-components';

import { NakedButton } from '@symplete/symplete-ui';

const getBackground = (props) => {
  if (props.disabled) return props.theme.palette.disabled;

  const variants = {
    focus: props.theme.palette.focus || 'black',
    subtle: props.theme.palette['background-flat-dark'] || 'lightgray',
    disabled: props?.theme?.palette?.disabled || 'gray',
  };

  return variants[props.variant] || 'green';
};

const commonRootStyles = css`
  display: flex;
  align-items: center;
  height: 30px;
  width: fit-content;
  background: ${getBackground};
  border-radius: 15px;
  padding-left: 16px;
  padding-right: ${(props) => (props.hasDeleteButton ? '11px' : '16px')};
  cursor: inherit;
`;

export const Root = styled.div`
  ${commonRootStyles}
  padding-right: ${(props) => (props.hasDeleteButton ? '11px' : '16px')};
  cursor: inherit;
`;

export const RootButton = styled(NakedButton)`
  && {
    ${commonRootStyles}
    padding-right: 16px;
    cursor: pointer;
  }
`;

export const DeleteButtonRoot = styled(NakedButton)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    &:hover {
      opacity: 0.5;
    }
  }
`;

export default {
  Root,
  RootButton,
  DeleteButtonRoot,
};
