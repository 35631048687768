import { connect } from 'react-redux';

import TransactionsList from './TransactionsList';

const mapStateToProps = (state) => {
  const {

  } = state;

  return {

  };
};

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsList);
