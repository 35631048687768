import React from 'react';
import { connect } from 'react-redux';
import {
  Form, Input, Select, Button,
} from 'antd';

import UploadFileInput from 'shared/inputs/UploadFile';
import './AddUserForm.scss';

const FormItem = Form.Item;
const { Option } = Select;

const AddUserForm = Form.create()((props) => {
  const submit = () => {
    props.form.validateFields((err, values) => {
      if (!err) {
        props.action(values, props.form);
      }
    });
  };

  const { getFieldDecorator } = props.form;
  const offices = props.offices.map((office, i) => (
    <Option value={office.OfficeID} key={office.OfficeName}>
      {office.OfficeName}
    </Option>
  ));

  return (
    <div className="add-user-form">
      <div className="add-user-form__title">
        Add A New Employee or Select Existing
      </div>

      <div className="add-user-form__row">
        <div className="add-user-form__wrapper">
          <div className="add-user-form__name">First Name *</div>

          <FormItem>
            {getFieldDecorator('Name', {
              rules: [
                {
                  required: true,
                  message: 'First Name is required!',
                },
              ],
            })(<Input size="large" />)}
          </FormItem>
        </div>

        <div className="add-user-form__wrapper">
          <div className="add-user-form__name">Last Name *</div>

          <FormItem>
            {getFieldDecorator('Surname', {
              rules: [
                {
                  required: true,
                  message: 'Last Name is required!',
                },
              ],
            })(<Input size="large" />)}
          </FormItem>
        </div>
      </div>

      <div className="add-user-form__row">
        <div className="add-user-form__wrapper">
          <div className="add-user-form__name">Email *</div>

          <FormItem>
            {getFieldDecorator('Email', {
              rules: [
                {
                  type: 'email',
                  message: 'Please enter a valid E-mail!',
                },
                {
                  required: true,
                  message: 'Email is required!',
                },
              ],
            })(<Input size="large" />)}
          </FormItem>
        </div>

        <div className="add-user-form__wrapper">
          <div className="add-user-form__name">Role</div>

          <FormItem>
            {getFieldDecorator('Role')(<Input size="large" />)}
          </FormItem>
        </div>
      </div>

      <div className="add-user-form__row">
        <div className="add-user-form__wrapper">
          <div className="add-user-form__name">Primary Office</div>

          <FormItem>
            {getFieldDecorator('OfficeID')(
              <Select size="large">{offices}</Select>,
            )}
          </FormItem>
        </div>
      </div>

      <div className="add-user-form__row">
        <div className="add-user-form__wrapper">
          <div className="add-user-form__name">Profile Image</div>

          <UploadFileInput
            fileLimit="1"
            inputName="Avatar"
            className="avatar-uploader"
            getFieldDecorator={getFieldDecorator}
          />
        </div>
      </div>

      <div className="add-user-form__add">
        {/* eslint-disable-next-line react/jsx-no-bind */}
        <Button type="primary" onClick={submit}>
          Add
        </Button>
      </div>
    </div>
  );
});

const mapStateToProps = (state) => ({
  offices: state.myCompany.offices,
});

export default connect(mapStateToProps)(AddUserForm);
