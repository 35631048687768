import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import Typography from 'ui/Typography';

import StageItemLayout from 'sections/Transactions/components/StageItemLayout';

import TransactionStageNotes from 'sections/Transactions/modules/TransactionStageNotes';

const MoveOutDate = (props) => {
  const {
    transactionId,
  } = props;

  const detailsComponent = (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3">
          Notes
        </Typography>
        <TransactionStageNotes
          transactionId={transactionId}
          stage="move out date"
        />
      </Grid>
    </Grid>
  );

  return (
    <StageItemLayout
      documentsComponent={null}
      detailsComponent={detailsComponent}
    />
  );
};

MoveOutDate.defaultProps = {

};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

MoveOutDate.propTypes = {

};

export default MoveOutDate;
