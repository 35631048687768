import setFilterItem from './setFilterItem';

const setAll = () => (dispatch) => {
  dispatch(setFilterItem('status')(null));
  dispatch(setFilterItem('type')(null));
};

const setArchived = () => (dispatch) => {
  dispatch(setFilterItem('status')(2));
  dispatch(setFilterItem('type')(null));
};

const setProspects = () => (dispatch) => {
  dispatch(setFilterItem('status')(null));
  dispatch(setFilterItem('type')('prospect'));
};

const setClients = () => (dispatch) => {
  dispatch(setFilterItem('status')(null));
  dispatch(setFilterItem('type')('client'));
};

const keyHandlers = {
  all: setAll,
  archived: setArchived,
  prospects: setProspects,
  clients: setClients,
};

const handleCustomFilter = (key) => (dispatch) => {
  if (keyHandlers[key]) dispatch(keyHandlers[key]());
};

export default handleCustomFilter;
