import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateProperty,
} from 'store/actions/properties';

import PropertyDetails from './PropertyDetails';

const mapStateToProps = (state) => {
  const client = selectors.realtorClients.selected(state);
  const property = selectors.properties.byId(state, client.realtor_property_id);

  return {
    property: property || {},
    propertyId: client.realtor_property_id,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onChange: (fieldName, value, propertyId) => {
    dispatch(updateProperty(propertyId)({ [fieldName]: value }));
  },
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  onChange: (fieldName) => (value) => {
    dispatchProps.onChange(fieldName, value, stateProps.propertyId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PropertyDetails);
