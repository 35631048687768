import React, {
  useCallback,
  useContext,
  useEffect,
  useState,

} from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Button from 'ui/Button';

import { useHistory } from 'react-router-dom';

import ConnectedAccountCard from 'sections/InAppMarketing/components/ConnectedAccountCard';

import FacebookLogin from 'shared/modules/FacebookLogin';

import Marketing from 'sections/Communications/modules/Marketing';
import useIntegrations from 'sections/Integrations/hooks/useIntegrations';
import useCampaigns from 'sections/Communications/hooks/useCampaigns';

import {
  Root,
} from './styledItems';
import Elevation from 'ui/Elevation/index';

const ConnectedAccounts = (props) => {
  const {
    fbUserName,
  } = props;

  const history = useHistory();

  const navigateTo = useCallback((route) => () => {
    history.push(route);
  }, [history]);

  const {
    scopes,
  } = useIntegrations();

  // const hasEmailConnected = scopes?.google?.has('email');
  const hasEmailConnected = true;

  const [diffDays, setDiffDays] = useState(0);
  const [cardMetrics, setCardMetrics] = useState({});

  const {
    campaignsList,
  } = useCampaigns({
    limit: 100,
    offset: 0,
  });

  // TODO: refactor: move to its own email card component
  useEffect(() => {
    if (!campaignsList?.length) {
      return;
    }
    
    // filter all campaigns sent in the last week by the sent_at date.
    const campaignsSentThisWeek = campaignsList.filter((campaign) => {
      const campaignDate = new Date(campaign.sent_at);
      const today = new Date();
      
      const diff = today.getTime() - campaignDate.getTime();
      const diffDays = Math.ceil(diff / (1000 * 3600 * 24));

      return diffDays <= 7;
    });

    // get the latest campaign and get the day difference compared to today.
    const latestCampaign = campaignsSentThisWeek[0];
    const latestCampaignDate = new Date(latestCampaign?.sent_at);
    const today = new Date();
    const diff = today.getTime() - latestCampaignDate.getTime();
    const diffDays = Math.ceil(diff / (1000 * 3600 * 24));

    setDiffDays(latestCampaign?.sent_at ? diffDays : 0);

    const totalSent = campaignsSentThisWeek.reduce((acc, campaign) => {
      const {
        metrics,
      } = campaign;
  
      acc.sent += 1;
      acc.replies += metrics.replyRate.count;
      acc.newLeads += metrics.newLeads.count;
  
      return acc;
    }, {
      sent: 0,
      replies: 0,
      newLeads: 0,
    });
  
    const metrics = {
      sent: totalSent.sent,
      replies: totalSent.replies,
      newLeads: totalSent.newLeads,
    };

    setCardMetrics(metrics);
  }, [campaignsList])

  return (
    <>
      <Root>
        <Flex
          spacing={2}
          fullWidth
          justifyContent="space-between"
          padding={'0 0 40px 0'}
        >
          <Flex spacing={3} flexWrap="wrap">
            <FlexItem>
              <ConnectedAccountCard
                type="email"
                className="current-card"
                hasConnected={hasEmailConnected}
                metrics={cardMetrics}
                diffDays={diffDays}
                actionButton={
                  <Button
                    onClick={navigateTo('/integrations')}
                    variant={'primary'}
                    iconColor={'contrast'}
                  >
                    Connect Email Address
                  </Button>
                }
              />
            </FlexItem>
          </Flex>
          <Flex spacing={3} flexWrap="wrap">
            <FlexItem>
              <ConnectedAccountCard
                type="facebook"
                loggedInAs={fbUserName}
                actionButton={<FacebookLogin 
                  title="Connect Facebook"
                  withIcon={false} />}
              />
            </FlexItem>
          </Flex>
          <Flex spacing={3} flexWrap="wrap">
            <FlexItem>
              <ConnectedAccountCard
                type="instagram"
                loggedInAs={fbUserName}
                actionButton={<FacebookLogin 
                  title="Connect Instagram"
                  withIcon={false} />}
              />
            </FlexItem>
          </Flex>
        </Flex>
      </Root>
    </>
  );
};

ConnectedAccounts.defaultProps = {
  fbUserName: '',
};

const {
  string,
} = PropTypes;

ConnectedAccounts.propTypes = {
  fbUserName: string,
};

export default ConnectedAccounts;
