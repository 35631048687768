import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  getLeadById,
} from 'store/actions/realtorLeads';

import MakeContact from './MakeContact';

const mapStateToProps = (state, ownProps) => {
  const lead = selectors.realtorLeads.byId(state, ownProps.leadId);

  return {
    shouldShow: lead?.isNew,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAfter: () => {
    dispatch(getLeadById(ownProps.leadId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MakeContact);
