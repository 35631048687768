import React, {
  useState, useCallback, useEffect, forwardRef, useRef,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { round } from 'shared/utility';
import Icon from 'ui/Icon';
import UpDownButtons from 'ui/UpDownButtons';
import forceFocus from 'ui/utils/forceFocus';
import useFocusOnRender from 'ui/hooks/useFocusOnRender';
import {
  Root,
  Label,
  InputWithMask,
  InputNoMask,
  InputTextArea,
  InputCurrency,
  IconWrapper,
  HintMessage,
  ControlButtonsWrapper,
  InputWrapper,
  RequiredIndicator,
} from './styledItems';

const masks = {
  phone: ['+', /\d/, ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  zip: [/\d/, /\d/, /\d/, /\d/, /\d/],
};

const Textfield = forwardRef((props, ref) => {
  const {
    autoWidth,
    className,
    ControlComponent,
    currency,
    dense,
    disabled,
    error,
    flat,
    focusOnRender,
    fullWidth,
    hideNumberArrows,
    hint,
    icon,
    iconColor,
    iconPosition,
    iconType,
    inputProps,
    label,
    mask,
    max,
    min,
    onBlur,
    onChange,
    onClick,
    onEnter,
    onFocus,
    onKeyPress,
    placeholder,
    preventAutoresize,
    required,
    showMask,
    step,
    style,
    textarea,
    textColor,
    transparent,
    type,
    value,
  } = props;

  const [isFocused, setIsFocused] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    if (value || value === '' || value === 0) { setCurrentValue(value === 0 ? '' : value); }
    // workaround for material autocomplete
    inputProps?.value && setCurrentValue(inputProps?.value); // eslint-disable-line chai-friendly/no-unused-expressions
  }, [value, inputProps?.value]);

  const inputEl = useRef(null);

  useEffect(() => {
    setTimeout(() => { // resize textarea; setTimeout is required for correct rendering
      if (textarea && inputEl?.current) {
        inputEl.current.style.height = 'auto';
        inputEl.current.style.height = `${inputEl.current.scrollHeight}px`;
      }
    }, 0);
  }, [value, textarea]);

  useFocusOnRender(inputProps.ref || inputEl, focusOnRender);

  const handleChange = useCallback((event, inputValue) => {
    const actualValue = currency
      ? inputValue
      : event.target.value;
    setCurrentValue(actualValue);
    onChange(actualValue, event);
    // workaround for material autocomplete
    inputProps?.onChange && inputProps.onChange(event); // eslint-disable-line chai-friendly/no-unused-expressions
    // auto resize textarea
    if (textarea && !preventAutoresize && inputEl?.current) {
      inputEl.current.style.height = 'auto';
      inputEl.current.style.height = `${inputEl.current.scrollHeight}px`;
    }
    //
  }, [onChange, textarea, inputProps?.onChange, currency, preventAutoresize]);

  const handleFocus = useCallback((event) => {
    if (!disabled) {
      setIsFocused(true);
      onFocus(event);
      // workaround for material autocomplete
      inputProps?.onFocus && inputProps.onFocus(event); // eslint-disable-line chai-friendly/no-unused-expressions
    }
  }, [onFocus, disabled, inputProps?.onFocus]);

  const handleBlur = useCallback((event) => {
    if (event.relatedTarget?.className.includes('up-down-buttons')) {
      const inputElement = inputProps.ref || inputEl;
      forceFocus(inputElement);
      return;
    }
    setIsFocused(false);
    onBlur(event);
    inputProps?.onBlur && inputProps.onBlur(event); // eslint-disable-line chai-friendly/no-unused-expressions
  }, [onBlur, inputProps?.onBlur, inputEl, inputProps.ref]);

  const isLabelActive = isFocused || !!currentValue || showMask;

  let Input = InputNoMask;
  if (currency) { Input = InputCurrency; }
  if (mask) { Input = InputWithMask; }
  if (textarea) { Input = InputTextArea; }

  const handleKeyPress = useCallback((event) => {
    if (event.key === 'Enter' && onEnter) {
      onEnter(event);
    } else if (onKeyPress) {
      onKeyPress(event);
    }
  }, [onEnter, onKeyPress]);

  const increaseValue = useCallback((event) => {
    event.preventDefault();
    const newValue = round(Number(currentValue) + step, 2);

    if (max && newValue > max) return;

    setCurrentValue(newValue);
    onChange(newValue);
  }, [onChange, step, currentValue, max]);

  const decreaseValue = useCallback((event) => {
    event.preventDefault();
    const newValue = round(Number(currentValue) - step, 2);

    if (min && newValue < min) return;

    setCurrentValue(newValue);
    onChange(newValue);
  }, [onChange, step, currentValue, min]);

  return (
    <Root
      ref={ref}
      className={classnames({
        [className]: className,
      })}
      fullWidth={fullWidth}
      disabled={disabled}
      transparent={transparent}
    >
      {label && (
        <Label
          className={isLabelActive ? 'label active' : 'label'}
          icon={icon}
          iconPosition={iconPosition}
          active={isLabelActive}
          variant={isLabelActive ? 'caption' : 'body'}
          color={error // eslint-disable-line no-nested-ternary
              ? 'error'
              : isLabelActive ? 'descriptor-text' : 'main-text'}
          fontStyle={isLabelActive ? 'initial' : 'italic'}
        >
          {label}
        </Label>
      )}
      <InputWrapper>
        {
          type === 'number' ? (
            <>
              <Input
                {...inputProps} // eslint-disable-line react/jsx-props-no-spreading
                autoWidth={autoWidth}
                disabled={disabled}
                error={error}
                flat={flat}
                focused={isFocused}
                guide={false}
                icon={!!icon}
                iconPosition={iconPosition}
                isDense={dense}
                mask={masks[mask] || false}
                max={max}
                min={min}
                onBlur={handleBlur}
                onChange={handleChange}
                onClick={onClick}
                onFocus={handleFocus}
                onKeyPress={handleKeyPress}
                placeholder={mask === 'phone' ? '+1' : placeholder}
                required={required}
                ref={inputProps?.ref || inputEl}
                showMask={showMask || false}
                step={step}
                style={style}
                textColor={textColor}
                type={type || 'text'}
                value={currentValue}
              />
              {
                !hideNumberArrows && (
                  <ControlButtonsWrapper>
                    <UpDownButtons onIncrease={increaseValue} onDecrease={decreaseValue} />
                  </ControlButtonsWrapper>
                )
              }
            </>
          ) : (
            <>
              <Input
                {...inputProps} // eslint-disable-line react/jsx-props-no-spreading
                autoWidth={autoWidth}
                ref={inputProps?.ref || inputEl}
                textColor={textColor}
                style={style}
                error={error}
                focused={isFocused}
                mask={masks[mask] || false}
                showMask={showMask || false}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChange}
                value={currentValue}
                type={type || 'text'}
                min={min}
                max={max}
                placeholder={mask === 'phone' ? '+1' : placeholder}
                guide={false}
                onClick={onClick}
                disabled={disabled}
                icon={!!icon}
                iconPosition={iconPosition}
                withControl={!!ControlComponent}
                onKeyPress={handleKeyPress}
                step={step}
                flat={flat}
                isDense={dense}
              />
              {
                ControlComponent && (
                  <ControlButtonsWrapper>{ControlComponent}</ControlButtonsWrapper>
                )
              }
            </>
          )
        }
        { required && (<RequiredIndicator>*</RequiredIndicator>)}
      </InputWrapper>
      {
        icon && (
          <IconWrapper iconPosition={iconPosition}>
            <Icon
              name={icon}
              type={iconType}
              size="m"
              color={
                error // eslint-disable-line no-nested-ternary
                  ? 'error'
                  : disabled ? 'disabled' : iconColor || 'main-text'
              }
            />
          </IconWrapper>
        )
      }
      {
        error && (
          <HintMessage variant="caption2" color="error">
            {error}
          </HintMessage>
        )
      }
      {
        !error && hint && (
          <HintMessage variant="caption2" color="descriptor-text">{hint}</HintMessage>
        )
      }
    </Root>
  );
});

const noOp = () => { };

Textfield.defaultProps = {
  autoWidth: false,
  className: null,
  ControlComponent: null,
  currency: false,
  dense: false,
  disabled: false,
  error: null,
  flat: false,
  focusOnRender: false,
  fullWidth: false,
  hideNumberArrows: false,
  hint: null,
  icon: null,
  iconColor: null,
  iconPosition: 'right',
  iconType: null,
  inputProps: {},
  label: null,
  mask: null,
  max: null,
  min: null,
  onBlur: noOp,
  onChange: noOp,
  onClick: noOp,
  onEnter: noOp,
  onFocus: noOp,
  onKeyPress: noOp,
  placeholder: null,
  preventAutoresize: false,
  required: false,
  showMask: false,
  step: 1,
  style: {},
  textarea: false,
  textColor: 'main-text',
  transparent: false,
  type: null,
  value: null,
};

const {
  bool, func, number, oneOf, oneOfType, shape, string, element,
} = PropTypes;

Textfield.propTypes = {
  autoWidth: bool,
  className: string,
  ControlComponent: element,
  currency: bool,
  dense: bool,
  disabled: bool,
  error: oneOfType([string, bool]),
  flat: bool,
  focusOnRender: bool,
  fullWidth: bool,
  hideNumberArrows: bool,
  hint: string,
  icon: string,
  iconColor: string,
  iconPosition: oneOf('left', 'right'),
  iconType: oneOf(['feather', 'fontawesome', 'custom']),
  inputProps: shape({}),
  label: string,
  mask: oneOf(['phone']),
  max: number,
  min: number,
  onBlur: func,
  onChange: func,
  onClick: func,
  onEnter: func,
  onFocus: func,
  onKeyPress: func,
  placeholder: string,
  preventAutoresize: bool,
  required: bool,
  showMask: bool,
  step: number,
  style: shape(),
  textarea: bool,
  textColor: string,
  transparent: bool,
  type: string,
  value: oneOfType([string, number]),
};

export default Textfield;
