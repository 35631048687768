import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import { isEmpty } from 'shared/utility';

import { useForm, Controller } from 'react-hook-form';

import Button from 'ui/Button';
import Typography from 'ui/Typography';
import Textfield from 'ui/Textfield';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Icon from 'ui/Icon';

import { timeIntervalTypes } from 'constants/followUpReminder.constants';

import DateTimeInput from 'shared/inputs/DateTimeInputV2';

const NewReminderFields = (props) => {
  const {
    createReminder,
    reminderForm,
    disableDateSelection,
    draftHandlers,
  } = props;

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: reminderForm,
  });

  useEffect(() => {
    if (!isEmpty(draftHandlers?.draftData)) {
      Object.entries(draftHandlers.draftData).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [JSON.stringify(draftHandlers?.draftData)]);

  useEffect(
    () => () => {
      if (draftHandlers?.saveDraft) {
        draftHandlers.saveDraft(getValues(), true);
      }
    },
    [],
  );

  const [fieldsValidators, setValidators] = useState({
    isScheduledDateValid: true,
    isTimeIntervalValueValid: false,
  });

  const { isScheduledDateValid } = fieldsValidators;

  const formScheduledDate = watch('scheduledDate', null);

  useEffect(() => {
    if (formScheduledDate !== null) {
      setValidators({
        isScheduledDateValid: true,
      });
    }
  }, [formScheduledDate]);

  const onClickCreate = useCallback(
    (data) => {
      if (!disableDateSelection && !data.scheduledDate) {
        setValidators({
          isScheduledDateValid: false,
        });
        return;
      }

      if (
        !disableDateSelection
        && moment(data.scheduledDate).isBefore(moment())
      ) {
        setValidators({
          ...fieldsValidators,
          isScheduledDateValid: false,
        });
        return;
      }

      const uniqueIdInCreatedRemindersArray = 1;

      let reminderScheduledDate = data.scheduledDate;

      if (!disableDateSelection && !reminderScheduledDate) {
        reminderScheduledDate = moment().add(
          data.timeIntervalValue,
          timeIntervalTypes[data.timeIntervalType].momentShorthand,
        );
      }

      const newReminder = {
        id: uniqueIdInCreatedRemindersArray,
        is_new: true,
        scheduled_date: reminderScheduledDate
          ? moment(reminderScheduledDate).format()
          : null,
        title: data.title,
        comment: data.comment,
        is_repeat: false,
        status: 1,
        time_interval_type:
          data.timeIntervalValue !== 0 ? data.timeIntervalType : null,
        time_interval_value:
          data.timeIntervalValue === 0 ? null : data.timeIntervalValue,
      };

      createReminder(newReminder);

      reset(reminderForm);
    },
    [createReminder, reset, reminderForm, disableDateSelection],
  );

  return (
    <Flex spacing={2} flexWrap="wrap">
      {!disableDateSelection && (
        <Flex fullWidth spacing={1} alignItems="center">
          <Icon name="bullhorn" type="fontawesome" color="focus" />
          <Typography
            variant="title3"
            tag="p"
            className="field-description-text"
            noMargin
          >
            Follow up on
          </Typography>

          <Controller
            name="scheduledDate"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Flex alignItems="center">
                <DateTimeInput
                  value={field.value ? moment(field.value).toDate() : null}
                  disablePast
                  error={!!errors.scheduledDate || !isScheduledDateValid}
                  handleChange={field.onChange}
                  label="Reminder date"
                />
              </Flex>
            )}
          />

          <Controller
            name="scheduledDate"
            control={control}
            render={({ field }) => (
              <Button
                variant="secondary"
                onClick={(event) => {
                  event.preventDefault();
                  field.onChange(moment().add(1, 'day'));
                }}
              >
                Tomorrow
              </Button>
            )}
          />

          <Controller
            name="scheduledDate"
            control={control}
            render={({ field }) => (
              <Button
                variant="secondary"
                onClick={(event) => {
                  event.preventDefault();
                  field.onChange(moment().add(1, 'week'));
                }}
              >
                In a week
              </Button>
            )}
          />
        </Flex>
      )}

      <FlexItem fullWidth>
        <Controller
          name="title"
          control={control}
          render={({ field }) => (
            <Textfield
              label="Follow up reminder title"
              onChange={field.onChange}
              value={field.value}
              error={errors.title ? 'Title is mandatory' : ''}
              required
            />
          )}
          rules={{
            required: true,
          }}
        />
      </FlexItem>

      <FlexItem fullWidth>
        <Controller
          name="comment"
          control={control}
          render={({ field }) => (
            <Textfield
              value={field.value}
              error={!!errors.comment}
              label="Leave a comment"
              onChange={field.onChange}
              textarea
            />
          )}
        />
      </FlexItem>

      <Flex justifyContent="flex-end" fullWidth>
        <Button variant="primary" onClick={handleSubmit(onClickCreate)}>
          Add Reminder
        </Button>
      </Flex>
    </Flex>
  );
};

NewReminderFields.defaultProps = {
  draftHandlers: null,
};

const {
 bool, func, number, shape, string,
} = PropTypes;

NewReminderFields.propTypes = {
  createReminder: func.isRequired,
  reminderForm: shape({
    timeIntervalType: number,
    timeIntervalValue: number,
    isRepeat: bool,
    scheduledDate: string,
    title: string,
    comment: string,
  }).isRequired,
  draftHandlers: shape({
    draftData: shape({}),
    saveDraft: func,
    clearDraft: func,
  }),
};

export default NewReminderFields;
