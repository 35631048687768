import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundary from 'ui/ErrorBoundary';
import KPIItem from 'ui/KPIItem';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Typography from 'ui/Typography';

import RequirementsAddressList from 'sections/Properties/modules/RequirementsAddressList';
import RequirementsPriceRangeInput from 'sections/Properties/modules/RequirementsPriceRangeInput';
import RequirementsParametersMain from 'sections/Properties/modules/RequirementsParametersMain';
import RequirementsPropertyTypeSelect from 'sections/Properties/modules/RequirementsPropertyTypeSelect';
import RequirementsYearBuiltSelect from 'sections/Properties/modules/RequirementsYearBuiltSelect';
import RequirementsNumberOfStoriesSelect from 'sections/Properties/modules/RequirementsNumberOfStoriesSelect';

import PreApprovalControl from 'sections/Properties/modules/RequirementsPreApproval/PreApprovalControl';

import ContactDescription from 'sections/Contacts/modules/ContactDescription';
import ContactLikes from 'sections/Contacts/modules/ContactLikes';
import ContactDislikes from 'sections/Contacts/modules/ContactDislikes';

import AdditionalAmenities from 'shared/modules/AdditionalAmenities';

const BuyerRequirements = (props) => {
  const {
    contactId,
    itemId,
  } = props;

  return (
    <ErrorBoundary>
      <Flex
        spacing={4}
        flexWrap="wrap"
      >
        <Flex
          spacing={4}
          flexWrap="wrap"
          alignItems="center"
        >
          <FlexItem xs={12}>
            <RequirementsPriceRangeInput
              itemId={itemId}
            />
          </FlexItem>

          <FlexItem xs={12}>
            <KPIItem
              iconName="bookmark"
              variant="flat"
              // tooltip="Pre-approval" // need to fix tooltip position
            >
              <Flex
                alignItems="baseline"
                spacing={4}
              >
                <Typography noMargin>
                  Pre-Approved:
                </Typography>
                <PreApprovalControl
                  propertyRequirementId={itemId}
                />
              </Flex>
            </KPIItem>
          </FlexItem>

          <FlexItem fullWidth>
            <RequirementsAddressList
              defaultView="edit"
              itemId={itemId}
              placeholder="Enter buyer preferred locations"
            />
          </FlexItem>

          <Flex
            spacing={4}
            fullWidth
            alignItems="center"
            flexWrap="wrap"
          >
            <RequirementsPropertyTypeSelect
              itemId={itemId}
              defaultView="edit"
            />
            <FlexItem fullWidth>
              <RequirementsParametersMain
                itemId={itemId}
                defaultView="edit"
              />
            </FlexItem>
          </Flex>

          <Flex
            fullWidth
            spacing={4}
          >
            <FlexItem xs={16}>
              <Flex spacing={2}>
                <FlexItem xs={12}>
                  <Typography
                    variant="title3"
                  >
                    Likes
                  </Typography>
                  <ContactLikes contactId={contactId} />
                </FlexItem>
                <FlexItem xs={12}>
                  <Typography
                    variant="title3"
                  >
                    Dislikes
                  </Typography>
                  <ContactDislikes contactId={contactId} />
                </FlexItem>
              </Flex>
            </FlexItem>
            <FlexItem xs={8}>
              <Typography
                variant="title3"
              >
                Requirement Notes
              </Typography>
              <ContactDescription contactId={contactId} />
            </FlexItem>
          </Flex>

          <Flex
            fullWidth
            spacing={4}
            flexWrap="wrap"
          >
            <FlexItem xs={12}>
              <RequirementsYearBuiltSelect
                itemId={itemId}
                defaultView="edit"
              />
            </FlexItem>

            <FlexItem xs={12}>
              <RequirementsNumberOfStoriesSelect
                defaultView="edit"
                itemId={itemId}
              />
            </FlexItem>

          </Flex>
        </Flex>

        <FlexItem fullWidth>
          <Typography
            variant="title2"
          >
            Property Features
          </Typography>
          <AdditionalAmenities
            itemId={itemId}
            itemType="property_requirements"
          />
        </FlexItem>

      </Flex>
    </ErrorBoundary>
  );
};

const {
  number,
} = PropTypes;

BuyerRequirements.propTypes = {
  contactId: number.isRequired,
  itemId: number.isRequired,
};

export default BuyerRequirements;
