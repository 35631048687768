import React from 'react';
import PropTypes from 'prop-types';

import {
 Checkbox, Form, Input,
} from 'antd';

import Button from 'ui/Button';

import { isEmpty, get } from 'shared/utility';

import { common } from 'constants';

import Typography from 'ui/Typography';
import Textfield from 'ui/Textfield';

import ButtonLink from 'shared/components/ButtonLink';
import { questions } from 'shared/modals/GeneralEmployee/2FA/QuestionList';

import { securityQuestionEnum } from 'shared/modals/GeneralEmployee/2FA/shared';

import PinInput from 'react-pin-input';

import InputWarning from '../../../shared/components/InputWarning';

import './LoginWorkflow.scss';

const signUpLink = process.env.REACT_APP_SYMPLETE_SIGN_UP_LINK;

const rootClass = 'login-workflow';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      twoFactor: {
        answerOne: '',
        answerTwo: '',
        answerThree: '',
        code: '',
      },
      remember: true,
      pastedCode: '',
    };
    this.logInButtonRef = React.createRef();
  }
  /* 2 FACTOR START */

  setCode = (code) => {
    this.setState(
      (state) => ({
        ...state,
        twoFactor: {
          ...state.twoFactor,
          code: String(code),
        },
      }),
      this.handleAnswersSubmit,
    );
  };

  toggleRemember = () => {
    const { remember } = this.state;

    this.setState((state) => ({
      ...state,
      remember: !remember,
    }));
  };

  getTitleById = (id = '', dictionary = []) => {
    if (!id) {
      return '';
    }

    return dictionary.find(({ id: iterableId }) => iterableId === id).question;
  };

  handleAnswerInput = (questionNum) => ({ target: { value } }) => {
    this.setState((state) => ({
      ...state,
      twoFactor: {
        ...state.twoFactor,
        [`answer${questionNum}`]: value,
      },
    }));
  };

  renderInputAndTitle = (questionOneTitle, questionNumString, isError) => {
    const { twoFactor } = this.state;

    const validateStatus = isError ? 'error' : '';

    const helpLabel = isError ? 'Answer is incorrect' : '';

    return (
      <>
        <Typography className={`${rootClass}__questions_title`}>
          {questionOneTitle}
        </Typography>
        <Form.Item validateStatus={validateStatus} help={helpLabel}>
          <Input
            className={`${rootClass}__questions_input_style`}
            name="questionOne"
            onChange={this.handleAnswerInput(questionNumString)}
            value={twoFactor[`answer${questionNumString}`]}
          />
        </Form.Item>
      </>
    );
  };

  getErrorByInputNum = (inputNum) => {
    let { error } = this.props;

    if (!error || !Array.isArray(error)) {
      return false;
    }

    error = error.find(({ questionNum }) => questionNum === inputNum);
    /* we need to know is the error present or not */
    return !!error;
  };

  onForgotPassword = () => {
    const { form, onForgot } = this.props;

    const email = form.getFieldValue('email');
    onForgot(email);
  };

  renderQuestionsForm = (twoFactor) => {
    const questionOneID = get(twoFactor, 'data.questionOneID');
    const questionTwoID = get(twoFactor, 'data.questionTwoID');
    const questionThreeID = get(twoFactor, 'data.questionThreeID');
    const questionOneTitle = this.getTitleById(questionOneID, questions);
    const questionTwoTitle = this.getTitleById(questionTwoID, questions);
    const questionThreeTitle = this.getTitleById(questionThreeID, questions);

    const questionOneError = this.getErrorByInputNum(1);
    const questionTwoError = this.getErrorByInputNum(2);
    const questionThreeError = this.getErrorByInputNum(3);

    return (
      <>
        {this.renderInputAndTitle(
          `1. ${questionOneTitle}`,
          'One',
          questionOneError,
        )}
        {this.renderInputAndTitle(
          `2. ${questionTwoTitle}`,
          'Two',
          questionTwoError,
        )}
        {this.renderInputAndTitle(
          `3. ${questionThreeTitle}`,
          'Three',
          questionThreeError,
        )}
      </>
    );
  };

  validateInput = (value) => {
    const { pastedCode } = this.state;

    if (pastedCode) return pastedCode;

    const numCode = value.charCodeAt(0);
    const isInteger = numCode >= '0'.charCodeAt(0) && numCode <= '9'.charCodeAt(0);

    return isInteger ? value : '';
  };

  renderCodeForm = () => (
    <PinInput
      length={6}
      type="numeric"
      style={{
        paddingBottom: '30px',
        width: '100%',
      }}
      validate={this.validateInput}
      inputStyle={{
        width: '40px',
        height: '63px',
        marginLeft: '7px',
        marginRight: '7px',
        borderRadius: '4px',
        border: 'solid 1px #d9d9d9',
        backgroundColor: '#ffffff',
      }}
      onComplete={this.setCode}
    />
  );

  onPaste = (event) => {
    event.persist();

    const IEText = event.clipboardData.getData('Text');
    const otherBrowsersText = event.clipboardData.getData('text/plain');
    const value = IEText || otherBrowsersText;

    if (value) {
      // fill inputs DANGEROUS, beacause PinInput is uncontrolled component
      /* let pininput = document.getElementsByClassName('pincode-input-container')[0]
      pininput && pininput.children.forEach((input, index ) => {
        input.value = value[index]
      }) */

      this.setState(
        {
          pastedCode: `${value}`,
        },
        this.handleAnswersSubmit,
      );
    }
  };

  renderEmailAndPassForm = () => {
    const {
      form: { getFieldDecorator },
      twoFactor,
      externalLogin,
      externalPassword,
    } = this.props;

    /* absent two factor means that login and pass were not successful */
    if (isEmpty(twoFactor)) {
      return (
        <>
          <Form.Item
            className="login-form__item"
            data-test="email-field-form-item"
          >
            {getFieldDecorator('email', {
              validateTrigger: 'onBlur',
              rules: common.EMAIL_VALIDATOR,
              initialValue: externalLogin,
            })(
              <Textfield
                icon="user"
                iconPosition="left"
                name="email"
                label="Email"
                onEnter={this.handleSubmit}
                style={{ paddingBottom: 5 }}
              />,
            )}
          </Form.Item>
          <Form.Item
            className="login-form__item"
            data-test="password-field-form-item"
          >
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ],
              initialValue: externalPassword,
            })(
              <Textfield.Password
                name="password"
                label="Password"
                onEnter={this.handleSubmit}
              />,
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('remember', {
              valuePropName: 'checked',
              initialValue: true,
            })(
              <Checkbox name="remember" data-test="remember-me-checkbox">
                <Typography tag="span">Remember me</Typography>
              </Checkbox>,
            )}
            <ButtonLink
              data-test="forgot-password-button"
              className="login-form__forgot"
              onClick={this.onForgotPassword}
            >
              <Typography tag="span" color="link">
                Forgot password
              </Typography>
            </ButtonLink>
            <Button
              data-test="log-in-button"
              onClick={this.handleSubmit}
              variant="primary"
              htmlType="submit"
              fullWidth
              buttonRef={this.logInButtonRef}
            >
              Log in
            </Button>
            <Typography tag="span">Or</Typography>
            {' '}
            <a data-test="sign-up-button" href={signUpLink}>
              <Typography tag="span" color="link">
                sign up now.
              </Typography>
            </a>
          </Form.Item>
        </>
      );
    }

    const type = get(twoFactor, 'type');
    let twoFactorInputs = null;

    if (type === securityQuestionEnum) {
      twoFactorInputs = this.renderQuestionsForm(twoFactor);
    } else {
      twoFactorInputs = this.renderCodeForm();
    }

    return (
      <div onPaste={this.onPaste}>
        {twoFactorInputs}
        <Button
          onClick={this.handleAnswersSubmit}
          type="primary"
          htmlType="submit"
        >
          Log in
        </Button>
      </div>
    );
  };

  renderError = () => {
    let { error } = this.props;

    if (!error) {
      return null;
    }

    // In case of wrong code or wrong answers show errors.
    // Maybe in case of answers it is not needed
    if (Array.isArray(error)) {
      error = error.map(({ message: errorString }) => (
        <Typography key={errorString} tag="div" color="error">
          {errorString}
          <br />
        </Typography>
      ));
    }

    return <InputWarning>{error}</InputWarning>;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.logInButtonRef.current.className = `${this.logInButtonRef.current.className } activated`;
    const { form, onSubmit } = this.props;
    form.validateFields((error, values) => {
      if (!error) {
        onSubmit(values);
      }
    });
  };

  handleAnswersSubmit = () => {
    const {
      twoFactor: { email, password, type },
      onSubmit,
    } = this.props;

    const { twoFactor, remember, pastedCode } = this.state;

    const values = { ...twoFactor };

    values.email = email;
    values.password = password;
    values.isTwoFactor = true;
    values.type = type;
    values.remember = remember;
    values.code = pastedCode || values.code;

    onSubmit(values);
  };

  render() {
    return (
      <Form className="login-form" data-test="login-form">
        {this.renderError()}
        {this.renderEmailAndPassForm()}
      </Form>
    );
  }
}

LoginForm.defaultProps = {
  error: [],
  twoFactor: {},
  externalLogin: null,
  externalPassword: null,
};

const {
 array, func, object, string,
} = PropTypes;

LoginForm.propTypes = {
  error: array, // eslint-disable-line react/forbid-prop-types
  form: object.isRequired, // eslint-disable-line react/forbid-prop-types
  onForgot: func.isRequired,
  onSubmit: func.isRequired,
  twoFactor: object, // eslint-disable-line react/forbid-prop-types
  externalLogin: string,
  externalPassword: string,
};

export default Form.create({
  name: 'normal_login',
})(LoginForm);
