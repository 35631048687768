import Type from './Type';
import SendDate from './SendDate';
import Reach from './Reach';
import Metrics from './Metrics/index';
import NextStage from './NextStage';
import Status from './Status';
import Option from './Option';

export default {
  Type,
  SendDate,
  Reach,
  Metrics,
  NextStage,
  Status,
  Option,
};
