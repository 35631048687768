import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundary from 'ui/ErrorBoundary';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Typography from 'ui/Typography';
import Elevation from 'ui/Elevation';
import Divider from 'ui/Divider';

import ContactInfoMain from 'sections/Contacts/modules/ContactInfoMain';
import SocialLinks from 'sections/Contacts/modules/SocialLinks';

import PastClient from 'sections/Contacts/modules/PastClient';

import ContactLogButton from 'sections/ContactLogs/ContactLogButton';

import CommentsButton from 'sections/Leads/modules/CommentsButton';
import TempSelect from 'sections/Leads/modules/TempSelect';

import ContactLabelsRaw from 'sections/Contacts/modules/ContactLabels';
import Call from 'sections/Contacts/modules/Call';
import EmailButton from 'sections/Contacts/modules/EmailButton';

import withInlineLabel from 'shared/hocs/withInlineLabelV2';

const ContactLabels = withInlineLabel(ContactLabelsRaw);

const sectionStyle = {
  padding: 10,
};

const Contact = (props) => {
  const {
    contactId,
    leadId,
    avatarLabel,
  } = props;

  return (
    <ErrorBoundary>
      <Elevation
        rounded="s"
        fullHeight
      >
        <div style={sectionStyle}>
          <Flex
            spacing={0.5}
            justifyContent="space-between"
          >
            <ContactInfoMain
              contactId={contactId}
              avatarProps={{
                label: avatarLabel,
              }}
            />
            <FlexItem keepSize>
              <TempSelect leadId={leadId} />
            </FlexItem>
          </Flex>
        </div>

        <Divider />

        <div style={sectionStyle}>
          <Flex
            spacing={2}
            alignItems="center"
            style={{ marginBottom: 10 }}
          >
            <ContactLogButton
              objectType="realtor contact"
              objectId={contactId}
              buttonProps={{
                variant: 'flat',
              }}
            >
              Log Contact
            </ContactLogButton>
            <CommentsButton contactId={contactId} />
            <PastClient contactId={contactId} />
          </Flex>

          <Flex
            justifyContent="center"
            spacing={2}
          >
            <Call
              showText
              contactId={contactId}
            />
            <EmailButton
              showText
              contactId={contactId}
              contactType={avatarLabel}
              objectId={leadId}
            />
          </Flex>
        </div>

        <Divider />

        <div style={sectionStyle}>
          <SocialLinks contactId={contactId} />
        </div>

        <Divider />

        <div style={sectionStyle}>
          <Typography
            variant="title2"
            tag="h4"
          >
            Attributes
          </Typography>

          <ContactLabels
            contactId={contactId}
            alignItems="center"
            labelProps={{
                weight: 500,
                noMargin: true,
                style: { marginRight: 10 },
              }}
          />
        </div>

      </Elevation>
    </ErrorBoundary>
  );
};

Contact.defaultProps = {
  avatarLabel: null,
};

const {
  number,
  string,
} = PropTypes;

Contact.propTypes = {
  contactId: number.isRequired,
  leadId: number.isRequired,
  avatarLabel: string,
};

export default Contact;
