import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { NakedButton } from '@symplete/symplete-ui';
import FormTextfield from 'shared/inputs/FormTextfield';

import AssignInterestedPartyModal from 'shared/modals/AssignInterestedPartyModal';

const ContactSelect = (props) => {
  const {
    contactId,
    onChange,
    displayValue,
    label,
  } = props;

  const [isAssignModalVisible, setIsAssignModalVisible] = useState(false);
  const [selectedContact, setSelectedContact] = useState(contactId);

  useEffect(() => {
    setSelectedContact(contactId);
  }, [
    contactId,
  ]);

  const openModal = useCallback((key, role) => () => {
    setSelectedContact(contactId);
    setIsAssignModalVisible(true);
  }, [
    contactId,
  ]);

  const closeModal = useCallback(() => {
    setIsAssignModalVisible(false);
  }, []);

  const onSave = useCallback((newContactIds) => {
    onChange(newContactIds.addedContacts[0]);
    closeModal();
  }, [
    onChange,
  ]);

  return (
    <>
      <NakedButton onClick={openModal()} style={{ flex: 1, width: '100%' }}>
        <FormTextfield
          value={displayValue}
          label={label}
          fullWidth
        />
      </NakedButton>

      <AssignInterestedPartyModal
        title="Select contact"
        isVisible={isAssignModalVisible}
        oneContactSelect
        closeModal={closeModal}
        selectedContacts={selectedContact ? [selectedContact] : []}
        onSave={onSave}
      />
    </>
  );
};

ContactSelect.defaultProps = {
  playersData: [],
};

const {
  arrayOf,
  shape,
  string,
  number,
  func,
} = PropTypes;

ContactSelect.propTypes = {
  onChange: func.isRequired,
};

export default ContactSelect;
