import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  setSelectedPropertyRows,
  getPropertiesForStoreFilter,
} from 'store/actions/properties';

import {
  setFilterItem,
} from 'store/actions/propertiesFilter';

import PropertiesTable from './PropertiesTable';

const mapStateToProps = (state) => {
  const {
    properties: {
      propertiesByFilterCount,
    },
    propertiesFilter: {
      pageNumber,
      pageSize,
      sortBy,
      sortDirection,
    },
  } = state;

  const {
    filterId,
  } = selectors.properties.propertiesFilter(state);

  const {
    propertiesList,
    firstSelfListingPropertyKey,
    firstNotSelfListingPropertyKey,
  } = selectors.properties.propertiesList(state, filterId);

  return {
    itemsCount: propertiesByFilterCount[filterId],
    propertiesList,
    pageNumber,
    pageSize,
    sortBy,
    sortDirection,
    firstSelfListingPropertyKey,
    firstNotSelfListingPropertyKey,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getProperties: (page, pageSize) => {
    const offset = (page - 1) * pageSize;
    const limit = pageSize;

    dispatch(getPropertiesForStoreFilter(offset, limit));
  },
  setFilterItem: (key) => (value) => {
    dispatch(setFilterItem(key)(value));
  },
  onRowSelect: (selectedIds) => {
    dispatch(setSelectedPropertyRows(selectedIds));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesTable);
