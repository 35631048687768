import {
  SET_FACEBOOK_FIELD,
} from 'store/actionTypes/socialIntegrations';

const setFacebookField = (field) => (value) => ({
  type: SET_FACEBOOK_FIELD,
  payload: {
    field,
    value,
  },
});

export default setFacebookField;
