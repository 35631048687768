import outgoingCaller from './outgoingCaller';
import token from './token';
import activePhones from './activePhones';
import recordings from './recordings';
import logs from './logs';
import isPhoneACaller from './isPhoneACaller';

import saveCall from './saveCall';

export default {
  outgoingCaller,
  token,
  activePhones,
  recordings,
  logs,
  saveCall,
  isPhoneACaller,
};
