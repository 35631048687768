import { connect } from 'react-redux';

import {
  updateInspection,
} from 'store/actions/realtorInspections';

import FlagCell from './FlagCell';

const mapStateToProps = (state, ownProps) => {
  const {
    isFlagged,
    inspectionId,
  } = ownProps;

  return {
    isFlagged,
    inspectionId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onFlagClick: (is_flagged, inspectionId) => {
    dispatch(updateInspection(inspectionId)({ is_flagged: !is_flagged }));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onFlagClick: (is_flagged) => () => {
    dispatchProps.onFlagClick(is_flagged, stateProps.inspectionId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(FlagCell);
