import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'ui/Icon';
import Badge from 'ui/Badge';
import Tooltip from 'ui/Tooltip';

import {
  Root,
  Image,
  IconWrapper,
} from './styledItems';

const statusColorDict = {
  1: 'available',
  2: 'under offer',
  3: 'in transaction',
  4: 'sold',
  5: 'off market',
};

const statusTitleDict = {
  1: 'Available',
  2: 'Under Offer',
  3: 'In Transaction',
  4: 'Sold',
  5: 'Off Market',
};

const PropertyImageCell = (props) => {
  const {
    image,
    status,
    rounded,
    height,
  } = props;

  return (
    <Root
      height={height}
    >
      {
        image ? (
          <Badge variant="status" color={statusColorDict[status]}>
            <Tooltip title={statusTitleDict[status]}>
              <Image
                status={status}
                src={image}
                rounded={rounded}
                height={height}
              />
            </Tooltip>
          </Badge>
        )
        : (
          <Badge variant="status" color={statusColorDict[status]}>
            <Tooltip title={statusTitleDict[status]}>
              <IconWrapper
                rounded={rounded}
                height={height}
              >
                <Icon
                  name="property"
                  className="property-icon"
                  type="custom"
                  color="background-flat"
                  size="xl"
                />
              </IconWrapper>
            </Tooltip>
          </Badge>
        )
      }
    </Root>
  );
};

PropertyImageCell.defaultProps = {
  image: null,
  status: null,
  rounded: 'm',
  height: 64,
};

const {
  string,
  number,
  oneOfType,
} = PropTypes;

PropertyImageCell.propTypes = {
  image: string,
  status: string,
  rounded: string,
  height: oneOfType([string, number]),
};

export default React.memo(PropertyImageCell);
