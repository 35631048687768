import React from 'react';
import PropTypes from 'prop-types';

import { currency } from '@symplete/symplete-utils/numbers';

import withMainPropertyImage from 'sections/Properties/hocs/withMainPropertyImage';

import ErrorBoundary from 'ui/ErrorBoundary';
import Flex from 'ui/Flex';
import Tooltip from 'ui/Tooltip';
import Typography from 'ui/Typography';

import {
  Root,
  PropertyPhoto,
} from './styledItems';

const PropertyImage = withMainPropertyImage(PropertyPhoto);

const descriptionStyle = {
  overflow: 'hidden',
};

const MatchingPropertyCard = (props) => {
  const {
    price,
    address,
    propertyId,
    controls,
  } = props;

  return (
    <ErrorBoundary>
      <Root>
        <Flex
          fullWidth
          flexDirection="column"
          spacing={1}
        >
          <Flex
            spacing={1}
            fullWidth
          >
            <PropertyImage
              propertyId={propertyId}
            />
            <Flex
              flexDirection="column"
              spacing={0.5}
              style={descriptionStyle}
            >
              <Tooltip title={address}>
                <Typography
                  variant="title3"
                  tag="h4"
                  weight="bold"
                  noMargin
                  ellipsis
                >
                  {address}
                </Typography>
              </Tooltip>

              {
                price > 0 && (
                  <Typography
                    variant="title4"
                    color="money"
                    tag="p"
                    weight="bold"
                    noMargin
                  >
                    {currency.full(price)}
                  </Typography>
                )
              }
            </Flex>
          </Flex>
          { controls }
        </Flex>
      </Root>
    </ErrorBoundary>
  );
};

MatchingPropertyCard.defaultProps = {
  price: null,
  address: null,
  propertyId: null,
  controls: null,
};

const {
  element,
  number,
  string,
} = PropTypes;

MatchingPropertyCard.propTypes = {
  price: number,
  address: string,
  propertyId: number,
  controls: element,
};

export default MatchingPropertyCard;
