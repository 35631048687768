import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import ExpectedSalePriceInput from 'ui/CurrencyInput';

import withDebouncedCallbacks from 'shared/hocs/withDebouncedCallbacks';

import withPropertyData from 'sections/Leads/hocs/withPropertyData';

import AddressInput from 'shared/inputs/AddressInput';
import PropertyTypeSelect from 'shared/inputs/PropertyTypeSelect';

import LeadPropertyParametersArea from 'sections/Leads/inputs/LeadPropertyParametersArea';
import SaleTypeSelect from 'sections/Leads/inputs/SaleTypeSelect';
import YearBuiltSelect from 'sections/Leads/inputs/YearBuiltSelect';
import NumberOfStoriesSelect from 'sections/Leads/inputs/NumberOfStoriesSelect';
import ViewsFromHomeSelect from 'sections/Leads/inputs/ViewsFromHomeSelect';

import DetailsSection from 'sections/Leads/components/DetailsSection';

const PrimaryDetails = (props) => {
  const {
    propertyData,
    onChange,
    onRelationOverwrite,
  } = props;

  const handleFieldChange = useCallback((field) => (value) => {
    if (field === 'views') {
      onRelationOverwrite('views')(value);
    } else {
      onChange(field, value);
    }
  }, [
    onChange,
    onRelationOverwrite,
  ]);

  const PriceInput = withDebouncedCallbacks(
    ExpectedSalePriceInput,
    [
      ['onChange', 1000],
    ],
  );

  return (
    <Flex
      spacing={4}
      flexDirection="column"
    >
      <DetailsSection
        icon="map-pin"
        title="Property Address"
      >
        <Flex
          spacing={2}
        >
          <FlexItem xs={16}>
            <AddressInput
              handleChange={handleFieldChange('address_id')}
              addressId={propertyData?.address_id}
            />
          </FlexItem>
          <FlexItem xs={8}>
            <PriceInput
              onChange={handleFieldChange('expected_price')}
              value={propertyData?.expected_price || 0}
              label="Expected sale price"
            />
          </FlexItem>
        </Flex>
      </DetailsSection>

      <DetailsSection
        icon="check-circle"
        iconType="feather"
        title="Property Details"
      >
        <Flex
          spacing={4}
          alignItems="center"
          flexWrap="wrap"
        >
          <FlexItem xs={16}>
            <LeadPropertyParametersArea
              parameters={{
                bedrooms: propertyData?.bedrooms,
                bathrooms: propertyData?.bathrooms,
                floor_area: propertyData?.floor_area,
                plot_area: propertyData?.plot_area,
                parking: propertyData?.parking,
              }}
              handleChangeProperty={onChange}
            />
          </FlexItem>
          <FlexItem flex={1}>
            <PropertyTypeSelect
              handleChange={handleFieldChange('property_type')}
              value={propertyData?.property_type}
            />
          </FlexItem>
          <FlexItem xs={12}>
            <Flex spacing={2}>
              <FlexItem flex={1}>
                <SaleTypeSelect
                  handleChange={handleFieldChange('sale_type')}
                  value={propertyData?.sale_type || ''}
                />
              </FlexItem>
              <FlexItem flex={1}>
                <YearBuiltSelect
                  handleChange={handleFieldChange('year_built')}
                  value={propertyData?.year_built}
                />
              </FlexItem>
              <FlexItem flex={1}>
                <NumberOfStoriesSelect
                  handleChange={handleFieldChange('number_of_stories')}
                  value={propertyData?.number_of_stories || ''}
                />
              </FlexItem>
            </Flex>
          </FlexItem>
          <FlexItem xs={12}>
            <ViewsFromHomeSelect
              handleChange={handleFieldChange('views')}
              value={propertyData?.views || undefined}
            />
          </FlexItem>
        </Flex>
      </DetailsSection>
    </Flex>
  );
};

const {
  func,
  number,
  shape,
} = PropTypes;

PrimaryDetails.propTypes = {
  propertyData: shape({
    address_id: number,
    expected_price: number,
    property_type: number,
    bedrooms: number,
    bathrooms: number,
    floor_area: number,
    plot_area: number,
    parking: number,
  }).isRequired,
  onChange: func.isRequired,
  onRelationOverwrite: func.isRequired,
};

export default withPropertyData(PrimaryDetails);
