import React, {
  useCallback,
  useState,
} from 'react';

import PropTypes from 'prop-types';

import { Table } from 'antd';

import './MatchingPropertiesTable.scss';

import getColumns from './columns';

const MatchingPropertiesTable = (props) => {
  const {
    properties,
  } = props;

  const [hoveredRecord, setHoveredRecord] = useState(null);

  const onRow = useCallback((record, rowIndex) => ({
    onMouseEnter: (event) => {
      setHoveredRecord(record.id);
    },
    onMouseLeave: (event) => {
      setHoveredRecord(null);
    },
  }), [
    setHoveredRecord,
  ]);

  return (
    <Table
      key="matching-properties-table"
      className="matching-properties-table"
      dataSource={properties}
      columns={getColumns({ hoveredRecord })}
      onRow={onRow}
      tableLayout="fixed"
      size="middle"
      pagination={false}
    />
  );
};

MatchingPropertiesTable.defaultProps = {
  properties: [],
};

const {
  arrayOf,
  shape,
} = PropTypes;

MatchingPropertiesTable.propTypes = {
  properties: arrayOf(shape()),
};

export default MatchingPropertiesTable;
