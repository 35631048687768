import React from 'react';

import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import routes from 'sections/Communications/routes';

import EmailMarketingCampaign from 'sections/Communications/pages/EmailMarketingCampaign';

import Communications from './Communications';

const Root = (props) => (
  <>
    <Switch>
      <Route
        exact
        path={routes.sectionRoot}
        component={Communications}
      />
      <Route
        path={`${routes.campaignDetails}/:campaignId`}
        component={EmailMarketingCampaign}
      />

      <Redirect to={routes.sectionRoot} />
    </Switch>
  </>
);

export default Root;
