import {
  useState,
  useEffect,
  useCallback,
} from 'react';

import requests from 'api/requests';

const listTemplates = (onSuccess, onError) => async (limit = 50, offset = 0) => {
  try {
    const result = await requests.integrations.emailTemplates.list({ limit, offset });

    onSuccess(result?.data || []);
  } catch (error) {
    onError(error);
  }
};

const createTemplate = (onSuccess, onError) => async (templateData) => {
  try {
    const result = await requests.integrations.emailTemplates.create(templateData);

    onSuccess(result.data.id);
  } catch (error) {
    onError(error);
  }
};

const useTemplates = (pagination = {}) => {
  const {
    limit = 50,
    offset = 0,
  } = pagination;

  const [templates, setTemplates] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const [listError, setListError] = useState(null);
  const [createError, setCreateError] = useState(null);

  const list = async (limit, offset) => {
    setListError(null);
    setIsLoading(true);

    await listTemplates(setTemplates, setListError)(limit, offset);

    setIsLoading(false);
  };

  useEffect(() => {
    listTemplates(setTemplates, setListError)(limit, offset);
  }, [
    limit,
    offset,
  ]);

  const create = useCallback(async (campaignData) => {
    setCreateError(null);
    setIsCreating(true);

    let newTemplateId = null;

    await createTemplate(
      (id) => {
        list(limit, offset);
        newTemplateId = id;
      },
      setCreateError,
    )(campaignData);

    setIsCreating(false);

    return newTemplateId;
  }, [
    limit,
    offset,
  ]);

  return {
    templates,
    isLoading,
    isCreating,

    list,
    create,

    listError,
    createError,
  };
};

export default useTemplates;
