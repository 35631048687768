import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import ImageUploadWrapper from 'shared/components/ImageUploadWrapper';

import Icon from 'ui/Icon';
import Tooltip from 'ui/Tooltip';
import RoleLabel from 'ui/RoleLabel';

import {
  Root,
  Initials,
  Wrapper,
  IconWrapper,
  RoleLabelWrapper,
} from './styledItems';

const getInitials = (firstName = '', lastName = '') => {
  const fname = firstName ? firstName.split('')[0] || '' : '';
  const lname = lastName ? lastName.split('')[0] || '' : '';

  return fname + lname;
};

const sizes = {
  xs: 32,
  s: 36,
  sm: 50,
  m: 116,
};

const iconSizes = {
  xs: 14,
  s: 14,
  sm: 24,
  m: 32,
};

const RealtorContactAvatar = (props) => {
  const {
    src,
    firstName,
    lastName,
    onSave,
    size,
    round,
    className,
    isNameLetterAvatar,
    withNameTooltip,
    iconColor,
    label,
    hideLabel,
  } = props;

  const initials = getInitials(firstName, lastName);

  const NoAvatarImage = isNameLetterAvatar ? (
    <Initials
      size={sizes[size] || size}
    >
      {initials}
    </Initials>
  ) : (
    <IconWrapper
      round={round}
      size={sizes[size] || size}
    >
      <Icon
        name="profile"
        // className="property-icon"
        type="custom"
        color={iconColor}
        size={iconSizes[size]}
      />
    </IconWrapper>
  );

  let result = null;

  if (!onSave) {
    result = (
      <Wrapper
        className={classnames({
          [className]: className,
        })}
      >
        <Root
          src={src}
          size={sizes[size] || size}
          round={round}
        >
          {
            !src && (
              NoAvatarImage
            )
          }
          {
            label && (
              <RoleLabelWrapper>
                <RoleLabel>{label}</RoleLabel>
              </RoleLabelWrapper>
            )
          }
        </Root>
      </Wrapper>
    );
  } else {
    result = (
      <Wrapper>
        <ImageUploadWrapper
          appModule="contact-leads-logo"
          uploadNoSubmit={onSave}
        >
          <Root
            size={sizes[size] || size}
            src={src}
            round={round}
          >
            {
              !src && (
                NoAvatarImage
              )
            }
            {
              label && !hideLabel && (
                <RoleLabelWrapper>
                  <RoleLabel>{label}</RoleLabel>
                </RoleLabelWrapper>
              )
            }
          </Root>
        </ImageUploadWrapper>
      </Wrapper>
    );
  }

  return withNameTooltip ? (
    <Tooltip
      title={`${firstName || ''} ${lastName || ''}`}
    >
      {result}
    </Tooltip>
  ) : result;
};

RealtorContactAvatar.defaultProps = {
  src: null,
  firstName: null,
  lastName: null,
  onSave: null,
  size: 'm',
  round: false,
  isNameLetterAvatar: false,
  className: null,
  withNameTooltip: false,
  iconColor: 'avatarIcon',
  label: null,
  hideLabel: false,
};

const {
  bool,
  string,
  number,
  func,
} = PropTypes;

RealtorContactAvatar.propTypes = {
  /**
   * image source
   */
  src: string,
  /**
   * first name
   */
  firstName: string,
  /**
   * last name
   */
  lastName: string,
  size: number,
  onSave: func,
  round: bool,
  className: string,
  isNameLetterAvatar: bool,
  withNameTooltip: bool,
  iconColor: string,
  label: string,
  hideLabel: bool,
};

export default RealtorContactAvatar;
