import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';

import { useHistory } from 'react-router-dom';

import useCampaigns from 'sections/Communications/hooks/useCampaigns';
import useTemplates from 'sections/Communications/hooks/useTemplates';

import Table from 'ui/Table';

import routes from 'sections/InAppMarketing/routes';

import columns from './columns';

const showTotal = (total, range) => `${range[0]}-${range[1]} of ${total} campaigns`;

const Campaigns = (props) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const history = useHistory();

  const {
    templates,
  } = useTemplates();

  const {
    campaignsList,
    campaignsTotal,
  } = useCampaigns({
    limit: pageSize,
    offset: pageSize * (pageNumber - 1),
  });

  useEffect(() => {
    if (templates?.length) {
      // get template id from campaignList and add template name to campaign list
      campaignsList.forEach((campaign) => {
        const template = templates.find((t) => t.id === campaign.template_id);
        campaign.templateName = template?.name;
      });
    }
  }, [
    campaignsList,
    templates,
  ]);

  const onPaginationChange = useCallback((newPageNumber, newPageSize) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
  });

  const onRow = useCallback((record, rowIndex) => ({
    onClick: (event ) => {
      // if click is on last column, do not redirect
      if (event.target.tagName === 'svg' || event.target.cellIndex === 6) {
        return;
      }

      history.push(`${routes.campaignDetails}/${record.id}`);
    },
  }), [
    history,
  ]);

  return (
    <Table
      key="email-campaigns-table"
      dataSource={campaignsList}
      columns={columns()}
      pagination={{
        total: campaignsTotal,
        showTotal,
        onChange: onPaginationChange,
        current: pageNumber,
        pageSize,
      }}
      onRow={onRow}
      tableLayout="fixed"
      size="middle"
    />
  );
};

export default Campaigns;
