import { connect } from 'react-redux';

// import {
//   optimizedCrmContactsActions,
// } from 'store/actions/crm/crmContacts';

import selectors from 'store/selectors';

import ContactInfo from './ContactInfo';

const mapStateToProps = (state, ownProps) => {
  const {
    leadId,
  } = ownProps;

  const lead = selectors.realtorLeads.byId(state, leadId);
  const contact = selectors.realtorContacts.byId(state, lead?.contact_id);

  return {
    leadId,
    contactId: contact?.id,
    // firstName: contact?.first_name,
    // lastName: contact?.last_name,
    // email: contact?.email_primary,
    // phone: contact?.phone_primary,
    // // labels: contact?.labels,
    // // description: contact?.description,
    // socialLinks: [
    //   {
    //     name: 'facebook',
    //     url: contact?.facebook_url,
    //     icon: 'facebook',
    //   },
    //   {
    //     name: 'twitter',
    //     url: contact?.twitter_url,
    //     icon: 'twitter',
    //   },
    //   {
    //     name: 'linkedin',
    //     url: contact?.linkedin_url,
    //     icon: 'linkedin',
    //   },
    //   {
    //     name: 'instagram',
    //     url: contact?.instagram_url,
    //     icon: 'instagram',
    //   },
    //   {
    //     name: 'website',
    //     url: contact?.website_url,
    //     icon: 'globe',
    //   },
    // ],
  };
};

export default connect(mapStateToProps)(ContactInfo);
