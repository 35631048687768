import store from 'store';

import {
  createMatchingProperty,
  resetMatchingPropertyFilters,
} from 'store/actions/realtorMatchingProperties';

import { getClientById } from 'store/actions/realtorClients';

const createMatch = async (matchingPropertyData, clientId) => {
  await store.dispatch(createMatchingProperty(matchingPropertyData));
  await store.dispatch(getClientById(clientId));
  store.dispatch(resetMatchingPropertyFilters());
};

export default createMatch;
