import React from 'react';
import PropTypes from 'prop-types';

import { pretty } from '@symplete/symplete-utils/numbers';

import Typography from 'ui/Typography';
import Icon from 'ui/Icon';

import {
  Root,
} from './styledItems';

const variant2color = {
  default: 'background-flat',
  attention: 'warning',
  warning: 'error',
};

const variant2icon = {
  default: null,
  attention: 'info',
  warning: 'alert-triangle',
};

const StatItem = (props) => {
  const {
    value,
    title,
    variant,
  } = props;

  return (
    <Root
      alignItems="center"
      spacing={2}
      color={variant2color[variant]}
    >
      {
        variant2icon[variant] && (
          <Icon
            name={variant2icon[variant]}
            color={variant2color[variant]}
          />
        )
      }
      <Typography
        noMargin
      >
        <b>{pretty.int(value)}</b>
        {' '}
        {title}
      </Typography>
    </Root>
  );
};

StatItem.defaultProps = {
  variant: 'default',
  value: 0,
};

const {
  number,
  oneOf,
  string,
} = PropTypes;

StatItem.propTypes = {
  variant: oneOf(['default', 'attention', 'warning']),
  value: number,
  title: string.isRequired,
};

export default StatItem;
