import React, {
  useEffect,
  useCallback,
  useState,
} from 'react';

import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

import Table from 'ui/Table';

import ArchiveDeleteModal from 'sections/Opportunities/modals/ArchiveDeleteOpportunityModal';

import columns from './columns';

const sortFieldDict = {
  contact_name: 'contact_name',
  stage: 'stage_id',
  type: 'type',
  budgetPrice: 'budgetPrice',
  propertyType: 'propertyType',
  location: 'location',
  toReview: 'to_review',
  viewings: 'viewings_count',
  nextViewing: 'next_viewing_time',
  createdAt: 'created_at',
};

const sortDirectionDict = {
  ascend: 'asc',
  descend: 'desc',
};

const sections = {
  client: 'clients-sellers',
  prospect: 'clients-buyers',
};

const showTotal = (total, range) => `${range[0]}-${range[1]} of ${total}`;

const OpportunitiesTable = (props) => {
  const {
    itemsList,
    getItems,
    setFilterItem,
    itemsCount,
    pageNumber,
    pageSize,
    sortBy,
    sortDirection,
    onRowSelect,
  } = props;

  const history = useHistory();

  useEffect(() => {
    getItems(pageNumber, pageSize);

    return () => { // this is the same as componentWillUnmount
      onRowSelect([]);
    };
  }, []);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isArchiveDeleteModalVisible, setIsArchiveDeleteModalVisible] = useState(false);
  const [archiveDeleteModalData, setArchiveDeleteModalData] = useState({});

  const openArchiveDeleteModal = useCallback((itemId, itemType) => () => {
    setArchiveDeleteModalData({
      itemId,
      itemType,
    });
    setIsArchiveDeleteModalVisible(true);
  }, []);

  const closeArchiveDeleteModal = useCallback(() => {
    setIsArchiveDeleteModalVisible(false);
    setArchiveDeleteModalData({});
  }, []);

  const rowSelection = {
    onChange: useCallback((selectedKeys, selectedRows) => {
      onRowSelect(selectedRows.map((item) => item.id));
      setSelectedRowKeys(selectedKeys);
    }, []),

    selectedRowKeys,
  };

  const onChange = useCallback((pagination, filter, sorter) => {
    const hasPageChaged = pagination && (pagination.current !== pageNumber || pagination.pageSize !== pageSize); // eslint-disable-line max-len
    const hasSortedChanged = sorter.field && (sortBy !== sortFieldDict[sorter.field] || sortDirection !== sortDirectionDict[sorter.order] || 'asc'); // eslint-disable-line max-len

    const shouldGetNewSortedItems = !hasPageChaged && hasSortedChanged;

    if (shouldGetNewSortedItems) {
      setFilterItem('sortBy')(sortFieldDict[sorter.columnKey]);
      setFilterItem('sortDirection')(sortDirectionDict[sorter.order] || 'asc');
      getItems(pageNumber, pageSize);
      onRowSelect([]);
      setSelectedRowKeys([]);
    }
  }, [
    onRowSelect,
    setSelectedRowKeys,
  ]);

  const onPaginationChange = useCallback((newPageNumber, newPageSize) => {
    setFilterItem('pageNumber')(newPageNumber);
    setFilterItem('pageSize')(newPageSize);
    getItems(newPageNumber, newPageSize);
    onRowSelect([]);
    setSelectedRowKeys([]);
  }, [
    setFilterItem,
    getItems,
    onRowSelect,
    setSelectedRowKeys,
  ]);

  const onSort = useCallback((key) => (order) => () => {
    setFilterItem('sortBy')(sortFieldDict[key]);
    setFilterItem('sortDirection')(sortDirectionDict[order] || 'asc');
    getItems(pageNumber, pageSize);
    onRowSelect([]);
    setSelectedRowKeys([]);
  }, [
    onRowSelect,
    setSelectedRowKeys,
    setFilterItem,
    getItems,
    pageNumber,
    pageSize,
  ]);

  const [hoveredRecord, setHoveredRecord] = useState(null);

  const onRow = useCallback((record, rowIndex) => ({
    onClick: (event) => {
      history.push(`/${sections[record.type]}/${record.id}`);
    },
    onMouseEnter: (event) => {
      setHoveredRecord(record.key);
    },
    onMouseLeave: (event) => {
      setHoveredRecord(null);
    },
  }), [
    history,
    setHoveredRecord,
  ]);

  return (
    <>
      <Table
        key="prospects-table"
        className="realtor-prospects-table"
        dataSource={itemsList}
        columns={columns({ hoveredRecord, openArchiveDeleteModal, onSort })}
        // rowSelection={{ ...rowSelection }}
        onChange={onChange}
        pagination={{
          total: itemsCount,
          showTotal,
          onChange: onPaginationChange,
          current: pageNumber,
          pageSize,
        }}
        onRow={onRow}
        tableLayout="fixed"
        size="middle"
      />

      <ArchiveDeleteModal
        isVisible={isArchiveDeleteModalVisible}
        itemId={archiveDeleteModalData?.itemId}
        itemType={archiveDeleteModalData?.itemType}
        closeModal={closeArchiveDeleteModal}
      />
    </>
  );
};

OpportunitiesTable.defaultProps = {
  itemsList: [],
  itemsCount: 0,
  pageNumber: 1,
  pageSize: 10,
  sortBy: 'id',
  sortDirection: 'desc',
};

const {
  arrayOf,
  func,
  number,
  oneOf,
  shape,
  string,
} = PropTypes;

OpportunitiesTable.propTypes = {
  itemsList: arrayOf(shape({
    key: string,
    // leadId: number,
    // connectedItemId: number,
    // stage: string,
    // sourceId: number,
    // personId: number,
    // type: number,
    // expectedSalePrice: number,
    // expectedPurchasePrice: arrayOf(number),
    // propertyType: arrayOf(string),
    // location: number,
    // createdAt: string,
    // children: arrayOf(shape({
    //   key: string,
    //   leadId: number,
    //   connectedItemId: number,
    //   type: number,
    //   expectedSalePrice: number,
    //   expectedPurchasePrice: arrayOf(number),
    //   propertyType: arrayOf(string),
    //   location: number,
    //   createdAt: string,
    // })),
  })),
  getItems: func.isRequired,
  setFilterItem: func.isRequired,
  itemsCount: number,
  pageNumber: number,
  pageSize: number,
  sortBy: string,
  sortDirection: oneOf(['asc', 'desc']),
  onRowSelect: func.isRequired,
};

export default OpportunitiesTable;
