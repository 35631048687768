import React, {
  useState,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';

import AssignInterestedPartyModal from 'shared/modals/AssignInterestedPartyModal';

import noOp from 'shared/utils/noOp';

import PlayerItemV2 from 'shared/modules/PlayerItemV2';

const Players = (props) => {
  const {
    playersData,
    onAdd,
    onRemove,
    withRoleSelection,
    preset,
  } = props;

  const [isAssignModalVisible, setIsAssignModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState('Add Key User');
  const [selectedRole, setSelectedRole] = useState(null);

  const rolesFilled = playersData
    .filter((elm) => !!elm.contactId)
    .map((elm) => elm.role);

  const openModal = useCallback(() => {
    setIsAssignModalVisible(true);
  }, []);

  const openModalPreset = useCallback((key, contactId, role) => () => {
    setModalTitle(`Choose a new ${role}`);
    setSelectedRole(role);
    setIsAssignModalVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsAssignModalVisible(false);
    setModalTitle('Add Key User');
    setSelectedRole(null);
  }, []);

  const onSave = useCallback((newContactIds, role) => {
    onAdd(newContactIds.addedContacts[0], role || selectedRole);
    closeModal();
  }, [
    onAdd,
    selectedRole,
  ]);

  return (
    <>
      <Flex
        spacing={1}
        flexWrap="nowrap"
        style={{ overflowX: 'auto', paddingBottom: 12 }}
      >
        {
          playersData.map((item) => (
            <PlayerItemV2
              key={item.key}
              contactId={item.contactId}
              role={item.role}
              onDelete={onRemove}
              onClick={item.contactId ? noOp : openModalPreset(item.key, item.contactId, item.role)}
            />
          ))
        }
        {
          !preset && (
            <PlayerItemV2
              key="new"
              onClick={openModal}
              contactId={null}
              role="Add Key User"
            />
          )
        }
      </Flex>

      <AssignInterestedPartyModal
        title={modalTitle}
        isVisible={isAssignModalVisible}
        oneContactSelect
        closeModal={closeModal}
        onSave={onSave}
        withRoleSelection={selectedRole ? false : withRoleSelection}
        rolesFilled={rolesFilled}
        rolesOptions={playersData.map(({ key, role }) => ({ value: key, label: role }))}
      />
    </>
  );
};

Players.defaultProps = {
  playersData: [],
  withRoleSelection: true,
  preset: false,
};

const {
  arrayOf,
  bool,
  func,
  number,
  shape,
  string,
} = PropTypes;

Players.propTypes = {
  playersData: arrayOf(shape({
    key: string,
    contactId: number,
    role: string,
  })),
  onAdd: func.isRequired,
  onRemove: func.isRequired,
  withRoleSelection: bool,
  preset: bool,
};

export default Players;
