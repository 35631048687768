import React, {
  useState,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import requests from 'api/requests';

import Button from 'ui/Button';
import Flex from 'ui/Flex';
import Typography from 'ui/Typography';

import useModal from 'ui/hooks/useModal';

import EmailSelector from 'sections/Integrations/modules/EmailSelector';

import useCampaign from 'sections/Communications/hooks/useCampaign';

const Start = (props) => {
  const {
    campaignId,
  } = props;

  const {
    Modal,
    isModalVisible,
    openModal,
    closeModal,
  } = useModal();

  const {
    campaign,
  } = useCampaign(campaignId);

  const [tokenId, setTokenId] = useState(null);

  const onSend = useCallback(async () => {
    requests.integrations.emailCampaigns.actions.start(campaignId)({
      tokenId,
    });

    closeModal();
  }, [
    tokenId,
    campaignId,
  ]);

  return (
    <>
      <Button
        variant="primary"
        onClick={openModal}
        icon="play"
        iconPosition="right"
        noWrap
      >
        Start
      </Button>

      <Modal
        width={600}
        visible={isModalVisible}
        onCancel={closeModal}
        title="Start campaign"
        footer={[
          <Button
            variant="secondary"
            onClick={closeModal}
          >
            Cancel
          </Button>,
          <Button
            variant="primary"
            onClick={onSend}
          >
            Start
          </Button>,
        ]}
      >
        <Flex
          flexDirection="column"
          spacing={2}
        >
          <Typography>
            This will send the email to
            {' '}
            {campaign?.recipients?.length}
            {' '}
            {campaign?.recipients?.length > 1 ? 'contacts' : 'contact'}
          </Typography>
          <Flex
            alignItems="baseline"
            spacing={0.5}
          >
            <Typography>Send as:</Typography>
            <EmailSelector
              onChange={setTokenId}
              showAll={false}
            />
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

const {
  number,
} = PropTypes;

Start.propTypes = {
  campaignId: number.isRequired,
};

export default Start;
