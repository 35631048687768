import { connect } from 'react-redux';

import { currency } from '@symplete/symplete-utils/numbers';

import selectors from 'store/selectors';

import SearchPropertyInput from './SearchPropertyInput';

const mapStateToProps = (state, ownProps) => {
  const {
    selectedPropertyId,
  } = ownProps;

  const selectedProperty = selectors.properties.byId(state, selectedPropertyId);

  let address;
  let price;

  if (selectedProperty.id) {
    address = selectors.addresses.byId(state, selectedProperty.address_id)?.address;
    price = currency.full(selectedProperty.expected_price);
  }

  return {
    selectedProperty: selectedProperty.id ? {
      ...selectedProperty,
      address,
      price,
    } : {},
  };
};

export default connect(mapStateToProps)(SearchPropertyInput);
