import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import { map } from 'shared/utility';

import { NUMBER_OF_STORIES } from 'constants/realtor.constants';

import Grid from 'ui/Grid';

import DropdownTextfield from 'ui/DropdownTextfield';

const options = map(NUMBER_OF_STORIES, (label, key) => ({
  value: key,
  label,
}));

const RangeSelect = (props) => {
  const {
    minValue,
    maxValue,
    handleChangeMin,
    handleChangeMax,
  } = props;

  const [currentMinValue, setCurrentMinValue] = useState(minValue);
  const [currentMaxValue, setCurrentMaxValue] = useState(maxValue);

  useEffect(() => {
    setCurrentMinValue(minValue);
    setCurrentMaxValue(maxValue);
  }, [
    minValue,
    maxValue,
  ]);

  const onChange = useCallback((type) => (value) => {
    if (type === 'min') {
      setCurrentMinValue(value);
      handleChangeMin(value);
    } else {
      setCurrentMaxValue(value);
      handleChangeMax(value);
    }
  }, [
    handleChangeMin,
    handleChangeMax,
  ]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <DropdownTextfield
          options={options}
          value={currentMinValue}
          label="Num of Stories Min"
          onChange={onChange('min')}
        />
      </Grid>
      <Grid item xs={6}>
        <DropdownTextfield
          options={options}
          value={currentMaxValue}
          label="Num of Stories Max"
          onChange={onChange('max')}
        />
      </Grid>
    </Grid>
  );
};

RangeSelect.defaultProps = {
  minValue: null,
  maxValue: null,
};

const {
  func,
  oneOfType,
  string,
  number,
} = PropTypes;

RangeSelect.propTypes = {
  handleChangeMin: func.isRequired,
  handleChangeMax: func.isRequired,
  minValue: oneOfType([string, number]),
  maxValue: oneOfType([string, number]),
};

export default RangeSelect;
