import React from 'react';

import Icon from 'ui/Icon';

const statusToIconProps = {
  'pending-not-received': {
    name: 'loader',
    color: 'focus',
    base: null,
    baseColor: null,
  },
  'pending-received': {
    name: 'minus',
    color: 'main-text',
    base: 'circle',
    baseColor: 'warning',
  },
  bounced: {
    name: 'alert-triangle',
    color: 'contrast',
    base: 'circle',
    baseColor: 'error',
  },
  success: {
    name: 'check',
    color: 'contrast',
    base: 'circle',
    baseColor: 'success',
  },
  1: {
    name: 'loader',
    color: 'focus',
    base: null,
    baseColor: null,
  },
  3: {
    name: 'minus',
    color: 'main-text',
    base: 'circle',
    baseColor: 'warning',
  },
  6: {
    name: 'check',
    color: 'contrast',
    base: 'circle',
    baseColor: 'success',
  },
};

const iconProps = {
  done: {
    name: 'check',
    color: 'contrast',
    base: 'circle',
    baseColor: 'success',
  },
  inProgress: {
    name: 'loader',
    color: 'focus',
    base: null,
    baseColor: null,
  },
  warning: {
    name: 'alert-triangle',
    color: 'contrast',
    base: 'circle',
    baseColor: 'error',
  },
  pending: {
    name: 'minus',
    color: 'main-text',
    base: 'circle',
    baseColor: 'warning',
  },
};

const getStage = {
  sent: (status) => {
    if (status.bounced) {
      return iconProps.warning;
    }

    if (status.sent) {
      return iconProps.done;
    }

    if (status.sending) {
      return iconProps.inProgress;
    }

    return iconProps.pending;
  },
  opened: (status) => {
    if (status.opened) {
      return iconProps.done;
    }

    return iconProps.pending;
  },
  replied: (status) => {
    if (status.replied) {
      return iconProps.done;
    }

    return iconProps.pending;
  },
  link_clicked: (status) => {
    if (status.link_clicked) {
      return iconProps.done;
    }

    return iconProps.pending;
  },
  became_lead: (status) => {
    if (status.became_lead) {
      return iconProps.done;
    }

    return iconProps.pending;
  },
  became_client: (status) => {
    if (status.became_client) {
      return iconProps.done;
    }

    return iconProps.pending;
  },
  closed_deal: (status) => {
    if (status.closed_deal) {
      return iconProps.done;
    }

    return iconProps.pending;
  },
};

const getIconProps = (stage) => (status) => {
  if (!stage || !getStage[stage]) return iconProps.inProgress;

  return getStage[stage](status);
};

const Stage = (props) => {
  const {
    status,
    stage,
  } = props;

  if (!status) {
    return null;
  }

  const {
    name,
    color,
    base,
    baseColor,
  } = getIconProps(stage)(status);

  return (
    <Icon
      name={name}
      color={color}
      base={base}
      baseColor={baseColor}
    />
  );
};

export default Stage;
