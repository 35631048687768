import { connect } from 'react-redux';

import selectors from 'store/selectors';

import Kanban from 'ui/Kanban';
import MatchingBuyerCard from 'sections/Clients/modules/MatchingBuyerCardV2';

const mapStateToProps = (state, ownProps) => {
  const {
    realtorClients: {
      selectedClient,
      clients,
    },
  } = state;

  const propertyId = clients[selectedClient]?.realtor_property_id;

  const lanes = selectors.realtorMatchingProperties.kanbanBuyers(propertyId, selectedClient)(state);

  return {
    lanes,
    CardComponent: MatchingBuyerCard,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(Kanban);
