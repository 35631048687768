import styled from 'styled-components'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;  
  background-color: ${props => props.theme.palette['background-flat']};
  border-radius: 10px;
  border: 1px solid ${props => props.theme.palette.success};
`

export default {
  Root
}
