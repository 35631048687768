import React, {
  useContext,
} from 'react';

import OpportunitiesContext from 'sections/OpportunitiesListing/context/OpportunitiesContext';

import Flex from 'ui/Flex';
import Icon from 'ui/Icon';
import Textfield from 'ui/Textfield';

const Filter = (props) => {
  const {
    setFilter,
  } = useContext(OpportunitiesContext);

  return (
    <Flex
      spacing={2}
      alignItems="center"
      fullWidth
    >
      <Icon
        size="m"
        color="main-text"
        name="filter"
      />
      <Textfield
        placeholder="Search by address or homeowner name"
        onChange={setFilter}
        fullWidth
      />
    </Flex>
  );
};

export default Filter;
