import React, {
  useState,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import requests from 'api/requests';

import { datetime } from '@symplete/symplete-utils';

import { NakedButton } from '@symplete/symplete-ui';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import AreaHighlight from 'ui/AreaHighlight';
import Typography from 'ui/Typography';
// import ChipList from 'ui/ChipList';
import Tooltip from 'ui/Tooltip';

// import RelatedProperty from 'sections/Integrations/modules/ThreadItem/RelatedProperty';
// import Attachment from 'sections/Integrations/modules/ThreadItem/Attachment';

import {
  MessagesCount,
} from './styledItems';

const readThread = async (thread) => {
  try {
    await requests.integrations.emails.updateThread(thread.id)({
      tokenId: thread.tokenId,
      action: 'read',
    });

    return true;
  } catch (updateError) {
    return false;
  }
};

const ThreadItem = (props) => {
  const {
    thread,
    showTo,
    onItemClick: onItemClickProps,
  } = props;

  const [isUnread, setIsUnread] = useState(thread.unread);

  const onItemClick = useCallback(async () => {
    if (onItemClickProps) {
      onItemClickProps(thread);
    }

    if (isUnread) {
      const readSuccess = await readThread({
        tokenId: thread.sympleteTokenId,
        id: thread.id,
      });

      if (readSuccess) {
        setIsUnread(false);
      }
    }
  }, [
    isUnread,
    thread?.id,
    thread.sympleteTokenId,
  ]);

  const emailDate = datetime.unix(thread.last_message_timestamp);

  const fullDate = emailDate.format('DD MMM, HH:mm');
  const shortDate = emailDate.format('HH:mm');

  const content = (
    <Flex
      spacing={2}
      padding="12px 8px"
    >
      <FlexItem xs={4}>
        <Flex
          flexDirection="column"
          spacing={1}
        >
          <Typography
            variant="title3"
            weight="bold"
            noMargin
            align="left"
            style={{ lineHeight: '30px' }}
          >
            {thread.participants?.[0]?.name}
          </Typography>
          <Tooltip
            title={thread.participants?.[0]?.email}
          >
            <Typography
              noMargin
              align="left"
              ellipsis
            >
              {thread.participants?.[0]?.email}
            </Typography>
          </Tooltip>
        </Flex>
      </FlexItem>
      <FlexItem xs={20}>
        <Flex
          fullWidth
          spacing={2}
          justifyContent="space-between"
        >
          <FlexItem
            xs={19}
          >
            <Flex
              flexDirection="column"
              spacing={1}
            >
              <Flex
                fullWidth
                justifyContent="space-between"
              >
                <Flex
                  spacing={1}
                  alignItems="baseline"
                >
                  <Typography
                    variant="title3"
                    weight="bold"
                    align="left"
                    noMargin
                    style={{
                      lineHeight: '30px',
                      width: '600px',
                    }}
                  >
                    {thread.subject}
                  </Typography>
                  {/*
                    email.sympleteAnalysis?.labels?.length > 0 && (
                      <ChipList
                        items={email.sympleteAnalysis?.labels}
                        ellipsis={2}
                      />
                    )
                  */}
                </Flex>
                {
                  showTo && (
                    <Typography
                      variant="title3"
                      align="left"
                      noMargin
                      color="ghost"
                      tag="p"
                    >
                      {thread.participants?.[1]?.email}
                    </Typography>
                  )
                }
              </Flex>
              <Typography
                noMargin
                ellipsis
                align="left"
                style={{
                  maxWidth: '600px',
                }}
              >
                {thread.snippet}
              </Typography>
            </Flex>
          </FlexItem>

          <FlexItem
            xs={2}
          >
            {/*
              email.sympleteAnalysis?.properties?.length > 0 && (
                <RelatedProperty
                  propertyId={email.sympleteAnalysis?.properties[0]}
                />
              )
            */}
          </FlexItem>

          <FlexItem
            xs={3}
          >
            <Typography
              align="right"
            >
              {datetime().isSame(emailDate, 'day') ? shortDate : fullDate}
            </Typography>
            {
              thread?.message_ids?.length > 1 && (
                <MessagesCount color={isUnread ? 'background' : 'background-flat'}>
                  <Typography
                    noMargin
                    color="focus"
                  >
                    {thread.message_ids.length}
                  </Typography>
                </MessagesCount>
              )
            }
          </FlexItem>
        </Flex>
      </FlexItem>
    </Flex>
  );

  return (
    <Flex
      spacing={1}
      flexDirection="column"
      fullWidth
    >
      <NakedButton
        onClick={onItemClick}
        style={{
          width: '100%',
        }}
      >
        {
          isUnread
            ? (
              <AreaHighlight
                variant="backgroundEmpty"
                style={{
                      width: '100%',
                    }}
              >
                {content}
              </AreaHighlight>
                )
            : (
              <>
                {content}
              </>
              )
        }
      </NakedButton>
    </Flex>
  );
};

ThreadItem.defaultProps = {
  showTo: false,
  onItemClick: null,
};

const {
  bool,
  shape,
  func,
} = PropTypes;

ThreadItem.propTypes = {
  thread: shape({}).isRequired,
  showTo: bool,
  onItemClick: func,
};

export default ThreadItem;
