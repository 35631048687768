import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import Icon from 'ui/Icon';

import {
  Wrapper,
} from './styledItems';

const keyToIcon = {
  openRate: {
    icon: 'envelope-open',
    type: 'fontawesome',
  },
  bounceRate: {
    icon: 'times',
    type: 'fontawesome',
  },
  replyRate: {
    icon: 'envelope-open-text',
    type: 'fontawesome',
  },
  linkClickRate: {
    icon: 'link',
    type: 'fontawesome',
  },
};

const MetricsItem = (props) => {
  const {
    metricType,
    value,
  } = props;

  return (
    <Wrapper
      spacing={1.5}
      alignItems="center"
    >
      <Icon
        name={keyToIcon[metricType]?.icon}
        type={keyToIcon[metricType]?.type}
        color="focus"
      />
      <Typography
        variant="cell"
        noMargin
      >
        {value}
      </Typography>
    </Wrapper>
  );
};

const {
  oneOf,
  string,
} = PropTypes;

MetricsItem.propTypes = {
  metricType: oneOf(['openRate', 'bounceRate', 'replyRate', 'linkClickRate']).isRequired,
  value: string.isRequired,
};

export default MetricsItem;
