import styled from 'styled-components';

export const Root = styled.div`
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme?.palette?.border || 'gray'};
  width: 100%;
  padding: 8px 8px;
  .contact-name {
    margin-bottom: 5px;
  }
  .information-item {
    margin-left: -6px;
    margin-bottom: 5px
  }
`;

export default {
  Root,
};
