import React, {
  useState,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import Grid from 'ui/Grid';
import Elevation from 'ui/Elevation';

// import PaperBlock from 'shared/components/UI/PaperBlock';
import TabLabel from 'shared/components/UI/TabLabel';

// import Breadcrumbs from 'sections/Prospects/modules/Breadcrumbs';
import PinnedNote from 'sections/Prospects/modules/PinnedNote';
import Options from 'sections/Prospects/modules/Options';
import ContactInfo from 'sections/Contacts/modules/ContactInfo';
// import ProspectSummary from 'sections/Prospects/modules/ProspectSummary';

import BuyerNeedsView from 'sections/Prospects/modules/BuyerNeedsView';
import ViewingsView from 'sections/Prospects/modules/ViewingsView';
import OffersView from 'sections/Opportunities/modules/OffersView';
import MatchingPropertiesView from 'sections/Prospects/modules/MatchingPropertiesView';

// import MatchingPropertyCard from 'sections/Prospects/modules/MatchingPropertyCard';

import MatchingPropertyRejectionModal from 'sections/Prospects/modals/MatchingPropertyRejectionModal';

import FollowUp from 'sections/Prospects/modules/FollowUp';
import FollowUpRemindersModal from 'shared/modules/FollowUpRemindersModal';

// import Paper from '@material-ui/core/Paper';

import Tabs from 'ui/Tabs';
import Tab from 'ui/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabPanel from '@material-ui/lab/TabPanel';

import {
  Root,
} from './styledItems';

import ProspectItemContext from './context';

const ProspectItem = (props) => {
  const {
    selectedProspect,
    summary,
    contactId,
  } = props;

  const [tab, setTab] = useState('needs');

  const handleTabChange = useCallback((event, value) => {
    setTab(value);
  }, []);

  return (
    <ProspectItemContext.Provider value={{ tab, setTab }}>
      <Root>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            container
            spacing={2}
          >
            <Grid
              item
              xs={6}
              container
              alignItems="center"
            >
              {/* <Breadcrumbs /> */}
            </Grid>

            <Grid
              item
              xs={6}
              container
              spacing={2}
              justify="flex-end"
              alignItems="center"
            >
              <Grid item>
                <FollowUp prospectId={selectedProspect} />
              </Grid>
              <Grid item>
                <PinnedNote />
              </Grid>
              <Grid item>
                <Options />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <Elevation rounded="s" padding="s">
                <ContactInfo
                  contactId={contactId}
                />
              </Elevation>
            </Grid>

            <Grid
              item
              xs={12}
            >
              <Elevation
                rounded="m"
                padding="0 0 14px 0"
                hideOverflow
                style={{
                  width: '-webkit-fill-available',
                }}
              >
                <TabContext value={tab}>
                  <Tabs
                    variant="secondary"
                    value={tab}
                    onChange={handleTabChange}
                  >
                    <Tab
                      variant="secondary"
                      label={(
                        <TabLabel
                          isSelected={tab === 'needs'}
                          variant="title2"
                          highlightSelected={false}
                        >
                          Buyer Needs
                        </TabLabel>
                      )}
                      value="needs"
                    />
                    <Tab
                      variant="secondary"
                      label={(
                        <TabLabel
                          isSelected={tab === 'matches'}
                          variant="title2"
                          highlightSelected={false}
                        >
                          {summary?.matches?.active_matching_properties || 0}
                          {' '}
                          Active
                          {' '}
                          {summary?.matches?.active_matching_properties === 1 ? 'property' : 'properties'}
                        </TabLabel>
                      )}
                      value="matches"
                    />
                    <Tab
                      variant="secondary"
                      label={(
                        <TabLabel
                          isSelected={tab === 'viewings'}
                          variant="title2"
                          highlightSelected={false}
                        >
                          {summary?.viewings?.upcoming_viewings || 0}
                          {' '}
                          Upcoming
                          {' '}
                          {summary?.viewings?.upcoming_viewings === 1 ? 'showing' : 'showings'}
                        </TabLabel>
                      )}
                      value="viewings"
                    />
                    <Tab
                      variant="secondary"
                      label={(
                        <TabLabel
                          isSelected={tab === 'offers'}
                          variant="title2"
                          highlightSelected={false}
                        >
                          {summary?.offers?.total_offers || 0}
                          {' '}
                          {summary?.offers?.total_offers === 1 ? 'offer' : 'offers'}
                          {' '}
                          /
                          {' '}
                          {summary?.offers?.total_counters || 0}
                          {' '}
                          {summary?.offers?.total_counters === 1 ? 'counter' : 'counters'}
                        </TabLabel>
                      )}
                      value="offers"
                    />
                  </Tabs>
                  <TabPanel value="needs" index={0}>
                    <BuyerNeedsView />
                  </TabPanel>
                  <TabPanel value="matches" index={1}>
                    <MatchingPropertiesView
                      prospectId={selectedProspect}
                    />
                  </TabPanel>
                  <TabPanel value="viewings" index={2}>
                    <ViewingsView />
                  </TabPanel>
                  <TabPanel value="offers" index={3}>
                    <OffersView
                      objectType="prospect"
                      objectId={selectedProspect}
                    />
                  </TabPanel>
                </TabContext>
                {/* <Grid
                  container
                  xs={12}
                  spacing={3}
                  alignContent="flex-start"
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <ProspectSummary />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                  >
                    {
                      ['offers', 'counters'].includes(selectedView) && <OffersView objectType="prospect" />
                    }
                    {
                      selectedView === 'buyer needs' && <BuyerNeedsView />
                    }
                    {
                      selectedView === 'viewings' && <ViewingsView />
                    }
                    {
                      selectedView === 'matching properties' && <MatchingPropertiesView />
                    }
                  </Grid>

                </Grid> */}
              </Elevation>
            </Grid>
          </Grid>

        </Grid>

        <MatchingPropertyRejectionModal />
        <FollowUpRemindersModal
          objectId={selectedProspect}
          objectTypeId={30}
        />

      </Root>
    </ProspectItemContext.Provider>
  );
};

ProspectItem.defaultProps = {
  // selectedView: 'offers',
};

const {
  number,
  string,
  oneOfType,
  shape,
} = PropTypes;

ProspectItem.propTypes = {
  selectedProspect: oneOfType([number, string]).isRequired,
  summary: shape({
    matches: shape({
      active_matching_properties: number,
    }),
    viewings: shape({
      upcoming_viewings: number,
    }),
    offers: shape({
      total_offers: number,
      total_counters: number,
    }),
  }).isRequired,
  contactId: number.isRequired,
};

export default ProspectItem;
