import { connect } from 'react-redux';

import ConnectedAccounts from './ConnectedAccounts';

const mapStateToProps = (state) => {
  const {
    socialIntegrations: {
      facebook: {
        userName,
      },
    },
  } = state;

  return {
    fbUserName: userName,
  };
};

export default connect(mapStateToProps)(ConnectedAccounts);
