import styled from 'styled-components';

export const MessagesCount = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${(props) => props?.theme?.palette?.[props.color] || '#f2f6fe'};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default {
  MessagesCount,
};
