import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  deletePropertyViewing,
} from 'store/actions/propertyViewings';

import DeleteButton from './DeleteButton';

const mapStateToProps = (state, ownProps) => {
  const client = selectors.realtorClients.selected(state);

  return {
    propertyId: client?.realtor_property_id,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDelete: (propertyId) => {
    const { viewingId } = ownProps;
    dispatch(deletePropertyViewing(viewingId, propertyId));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  onDelete: () => {
    const { propertyId } = stateProps;
    dispatchProps.onDelete(propertyId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(DeleteButton);
