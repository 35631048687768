import { connect } from 'react-redux';

import {
  updatePropertyRequirement,
  overwritePropertyRequirementRelations,
  addPropertyRequirementRelations,
  removePropertyRequirementRelations,
} from 'store/actions/propertyRequirements';

import PrimaryRequirements from './PrimaryRequirements';

const mapStateToProps = (state, ownProps) => ({

});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (key, value) => {
    dispatch(updatePropertyRequirement(ownProps.itemId)({ [key]: value }));
  },
  onRelationOverwrite: (relationType) => (value) => {
    dispatch(overwritePropertyRequirementRelations(relationType)(ownProps.itemId)(value));
  },
  onRelationAdd: (relationType) => (value) => {
    dispatch(addPropertyRequirementRelations(relationType)(ownProps.itemId)(value));
  },
  onRelationRemove: (relationType) => (value) => {
    dispatch(removePropertyRequirementRelations(relationType)(ownProps.itemId)(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryRequirements);
