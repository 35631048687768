import { connect } from 'react-redux';

import { currency } from '@symplete/symplete-utils/numbers';

import selectors from 'store/selectors';

import {
  getOpportunitiesOverallSummary,
  getOpportunitiesForStoreFilter,
} from 'store/actions/realtorOpportunities';

import {
  clearFilter,
  handleCustomFilter,
} from 'store/actions/realtorOpportunitiesFilter';

import KPIBar from 'ui/KPIBar';

const mapStateToProps = (state) => {
  const summary = selectors.realtorOpportunities.overallSummary(state);
  const filterKey = selectors.realtorOpportunities.opportunitiesFilterKey(state);

  return {
    values: {
      all: summary.total || 0,
      prospects: summary.prospects || 0,
      clients: summary.clients || 0,
      potentialCommission: currency.full(summary.potential_commission),
    },
    indicators: [
      {
        key: 'all',
        title: 'Total',
        iconName: 'users',
        selected: filterKey === 'all',
      },
      {
        key: 'prospects',
        title: 'Buyers',
        iconName: 'investment',
        iconType: 'custom',
        selected: filterKey === 'prospects',
      },
      {
        key: 'clients',
        title: 'Sellers',
        iconName: 'briefcase',
        selected: filterKey === 'clients',
      },
      {
        key: 'potentialCommission',
        title: 'Potential Commission',
        iconName: 'dollar-sign',
        flex: 2.5,
        disabled: true,
        noWrap: true,
      },
    ],
  };
};

const mapDispatchToProps = (dispatch) => ({
  getData: getOpportunitiesOverallSummary,
  onClick: (key) => () => {
    dispatch(clearFilter());
    dispatch(handleCustomFilter(key));
    dispatch(getOpportunitiesForStoreFilter());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(KPIBar);
