import { connect } from 'react-redux';

// import selectors from 'store/selectors';

import SummarySelector from 'shared/components/UI/SummarySelector';

const mapStateToProps = (state) => {
  // const summary = selectors.realtorLeads.overallSummary(state);

  return {
    items: [
      {
        key: 'prospects',
        value: 7,
        title: 'Prospects',
      },
      {
        key: 'buyers',
        value: 2,
        title: 'Buyers',
      },
      {
        key: 'sellers',
        value: 5,
        title: 'Sellers',
      },
      {
        key: 'potentialCommission',
        value: '$524,544',
        title: 'Potential Commissions',
      },
      {
        key: 'completed',
        value: 17,
        title: 'Completed',
      },
    ],
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(SummarySelector);
