import { connect } from 'react-redux';

import selectors from 'store/selectors';

import PropertyAddressInput from 'shared/inputs/AddressInput';

const mapStateToProps = (state, ownProps) => {
  const value = selectors.addresses.byId(state, ownProps.addressId)?.address;

  return {
    value,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleChange: async (addressData) => {
    ownProps.handleChange(addressData);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyAddressInput);
