import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updatePropertyRequirement,
} from 'store/actions/propertyRequirements';

import NumberOfStoriesSelect from 'sections/Leads/inputs/NumberOfStoriesSelect';

const mapStateToProps = (state, ownProps) => {
  const propertyRequirementData = selectors.propertyRequirements.byId(state, ownProps.itemId);

  return {
    rangeSelect: true,
    minValue: propertyRequirementData?.number_of_stories_min,
    maxValue: propertyRequirementData?.number_of_stories_max,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleChangeMin: (value) => {
    dispatch(updatePropertyRequirement(ownProps.itemId)({ number_of_stories_min: value }));
  },
  handleChangeMax: (value) => {
    dispatch(updatePropertyRequirement(ownProps.itemId)({ number_of_stories_max: value }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NumberOfStoriesSelect);
