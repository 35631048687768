import { connect } from 'react-redux';

import moment from 'moment';

import selectors from 'store/selectors';

import ViewingsTable from 'sections/Opportunities/components/ViewingsTable';

import columns from 'sections/Clients/components/ViewingsTable/columns';

const getTomorrowViewings = (items = []) => {
  if (!Array.isArray(items)) {
    return [];
  }
  const viewings = items.filter(({ date_time }) => (
    moment().add(1, 'days').isSame(date_time, 'day')
  ))
    .sort((a, b) => moment(a.date_time).valueOf() - moment(b.date_time).valueOf());

  return viewings;
};

const mapStateToProps = (state) => {
  const client = selectors.realtorClients.selected(state);

  const { events: propertyViewings } = selectors.properties.propertyViewings(state, client?.realtor_property_id);

  return {
    tableKey: 'tomorrow_viewings',
    filterViewings: getTomorrowViewings,
    allViewings: propertyViewings,
    getColumns: columns,
  };
};

export default connect(mapStateToProps)(ViewingsTable);
