import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { currency } from '@symplete/symplete-utils/numbers';

import Grid from 'ui/Grid';

import Typography from 'ui/Typography';

import FormEditableField from 'shared/inputs/FormEditableField';
import DateInput from 'shared/inputs/DateInput';

import SelectPropertyStatusArea from 'sections/MLS/components/SelectPropertyStatusArea';
import withInlineLabel from 'shared/hocs/withInlineLabel';
import withDebouncedCallbacks from 'shared/hocs/withDebouncedCallbacks';

import {
  PROPERTY_TYPES_ARRAY,
  PROPERTY_TYPES,
  PROPERTY_STATUSES_ARRAY,
  PROPERTY_STATUSES,
  SALE_TYPES_ARRAY,
  SALE_TYPES,
  VIEWS_FROM_HOME_ARRAY,
  VIEWS_FROM_HOME,
} from 'constants/realtor.constants';

const detailsFields = [
  {
    label: 'Property Type:',
    key: 'property_type',
    placeholder: '',
    type: 'select',
    options: PROPERTY_TYPES_ARRAY,
    values: PROPERTY_TYPES,
  },
  {
    label: 'Status:',
    key: 'listing_status',
    placeholder: '',
    type: 'listing_status',
    options: PROPERTY_STATUSES_ARRAY,
    values: PROPERTY_STATUSES,
  },
  {
    label: 'Prc/Sqft:',
    type: 'prc/sqft',
  },
  {
    label: 'On market since:',
    key: 'on_market_since',
    placeholder: '54',
    type: 'date',
  },
  {
    label: 'Sale type',
    key: 'sale_type',
    type: 'select',
    options: SALE_TYPES_ARRAY,
    values: SALE_TYPES,
  },
  {
    label: 'Year Built:',
    key: 'year_built',
    placeholder: '1970',
    type: 'date',
    dateView: 'year',
  },
  {
    label: '# of Levels',
    key: 'number_of_stories',
    type: 'number',
  },
  {
    label: 'Views:',
    key: 'views',
    type: 'multiSelect',
    options: VIEWS_FROM_HOME_ARRAY,
    values: VIEWS_FROM_HOME,
  },
];

const renderField = (data, onChange, width) => (field) => {
  const {
    type,
    label,
    key,
    placeholder,
    options,
    dateView,
    values,
  } = field;

  if (type === 'text') {
    return (
      <Fragment key={key}>
        <Grid
          item
          xs={width}
          style={{ paddingRight: 30, height: 43 }}
        >
          <FormEditableField
            variant="inline"
            fieldType="text"
            label={label}
            onChange={onChange(key)}
            placeholder={placeholder}
            value={data[key]}
            alignItems="center"
          />
        </Grid>
      </Fragment>
    );
  }

  if (type === 'number') {
    const FormEditableFieldDebounced = withDebouncedCallbacks(FormEditableField, [
      ['onChange', 800],
    ]);

    return (
      <Fragment key={key}>
        <Grid
          item
          xs={width}
          style={{ paddingRight: 30, height: 43 }}
        >
          <FormEditableFieldDebounced
            variant="inline"
            fieldType="number"
            label={label}
            onChange={onChange(key)}
            placeholder={placeholder}
            value={data[key]}
            alignItems="center"
            min={0}
          />
        </Grid>
      </Fragment>
    );
  }

  if (type === 'select') {
    return (
      <Fragment key={key}>
        <Grid
          item
          xs={width}
          style={{ paddingRight: 30, height: 43 }}
        >
          <FormEditableField
            variant="inline"
            fieldType="select"
            label={label}
            onChange={onChange(key)}
            placeholder={placeholder}
            value={values[data[key]]}
            options={options}
            alignItems="center"
          />
        </Grid>
      </Fragment>
    );
  }

  if (type === 'multiSelect') {
    return (
      <Fragment key={key}>
        <Grid
          item
          xs={width}
          style={{ paddingRight: 30, height: 43 }}
        >
          <FormEditableField
            variant="inline"
            fieldType="multiSelect"
            label={label}
            onChange={onChange(key)}
            placeholder={placeholder}
            value={data[key]}
            options={values}
            alignItems="center"
            fullWidth
          />
        </Grid>
      </Fragment>
    );
  }

  if (type === 'date') {
    return (
      <Fragment key={key}>
        <Grid
          item
          xs={width}
          style={{ paddingRight: 30, height: 43 }}
        >
          <DateInput
            variant="inline"
            view={dateView}
            handleChange={onChange(key)}
            label={label}
            placeholder={placeholder}
            value={data[key]}
            disableFuture
            disableToolbar
            autoOk
            alignItems="center"
          />
        </Grid>
      </Fragment>
    );
  }

  if (type === 'prc/sqft') {
    const Field = withInlineLabel(Typography);

    const { expected_price, floor_area } = data;

    const value = expected_price && floor_area
      ? currency.short(expected_price / floor_area)
      : 'N/A';

    return (
      <Fragment key={key}>
        <Grid
          item
          xs={width}
          style={{ paddingRight: 30, height: 43 }}
        >
          <Field
            variant="inline"
            label={label}
            noMargin
            alignItems="center"
          >
            {value}
          </Field>
        </Grid>
      </Fragment>
    );
  }

  if (type === 'listing_status') {
    const value = values[data[key]];

    return (
      <Fragment key={key}>
        <Grid
          item
          xs={width}
          style={{ paddingRight: 30, height: 43 }}
        >
          <SelectPropertyStatusArea
            label={label}
            variant="inline"
            onChange={onChange(key)}
            value={value}
            options={options}
            alignItems="center"
          />
        </Grid>
      </Fragment>
    );
  }

  return null;
};

const PropertyDetails = (props) => {
  const {
    onChange,
    property,
    columns,
  } = props;

  return (
    <Grid container spacing={4}>
      {/* property details */}
      <Grid item container spacing={2} xs={12}>

        <Grid item container spacing={1} xs={12}>
          {detailsFields.map(renderField(property, onChange, 12 / columns))}
        </Grid>

      </Grid>
    </Grid>
  );
};

PropertyDetails.defaultProps = {
  onChange: () => () => {},
  property: {},
  columns: 4,
};

const {
  func,
  number,
  shape,
} = PropTypes;

PropertyDetails.propTypes = {
  onChange: func,
  property: shape(),
  columns: number,
};

export default PropertyDetails;
