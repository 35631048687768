import React from 'react';
import PropTypes from 'prop-types';

import ActiveFilters from './ActiveFilters';
import Search from './Search';

const options = [
  {
    value: 'all',
    label: 'All prospects/clients',
  },
  {
    value: 'archived',
    label: 'Archived prospects/clients',
  },
  {
    value: 'prospects',
    label: 'Only prospects',
  },
  {
    value: 'clients',
    label: 'Only clients',
  },
];

const ProspectsTableSearchFilter = (props) => {
  const {
    // onFilterClick,
  } = props;

  return (
    <div>
      <Search
        options={options}
        searchInputPlaceholder="Search all prospects & clients"
        dropdownFilterProps={{
          style: { minWidth: 240 },
        }}
      />

      <ActiveFilters />
    </div>
  );
};

ProspectsTableSearchFilter.defaultProps = {

};

const {
  // func,
} = PropTypes;

ProspectsTableSearchFilter.propTypes = {
  // onFilterClick: func.isRequired,
};

export default ProspectsTableSearchFilter;
