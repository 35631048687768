import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background-color: ${(props) => props.theme?.palette?.highlight};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("${(props) => props.src}");
  border-radius: 10px;
  text-transform: uppercase;
`;

export const Initials = styled.div`
  color: white;
  font-size: ${(props) => props.size * 0.8}px;
  font-weight: bold;
  font-family: ${(props) => props.theme?.fonts?.primary};
`;

export default {
  Root,
  Initials,
};
