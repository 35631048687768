import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

const Options = (props) => {
  const {
    status,
    onStatusChange,
    onDelete,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const onButtonClick = useCallback((event) => {
    setAnchorEl(event.target);
    setMenuOpen(true);
  }, []);

  const handleStatusChange = useCallback((newStatus) => () => {
    onStatusChange(newStatus);
    setMenuOpen(false);
  }, [onStatusChange]);

  const handleDelete = useCallback(() => {
    onDelete();
    setMenuOpen(false);
  }, [onDelete]);

  const closeMenu = useCallback(() => {
    setMenuOpen(false);
  }, []);

  return (
    <>
      <Button
        icon="menu"
        variant="secondary"
        onClick={onButtonClick}
      />
      <Menu
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={closeMenu}
      >
        {
          status === 2 && (
            <MenuItem onClick={handleStatusChange(1)}>
              Set to active
            </MenuItem>
          )
        }
        {
          status !== 2 && (
            <MenuItem onClick={handleStatusChange(2)}>
              Archive
            </MenuItem>
          )
        }
        <MenuItem onClick={handleDelete}>
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

Options.defaultProps = {
  status: null,
};

const {
  func,
  number,
} = PropTypes;

Options.propTypes = {
  onStatusChange: func.isRequired,
  onDelete: func.isRequired,
  status: number,
};

export default Options;
