import { connect } from 'react-redux';

import selectors from 'store/selectors';

import RejectedProperties from 'sections/Prospects/modules/MatchingPropertiesView/MatchingPropertiesTable';

const mapStateToProps = (state, ownProps) => {
  const {

  } = state;

  const {
    prospectId,
  } = ownProps;

  const matchingProperties = selectors.realtorMatchingProperties.byFilter({
    prospect_id: prospectId,
    status: 5,
  })()(state);

  const properties = selectors.realtorMatchingProperties.rejectedProperties(matchingProperties?.items || [])(state);

  return {
    properties,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(RejectedProperties);
