import { connect } from 'react-redux';
import { toDoListSettingsHideAction } from 'store/actions/to-do-list';

import SettingsHeader from './SettingsHeader';

const mapDispatchToProps = {
  toDoListSettingsHideAction,
};

export default connect(null, mapDispatchToProps)(SettingsHeader);
