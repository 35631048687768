import React, {
  useContext,
  useState,
  useCallback,
} from 'react'

import Flex from 'ui/Flex'
import Typography from 'ui/Typography'
import PriceRange from 'ui/PriceRange'

import OpportunitiesContext from 'sections/OpportunitiesListing/context/OpportunitiesContext';

const AVMRange = () => {
  const {
    setPriceRange,
  } = useContext(OpportunitiesContext);

  const [value, setValue] = useState([0, 1e8]);

  const onChange = useCallback((newValue) => {
    setPriceRange(newValue);
    setValue(newValue);
  }, [
    value,
    setPriceRange,
  ]);

  return (
    <Flex
      spacing={2}
      alignItems="center"
    >
      <Typography
        variant="title2"
        tag="p"
        noMargin
        weight="bold"
        noWrap
      >
        Home Price
      </Typography>
      <PriceRange 
        value={value}
        onChange={onChange}
      />
    </Flex>
  )
}

export default AVMRange
