import React from 'react';
import { Button } from 'antd';
import classnames from 'classnames';

import './ButtonLink.scss';

const ButtonLink = ({ className, children, ...otherProps }) => (
  <button
    className={classnames(
      'button-link',
      {
        [className]: className,
      },
    )}
    {...otherProps}
  >
    {children}
  </button>
);

export default ButtonLink;
