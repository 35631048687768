import React, { useState, useCallback } from 'react';
// import PropTypes from 'prop-types';

import { NakedButton } from '@symplete/symplete-ui';

import Grid from 'ui/Grid';
import Typography from 'ui/Typography';

import {
  DragDropContext,
  Droppable,
} from 'react-beautiful-dnd';

import KanbanView from './KanbanView';
import RejectedProperties from './RejectedProperties';
// import AssignPropertyModal from './AssignPropertyModal';

const statuses = {
  review: 1,
  viewings: 2,
  offers: 3,
  won: 4,
  rejected: 5,
};

const MatchingPropertiesView = (props) => {
  const {
    // onAssignPropertyClick,
    openSearchMLSModal,
    prospectId,
    updateMatchingProperty,
  } = props;

  // const [isModalVisible, setIsModalVisible] = useState(false);

  // const setModalVisibility = useCallback((isVisible) => () => {
  //   setIsModalVisible(isVisible);
  // }, []);

  const onMatchingPropertyDrop = useCallback(({
    draggableId: matchingPropertyId,
    destination,
    source,
  }) => {
    if (destination?.droppableId !== source?.droppableId) {
      const oldStatus = statuses[source?.droppableId];
      const newStatus = statuses[destination?.droppableId];

      if (newStatus) {
        updateMatchingProperty(matchingPropertyId)({
          status: newStatus,
        }, {
          status: oldStatus,
        });
      }
    }
  }, [
    updateMatchingProperty,
  ]);

  return (
    <DragDropContext
      // onDragStart={console.log}
      // onDragUpdate={console.log}
      onDragEnd={onMatchingPropertyDrop}
    >
      <Grid container spacing={4}>

        <Grid item xs={12}>
          <NakedButton onClick={openSearchMLSModal}>
            <Typography
              variant="button"
              color="link"
              weight={600}
            >
              + Assign new property
            </Typography>
          </NakedButton>
        </Grid>

        <Grid item xs={12}>
          <KanbanView prospectId={prospectId} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h2" tag="h3">
            Rejected Properties
          </Typography>
          <Droppable droppableId="rejected">
            {
              (provided) => (
                <div ref={provided.innerRef}>
                  <RejectedProperties
                    prospectId={prospectId}
                  />
                </div>
              )
            }
          </Droppable>
        </Grid>

        {/* <AssignPropertyModal
          isVisible={isModalVisible}
          closeModal={setModalVisibility(false)}
        /> */}

      </Grid>
    </DragDropContext>
  );
};

export default MatchingPropertiesView;
