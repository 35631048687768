import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { ThemeContext } from 'styled-components';

import Icon from 'ui/Icon';
import Typography from 'ui/Typography';

import {
  Wrapper,
  IconContainer,
} from './styledItems';

const ProgressItem = (props) => {
  const {
    iconName,
    status,
    title,
    subtitle,
  } = props;

  const theme = useContext(ThemeContext);

  return (
    <Wrapper>
      {
        title && (
          <Typography
            variant="subtitle1"
            style={{ marginBottom: 4, textTransform: 'capitalize' }}
            weight={500}
          >
            {title}
          </Typography>
        )
      }

      {
        subtitle && (
          <Typography
            color="descriptor-text"
            variant="subtitle2"
            style={{ marginBottom: 4, textTransform: 'none' }}
          >
            {subtitle}
          </Typography>
        )
      }
      {
        iconName && (
          <IconContainer backgroundColor={theme.palette[status]}>
            <Icon
              name={iconName}
              color="main-text-inversed"
              size="s"
            />
          </IconContainer>
        )
      }
    </Wrapper>
  );
};

ProgressItem.defaultProps = {
  title: null,
  subtitle: null,
  iconName: null,
  status: 'in progress',
};

const {
  string,
  oneOf,
} = PropTypes;

ProgressItem.propTypes = {
  title: string,
  subtitle: string,
  iconName: string,
  status: oneOf(['in progress', 'done', 'attention']),
};

export default ProgressItem;
