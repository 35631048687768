import { createSelector } from 'reselect';

import { isEmpty } from 'shared/utility';
import { currency } from '@symplete/symplete-utils/numbers';
import moment from 'moment';

const filterObj = (state) => state.crmLeadsFilter;
const sourceTypes = (state) => state.crmSources.sourceTypes;

const temps = {
  null: 'Not selected',
  1: 'Cold',
  2: 'Warm',
  3: 'Hot',
};

const getTempChipValue = (temp = []) => temp.map((item) => temps[item]).join(', ');

const getValueRangeChipValue = (valueRange = []) => `${currency.short(valueRange[0])}–${currency.short(valueRange[1])}`; // eslint-disable-line max-len

const getDateRangeChipValue = (range = []) => `${moment(range[0]).format('MMM D, YYYY')}–${moment(range[1]).format('MMM D, YYYY')}`; // eslint-disable-line max-len

const getSourcesChipValue = (filterSources, sourceTypesList) => filterSources.map((item) => sourceTypesList.find((type) => type.id === item).name).join(', '); // eslint-disable-line max-len

export default createSelector(
  filterObj,
  sourceTypes,
  (filter, sources) => {
    const chips = [];

    if (!isEmpty(filter.temp)) {
      chips.push({
        key: 'temp',
        title: 'Status',
        value: getTempChipValue(filter.temp),
      });
    }

    if (!isEmpty(filter.valueRange)) {
      chips.push({
        key: 'valueRange',
        title: 'Deal value',
        value: getValueRangeChipValue(filter.valueRange),
      });
    }

    if (!isEmpty(filter.createdAtRange)) {
      chips.push({
        key: 'createdAtRange',
        title: 'Date created',
        value: getDateRangeChipValue(filter.createdAtRange),
      });
    }

    if (!isEmpty(filter.lastContactRange)) {
      chips.push({
        key: 'lastContactRange',
        title: 'Last contact',
        value: getDateRangeChipValue(filter.lastContactRange),
      });
    }

    if (!isEmpty(filter.sourceType)) {
      chips.push({
        key: 'sourceType',
        title: 'Sources',
        value: getSourcesChipValue(filter.sourceType, sources),
      });
    }

    return chips;
  },
);
