import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';

const CreateProspect = (props) => {
  const {
    onClick,
    type,
  } = props;

  return (
    <Button
      variant="primary"
      onClick={onClick}
      icon="star"
    >
      {
        type === 'properties' ? 'Make Client' : 'Make Prospect'
      }
    </Button>
  );
};

const {
  func,
  string,
} = PropTypes;

CreateProspect.propTypes = {
  onClick: func.isRequired,
  type: string.isRequired,
};

export default CreateProspect;
