import React, { 
  useState, 
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import Modal from 'ui/Modal';

import AttachmentsModule from 'shared/AttachmentsV4';

import RootButton from './RootButton';

import useAttachments from 'shared/hooks/useAttachments'


const ListingAgreement = (props) => {
  const {
    objectType,
    objectId,
    attachmentType,
    modalTitle,
    buttonLabelEmpty,
    buttonLabelComplete,
  } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [attachments, setAttachments] = useState([])

  const [list] = useAttachments(objectType, objectId, attachmentType)

  const toggleModal = useCallback((isVisible) => () => {
    setIsModalVisible(isVisible);
  }, []);

  
  useEffect(() => {
    setAttachments(list)
  }, [list])
  
  const hasAttachments = attachments?.length > 0

  return (
    <>
      <RootButton
        onClick={toggleModal(true)}
        hasAttachments={hasAttachments}
        buttonLabelEmpty={buttonLabelEmpty}
        buttonLabelComplete={buttonLabelComplete}
      />

      <Modal
        width={720}
        visible={isModalVisible}
        onCancel={toggleModal(false)}
        footer={null}
        title={modalTitle}
      >
        <AttachmentsModule
          objectType={objectType}
          objectId={objectId}
          attachmentType={attachmentType}
          onRefresh={setAttachments}
        />
      </Modal>
    </>
);
};

ListingAgreement.defaultProps = {
  attachmentType: null,
  modalTitle: 'Attachments',
  buttonLabelEmpty: 'Upload',
  buttonLabelComplete: 'See attachments',
};

const {
  number,
  string,
} = PropTypes;

ListingAgreement.propTypes = {
  objectType: number.isRequired,
  objectId: number.isRequired,
  attachmentType: number,
  modalTitle: string,
  buttonLabelEmpty: string,
  buttonLabelComplete: string,
};

export default ListingAgreement;
