import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';

const CommonTools = (props) => {
  const { onNewEvent } = props;

  return (
    <Button variant="primary" icon="plus" onClick={onNewEvent}>
      New Entry
    </Button>
  );
};

CommonTools.defaultProps = {};

const { func } = PropTypes;

CommonTools.propTypes = {
  onNewEvent: func.isRequired,
};

export default CommonTools;
