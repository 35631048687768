import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import Block from 'shared/components/UI/Block';
import Typography from 'ui/Typography';
import AdditionalAmenities from 'shared/modules/AdditionalAmenities';

import PrimaryRequirements from 'sections/Leads/modules/PrimaryRequirements';

import Comments from 'sections/Prospects/components/Comments';

const BuyerNeedsView = (props) => {
  const {
    propetyRequirementId,
  } = props;

  return (
    <Grid container spacing={3}>

      <Grid item xs={12}>
        <PrimaryRequirements itemId={propetyRequirementId} />
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="h3"
        >
          Additional Amenities
        </Typography>
        <AdditionalAmenities
          itemId={propetyRequirementId}
          itemType="property_requirements"
        />
      </Grid>

      <Grid item xs={12}>
        <Block>
          <Comments />
        </Block>
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant="body2"
          color="ghost"
        >
          * every measurement such as a sqft is approx. Buyer to confirm all details before purchase.
        </Typography>
      </Grid>

    </Grid>
  );
};

const {
  number,
} = PropTypes;

BuyerNeedsView.propTypes = {
  propetyRequirementId: number.isRequired,
};

export default BuyerNeedsView;
