import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  overwritePropertyRequirementRelations,
} from 'store/actions/propertyRequirements';

import ViewsFromHomeSelect from 'sections/Leads/inputs/ViewsFromHomeSelect';

const mapStateToProps = (state, ownProps) => {
  const propertyRequirementData = selectors.propertyRequirements.byId(state, ownProps.itemId);

  return {
    value: propertyRequirementData?.views || undefined,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleChange: (value) => {
    dispatch(overwritePropertyRequirementRelations('views')(ownProps.itemId)(value));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(ViewsFromHomeSelect);
