import { connect } from 'react-redux';

import { updatePropertyViewing } from 'store/actions/propertyViewings';

import ConfirmationCell from './ConfirmationCell';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (checked) => {
    dispatch(updatePropertyViewing(ownProps.viewingId)({ is_confirmed: checked }));
  },
});

export default connect(null, mapDispatchToProps)(ConfirmationCell);
