import { useState, useEffect } from 'react';

import requests from 'api/requests';

const defaultScopes = {
  google: new Set(),
  icloud: new Set()
};

const getScopes = (integrations = []) =>
  integrations.reduce((acc, item) => {
    if (!acc[item.provider]) {
      acc[item.provider] = new Set();
    }

    acc[item.provider].add(item.scope);

    return acc;
  }, defaultScopes);

const arrangeByScope = (integrations = []) =>
  integrations.reduce((acc, item) => {
    if (!acc[item.scope]) {
      acc[item.scope] = [item];
    } else {
      acc[item.scope].push(item);
    }

    return acc;
  }, {});

const arrangeByProvider = (integrations = []) =>
  integrations.reduce((acc, item) => {
    if (!acc[item.provider]) {
      acc[item.provider] = [item];
    } else {
      acc[item.provider].push(item);
    }

    return acc;
  }, {});

const useIntegrations = () => {
  const [integrations, setIntegrations] = useState(null);
  const [scopes, setScopes] = useState(defaultScopes);
  const [integrationsByScope, setByScope] = useState({});
  const [integrationsByProvider, setByProvider] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getIntegrations = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await requests.integrations.list();

      setIntegrations(response.data);
      setScopes(getScopes(response.data));
      setByScope(arrangeByScope(response.data));
      setByProvider(arrangeByProvider(response.data));
      console.log('on useIntegrations hook...');
      console.log(
        'gotten response from requests.integrations.list: ',
        response.data
      );
    } catch (newError) {
      console.log('Error on useIntegrations hook :(');
      setError(newError);
    } finally {
      console.log('useIntegrations hook end...');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getIntegrations();
  }, []);

  return {
    integrations,
    scopes,
    integrationsByScope,
    integrationsByProvider,

    isLoading,
    error,
    getIntegrations
  };
};

export default useIntegrations;
