import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px 0 7px;
  padding: 5px;
`;

export const SymCardLogo = styled.span`
  font-family: EstiloText;
  font-weight: 300;
  color: #a1bdcb;
  font-size: 19px;
  cursor: pointer;
`;

export default {
  Root,
  SymCardLogo,
};
