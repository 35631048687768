import { connect } from 'react-redux';

import store from 'store';

import {
  setSelectedLead,
} from 'store/actions/realtorLeads';

import Lead from './Lead';

const mapStateToProps = (state, ownProps) => {
  const {
    realtorLeads: {
      selectedLead,
    },
  } = state;

  const { leadId } = ownProps.match.params;

  if (selectedLead !== leadId) {
    store.dispatch(setSelectedLead(leadId));
  }

  return {
    leadId,
  };
};

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Lead);
