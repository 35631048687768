import React from 'react';

import PropTypes from 'prop-types';

import { NakedButton } from '@symplete/symplete-ui';

import ClickAwayListener from 'ui/ClickAwayListener';
import Selection from 'ui/Selection';
import Tag from 'ui/Tag';

import useEditMode from 'ui/hooks/useEditMode';

import {
  sentiments,
  sentimentIcons,
} from 'constants/feedback.constants';

const SentimentInput = (props) => {
  const {
    sentiment,
    onChange,
    defaultView,
  } = props;

  const {
    mode,
    setEditMode,
    setDisplayMode,
  } = useEditMode(defaultView);

  if (mode === 'display' && sentiment) {
    return (
      <NakedButton onClick={setEditMode}>
        <Tag
          variant={sentiments[sentiment]}
          size="m"
          typographyProps={{
            textTransform: 'capitalize',
          }}
        >
          {sentiments[sentiment]}
        </Tag>
      </NakedButton>
    );
  }

  return (
    <ClickAwayListener onClickAway={setDisplayMode}>
      <div>
        <Selection
          options={Object.keys(sentiments).map((item) => ({
            id: +item,
            label: sentiments[item],
            iconName: sentimentIcons[sentiments[item]],
            iconColor: sentiments[item],
          }))}
          value={sentiment}
          onChange={onChange}
          radio
          variant="horizontal"
          itemTypographyProps={{
            textTransform: 'capitalize',
          }}
        />
      </div>
    </ClickAwayListener>
  );
};

SentimentInput.defaultProps = {
  sentiment: null,
  defaultView: 'edit',
};

const {
  func,
  oneOf,
} = PropTypes;

SentimentInput.propTypes = {
  sentiment: oneOf(Object.keys(sentiments)),
  defaultView: oneOf(['display', 'edit']),
  onChange: func.isRequired,
};

export default SentimentInput;
