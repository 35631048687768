import styled from 'styled-components';

export const BgWrapper = styled.div`
  background: white;
  height: inherit;
`;

export const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: inherit;
  margin: 0 auto;
  background: white;
  background-color: ${(props) => props?.theme?.palette?.['background-app'] || 'black'};
`;

export const AppBody = styled.section`
  flex: 1;
  display: flex;
  max-height: 100vh;
  overflow-y: hidden;
`;

export const AppMain = styled.main`
  overflow-y: auto;
  display: flex;
  max-height: 100%;
  width: 100%;
  background-color: ${(props) => props?.theme?.palette?.background || 'white'};
  background-image: ${(props) => props.backgroundImage && `url(${props.backgroundImage})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 20px 10px;
`;

export const WidthLimit = styled.div`
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
`;
