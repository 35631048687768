import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import requests from 'api/requests';

import useLocalStorageItem from 'shared/hooks/useLocalStorageItem';
import updateLocalStorage from 'shared/utils/updateLocalStorageWithCustomEvent';

import Elevation from 'ui/Elevation';
import PostCard from 'shared/PostCard';

import 'swiper/swiper.scss';
import 'swiper/modules/pagination/pagination.scss';

import ExternalPropertyOverview from './modules/ExternalPropertyOverview';
import ExternalContactOverview from './modules/ExternalContactOverview';
import OfferForm from './modules/OfferForm/OfferForm';
import ContacForm from './modules/ContactForm';
import RequestShowing from './modules/RequestShowing/RequestShowing';

const getExternalToken = (pathname) => {
  if (!pathname) return null;

  const token = pathname.split('/').pop();

  return token;
};

const ExternalOffersV2 = () => {
  const [propertyData, setPropertyData] = useState(null);
  const [contactData, setContactData] = useState(null);
  const [propertyImages, setPropertyImages] = useState(null);
  const [tab, setTab] = useState();

  const [loading, setLoading] = useState(true);

  const externalToken = useLocalStorageItem('externalToken');
  const location = useLocation();

  useEffect(() => {
    const token = getExternalToken(location.pathname);
    updateLocalStorage('set', 'externalToken', token);
  }, [location?.pathname]);

  useEffect(() => {
    if (externalToken) {
      const getPropertyData = async () => {
        const response = await requests.external.realtor.offers.get();

        setPropertyData(response.data.propertyData);
        setContactData(response.data.contactData);
        setPropertyImages(response.data.propertyImages);

        setLoading(false);
      };
      getPropertyData();
    }
  }, [externalToken]);
  // eslint-disable-next-line no-console
  console.log('==== propertyData ====', propertyData);
  return (
    <PostCard
      header={
        !loading && (
          <Elevation rounded="s" padding="m" fullHeight>
            <ExternalContactOverview
              contactData={contactData}
              onSwitch={(val) => setTab(val)}
              propertyData={propertyData}
            />
          </Elevation>
        )
      }
      footer={
        !loading && (
          <Elevation rounded="s" padding="m" fullHeight>
            <ExternalPropertyOverview
              propertyData={propertyData}
              propertyImages={propertyImages}
            />
          </Elevation>
        )
      }
      propertyData={propertyData}
      propertyImages={propertyImages}
    >
      {loading ? (
        ''
      ) : (
        <>
          <ContacForm
            isVisible={tab === 'contact'}
            onClose={() => setTab(undefined)}
            propertyData={propertyData}
          />
          <OfferForm
            isVisible={tab === 'offer'}
            onClose={() => setTab(undefined)}
            propertyData={propertyData}
          />
          <RequestShowing
            isVisible={tab === 'request'}
            onClose={() => setTab(undefined)}
            propertyData={propertyData}
          />
        </>
      )}
    </PostCard>
  );
};

export default ExternalOffersV2;
