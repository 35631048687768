/* eslint-disable camelcase */

import { connect } from 'react-redux';

import {
  setNewPropertyFormField,
  setNewPropertyNestedFormField,
} from 'store/actions/newRealtorPropertyForm';
import NewPropertyCommercialForm from './CommercialForm';

const mapStateToProps = (state) => {
  const {
    realtorNewPropertyForm: {
      listing_agent,
      on_market_since,
      address_id,
      seller,
      transfer_state,
    },
  } = state;

  return {
    selectedListingAgent: listing_agent,
    selectedSeller: seller,
    onMarketSince: on_market_since,
    addressId: address_id,
    transferState: transfer_state,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleChange: (fieldName) => (value) => {
    dispatch(setNewPropertyFormField(fieldName)(value));
  },
  handleChangeNested: (parentName) => (fieldName) => (value) => {
    dispatch(setNewPropertyNestedFormField(parentName)(fieldName)(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPropertyCommercialForm);
