import React from 'react';
import PropTypes from 'prop-types';

import Container from 'ui/Container';
import Item from 'ui/Item';
import Typography from 'ui/Typography';
import Tag from 'ui/Tag';
import EditableArea from 'ui/EditableArea';
import Textfield from 'ui/Textfield';

const PropertyTab = (props) => {
  const {
    address,
    price,
    opportunityName,
    onChangeOpportunityName,
  } = props;

  return (
    <Container spacing={2} alignItems="baseline" wrap="nowrap">
      <Item>
        <Tag variant="seller">
          SELLER
        </Tag>
      </Item>
      <Item>
        <EditableArea getEditComponent={(onBlur) => ( // eslint-disable-line react/jsx-no-bind
          <Textfield
            value={opportunityName || address}
            onChange={onChangeOpportunityName}
            onBlur={onBlur}
            focusOnRender
            label="Name"
          />
          )}
        >
          <Typography
            variant="title2"
            noMargin
            textAlign="center"
            noWrap
          >
            {opportunityName || address}
          </Typography>
        </EditableArea>
      </Item>
      <Item>
        <Typography variant="title2" noMargin textAlign="center">
          |
        </Typography>
      </Item>
      <Item>
        <Typography variant="title2" weight="700" noMargin textAlign="center" noWrap>
          {price}
        </Typography>
      </Item>
    </Container>
  );
};

PropertyTab.defaultProps = {
  address: null,
  price: null,
  opportunityName: null,
};

const {
  string,
  func,
} = PropTypes;

PropertyTab.propTypes = {
  address: string,
  price: string,
  opportunityName: string,
  onChangeOpportunityName: func.isRequired,
};

export default PropertyTab;
