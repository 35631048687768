import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import {
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';

import InfiniteScroll from 'react-infinite-scroller';

import Flex from 'ui/Flex';
import Button from 'ui/Button';
import Spin from 'ui/Spin';
import Typography from 'ui/Typography';

import {
  BoardWrapper,
  LaneContent,
  CardWrapper,
  Lane,
} from './styledItems';

const wrapperStyle = {
  height: '100%',
  overflowX: 'auto',
};

const Kanban = (props) => {
  const {
    lanes,
    CardComponent,
    onCardClick,
    onAdd,
    height: rootHeight,
  } = props;

  const colStyle = {
    width: `${100 / (lanes.length || 1)}%`,
    height: '100%',
  };

  const handleCardClick = useCallback((item) => (event) => {
    onCardClick && onCardClick(item, event);
  }, [
    onCardClick,
  ]);

  return (
    <BoardWrapper
      height={rootHeight}
    >
      <Flex
        style={wrapperStyle}
      >
        {
          lanes.map((lane) => (
            <Lane
              key={lane.id}
              style={colStyle}
              flexDirection="column"
              fullHeight
            >
              <Flex
                flexDirection="column"
                fullWidth
                alignItems="center"
              >
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  fullWidth
                >
                  <Typography
                    variant="title1"
                    tag="h3"
                    weight="bold"
                    noMargin
                    style={{ lineHeight: '44px' }}
                  >
                    {lane.name}
                  </Typography>
                  {
                    lane.canAdd && (
                      <Button
                        variant="tertiary"
                        icon="plus"
                        onClick={onAdd?.[lane.id]}
                      />
                    )
                  }
                </Flex>
                {
                  lane.subheading && (
                    <Typography
                      variant="h4"
                      align="center"
                      tag="p"
                    >
                      {lane.subheading}
                    </Typography>
                  )
                }
              </Flex>

              <Droppable droppableId={lane.id}>
                {
                  (provided) => (
                    <LaneContent
                      ref={provided.innerRef}
                    >
                      <InfiniteScroll
                        initialLoad
                        loadMore={lane.loadMore}
                        hasMore={lane.listHasMore}
                        loader={<Flex justifyContent="center"><Spin /></Flex>}
                        useWindow={false}
                      >
                        {
                          lane.list?.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={`${item.id}`}
                              index={index}
                            >
                              {
                                (provided) => (
                                  <CardWrapper
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    onClick={handleCardClick(item)}
                                  >
                                    <CardComponent
                                      {...item}
                                    />
                                  </CardWrapper>
                                  )
                              }
                            </Draggable>
                          ))
                        }
                      </InfiniteScroll>
                      {provided.placeholder}
                    </LaneContent>
                    )
                }
              </Droppable>
            </Lane>
          ))
        }
      </Flex>
    </BoardWrapper>
  );
};

Kanban.defaultProps = {
  lanes: [],
  onCardClick: null,
  height: null,
};

const {
  arrayOf,
  bool,
  elementType,
  func,
  number,
  oneOfType,
  shape,
  string,
} = PropTypes;

Kanban.propTypes = {
  lanes: arrayOf(shape({
    id: string,
    name: string,
    list: arrayOf(shape({
      id: oneOfType([number, string]).isRequired,
    })),
    listHasMore: bool,
    loadMore: func,
  })),
  CardComponent: elementType.isRequired,
  onCardClick: func,
  height: string,
};

export default Kanban;
