import { connect } from 'react-redux';

import selectors from 'store/selectors';

import { updateTransaction } from 'store/actions/realtorTransactions';

import CompleteTransaction from './CompleteTransaction';

const mapStateToProps = (state, ownProps) => {
  const {
    transactionId,
  } = ownProps;

  const transaction = selectors.realtorTransactions.byId(state, transactionId);

  const timelineItemsFields = [
    'initial_documents_completed_at',
    'seller_disclosure_completed_at',
    'inspections_completed_at',
    'appraisal_completed_at',
    'loan_completed_at',
    'final_walkthrough_completed_at',
    'close_escrow_completed_at',
    'move_out_date_completed_at',
  ];

  const hasUnfinishedItems = timelineItemsFields.some((field) => !transaction?.[field]);

  return {
    hasUnfinishedItems,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onComplete: () => {
    const now = new Date();
    dispatch(updateTransaction(ownProps.transactionId)({
      status: 6,
      completed_at: now,
      initial_documents_completed_at: now,
      seller_disclosure_completed_at: now,
      inspections_completed_at: now,
      appraisal_completed_at: now,
      loan_completed_at: now,
      final_walkthrough_completed_at: now,
      close_escrow_completed_at: now,
      move_out_date_completed_at: now,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CompleteTransaction);
