/* eslint-disable no-param-reassign */
import { connect } from 'react-redux';

import { createPropertyFromNewPropertyForm } from 'store/actions/properties';

import { closeNewPropertyModal } from 'store/actions/newRealtorPropertyModal';

import { setNewPropertyForm } from 'store/actions/newRealtorPropertyForm';

import { handleModalFormCache } from 'store/actions/modals';

import handleDrafts from 'shared/hooks/useModalsDrafts/handleDrafts';

import NewPropertyModal from './NewPropertyModal';

const mapStateToProps = (state, ownProps) => {
  const {
    realtorNewPropertyModal: { isVisible, saveAttempt },
  } = state;

  return {
    isVisible: ownProps.isVisible || isVisible,
    saveAttempt: ownProps.saveAttempt || saveAttempt,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  closeModal: () => {
    dispatch(
      handleModalFormCache(
        'realtorNewPropertyModal',
        'realtorNewPropertyForm',
      )(),
    );
    ownProps.saveAttempt = false;

    if (ownProps.closeModal) {
      ownProps.closeModal();
    } else {
      dispatch(closeNewPropertyModal());
    }
  },
  saveProperty: async () => {
    const id = await dispatch(createPropertyFromNewPropertyForm());

    ownProps.saveAttempt = true;

    if (id === null) return null;

    if (ownProps.closeModal) {
      ownProps.closeModal();
    } else {
      dispatch(closeNewPropertyModal());
    }

    dispatch(
      handleModalFormCache(
        'realtorNewPropertyModal',
        'realtorNewPropertyForm',
      )('clear'),
    );

    if (ownProps.onCreateSuccess) {
      ownProps.onCreateSuccess(id);
    }

    return id;
  },
  getCachedData: () => {
    const cachedData = handleDrafts.get('realtorNewPropertyModal');
    if (cachedData) {
      dispatch(setNewPropertyForm(cachedData));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPropertyModal);
