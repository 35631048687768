/* eslint-disable camelcase */
import { connect } from 'react-redux';

// import selectors from 'store/selectors';

// import { omit } from 'shared/utility'

import {
  createMatchingProperty,
  // removeMatchingProperty,
  resetMatchingPropertyFilters,
} from 'store/actions/realtorMatchingProperties';

import {
  getPropertyById,
} from 'store/actions/properties';

import AssignContactModal from 'sections/Contacts/modals/AssignContactModal';

const mapStateToProps = (state, ownProps) =>
  // const matches = selectors.realtorMatchingProperties.propertyMatches(ownProps.propertyId)(state);

   ({
    // selectedContacts: matches.map((item) => item.realtor_contact_id),
    // matches,
    // withAddContact: true,
  });
const mapDispatchToProps = (dispatch, ownProps) => ({
  onSave: async ({
    addedContacts,
    // removedContacts,
    // matches,
  }) => {
    const addContacts = addedContacts.map(async (contactId) => {
      await dispatch(createMatchingProperty({
        realtor_property_id: ownProps.propertyId,
        realtor_contact_id: contactId,
        status: 1,
      }));
    });

    // const removeContacts = removedContacts.map(async (contactId) => {
    //   const match = matches.find((item) => item.realtor_contact_id === contactId);
    //   await dispatch(removeMatchingProperty(match?.id));
    // });

    await Promise.all(addContacts);
    // await Promise.all(removeContacts);

    dispatch(getPropertyById(ownProps.propertyId));

    await dispatch(resetMatchingPropertyFilters());
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onSave: async ({ addedContacts, removedContacts }) => {
    dispatchProps.onSave({
      addedContacts,
      removedContacts,
      matches: stateProps.matches,
    });
    ownProps.closeModal();
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AssignContactModal);
