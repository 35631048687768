import React from 'react';
import PropTypes from 'prop-types';

import ActiveFilters from 'sections/Leads/modules/LeadsTableSearchFilter/ActiveFilters';
import Search from 'sections/Leads/modules/LeadsTableSearchFilter/Search';

const options = [
  {
    value: 'all',
    label: 'All Leads',
  },
  {
    value: 'new',
    label: 'New Leads',
  },
  {
    value: 'active',
    label: 'Active leads',
  },
  {
    value: 'archived',
    label: 'Archived',
  },
];

const LeadsTableSearchFilter = (props) => {
  const {
    onFilterClick,
  } = props;

  return (
    <div>
      <Search
        options={options}
        searchInputPlaceholder="Search all leads"
      />

      <ActiveFilters />
    </div>
  );
};

LeadsTableSearchFilter.defaultProps = {

};

const {
  func,
} = PropTypes;

LeadsTableSearchFilter.propTypes = {
  onFilterClick: func.isRequired,
};

export default LeadsTableSearchFilter;
