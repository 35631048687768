import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  openLogOfferModal,
} from 'store/actions/realtorLogOfferModal';

import OffersView from './OffersView';

const labels = {
  client: '+ Log new offer',
  prospect: '+ Make new offer',
};

const mapStateToProps = (state, ownProps) => {
  const { objectType } = ownProps;

  let offers;
  let propertyId;

  if (objectType === 'client') {
    const client = selectors.realtorClients.selected(state);
    offers = client?.offers;
    propertyId = client?.realtor_property_id;
  }

  if (objectType === 'prospect') {
    offers = selectors.realtorProspects.selected(state)?.offers;
  }

  return {
    offers: offers || [],
    propertyId,
    label: labels[objectType] || '+ Log new offer',
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLogOfferClick: ({
    propertyId,
  }) => {
    let clientId; let
prospectId;

    if (ownProps.objectType === 'client') clientId = ownProps.objectId;
    if (ownProps.objectType === 'prospect') prospectId = ownProps.objectId;

    dispatch(openLogOfferModal(
      {
        type: ownProps.objectType,
        clientId,
        prospectId,
        propertyId,
      },
    ));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onLogOfferClick: () => {
    dispatchProps.onLogOfferClick(stateProps);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(OffersView);
