import React from 'react';
import PropTypes from 'prop-types';

import { Root, Initials } from './styledItems';

const getInitials = (firstName = '', lastName = '') => {
  const fname = firstName ? firstName.split('')[0] || '' : '';
  const lname = lastName ? lastName.split('')[0] || '' : '';

  return fname + lname;
};

const sizes = {
  sm: 42,
  md: 100,
};

const Avatar = (props) => {
  const {
    src,
    firstName,
    lastName,
    size,
  } = props;

  const initials = getInitials(firstName, lastName);

  return (
    <Root
      src={src}
      size={sizes[size] || sizes.md}
    >
      {
        !src && (
          <Initials size="40">{initials}</Initials>
        )
      }
    </Root>
  );
};

Avatar.defaultProps = {
  src: null,
  firstName: null,
  lastName: null,
  size: 'md',
};

const {
  string,
  oneOf,
} = PropTypes;

Avatar.propTypes = {
  /**
   * image source
   */
  src: string,
  /**
   * first name
   */
  firstName: string,
  /**
   * last name
   */
  lastName: string,
  size: oneOf(['sm', 'md']),

};

export default Avatar;
