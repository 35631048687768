import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import MUIBreadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

import routes from 'sections/Transactions/routes';

const Breadcrumbs = (props) => {
  const {
    name,
  } = props;

  const history = useHistory();

  const handleClick = useCallback((event) => {
    event.preventDefault();
    history.push(routes.transactionsList);
  });

  return (
    <MUIBreadcrumbs>
      <Link
        color="inherit"
        href={routes.transactionsList}
        onClick={handleClick}
      >
        Transactions
      </Link>
      <Typography color="textPrimary">
        {name}
      </Typography>
    </MUIBreadcrumbs>
  );
};

Breadcrumbs.defaultProps = {
  name: null,
};

const {
  string,
} = PropTypes;

Breadcrumbs.propTypes = {
  name: string,
};

export default Breadcrumbs;
