import React, {
  useCallback,
} from 'react';

import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

import KanbanView from 'shared/components/UI/KanbanView';

import KanbanCard from 'sections/Opportunities/modules/KanbanCard';

const Kanban = (props) => {
  const { lanes } = props;

  const history = useHistory();

  const handleCardClick = useCallback((item) => {
    if (item.type === 'client') {
      history.push(`/clients-sellers/${item.id}`);
    }
    if (item.type === 'prospect') {
      history.push(`/prospects/${item.id}`);
    }
  });

  return (
    <div style={{ height: 700, width: '100%' }}>
      <KanbanView
        lanes={lanes}
        CardComponent={KanbanCard}
        onCardClick={handleCardClick}
      />
    </div>
  );
};

Kanban.defaultProps = {
  lanes: [],
};

const {
  arrayOf,
  bool,
  func,
  number,
  oneOfType,
  shape,
  string,
} = PropTypes;

Kanban.propTypes = {
  lanes: arrayOf(shape({
    id: string,
    name: string,
    list: arrayOf(shape({
      id: oneOfType([number, string]).isRequired,
    })),
    listHasMore: bool,
    loadMore: func,
  })),
};

export default Kanban;
