import React from 'react';

import { datetime } from '@symplete/symplete-utils';
import { between } from '@symplete/symplete-utils/datetime';

import Typography from 'ui/Typography';
import TableHead from 'ui/TableHead';

import Options from './Options';
import Expiry from './Expiry';
import ContactCell from './ContactCell';
import Agreement from './Agreement';
import Price from './Price';
import ClosingDate from './ClosingDate';
import FinanceType from './FinanceType';

const columns = (tableName, expiryIndex) => [
    {
      title: () => (
        <TableHead>
          Purchase Price
        </TableHead>
      ),
      width: '15%',
      dataIndex: 'amount',
      key: 'amount',
      render: (value, entry) => (value ? (
        <Price value={value} bestPrice={entry.bestPrice} />
      ) : null),
    },
    {
      title: () => (
        <TableHead>
          Closing
        </TableHead>
      ),
      width: '10%',
      dataIndex: 'closing_date',
      key: 'closing',
      render: (value, entry) => (value ? (
        <ClosingDate date={value} bestClose={entry.bestClose} />
      ) : '-'),
    },
    {
      title: () => (
        <TableHead>
          Financing
        </TableHead>
      ),
      width: '10%',
      dataIndex: 'financing_type',
      key: 'financing',
      render: (value, entry) => (value ? (
        <FinanceType value={value} />
      ) : '-'),
    },
    {
      title: () => (
        <TableHead>
          Contingencies
        </TableHead>
      ),
      width: '20%',
      key: 'contigencies',
      render: (value, entry) => (entry ? (
        <Typography
          variant="cell"
        >
          {`${entry.contingency_inspection ? 'Inspection,' : ''}
            ${entry.contingency_loan ? 'Loan,' : ''}
            ${entry.contingency_appraisal ? 'Appraisal' : ''}
            ${between(datetime(), entry.contingency_period_end)} Days
          `}
        </Typography>
      ) : '-'),
    },
    {
      title: () => (
        <TableHead>
          Other Party
        </TableHead>
      ),
      width: '20%',
      key: 'other_party',
      render: (value, entry) => (entry ? (
        <ContactCell
          entry={entry}
        />
      ) : '-'),
    },
    {
      title: () => (
        <TableHead>
          {tableName === 'declined' || tableName === 'accepted' ? tableName : 'Expiry'}
        </TableHead>
      ),
      width: '10%',
      dataIndex: expiryIndex,
      key: 'expiry',
      render: (value, entry) => (
        tableName === 'declined' || tableName === 'accepted' ? (
          <Typography
            variant="cell"
          >
            {datetime(value).format('MMMM D, YYYY')}
          </Typography>

      ) : (<Expiry date={value} />)),
    },
    {
      title: () => (
        <TableHead>
          Agreement
        </TableHead>
      ),
      width: '10%',
      key: 'agreement',
      render: (value, entry) => (
        <Agreement offerId={entry.id} />
      ),
    },
    {
      title: null,
      dataIndex: 'id',
      key: 'options',
      width: '5%',
      render: (value, entry) => (
        <Options
          offerId={value}
          rootOfferId={entry.parent_offer_id}
          propertyId={entry.realtor_property_id}
          offerType={entry.type}
          totalCounters={entry.items?.length}
          offerData={entry}
        />
      ),
    },
  ];

export default columns;
