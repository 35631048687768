import React from 'react';
import { oneOf, func } from 'prop-types';

import RadioGroup from 'ui/RadioGroup';

const items = [
  {
    value: 'sale',
    displayedValue: 'Sale',
  },
  {
    value: 'lease',
    displayedValue: 'Lease',
  },
];

const TransferState = ({ value, onChange }) => (
  <RadioGroup
    items={items}
    defaultValue={value}
    onChange={onChange}
    flexDirection="row"
    gap="20px"
  />
);

TransferState.propTypes = {
  value: oneOf(['sale', 'lease']).isRequired,
  onChange: func.isRequired,
};

export default TransferState;
