import React, { PureComponent } from 'react';

import {
  string,
  oneOf,
  shape,
  func,
} from 'prop-types';

import { NakedButton } from '@symplete/symplete-ui';
import Icon from 'ui/Icon';

import { Tooltip } from 'antd';

const socialEnum = [
  'facebook',
  'linkedin',
  'twitter',
  'instagram',
  'globe',
];

const types = {
  facebook: 'facebook',
  linkedin: 'linkedin',
  twitter: 'twitter',
  instagram: 'instagram',
  website: 'globe',
};

class SocialIcon extends PureComponent {
  renderIcon = (type, onClick) => {
    if (!onClick) {
 return (
   <Icon
     name={types[type]}
     size="m"
     color="focus"
   />
    );
}

    return (
      <NakedButton onClick={onClick}>
        <Icon
          name={types[type]}
          size="m"
          color="focus"
        />
      </NakedButton>
    );
  }

  render() {
    const {
      type,
      tooltipProps: {
        title,
        placement,
      },
      onClick,
    } = this.props;

    return (
      title
        ? (
          <Tooltip title={title} placement={placement || 'top'}>
            {this.renderIcon(type, onClick)}
          </Tooltip>
        )
        : this.renderIcon(type, onClick)
    );
  }
}

SocialIcon.defaultProps = {
  tooltipProps: {
    title: '',
    placement: '',
  },
};

SocialIcon.propTypes = {
  type: oneOf(socialEnum).isRequired,
  tooltipProps: shape({
    title: string.isRequired,
    placement: string,
  }),
  onClick: func.isRequired,
};

export default SocialIcon;
