import React, { useCallback, useState } from 'react';

import Button from 'ui/Button';
import Flex from 'ui/Flex';
import Typography from 'ui/Typography';
import Icon from 'ui/Icon';
import Spin from 'ui/Spin';

import useModal from 'ui/hooks/useModal';
import useCalendarImportInfo from 'sections/Integrations/hooks/useCalendarImportInfo';

import requests from 'api/requests';

const useCalendarImport = ({ tokenId, name, icon }) => {
  const [importStarted, setImportStarted] = useState(false);
  const [importInProgress, setImportInProgress] = useState(false);

  const { isModalVisible, openModal, closeModal, Modal } = useModal();

  const {
    allCalendarsCount,
    isLoading
    // error, <-- ToDo: show Validation/Error modal on error
  } = useCalendarImportInfo({
    tokenId: isModalVisible ? tokenId : null
  });

  const start = useCallback(async () => {
    try {
      setImportStarted(true);
      setImportInProgress(true);
      console.log(
        'going to call requests.integrations.calendar endpoint with tokenId: ',
        { tokenId }
      );
      await requests.integrations.calendar.importCalendar({ tokenId });
    } catch (error) {
      console.info(error);
    } finally {
      setImportInProgress(false);
    }
  }, [tokenId]);

  const modal = (
    <Modal
      title="Importing your events into Symplete"
      visible={isModalVisible}
      onCancel={closeModal}
      footer={
        (!importStarted && allCalendarsCount != 0) ? (
          <Button variant="primary" onClick={start} disabled={isLoading}>
            Start
          </Button>
        ) : (
          <Button
            variant="primary"
            onClick={closeModal}
            disabled={importInProgress}
          >
            Finish
          </Button>
        )
      }
    >
      <Spin spinning={isLoading}>
        {!importStarted && (
          <Flex spacing={1} flexDirection="column" alignItems="center">
            <Icon name={icon} type="custom" size={100} />
            <Typography variant="title3" weight="bold" noMargin textCenter>
              {`${allCalendarsCount} events found in ${name}.`}
            </Typography>
            {
              allCalendarsCount > 0 && (
                <Typography noMargin textCenter>
                  Import all events?
                </Typography>
              )
            }
          </Flex>
        )}
      </Spin>
    </Modal>
  );

  return {
    openCalendarImportModal: openModal,
    modal
  };
};

export default useCalendarImport;
