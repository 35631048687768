import React, {
  Fragment,
  useCallback,
  useState
} from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import Spin from 'ui/Spin';
import { Alert } from 'ui/Alert';
import Checkbox from 'ui/Checkbox'
import Textfield from 'ui/Textfield';

import MarketingTemplateItem from 'sections/Communications/modals/NewMarketingCampaignModal/MarketingTemplateItem';

import useTemplates from 'sections/Communications/hooks/useTemplates';

import {
  Wrapper,
} from './styledItems';
import FlexItem from 'ui/FlexItem/FlexItem';

const GroupEmails = (props) => {
  const {
    onChooseTemplate,
    onCreateTemplate,
    selectedTemplateId,
    newTemplateName,
    newSaveTemplate,
  } = props;

  const {
    templates,
    isLoading: isTemplatesListLoading,
    listError,
  } = useTemplates();

  const [saveTemplate, setSaveTemplate] = useState(newSaveTemplate);
  const [templateName, setTemplateName] = useState(newTemplateName);

  const handleChangeSaveTemplate = useCallback((newValue) => {
    setSaveTemplate(newValue);
    onCreateTemplate(templateName, newValue);
  }, [setTemplateName, templateName]);

  const handleChangeTemplateName = useCallback((newValue) => {
    setTemplateName(newValue);
    onCreateTemplate(newValue, saveTemplate);
  }, [saveTemplate, setSaveTemplate]);

  return (
    <Wrapper>
      <div>
        <Typography
          variant="title2"
          noMargin
          weight={500}
          style={{ marginBottom: 10 }}
        >
          Select email template
        </Typography>
        <Flex
          fullWidth
          flexWrap="wrap"
          spacing={2}
        >
          {
            listError && (
              <Typography color="error">
                Cannot get templates :(
              </Typography>
            )
          }
          {
            isTemplatesListLoading && (
              <Flex justifyContent="center">
                <Spin />
              </Flex>
            )
          }
          {
            !isTemplatesListLoading && !listError && templates.map((template) => (
              <>
                {
                  (template?.is_saved === 1)  && 
                    <Fragment key={template.id}>
                      <MarketingTemplateItem
                        id={template.id}
                        onClick={() => onChooseTemplate(template)}
                        icon={template.icon || 'defaultEmail'}
                        title={template.name}
                        selected={template.id === selectedTemplateId}
                        iconType='custom'
                      />
                    </Fragment>
                }
              </>
            ))
          }
        </Flex>
      </div>
      <div>
        <Typography
          variant="title2"
          noMargin
          weight={500}
          style={{ marginBottom: 10, marginTop: 20 }}
        >
          Create from scratch
        </Typography>

        <Flex
          fullWidth
          justifyContent="space-between"
          flexWrap="nowrap"
          spacing={2}
        >
          <FlexItem
            fullWidth
            flex={3}
          >
            <Textfield
              fullWidth
              label="Name your email template"
              value={templateName}
              onChange={handleChangeTemplateName}
            />
          </FlexItem>
          <FlexItem
            fullWidth
            flex={1}
            padding="10px 0 0 0"
          >
            <Checkbox
              checked={saveTemplate}
              onChange={handleChangeSaveTemplate}
              label="Save as template"
              typographyProps={{weight: 'regular' }}
            />
          </FlexItem>
        </Flex>
      </div>
    </Wrapper>
  );
};

const {
  func,
  arrayOf,
  shape,
  string,
  bool,
} = PropTypes;

GroupEmails.propTypes = {
  templates: arrayOf(shape()).isRequired,
  onChooseTemplate: func.isRequired,
  onCreateTemplate: func.isRequired,
  selectedTemplateId: string,
  newTemplateName: string,
  newSaveTemplate: bool,
};

export default GroupEmails;
