import { connect } from 'react-redux';

import {
  updateInspection,
} from 'store/actions/realtorInspections';

import ContactCell from 'shared/components/UI/TableCells/RealtorCells/ContactCell';

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeContact: (inspector_contact_id) => {
    dispatch(updateInspection(ownProps.inspectionId)({ inspector_contact_id }));
  },
});

export default connect(null, mapDispatchToProps)(ContactCell);
