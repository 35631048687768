import { defaultTheme as theme } from '@symplete/symplete-ui';

export const FINANCING_TYPES = {
  1: 'Conventional',
  2: 'Cash',
  3: 'FHA Loan',
  4: 'Va Loan',
  5: 'USDA Loan',
  6: 'Jumbo Loan',
};

export const FINANCING_TYPES_ARRAY = [
  {
    value: 1,
    label: 'Conventional',

  },
  {
    value: 2,
    label: 'Cash',

  },
  {
    value: 3,
    label: 'FHA Loan',

  },
  {
    value: 4,
    label: 'Va Loan',

  },
  {
    value: 5,
    label: 'USDA Loan',

  },
  {
    value: 6,
    label: 'Jumbo Loan',

  },
];

export const PHONE_TYPES = {
  1: 'Mobile',
  2: 'Work',
  3: 'Home',
};

export const PROPERTY_TYPES = {
  1: 'single family',
  2: 'manufactured',
  3: 'condos/co-ops',
  4: 'multi-family',
  5: 'apartments',
  6: 'lots/land',
  7: 'townhomes',
  8: 'new construction',
  9: 'commercial',
  10: 'combo property',
};

export const PROPERTY_TYPES_ARRAY = [
  {
    value: 1,
    label: 'Single Family',
  },
  {
    value: 2,
    label: 'Manufactured',
  },
  {
    value: 3,
    label: 'Condos/Co-Ops',
  },
  {
    value: 4,
    label: 'Multi-Family',
  },
  {
    value: 5,
    label: 'Apartments',
  },
  {
    value: 6,
    label: 'Lots/Land',
  },
  {
    value: 7,
    label: 'Townhomes',
  },
  {
    value: 8,
    label: 'New Construction',
  },
  {
    value: 9,
    label: 'Commercial',
  },
  {
    value: 10,
    label: 'Combo Property',
  },
];

export const PROPERTY_STATUSES = {
  1: 'available',
  2: 'under offer',
  3: 'in transaction',
  4: 'sold',
  5: 'off market',
};

export const PROPERTY_STATUSES_ARRAY = [
  {
    label: 'Available',
    value: 1,
  },
  {
    label: 'Under Offer',
    value: 2,
  },
  {
    label: 'In Transaction',
    value: 3,
  },
  {
    label: 'Sold',
    value: 4,
  },
  {
    label: 'Off Market',
    value: 5,
  },
];

export const PROPERTY_DEAL_STATUSES = {
  1: 'successful close',
  2: 'unsuccessful',
};

export const SALE_TYPES = {
  1: 'REO',
  2: 'by agent',
  3: 'auction',
  4: 'foreclosure',
  5: 'coming soon',
  6: 'off-market listing',
};

export const SALE_TYPES_ARRAY = [
  {
    value: 1,
    label: 'REO',
  },
  {
    value: 2,
    label: 'By agent',
  },
  {
    value: 3,
    label: 'Auction',
  },
  {
    value: 4,
    label: 'Foreclosure',
  },
  {
    value: 5,
    label: 'Coming soon',
  },
  {
    value: 6,
    label: 'Off-market listing',
  },
];

export const VIEWS_FROM_HOME = {
  1: 'ocean',
  2: 'lake',
  3: 'mountains',
  4: 'farm',
  5: 'city',
};

export const VIEWS_FROM_HOME_ARRAY = [
  {
    value: 1,
    label: 'Ocean',
  },
  {
    value: 2,
    label: 'Lake',
  },
  {
    value: 3,
    label: 'Mountains',
  },
  {
    value: 4,
    label: 'Farm',
  },
  {
    value: 5,
    label: 'City',
  },
];

export const NUMBER_OF_STORIES = {
  1: 'Single Story',
  2: '1 Level',
  3: '2 Level',
};

export const NUMBER_OF_STORIES_ARRAY = [
  {
    value: 1,
    label: 'Single Story',
  },
  {
    value: 2,
    label: '1 Level',
  },
  {
    value: 3,
    label: '2 Level',
  },
];

export const EMAIL_TYPES = {
  1: 'Personal',
  2: 'Work',
};

export const SOURCE_TYPES = {
  1: 'Referral',
};

export const REQUIREMENT_OPTIONS = {
  1: 'required',
  2: 'nice to have',
  3: 'don\'t want it',
};

export const LEAD_TYPES = {
  1: 'buyer',
  2: 'seller',
};

export const LEAD_STATUSES = {
  1: 'active',
  2: 'new today',
  3: 'new this week',
  4: 'completed',
};

export const ROLE_LABELS = {
  1: 'buyer',
  2: 'seller',
  3: 'buyer/seller',
};

export const ROLE_COLORS = {
  1: theme.palette.buyer,
  2: theme.palette.seller,
  3: theme.palette['buyer/seller'],
};

export const CONTACT_TYPES_ARRAY = [
  {
    value: 1,
    label: 'real estate agent',
    category: 'agents',
  },
  {
    value: 2,
    label: 'realtor',
    category: 'agents',
  },
  {
    value: 3,
    label: 'broker',
    category: 'agents',
  },
  {
    value: 4,
    label: 'escrow',
    category: 'services',
  },
  {
    value: 5,
    label: 'inspections',
    category: 'services',
  },
  {
    value: 6,
    label: 'title agent',
    category: 'services',
  },
  {
    value: 7,
    label: 'mortgage broker',
    category: 'services',
  },
  {
    value: 8,
    label: 'bank',
    category: 'services',
  },
  {
    value: 9,
    label: 'appraiser',
    category: 'services',
  },
  {
    value: 10,
    label: 'lawyer',
    category: 'services',
  },
  {
    value: 11,
    label: 'buyer lead',
    category: 'clients',
  },
  {
    value: 12,
    label: 'seller lead',
    category: 'clients',
  },
  {
    value: 13,
    label: 'buyer & seller lead',
    category: 'clients',
  },
  {
    value: 14,
    label: 'VIP',
    category: 'clients',
  },
];

export const CONTACT_TYPE_LABELS = {
  1: 'real estate agent',
  2: 'realtor',
  3: 'broker',
  4: 'escrow',
  5: 'inspections',
  6: 'title agent',
  7: 'mortgage broker',
  8: 'bank',
  9: 'appraiser',
  10: 'lawyer',
  11: 'buyer lead',
  12: 'seller lead',
  13: 'buyer & seller lead',
  14: 'VIP',
};

export const CONTACT_TYPE_COLORS = {
  1: {
    tag: 'agentContact',
    text: 'main-text',
  },
  2: {
    tag: 'agentContact',
    text: 'main-text',
  },
  3: {
    tag: 'agentContact',
    text: 'main-text',
  },
  4: {
    tag: 'serviceContact',
    text: 'main-text-inversed',
  },
  5: {
    tag: 'serviceContact',
    text: 'main-text-inversed',
  },
  6: {
    tag: 'serviceContact',
    text: 'main-text-inversed',
  },
  7: {
    tag: 'serviceContact',
    text: 'main-text-inversed',
  },
  8: {
    tag: 'serviceContact',
    text: 'main-text-inversed',
  },
  9: {
    tag: 'serviceContact',
    text: 'main-text-inversed',
  },
  10: {
    tag: 'serviceContact',
    text: 'main-text-inversed',
  },
  11: {
    tag: 'clientContact',
    text: 'main-text-inversed',
  },
  12: {
    tag: 'clientContact',
    text: 'main-text-inversed',
  },
  13: {
    tag: 'clientContact',
    text: 'main-text-inversed',
  },
};

export const REALTOR_ROLES = {
  1: 'buyer',
  2: 'seller',
  3: 'buyer agent',
  4: 'seller agent',
  5: 'title agent',
  6: 'escrow agent',
  7: 'lawyer',
};

export const REALTOR_ROLES_ARRAY = [
  {
    value: 1,
    label: 'Buyer',
  },
  {
    value: 2,
    label: 'Seller',
  },
  {
    value: 3,
    label: 'Buyer Agent',
  },
  {
    value: 4,
    label: 'Seller Agent',
  },
  {
    value: 5,
    label: 'Title Agent',
  },
  {
    value: 6,
    label: 'Escrow Agent',
  },
  {
    value: 7,
    label: 'Lawyer',
  },
];

export const REALTOR_OFFER_TYPES = {
  1: 'buyer',
  2: 'seller',
};

export const PROPOSED_FIX_TYPES_ARRAY = [
  {
    id: 1,
    name: 'seller to fix',
  },
  {
    id: 2,
    name: 'credit',
  },
  {
    id: 3,
    name: 'declined',
  },
];

export const CONTACT_RELATION_STATUSES = {
  1: 'agent',
  2: 'assistant',
  3: 'boss',
  4: 'broker',
  5: 'business associate',
  6: 'business partner',
  7: 'colleague',
  8: 'employee',
  9: 'manager',
  10: 'spouse',
};

export const CONTACT_RELATION_STATUSES_ARRAY = [
  {
    value: 1,
    label: 'Agent',
  },
  {
    value: 2,
    label: 'Assistant',
  },
  {
    value: 3,
    label: 'Boss',
  },
  {
    value: 4,
    label: 'Broker',
  },
  {
    value: 5,
    label: 'Business Associate',
  },
  {
    value: 6,
    label: 'Business Partner',
  },
  {
    value: 7,
    label: 'Colleague',
  },
  {
    value: 8,
    label: 'Employee',
  },
  {
    value: 9,
    label: 'Manager',
  },
  {
    value: 10,
    label: 'Spouse',
  },
];
