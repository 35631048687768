import styled from 'styled-components';

export const BgWrapper = styled.div`
  background: white;
  height: inherit;
`;

export const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* max-width: 1598px; */
  height: inherit;
  margin: 0 auto;
  background: white;
  background-color: ${(props) => props?.theme?.palette?.['background-app'] || 'black'};
`;

export const AppBody = styled.section`
  flex: 1;
  display: flex;
  /* max-height: 100vh; */
  background-color: ${(props) => props?.theme?.palette?.navColorPrimary || 'black'};
  overflow-y: hidden;
  `;

export const AppMain = styled.main`
  overflow-y: auto;
  display: flex;
  max-height: 100%;
  width: 100%;
  background-color: ${(props) => props?.theme?.palette?.background || 'white'};
  border-top-left-radius: 29px;
  margin-top: 16px;
  padding: 20px 10px;
`;

export const WidthLimit = styled.div`
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
`;

export default {
  AppWrapper,
  AppBody,
  AppMain,
  BgWrapper,
};
