import React from 'react';
import PropTypes from 'prop-types';

import { isUndefined } from 'shared/utility';

import Typography from 'ui/Typography';

import {
  Root,
  Item,
} from './styledItems';

const AdditionalParameters = (props) => {
  const {
    data,
  } = props;

  return (
    <Root>
      {data.map((item, index) => (
        <>
          <Item key={item.title}>
            {!isUndefined(item.count) && (
              <Typography
                variant="body2"
                weight="bold"
                noMargin
              >
                {item.count}
&nbsp;
              </Typography>
            )}
            <Typography
              variant="body2"
              noMargin
            >
              {item.title}
            </Typography>
          </Item>
        </>
      ))}
    </Root>
  );
};

AdditionalParameters.defaultProps = {
  data: [],
};

const {
  arrayOf,
  number,
  shape,
  string,
} = PropTypes;

AdditionalParameters.propTypes = {
  data: arrayOf(shape({
    count: number,
    title: string.isRequired,
  })),
};

export default AdditionalParameters;
