import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import Elevation from 'ui/Elevation';
import Tabs from 'ui/Tabs';
import Tab from 'ui/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabPanel from '@material-ui/lab/TabPanel';

import PaperBlock from 'shared/components/UI/PaperBlock';
import TabLabel from 'shared/components/UI/TabLabel';

import SellerAlert from 'sections/Clients/components/SellerAlert';

// import Breadcrumbs from 'sections/Clients/modules/Breadcrumbs';
import PinnedNote from 'sections/Clients/modules/PinnedNote';
import Options from 'sections/Clients/modules/Options';
import Sidebar from 'sections/Clients/modules/Sidebar';
// import ClientSummarySelector from 'sections/Clients/modules/ClientSummary';
import PropertyDetailsView from 'sections/Clients/modules/PropertyDetailsView';
import ViewingsView from 'sections/Clients/modules/ViewingsView';
import OffersView from 'sections/Opportunities/modules/OffersView';
import ListingFocusView from 'sections/Clients/modules/ListingFocusView';

import AssignInterestedPartyModal from 'sections/Clients/modals/AssignInterestedPartyModal';

import FollowUp from 'sections/Clients/modules/FollowUp';
import FollowUpRemindersModal from 'shared/modules/FollowUpRemindersModal';

import ClientItemContext from './context';

import {
  Root,
} from './styledItems';

const {
  number,
  string,
  oneOfType,
  element,
  bool,
  shape,
  // oneOf,
} = PropTypes;

TabLabel.propTypes = {
  children: element.isRequired,
  isSelected: bool.isRequired,
};

const ClientItem = (props) => {
  const {
    selectedClient, // eslint-disable-line no-unused-vars
    // selectedView,
    summary,
  } = props;

  const [tab, setTab] = useState('listing focus');
  const handleTabChange = useCallback((event, value) => {
    setTab(value);
  }, []);

  return (
    <ClientItemContext.Provider value={{ tab, setTab }}>
      <Root>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            container
            spacing={2}
          >
            <Grid
              item
              xs={6}
              container
              alignItems="center"
            >
              {/* <Breadcrumbs /> */}
            </Grid>

            <Grid
              item
              xs={6}
              container
              spacing={2}
              justify="flex-end"
              alignItems="center"
            >
              <Grid item>
                <FollowUp clientId={selectedClient} />
              </Grid>
              <Grid item>
                <PinnedNote />
              </Grid>
              <Grid item>
                <Options />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <SellerAlert />
          </Grid>

          <Grid
            item
            xs={12}
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <PaperBlock>
                <Sidebar />
              </PaperBlock>
            </Grid>

            <Grid
              item
              xs={12}
            >
              <Elevation
                rounded="m"
                padding="0 0 14px 0"
                hideOverflow
                style={{
                  width: '-webkit-fill-available',
                }}
              >
                <TabContext value={tab}>
                  <Tabs
                    variant="secondary"
                    value={tab}
                    onChange={handleTabChange}
                  >
                    <Tab
                      variant="secondary"
                      label={(
                        <TabLabel
                          isSelected={tab === 'listing focus'}
                          variant="title2"
                          highlightSelected={false}
                        >
                          Listing Focus
                        </TabLabel>
                      )}
                      value="listing focus"
                    />
                    <Tab
                      variant="secondary"
                      label={(
                        <TabLabel
                          isSelected={tab === 'viewings'}
                          variant="title2"
                          highlightSelected={false}
                        >
                          {summary.viewings}
                          {' '}
                          {summary.viewings === 1 ? 'Showing' : 'Showings'}
                        </TabLabel>
                      )}
                      value="viewings"
                    />
                    <Tab
                      variant="secondary"
                      label={(
                        <TabLabel
                          isSelected={tab === 'offers'}
                          variant="title2"
                          highlightSelected={false}
                        >
                          {summary.offers}
                          {' '}
                          {summary.offers === 1 ? 'Offer' : 'Offers'}
                          {' '}
                          /
                          {' '}
                          {summary.counters}
                          {' '}
                          {summary.offers === 1 ? 'Counter' : 'Counters'}
                        </TabLabel>
                      )}
                      value="offers"
                    />
                    {/* <Tab
                      label={<TabLabel isSelected={tab === 'property details'}>Property Details</TabLabel>}
                      value="property details"
                    /> */}
                  </Tabs>

                  <TabPanel value="listing focus" index={0}>
                    <ListingFocusView />
                  </TabPanel>
                  <TabPanel value="property details" index={1}>
                    <PropertyDetailsView />
                  </TabPanel>
                  <TabPanel value="viewings" index={2}>
                    <ViewingsView />
                  </TabPanel>
                  <TabPanel value="offers" index={3}>
                    <OffersView
                      objectType="client"
                      objectId={selectedClient}
                    />
                  </TabPanel>
                </TabContext>
              </Elevation>
            </Grid>
          </Grid>

          {/* <Grid
            item
            xs={12}
            container
            spacing={3}
          >
            <Grid
              item
              xs={4}
            >
              <Block>
                <Sidebar />
              </Block>
            </Grid>

            <Grid
              item
              container
              xs={8}
              spacing={3}
              alignContent="flex-start"
            >
              <Grid
                item
                xs={12}
              >
                <ClientSummarySelector />
              </Grid>

              <Grid
                item
                xs={12}
              >
                {
                  ['offers', 'counters'].includes(selectedView) && <OffersView objectType="client" />
                }
                {
                  selectedView === 'property details' && <PropertyDetailsView />
                }
                {
                  selectedView === 'viewings' && <ViewingsView />
                }
              </Grid>

            </Grid>
          </Grid> */}

        </Grid>

        <AssignInterestedPartyModal />
        <FollowUpRemindersModal
          objectId={selectedClient}
          objectTypeId={29}
        />

      </Root>
    </ClientItemContext.Provider>
  );
};

ClientItem.defaultProps = {
  selectedClient: null,
  summary: {
    viewings: 0,
    offers: 0,
    counters: 0,
  },
  // selectedView: 'property details',
};

ClientItem.propTypes = {
  selectedClient: oneOfType([number, string]),
  summary: shape({
    viewings: number,
    offers: number,
    counters: number,
  }),
  // selectedView: oneOf(['offers', 'counters', 'viewings', 'interested buyers', 'property details', null]),
};

export default ClientItem;
