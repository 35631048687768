import store from 'store';

import {
  createMatchingProperty,
  resetMatchingPropertyFilters,
} from 'store/actions/realtorMatchingProperties';

const createMatch = async (matchingPropertyData, clientId) => {
  await store.dispatch(createMatchingProperty(matchingPropertyData));
  store.dispatch(resetMatchingPropertyFilters());
};

export default createMatch;
