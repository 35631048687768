import React from 'react';
import PropTypes from 'prop-types';

import { pretty } from '@symplete/symplete-utils/numbers';

import ErrorBoundary from 'ui/ErrorBoundary';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import realtorLogo from 'assets/icons/realtor-logo.svg';

const ExternalPropertyOverview = ({ propertyData }) => {
  const {
    price, bedrooms, bathrooms, floorArea, address, propertyDescription,
  } = propertyData;
  console.log(propertyData);
  return (
    <ErrorBoundary>
      <Flex
        container
        spacing={2}
        justifyContent="space-between"
        style={{ padding: '16px 26px' }}
      >
        <FlexItem xs={16} flexDirection="column" spacing={1}>
          <h2
            style={{
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 700,
              color: 'white',
              fontSize: '30px',
              fontStyle: 'italic',
            }}
          >
            {address}
          </h2>
          <p style={{ fontFamily: 'Poppins, sans-serif', fontSize: '24px', fontWeight: 400, color: 'white' }}>
            {propertyDescription}
          </p>
        </FlexItem>
        <Flex xs={8} flexDirection="column" spacing={1} alignItems="flex-end">
          {price && (
            <span
              style={{
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 700,
                color: 'white',
                fontSize: '30px',
                fontStyle: 'italic',
              }}
            >
              $
              {' '}
              {price}
            </span>
          )}
          <span
            style={{
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 400,
              color: 'white',
              fontSize: '24px',
              fontStyle: 'italic',
            }}
          >
            {bedrooms || 0}
            {' '}
            BEDS
          </span>
          <span
            style={{
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 400,
              color: 'white',
              fontSize: '24px',
              fontStyle: 'italic',
            }}
          >
            {bathrooms || 0}
            {' '}
            BATHS
          </span>
          <span
            style={{
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 400,
              color: 'white',
              fontSize: '24px',
              fontStyle: 'italic',
            }}
          >
            {floorArea || 0}
            {' '}
            SQFT
          </span>
        </Flex>
      </Flex>
      <Flex flexDirection="column" style={{ padding: '0 26px' }}>
        <span
          style={{
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 500,
            color: 'white',
            fontSize: '10px',
          }}
        >
          Powerd By
        </span>
        <img src={realtorLogo} alt="Logo" style={{ width: '110px' }} />
      </Flex>
    </ErrorBoundary>
  );
};

const { shape } = PropTypes;

ExternalPropertyOverview.propTypes = {
  propertyData: shape({}).isRequired,
};

export default ExternalPropertyOverview;
