import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import Typography from 'ui/Typography';
import Icon from 'ui/Icon';

import {
  Root,
  IconWrapper,
} from './styledItems';

const colorMap = {
  primary: {
    active: 'main-text-inversed',
    disabled: 'main-text-inversed',
  },
  secondary: {
    active: 'main-text',
    disabled: 'main-text-inversed',
  },
  tertiary: {
    active: 'focus',
    disabled: 'main-text',
  },
  cancel: {
    active: 'main-text-inversed',
    disabled: 'main-text',
  },
  flat: {
    active: 'main-text',
    disabled: 'main-text',
  },
  positive: {
    active: 'positive',
    disabled: 'main-text-inversed',
  },
  neutral: {
    active: 'neutral',
    disabled: 'main-text-inversed',
  },
  negative: {
    active: 'negative',
    disabled: 'main-text-inversed',
  },
};

const Button = (props) => {
  const {
    children,
    disabled,
    onClick,
    variant,
    icon,
    color: propsColor,
    fullWidth,
    iconType,
    iconColor,
    iconSize,
    iconOffset,
    buttonRef,
    className,
    noWrap,
    dense,
    typographyProps,
    wrapper,
    centered,
    iconPosition,
  } = props;

  const isDisabled = disabled;

  const color = propsColor || iconColor || colorMap[variant][isDisabled ? 'disabled' : 'active'];

  const thisIconColor = disabled
    ? color
    : iconColor || color;

  return (
    <Root
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      className={classnames({
        [className]: className,
      })}
      hasText={!!children}
      onClick={onClick}
      variant={variant}
      fullWidth={fullWidth}
      disabled={isDisabled}
      icon={icon}
      ref={buttonRef}
      dense={dense}
      wrapper={wrapper}
      centered={centered}
    >
      {
        icon && (
          <IconWrapper
            hasText={!!children}
            iconOffset={iconOffset}
            iconPosition={iconPosition}
          >
            <Icon
              name={icon}
              size={iconSize}
              color={thisIconColor}
              type={iconType}
            />
          </IconWrapper>
        )
      }
      {
        children && (
          <Typography
            variant="button"
            color={color}
            noWrap={noWrap}
            {...typographyProps} // eslint-disable-line react/jsx-props-no-spreading
          >
            {children}
          </Typography>
        )
      }
    </Root>
  );
};

Button.defaultProps = {
  children: null,
  disabled: false,
  onClick: null,
  icon: null,
  iconType: 'feather',
  iconSize: 'm',
  variant: 'primary',
  fullWidth: false,
  iconColor: null,
  iconOffset: null,
  buttonRef: null,
  className: null,
  color: null,
  noWrap: false,
  typographyProps: {},
  dense: false,
  wrapper: false,
  centered: false,
  iconPosition: 'left',
};

const {
  bool,
  func,
  oneOf,
  string,
  instanceOf,
  shape,
  oneOfType,
  number,
} = PropTypes;

Button.propTypes = {
  children: string,
  variant: oneOf([
    'primary',
    'secondary',
    'tertiary',
  ]),
  disabled: bool,
  onClick: func,
  icon: string,
  iconType: string,
  className: string,
  iconSize: string,
  fullWidth: bool,
  iconColor: string,
  iconOffset: number,
  buttonRef: oneOfType([
    func,
    shape({ current: instanceOf(Element) }),
  ]),
  color: string,
  noWrap: bool,
  typographyProps: shape(),
  dense: bool,
  wrapper: bool,
  centered: bool,
  iconPosition: oneOf(['left', 'right']),
};

export default Button;
