import {
  useState,
  useCallback,
} from 'react';

import requests from 'api/requests';

const useReserved = () => {
  const [reserved, setReserved] = useState(new Set());

  const reserve = useCallback(async (propertyId) => {
    const reservedClone = new Set(reserved);

    reservedClone.add(propertyId);

    await requests.globalProperties.reserve(propertyId);

    setReserved(reservedClone);
  }, [
    reserved,
  ]);

  const unreserve = useCallback(async (propertyId) => {
    const reservedClone = new Set(reserved);

    reservedClone.delete(propertyId);

    await requests.globalProperties.unreserve(propertyId);

    setReserved(reservedClone);
  }, [
    reserved,
  ]);

  return {
    reserved,
    reserve,
    unreserve,
  };
};

export default useReserved;
