import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import Typography from 'ui/Typography';

import StageItemLayout from 'sections/Transactions/components/StageItemLayout';

import TransactionStageNotes from 'sections/Transactions/modules/TransactionStageNotes';

import MoneyInput from 'shared/inputs/MoneyInput';

import AttachmentsModule from 'shared/AttachmentsV3';

const Loan = (props) => {
  const {
    transactionId,
    loanAmount,
    onLoanAmountChange,

    attachments,
    refreshAttachments,
  } = props;

  const documentsComponent = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h3" noMargin>
          Documents
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <AttachmentsModule
          selectedAttachments={attachments}
          objectType={41}
          objectId={transactionId}
          attachmentType={16}
          onAfterUpload={refreshAttachments}
          onAfterDelete={refreshAttachments}
        />
      </Grid>
    </Grid>
  );

  const detailsComponent = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h3">
          Loan amount
        </Typography>

        <MoneyInput
          value={loanAmount}
          onChange={onLoanAmountChange}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">
          Notes
        </Typography>
        <TransactionStageNotes
          transactionId={transactionId}
          stage="loan"
        />
      </Grid>
    </Grid>
  );

  return (
    <StageItemLayout
      documentsComponent={documentsComponent}
      detailsComponent={detailsComponent}
    />
  );
};

Loan.defaultProps = {

};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

Loan.propTypes = {

};

export default Loan;
