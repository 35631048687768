import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { NakedButton } from '@symplete/symplete-ui';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';

const ColumnSelector = (props) => {
  const {
    items,
    selectedValue,
    onSelect,
  } = props;

  const itemsWithBorderElements = items.reduce((acc, item, index) => {
    const isLast = items.length - 1 === index;

    if (isLast) {
      acc.push(item);
    } else {
      acc.push(item, {
        border: true,
      });
    }

    return acc;
  }, []);

  const selectHandler = useCallback((value, filter) => () => {
    onSelect(value, filter);
  }, [onSelect]);

  return (
    <Flex
      spacing={1}
      style={{
        marginTop: '20px',
      }}
      justifyContent="center"
    >
      {
        itemsWithBorderElements.map(({
          label,
          value,
          filter,
          border,
        }) => {
          if (border) {
            return (
              <Typography
                variant="body"
                color="disabled"
                noMargin
              >
                |
              </Typography>
            );
          }
          return (
            <NakedButton
              onClick={selectHandler(value, filter)}
            >
              <Typography
                variant="body"
                color={value === selectedValue ? 'main-text' : 'disabled'}
                noMargin
              >
                {label}
              </Typography>
            </NakedButton>
          );
        })
      }
    </Flex>
  );
};

const {
  arrayOf,
  shape,
  string,
  func,
} = PropTypes;

ColumnSelector.propTypes = {
  items: arrayOf(shape()).isRequired,
  selectedValue: string.isRequired,
  onSelect: func.isRequired,
};

export default ColumnSelector;
