/* eslint-disable  react/jsx-no-bind, react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import { Controller } from 'react-hook-form';

import FormTextfield from 'shared/inputs/FormTextfield';

const RejectionNotesInput = (props) => {
  const {
    control,
  } = props;

  return (
    <Controller
      name="reject_reason_note"
      control={control}
      defaultValue=""
      render={({ field }) => (
        <FormTextfield
          label="Leave a note about why this property has been rejected"
          multiline
          rows={3}
          {...field}
          handleChange={field.onChange}
        />
      )}
    />
  );
};

const {
  shape,
} = PropTypes;

RejectionNotesInput.propTypes = {
  control: shape().isRequired,
};

export default RejectionNotesInput;
