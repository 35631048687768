import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { NakedButton } from '@symplete/symplete-ui';

import SearchCategoryBlock from 'shared/components/UI/SearchCategoryBlock';
import SearchItemContact from 'shared/modules/SearchItemContact';

const Item = ({ contact, handleClick }) => (handleClick
    ? (
      <NakedButton
        onClick={handleClick(contact.id)}
        key={contact.id}
      >
        <SearchItemContact
          objectId={contact.id}
        />
      </NakedButton>
    )
    : (
      <Link
        to={`/contacts/${contact.id}`}
        key={contact.id}
      >
        <SearchItemContact
          objectId={contact.id}
        />
      </Link>
    ));

const ContactsSearch = (props) => {
  const [searchResult, setSearchResult] = useState([]);

  const {
    search,
    onSearch,
    onSelect,
  } = props;

  useEffect(() => {
    const fetchData = async () => {
      const data = await onSearch(search);
      setSearchResult(data);
    };

    fetchData();
  }, [search]);

  const handleClick = onSelect
    ? useCallback((contactId) => (event) => {
        event.preventDefault();
        onSelect(25)(contactId);
      })
    : null;

  return (
    <SearchCategoryBlock
      title="contacts"
      iconName="bookOpen"
    >
      {
        searchResult && searchResult.map((contact) => <Item contact={contact} handleClick={handleClick} />)
      }
    </SearchCategoryBlock>
  );
};

ContactsSearch.defaultProps = {
  search: '',
};

const {
  func,
  string,
} = PropTypes;

ContactsSearch.propTypes = {
  search: string,
  onSearch: func.isRequired,
};

export default ContactsSearch;
