import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'ui/Icon';
import Typography from 'ui/Typography';

import {
  Root,
  Tile,
} from './styledItems';

const CreateNew = (props) => {
  const {
    onClick,
    disabled,
  } = props;

  return (
    <Root
      onClick={onClick}
      disabled={disabled}
    >
      <Tile>
        <Icon
          name="plus"
          color="focus"
          size="m"
        />
        <Typography
          variant="body"
          color="highlight"
          align="center"
        >
          Create New
        </Typography>
      </Tile>
    </Root>
  );
};

CreateNew.defaultProps = {
  disabled: false,
};

const {
  func,
  bool,
} = PropTypes;

CreateNew.propTypes = {
  onClick: func.isRequired,
  disabled: bool,
};

export default CreateNew;
