import React, {
  useState,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import Button from 'ui/Button';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Elevation from 'ui/Elevation';

import KPIBar from 'sections/Leads/modules/KPIBar';
import LeadsTable from 'sections/Leads/modules/LeadsTable';
import LeadsTableSearchFilter from 'sections/Leads/modules/LeadsTableSearchFilter';
import LeadsTableFilterModal from 'sections/Leads/modals/LeadsTableFilterModal';
import OptionsLeadsList from 'sections/Leads/modules/OptionsLeadsList';
// import BulkLeadActions from 'sections/Leads/modules/BulkLeadActions';
// import ActiveFilters from 'sections/Leads/components/ActiveFilters';

import { Root } from './styledItems';

const Leads = (props) => {
  const {
    openNewLeadModal,
  } = props;

  const [isLeadTableFilterModalVisible, setLeadTableFilterModalVisibility] = useState(false);

  const [selectedLeadIds, setSelectedLeadIds] = useState([]);

  const openLeadTableFilterModal = useCallback(() => {
    setLeadTableFilterModalVisibility(true);
  }, [
    setLeadTableFilterModalVisibility,
  ]);

  const closeLeadTableFilterModal = useCallback(() => {
    setLeadTableFilterModalVisibility(false);
  }, [
    setLeadTableFilterModalVisibility,
  ]);

  return (
    <Root>
      <Flex spacing={2} flexWrap="wrap">
        <Flex fullWidth spacing={2} alignItems="center">
          <FlexItem flex={1}>
            <KPIBar interactive />
          </FlexItem>
          <Button
            onClick={openNewLeadModal}
            variant="primary"
            icon="plus"
            style={{ zIndex: 1 }}
          >
            New Lead
          </Button>
          <OptionsLeadsList
            selected={selectedLeadIds}
          />
        </Flex>
        <FlexItem fullWidth>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <FlexItem xs={16}>
              <LeadsTableSearchFilter
                onFilterClick={openLeadTableFilterModal}
              />
            </FlexItem>
          </Flex>
        </FlexItem>
        <Elevation
          rounded="m"
          hideOverflow
        >
          <LeadsTable
            onRowSelect={setSelectedLeadIds}
          />
        </Elevation>
      </Flex>

      <LeadsTableFilterModal
        isVisible={isLeadTableFilterModalVisible}
        closeModal={closeLeadTableFilterModal}
      />
    </Root>
  );
};

const {
  func,
} = PropTypes;

Leads.propTypes = {
  openNewLeadModal: func.isRequired,
};

export default Leads;
