import React from 'react';
import PropTypes from 'prop-types';

import IconButton from 'shared/components/UI/ObjectMenuIconButton';
import withOptionsMenu from 'ui/withOptionsMenu';

const noOp = () => {};

const Root = withOptionsMenu(() => (
  <IconButton
    iconName="ellipsis-v"
    onClick={noOp}
  />
));

const options = [
  {
    value: 'reinspection',
    label: '+ Add Re-Inspection',
  },
  {
    value: 'repair',
    label: '+ New Repair Request',
  },
  {
    value: 'delete',
    label: 'Delete inspection',
  },
];

const ActionsCell = (props) => {
  const {
    onAction,
  } = props;

  return (
    <Root
      options={options}
      onChange={onAction}
    />
  );
};

const {
  func,
} = PropTypes;

ActionsCell.propTypes = {
  onAction: func.isRequired,
};

export default ActionsCell;
