import { connect } from 'react-redux';

import selectors from 'store/selectors';

import MultipleRoleLabel from './MultipleRoleLabel';

const mapStateToProps = (state, ownProps) => {
  const {
    leadId,
  } = ownProps;

  const lead = selectors.realtorLeads.byId(state, leadId);

  const hasProperties = lead?.properties?.length > 0;
  const hasRequirements = lead?.property_requirements?.length > 0;
  const hasBoth = hasProperties && hasRequirements;

  const role = hasBoth
    ? 3
    : hasRequirements
      ? 1
      : 2;

  return {
    role,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (value) => {},
});

export default connect(mapStateToProps, mapDispatchToProps)(MultipleRoleLabel);
