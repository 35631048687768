import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  history,
} from 'store';

import {
  updateSelectedClient,
  deleteSelectedClient,
} from 'store/actions/realtorClients';

import Options from './Options';

const mapStateToProps = (state) => {
  const client = selectors.realtorClients.selected(state);

  return {
    status: client.status,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onStatusChange: (status) => {
    dispatch(updateSelectedClient({ status }));
  },
  onDelete: () => {
    dispatch(deleteSelectedClient());
    history.push('/opportunities');
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Options);
