import { connect } from 'react-redux';

import selectors from 'store/selectors';

import { PROPERTY_TYPES } from 'constants/realtor.constants';

import RealtorLeadItemDetails from 'shared/components/UI/RealtorLeadItemDetails';

const itemTypeToSelector = {
  client: 'realtorClients',
  prospect: 'realtorProspects',
};

const mapStateToProps = (state, ownProps) => {
  const {
    itemId,
    itemType,
  } = ownProps;

  if (!itemId || !itemType) {
    return null;
  }

  const item = selectors[itemTypeToSelector[itemType]].byId(state, itemId);

  const contact = selectors.realtorContacts.byId(state, item?.contact_id);

  let propertyOrRequirement = {};

  if (itemType === 'client') {
    propertyOrRequirement = selectors.properties.byId(state, item?.realtor_property_id);
  }
  if (itemType === 'prospect') {
    propertyOrRequirement = selectors.propertyRequirements.byId(state, item?.realtor_property_requirement_id);
  }

  const addressId = propertyOrRequirement?.addresses
    ? propertyOrRequirement?.addresses[0]
    : propertyOrRequirement?.address_id;
  const address = selectors.addresses.byId(state, addressId);

  const data = {
    contactName: `${contact?.first_name || ''} ${contact?.last_name || ''}`,
    location: address?.address,
    role: itemType === 'client' ? 2 : 1,
    expectedSalePrice: propertyOrRequirement.expected_price,
    expectedPurchasePrice: [propertyOrRequirement.budget_min, propertyOrRequirement.budget_max],
    propertyType: itemType === 'client'
      ? [PROPERTY_TYPES[propertyOrRequirement?.property_type]]
      // eslint-disable-next-line max-len
      : Array.isArray(propertyOrRequirement?.property_type) && propertyOrRequirement?.property_type?.map((type) => PROPERTY_TYPES[type]),
  };

  return data;
};

export default connect(mapStateToProps)(RealtorLeadItemDetails);
