import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Icon from 'ui/Icon';
import Typography from 'ui/Typography';

import {
  Root,
  Tile,
} from './styledItems';

const MarketingTemplateItem = (props) => {
  const {
    id,
    onClick,
    icon,
    selected,
    iconType,
    title,
    disabled,
  } = props;

  const handleClick = useCallback(() => {
    onClick(id);
  }, [
    id,
  ]);

  return (
    <Root
      onClick={handleClick}
      disabled={disabled}
      selected={selected}
    >
      <Tile>
        <Icon
          name={icon}
          color="focus"
          size="60"
          type={iconType}
        />
      </Tile>
      <Typography
        variant="title4"
        color="highlight"
        noMargin
        textTransform="uppercase"
        align="center"
        weight={500}
      >
        {title}
      </Typography>
    </Root>
  );
};

MarketingTemplateItem.defaultProps = {
  iconType: 'feather',
  disabled: false,
  selected: false,
};

const {
  func,
  string,
  number,
  bool,
  oneOfType,
} = PropTypes;

MarketingTemplateItem.propTypes = {
  id: oneOfType([number, string]).isRequired,
  onClick: func.isRequired,
  icon: string.isRequired,
  iconType: string,
  title: string.isRequired,
  selected: bool.isRequired,
  disabled: bool,
};

export default MarketingTemplateItem;
