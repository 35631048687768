import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import { NakedButton } from '@symplete/symplete-ui';
import ClickAwayListener from 'ui/ClickAwayListener';
import Typography from 'ui/Typography';

import useEditMode from 'ui/hooks/useEditMode';

import PlacesAutocomplete from 'shared/components/UI/PlacesAutocompleteV2';

const AddressInput = (props) => {
  const {
    defaultView,
    error,
    focusOnRender,
    handleChange,
    inline,
    label,
    onBlur,
    placeholder,
    required,
    value,
  } = props;

  const {
    mode,
    setEditMode,
    setDisplayMode,
  } = useEditMode(defaultView);

  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [
    value,
  ]);

  const onChange = useCallback((location) => {
    setCurrentValue(location?.formatted_address);
    handleChange({
      address: location?.formatted_address,
    });
  }, [
    handleChange,
  ]);

  return (
    <>
      {
        mode === 'edit' && (
          <ClickAwayListener onClickAway={setDisplayMode}>
            <div>
              <PlacesAutocomplete
                defaultValue={currentValue}
                error={error}
                focusOnRender={focusOnRender}
                inline={inline}
                label={label}
                onBlur={onBlur}
                onChange={onChange}
                onSelect={onChange}
                placeholder={placeholder}
                required={required}
                selectedValue={currentValue}
                value={currentValue}
                variant="outlined"
              />
            </div>
          </ClickAwayListener>
        )
      }
      {
        mode === 'display' && (
          <NakedButton onClick={setEditMode}>
            <Typography
              variant="title1"
              noMargin
            >
              { value }
            </Typography>
          </NakedButton>
        )
      }
    </>

  );
};

AddressInput.defaultProps = {
  defaultView: 'edit',
  error: null,
  focusOnRender: false,
  inline: false,
  label: 'Address',
  onBlur: () => {},
  placeholder: 'Not set',
  required: false,
  value: null,
};

const {
  bool,
  func,
  oneOf,
  string,
  oneOfType,
} = PropTypes;

AddressInput.propTypes = {
  defaultView: oneOf(['edit', 'display']),
  error: oneOfType([string, PropTypes.null]),
  focusOnRender: bool,
  handleChange: func.isRequired,
  inline: bool,
  label: string,
  onBlur: func,
  placeholder: string,
  required: bool,
  value: string,
};

export default AddressInput;
