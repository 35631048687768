import apiConnector, { endpoints } from 'api';

const getPostalCode = async (placeId) => {
  const endpoint = `${endpoints.client.postalCode}/${placeId}`;
  try {
    const result = await apiConnector.client.get(endpoint);
    return result.status === 200 ? result.data : '';
  } catch (error) {
    console.error(error);
    return '';
  }
};

export default getPostalCode;
