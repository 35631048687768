import React from 'react';
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import { defaultTheme } from '@symplete/symplete-ui';

import OurThemeProvider from 'shared/themes/OurThemeProvider';
import withTheme from 'shared/hocs/withTheme';

import routes from 'sections/Calendar/routes';

import MonthView from 'sections/Calendar/pages/MonthView';
import DayView from 'sections/Calendar/pages/DayView';

import CommonTools from 'sections/Calendar/modules/CommonTools';
import CalendarSearchSuggestions from 'sections/Calendar/modules/CalendarSearchSuggestions';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import theme from './theme';

const today = new Date();
const year = today.getFullYear();
const month = today.getMonth();

const InAppMarketing = () => {
  const Theme = withTheme(defaultTheme, theme)(OurThemeProvider);


  return (
    <Flex
      flexDirection="column"
      spacing={2}
    >
      <Flex
        justifyContent="space-between"
        spacing={2}
        fullWidth
      >
        <FlexItem xs="20">
          <CalendarSearchSuggestions />
        </FlexItem>
        <FlexItem xs="3" width="auto" marginLeft="auto">
          <CommonTools />
        </FlexItem>
      </Flex>
      <Theme>
        <Switch>
          <Route
            path={routes.dayView}
            component={DayView}
          />
          <Route
            path={routes.monthView}
            component={MonthView}
          />

          <Redirect to={`${routes.sectionRoot}/${year}/${month + 1}`} />
        </Switch>
      </Theme>
    </Flex>
  );
};

export default InAppMarketing;
