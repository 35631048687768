import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  history,
} from 'store';

import {
  updateSelectedProspect,
  deleteSelectedProspect,
} from 'store/actions/realtorProspects';

import Options from './Options';

const mapStateToProps = (state) => {
  const prospect = selectors.realtorProspects.selected(state);

  return {
    status: prospect.status,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onStatusChange: (status) => {
    dispatch(updateSelectedProspect({ status }));
  },
  onDelete: () => {
    dispatch(deleteSelectedProspect());
    history.push('/opportunities');
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Options);
