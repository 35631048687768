import { connect } from 'react-redux';

import { currency } from '@symplete/symplete-utils/numbers';

import selectors from 'store/selectors';

import Property from './Property';

const mapStateToProps = (state, ownProps) => {
  const { offerId } = ownProps;

  const offer = selectors.realtorOffers.byId(state, offerId);

  let propertyId;

  if (offer.realtor_property_id) {
    propertyId = offer.realtor_property_id;
  } else if (offer.realtor_client_id) {
    const client = selectors.realtorClients.byId(state, offer.realtor_client_id);
    propertyId = client.realtor_property_id;
  }

  const property = selectors.properties.byId(state, propertyId);
  const address = selectors.addresses.byId(state, property.address_id);

  let sellerContact;

  if (offer.realtor_client_id) {
    const client = selectors.realtorClients.byId(state, offer.realtor_client_id);
    sellerContact = selectors.realtorContacts.byId(state, client?.contact_id);
  } else if (offer.seller_contact_id) {
    sellerContact = selectors.realtorContacts.byId(state, offer.seller_contact_id);
  }

  return {
    propertyId,
    address: address?.address,
    price: property.expected_price && currency.full(property.expected_price),
    representingContact: sellerContact ? `${sellerContact.first_name || ''} ${sellerContact.last_name || ''}` : null,
  };
};

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Property);
