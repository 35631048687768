import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

const ToDoCountColumn = (props) => {
  const {
    toDoCount,
  } = props;

  return (
    <Typography
      variant="subtitle1"
      noMargin
    >
      {toDoCount || 'None'}
    </Typography>
  );
};

const {
  number,
} = PropTypes;

ToDoCountColumn.propTypes = {
  toDoCount: number.isRequired,
};

export default ToDoCountColumn;
