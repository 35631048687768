import { connect } from 'react-redux';

import { updateSelectedContact } from 'store/actions/crm/crmContacts';

import SocialLinks from './SocialLinks';

const mapDispatchToProps = (dispatch) => ({
  updateLink: (type, url) => dispatch(updateSelectedContact({ [type]: url })),
});

export default connect(null, mapDispatchToProps)(SocialLinks);
