import { connect } from 'react-redux';

import selectors from 'store/selectors';

import ContactInfo from './ContactInfo';

const mapStateToProps = (state, ownProps) => {
  const client = selectors.realtorClients.selected(state);
  const contact = selectors.realtorContacts.byId(state, client.contact_id);

  return {
    contactId: contact?.id,
    email: contact?.email_primary,
    phone: contact?.phone_primary,
  };
};

export default connect(mapStateToProps)(ContactInfo);
