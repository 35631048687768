import store from 'store';

import {
  setNewPropertyViewingFormField,
} from 'store/actions/newRealtorPropertyViewingForm';

import {
  openNewPropertyViewingModal,
} from 'store/actions/newRealtorPropertyViewingModal';

import datetime from '@symplete/symplete-utils/datetime';

const onCreateViewing = ({
  contactId,
  propertyId,
  onSave,
  type,
}) => {
  contactId && store.dispatch(setNewPropertyViewingFormField('contacts')([contactId]));
  propertyId && store.dispatch(setNewPropertyViewingFormField('property_id')(propertyId));
  onSave && store.dispatch(setNewPropertyViewingFormField('onSave')(onSave));

  if (type === 'upcoming') {
    store.dispatch(setNewPropertyViewingFormField('minTime')(datetime()));
  }

  if (type === 'completed') {
    store.dispatch(setNewPropertyViewingFormField('maxTime')(datetime()));
  }

  store.dispatch(openNewPropertyViewingModal(
    propertyId,
    {
      property: !propertyId,
      contact: !contactId,
    },
  ));
};

export default onCreateViewing;
