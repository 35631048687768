import React, {
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import RichTextEditor from 'shared/components/RichTextEditor';
import ArticleControls from 'shared/components/ArticleControls';

const ReminderTextEditor = (props) => {
  const {
    defaultValue,
    onConfirm,
    onClickCancel,
    isAutoFocus,
    isClearEditorOnSubmit,
    visibility,
  } = props;

  const [value, setValue] = useState(defaultValue);

  const onCancel = useCallback(() => {
    setValue(defaultValue);
    onClickCancel();
  }, [
    defaultValue,
    setValue,
    onClickCancel,
  ]);

  const handleText = useCallback((content) => {
    onConfirm(content);
  },
  []);

  return (
    <RichTextEditor
      className="text-editor"
      isFullRichEditor={false}
      onClickPost={handleText}
      onClickCancel={onCancel}
      value={value}
      isClearEditorOnSubmit={isClearEditorOnSubmit}
      visibility={visibility}
      isAutoFocus={isAutoFocus}
      ControlsComponent={ArticleControls}
    />
  );
};

ReminderTextEditor.defaultProps = {
  defaultValue: null,
  isAutoFocus: false,
  visibility: false,
  isClearEditorOnSubmit: false,
};

const {
  func,
  string,
  bool,
} = PropTypes;

ReminderTextEditor.propTypes = {
  defaultValue: string,
  visibility: false,
  isAutoFocus: bool,
  isClearEditorOnSubmit: bool,
  onConfirm: func.isRequired,
  onClickCancel: func.isRequired,
};

export default ReminderTextEditor;
