import React, {
  useEffect,
  useCallback,
  useState,
} from 'react';

import { Table } from 'antd';

import PropTypes from 'prop-types';

import columns from './columns';

import './InspectionsTable.scss';

const showTotal = (total, range) => `${range[0]}-${range[1]} of ${total} ${total > 1 ? 'inspections' : 'inspection'}`;

const InspectionsTable = (props) => {
  const {
    getRepairRequests,
    inspectionsList,
    itemsCount,
    setFilterItem,
    transactionId,
  } = props;

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    getRepairRequests(pageNumber, pageSize);
  }, [
    transactionId,
  ]);

  const onPaginationChange = useCallback((newPageNumber, newPageSize) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
    setFilterItem('pageNumber')(newPageNumber);
    setFilterItem('pageSize')(newPageSize);
    getRepairRequests(newPageNumber, newPageSize);
  }, [
    getRepairRequests,
  ]);

  const [hoveredRecord, setHoveredRecord] = useState(null);

  const onRow = useCallback((record, rowIndex) => ({
    onMouseEnter: (event) => {
      setHoveredRecord(record.id);
    },
    onMouseLeave: (event) => {
      setHoveredRecord(null);
    },
  }), [
    setHoveredRecord,
  ]);

  return (
    <>
      <Table
        key="inspections-table"
        className="realtor-inspections-table"
        dataSource={inspectionsList}
        columns={columns({ hoveredRecord, transactionId })}
        pagination={{
          total: itemsCount,
          showTotal,
          onChange: onPaginationChange,
          current: pageNumber,
          pageSize,
        }}
        onRow={onRow}
        tableLayout="fixed"
        size="middle"
      />
    </>
  );
};

InspectionsTable.defaultProps = {
  inspectionsList: [],
  itemsCount: 0,
};

const {
  arrayOf,
  func,
  number,
  string,
  shape,
  oneOfType,
} = PropTypes;

InspectionsTable.propTypes = {
  inspectionsList: arrayOf(shape({})),
  setFilterItem: func.isRequired,
  getRepairRequests: func.isRequired,
  itemsCount: number,
  transactionId: oneOfType([number, string]).isRequired,
};

export default InspectionsTable;
