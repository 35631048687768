import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import Button from 'ui/Button';

import useCampaign from 'sections/Communications/hooks/useCampaign';

import routes from 'sections/InAppMarketing/routes';

const SendAgain = (props) => {
  const {
    campaignId,
  } = props;

  const {
    duplicate,
    isDuplicating,
  } = useCampaign(campaignId);

  const history = useHistory();

  const onClick = useCallback(async () => {
    const newCampaignId = await duplicate();

    if (!newCampaignId) {
      return;
    }

    history.push(`${routes.campaignDetails}/${newCampaignId}`);
  }, [
    duplicate,
  ]);

  return (
    <Button
      onClick={onClick}
      disabled={isDuplicating}
    >
      Send Again
    </Button>
  );
};

const {
  number,
} = PropTypes;

SendAgain.propTypes = {
  campaignId: number.isRequired,
};

export default SendAgain;
