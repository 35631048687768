import React from 'react';
import PropTypes from 'prop-types';

import datetime from '@symplete/symplete-utils/datetime';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import Icon from 'ui/Icon';
import Tag from 'ui/Tag';

const BasicInfo = (props) => {
  const {
    title,
    sentAt,
    completed,
  } = props;

  return (
    <Flex
      alignItems="center"
      spacing={3}
    >
      <Icon
        name="mail"
        color="focus"
        size="xl"
        base="rounded"
        baseColor="backgroundFlat"
      />
      <div>
        <Typography
          variant="title1"
          weight={500}
          noMargin
          style={{ marginBottom: 5 }}
        >
          {title}
        </Typography>
        <Flex
          spacing={1}
          alignItems="baseline"
        >
          <Typography noMargin>
            <b>Sent:</b>
            {' '}
            {sentAt ? datetime(sentAt).format('MMMM D, YYYY, h:mmA') : 'N/A'}
          </Typography>

          {
            completed && (
              <Tag
                variant="success"
              >
                COMPLETED
              </Tag>
            )
          }
        </Flex>
      </div>
    </Flex>
  );
};

BasicInfo.defaultProps = {
  completed: false,
};

const {
  string,
  bool,
} = PropTypes;

BasicInfo.propTypes = {
  title: string.isRequired,
  sentAt: string.isRequired,
  completed: bool,
};

export default BasicInfo;
