/* eslint-disable camelcase, import/no-cycle */
import { callAlert } from 'ui/Alert';

import { isEmpty } from 'shared/utility';

import dataUrlToFile from 'shared/utils/attachments/dataUrlToFile';

import { // eslint-disable-line import/no-cycle
  createContact,
} from 'store/actions/realtorContacts';

import {
  optimizedAttachmentsActions,
  uploadAttachment,
} from 'store/actions/attachmentsV2';

import {
  resetNewPropertyForm,
  setNewPropertyFormField,
} from 'store/actions/newRealtorPropertyForm';

import {
  createMatchingProperty,
} from 'store/actions/realtorMatchingProperties';

import createProperty from './createProperty';
import updateProperty from './updateProperty';
import getPropertyById from './getPropertyById';
import getPropertyAttachments from './getPropertyAttachments';
import getPropertiesForStoreFilter from './getPropertiesForStoreFilter'; // eslint-disable-line import/no-cycle

const propertyObjectType = 27;

const createPropertyFromNewPropertyForm = () => async (dispatch, getState) => {
  try {
    const {
      realtorNewPropertyForm,
    } = getState();

    const {
      address_id,
      property_type,
      expected_price,
      mls_number,
      mls_website,
      on_market_since,

      reo_bank_owned,
      in_foreclosure,
      short_sale,
      flood_ins_req,
      auction,
      water_shares,
      hud_owned,

      // sellerAgents,
      listing_agent,
      newSellerAgent,

      images,
      mainImageIndex,

      interested_parties,
      temp,
      seller,
      property_state,
      transfer_state,
    } = realtorNewPropertyForm;

    if (!address_id) return null;

    let sellerAgentId;

    if (listing_agent !== 0 && !listing_agent) {
      sellerAgentId = await dispatch(createContact(newSellerAgent));
    }

    const propertyData = {
      address_id,
      property_type,
      expected_price,
      mls_number,
      mls_website,
      on_market_since,
      status: 1,

      reo_bank_owned,
      in_foreclosure,
      short_sale,
      flood_ins_req,
      auction,
      water_shares,
      hud_owned,
      temp,

      listing_status: 1,
      listing_agent: sellerAgentId || listing_agent,
      seller,
      property_state,
      transfer_state,

      // sellerAgents: sellerAgentId
      //   ? [sellerAgentId]
      //   : sellerAgents || [],
    };

    const propertyId = await dispatch(createProperty(propertyData));

    if (Array.isArray(interested_parties)) {
      const addInterestedParties = interested_parties.map(async (contactId) => {
        await dispatch(createMatchingProperty({
          realtor_property_id: propertyId,
          realtor_contact_id: contactId,
          status: 1,
        }));
      });

      await Promise.all(addInterestedParties);
    }

    const hasImages = !isEmpty(images);
    let mainImageId;

    if (hasImages) {
      const uploadImages = async (index) => {
        const image = images[index];

        const {
          name,
          type,
          url,
        } = image;

        const file = await dataUrlToFile(url, name, type);
        const data = {
          file,
          objectId: propertyId,
          objectType: 27,
          attachmentType: 11,
          isPublic: true,
        };

        const attachment = await dispatch(uploadAttachment(data, index === images.length - 1));

        if (index === mainImageIndex) {
          mainImageId = attachment.attachmentId;
        }
        URL.revokeObjectURL(url);

        const nextIndex = index + 1;
        if (nextIndex < images.length) {
          await uploadImages(nextIndex);
        }
      };

      try {
        dispatch(setNewPropertyFormField('loading')(true));

        // doing recursion here because Promise.all is not suitable for images uploading
        // to AWS in this case
        await uploadImages(0);

        optimizedAttachmentsActions.getAttachmentsList(propertyObjectType, propertyId);
      } catch (error) {
        console.error(error);
        callAlert.error('Could not upload images');
      }

      dispatch(setNewPropertyFormField('loading')(false));
    }

    if (mainImageId) {
      await dispatch(updateProperty(propertyId)({ main_image_id: mainImageId }));
    } else {
      await dispatch(getPropertyById(propertyId));
    }

    if (hasImages) {
      dispatch(getPropertyAttachments(propertyId));
    }

    dispatch(resetNewPropertyForm());

    dispatch(getPropertiesForStoreFilter());

    return propertyId;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default createPropertyFromNewPropertyForm;
