import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import Flex from 'ui/Flex';
import Button from 'ui/Button';
import Typography from 'ui/Typography';

import MainContactInfo from './MainContactInfo';

import {
  Root,
  TagWrapper,
} from './styledItems';

const MatchingBuyerCard = (props) => {
  const {
    contactId,
    cancelShowing,
    showingDate,
  } = props;

  return (
    <Root
      rounded="s"
      padding="8px"
    >
      <Flex
        flexDirection="column"
        alignItems="stretch"
        spacing={1}
      >
        <MainContactInfo contactId={contactId} />
        <Flex
          justifyContent="stretch"
          alignItems="stretch"
          spacing={2}
        >
          <TagWrapper>
            <Typography
              color="main-text"
              noMargin
            >
              {moment(showingDate).format('ddd MMMM D, LT')}
            </Typography>
          </TagWrapper>

          <Button
            variant="secondary"
            icon="x"
            iconColor="main-text"
            onClick={cancelShowing}
          />
        </Flex>
      </Flex>

    </Root>
  );
};

const {
  number,
  string,
  func,
} = PropTypes;

MatchingBuyerCard.propTypes = {
  contactId: number.isRequired,
  showingDate: string.isRequired,
  cancelShowing: func.isRequired,
};

export default MatchingBuyerCard;
