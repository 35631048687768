import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import YearRangeSelect from 'ui/YearRangeSelect';

import SingleSelect from './SingleSelect';

const YearBuiltSelect = (props) => {
  const {
    handleChange,
    handleChangeMin,
    handleChangeMax,
    value,
    minValue,
    maxValue,
    rangeSelect,
    defaultView,
  } = props;

  const handleRangeChange = useCallback((type) => (value) => {
    if (type === 'min') {
      handleChangeMin(value);
    } else {
      handleChangeMax(value);
    }
  }, [
    handleChangeMin,
    handleChangeMax,
  ]);

  if (rangeSelect) {
    return (
      <YearRangeSelect
        min={minValue}
        max={maxValue}
        onChange={handleRangeChange}
        defaultView={defaultView}
        minLabel="Oldest (year built max)"
        maxLabel="Newest (year built min)"
        displayLabel="Years built:"
      />
    );
  }

  return (
    <SingleSelect
      value={value}
      handleChange={handleChange}
      defaultView={defaultView}
    />
  );
};

YearBuiltSelect.defaultProps = {
  value: null,
  minValue: null,
  maxValue: null,
  rangeSelect: false,
  handleChangeMin: () => {},
  handleChangeMax: () => {},
  handleChange: () => {},
  defaultView: 'edit',
};

const {
  bool,
  func,
  number,
  oneOf,
} = PropTypes;

YearBuiltSelect.propTypes = {
  handleChange: func,
  handleChangeMin: func,
  handleChangeMax: func,
  value: number,
  rangeSelect: bool,
  minValue: number,
  maxValue: number,
  defaultView: oneOf(['edit', 'display']),
};

export default YearBuiltSelect;
