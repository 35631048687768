import React from 'react';
import PropTypes from 'prop-types';
import ReminderItems from './ReminderItems';
import TitleForm from './ReminderTitleForm';
import './ToDoListBody.scss';

const ToDoListBody = (props) => {
  const {
    todoReminderList,
  } = props;
  return (
    <div className="to-do-list-body">
      <TitleForm />
      <ReminderItems
        todoReminderList={todoReminderList}
      />
    </div>
  );
};

const {
  arrayOf,
  number,
  string,
} = PropTypes;

ToDoListBody.propTypes = {
  todoReminderList: arrayOf({
    EmployeeID: number,
    Name: string,
    Surname: string,
    assigneeByID: number,
    completedAt: string,
    createdAt: string,
    creatorID: number,
    isCompleted: number,
    isImportant: number,
    numberInList: number,
    reminderID: number,
    reminderTitle: string,
  }).isRequired,
};

export default ToDoListBody;
