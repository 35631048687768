import React from 'react';
import PropTypes from 'prop-types';

import {
  NakedButton,
} from '@symplete/symplete-ui';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';
import Icon from 'ui/Icon';

const MailboxSelectorRoot = (props) => {
  const {
    anchorRef,
    openMenu,
    selectedBox,
  } = props;

  return (
    <NakedButton
      ref={anchorRef}
      onClick={openMenu}
    >
      <Flex
        alignItems="center"
        spacing={0.5}
      >
        <Typography
          tag="span"
          textTransform="capitalize"
          variant="title1"
          noMargin
          weight="bold"
        >
          { selectedBox }
        </Typography>
        <Icon
          size="m"
          name="chevron-down"
          color="main-text"
        />
      </Flex>
    </NakedButton>
  );
};

const {
  any,
  func,
  oneOfType,
  shape,
  string,
} = PropTypes;

MailboxSelectorRoot.propTypes = {
  anchorRef: oneOfType([
    func,
    shape({ current: any }),
  ]).isRequired,
  openMenu: func.isRequired,
  selectedBox: string.isRequired,
};

export default MailboxSelectorRoot;
