import {
  SET_REALTOR_CLIENTS_FILTER_ITEM,
} from 'store/actionTypes/realtorClients';

const setFilterItem = (key) => (value) => ({
  type: SET_REALTOR_CLIENTS_FILTER_ITEM,
  payload: {
    key,
    value,
  },
});

export default setFilterItem;
