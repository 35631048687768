import React from 'react';
import PropTypes from 'prop-types';

import { isNil } from 'shared/utility';

import Grid from 'ui/Grid';

import PriceText from 'shared/components/UI/PriceText';

const Price = (props) => {
  const {
    initialValue,
    finalValue,
  } = props;

  return (
    <Grid container spacing={2} alignItems="baseline">
      <Grid item>
        <PriceText value={finalValue || initialValue} />
      </Grid>
      {
        finalValue !== initialValue && !isNil(finalValue) && (
          <Grid item>
            <PriceText
              value={initialValue}
              variant="h3"
              color="disabled"
              textDecoration="line-through"
            />
          </Grid>
        )
      }
    </Grid>
  );
};

const { number } = PropTypes;

Price.propTypes = {
  initialValue: number.isRequired,
  finalValue: number.isRequired,
};

export default Price;
