import { connect } from 'react-redux';

import selectors from 'store/selectors';

import LocationCell from './LocationCell';

const mapStateToProps = (state, ownProps) => {
  const {
    type,
    itemId,
  } = ownProps;

  const item = selectors.realtorOpportunities.byTypeAndId(state, type, itemId);

  return {
    type,
    item,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(LocationCell);
