import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import { currency } from '@symplete/symplete-utils/numbers';

import Grid from 'ui/Grid';

import withFieldDecorator from 'shared/hocs/withFieldDecorator';

import withContactDataBasic from 'sections/Contacts/hocs/withContactDataBasic';
import withMainPropertyImage from 'sections/Properties/hocs/withMainPropertyImage';

import Typography from 'ui/Typography';

import PropertyRequirementParametersIndicators from 'sections/MLS/modules/PropertyRequirementParametersIndicators';
import AvatarComponent from 'sections/Opportunities/components/Avatar';
import AdditionalParameters from 'sections/Opportunities/components/AdditionalParameters';

import UnderConstructionPlaceholder from 'shared/components/UI/UnderConstructionPlaceholder';

import {
  Root,
  BuyerContainer,
  BuyerDetailsContainer,
  AvatarContainer,
  PropertyPhoto,
  PhotoContainer,
  Label,
  Notice,
} from './styledItems';

const formatPrice = (value) => currency.full(value);

const OfferCount = withFieldDecorator({
  iconName: 'tag',
  variant: 'warm',
  size: 'xs',
  noMargin: true,
})(Typography);

const BuyerPhoto = withContactDataBasic(AvatarComponent);
const BuyerName = withContactDataBasic((props) => (
  <Typography
    variant="h3"
    weight={700}
    noMargin
  >
    {props.firstName}
    {' '}
    {props.lastName}
  </Typography>
));

const PropertyImage = withMainPropertyImage(PropertyPhoto);

const ProspectCard = (props) => {
  const {
    budget,
    expiryDate,
    addresses,
    buyerId,
    types,
    createdAt,
    propertyRequirementId,
    label,
    notice,

    // offersCount,
    // viewingsCount,
  } = props;

  const daysOld = moment().diff(moment(createdAt), 'days');
  const weeksToExpire = moment(expiryDate).diff(moment(), 'weeks') < 1
    ? `${moment(expiryDate).diff(moment(), 'days')} D`
    : `${moment(expiryDate).diff(moment(), 'weeks')} W`;

  return (
    <Root>
      {
        notice && (
          <Notice variant={notice.variant}>
            {notice.message}
          </Notice>
        )
      }
      <BuyerContainer>
        <BuyerPhoto
          contactId={buyerId}
          size="lg"
        />
        <BuyerDetailsContainer>
          <BuyerName
            contactId={buyerId}
          />
          <Label>
            Buyer
            {label && `| ${label}`}
          </Label>
          <Typography
            variant="label"
            weight="bold"
          >
            Active:
            {' '}
            <Typography variant="label">
              {daysOld}
              {' '}
              days
            </Typography>
          </Typography>
        </BuyerDetailsContainer>
      </BuyerContainer>

      <Grid
        container
        spacing={1}
        alignItems="baseline"
        style={{ padding: 5 }}
      >
        <Grid item xs={6}>
          <Typography
            variant="h3"
            color="success"
            tag="p"
            weight="bold"
            noWrap
          >
            {formatPrice(budget[0])}
            {' '}
            -
            {' '}
            {formatPrice(budget[1])}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Typography
              ellipsis
              style={{ textTransform: 'capitalize' }}
            >
              {types.join(', ')}
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12}>
          <PropertyRequirementParametersIndicators
            propertyRequirementId={propertyRequirementId}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            ellipsis
            noMargin
            tag="span"
          >
            {addresses[0]}
          </Typography>
          {
            addresses.length > 1 && (
              <Typography
                noMargin
                tag="span"
              >
                {`+ ${addresses.length - 1}`}
              </Typography>
            )
          }
        </Grid>

        <Grid item xs={12}>
          <AdditionalParameters
            data={[
              {
                count: 4,
                title: 'Reviewed',
              },
              {
                count: 5,
                title: 'Shortlisted',
              },
              {
                count: 3,
                title: 'Rejected',
              },
            ]}
          />
        </Grid>

      </Grid>
    </Root>
  );
};

ProspectCard.defaultProps = {
  budget: [],
  expiryDate: null,
  addresses: [],
  buyerId: null,
  types: [],
  createdAt: null,
  propertyRequirementId: null,
  label: null,
  notice: null,
};

const {
  arrayOf,
  number,
  string,
  shape,
} = PropTypes;

ProspectCard.propTypes = {
  budget: arrayOf(number),
  expiryDate: string,
  addresses: arrayOf(string),
  buyerId: number,
  types: arrayOf(number),
  createdAt: string,
  propertyRequirementId: number,
  label: string,
  notice: shape({
    variant: string,
    message: string,
  }),
};

export default ProspectCard;
