import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  Spin,
} from 'antd';

import {
  isEmpty,
} from 'shared/utility';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Typography from 'ui/Typography';

import InfiniteScroll from 'react-infinite-scroller';

import CenterBox from 'shared/components/UI/CenterBox';

import ContactItem from '../ContactItem';

import {
  ScrollContainer,
} from './styledItems';

const ContactsList = (props) => {
  const {
    contacts,
    loadMore,
    oneContactSelect,
    hasMore,
    selectedContacts,
    onAddContact,
    onRemoveContact,
    clearSearch,
    setContactDealRole,
    contactDealRole,
  } = props;

  useEffect(() => {
    if (contactDealRole) {
      setContactDealRole(contactDealRole);
    }
    clearSearch();
    loadMore();

    return () => {
      clearSearch();
      setContactDealRole(null);
    };
  }, []);

  return (
    <Flex spacing={2} flexWrap="wrap">
      <FlexItem fullWidth>
        <Typography variant="title4">
          You:
        </Typography>
        <ContactItem
          contactId={0}
          oneContactSelect={oneContactSelect}
          isEmptyContactSelector={isEmpty(selectedContacts)}
          isSelected={selectedContacts.includes(0)}
          onAdd={onAddContact(0)}
          onRemove={onRemoveContact(0)}
        />
      </FlexItem>
      <FlexItem fullWidth>
        <ScrollContainer>
          <Typography variant="title4">
            { oneContactSelect ? 'Or' : 'And/Or' }
          </Typography>
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={hasMore}
            loader={<CenterBox><Spin /></CenterBox>}
            useWindow={false}
            threshold={88}
          >
            <Flex spacing={2} flexWrap="wrap">
              {
                contacts.map(({ key, id }) => (
                  <Fragment key={key}>
                    <FlexItem
                      xs={7.5} // weird but this is because of the custom scrollbar
                    >
                      <ContactItem
                        contactId={id}
                        oneContactSelect={oneContactSelect}
                        isEmptyContactSelector={isEmpty(selectedContacts)}
                        isSelected={selectedContacts.includes(id)}
                        onAdd={onAddContact(id)}
                        onRemove={onRemoveContact(id)}
                      />
                    </FlexItem>
                  </Fragment>
                ))
              }
            </Flex>
          </InfiniteScroll>
        </ScrollContainer>
      </FlexItem>
    </Flex>
  );
};

ContactsList.defaultProps = {
  contacts: [],
  selectedContacts: [],
  hasMore: false,
  contactDealRole: null,
};

const {
  number,
  bool,
  arrayOf,
  shape,
  func,
  oneOf,
} = PropTypes;

ContactsList.propTypes = {
  contacts: arrayOf(shape({
    key: number,
    id: number,
  })),
  hasMore: bool,
  loadMore: func.isRequired,
  oneContactSelect: bool.isRequired,
  selectedContacts: arrayOf(number),
  onAddContact: func.isRequired,
  onRemoveContact: func.isRequired,
  clearSearch: func.isRequired,
  setContactDealRole: func.isRequired,
  contactDealRole: oneOf(['buyer', 'seller']),
};

export default ContactsList;
