import React from 'react';
import PropTypes from 'prop-types';

import BookShowing from 'sections/Prospects/modules/BookShowing';

import UpcomingShowingSummary from 'sections/MatchingProperties/modules/UpcomingShowingSummary';
import LogShowFeedback from 'sections/MatchingProperties/modules/LogShowFeedback';

const KanbanCardControls = (props) => {
  const {
    matchId,
    clientId,
    lane,
  } = props;

  if (lane === 'interestedParties') {
    return (
      <BookShowing
        matchId={matchId}
        clientId={clientId}
      />
    );
  }

  if (lane === 'showingsUpcoming') {
    return (
      <UpcomingShowingSummary
        matchId={matchId}
      />
    );
  }

  if (lane === 'showingsCompleted') {
    return (
      <LogShowFeedback
        matchId={matchId}
      />
    );
  }

  return null;
};

const {
  number,
  oneOf,
} = PropTypes;

KanbanCardControls.propTypes = {
  matchId: number.isRequired,
  clientId: number.isRequired,
  lane: oneOf([
    'interestedParties',
    'showingsUpcoming',
    'showingsCompleted',
    'offers',
  ]).isRequired,
};

export default KanbanCardControls;
