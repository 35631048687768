import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { isUndefined } from 'shared/utility';

import Flex from 'ui/Flex';

import MetricsItem from './MetricsItem';

const placeholderItems = [
  {
    key: 'openRate',
    title: 'Open Rate',
    extra: (value) => `${value} ${value === 1 ? 'Open' : 'Opens'}`,
  },
  {
    key: 'replyRate',
    title: 'Reply Rate',
    extra: (value) => `${value} ${value === 1 ? 'Reply' : 'Replies'}`,
  },
  {
    key: 'linkClickRate',
    title: 'Link Click Rate',
    extra: (value) => `${value} Link ${value === 1 ? 'Click' : 'Clicks'}`,
  },
  {
    key: 'noResponseRate',
    title: 'No Response',
    extra: (value) => `${value} No Response`,
  },
  {
    key: 'bounceRate',
    title: 'Bounce Rate',
    extra: (value) => `${value} ${value === 1 ? 'Bounce' : 'Bounces'}`,
  },
  {
    key: 'newLeads',
    title: 'New Leads',
    extra: (value) => `${value} Converted ${value === 1 ? 'Lead' : 'Leads'}`,
  },
  {
    key: 'newClients',
    title: 'New Clients',
    extra: (value) => `${value} New ${value === 1 ? 'Client' : 'Clients'}`,
  },
  {
    key: 'dealsClosed',
    title: 'Deals Closed',
    extra: (value) => `${value} New Closed ${value === 1 ? 'Deal' : 'Deals'}`,
  },
];

const Metrics = (props) => {
  const {
    data,
  } = props;

  return (
    <Flex
      spacing={2}
      flexWrap="wrap"
    >
      {
        placeholderItems.map((item) => (
          <Fragment key={item.key}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <MetricsItem
              value={data[item.key]?.percentage || null}
              title={item.title}
              extra={isUndefined(data[item.key]?.count) ? null : item.extra(data[item.key]?.count)}
            />
          </Fragment>
        ))
      }
    </Flex>
  );
};

Metrics.defaultProps = {
  data: {},
};

const {
  shape,
  string,
  number,
} = PropTypes;

const metricShape = {
  percentage: string,
  count: number,
};

Metrics.propTypes = {
  data: shape({
    openRate: metricShape,
    replyRate: metricShape,
    linkClickRate: metricShape,
    noResponseRate: metricShape,
    bounceRate: metricShape,
    newLeads: metricShape,
    newClients: metricShape,
    dealsCloses: metricShape,
  }),
};

export default Metrics;
