import React from 'react';

import TableHead from 'ui/TableHead';
import ContactLabels from 'sections/Contacts/modules/ContactLabels';

import ContactCell from './Cells/Contact';
import StageCell from './Cells/Stage';
import OptionsCell from './Cells/Options';

const columns = () => (
  [
    {
      key: 'contact',
      dataIndex: 'realtor_contact_id',
      title: () => (
        <TableHead>
          Contact
        </TableHead>
      ),
      width: 2.5,
      render: (contactId, record) => (
        <ContactCell contactId={contactId} email={record?.email} />
      ),
    },
    {
      key: 'contact labels',
      dataIndex: 'realtor_contact_id',
      title: () => (
        <TableHead>
          Labels
        </TableHead>
      ),
      width: 1.5,
      render: (contactId) => (
        <ContactLabels contactId={contactId} editable={false} />
      ),
    },
    {
      key: 'stage_sent',
      dataIndex: 'status_details',
      align: 'center',
      title: () => (
        <TableHead>
          Sent
        </TableHead>
      ),
      width: 1,
      render: (value) => <StageCell status={value} stage="sent" />,
    },
    {
      key: 'stage_opened',
      dataIndex: 'status_details',
      align: 'center',
      title: () => (
        <TableHead>
          Opened
        </TableHead>
      ),
      width: 1,
      render: (value) => <StageCell status={value} stage="opened" />,
    },
    {
      key: 'stage_replied',
      dataIndex: 'status_details',
      align: 'center',
      title: () => (
        <TableHead>
          Replied
        </TableHead>
      ),
      width: 1,
      render: (value) => <StageCell status={value} stage="replied" />,
    },
    {
      key: 'stage_link_clicked',
      dataIndex: 'status_details',
      align: 'center',
      title: () => (
        <TableHead>
          Link Clicked
        </TableHead>
      ),
      width: 1,
      render: (value) => <StageCell status={value} stage="link_clicked" />,
    },
    {
      key: 'stage_became_lead',
      dataIndex: 'status_details',
      align: 'center',
      title: () => (
        <TableHead>
          Became Lead
        </TableHead>
      ),
      width: 1,
      render: (value) => <StageCell status={value} stage="became_lead" />,
    },
    {
      key: 'stage_became_client',
      dataIndex: 'status_details',
      align: 'center',
      title: () => (
        <TableHead>
          Became Client
        </TableHead>
      ),
      width: 1,
      render: (value) => <StageCell status={value} stage="became_client" />,
    },
    {
      key: 'stage_closed_deal',
      dataIndex: 'status_details',
      align: 'center',
      title: () => (
        <TableHead>
          Closed Deal
        </TableHead>
      ),
      width: 1,
      render: (value) => <StageCell status={value} stage="closed_deal" />,
    },
    {
      key: 'options',
      dataIndex: 'status_details',
      title: null,
      width: 0.5,
      render: (value, entry) => (
        <OptionsCell />
      ),
    },
  ]
);

export default columns;
