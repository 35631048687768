import apiConnector, { endpoints } from 'api';

/**
 *
 * @param {Object} contactDetailData
 */
const create = async (contactDetailData) => {
  const endpoint = endpoints.external.realtor.contact;
  try {
    const result = await apiConnector.client.post(endpoint, contactDetailData);

    return result;
  } catch (error) {
    console.error(error);
    throw (error);
  }
};

export default create;
