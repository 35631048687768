import { connect } from 'react-redux';

import selectors from 'store/selectors';

import NextViewing from './NextViewing';

const mapStateToProps = (state, ownProps) => {
  const { viewingId } = ownProps;

  const viewing = selectors.propertyViewings.byId(state, viewingId);

  return {
    dateTime: viewing.date_time,
  };
};

export default connect(mapStateToProps)(NextViewing);
