import React from 'react';

import { connect } from 'react-redux';

import selectors from 'store/selectors';

import MatchingBuyerCard from 'sections/Clients/components/MatchingBuyerCardV2';

import KanbanCardControls from 'sections/Clients/modules/KanbanCardControls';

const mapStateToProps = (state, ownProps) => {
  const {
    matchId,
    clientId,
    lane,
  } = ownProps;

  const {
    realtor_contact_id,
  } = selectors.realtorMatchingProperties.byId(matchId)(state);

  return {
    contactId: realtor_contact_id,
    controls: (
      <KanbanCardControls
        matchId={matchId}
        clientId={clientId}
        lane={lane}
      />
    ),
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(MatchingBuyerCard);
