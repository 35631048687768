import styled from 'styled-components';
import Typography from 'ui/Typography';

export const Root = styled.tr`
  position: relative;
  height: 15px;
`;

export const InnerWrapper = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const Title = styled(Typography)`
  margin: 0;
  padding: 6px 5px;
  width: 225px;
  text-align: center;
  border: solid 1px;
  border-color: ${(props) => props.theme?.palette?.border || 'grey'};
  border-radius: 5px;
  background-color: ${({ theme, backgroundColor }) => theme?.palette?.[backgroundColor] || backgroundColor};
`;
