/* eslint-disable react/jsx-no-bind */

import React from 'react';

import { shape } from 'prop-types';

import { useController } from 'react-hook-form';

import Radio from 'ui/Radio';
import Flex from 'ui/Flex';

const Financing = (props) => {
  const {
    control,
  } = props;

  const {
    field: {
      onChange: onChangeFinancing,
      value: financing,
    },
  } = useController({
    name: 'financing',
    control,
  });

  return (
    <Flex
      spacing={2}
      alignItems="center"
    >
      <Radio
        checked={financing === 'pre_approved_loan'}
        value="pre_approved_loan"
        name="pre_approved_loan"
        displayedValue="Pre-Approved Loan"
        onChange={onChangeFinancing}
      />
      <Radio
        checked={financing === 'loan'}
        value="loan"
        name="loan"
        displayedValue="Loan"
        onChange={onChangeFinancing}
      />
      <Radio
        checked={financing === 'cash'}
        value="cash"
        name="cash"
        displayedValue="Cash"
        onChange={onChangeFinancing}
      />
    </Flex>
  );
};

Financing.propTypes = {
  control: shape({}).isRequired,
};

export default Financing;
