import { connect } from 'react-redux';

import {
  updateProperty,
} from 'store/actions/properties';

import Button from 'ui/Button';

const mapStateToProps = (state) => {
  const {

  } = state;

  return {
    variant: 'secondary',
    icon: 'archive',
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: () => {
    dispatch(updateProperty(ownProps.propertyId)({
      status: 2,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Button);
