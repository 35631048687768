import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'antd';

const ActionButton = (props) => {
  const {
    onClick,
  } = props;

  return (
    <Button
      type="primary"
      icon="edit"
      size="small"
      onClick={onClick}
    />
  );
};

const {
  func,
} = PropTypes;

ActionButton.propTypes = {
  onClick: func.isRequired,
};

export default ActionButton;
