import React from 'react'

import Typography from 'ui/Typography'
import Flex from 'ui/Flex'

const style = {
  padding: 10
}

const SymScoreTip = (props) => {
  return (
    <div style={style}>
      <Typography
        variant="title3" 
        weight="bold"
        color="main-text-inversed"
      >
        SymScore
      </Typography>
      <Typography
        color="main-text-inversed"
      >
        Using a large number of data points, we score every lead on their likehood to sell within the next 12 months.
      </Typography>
      <Flex
        justifyContent="space-between"
      >
        <div>
          <Typography
            color="veryLikely"
            align="center"
          >
            8–10
          </Typography>
          <Typography
            color="main-text-inversed"
            align="center"
            noMargin
          >
            Very Likely
          </Typography>
        </div>
        <div>
          <Typography
            color="likely"
            align="center"
          >
            7–7.9
          </Typography>
          <Typography
            color="main-text-inversed"
            align="center"
            noMargin
          >
            Likely
          </Typography>
        </div>
        <div>
          <Typography
            color="unlikely"
            align="center"
          >
            4–6.9
          </Typography>
          <Typography
            color="main-text-inversed"
            align="center"
            noMargin
          >
            Unikely
          </Typography>
        </div>
      </Flex> 
    </div>
  )
}

export default SymScoreTip