import { useForm, useController } from 'react-hook-form';

const useNewEmailCampaign = (defaultValues = {}) => {
  const {
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      type: defaultValues.type || 1,
      name: defaultValues.name || 'My new email campaign',
      templateId: defaultValues.templateId,
      recipients: defaultValues.recipients || [],
      objects: defaultValues.objects || [],
      emailBody: defaultValues.emailBody || null,
      emailSubject: defaultValues.emailBody || null,
    },
  });

  const {
    field: {
      onChange: onEmailTemplateIdSelect,
    },
  } = useController({
    name: 'templateId',
    control,
  });

  const {
    field: {
      onChange: onEmailBodyChange,
    },
  } = useController({
    name: 'emailBody',
    control,
  });

  const {
    field: {
      onChange: onEmailSubjectChange,
    },
  } = useController({
    name: 'emailBody',
    control,
  });

  const {
    field: {
      onChange: onAddObject,
    },
  } = useController({
    name: 'objects',
    control,
  });

  return {
    handleSubmit,
    control,
    onChange: {
      onEmailTemplateIdSelect,
      onEmailBodyChange,
      onEmailSubjectChange,
      onAddObject,
    },
  };
};

export default useNewEmailCampaign;
