import { createSelector } from 'reselect';

import { isEmpty } from 'shared/utility';
import { currency } from '@symplete/symplete-utils/numbers';

const filterObj = (state) => state.crmCompaniesFilter;
const labels = (state) => state.crmCompanies.labels;

const companyTypes = {
  null: 'Not selected',
  all: 'All',
  1: 'Customer',
  2: 'Potential Customer',
  3: 'Previous Customer',
  4: 'New Lead/Prospect',
  5: 'Existing Lead/Prospect',
  6: 'Reseller',
  7: 'Partner',
  8: 'Client',
  9: 'General Contact',
  10: 'Professional Service',
  11: 'Investor',
  12: 'Press & Media',
  13: 'Referral',
  14: 'Introduction',
};

const industryTypes = {
  null: 'Not selected',
  all: 'All',
  1: 'Agriculture & Forestry/Wildlife',
  2: 'Automotive',
  3: 'Business & Information',
  4: 'Construction/Utilities/Contracting',
  5: 'Education',
  6: 'Finance & Insurance',
  7: 'Food & Hospitality',
  8: 'Gaming',
  9: 'Health Services',
  10: 'Natural Resources/Environmental',
  11: 'Other',
  12: 'Personal Services',
  13: 'Real Estate & Housing',
  14: 'Safety/Security & Legal',
  15: 'Transportation',
};

const getCompanyTypesChipValue = (companyType = []) => companyType.map((item) => companyTypes[item]).join(', ');

const getIndustryTypesChipValue = (industryType = []) => industryType.map((item) => industryTypes[item]).join(', ');

const getRevenueRangeChipValue = (revenueRange = []) => `${currency.short(revenueRange[0])}–${currency.short(revenueRange[1])}`; // eslint-disable-line max-len

const getEmployeesRangeChipValue = (employeesRange = []) => `${employeesRange[0]}–${employeesRange[1]}`;

const getLabelsChipValue = (label, allLabels) => label.map((item) => allLabels[item].name).join(', '); // eslint-disable-line max-len, no-shadow

export default createSelector(
  filterObj,
  labels,
  (filter, allLabels) => {
    const chips = [];

    if (!isEmpty(filter.company_type)) {
      chips.push({
        key: 'company_type',
        title: 'Company type',
        value: getCompanyTypesChipValue(filter.company_type, companyTypes),
      });
    }

    if (!isEmpty(filter.industry_type)) {
      chips.push({
        key: 'industry_type',
        title: 'Industry type',
        value: getIndustryTypesChipValue(filter.industry_type, industryTypes),
      });
    }

    if (!isEmpty(filter.revenue_range)) {
      chips.push({
        key: 'revenue_range',
        title: 'Annual revenue',
        value: getRevenueRangeChipValue(filter.revenue_range),
      });
    }

    if (!isEmpty(filter.employees_range)) {
      chips.push({
        key: 'employees_range',
        title: 'Employees',
        value: getEmployeesRangeChipValue(filter.employees_range),
      });
    }

    if (!isEmpty(filter.labels)) {
      chips.push({
        key: 'labels',
        title: 'Labels',
        value: getLabelsChipValue(filter.labels, allLabels),
      });
    }
    return chips;
  },
);
