import styled from 'styled-components';

import { NakedButton } from '@symplete/symplete-ui';

export const StyledButton = styled(NakedButton)`
&& {
  margin: 6px 0 !important;
  p {
    padding: 0;
    margin-bottom: 20px;
    color: #ff6446;
    text-decoration: underline;
  }
}
`;

export const PropertyValueWrapper = styled.div`
  padding: 25px 10px 0;
`;

export default {
  StyledButton,
  PropertyValueWrapper,
};
