import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: visible;
`;

export const IconContainer = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: ${(props) => props.backgroundColor || '#d4eaff'};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
`;

export const TodayMarker = styled.div`
  position: absolute;

  top: 100%;
  left: 50%;

  transform: translateX(-50%) translateY(-150%);

  padding: 8px 11px;

  border: solid 2px #fff; 
  border-radius: 10px;
  background-color: ${props => props.theme.palette.warning};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
`