import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import UserAvatarArrayWithButton from 'shared/components/UserAvatarArrayWithButton';
import UserAvatar from 'shared/components/UserAvatarV2';

const rootClass = 'ticket-assgined-agents';
const avatarSize = 20;
const offset = 10;

class ReminderAssignedAgents extends Component {
  componentDidMount() {

  }

  render() {
    const {
      agents,
      className,
      onAddClick,
      onAgentClick,
      showButton,
    } = this.props;

    const AgentsIconsStyle = {
      width: agents.length * offset + avatarSize + offset * 2,
    };

    return (
      <div
        className={classnames(
          rootClass,
          {
            [className]: className,
          },
        )}
        style={{
          AgentsIconsStyle,
        }}
      >
        <UserAvatarArrayWithButton
          defaultOffset={0}
          offsetItem={offset}
          onClickButton={onAddClick}
          onClickItem={onAgentClick}
          showButton={showButton}
        >
          {
            agents.map((agent) => {
              const {
                id,
              } = agent;
              return (
                <UserAvatar
                  key={id}
                  userType={1}
                  userId={id}
                  tooltipPlacement="bottom"
                  noBadge
                  size="xxs"
                />
              );
            })
          }
        </UserAvatarArrayWithButton>
      </div>
    );
  }
}

export default ReminderAssignedAgents;

ReminderAssignedAgents.defaultProps = {
  agents: [],
  className: null,
  showButton: true,
};

const {
  arrayOf,
  bool,
  func,
  shape,
  string,
} = PropTypes;

const agentShape = shape({
  avatarUrl: string,
  reminderID: string,
  id: string,
  name: string,
  surname: string,
});

ReminderAssignedAgents.propTypes = {
  agents: arrayOf(agentShape),
  className: string,
  onAddClick: func.isRequired,
  onAgentClick: func.isRequired,
  showButton: bool,
};
