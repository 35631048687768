/* eslint-disable react/jsx-no-bind */

import React from 'react';

import { shape } from 'prop-types';

import { useController } from 'react-hook-form';

import Button from 'ui/Button';
import Flex from 'ui/Flex';

const ContactType = (props) => {
  const {
    control,
  } = props;

  const {
    field: {
      onChange: onChangeType,
      value: type,
    },
  } = useController({
    name: 'type',
    control,
  });

  return (
    <Flex
      spacing={2}
      alignItems="center"
    >
      <Button
        variant="secondary"
        style={{ border: type === 'buy' ? '1px solid #367BED' : 'none', padding: '10px 20px' }}
        onClick={() => onChangeType('buy')}
        typographyProps={{
          fontSize: '12px',
        }}
      >
        I&apos;M LOOKING TO BUY
      </Button>
      <Button
        variant="secondary"
        style={{ border: type === 'sell' ? '1px solid #367BED' : 'none', padding: '10px 20px' }}
        onClick={() => onChangeType('sell')}
        typographyProps={{
          fontSize: '12px',
        }}
      >
        I&apos;M LOOKING TO SELL
      </Button>
      <Button
        variant="secondary"
        style={{ border: type === 'contact' ? '1px solid #367BED' : 'none', padding: '10px 20px' }}
        onClick={() => onChangeType('contact')}
        typographyProps={{
          fontSize: '12px',
        }}
      >
        GENERAL CONTACT
      </Button>
    </Flex>
  );
};

ContactType.propTypes = {
  control: shape({}).isRequired,
};

export default ContactType;
