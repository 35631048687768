import {
  useReducer,
} from 'react';

import requests from 'api/requests';

import { memoizeDebounce } from 'shared/utility';

const initialState = {
  details: {},
  isLoading: {},
  error: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setProperty':
      return {
        ...state,
        details: {
          ...state.details,
          [action.payload.propertyId]: action.payload.propertyDetails,
        },
      };
    case 'setLoading':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.payload.propertyId]: action.payload.isLoading,
        },
      };
    case 'setError':
      return {
        ...state,
        error: {
          ...state.error,
          [action.payload.propertyId]: action.payload.error,
        },
      };
    default:
      return state;
  }
};

const usePropertyDetails = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getDetails = memoizeDebounce(
    async (propertyId) => {
      if (state.details[propertyId]) return;

      dispatch({
        type: 'setLoading',
        payload: {
          propertyId,
          isLoading: true,
        },
      });

      try {
        const result = await requests.globalProperties.item(propertyId);

        dispatch({
          type: 'setProperty',
          payload: {
            propertyId,
            propertyDetails: result.data,
          },
        });

        dispatch({
          type: 'setError',
          payload: {
            propertyId,
            error: null,
          },
        });
      } catch (error) {
        dispatch({
          type: 'setError',
          payload: {
            propertyId,
            error,
          },
        });
      } finally {
        dispatch({
          type: 'setLoading',
          payload: {
            propertyId,
            isLoading: false,
          },
        });
      }
    },
    1000,
    {
      leading: true,
      trailing: false,
    },
  );

  return {
    details: state.details,
    isLoading: state.isLoading,
    error: state.error,
    getDetails,
  };
};

export default usePropertyDetails;
