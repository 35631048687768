import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';
import Tooltip from 'ui/Tooltip';

import ContactAvatar from 'sections/Prospects/modules/ContactAvatar';

import useContactName from 'shared/hooks/contacts/useContactName';

const descriptionStyle = {
  overflow: 'hidden',
};

const MainContactInfo = (props) => {
  const {
    contactId,
  } = props;

  const name = useContactName(contactId);

  return (
    <Flex
      spacing={1}
    >
      <ContactAvatar
        contactId={contactId}
        size={45}
      />
      <Flex
        flexDirection="column"
        spacing={0.5}
        style={descriptionStyle}
      >
        <Tooltip title={name}>
          <Typography
            variant="title3"
            weight="bold"
            noMargin
            ellipsis
          >
            {name}
          </Typography>
        </Tooltip>
      </Flex>
    </Flex>
  );
};

const {
  number,
} = PropTypes;

MainContactInfo.propTypes = {
  contactId: number.isRequired,
};

export default MainContactInfo;
