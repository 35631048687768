import React, {
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import GroupEmails from './GroupEmails';

const MarketingSelect = (props) => {
  const {
    onChooseTemplate,
    onCreateTemplate,
    selectedTemplateId,
    newTemplateName,
    setNewTemplateName
  } = props;

  return (
    <>
      <GroupEmails
        onChooseTemplate={onChooseTemplate}
        onCreateTemplate={onCreateTemplate}
        selectedTemplateId={selectedTemplateId}
        newTemplateName={newTemplateName}
        newSaveTemplate={setNewTemplateName}
      />
    </>
  );
};

const {
  func,
  arrayOf,
  shape,
  string,
  bool
} = PropTypes;

MarketingSelect.propTypes = {
  emailTemplates: arrayOf(shape()).isRequired,
  onChooseTemplate: func.isRequired,
  onCreateTemplate: func.isRequired,
  selectedTemplateId: string,
  newTemplateName: string,
  setNewTemplateName: bool,
};

export default MarketingSelect;
