import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import UploadContactAvatar from 'sections/Contacts/modules/UploadContactAvatar';
import PersonTitleSelect from 'sections/Contacts/modules/PersonTitleSelect';
import PastClientCheckBox from 'sections/Contacts/modules/PastClientInput';
import FirstNameInputRaw from 'sections/Contacts/modules/FirstNameInput';
import LastNameInputRaw from 'sections/Contacts/modules/LastNameInput';
import EmailInputRaw from 'sections/Contacts/modules/EmailInput';
import PhoneInput from 'sections/Contacts/modules/PhoneInput';
import SourceSelect from 'sections/Contacts/forms/NewContactForm/SourceSelect';
import DateInput from 'sections/Contacts/modules/DateInput';

import withNewContactFormDefaultValue from 'sections/Contacts/hocs/withNewContactFormDefaultValue';

import JobRoleInputRaw from './JobRoleInput';
import CompanyNameInputRaw from './CompanyNameInput';
import ContactNoteRaw from './ContactNote';
import ContactLabelsInputRaw from './ContactLabelsInput';

import {
  Wrapper,
} from './styledItems';

const FirstNameInput = withNewContactFormDefaultValue(FirstNameInputRaw)('personFirstName');
const LastNameInput = withNewContactFormDefaultValue(LastNameInputRaw)('personLastName');
const EmailInput = withNewContactFormDefaultValue(EmailInputRaw)('mainEmail');

const JobRoleInput = withNewContactFormDefaultValue(JobRoleInputRaw)('jobRole');
const CompanyNameInput = withNewContactFormDefaultValue(CompanyNameInputRaw)('companyName');
const ContactNote = withNewContactFormDefaultValue(ContactNoteRaw)('contactNote');
const ContactLabelsInput = withNewContactFormDefaultValue(ContactLabelsInputRaw)('contactLabels');

const NewContactForm = ({ withSalutations, saveAttempt }) => (
  <Wrapper>
    <form>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          xs={12}
        >
          {/* <DecoratedFieldWrapper
            iconName="image"
            variant="focus"
          > */}
          <UploadContactAvatar />
          {/* </DecoratedFieldWrapper> */}
        </Grid>

        <Grid
          item
          xs={12}
        >
          {/* <DecoratedFieldWrapper
            iconName="users"
            variant="focus"
          > */}
          <Grid container spacing={1}>
            {
              withSalutations && (
                <Grid item xs={3}>
                  <PersonTitleSelect />
                </Grid>
              )
            }
            <Grid item xs={4}>
              <FirstNameInput saveAttempt={saveAttempt} />
            </Grid>
            <Grid item xs={5}>
              <LastNameInput />
            </Grid>
          </Grid>
          {/* </DecoratedFieldWrapper> */}
        </Grid>

        <Grid
          item
          xs={12}
        >
          {/* <DecoratedFieldWrapper
            iconName="id-badge"
            variant="focus"
          > */}
          <JobRoleInput />
          {/* </DecoratedFieldWrapper> */}
        </Grid>

        <Grid
          item
          xs={12}
        >
          {/* <DecoratedFieldWrapper
            iconName="envelope"
            variant="focus"
          > */}
          <EmailInput />
          {/* </DecoratedFieldWrapper> */}
        </Grid>

        <Grid
          item
          xs={12}
        >
          {/* <DecoratedFieldWrapper
            iconName="phone"
            variant="focus"
          > */}
          <PhoneInput />
          {/* </DecoratedFieldWrapper> */}
        </Grid>

        <Grid item xs={12}>
          {/* <DecoratedFieldWrapper
            iconName="building"
            variant="focus"
          > */}
          <CompanyNameInput />
          {/* </DecoratedFieldWrapper> */}
        </Grid>

        <Grid item xs={12}>
          {/* <DecoratedFieldWrapper
            iconName="users"
            variant="focus"
          > */}
          <ContactLabelsInput />
          {/* </DecoratedFieldWrapper> */}
        </Grid>

        <Grid
          item
          xs={12}
        >
          <Grid container spacing={1}>
            <Grid item xs={5}>
              {/* <DecoratedFieldWrapper
                iconName="search"
                variant="focus"
              > */}
              <SourceSelect />
              {/* </DecoratedFieldWrapper> */}
            </Grid>

            <Grid item xs={12}>
              <PastClientCheckBox />
            </Grid>

            <Grid item xs={7}>
              {/* <DecoratedFieldWrapper
                iconName="file-alt"
                variant="focus"
              > */}
              <DateInput />
              {/* </DecoratedFieldWrapper> */}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {/* <DecoratedFieldWrapper
            iconName="newspaper"
            variant="focus"
          > */}
          <ContactNote />
          {/* </DecoratedFieldWrapper> */}
        </Grid>

      </Grid>
    </form>
  </Wrapper>
);

const { bool } = PropTypes;

NewContactForm.defaultProps = {
  saveAttempt: false,
};

NewContactForm.propTypes = {
  withSalutations: bool.isRequired,
  saveAttempt: bool,
};

export default NewContactForm;
