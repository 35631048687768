import React from 'react';

import PropTypes from 'prop-types';

import ErrorBoundary from 'ui/ErrorBoundary';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Icon from 'ui/Icon';

import './ExternalContactOverview.scss';

const ExternalContactOverview = ({
 contactData, propertyData, onSwitch,
}) => {
  const { name, avatarUrl } = contactData;
  const propertyState = propertyData?.propertyState ?? 'residential';
  const transferState = propertyData?.transferState ?? 'sale';

  return (
    <ErrorBoundary>
      <Flex spacing={2} alignItems="center" style={{ padding: '0px 20px' }}>
        <Flex fullWidth spacing={2}>
          {avatarUrl ? (
            <img src={avatarUrl} alt={name} />
          ) : (
            <div
              style={{
                background: 'white',
                padding: '8px',
                borderRadius: '8px',
              }}
            >
              <Icon name="profile" type="custom" color="#F2F6FE" size={32} />
            </div>
          )}

          <FlexItem flex={1}>
            <div>
              <h1
                style={{
                  fontFamily: 'Comfortaa, sans-serif',
                  fontWeight: 700,
                  fontSize: '32px',
                  lineHeight: '32px',
                  margin: '0px',
                  color: 'white',
                }}
              >
                {name}
              </h1>
              <span
                style={{
                  fontSize: '13px',
                  color: 'white',
                  fontFamily: 'Poppins, sans-serif',
                }}
              >
                PRODUCT PRESENTED BY
                {' '}
                <a
                  href="https://symplete.com"
                  style={{ color: 'white' }}
                  target="_blank"
                  rel="noreferrer"
                >
                  SYMPLETE
                </a>
              </span>
            </div>
          </FlexItem>
        </Flex>
        <Flex
          fullWidth
          spacing={2}
          justifyContent="flex-end"
          className="header-actions"
        >
          {propertyState === 'residential'
            && transferState === 'sale' ? (
              <button type="button" onClick={() => onSwitch('request')}>REQUEST SHOWING</button>
            ) : (
              <button type="button" onClick={() => onSwitch('request')}>REQUEST TOUR</button>
            )}
          {(propertyState === 'commercial'
            || transferState === 'sale') && (
            <button type="button" onClick={() => onSwitch('offer')}>MAKE AN OFFER</button>
          )}
          <button type="button" onClick={() => onSwitch('contact')}>CONTACT US</button>
        </Flex>
      </Flex>
    </ErrorBoundary>
  );
};

const { shape } = PropTypes;

ExternalContactOverview.propTypes = {
  contactData: shape({}).isRequired,
  propertyData: shape({}).isRequired,
};

export default ExternalContactOverview;
