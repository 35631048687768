import { connect } from 'react-redux';

import selectors from 'store/selectors';

import ListingAgreement from './ListingAgreement';

const mapStateToProps = (state, ownProps) => {
  const {
    propertyId,
  } = ownProps;

  const property = selectors.properties.byId(state, propertyId);

  const isMyListing = property?.listing_agent === 0;

  return {
    isMyListing,
  };
};

export default connect(mapStateToProps)(ListingAgreement);
