import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import { Spin } from 'antd';

import Typography from 'ui/Typography';
import Checkbox from 'ui/Checkbox';
import Button from 'ui/Button';
import Modal from 'ui/Modal';
import Textfield from 'ui/Textfield';
import DropdownTextfield from 'ui/DropdownTextfield';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import FacebookLogin from 'shared/modules/FacebookLogin';

import useLocalStorageItem from 'shared/hooks/useLocalStorageItem';
import useInitFbSDK from 'shared/hooks/useInitFbSDK';

import isSectionUnderConstruction from 'shared/utils/isSectionUnderConstruction';

import getPageInstagaramId from './getPageInstagaramId';

const FacebookPost = (props) => {
  if (isSectionUnderConstruction('facebook-integration')) {
    return (
      null
    );
  }

  const {
    fbUserPages,
    propertyAddress,
    createFacebookPost,
    createInstagramPost,
  } = props;

  const fbUserAccessToken = useLocalStorageItem('fbLongLivedUserAccessToken');
  const isFbSDKInitialized = useInitFbSDK();

  const [postText, setPostText] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pagesOptions, setPagesOptions] = useState([]);
  const [selectedPageId, setSelectedPageId] = useState(null);
  const [instagramAccountId, setInstagramAccountId] = useState(null);

  const [isPublishingToFacebook, setIsPublishingToFacebook] = useState(false);
  const [isPublishingToInstagram, setIsPublishingToInstagram] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [shouldPostToFacebook, setShouldPostToFacebook] = useState(false);
  const [shouldPostToInstagram, setShouldPostToInstagram] = useState(false);

  const [shouldAddLeadFormLink, setShouldAddLeadFormLink] = useState(false);

  const [postButtonText, setPostButtonText] = useState('Post');

  useEffect(() => {
    if (propertyAddress) {
      setPostText(`Property for sale at ${propertyAddress}\n\n`);
    }
  }, [
    propertyAddress,
  ]);

  useEffect(() => {
    if (Array.isArray(fbUserPages)) {
      const options = fbUserPages.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      setPagesOptions(options);
      setSelectedPageId(fbUserPages[0]?.id);
    } else {
      setPagesOptions([]);
      setSelectedPageId(null);
    }
  }, [
    JSON.stringify(fbUserPages),
  ]);

  useEffect(() => {
    if (!isFbSDKInitialized) {
      return;
    }

    setIsLoading(true);

    getPageInstagaramId({
      pageId: selectedPageId,
      pages: fbUserPages,
      onSuccess: (instagramId) => {
        setInstagramAccountId(instagramId);
        setIsLoading(false);
      },
      onError: () => {
        setInstagramAccountId(null);
        setIsLoading(false);
      },
    });

    setShouldPostToFacebook(true);
  }, [
    JSON.stringify(fbUserPages),
    selectedPageId,
  ]);

  useEffect(() => {
    if (!instagramAccountId) {
      setShouldPostToInstagram(false);
    }
  }, [
    instagramAccountId,
  ]);

  useEffect(() => {
    if ((shouldPostToFacebook && shouldPostToInstagram) || (!shouldPostToFacebook && !shouldPostToInstagram)) {
      setPostButtonText('Post');
    }
    if (shouldPostToFacebook && !shouldPostToInstagram) {
      setPostButtonText('Post to Facebook');
    }
    if (!shouldPostToFacebook && shouldPostToInstagram) {
      setPostButtonText('Post to Instagram');
    }
  }, [
    shouldPostToFacebook,
    shouldPostToInstagram,
  ]);

  useEffect(() => {
    if (!isPublishingToFacebook && !isPublishingToInstagram) {
      setIsModalVisible(false);
    }
  }, [
    isPublishingToFacebook,
    isPublishingToInstagram,
  ]);

  const toggleModal = useCallback((open) => () => {
    setIsModalVisible(open);
  }, []);

  const sendPostToPage = useCallback(() => {
    if (!selectedPageId) {
      return;
    }

    if (shouldPostToFacebook) {
      setIsPublishingToFacebook(true);

      createFacebookPost({
        pageId: selectedPageId,
        text: postText,
        shouldAddLeadFormLink,
        onSuccess: () => {
          setIsPublishingToFacebook(false);
        },
        onError: () => {
          setIsPublishingToFacebook(false);
        },
      });
    }

    if (shouldPostToInstagram) {
      setIsPublishingToInstagram(true);

      createInstagramPost({
        fbUserAccessToken,
        instagramAccountId,
        text: postText,
        shouldAddLeadFormLink,
        onSuccess: () => {
          setIsPublishingToInstagram(false);
        },
        onError: () => {
          setIsPublishingToInstagram(false);
        },
      });
    }
  }, [
    fbUserAccessToken,
    selectedPageId,
    instagramAccountId,
    postText,
    shouldAddLeadFormLink,
    shouldPostToFacebook,
    shouldPostToInstagram,
    createFacebookPost,
    createInstagramPost,
  ]);

  return (
    <div>
      <Button
        onClick={toggleModal(true)}
        variant="primary"
        icon="facebook"
        iconColor="contrast"
      >
        Post to social media
      </Button>

      <Modal
        width={600}
        visible={isModalVisible}
        onCancel={toggleModal(false)}
        footer={null}
        title="Post this property to a Facebook page"
      >
        <Spin spinning={isPublishingToFacebook || isPublishingToInstagram || isLoading}>
          <Flex
            flexDirection="column"
            spacing={4}
            fullWidth
          >
            <FlexItem>
              <FacebookLogin />
            </FlexItem>

            {
              fbUserAccessToken && (
                <FlexItem fullWidth>
                  <DropdownTextfield
                    options={pagesOptions}
                    value={selectedPageId}
                    onChange={setSelectedPageId}
                    label="Select a page you want to use"
                    fullWidth
                  />
                </FlexItem>
              )
            }

            {
              fbUserAccessToken && selectedPageId && (
                <Flex
                  spacing={2}
                  flexDirection="column"
                  alignItems="center"
                  fullWidth
                >
                  <FlexItem fullWidth>
                    <Textfield
                      textarea
                      fullWidth
                      value={postText}
                      onChange={setPostText}
                    />
                  </FlexItem>
                  <FlexItem fullWidth>
                    <Flex
                      spacing={1}
                      alignItems="center"
                      fullWidth
                    >
                      <FlexItem item>
                        <Checkbox
                          checked={shouldAddLeadFormLink}
                          onChange={setShouldAddLeadFormLink}
                        />
                      </FlexItem>
                      <FlexItem item>
                        <Typography
                          noMargin
                        >
                          Add lead form link
                        </Typography>
                      </FlexItem>
                    </Flex>
                  </FlexItem>

                  {
                    instagramAccountId && (
                      <FlexItem fullWidth>
                        <Flex
                          spacing={3}
                          fullWidth
                        >
                          <FlexItem>
                            <Flex
                              spacing={1}
                              alignItems="center"
                            >
                              <FlexItem item>
                                <Checkbox
                                  checked={shouldPostToFacebook}
                                  onChange={setShouldPostToFacebook}
                                />
                              </FlexItem>
                              <FlexItem item>
                                <Typography
                                  noMargin
                                >
                                  Post to Facebook
                                </Typography>
                              </FlexItem>
                            </Flex>
                          </FlexItem>

                          <FlexItem>
                            <Flex
                              spacing={1}
                              alignItems="center"
                            >
                              <FlexItem item>
                                <Checkbox
                                  checked={shouldPostToInstagram}
                                  onChange={setShouldPostToInstagram}
                                />
                              </FlexItem>
                              <FlexItem item>
                                <Typography
                                  noMargin
                                >
                                  Post to Instagram
                                </Typography>
                              </FlexItem>
                            </Flex>
                          </FlexItem>
                        </Flex>
                      </FlexItem>
                    )
                  }

                  <FlexItem>
                    <Button
                      onClick={sendPostToPage}
                      variant="primary"
                      disabled={!shouldPostToFacebook && !shouldPostToInstagram}
                    >
                      {postButtonText}
                    </Button>
                  </FlexItem>
                </Flex>
              )
            }

          </Flex>
        </Spin>
      </Modal>
    </div>
  );
};

FacebookPost.defaultProps = {
  fbUserPages: [],
  propertyAddress: null,
};

const {
  string,
  arrayOf,
  shape,
  func,
} = PropTypes;

FacebookPost.propTypes = {
  propertyAddress: string,
  fbUserPages: arrayOf(shape({
    id: string,
    name: string,
    access_token: string,
  })),
  createFacebookPost: func.isRequired,
  createInstagramPost: func.isRequired,
};

export default FacebookPost;
