import React from 'react';
import PropTypes from 'prop-types';

import PropertyCard from 'sections/Opportunities/modules/PropertyCard';
import ProspectCard from 'sections/Opportunities/modules/ProspectCard';

const byType = {
  client: PropertyCard,
  prospect: ProspectCard,
};

const KanbanCard = (props) => {
  const {
    type,
  } = props;

  const CardComponent = byType[type];

  if (!CardComponent) return null;

  return (
    <CardComponent
      {...props}
    />
  );
};

KanbanCard.defaultProps = {

};

const {
  oneOf,
} = PropTypes;

KanbanCard.propTypes = {
  type: oneOf(['client', 'prospect']),
};

export default KanbanCard;
