import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex'

import Location from 'sections/Opportunities/modules/Table/Location';
import LocationRequired from 'sections/Opportunities/modules/Table/LocationRequired';

const LocationCell = (props) => {
  const {
    type,
    item,
  } = props;

  if (type === 'client') {
    return (
      <Flex fullWidth>
        <Location propertyId={item.realtor_property_id} />
      </Flex> 
    );
  }

  if (type === 'prospect') {
    return (
      <Flex fullWidth>
        <LocationRequired propertyRequirementId={item.realtor_property_requirement_id} />
      </Flex>
    );
  }
};

LocationCell.defaultProps = {

};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

LocationCell.propTypes = {

};

export default LocationCell;
