import React from 'react';
import { shape } from 'prop-types';

import { useController } from 'react-hook-form';

import Textfield from 'ui/Textfield';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import PhoneInput from 'shared/inputs/PhoneInput';
import EmailInput from 'shared/inputs/EmailInput';

const UserDetails = (props) => {
  const { control } = props;

  const {
    field: { onChange: onChangeFirstName, value: firstName },
  } = useController({
    name: 'firstName',
    control,
  });

  const {
    field: { onChange: onChangeLastName, value: lastName },
  } = useController({
    name: 'lastName',
    control,
  });

  const {
    field: { onChange: onChangePhone, value: phone },
  } = useController({
    name: 'phone',
    control,
  });

  const {
    field: { onChange: onChangeEmail, value: email },
  } = useController({
    name: 'email',
    control,
  });

  return (
    <>
      <Flex spacing={2} fullWidth>
        <FlexItem xs={12}>
          <Textfield
            placeholder="First name"
            onChange={onChangeFirstName}
            value={firstName}
          />
        </FlexItem>
        <FlexItem xs={12}>
          <Textfield
            placeholder="Last name"
            onChange={onChangeLastName}
            value={lastName}
          />
        </FlexItem>
      </Flex>
      <Flex spacing={2} fullWidth>
        <FlexItem xs={12}>
          <EmailInput
            placeholder="Email"
            handleChange={onChangeEmail}
            value={email}
          />
        </FlexItem>
        <FlexItem xs={12}>
          <PhoneInput
            placeholder="Phone"
            value={phone}
            handleChange={onChangePhone}
          />
        </FlexItem>
      </Flex>
    </>
  );
};

UserDetails.propTypes = {
  control: shape({}).isRequired,
};

export default UserDetails;
