import { connect } from 'react-redux';
import store from 'store';
import selectors from 'store/selectors';

import {
  setSelectedTransaction,
} from 'store/actions/realtorTransactions';

import TransactionsItem from './TransactionsItem';

const mapStateToProps = (state, ownProps) => {
  const {
    realtorTransactions: {
      selectedTransaction,
    },
  } = state;

  const { id } = ownProps.match.params;

  if (selectedTransaction !== id) {
    store.dispatch(setSelectedTransaction(id));
  }

  let propertyId;

  const transaction = selectors.realtorTransactions.selected(state);

  if (transaction.realtor_property_id) {
    propertyId = transaction.realtor_property_id;
  } else if (transaction.realtor_client_id) {
    const client = selectors.realtorClients.byId(state, transaction.realtor_client_id);
    propertyId = client?.realtor_property_id;
  }

  const settings = selectors.appCustomization.settings(state);

  return {
    selectedTransaction,
    propertyId,
    isCompleted: transaction?.status === 6,
    attorneyOrEscrow: settings.attorney_state_or_escrow_state,
  };
};

export default connect(mapStateToProps)(TransactionsItem);
