import { connect } from 'react-redux';

import {
  openNewPropertyViewingModal,
} from 'store/actions/newRealtorPropertyViewingModal';

import Button from 'ui/Button';

const mapStateToProps = (state) => ({
    children: 'Book Showing',
    variant: 'primary',
    icon: 'eye',
  });

const mapDispatchToProps = (dispatch) => ({
  onClick: () => {
    dispatch(openNewPropertyViewingModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Button);
