/* eslint-disable no-nested-ternary */

import React from 'react';

import PropTypes from 'prop-types';

import { datetime } from '@symplete/symplete-utils';
import { between, ago } from '@symplete/symplete-utils/datetime';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';

const ClosingDate = (props) => {
  const {
    date,
    bestClose,
  } = props;

  const dayValue = between(datetime(), date, 'day');

  return (
    <>
      {
        dayValue > 0
        ? (

          !bestClose
          ? (
            <Typography
              variant="cell"
            >
              {`${dayValue} days`}
            </Typography>
            )
          : (
            <Flex
              flexDirection="column"
              spacing={1}
            >
              <Typography
                variant="cell2"
                color="success"
                weight="bold"
              >
                BEST CLOSE!
              </Typography>
              <Typography
                variant="cell"
                noMargin
                color="success"
              >
                {`${dayValue} days`}
              </Typography>
            </Flex>
            )

          )
        : (
          <Flex
            flexDirection="column"
            spacing="1"
          >
            <Typography
              variant="cell"
              color="alert"
            >
              Closed
            </Typography>
            <Typography
              variant="cell2"
              color="alert"
            >
              {ago(date)}
            </Typography>
          </Flex>
          )
      }

    </>
  );
};

ClosingDate.defaultProps = {
  date: null,
  bestClose: null,
};

const {
  string,
  bool,
} = PropTypes;

ClosingDate.propTypes = {
  date: string,
  bestClose: bool,
};

export default ClosingDate;
