import styled from 'styled-components';

export const IconContainer = styled.div`
  width: 110px;
  height: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 15px 5px 5px;
  border-radius: 8px;
  border: solid 1px;
  border-color: ${(props) => (props.theme.palette?.border || 'lightgray')};
  background-color: ${(props) => (props.theme.palette?.['background-flat'] || 'lightgray')};

  &:hover {
    filter: contrast(105%);
  }
`;

export default {
  IconContainer,
};
