import React, {
  useCallback,
  useState,
} from 'react';

import PropTypes from 'prop-types';

import {
  DragDropContext,
  Droppable,
} from 'react-beautiful-dnd';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Typography from 'ui/Typography';

import useModal from 'ui/hooks/useModal';

import KanbanView from 'sections/Prospects/modules/MatchingPropertiesViewV2/KanbanView';
import RejectedProperties from 'sections/Prospects/modules/MatchingPropertiesViewV2/RejectedProperties';

import NewOfferModal from 'sections/Prospects/modals/NewOfferModal';

const statuses = {
  assigned: 1,
  showingsUpcoming: 2,
  showingsCompleted: 2,
  offers: 3,
  rejected: 5,
};

const MatchingPropertiesView = (props) => {
  const {
    onAssignProperty,
    onCreateShowing,

    prospectId,
    updateMatchingProperty,
  } = props;

  const {
    openModal: openOfferModal,
    closeModal: closeOfferModal,
    isModalVisible: isOfferModalVisible,
  } = useModal();

  const [currentMatch, setCurrentMatch] = useState(null);

  const onMatchingPropertyDrop = useCallback((dragParams) => {
    const {
      draggableId: matchingPropertyId,
      destination,
      source,
    } = dragParams;

    if (destination?.droppableId !== source?.droppableId) {
      const oldStatus = statuses[source?.droppableId];
      const newStatus = statuses[destination?.droppableId];

      setCurrentMatch(matchingPropertyId);

      if (newStatus === statuses.offers) {
        openOfferModal();
      } else if (newStatus) {
        updateMatchingProperty(matchingPropertyId)(
          // update data
          { status: newStatus },
          // current data
          { status: oldStatus },
          // board data
          {
            source,
            destination,
          },
        );
      }
    }
  }, [
    updateMatchingProperty,
    setCurrentMatch,
    openOfferModal,
  ]);

  return (
    <>

      <DragDropContext
        onDragEnd={onMatchingPropertyDrop}
      >
        <Flex
          spacing={4}
          flexWrap="wrap"
          fullHeight
        >
          <KanbanView
            prospectId={prospectId}
            onAdd={{
              assigned: onAssignProperty,
              showingsUpcoming: onCreateShowing('upcoming'),
              showingsCompleted: onCreateShowing('completed'),
            }}
          />

          <FlexItem fullWidth>
            <Typography
              variant="title1"
              tag="h3"
            >
              Rejected Properties
            </Typography>
            <Droppable droppableId="rejected">
              {
                (provided) => (
                  <div ref={provided.innerRef}>
                    <RejectedProperties
                      prospectId={prospectId}
                    />
                  </div>
                )
              }
            </Droppable>
          </FlexItem>
        </Flex>
      </DragDropContext>

      <NewOfferModal
        isVisible={isOfferModalVisible}
        closeModal={closeOfferModal}
        prospectId={prospectId}
        matchId={currentMatch}
      />
    </>
  );
};

const {
  func,
  number,
} = PropTypes;

MatchingPropertiesView.propTypes = {
  onAssignProperty: func.isRequired,
  onCreateShowing: func.isRequired,

  prospectId: number.isRequired,
  updateMatchingProperty: func.isRequired,
};

export default MatchingPropertiesView;
