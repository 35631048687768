import React from 'react';

import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import OurThemeProvider from 'shared/themes/OurThemeProvider';
import withTheme from 'shared/hocs/withTheme';

import List from 'sections/Opportunities/pages/List';
// import ProspectItem from 'sections/Opportunities/pages/ProspectItem';

import routes from 'sections/Opportunities/routes';

import { defaultTheme } from '@symplete/symplete-ui';
import theme from './theme';

const Opportunities = () => {
  const Theme = withTheme(defaultTheme, theme)(OurThemeProvider);

  return (
    <Theme>
      <Switch>
        <Route
          exact
          path={routes.sectionRoot}
          component={List}
        />
        {/* <Route
          path={routes.prospect}
          component={ProspectItem}
        /> */}

        <Redirect to={routes.sectionRoot} />
      </Switch>
    </Theme>
  );
};

export default Opportunities;
