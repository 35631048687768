import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import CounterItemWithButton from 'shared/components/UI/CounterItemWithButton';

import Grid from 'ui/Grid';

import { map } from 'shared/utility';

const overviewItems = {
  total_inspections: {
    name: 'Total',
    withActionButton: false,
  },
  scheduled_inspections: {
    name: 'Scheduled',
    withActionButton: false,
  },
  completed_inspections: {
    name: 'Completed',
    withActionButton: false,
  },
};

const Overview = (props) => {
  const {
    data,
  } = props;

  return (
    <div>
      <Grid container spacing={2}>
        {
          map(overviewItems, (value, key) => (
            <Fragment key={key}>
              <Grid item xs="auto">
                <CounterItemWithButton
                  title={value.name}
                  value={data[key] || 0}
                  withActionButton={value.withActionButton}
                />
              </Grid>
            </Fragment>
          ))
        }
      </Grid>
    </div>
  );
};

const {
  shape,
  number,
} = PropTypes;

Overview.propTypes = {
  data: shape({
    total_repair_requests: number,
    accepted_repair_requests: number,
    declined_repair_requests: number,
  }).isRequired,
};

export default Overview;
