import {
  useState,
  useEffect,
  useCallback,
} from 'react';

import requests from 'api/requests';

const getTemplate = (onSuccess, onError) => async (templateId) => {
  try {
    const response = await requests.integrations.emailTemplates.get(templateId);

    onSuccess(response?.data);
  } catch (newError) {
    onError(newError);
  }
};

const updateTemplate = (onSuccess, onError) => (id) => async (templateData) => {
  try {
    const result = await requests.integrations.emailTemplates.update(id)(templateData);
    onSuccess(result);
  } catch (error) {
    onError(error);
  }
};

const removeTemplate = (onSuccess, onError) => async (id) => {
  try {
    const result = await requests.integrations.emailTemplates.remove(id);

    onSuccess(result);
  } catch (error) {
    onError(error);
  }
};

const useTemplate = (templateId) => {
  const [template, setTemplate] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const [getError, setGetError] = useState(null);
  const [updateError, setUpdateError] = useState(null);
  const [removeError, setRemoveError] = useState(null);

  const get = useCallback(async () => {
    setGetError(null);
    setIsLoading(true);

    await getTemplate(setTemplate, setGetError)(templateId);

    setIsLoading(false);
  }, [
    templateId,
  ]);

  const update = useCallback(async (templateData) => {
    setUpdateError(null);
    setIsUpdating(true);

    await updateTemplate(() => get(templateId), setUpdateError)(templateId)(templateData);

    setIsUpdating(false);
  }, [
    templateId,
  ]);

  const remove = useCallback(async () => {
    setRemoveError(null);
    setIsRemoving(true);

    await removeTemplate(() => {}, setRemoveError)(templateId);
    setIsRemoving(false);
  }, [
    templateId,
  ]);

  useEffect(() => {
    get(templateId);
  }, [
    templateId,
  ]);

  return {
    template: template || {},

    isLoading,
    isUpdating,
    isRemoving,

    get,
    update,
    remove,

    getError,
    updateError,
    removeError,
  };
};

export default useTemplate;
