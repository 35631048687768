import React, {
  useState,
  useCallback,
} from 'react';

import isSectionUnderConstruction from 'shared/utils/isSectionUnderConstruction';

import TabLabel from 'ui/TabLabel';
import Tabs from 'ui/Tabs';
import Tab from 'ui/Tab';
import TabContext from 'ui/TabContext';
import TabPanel from 'ui/TabPanel';

import Integrations from 'sections/Integrations';

import General from './General';
import AppCustomization from './AppCustomization';

import {
  Root,
} from './styledItems';

const Settings = () => {
  const [tab, setTab] = useState('general');

  const handleTabChange = useCallback((event, value) => {
    setTab(value);
  }, []);

  return (
    <Root>
      <TabContext value={tab}>
        <Tabs
          variant="fullWidth"
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
        >
          <Tab
            label={(
              <TabLabel
                isSelected={tab === 'general'}
                variant="title2"
                weight={400}
                weightSelected={700}
              >
                General
              </TabLabel>
            )}
            value="general"
            withBottomBorder
          />
          {
            !isSectionUnderConstruction('user-management-settings') && (
              <Tab
                label={(
                  <TabLabel
                    isSelected={tab === 'user management'}
                    variant="title2"
                    weight={400}
                    weightSelected={700}
                  >
                    User Management
                  </TabLabel>
                )}
                value="user management"
                withBottomBorder
              />
            )
          }
          {
            !isSectionUnderConstruction('billing-settings') && (
              <Tab
                label={(
                  <TabLabel
                    isSelected={tab === 'billing'}
                    variant="title2"
                    weight={400}
                    weightSelected={700}
                  >
                    Billing
                  </TabLabel>
                )}
                value="billing"
                withBottomBorder
              />
            )
          }
          {
            !isSectionUnderConstruction('app-customization') && (
              <Tab
                label={(
                  <TabLabel
                    isSelected={tab === 'app customization'}
                    variant="title2"
                    weight={400}
                    weightSelected={700}
                  >
                    App Customization
                  </TabLabel>
                )}
                value="app customization"
                withBottomBorder
              />
            )
          }
          {
            !isSectionUnderConstruction('integrations') && (
              <Tab
                label={(
                  <TabLabel
                    isSelected={tab === 'integrations'}
                    variant="title2"
                    weight={400}
                    weightSelected={700}
                  >
                    Integrations
                  </TabLabel>
                )}
                value="integrations"
                withBottomBorder
              />
            )
          }
        </Tabs>

        <TabPanel
          value="general"
          index={0}
        >
          <General />
        </TabPanel>
        <TabPanel
          value="user management"
          index={1}
        >
          {null}
        </TabPanel>
        <TabPanel
          value="billing"
          index={2}
        >
          {null}
        </TabPanel>
        <TabPanel
          value="app customization"
          index={3}
        >
          <AppCustomization />
        </TabPanel>
        <TabPanel
          value="integrations"
          index={4}
        >
          <Integrations />
        </TabPanel>
      </TabContext>
    </Root>
  );
};

export default Settings;
