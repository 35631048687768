import React, {
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import ErrorBoundary from 'ui/ErrorBoundary';
import Button from 'ui/Button';

import useInitFbSDK from 'shared/hooks/useInitFbSDK';
import useLocalStorageItem from 'shared/hooks/useLocalStorageItem';

import updateLocalStorage from 'shared/utils/updateLocalStorageWithCustomEvent';

// eslint-disable-next-line max-len
const permissions = 'public_profile,pages_show_list,pages_read_engagement,pages_read_user_content,pages_manage_posts,instagram_basic,instagram_content_publish';

const FacebookLogin = (props) => {
  const {
    userID,
    setUserId,
    setUserName,
    setUserPages,
    getLongLivedToken,
    withIcon,
    variant,
    title,
  } = props;

  const userAccessToken = useLocalStorageItem('fbLongLivedUserAccessToken');

  // Initializes the Facebook SDK
  const isFbSDKInitialized = useInitFbSDK();
  
  const logInToFB = () => {
    if (isFbSDKInitialized) {
      try {
        window.FB.login((response) => {
          if (response?.status === 'connected') {
            getLongLivedToken(response?.authResponse?.accessToken);
          }
        }, {
          scope: permissions,
        }); 
      }
      catch (error) {
        console.info(error)
      }
    }
  };

  const logOutOfFB = useCallback(() => {
    window.FB.logout(() => {});
    updateLocalStorage('remove', 'fbLongLivedUserAccessToken');
    setUserId(null);
    setUserName(null);
    setUserPages([]);
  }, []);

  useEffect(() => {
    try {
      if (!isFbSDKInitialized) {
        return;
      }
      if (userAccessToken && !userID) {
        window.FB.api(
          `/me?fields=id,name&access_token=${userAccessToken}`,
          (data) => {
            if (data.error) {
              localStorage.removeItem('fbLongLivedUserAccessToken');
              setUserId(null);
              setUserName(null);
              setUserPages([]);
            }
            setUserId(data?.id);
            setUserName(data?.name);
          },
        );
      }  
    } catch (error) {
      
    }  
  }, [
    isFbSDKInitialized,
    userAccessToken,
    userID,
  ]);

  useEffect(() => {
    try {
      if (!isFbSDKInitialized) {
        return;
      }
      if (userAccessToken && userID) {
        window.FB.api(
          `/${userID}/accounts?fields=name,access_token&access_token=${userAccessToken}`,
          ({ data }) => {
            setUserPages(data);
          },
        );
      }
    } catch (error) {
      
    }  
  }, [
    isFbSDKInitialized,
    userID,
    userAccessToken,
  ]);

  return (
    <ErrorBoundary>
      <>
        {
          userAccessToken ? (
            <Button
              onClick={logOutOfFB}
              variant="secondary"
              icon={withIcon && 'facebook'}
            >
              Log out
            </Button>
          ) : (
            <Button
              onClick={logInToFB}
              variant={variant}
              icon={withIcon && 'facebook'}
              iconColor={variant === 'secondary' ? 'focus' : 'contrast'}
            >
              {title}
            </Button>
          )
        }
      </>
    </ErrorBoundary>
  );
};

FacebookLogin.defaultProps = {
  userID: null,
  withIcon: true,
  variant: 'primary',
  title: 'Login with Facebook',
};

const {
  string,
  func,
  bool,
  oneOfType,
  element,
} = PropTypes;

FacebookLogin.propTypes = {
  userID: string,
  setUserId: func.isRequired,
  setUserName: func.isRequired,
  setUserPages: func.isRequired,
  getLongLivedToken: func.isRequired,
  withIcon: bool,
  variant: oneOfType([string, element]),
  title: string,
};

export default FacebookLogin;
