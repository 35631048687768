import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import TabLabel from 'ui/TabLabel';
import Tabs from 'ui/Tabs';
import Tab from 'ui/Tab';

const EmailTabs = (props) => {
  const {
    mailbox,
    tab,
    onChange,
  } = props;

  const handleTabChange = useCallback((value) => (event) => {
    onChange(value);
  }, [
    onChange,
  ]);

  return (
    <Tabs
      variant="fullWidth"
      value={tab}
      indicatorColor="primary"
      textColor="primary"
    >
      {
        mailbox === 'inbox' && (
          [
            <Tab
              onClick={handleTabChange('all')}
              label={(
                <TabLabel
                  isSelected={tab === 'all'}
                  variant="title2"
                  weight={400}
                  weightSelected={700}
                >
                  All emails
                </TabLabel>
              )}
              value="all"
              withBottomBorder
            />,
            <Tab
              onClick={handleTabChange('read')}
              label={(
                <TabLabel
                  isSelected={tab === 'read'}
                  variant="title2"
                  weight={400}
                  weightSelected={700}
                >
                  Read
                </TabLabel>
              )}
              value="read"
              withBottomBorder
            />,
            <Tab
              onClick={handleTabChange('unread')}
              label={(
                <TabLabel
                  isSelected={tab === 'unread'}
                  variant="title2"
                  weight={400}
                  weightSelected={700}
                >
                  Unread
                </TabLabel>
              )}
              value="unread"
              withBottomBorder
            />,
          ]
        )
      }
      {
        mailbox === 'sent' && (
          [
            <Tab
              onClick={handleTabChange('all')}
              label={(
                <TabLabel
                  isSelected={tab === 'all'}
                  variant="title2"
                  weight={400}
                  weightSelected={700}
                >
                  All emails
                </TabLabel>
              )}
              value="all"
              withBottomBorder
            />,
          ]
        )
      }
    </Tabs>
  );
};

const {
  func,
  oneOf,
  string,
} = PropTypes;

EmailTabs.propTypes = {
  mailbox: oneOf(['inbox', 'sent']).isRequired,
  tab: string.isRequired,
  onChange: func.isRequired,
};

export default EmailTabs;
