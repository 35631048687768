import React, {
  useCallback,
  useContext,
} from 'react';

import PropTypes from 'prop-types';

import OpportunitiesContext from 'sections/OpportunitiesListing/context/OpportunitiesContext';

import Button from 'ui/Button';

const Reserve = (props) => {
  const {
    propertyId,
    entry,
  } = props;

  const context = useContext(OpportunitiesContext);

  const {
    reserved,
    reserve,
    unreserve,
    view,
  } = context;

  const isReserved = reserved.has(propertyId) || view === 'cart' || entry?.reserved;

  const onClick = useCallback(() => {
    isReserved ? unreserve(propertyId) : reserve(propertyId);
  }, [
    reserved,
    isReserved,
    propertyId,
  ]);

  const variant = isReserved ? 'tertiary' : 'primary';

  const icon = isReserved ? 'x' : null;

  return (
    <Button
      variant={variant}
      onClick={onClick}
      icon={icon}
    >
      {isReserved ? 'Reserved' : 'Reserve'}
    </Button>
  );
};

const {
  number,
} = PropTypes;

Reserve.propTypes = {
  propertyId: number.isRequired,
};

export default Reserve;
