import React from 'react';
import { shape } from 'prop-types';

import { useController } from 'react-hook-form';

import Textfield from 'ui/Textfield';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import PropertyAddressInput from 'shared/inputs/PropertyAddressInput';

import SubTitle from 'sections/Offers/forms/NewOfferForm/SubTitle';

import Financing from './Financing';
import NeighborDetails from './NeighborDetails';

const PropertyDetails = (props) => {
  const { control } = props;

  const {
    field: { value: type },
  } = useController({
    name: 'type',
    control,
  });

  const {
    field: { onChange: onChangePropertyAddress, value: propertyAddress },
  } = useController({
    name: 'propertyAddress',
    control,
  });

  const {
    field: { onChange: onChangeComments, value: comments },
  } = useController({
    name: 'comments',
    control,
  });

  return (
    <>
      {type === 'sell' && (
        <Flex spacing={2} fullWidth>
          <FlexItem xs={12}>
            <PropertyAddressInput
              decorated
              useId
              placeholder="Property address"
              addressId={propertyAddress}
              handleChange={onChangePropertyAddress}
            />
          </FlexItem>
          <FlexItem xs={12} />
        </Flex>
      )}

      {type === 'buy' && (
        <>
          <NeighborDetails control={control} />

          <Flex fullWidth flexDirection="column">
            <SubTitle>Financing</SubTitle>
            <Financing control={control} />
          </Flex>
        </>
      )}
      <Flex spacing={2} fullWidth>
        <FlexItem xs={12}>
          <Textfield
            fullWidth
            textarea
            placeholder="Comments"
            onChange={onChangeComments}
            value={comments}
          />
        </FlexItem>
        <FlexItem xs={12} />
      </Flex>
    </>
  );
};

PropertyDetails.propTypes = {
  control: shape({}).isRequired,
};

export default PropertyDetails;
