import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  getTransactionAttachments,
} from 'store/actions/realtorTransactions';

import FinalWalkthrough from './FinalWalkthrough';

const mapStateToProps = (state, ownProps) => {
  const {
    transactionId,
  } = ownProps;

  const transaction = selectors.realtorTransactions.byId(state, transactionId);

  const attachments = transaction?.attachments?.['realtor transaction walkthrough'] || [];

  return {
    attachments,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  refreshAttachments: () => {
    dispatch(getTransactionAttachments(ownProps.transactionId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FinalWalkthrough);
