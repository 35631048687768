import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const TempSelect = (props) => {
  const {
    value,
    options,
    onChange,
  } = props;

  const handleChange = useCallback((event) => {
    if (event.target.value === 'all') {
      onChange(Object.keys(value).reduce((acc, current) => {
        acc[current] = event.target.checked;
        return acc;
      }, {}));

      return;
    }

    onChange({
      ...value,
      [event.target.value]: event.target.checked,
    });
  });

  return (
    <FormGroup row>
      {
        options.map((option) => (
          <FormControlLabel
            key={option.value}
            control={(
              <Checkbox
                color="primary"
                checked={value[option.value]}
                onChange={handleChange}
                value={option.value}
              />
              )}
            label={option.name}
          />
        ))
      }
    </FormGroup>
  );
};

TempSelect.defaultProps = {
  options: [],
  value: [],
};

const {
  arrayOf,
  func,
  number,
  shape,
  string,
} = PropTypes;

TempSelect.propTypes = {
  options: arrayOf(shape({
    id: number,
    name: string,
  })),
  value: arrayOf(number),
  onChange: func.isRequired,
};

export default TempSelect;
