import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import CheckboxTag from 'sections/Contacts/components/CheckboxTag';

const SpecialStatuses = (props) => {
  const {
    onChange,
    isReo,
    isPocketListing,
  } = props;

  return (
    <Flex spacing={2}>
      <CheckboxTag
        value={isReo}
        onChange={onChange('is_reo')}
        tag="reo"
      />
      <CheckboxTag
        value={isPocketListing}
        onChange={onChange('is_pocket_listing')}
        tag="off-market listing"
      />
    </Flex>
  );
};

SpecialStatuses.defaultProps = {

};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

SpecialStatuses.propTypes = {

};

export default SpecialStatuses;
