import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateTransaction,
} from 'store/actions/realtorTransactions';

import Options from './Options';

const mapStateToProps = (state, ownProps) => {
  const transaction = selectors.realtorTransactions.byId(state, ownProps.transactionId);

  return {
    status: transaction?.status,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onStatusChange: (status) => {
    dispatch(updateTransaction(ownProps.transactionId)({ status }));
  },
  onDelete: () => {
    // dispatch(deleteSelectedClient());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Options);
