import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  padding: 5px;
  flex-wrap: nowrap;
  background-color: ${(props) => props.theme?.palette?.['background-flat']};
  border-radius: 8px;
  height: 30px;
`;

export const Item = styled.div`
  /* width: ${(props) => (props.width ? `${props.width * 100 / 12}%` : 'auto')}; */
  flex: 1;
  padding: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  :not(:last-child) {
    border-right: solid 1px ${(props) => props.theme?.palette?.border || 'gray'};;
  } 
`;

export default {
  Root,
  Item,
};
