import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 2px 5px;
  margin: 3px 0;
`;

export const Details = styled.div`
  padding-left: 5px;
`;

export default {
  Root,
  Details,
};
