import { connect } from 'react-redux';

import {
  setFilterItem,
} from 'store/actions/realtorLeadsFilter';

import {
  optimizedRealtorLeadsActions,
} from 'store/actions/realtorLeads';

import LeadsTableFilterModal from './LeadsTableFilterModal';

const mapStateToProps = (state) => {
  const {
    realtorLeads: {
      overallLeadsSummary,
    },
  } = state;

  if (!overallLeadsSummary) {
    optimizedRealtorLeadsActions.getOverallLeadsSummary();
  }

  const minValue = overallLeadsSummary ? Math.floor(overallLeadsSummary.min_property_value) : 0;
  let maxValue = overallLeadsSummary ? Math.ceil(overallLeadsSummary.max_property_value) : 250000;

  if (overallLeadsSummary?.min_property_value === overallLeadsSummary?.max_property_value) {
    maxValue += 1;
  }

  const minDate = overallLeadsSummary ? overallLeadsSummary.min_lead_date : null;
  const maxDate = overallLeadsSummary ? overallLeadsSummary.max_lead_date : null;

  return {
    defaultRangeFilter: {
      propertyValueRange: [minValue, maxValue],
      createdAtRange: [minDate, maxDate],
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  onOk: (filter) => {
    Object.keys(filter).forEach((key) => {
      dispatch(setFilterItem(key)(filter[key]));
    });

    optimizedRealtorLeadsActions.getLeadsForStoreFilter();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LeadsTableFilterModal);
