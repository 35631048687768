import React from 'react';

import { NewEmailCampaignContext } from 'sections/Communications/providers/context';

import useNewEmailCampaign from 'sections/Communications/forms/useNewEmailCampaign';

const NewEmailCampaignProvider = (props) => {
  const {
    control,
    handleSubmit,
    onChange,
  } = useNewEmailCampaign();

  return (
    <NewEmailCampaignContext.Provider value={{
      control,
      handleSubmit,
      onChange,
    }}
    >
      {props.children}
    </NewEmailCampaignContext.Provider>
  );
};

export default NewEmailCampaignProvider;
