import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import useStyles from './useStyles';

const SourceSelect = (props) => {
  const {
    value,
    onChange,
    options,
  } = props;

  const classes = useStyles();

  const handleChange = useCallback((event) => {
    let valueList = event.target.value;
    const isAllSelectedAsFirst = valueList.indexOf(0) === 0;
    const isAllSelectedAsLast = valueList.indexOf(0) === valueList.length - 1;
    const areFewOptionsSelected = valueList.length > 1;

    if (isAllSelectedAsFirst && areFewOptionsSelected) {
      valueList = valueList.slice(1);
    } else if (isAllSelectedAsLast && areFewOptionsSelected) {
      valueList = [0];
    }

    onChange(valueList);
  });

  const onClose = useCallback(() => {
    const isAllSelected = value.indexOf(0) > -1;

    if (isAllSelected) {
      onChange([0]);
    }
  }, [
    value,
  ]);

  return (
    <FormControl
      variant="outlined"
      fullWidth
    >
      <InputLabel
        margin="dense"
        id="select-source-label"
      >
        Source
      </InputLabel>
      <Select
        multiple
        value={value}
        onChange={handleChange}
        variant="outlined"
        margin="dense"
        labelId="select-source-label"
        label="Source"
        onClose={onClose}
        classes={{
          selectMenu: classes.selectMenu,
        }}
      >
        {options.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SourceSelect.defaultProps = {
  options: [],
  value: [],
};

const {
  arrayOf,
  func,
  number,
  shape,
  string,
} = PropTypes;

SourceSelect.propTypes = {
  value: arrayOf(string),
  onChange: func.isRequired,
  options: arrayOf(shape({
    id: number,
    name: string,
  })),
};

export default SourceSelect;
