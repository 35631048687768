import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import LeadPropertiesSelector from 'sections/Leads/modules/LeadPropertiesSelector';

import PrimaryRequirements from 'sections/Leads/modules/PrimaryRequirements';
import PrimaryDetails from 'sections/Leads/modules/PrimaryDetails';

import DetailsSection from 'sections/Leads/components/DetailsSection';

import AdditionalAmenities from 'shared/modules/AdditionalAmenities';

const primaryComponents = {
  properties: PrimaryDetails,
  property_requirements: PrimaryRequirements,
};

const contentStyles = {
  padding: '12px 24px 24px',
};

const ObjectParameters = (props) => {
  const {
    leadId,
    selectedProperty,
    setSelectedProperty,
  } = props;

  const onPropertySelect = useCallback((type) => (id) => {
    setSelectedProperty({ type, id, leadId });
  }, [
    leadId,
    setSelectedProperty,
  ]);

  const PrimaryComponent = selectedProperty?.type
    ? primaryComponents[selectedProperty.type]
    : null;

  return (
    <Flex
      fullWidth
      flexDirection="column"
      spacing={2}
    >
      <FlexItem fullWidth>
        <LeadPropertiesSelector
          leadId={leadId}
          onSelect={onPropertySelect}
          selectedProperty={selectedProperty?.id ? selectedProperty : null}
        />
      </FlexItem>

      {
        selectedProperty?.id && (
          <Flex
            spacing={4}
            flexDirection="column"
            fullWidth
            style={contentStyles}
          >
            {
              PrimaryComponent && (
                <PrimaryComponent itemId={selectedProperty.id} />
              )
            }

            <DetailsSection
              icon="key"
              title="Property Features"
            >
              <AdditionalAmenities
                itemId={selectedProperty?.id}
                itemType={selectedProperty?.type}
              />
            </DetailsSection>
          </Flex>
        )
      }
    </Flex>
  );
};

ObjectParameters.defaultProps = {
  leadId: null,
  selectedProperty: {},
};

const {
  number,
  string,
  shape,
  func,
  oneOfType,
} = PropTypes;

ObjectParameters.propTypes = {
  leadId: oneOfType([number, string]),
  selectedProperty: shape({
    id: number,
    type: string,
  }),
  setSelectedProperty: func.isRequired,
};

export default ObjectParameters;
