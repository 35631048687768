/* eslint-disable react/jsx-no-bind */

import React from 'react';

import { shape } from 'prop-types';

import { useController } from 'react-hook-form';

import Button from 'ui/Button';
import Flex from 'ui/Flex';

const RequestorType = (props) => {
  const { control, propertyData } = props;
  const propertyState = propertyData?.propertyState ?? 'residential';
  const transferState = propertyData?.transferState ?? 'sale';

  const {
    field: { onChange: onChangeType, value: type },
  } = useController({
    name: 'type',
    control,
  });

  return (
    <Flex spacing={2} alignItems="center">
      {propertyState === 'commercial'
      || transferState === 'sale' ? (
        <>
          <Button
            variant="secondary"
            style={{ border: type === 'buyer' ? '1px solid #367BED' : 'none' }}
            onClick={() => onChangeType('buyer')}
          >
            I&apos;M A BUYER
          </Button>
          <Button
            variant="secondary"
            style={{ border: type === 'agent' ? '1px solid #367BED' : 'none' }}
            onClick={() => onChangeType('agent')}
          >
            I&apos;M A BUYER AGENT
          </Button>
        </>
      ) : (
        <>
          <Button
            variant="secondary"
            style={{ border: type === 'renter' ? '1px solid #367BED' : 'none' }}
            onClick={() => onChangeType('renter')}
          >
            I&apos;M A RENTER
          </Button>
          <Button
            variant="secondary"
            style={{ border: type === 'renter_agent' ? '1px solid #367BED' : 'none' }}
            onClick={() => onChangeType('renter_agent')}
          >
            I&apos;M A RENTER AGENT
          </Button>
        </>
      )}
    </Flex>
  );
};

RequestorType.propTypes = {
  control: shape({}).isRequired,
  propertyData: shape({}).isRequired,
};

export default RequestorType;
