import { connect } from 'react-redux';

import { last } from 'shared/utility';

import selectors from 'store/selectors';

import {
  updateTransaction,
  getTransactionAttachments,
  getTransactionById,
} from 'store/actions/realtorTransactions';

import requests from 'api/requests';

import { REALTOR_ROLES } from 'constants/realtor.constants';

import CloseEscrow from './CloseEscrow';

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

const mapStateToProps = (state, ownProps) => {
  const {
    transactionId,
  } = ownProps;

  const transaction = selectors.realtorTransactions.byId(state, transactionId);

  const attachments = transaction?.attachments?.['realtor transaction escrow'] || [];

  const title_agents = transaction?.title_agents;
  const escrow_agents = transaction?.escrow_agents;
  const lawyers = transaction?.lawyers;

  const settings = selectors.appCustomization.settings(state);

  return {
    attachments,

    titleAgentContactId: last(title_agents),
    escrowAgentContactId: last(escrow_agents),
    lawyerContactId: last(lawyers),
    signingDate: transaction?.escrow_signing_date,
    signingAddressId: transaction?.escrow_signing_address_id,

    attorneyOrEscrow: settings.attorney_state_or_escrow_state,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  refreshAttachments: () => {
    dispatch(getTransactionAttachments(ownProps.transactionId));
  },

  onChange: (key) => (value) => {
    dispatch(updateTransaction(ownProps.transactionId)({
      [key]: value,
    }));
  },

  onUserChange: (role) => async (contactId) => {
    const roleId = (typeof role === 'string') ? getKeyByValue(REALTOR_ROLES, role) : role;

    try {
      await requests.realtor.transactions.addRelations('contacts')(ownProps.transactionId)({ contactId, roleId });
    } catch (error) {
      return;
    }

    dispatch(getTransactionById(ownProps.transactionId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CloseEscrow);
