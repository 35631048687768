import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import createHtmlMarkup from 'shared/utils/createHtmlMarkup';

import EditableTextField from 'shared/components/EditableTextField';

const PropertyDescription = (props) => {
  const {
    onChange,
    description,
  } = props;

  const renderValue = useCallback((value) => (
    <div className="ticket-text__content">
      {createHtmlMarkup(value)}
    </div>
  ), []);

  const text = description === '<p></p>'
    ? ''
    : description;

  return (
    <EditableTextField
      isBordered
      richTextEditor
      onConfirm={onChange}
      value={text}
      placeholder={'<p style="cursor: pointer;">Property description...</p>'}
      renderValue={renderValue}
    />
  );
};

const {
  func,
  string,
} = PropTypes;

PropertyDescription.propTypes = {
  onChange: func.isRequired,
  description: string.isRequired,
};

export default PropertyDescription;
