import styled from 'styled-components';

export const EmptyElement = styled.div`
  width: 71.55px;
`;

export const InformationWrapper = styled.div`
  background-color: ${(props) => props.theme?.palette['background-flat']};
  padding: 24px;
  width: 470px;
`;

export default {
  EmptyElement,
  InformationWrapper,
};
