import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'antd';

const ActionButtons = (props) => {
  const {
    // leadId,
    // connectedItemType,
    // connectedItemId,
    // openModal,
  } = props;

  const handleClick = useCallback((modalType) => (event) => {
    event.stopPropagation();
    // openModal(modalType, { leadId, connectedItemType, connectedItemId });
  }, [
    // leadId,
    // connectedItemType,
    // connectedItemId,
  ]);

  return (
    <>
      {/* <Button
        type="primary"
        icon="dollar"
        size="small"
        style={{ marginRight: 7 }}
        onClick={handleClick('leadToProspect')}
      /> */}
      <Button
        type="primary"
        icon="delete"
        size="small"
        onClick={handleClick('archiveDelete')}
      />
    </>
  );
};

const {
  number,
  string,
  func,
} = PropTypes;

ActionButtons.propTypes = {
  // leadId: number.isRequired,
  // connectedItemType: string.isRequired,
  // connectedItemId: number.isRequired,
  // openModal: func.isRequired,
};

export default ActionButtons;
