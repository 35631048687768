import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import ListingsDivider from './ListingsDivider';

const CustomRow = (props) => {
  const {
    myListingsFirstRowKey,
    featuredListingsFirstRowKey,
    'data-row-key': dataRowKey,
    shouldShowDividers,
  } = props;

  const [currentListingsDivider, setListingsDivider] = useState('');

  useEffect(() => {
    if (!shouldShowDividers) {
      setListingsDivider('');
      return;
    }

    if (dataRowKey === myListingsFirstRowKey) {
      setListingsDivider('myListings');
    } else if (dataRowKey === featuredListingsFirstRowKey) {
      setListingsDivider('featuredProperties');
    } else {
      setListingsDivider('');
    }
  }, [
    myListingsFirstRowKey,
    featuredListingsFirstRowKey,
    dataRowKey,
    shouldShowDividers,
  ]);

  return (
    <>
      {
        shouldShowDividers && currentListingsDivider && (
          <ListingsDivider type={currentListingsDivider} />
        )
      }
      <tr
        {...props} // eslint-disable-line react/jsx-props-no-spreading
      />
    </>
  );
};

const { number, bool } = PropTypes;

CustomRow.defaultProps = {
  myListingsFirstRowKey: undefined,
  featuredListingsFirstRowKey: undefined,
  'data-row-key': undefined,
  shouldShowDividers: false,
};

CustomRow.propTypes = {
  myListingsFirstRowKey: number,
  featuredListingsFirstRowKey: number,
  'data-row-key': number,
  shouldShowDividers: bool,
};

export default CustomRow;
