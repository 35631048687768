import React from 'react';
import PropTypes from 'prop-types';

import SingleSelect from './SingleSelect';
import MultiSelect from './MultiSelect';

const SaleTypeSelect = (props) => {
  const {
    handleChange,
    value,
    multiselect,
    defaultView,
  } = props;

  if (multiselect) {
    return (
      <MultiSelect
        value={value}
        handleChange={handleChange}
        defaultView={defaultView}
      />
    );
  }

  return (
    <SingleSelect
      value={value}
      handleChange={handleChange}
    />
  );
};

SaleTypeSelect.defaultProps = {
  value: undefined,
  multiselect: false,
  defaultView: 'edit',
};

const {
  func,
  number,
  bool,
  oneOf,
  oneOfType,
  arrayOf,
} = PropTypes;

SaleTypeSelect.propTypes = {
  handleChange: func.isRequired,
  value: oneOfType([number, arrayOf(number)]),
  multiselect: bool,
  defaultView: oneOf(['edit', 'display']),
};

export default SaleTypeSelect;
