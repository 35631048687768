import {
  SET_ASSIGN_INTERESTED_PARTY_MODAL_OPEN,
} from 'store/actionTypes/realtorOpportunities';

const openModal = ({
  selectedContacts,
  onSave,
  contactDealRole,
}) => ({
  type: SET_ASSIGN_INTERESTED_PARTY_MODAL_OPEN,
  payload: {
    selectedContacts,
    onSave,
    contactDealRole,
  },
});

export default openModal;
