import React from 'react';

import { Provider } from 'react-redux';

import {
  Route,
  Switch,
} from 'react-router-dom';

import { ConnectedRouter } from 'connected-react-router';

import 'webrtc-adapter';
import 'fullscreen-polyfill';

import {
  withAuth,
  withoutAuth,
} from 'shared/secureWrapper';

import 'babel-polyfill';

import ReactGA from 'react-ga';

import store, { history } from 'store';

import getFeatureVersion from 'shared/utils/getFeatureVersion';

import ErrorBoundary from 'components/ErrorBoundary';

import OACall from 'components/OACall';
// import MeetingsPage from 'oa/pages/meetings/index';
// import MeetingPage from 'oa/pages/meeting/index';

// import CustomerTicketView from 'components/CustomerTicketView';
import MainAppView from 'components/MainAppView';
import Login from 'components/Login';
import NewClientLogin from 'components/NewClientLogin';
import BanPage from 'components/Login/BanPage';

import ExternalLeads from 'sections/ExternalLeads';
import ExternalOffers from 'sections/ExternalOffers';
import ExternalOffersV2 from 'sections/ExternalOffersV2';

import PropertyScraper from 'sections/Properties/modules/PropertyScraper';

// import ArticlePublicObject from 'components/Articles/ArticlePublicObject';

import {
  InternalError,
  FilePermission,
} from 'components/ErrorPages';

import NotFound from 'components/ErrorPages/NotFound';

import getUserConfirmation from 'components/HR/Importer/LeavingInfo';

import addIcons from 'ui/Icon/addIcons';

import {
  isValidBrowser,
  is8thSafariOrLess,
  optionallyApplyDragNDropPolyfill,
} from 'shared/utils/handleBrowsers';

import ThemeProvider from 'ui/ThemeProvider';

import 'antd/dist/antd.less';
import 'oa/styles/main.css';
import './App.scss';

// This is necessary to add icons to @fortawesome/fontawesome-svg-core library
addIcons();

// initialize google analytics
ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);

const externalOfferVer = getFeatureVersion('external offers');

const ExternalOfferComponent = externalOfferVer === 1
  ? ExternalOffers
  : ExternalOffersV2;

const App = () => {
  optionallyApplyDragNDropPolyfill();

  if (isValidBrowser()) {
    return (
      <ThemeProvider>
        <Provider store={store}>
          <ConnectedRouter
            getUserConfirmation={getUserConfirmation}
            history={history}
          >
            <ErrorBoundary>
              <OACall>
                <Switch>
                  {/* <Route
                    path="/rooms"
                    component={withAuth(MeetingsPage)}
                    exact
                    strict
                  />

                  <Route
                    path="/rooms/:code"
                    component={MeetingPage}
                    exact
                    strict
                  /> */}

                  {/* <Route
                    path="/p/:databaseName/:objectType/:objectId"
                    component={ArticlePublicObject}
                  /> */}

                  <Route
                    path="/login"
                    component={withoutAuth(Login)}
                  />
                  <Route
                    path="/login-new"
                    component={NewClientLogin}
                  />
                  <Route
                    path="/ban"
                    component={BanPage}
                  />
                  {/* <Route
                    path="/tickets/:first/:second/:jwt"
                    component={CustomerTicketView}
                  /> */}
                  <Route
                    path="/l"
                    component={ExternalLeads}
                  />
                  <Route
                    path="/o"
                    component={ExternalOfferComponent}
                  />
                  <Route
                    path="/forbidden-file"
                    component={FilePermission}
                  />

                  <Route
                    path="/fail"
                    component={InternalError}
                  />
                  <Route
                    path="/404"
                    component={NotFound}
                  />
                  <Route
                    path="/ps"
                    component={PropertyScraper}
                  />
                  <Route
                    path="*"
                    component={withAuth(MainAppView)}
                  />
                </Switch>
              </OACall>
            </ErrorBoundary>
          </ConnectedRouter>
        </Provider>
      </ThemeProvider>
    );
  }

  /* in 8th safari gradient and flex works bad, so very simple screen with inlines */
  if (is8thSafariOrLess()) {
    return (
      /* override of 0 height in 8 safari in root needs that */
      <div style={{
        height: window.innerHeight,
        width: window.innerWidth,
      }}
      >
        <div className="safari-8-text-container">
          <h2 className="safari-8-text">
            Your browser is outdated. Please update browser
          </h2>
        </div>
      </div>
    );
  }

  /* normal not-supported screen */
  return (
    <div className="outdated-browser">
      <div span={12} className="logo-part">
        <div className="center">
          <img
            src="/assets/img/white-logo-sym.png"
            className="set-height add-top-shift"
            alt="symplete logotype"
          />
        </div>
      </div>
      <div className="message-part">
        <h2 className="center add-top-shift">
          Your browser is outdated. Please update browser
        </h2>
      </div>
    </div>
  );
};

export default App;
