import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { isNil, isEmpty } from 'shared/utility';

import {
  Upload,
  Spin,
} from 'antd';

import Grid from 'ui/Grid';
import Flex from 'ui/Flex';
import Icon from 'ui/Icon';

import FormLabel from '@material-ui/core/FormLabel';

import Typography from 'ui/Typography';

import {
  UploadWrapper,
  RadioButtonsWrapper,
  RadioButtons,
  RadioButton,
} from './styledItems';

const { Dragger } = Upload;

const ImagesUpload = (props) => {
  const {
    fileList,
    onMainImageChange,
    mainImageIndexOverwrite,
    onUpload,
    onRemove,
    label,
    icon,
    loading,
    multiple,
    withMainImageSelect,
  } = props;

  const [mainImageIndex, setMainImageIndex] = useState(null);

  useEffect(() => {
    if (!isNil(mainImageIndexOverwrite)) {
      setMainImageIndex(mainImageIndexOverwrite);
    } else {
      const index = fileList.findIndex((image) => image.isMain);
      setMainImageIndex(index);
    }
  }, [
    fileList,
    mainImageIndexOverwrite,
  ]);

  const handleMainImageChange = useCallback((imageId, index) => () => {
    onMainImageChange({
      imageId,
      index: Number(index),
    });
  }, [
    fileList,
    onMainImageChange,
  ]);

  useEffect(() => {
    if (!isEmpty(fileList) && mainImageIndex >= fileList.length) {
      handleMainImageChange(fileList[0].id, 0)();
    }
  }, [
    fileList,
    mainImageIndex,
    handleMainImageChange,
  ]);

  return (
    <UploadWrapper>
      <Spin spinning={loading}>
        <Grid container spacing={0} xs={12}>
          <Grid item xs={isEmpty(fileList) ? 12 : 11}>
            <Dragger
              listType="picture"
              accept="image/png, image/jpeg"
              fileList={fileList}
              customRequest={onUpload}
              onRemove={onRemove}
              multiple={multiple}
            >
              <Flex spacing={2} style={{ padding: '10px 0' }} justifyContent="center">
                {
                icon
                ? (
                  <Icon name="upload" color="main-text" />
                ) : null
              }
                <Typography
                  variant="title3"
                  tag="p"
                  weight={400}
                  color="main-text"
                  noMargin
                  align="center"
                >
                  {label}
                </Typography>
              </Flex>
            </Dragger>
          </Grid>

          {
            (!isEmpty(fileList) && withMainImageSelect) && (
              <Grid item justify="center" xs={1}>
                <RadioButtonsWrapper component="fieldset">
                  <FormLabel component="legend">
                    <Typography variant="label">
                      Main
                    </Typography>
                  </FormLabel>
                  <RadioButtons value={mainImageIndex}>
                    {
                      fileList.map((image, index) => (
                        <RadioButton
                          value={index}
                          onChange={handleMainImageChange(image.id, index)}
                          color="primary"
                        />
                      ))
                    }
                  </RadioButtons>
                </RadioButtonsWrapper>
              </Grid>
            )
          }

        </Grid>
      </Spin>
    </UploadWrapper>
  );
};

ImagesUpload.defaultProps = {
  fileList: [],
  label: 'Click or drag file to this area to upload',
  loading: false,
  multiple: false,
  onMainImageChange: () => {},
  mainImageIndexOverwrite: null,
  icon: false,
  withMainImageSelect: true,
};

const {
  func,
  shape,
  arrayOf,
  string,
  bool,
  number,
} = PropTypes;

ImagesUpload.propTypes = {
  fileList: arrayOf(shape({
    uid: string,
    name: string,
    url: string,
    thumbUrl: string,
    isMain: bool,
  })),
  label: string,
  loading: bool,
  multiple: bool,
  onUpload: func.isRequired,
  onRemove: func.isRequired,
  onMainImageChange: func,
  mainImageIndexOverwrite: number,
  icon: bool,
  withMainImageSelect: bool,
};

export default ImagesUpload;
