import React from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import Elevation from 'ui/Elevation';

import ErrorBoundary from 'ui/ErrorBoundary';

// import PinnedNote from 'sections/Prospects/modules/PinnedNote';
// import ProspectSummary from 'sections/Prospects/modules/ProspectSummary';

import BuyerNeeds from 'sections/Prospects/modules/BuyerNeeds';
import MatchingPropertiesView from 'sections/Prospects/modules/MatchingPropertiesViewV2';

// import ViewingsView from 'sections/Prospects/modules/ViewingsView';
// import OffersView from 'sections/Opportunities/modules/OffersView';

import MatchingPropertyRejectionModal from 'sections/Prospects/modals/MatchingPropertyRejectionModal';

// import FollowUp from 'sections/Prospects/modules/FollowUp';
import FollowUpRemindersModal from 'shared/modules/FollowUpRemindersModal';

import Contact from 'sections/Prospects/modules/Contact';

import {
  Root,
} from './styledItems';

const ProspectItem = (props) => {
  const {
    prospectId,
  } = props;

  return (
    <ErrorBoundary>
      <Root>
        <Flex
          fullWidth
          spacing={2}
          flexWrap="wrap"
          alignItems="stretch"
        >
          <FlexItem xs={8}>
            <Contact
              prospectId={prospectId}
            />
          </FlexItem>

          <FlexItem xs={16}>
            <Elevation
              rounded="s"
              padding="m"
              fullHeight
            >
              <BuyerNeeds
                prospectId={prospectId}
                modalProps={{
                  title: 'Buyer Needs',
                }}
              />
            </Elevation>
          </FlexItem>

          <FlexItem fullWidth>
            <Elevation
              rounded="s"
              padding="m"
            >
              <MatchingPropertiesView
                prospectId={prospectId}
              />

              {/*
                <ViewingsView />

                <OffersView
                  objectType="prospect"
                  objectId={prospectId}
                />
              */}
            </Elevation>
          </FlexItem>
        </Flex>
      </Root>

      <MatchingPropertyRejectionModal />
      <FollowUpRemindersModal
        objectId={prospectId}
        objectTypeId={30}
      />
    </ErrorBoundary>
  );
};

const {
  number,
  string,
  oneOfType,
} = PropTypes;

ProspectItem.propTypes = {
  prospectId: oneOfType([number, string]).isRequired,
};

export default ProspectItem;
