// TODO - fix lint errors
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';

import Icon from 'ui/Icon';

import {
  Tooltip,
} from 'antd';

import { isEmpty } from 'shared/utility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  NavLink,
  withRouter,
} from 'react-router-dom';

import classnames from 'classnames';

// TODO: fix depedency cycles
import { showLunchOutModal } from 'store/actions/dashboard'; // eslint-disable-line import/no-cycle

import {
  notificationsDrawerShowAction,
  notificationsDrawerHideAction,
} from 'store/actions/notifications'; // eslint-disable-line

import Dropdown from 'shared/components/Dropdown';
import moodTypes from 'shared/modals/MoodUserModal';
import CounterRound from 'shared/components/CounterRound';

// import {
//   toDoListDrawerShowAction,
//   toDoListDrawerHideAction,
//   toDoListSettingsHideAction,
// } from 'store/actions/to-do-list';

import isSectionUnderConstruction from 'shared/utils/isSectionUnderConstruction';

import checkLoggedEmployeePermissions from 'shared/utils/checkLoggedEmployeePermissions';
import FollowUps from 'sections/FollowUps/DrawerView';
import VideoChatDrawer from './components/VideoChatDrawer';

import './NavIcons.scss';

const NavIcons = (props) => {
  const {
    hasHRManagerPermissions,
    history,
    items: itemsProp,
    location,
    lunchIn,
    mood,
    notices,

    notifications: {
      counterNew = 0,
      drawerIsVisible,
    } = {},

    // todolist: {
    //   toDoListDrawerIsVisible,
    // },

    trigger,
    triggerModal,
    triggerUserMood,

    user: {
      EmployeeID: ID,
    } = {},

    openChatDrawer,
    isChatVisible,
    closeChatDrawer,
    newMessageCount,
  } = props;

  const navItems = Array.isArray(itemsProp)
    ? itemsProp.filter((item) => item)
    : itemsProp;

  const checkNoticesSeen = (data, id) => {
    if (!data) {
      return null;
    }

    return data.filter(
      (notice) => !notice.isSeen && notice.NoticeObject.CreatedBy !== id,
    );
  };

  const backToPrevious = () => {
    const { pathname } = props.location;

    if (pathname === '/employee-directory') {
      props.history.goBack();
    }
  };

  const getMoodIcon = () => {
    const moodType = moodTypes.find((item) => item.type === props.mood.MoodType);
    return moodType ? moodType.icon : 'good';
  };

  const hasLocationPath = location
    && location.pathname
    && location.pathname.length > 1;

  const getChildrenNavIcons = () => !isEmpty(navItems) && React.Children.map(navItems, (child) => (
    <li className="nav-icons__element">
      {React.cloneElement(child)}
    </li>
  ));

  function onClickChatIcon(event) {
    event.preventDefault();
    if (isChatVisible === false) {
      openChatDrawer();
      props.notificationsDrawerHideAction();
      // props.toDoListDrawerHideAction();
    } else {
      closeChatDrawer();
    }
  }

  // function onClickToDoListIcon(event) {
  //   event.preventDefault();
  //   if (toDoListDrawerIsVisible === false) {
  //     props.toDoListDrawerShowAction();
  //     props.notificationsDrawerHideAction();
  //     closeChatDrawer();
  //   } else {
  //     props.toDoListDrawerHideAction();
  //     props.toDoListSettingsHideAction();
  //   }
  // }

  // function onClickNotificationsIcon(event) {
  //   event.preventDefault();
  //   if (drawerIsVisible === false) {
  //     props.notificationsDrawerShowAction();
  //     props.toDoListDrawerHideAction();
  //     props.toDoListSettingsHideAction();
  //     closeChatDrawer();
  //   } else {
  //     props.notificationsDrawerHideAction();
  //   }
  // }

  const onMoodIconClick = (item) => {
    if (item.key === '1') {
      triggerUserMood();
      return;
    }
    history.push('/mood-dashboard');
  };

  const getMoodButton = () => {
    if (hasHRManagerPermissions) {
      return (
        <Dropdown
          className="mood-icon-dropdown"
          type="primary"
          placement="bottomLeft"
          trigger="click"
          isButton={false}
          handleClick={() => { }} // eslint-disable-line react/jsx-no-bind
          MenuProps={{
            handleClick: onMoodIconClick,
            items: [
              {
                key: 1,
                text: 'My Mood',
              },
              {
                key: 2,
                text: 'Team Mood',
              },
            ],
          }}
        >
          <Tooltip
            placement="bottom"
            title="Mood"
          >
            <div className={classnames(
              'mood-icon',
              `mood-icon-${getMoodIcon()}`,
            )}
            />
          </Tooltip>
        </Dropdown>
      );
    }

    return (
      <Tooltip
        placement="bottom"
        title="Mood"
      >
        <span className="mood-icon-dropdown">
          <div
            role="button"
            tabIndex="0"
            aria-label="Mood"
            onClick={triggerUserMood}
            onKeyUp={() => { }} // eslint-disable-line react/jsx-no-bind
            className={classnames(
              'mood-icon',
              `mood-icon-${mood.MoodType || 'good'}`,
            )}
          />
        </span>
      </Tooltip>
    );
  };

  return (
    <div className="nav-icons">
      {hasLocationPath
        ? getChildrenNavIcons()
        : null}

      <div className="nav-icons__element">
        {/* {lunchIn ? (
          <Icon
            onClick={triggerModal}
            type="clock-circle"
            className="nav-icons__luchIn"
          />
        ) : null} */}

        {/* {getMoodButton()} */}
        <Tooltip
          placement="bottom"
          title="Contacts"
        >
          <NavLink
            to="/contacts"
            className="employee-directory"
            exact
            onClick={backToPrevious} // eslint-disable-line react/jsx-no-bind
          >
            <Icon
              name="book-open"
              color="main-text-inversed"
              size="m"
            />
          </NavLink>
        </Tooltip>

        {/* <VideoChatDrawer /> */}
        {/* <Tooltip
          placement="bottom"
          title="Chat"
        >
          <a
            className={classnames('chat-icon', {
              active: isChatVisible,
            })}
            onClick={onClickChatIcon}
            href="#"
          >
            <FontAwesomeIcon
              icon="comment-dots"
            />
            {newMessageCount > 0 ? <CounterRound count={newMessageCount} /> : null}
          </a>
        </Tooltip> */}
        {/*
        {checkNoticesSeen(notices, ID) !== null ? (
          <>
            <Badge
              count={checkNoticesSeen(notices, ID).length}
              className={
                checkNoticesSeen(notices, ID).length
                  ? 'new-notices__badge'
                  : 'new-notices__no-notices'
              }
            />

            <Tooltip
              placement="bottom"
              title="Company Notices"
            >
              <FontAwesomeIcon
                icon="thumbtack"
                className={
                  checkNoticesSeen(notices, ID).length ? 'new-notices' : ''
                }
                onClick={trigger}
              />
            </Tooltip>
          </>
        ) : null} */}

        {/* <Tooltip
          placement="bottom"
          title="To Do List"
        >
          <a
            className={classnames('todolist-icon', {
              active: toDoListDrawerIsVisible,
            })}
            onClick={onClickToDoListIcon}
            href="#"
          >
            <Icon
              name="check-square"
              color="main-text-inversed"
              size="m"
            />
          </a>
        </Tooltip> */}

        {
          !isSectionUnderConstruction('calls') && (
            <Tooltip
              placement="bottom"
              title="Calls"
            >
              <NavLink
                to="/calls"
                exact
                className="employee-directory"
              >
                <Icon
                  name="phone"
                  color="main-text-inversed"
                  size="m"
                />
              </NavLink>
            </Tooltip>
          )
        }

        <Tooltip
          placement="bottom"
          title="Calendar"
        >
          <NavLink
            to="/calendar"
            exact
            className="employee-directory"
          >
            <Icon
              name="calendar"
              color="main-text-inversed"
              size="m"
            />
          </NavLink>
        </Tooltip>

        <span className="todolist-icon">
          <FollowUps />
        </span>
        {/* <Tooltip placement="bottom" title="Notifications">
          <a
            className={classnames('notify-icon', {
              active: drawerIsVisible,
            })}
            onClick={onClickNotificationsIcon}
            href="#"
          >
            <Icon
              name="bell"
              color="main-text-inversed"
              size="m"
            />
            {counterNew > 0 ? <CounterRound count={counterNew} /> : null}
          </a>
        </Tooltip> */}
      </div>
    </div>
  );
};

NavIcons.defaultProps = {
  hasHRManagerPermissions: false,
};

const {
  arrayOf,
  oneOfType,
  instanceOf,
  node,
  shape,
  bool,
  func,
  string,
  number,
} = PropTypes;

NavIcons.propTypes = {
  triggerModal: func.isRequired,
  closeChatDrawer: func.isRequired,
  notificationsDrawerShowAction: func.isRequired,
  notificationsDrawerHideAction: func.isRequired,
  // toDoListDrawerShowAction: func.isRequired,
  // toDoListDrawerHideAction: func.isRequired,
  // toDoListSettingsHideAction: func.isRequired,
  triggerUserMood: func.isRequired,
  trigger: func.isRequired,
  history: shape({
    push: func.isRequired,
    goBack: func.isRequired,
  }).isRequired,
  location: shape({
    pathname: string,
  }).isRequired,
  notices: arrayOf(shape({
    isAcknowledged: bool.isRequired,
    NoticeObject: shape({
      NoticeID: number.isRequired,
      CreatedBy: number.isRequired,
      CreatedAt: instanceOf(Date).isRequired,
      Description: string.isRequired,
    }).isRequired,
  })).isRequired,
  // todolist: shape({
  //   toDoListDrawerIsVisible: bool.isRequired,
  // }).isRequired,
  notifications: shape({
    counterNew: number.isRequired,
    drawerIsVisible: bool.isRequired,
  }).isRequired,
  lunchIn: bool.isRequired,
  user: shape({
    Name: string,
    UserRole: number,
    EmployeeID: number,
  }).isRequired,
  mood: shape({
    MoodType: number,
    MoodComment: string,
    HideUserInfo: bool,
  }).isRequired,
  items: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
  hasHRManagerPermissions: bool,
  openChatDrawer: func.isRequired,
  isChatVisible: bool.isRequired,
  newMessageCount: number.isRequired,
};

const mapStateToProps = (state) => {
  const hasHRManagerPermissions = checkLoggedEmployeePermissions.isManager('HR');

  return {
    notices: state.dashboard.noticesList,
    lunchIn: state.dashboard.lunchIn,
    user: state.auth.loggedUser,
    mood: state.mood.userMood,
    notifications: state.notifications,
    todolist: state.todolist,
    hasHRManagerPermissions,
    isChatVisible: state.chat.isVisible,
    newMessageCount: state.chat.newMessageCount,
  };
};

const mapDispatchToProps = (dispatch) => ({
  triggerModal: () => dispatch(showLunchOutModal()),
  notificationsDrawerShowAction: () => dispatch(notificationsDrawerShowAction()),
  notificationsDrawerHideAction: () => dispatch(notificationsDrawerHideAction()),
  // toDoListDrawerShowAction: () => dispatch(toDoListDrawerShowAction()),
  // toDoListDrawerHideAction: () => dispatch(toDoListDrawerHideAction()),
  // toDoListSettingsHideAction: () => dispatch(toDoListSettingsHideAction()),
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(NavIcons);
