import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  optimizedRealtorTransactionsActions,
} from 'store/actions/realtorTransactions';

import {
  setFilterItem,
} from 'store/actions/realtorTransactionsFilter';

import TransactionsTable from './TransactionsTable';

const mapStateToProps = (state) => {
  const {
    realtorTransactions: {
      transactionsByFilterCount,
    },
    realtorLeadsFilter: {
      pageNumber,
      pageSize,
    },
  } = state;

  const {
    filterId,
  } = selectors.realtorTransactions.transactionsFilter(state);

  const transactionsList = selectors.realtorTransactions.transactionsList(state, filterId);

  return {
    itemsCount: transactionsByFilterCount[filterId],
    transactionsList,
    pageNumber,
    pageSize,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTransactions: (page, pageSize) => {
    const offset = (page - 1) * pageSize;
    const limit = pageSize;

    optimizedRealtorTransactionsActions.getTransactionsForStoreFilter(offset, limit);
  },
  setFilterItem: (key) => (value) => {
    dispatch(setFilterItem(key)(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsTable);
