import React from 'react';

import ErrorBoundary from 'ui/ErrorBoundary';

// import RoleLabel from 'sections/Leads/components/RoleLabel';

import Typography from 'ui/Typography';
import TableHead from 'ui/TableHead';

import Metrics from 'sections/Leads/modules/LeadsTable/Metrics';

// import ActionButtons from './ActionButtons';

import ContactCell from './ContactCell';
// import PropertyTypeCell from './PropertyTypeCell';
import LocationCell from './LocationCell';
// import ViewingsCell from './ViewingsCell';
// import NextViewingCell from './NextViewingCell';
// import AgeCell from './AgeCell';

import formatValue from './formatValue';

// const priorities = {
//   1: 'Assign Properties',
//   2: 'Arrange Showings',
//   3: 'Showing Feedback',
//   4: 'Submit Offers',
//   5: 'Start Transaction',
  // 6: 'Assign Buyers',
  // 7: 'Start Transactio',
// };

const metrics = {
  client: [ // seller
    {
      key: 'metrics_interested',
      iconName: 'users',
      tooltip: 'Interested Parties',
    },
    {
      key: 'metrics_viewings',
      iconName: 'calendar',
      tooltip: 'Arranged Showings',
    },
    // {
    //   key: 'completedShowings',
    //   iconName: 'user-plus',
    //   tooltip: 'Completed Showings',
    // },
    {
      key: 'metrics_offers',
      iconName: 'investment',
      iconType: 'custom',
      tooltip: 'Offers',
    },
  ],
  prospect: [ // buyer
    {
      key: 'metrics_interested',
      iconName: 'home',
      tooltip: 'Properties To Review',
    },
    {
      key: 'metrics_viewings',
      iconName: 'calendar',
      tooltip: 'Upcoming Showings',
    },
    // {
    //   key: 'completedShowings',
    //   iconName: 'user-plus',
    //   tooltip: 'Completed Showings',
    // },
    {
      key: 'metrics_offers',
      iconName: 'investment',
      iconType: 'custom',
      tooltip: 'Submitted Offers',
    },
  ],
};

const columns = ({
  hoveredRecord,
  openArchiveDeleteModal,
  onSort,
}) => ([
  {
    title: () => (
      <TableHead onSort={onSort('contact_name')}>
        Name
      </TableHead>
    ),
    dataIndex: 'contact_id',
    key: 'contact_name',
    width: 5,
    render: (value, entry) => (
      <ErrorBoundary>
        <ContactCell
          type={entry.type}
          itemId={entry.id}
        />
      </ErrorBoundary>
    ),
    align: 'left',
  },
  // {
  //   title: () => (
  //     <TableHead onSort={onSort('stage')}>
  //       Stage
  //     </TableHead>
  //   ),
  //   width: 1,
  //   dataIndex: 'stage',
  //   key: 'stage',
  //   render: (value, entry) => (
  //     <Typography
  //       variant="cell2"
  //       style={{ textTransform: 'capitalize' }}
  //     >
  //       {value || '—'}
  //     </Typography>
  //   ),
  //   align: 'left',
  // },
  // {
  //   title: () => (
  //     <TableHead onSort={onSort('type')}>
  //       Type
  //     </TableHead>
  //   ),
  //   dataIndex: 'type',
  //   key: 'type',
  //   width: 1.5,
  //   render: (value, entry) => <RoleLabel role={value === 'client' ? 2 : 1} />,
  // },
  {
    title: () => (
      <TableHead onSort={onSort('budgetPrice')}>
        Budget / Price
      </TableHead>
    ),
    dataIndex: 'type',
    key: 'budgetPrice',
    width: 2,
    render: (value, entry) => {
      if (entry.type === 'client') {
        return (
          <ErrorBoundary>
            <Typography
              variant="cellLarge"
              align="right"
            >
              {formatValue(entry.listing_price)}
            </Typography>
          </ErrorBoundary>
        );
      }
      if (entry.type === 'prospect') {
        return (
          <ErrorBoundary>
            <Typography
              variant="cellLarge"
              align="right"
              noWrap
            >
              {`${formatValue(entry.budget_min)}—${formatValue(entry.budget_max)}`}
            </Typography>
          </ErrorBoundary>
        );
      }
      return (
        <Typography variant="cell">—</Typography>
      );
    },
  },
  // {
  //   title: () => (
  //     <TableHead onSort={onSort('propertyType')}>
  //       Property
  //     </TableHead>
  //   ),
  //   dataIndex: 'type',
  //   key: 'propertyType',
  //   width: 2,
  //   render: (value, entry) => (
  //     <PropertyTypeCell
  //       type={entry.type}
  //       itemId={entry.id}
  //     />
  //     ),
  // },
  {
    title: () => (
      <TableHead onSort={onSort('location')}>
        Location
      </TableHead>
    ),
    dataIndex: 'type',
    width: 5,
    key: 'location',
    render: (value, entry) => (
      <LocationCell
        type={entry.type}
        itemId={entry.id}
      />
      ),
  },
  // {
  //   title: () => (
  //     <TableHead onSort={onSort('toReview')}>
  //       To Review
  //     </TableHead>
  //   ),
  //   width: 2,
  //   dataIndex: 'id',
  //   key: 'toReview',
  //   render: (_, entry) => (
  //     <Typography variant="cell">
  //       {
  //         entry?.to_review_matching_properties
  //         || entry?.to_review_matching_buyers
  //         || 0
  //       }
  //     </Typography>
  //   ),
  //   align: 'right',
  // },
  // {
  //   title: () => (
  //     <TableHead onSort={onSort('viewings')}>
  //       Showings
  //     </TableHead>
  //   ),
  //   width: 1.5,
  //   dataIndex: 'viewings',
  //   key: 'viewings',
  //   render: (value, entry) => (
  //     <ViewingsCell
  //       type={entry.type}
  //       itemId={entry.id}
  //     />
  //   ),
  //   align: 'right',
  // },
  // {
  //   title: () => (
  //     <TableHead onSort={onSort('nextViewing')}>
  //       Next showing
  //     </TableHead>
  //   ),
  //   width: 2,
  //   dataIndex: 'next_viewing_id',
  //   key: 'nextViewing',
  //   render: (value, entry) => (
  //     <NextViewingCell
  //       type={entry.type}
  //       itemId={entry.id}
  //     />
  //   ),
  //   align: 'left',
  // },
  // {
  //   title: () => (
  //     <TableHead onSort={onSort('createdAt')}>
  //       Age
  //     </TableHead>
  //   ),
  //   dataIndex: 'created_at',
  //   key: 'createdAt',
  //   // render: formatCreationTime,
  //   width: 1,
  //   className: 'realtor-leads-table__sm-padding',
  //   render: (value, entry) => {
  //     if (hoveredRecord === entry.key) {
  //       // const {
  //       //   leadId,
  //       //   connectedItemType,
  //       //   connectedItemId,
  //       // } = record;

  //       // return null

  //       return (
  //         <ActionButtons openModal={openArchiveDeleteModal(entry.id, entry.type)} />
  //       );
  //     }

  //     return (
  //       <AgeCell
  //         type={entry.type}
  //         itemId={entry.id}
  //       />
  //     );
  //   },
  // },
  {
    title: () => (
      <TableHead>
        Metrics
      </TableHead>
    ),
    dataIndex: 'type',
    key: 'budgetPrice',
    width: 3.5,
    render: (value, entry) => (
      <ErrorBoundary>
        <Metrics
          data={entry}
          metrics={metrics[entry.type]}
        />
      </ErrorBoundary>
    ),
  },
  {
    title: () => (
      <TableHead>
        Next priority
      </TableHead>
    ),
    dataIndex: 'type',
    key: 'budgetPrice',
    width: 2.5,
    render: (value, entry) => (
      <ErrorBoundary>
        <Typography
          noMargin
          weight="bold"
          color="focus"
          noWrap
        >
          COMING SOON
        </Typography>
      </ErrorBoundary>
    ),
  },
]);

export default columns;
