import { connect } from 'react-redux';

import withPropertyData from 'sections/Properties/hocs/withPropertyData';

import DetailsCell from './DetailsCell';

const mapStateToProps = (state, ownProps) => {
  const {

  } = ownProps;

  const {

  } = state;

  return {

  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(withPropertyData(DetailsCell));
