import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  optimizedCrmSourcesActions,
} from 'store/actions/crm/crmSources';

import SourceSelect from './SourceSelect';

const mapStateToProps = (state) => {
  const {

  } = state;

  const sourceTypes = selectors.crm.sourceTypes(state);

  if (!sourceTypes) {
    optimizedCrmSourcesActions.getSourceTypes();
  }

  const options = sourceTypes
    ? [
      {
        id: 0,
        name: 'All',
      },
      ...sourceTypes,
    ]
    : [];

  return {
    options,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(SourceSelect);
