const getPageInstagaramId = ({
  pageId,
  pages = [],
  onSuccess,
  onError,
}) => {
  if (!pageId) {
    onError();
    return;
  }

  const page = pages.find((item) => item.id === pageId);
  const pageToken = page?.access_token;

  if (!pageToken || !window.FB) {
    onError();
    return;
  }

  window.FB.api(
    `/${pageId}?fields=instagram_business_account&access_token=${pageToken}`,
    ({ instagram_business_account }) => {
      if (instagram_business_account) {
        onSuccess(instagram_business_account.id);
      } else {
        onError();
      }
    },
  );
};

export default getPageInstagaramId;
