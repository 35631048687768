import { connect } from 'react-redux';

import selectors from 'store/selectors';

import MainContactInfo from './MainContactInfo';

const mapStateToProps = (state, ownProps) => {
  const {
    contactId,
  } = ownProps;

  const {
    email_primary,
    phone_primary,
    role,
  } = selectors.realtorContacts.byId(state, contactId);

  return {
    email: email_primary,
    phone: phone_primary,
    role,
  };
};

export default connect(mapStateToProps)(MainContactInfo);
