import { connect } from 'react-redux';

import {
  toDoListSetViewedAction,
  reminderChangeTitleAction,
} from 'store/actions/to-do-list';

import ReminderItem from './ReminderItem';

const mapDispatchToProps = {
  toDoListSetViewedAction,
  reminderChangeTitleAction,
};

export default connect(null, mapDispatchToProps)(ReminderItem);
