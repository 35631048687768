import React, {
  useEffect,
  useCallback,
  useState,
} from 'react';

import { useHistory } from 'react-router-dom';

import Table from 'ui/Table';

import PropTypes from 'prop-types';

import columns from './columns';

import './TransactionsTable.scss';

const showTotal = (total, range) => `${range[0]}-${range[1]} of ${total} transactions`;

const TransactionsTable = (props) => {
  const {
    transactionsList,
    getTransactions,
    setFilterItem,
    itemsCount,
    pageNumber,
    pageSize,
  } = props;

  const history = useHistory();

  useEffect(() => {
    getTransactions(pageNumber, pageSize);
  }, []);

  const onPaginationChange = useCallback((newPageNumber, newPageSize) => {
    setFilterItem('pageNumber')(newPageNumber);
    setFilterItem('pageSize')(newPageSize);
    getTransactions(newPageNumber, newPageSize);
  }, [
    setFilterItem,
    getTransactions,

  ]);

  const [hoveredRecord, setHoveredRecord] = useState(null);

  const onRow = useCallback((record, rowIndex) => ({
    onClick: (event) => {
      history.push(`/transactions/${record.id}`);
    },
    onMouseEnter: (event) => {
      setHoveredRecord(record.key);
    },
    onMouseLeave: (event) => {
      setHoveredRecord(null);
    },
  }), [
    history,
    setHoveredRecord,
  ]);

  return (
    <Table
      key="transactions-table"
      className="realtor-transactions-table"
      dataSource={transactionsList}
      columns={columns({ hoveredRecord })}
      pagination={{
        total: itemsCount,
        showTotal,
        onChange: onPaginationChange,
        current: pageNumber,
        pageSize,
      }}
      onRow={onRow}
      tableLayout="fixed"
      expandIconAsCell={false}
      expandIconColumnIndex={-1}
      size="middle"
    />
  );
};

TransactionsTable.defaultProps = {
  transactionsList: [],
  itemsCount: 0,
  pageNumber: 1,
  pageSize: 10,
};

const {
  arrayOf,
  func,
  number,
  shape,
  string,
} = PropTypes;

TransactionsTable.propTypes = {
  transactionsList: arrayOf(shape({
    key: string,
    leadId: number,
    connectedItemId: number,
  })),
  getTransactions: func.isRequired,
  setFilterItem: func.isRequired,
  itemsCount: number,
  pageNumber: number,
  pageSize: number,
};

export default TransactionsTable;
