import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import Breadcrumbs from 'sections/Transactions/modules/Breadcrumbs';
import PinnedNote from 'sections/Transactions/modules/PinnedNote';
import Options from 'sections/Transactions/modules/Options';
import KPIIndicatorBar from 'sections/Transactions/modules/KPIIndicatorBar';
import TransactionTabs from 'sections/Transactions/modules/TransactionTabs';
import Price from 'sections/Transactions/modules/Price';
import Players from 'sections/Transactions/modules/Players';
import FollowUp from 'sections/Transactions/modules/FollowUp';
import CompleteTransaction from 'sections/Transactions/modules/CompleteTransaction';
import CompletedTransactionInfo from 'sections/Transactions/modules/CompletedTransactionInfo';

import FollowUpRemindersModal from 'shared/modules/FollowUpRemindersModal';

import ImageCarousel from 'sections/MLS/modules/ImageCarousel';

import BorderLabel from 'ui/BorderLabel';

import {
  Root,
} from './styledItems';

const attorneyEscrowPlayersRoles = {
  attorney: [
    { key: 'sellers', role: 'seller' },
    { key: 'seller_agents', role: 'seller agent' },
    { key: 'buyers', role: 'buyer' },
    { key: 'buyer_agents', role: 'buyer agent' },
    { key: 'lawyers', role: 'lawyer' },
  ],
  escrow: [
    { key: 'sellers', role: 'seller' },
    { key: 'seller_agents', role: 'seller agent' },
    { key: 'buyers', role: 'buyer' },
    { key: 'buyer_agents', role: 'buyer agent' },
    { key: 'title_agents', role: 'title agent' },
    { key: 'escrow_agents', role: 'escrow agent' },
  ],
};

const attorneyOrEscrowLabels = {
  attorney: 'due diligence',
  escrow: 'contingencies',
};

const getLabelRelativePositionParams = () => {
  const tabsElemPos = document.getElementById('transaction-tabs')?.getBoundingClientRect();
  const firstTargetTabPos = document.getElementById('inspections transaction tab')?.getBoundingClientRect();

  const left = (firstTargetTabPos?.left && tabsElemPos.left) ? firstTargetTabPos.left - tabsElemPos.left : null;
  const width = firstTargetTabPos?.width || null;

  const targetTabsNum = 3;

  return {
    left: left ? `${left}px` : null,
    width: width ? `${width * targetTabsNum}px` : null,
  };
};

const TransactionsItem = (props) => {
  const {
    selectedTransaction,
    propertyId,
    isCompleted,
    attorneyOrEscrow,
  } = props;

  const [labelRelativePositionParams, setLabelRelativePositionParams] = useState({ width: null, left: null });

  useEffect(() => {
    setLabelRelativePositionParams(getLabelRelativePositionParams());
  }, []);

  return (
    <Root>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          container
          spacing={2}
        >
          <Grid
            item
            xs={6}
            container
            alignItems="center"
          >
            <Breadcrumbs />
          </Grid>

          <Grid
            item
            xs={6}
            container
            spacing={2}
            justify="flex-end"
            alignItems="center"
          >
            {
              !isCompleted && (
                <Grid item>
                  <CompleteTransaction transactionId={selectedTransaction} />
                </Grid>
              )
            }
            <Grid item>
              <FollowUp transactionId={selectedTransaction} />
            </Grid>
            <Grid item>
              <PinnedNote />
            </Grid>
            <Grid item>
              <Options transactionId={selectedTransaction} />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          container
          spacing={3}
        >
          <Grid
            item
            xs={3}
          >
            <ImageCarousel propertyId={propertyId} />
          </Grid>

          <Grid
            item
            container
            xs={9}
            spacing={2}
          >
            {
              isCompleted ? (
                <Grid item xs={12}>
                  <CompletedTransactionInfo
                    transactionId={selectedTransaction}
                  />
                </Grid>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Price
                      transactionId={selectedTransaction}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    justify="space-between"
                    alignItems="center"
                  >
                    <Players
                      playersRoles={attorneyEscrowPlayersRoles[attorneyOrEscrow]}
                      transactionId={selectedTransaction}
                      preset={false}
                    />
                  </Grid>
                </>
              )
            }

          </Grid>
          <Grid
            item
          >
            <KPIIndicatorBar
              transactionId={selectedTransaction}
            />
          </Grid>
        </Grid>

        <Grid
          item
        >
          <BorderLabel
            title={attorneyOrEscrowLabels[attorneyOrEscrow]}
            width={labelRelativePositionParams.width}
            left={labelRelativePositionParams.left}
          >
            <TransactionTabs />
          </BorderLabel>
        </Grid>
      </Grid>

      <FollowUpRemindersModal
        objectId={selectedTransaction}
        objectTypeId={41}
      />
    </Root>
  );
};

TransactionsItem.defaultProps = {
  selectedTransaction: null,
  propertyId: null,
  isCompleted: false,
};

const {
  number,
  string,
  oneOfType,
  bool,
  oneOf,
} = PropTypes;

TransactionsItem.propTypes = {
  selectedTransaction: oneOfType([number, string]),
  propertyId: number,
  isCompleted: bool,
  attorneyOrEscrow: oneOf(['attorney', 'escrow']).isRequired,
};

export default TransactionsItem;
