import { connect } from 'react-redux';

import {
  setNewPropertyViewingFormField,
  setNewPropertyViewingNestedFormField,
} from 'store/actions/newRealtorPropertyViewingForm';

import NewViewingForm from './NewViewingForm';

const mapStateToProps = (state, ownProps) => {
  const {
    newPropertyViewingForm: {
      date_time, // eslint-disable-line camelcase
      property_id, // eslint-disable-line camelcase
      minTime,
      maxTime,
      location,
      title,
      description,
      eventType,
    },
    newPropertyViewingModal: { propertyField, contactField },
  } = state;

  const showContactField = ownProps.showContactField ?? contactField;
  const showPropertyField = ownProps.showPropertyField ?? propertyField;

  return {
    dateTime: date_time || ownProps.dateTime, // eslint-disable-line camelcase
    showPropertyField: !!showPropertyField,
    showContactField: !!showContactField,
    selectedPropertyId: property_id, // eslint-disable-line camelcase
    minTime,
    maxTime,
    location,
    title,
    description,
    eventType,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleChange: (fieldName) => (value) => {
    dispatch(setNewPropertyViewingFormField(fieldName)(value));
  },
  handleChangeNested: (parentName) => (fieldName) => (value) => {
    dispatch(
      setNewPropertyViewingNestedFormField(parentName)(fieldName)(value),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewViewingForm);
