import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import Typography from 'ui/Typography';

const dateTimeFormat = 'D/M/YY';

const PropertyLastEndedViewingCell = (props) => {
  const {
    dateTime,
  } = props;

  if (!dateTime) {
    return (
      <Typography variant="cell">
        No
      </Typography>
    );
  }

  const getDateTimeText = useCallback(() => moment(dateTime).format(dateTimeFormat), [dateTime]);

  return (
    <Typography variant="cell">
      Yes -
      {' '}
      {getDateTimeText()}
    </Typography>
  );
};

PropertyLastEndedViewingCell.defaultProps = {
  dateTime: null,
};

const {
  string,
} = PropTypes;

PropertyLastEndedViewingCell.propTypes = {
  dateTime: string,
};

export default PropertyLastEndedViewingCell;
