import React from 'react';

import Disclosures from 'sections/Properties/modules/PropertyDisclosures';
import NotablePoints from 'sections/Properties/modules/PropertyNotablePoints';
import Flex from 'ui/Flex';

import {
  Root,
} from './styledItems';

const PropertyNotablePointsAndDisclosures = (props) => (
  <Root>
    <Flex
      spacing={1}
      spacingStep={100}
    >
      <NotablePoints
        title="Notable Selling Points"
        titleProps={{
          variant: 'title2',
        }}
        propertyId={props.propertyId}
        modalTitle="Notable Selling Points"
      />
      <Disclosures
        title="Disclosures"
        titleProps={{
          variant: 'title2',
        }}
        propertyId={props.propertyId}
        modalTitle="Disclosures"
      />
    </Flex>
  </Root>
);

export default PropertyNotablePointsAndDisclosures;
