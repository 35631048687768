import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import OptionsMenu from 'ui/OptionsMenu';

import MenuItem from 'ui/MenuItem';

// import PinnedNote from 'sections/Prospects/modules/PinnedNote'

const Options = (props) => {
  const {
    status,
    onStatusChange,
    onDelete,
  } = props;

  const handleStatusChange = useCallback((newStatus) => (closeMenu) => () => {
    onStatusChange(newStatus);
    closeMenu();
  }, [onStatusChange]);

  const handleDelete = useCallback((closeMenu) => () => {
    onDelete();
    closeMenu();
  }, [onDelete]);

  return (
    <OptionsMenu {...props}>
      {
        ({ closeMenu }) => (
          <>
            {/* <PinnedNote
              CustomRoot={({onClick}) => (
                <MenuIteme
                  onClick={() => {
                    onClick()
                    closeMenu()
                  }}
                >
                  Pinned note
                </MenuItem>
              )}
            /> */}
            {
              status === 2 && (
                <MenuItem onClick={handleStatusChange(1)(closeMenu)}>
                  Set to active
                </MenuItem>
              )
            }
            {
              status !== 2 && (
                <MenuItem onClick={handleStatusChange(2)(closeMenu)}>
                  Archive
                </MenuItem>
              )
            }
            <MenuItem onClick={handleDelete(closeMenu)}>
              Delete
            </MenuItem>
          </>
        )
      }
    </OptionsMenu>
  );
};

Options.defaultProps = {
  status: null,
};

const {
  func,
  number,
} = PropTypes;

Options.propTypes = {
  onStatusChange: func.isRequired,
  onDelete: func.isRequired,
  status: number,
};

export default Options;
