import { connect } from 'react-redux';
import store from 'store';

import selectors from 'store/selectors';

import {
  setSelectedProspect,
} from 'store/actions/realtorProspects';

import ProspectItem from './ProspectItem';

const mapStateToProps = (state, ownProps) => {
  const {
    realtorProspects: {
      selectedProspect,
    },
  } = state;

  const { id } = ownProps.match.params;

  if (selectedProspect !== id) {
    store.dispatch(setSelectedProspect(id));
  }

  const prospect = selectors.realtorProspects.selected(state);
  const viewingsSummary = prospect?.viewings_summary;
  const offersSummary = prospect?.offers_summary;
  const matchesSummary = prospect?.matching_properties_summary;

  return {
    prospectId: selectedProspect,
    summary: {
      viewings: viewingsSummary,
      offers: offersSummary,
      matches: matchesSummary,
    },
  };
};

export default connect(mapStateToProps)(ProspectItem);
