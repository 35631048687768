import React from 'react';
import PropTypes from 'prop-types';

import withSelectedLead from 'sections/Leads/hocs/withSelectedLead';

import Grid from 'ui/Grid';
import Item from 'ui/Item';
import Container from 'ui/Container';
import Elevation from 'ui/Elevation';
import Button from 'ui/Button';
import Typography from 'ui/Typography';

import Comments from 'sections/Leads/modules/Comments';
// import Breadcrumbs from 'sections/Leads/modules/Breadcrumbs';
import ContactInfo from 'sections/Leads/modules/ContactInfo';
import FollowUp from 'sections/Leads/modules/FollowUp';
import PinnedNote from 'sections/Leads/modules/PinnedNote';
import TempSelect from 'sections/Leads/modules/TempSelect';
import CreateProspect from 'sections/Leads/modules/CreateProspect';
// import Permissions from 'sections/Leads/modules/Permissions';
import Options from 'sections/Leads/modules/OptionsLead';
import MakeContact from 'sections/Leads/modules/MakeContact';
// import LeadTimeline from 'sections/Leads/modules/LeadTimeline';
import ObjectParameters from 'sections/Leads/modules/ObjectParameters';
import FollowUpRemindersModal from 'shared/modules/FollowUpRemindersModal';

import {
  Root,
} from './styledItems';

const Lead = (props) => {
  const {
    selectedLead,
    showLeadDetails,
  } = props;

  return (
    <Root>
      <Grid container spacing={1}>
        {/* <Grid
          item
          xs={12}
          container
          spacing={2}
        >
          <Grid
            item
            xs={5}
            container
            alignItems="center"
          >
            <Breadcrumbs />
          </Grid>
        </Grid> */}

        <Grid
          container
          spacing={2}
        >
          <Grid item xs={12}>
            <Elevation
              padding="s"
              rounded="s"
            >
              <ContactInfo
                leadId={selectedLead}
                toolbar={(
                  <Container
                    spacing={2}
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Item>
                      <Button
                        variant="tertiary"
                        onClick={showLeadDetails(selectedLead)}
                      >
                        <Typography
                          variant="body2"
                          color="link"
                          weight="bold"
                          textDecoration="underline"
                          noMargin
                        >
                          View full profile
                        </Typography>
                      </Button>
                    </Item>
                    <Item>
                      <TempSelect leadId={selectedLead} />
                    </Item>
                    <Item>
                      <MakeContact leadId={selectedLead} />
                    </Item>
                    <Item>
                      <CreateProspect />
                    </Item>
                    <Item>
                      <FollowUp leadId={selectedLead} />
                    </Item>
                    <Item>
                      <PinnedNote />
                    </Item>
                    <Item>
                      <Options />
                    </Item>
                  </Container>
                )}
              />
            </Elevation>
          </Grid>

          {/* <Grid item xs={12}>
            <LeadTimeline />
          </Grid> */}

          <Grid item xs={12}>
            <Elevation
              // padding="m"
              rounded="m"
            >
              <ObjectParameters
                leadId={selectedLead}
              />
            </Elevation>
          </Grid>

          <Grid item xs={12}>
            <Elevation
              padding="m"
              rounded="m"
            >
              <Comments />
            </Elevation>
          </Grid>
        </Grid>

      </Grid>

      <FollowUpRemindersModal
        objectId={selectedLead}
        objectTypeId={26}
      />
    </Root>
  );
};

Lead.defaultProps = {
  selectedLead: null,
};

const {
  func,
  number,
} = PropTypes;

Lead.propTypes = {
  selectedLead: number,
  showLeadDetails: func.isRequired,
};

export default withSelectedLead(Lead);
