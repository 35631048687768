import React, {
  useContext,
  useCallback,
} from 'react';

import Flex from 'ui/Flex';

import editorContext from 'ui/TextEditor/context';

import TemplateItemLabel from 'sections/Communications/modules/Marketing/TemplateComposer/TemplateItemLabel';

const wildcards = [
  'recipient first name',
  'recipient last name',
  'recipient full name',
];

const Wildcards = (props) => {
  const { editor } = useContext(editorContext);

  const onAdd = useCallback((item) => () => {
    if (editor) {
      editor
        .chain()
        .focus()
        .insertContent(' ')
        .insertContent(
          [
            {
              type: 'templateItem',
              content: [
                {
                  type: 'text',
                  text: item,
                },
              ],
            },
          ],
        )
        .insertContent(' ')
        .run();
    }
  }, [
    editor,
  ]);

  return (
    <Flex
      spacing={1}
      flexWrap="wrap"
    >
      {
        wildcards.map((item) => (
          <TemplateItemLabel
            key={item}
            variant="emailTemplateRecipient"
            onClick={onAdd(item)}
            noWrap
            style={{
              textTransform: 'uppercase',
            }}
          >
            {item}
          </TemplateItemLabel>
        ))
      }
    </Flex>
  );
};

export default Wildcards;
