import { connect } from 'react-redux';

import {
  updateProperty,
} from 'store/actions/properties';

import {
  openNewPropertyViewingModal,
} from 'store/actions/newRealtorPropertyViewingModal';

import OptionsCell from './OptionsCell';

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    propertyId,
  } = ownProps;

  return {
    bookShowing: () => {
      dispatch(openNewPropertyViewingModal(propertyId));
    },
    archiveProperty: () => {
      dispatch(updateProperty(propertyId)({
        status: 2,
      }));
    },
  };
};

export default connect(null, mapDispatchToProps)(OptionsCell);
