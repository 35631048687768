import { connect } from 'react-redux';

import selectors from 'store/selectors';

import { history } from 'store';

import {
  updateSelectedLead,
  deleteSelectedLead,
} from 'store/actions/realtorLeads';

import OptionsLead from './OptionsLead';

const mapStateToProps = (state) => {
  const lead = selectors.realtorLeads.selected(state);

  return {
    status: lead.status,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onStatusChange: (status) => {
    dispatch(updateSelectedLead({ status }));
  },
  onDelete: () => {
    dispatch(deleteSelectedLead());
    history.push('/leads');
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OptionsLead);
