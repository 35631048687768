import { connect } from 'react-redux';

import { currency } from '@symplete/symplete-utils/numbers';

import {
  optimizedRealtorLeadsActions,
} from 'store/actions/realtorLeads';

import RangeSlider from 'shared/components/UI/RangeSlider';

const mapStateToProps = (state) => {
  const {
    realtorLeads: {
      overallLeadsSummary,
    },
  } = state;

  if (!overallLeadsSummary) {
    optimizedRealtorLeadsActions.getOverallLeadsSummary();
  }

  const minValue = overallLeadsSummary ? Math.floor(overallLeadsSummary.min_property_value) : 0;
  let maxValue = overallLeadsSummary ? Math.ceil(overallLeadsSummary.max_property_value) : 250000;

  if (overallLeadsSummary?.min_property_value === overallLeadsSummary?.max_property_value) {
    maxValue += 1;
  }

  return {
    min: minValue,
    max: maxValue,
    marks: [
      {
        value: minValue,
        label: `${currency.short(minValue)}`,
      },
      {
        value: maxValue,
        label: `${currency.short(maxValue)}`,
      },
    ],
    formatValueLabel: (value) => `${currency.full(value)}`,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(RangeSlider);
