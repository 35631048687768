import React, {
  useState,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Elevation from 'ui/Elevation';

import Table from 'sections/Opportunities/modules/Table';
import Kanban from 'sections/Opportunities/modules/Kanban';
import OpportunitiesSearchFilter from 'sections/Opportunities/modules/OpportunitiesSearchFilter';
import KPIBar from 'sections/Opportunities/modules/KPIBar';

import TableKanbanSwitch from 'shared/components/UI/TableKanbanSwitch';

import { Root } from './styledItems';

const Prospects = (props) => {
  const [view, setView] = useState('table');

  const handleViewChange = useCallback((newView) => {
    setView(newView);
  });

  return (
    <>
      <Root>
        <Flex
          spacing={2}
          flexWrap="wrap"
          alignItems="center"
        >
          <FlexItem fullWidth>
            <KPIBar interactive />
          </FlexItem>
          <FlexItem fullWidth>
            <Flex spacing={2}>
              <FlexItem fullWidth>
                <OpportunitiesSearchFilter />
              </FlexItem>
              <TableKanbanSwitch
                onChange={handleViewChange}
                currentView={view}
              />
            </Flex>
          </FlexItem>
          {
            view === 'table' && (
              <Elevation
                rounded="m"
              >
                <Table />
              </Elevation>
            )
          }
          {
            view === 'kanban' && (
              <Kanban />
            )
          }
        </Flex>
      </Root>
    </>
  );
};

const {
  // func,
} = PropTypes;

Prospects.propTypes = {
};

export default Prospects;
