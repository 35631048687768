import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
`;

export const SelectWrapper = styled.div`
  position: relative;
  transform: translateY(-100%);
  width: 100%;
  /* border-radius: 6px; */
  /* border: 1px white solid; */
`;

export default {
  Root,
  SelectWrapper,
};
