/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */
import React, {
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import OptionsMenu from 'ui/OptionsMenu';
import MenuItem from 'ui/MenuItem';

const OptionsCell = (props) => {
  const {
  } = props;

  const onRemove = useCallback((closeMenu) => (event) => {
    event.stopPropagation();
    closeMenu();
  }, []);

  return (
    <OptionsMenu {...props}>
      {
        ({ closeMenu }) => (
          <>
            <MenuItem onClick={onRemove(closeMenu)}>
              Remove Contact From Recipient List
            </MenuItem>
          </>
        )
      }
    </OptionsMenu>
  );
};

const {
} = PropTypes;

OptionsCell.propTypes = {
};

export default OptionsCell;
