import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Button from 'ui/Button';
import Modal from 'ui/Modal';
import NewLeadFormSimple from 'sections/Leads/forms/NewLeadFormSimple';

const NewLeadFormModal = (props) => {
  const {
    isVisible,
    createLead,
    closeModal,
    mainEmail,
    personFirstName,
    selectedContact,
    doesPhoneExist,
    doesEmailExist,
    getLeads,
    pageNumber,
    pageSize,
    theme,
  } = props;

  const history = useHistory();
  const [saveAttempt, setSaveAttempt] = useState(false);

  const saveAndOpenLead = useCallback(async () => {
    setSaveAttempt(true);
    if (!personFirstName) return;
    const id = await createLead();
    await getLeads(pageNumber, pageSize);

    if (id) {
      closeModal();
      history.push(`/leads/${id}`);
    }
  }, [
    mainEmail,
    personFirstName,
    selectedContact,
    doesPhoneExist,
    doesEmailExist,
  ]);

  const saveLead = useCallback(async () => {
    setSaveAttempt(true);
    if (!personFirstName) return;
    await createLead();
    await getLeads(pageNumber, pageSize);
    closeModal();
  }, [
    mainEmail,
    personFirstName,
    selectedContact,
    doesPhoneExist,
    doesEmailExist,
  ]);

  return (
    <Modal
      theme={theme}
      width={760}
      visible={isVisible}
      okText="Save"
      onCancel={closeModal}
      title="New Lead"
      destroyOnClose
      footer={[
        <Button variant="secondary" onClick={closeModal}>
          Cancel
        </Button>,
        <Button variant="primary" onClick={saveLead}>
          Save
        </Button>,
        <Button variant="primary" onClick={saveAndOpenLead}>
          Save & Open Lead
        </Button>,
      ]}
    >
      <NewLeadFormSimple saveAttempt={saveAttempt} />
    </Modal>
  );
};

NewLeadFormModal.defaultProps = {
  isVisible: false,
  theme: null,
};

const {
 bool, func, string, shape, number,
} = PropTypes;

NewLeadFormModal.propTypes = {
  isVisible: bool,
  createLead: func.isRequired,
  closeModal: func.isRequired,
  mainEmail: string.isRequired,
  personFirstName: string.isRequired,
  getLeads: func.isRequired,
  pageNumber: number.isRequired,
  pageSize: number.isRequired,
  selectedContact: shape({
    id: number,
    first_name: string,
    last_name: string,
    email_primary: string,
  }).isRequired,
  doesPhoneExist: bool.isRequired,
  doesEmailExist: bool.isRequired,
  theme: shape(),
};

export default NewLeadFormModal;
