import React, {
  useState,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import requests from 'api/requests';

import { datetime } from '@symplete/symplete-utils';

import { NakedButton } from '@symplete/symplete-ui';

import createHtmlMarkup from 'shared/utils/createHtmlMarkup';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Typography from 'ui/Typography';
import ChipList from 'ui/ChipList';
import Tooltip from 'ui/Tooltip';
import Button from 'ui/Button';
import Spin from 'ui/Spin';

import RelatedProperty from 'sections/Integrations/modules/EmailItem/RelatedProperty';
import Attachment from 'sections/Integrations/modules/EmailItem/Attachment';
import ReplyButton from 'sections/Integrations/modules/EmailItemViewer/ReplyButton';

import useEmail from 'sections/Integrations/hooks/useEmail';

import { Wrapper } from './styledItems';

const readMessage = async (message) => {
  try {
    await requests.integrations.emails.update(message.id)({
      tokenId: message.tokenId,
      action: 'read',
    });

    return true;
  } catch (updateError) {
    return false;
  }
};

const EmailItem = (props) => {
  const {
    email: emailProps,
    emailId,
    tokenId,
    isBodyVisible,
    onItemClick: onItemClickProps,
    onReply,
    onForward,
  } = props;

  const {
    email: emailById,
    isLoading,
  } = useEmail({
    tokenId,
    messageId: emailId,
  });

  const email = emailProps || emailById;

  const [isUnread, setIsUnread] = useState(email?.unread);

  const onItemClick = useCallback(async () => {
    if (onItemClickProps) {
      onItemClickProps(email?.id);
    }

    if (isUnread) {
      const readSuccess = await readMessage({
        tokenId: email?.sympleteTokenId,
        id: email?.id,
      });

      if (readSuccess) {
        setIsUnread(false);
      }
    }
  }, [
    isUnread,
    readMessage,
    email?.id,
  ]);

  if (isLoading && !email) {
    return <Wrapper><Spin /></Wrapper>;
  }

  if (!email) {
    return null;
  }

  const emailDate = datetime.unix(email?.date);

  const fullDate = emailDate && emailDate.format('DD MMM, HH:mm');
  const shortDate = emailDate && emailDate.format('HH:mm');

  const emailsTo = Array.isArray(email?.to) ? email.to.map((item) => item?.email).join(', ') : '';

  const content = (
    <Flex
      spacing={2}
      padding="12px 8px"
      flexWrap="wrap"
    >
      <FlexItem xs={18}>
        <Flex
          spacing={1}
          alignItems="center"
        >
          <Typography
            variant="title3"
            weight="bold"
            noMargin
            align="left"
          >
            {email.from?.[0]?.name}
          </Typography>
          <Typography
            variant="title3"
            weight="bold"
            noMargin
          >
            |
          </Typography>
          <Tooltip
            title={email.from?.[0]?.email}
          >
            <Typography
              noMargin
              align="left"
              ellipsis
            >
              {email.from?.[0]?.email}
            </Typography>
          </Tooltip>
        </Flex>
        <Tooltip
          title={emailsTo}
        >
          <Typography
            noMargin
            align="left"
            ellipsis
            style={{ marginTop: 5, width: 'fit-content' }}
          >
            {`To: ${emailsTo}`}
          </Typography>
        </Tooltip>

        {
          !isBodyVisible && (
            <Typography
              noMargin
              ellipsis
              align="left"
              weight="bold"
              style={{
                maxWidth: '600px',
                marginTop: 10,
              }}
            >
              {email.snippet}
            </Typography>
          )
        }

      </FlexItem>

      <FlexItem xs={6}>
        <Flex
          spacing={1}
          justifyContent="right"
          style={{ marginBottom: 15 }}
        >
          <ReplyButton
            onReply={onReply}
            emailId={email.id}
            from={email.from || []}
            cc={email.cc || []}
            replyTo={email.reply_to || []}
          />
          <Button
            icon="long-arrow-alt-right"
            iconType="fontawesome"
            variant="flat"
            iconColor="focus"
            size="s"
            onClick={onForward(email)}
          />
        </Flex>
        <Typography
          align="right"
        >
          {datetime().isSame(emailDate, 'day') ? shortDate : fullDate}
        </Typography>
      </FlexItem>

      <FlexItem fullWidth>
        <Flex
          spacing={1}
          alignItems="baseline"
        >
          {
            email.sympleteAnalysis?.labels?.length > 0 && (
              <ChipList
                items={email.sympleteAnalysis?.labels}
                ellipsis={2}
              />
            )
          }
        </Flex>
        {
          email.sympleteAnalysis?.properties?.length > 0 && (
            <RelatedProperty
              propertyId={email.sympleteAnalysis?.properties[0]}
            />
          )
        }
      </FlexItem>
    </Flex>
  );

  return (
    <Wrapper>
      <Flex
        spacing={1}
        flexDirection="column"
        fullWidth
      >
        <NakedButton
          onClick={onItemClick}
          style={{
            width: '100%',
          }}
        >
          {content}
        </NakedButton>

        {
          isBodyVisible && (
            <Flex
              fullWidth
              flexDirection="column"
              spacing={1}
            >
              {
                email.files?.length > 0 && (
                  <Flex
                    spacing={2}
                    justifyContent="flex-end"
                    flexWrap="wrap"
                    fullWidth
                  >
                    {
                      email.files.map((file) => (
                        <Attachment
                          tokenId={email.sympleteTokenId}
                          attachment={file}
                        />
                      ))
                    }
                  </Flex>
                )
              }
              <Flex
                fullWidth
              >
                {
                  createHtmlMarkup(`<article>${email.body}</article>`) // the `article` wrapper is necessary because we get sanitized emails from the server
                }
              </Flex>
            </Flex>
          )
        }
      </Flex>
    </Wrapper>
  );
};

EmailItem.defaultProps = {
  onItemClick: null,
  email: null,
  emailId: null,
  isBodyVisible: false,
};

const {
  arrayOf,
  bool,
  number,
  shape,
  string,
  func,
} = PropTypes;

EmailItem.propTypes = {
  email: shape({
    date: number,
    subject: string,
    body: string,
    snippet: string,
    from: arrayOf(shape({
      email: string,
      name: string,
    })),
    to: arrayOf(shape({
      email: string,
      name0: string,
    })),
  }),
  onItemClick: func,
  onReply: func.isRequired,
  onForward: func.isRequired,
  emailId: string,
  isBodyVisible: bool,
  tokenId: string.isRequired,
};

export default EmailItem;
