import React from 'react';
import PropTypes from 'prop-types';

import ProgressItem from './ProgressItem';

import {
  Root,
} from './styledItems';

const TransactionProgressColumn = (props) => {
  const {
    tabsCollection,
    stagesStatuses,
  } = props;

  return (
    <Root>
      {
        tabsCollection.map((item) => (
          <ProgressItem
            title={item.displayName || item.name}
            subtitle={stagesStatuses?.[item.name].subtitle}
            iconName={stagesStatuses?.[item.name].status === 'done' ? 'check' : item.iconName}
            status={stagesStatuses?.[item.name].status}
          />
        ))
      }
    </Root>
  );
};

TransactionProgressColumn.defaultProps = {
  tabsCollection: [],
};

const {
  arrayOf,
  element,
  objectOf,
  shape,
  string,
} = PropTypes;

TransactionProgressColumn.propTypes = {
  tabsCollection: arrayOf(shape({
    name: string,
    iconName: string,
    status: string,
    customTabComponent: element,
    displayName: string,
  })),
  stagesStatuses: objectOf(shape({
    status: string,
    subtitle: string,
  })).isRequired,
};

export default TransactionProgressColumn;
