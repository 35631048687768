import { connect } from 'react-redux';

import selectors from 'store/selectors';

import { currency, pretty } from '@symplete/symplete-utils/numbers';

import {
  getOverallSummary,
  getPropertiesForStoreFilter,
} from 'store/actions/properties';

import {
  clearFilter,
  handleCustomFilter,
} from 'store/actions/propertiesFilter';

import KPIBar from 'ui/KPIBar';

const mapStateToProps = (state) => {
  const summary = selectors.properties.overallSummary(state);
  const filterKey = selectors.properties.propertiesFilterKey(state);
  const opportunitiesSummary = selectors.realtorOpportunities.overallSummary(state);

  return {
    values: {
      all: summary?.total || 0,
      mine: summary?.my_listings || 0,
      featured: summary?.featured_properties || 0,
      average_value: pretty.int(summary?.average_value || 0),
      average_dom: summary?.average_dom || 0,
      under_offer: summary?.under_offer || 0,
      pot_comm: currency.short(opportunitiesSummary?.potential_commission),
    },
    indicators: [
      {
        key: 'all',
        title: 'Total Properties',
        iconName: 'home',
        flex: 1,
        selected: filterKey === 'all',
      },
      {
        key: 'mine',
        title: 'My Listings',
        iconName: 'star',
        flex: 1,
        selected: filterKey === 'mine',
      },
      {
        key: 'featured',
        title: 'Featured',
        iconName: 'home',
        flex: 0.5,
        selected: filterKey === 'featured',
      },
      {
        key: 'under_offer',
        title: 'Under Offer',
        iconName: 'clipboard',
        flex: 1,
        disabled: true,
      },
      {
        key: 'pot_comm',
        title: 'Pot. Comm',
        iconName: 'dollar-sign',
        flex: 1,
        disabled: true,
      },
    ],
  };
};

const mapDispatchToProps = (dispatch) => ({
  getData: getOverallSummary,
  onClick: (key) => () => {
    dispatch(clearFilter());
    dispatch(handleCustomFilter(key));
    dispatch(getPropertiesForStoreFilter());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(KPIBar);
