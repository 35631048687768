import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import { NakedButton } from '@symplete/symplete-ui';

import Typography from 'ui/Typography';
import MenuItem from 'ui/MenuItem';
import Flex from 'ui/Flex';
import Spin from 'ui/Spin';

import useMenu from 'ui/hooks/useMenu';

import useIntegrations from 'sections/Integrations/hooks/useIntegrations';

const EmailSelector = (props) => {
  const {
    onChange,
    Root,
    showAll,
  } = props;

  const anchorEl = useRef(null);

  const {
    openMenu,
    closeMenu,
    Menu,
  } = useMenu(anchorEl);

  const {
    integrationsByScope,
    isLoading,
  } = useIntegrations();

  const [selectedEmail, setSelectedEmail] = useState(null);

  const integrations = integrationsByScope?.email;

  useEffect(() => {
    setSelectedEmail('All');
    onChange();
  }, [
    onChange,
  ]);

  useEffect(() => {
    if (integrations && integrations?.length > 0) {
      setSelectedEmail(integrations[0].name);
      onChange(integrations[0].id);
    }
  }, [
    onChange,
    integrations,
  ]);

  const onEmailSelect = useCallback((emailObject) => () => {
    setSelectedEmail(emailObject.name);
    onChange(emailObject.id);
    closeMenu();
  }, [
    onChange,
    closeMenu,
  ]);

  if (isLoading) {
    return (
      <Flex
        fullWidth
        justifyContent="center"
      >
        <Spin />
      </Flex>
    );
  }

  if (!integrations || integrations?.length === 0) {
    return (
      <Typography
        color="placeholder"
        tag="span"
      >
        You need to set up email integration first
      </Typography>
    );
  }

  if (integrations.length === 1) {
    return (
      <Typography tag="span">
        {selectedEmail}
      </Typography>
    );
  }

  return (
    <>
      {
        Root && (
          <Root
            anchorRef={anchorEl}
            openMenu={openMenu}
            selectedBox={selectedEmail}
          />
        )
      }
      {
        !Root && (
          <NakedButton
            ref={anchorEl}
            onClick={openMenu}
            style={{
              display: 'inline-block',
            }}
          >
            <Typography
              tag="span"
            >
              {selectedEmail}
            </Typography>
          </NakedButton>
        )
      }
      <Menu>
        {
          integrations.length > 1 && showAll && (
            <MenuItem
              onClick={onEmailSelect({
                name: 'All',
              })}
            >
              All
            </MenuItem>
          )
        }
        {
          integrations.map((item) => (
            <MenuItem
              key={item.name}
              onClick={onEmailSelect(item)}
            >
              {item.name}
            </MenuItem>
          ))
        }
      </Menu>
    </>
  );
};

EmailSelector.defaultProps = {
  Root: null,
  showAll: true,
};

const {
  bool,
  func,
} = PropTypes;

EmailSelector.propTypes = {
  onChange: func.isRequired,
  Root: func,
  showAll: bool,
};

export default EmailSelector;
