import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  PROPERTY_TYPES,
} from 'constants/realtor.constants';

import ProspectCard from 'sections/Opportunities/components/ProspectCard';

const mapStateToProps = (state, ownProps) => {
  const {
    id,
  } = ownProps;

  const prospect = selectors.realtorProspects.byId(state, id);

  const {
    contact_id: buyerId,
    realtor_property_requirement_id: propertyRequirementId,
  } = prospect;

  const propertyRequirement = selectors.propertyRequirements.byId(state, propertyRequirementId);

  const addresses = propertyRequirement?.addresses
    ? propertyRequirement?.addresses.map((item) => selectors.addresses.byId(state, item)?.address)
    : [];

  const types = propertyRequirement?.property_type
    ? propertyRequirement?.property_type.map((item) => PROPERTY_TYPES[item])
    : [];

  return {
    buyerId,
    propertyRequirementId,

    budget: [prospect.budget_min, prospect.budget_max],
    expiryDate: prospect.prospect_end_date,
    createdAt: prospect.created_at,

    viewingsCount: propertyRequirement.viewings_summary?.total_viewings,
    types,
    addresses,

    // offersCount,
    // viewingsCount,
    // label,
    // notice,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ProspectCard);
