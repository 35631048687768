import styled from 'styled-components';

export const Root = styled.div`
  background-color: ${(props) => props.theme.palette['background-flat-dark']};
  width: 100%;
  display: flex;
  min-height: 44px;
  border-radius: 10px;
`;

export default {
  Root,
};
