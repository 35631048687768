import React, {
  useState,
  useContext,
  useCallback,
} from 'react';

import { pretty } from '@symplete/symplete-utils/numbers';

import Flex from 'ui/Flex';
import Icon from 'ui/Icon';
import Textfield from 'ui/Textfield';
import Button from 'ui/Button';
import Typography from 'ui/Typography';

import OpportunitiesContext from 'sections/OpportunitiesListing/context/OpportunitiesContext';

const isZipValid = (zip) => zip && zip.match(/^\d{5}$/);

const textfieldStyle = {
  minWidth: 310, // a totally magic number :(
};

const ZipInput = () => {
  const [zip, setZip] = useState('');

  const {
    getProperties,
    propertiesCount,
  } = useContext(OpportunitiesContext);

  const handleGetProperties = useCallback(() => {
    getProperties(zip);
  }, [
    zip,
    getProperties,
  ]);

  return (
    <Flex
      spacing={2}
      alignItems="center"
      fullWidth
    >
      <Icon
        size="m"
        name="search"
        color="main-text"
      />
      <Textfield
        onChange={setZip}
        value={zip}
        placeholder="Search by zip, e.g. 90210"
        hideNumberArrows
        max={99999}
        onEnter={isZipValid(zip) && handleGetProperties}
        style={textfieldStyle}
        mask="zip"
      />
      <Button
        variant="primary"
        onClick={handleGetProperties}
        disabled={!isZipValid(zip)}
      >
        Get properties
      </Button>
      {
        propertiesCount > 0 && (
          <Typography
            variant="title1"
            tag="p"
            noMargin
            weight="regular"
          >
            {pretty.int(propertiesCount)}
            {' '}
            total opportunities in
            {' '}
            {zip}
            {' '}
            area
          </Typography>
        )
      }
    </Flex>
  );
};

export default ZipInput;
