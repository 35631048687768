import {
  useState,
  useEffect,
  useCallback,
} from 'react';

import requests from 'api/requests';

import emailCampaignsSocket from 'shared/sockets/emailCampaigns';

const getCampaign = (onSuccess, onError) => async (campaignId) => {
  try {
    const response = await requests.integrations.emailCampaigns.get(campaignId);

    onSuccess(response?.data);
  } catch (newError) {
    onError(newError);
  }
};

const updateCampaign = (onSuccess, onError) => (id) => async (campaignData) => {
  try {
    const result = await requests.integrations.emailCampaigns.update(id)(campaignData);

    onSuccess(result);
  } catch (error) {
    onError(error);
  }
};

const removeCampaign = (onSuccess, onError) => async (id) => {
  try {
    const result = await requests.integrations.emailCampaigns.remove(id);

    onSuccess(result);
  } catch (error) {
    onError(error);
  }
};

const duplicateCampaign = (onSuccess, onError) => async (id) => {
  try {
    const result = await requests.integrations.emailCampaigns.duplicate(id);

    onSuccess(result.data.id);
  } catch (error) {
    onError(error);
  }
};

const useCampaign = (campaignId) => {
  const [campaign, setCampaign] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);

  const [getError, setGetError] = useState(null);
  const [updateError, setUpdateError] = useState(null);
  const [removeError, setRemoveError] = useState(null);
  const [duplicateError, setDuplicateError] = useState(null);

  const get = useCallback(async () => {
    setGetError(null);
    setIsLoading(true);

    await getCampaign(setCampaign, setGetError)(campaignId);

    setIsLoading(false);
  }, [
    campaignId,
  ]);

  useEffect(() => {
    emailCampaignsSocket.handleUpdate(campaignId)(get);
  }, [
    campaignId,
    get,
  ]);

  const update = useCallback(async (campaignData) => {
    setUpdateError(null);
    setIsUpdating(true);

    await updateCampaign(() => get(campaignId), setUpdateError)(campaignId)(campaignData);
    setIsUpdating(false);
  }, [
    campaignId,
  ]);

  const remove = useCallback(async () => {
    setRemoveError(null);
    setIsRemoving(true);

    await removeCampaign(() => {}, setRemoveError)(campaignId);
    setIsRemoving(false);
  }, [
    campaignId,
  ]);

  const duplicate = useCallback(async () => {
    setDuplicateError(null);
    setIsDuplicating(true);

    let newCampaignId = null;

    await duplicateCampaign(
      (id) => {
        newCampaignId = id;
      },
      setDuplicateError,
    )(campaignId);

    setIsDuplicating(false);

    return newCampaignId;
  }, [
    campaignId,
  ]);

  useEffect(() => {
    get(campaignId);
  }, [
    campaignId,
  ]);

  return {
    campaign: campaign || {},

    isLoading,
    isUpdating,
    isRemoving,
    isDuplicating,

    get,
    update,
    remove,
    duplicate,

    getError,
    updateError,
    removeError,
    duplicateError,
  };
};

export default useCampaign;
