import styled from 'styled-components';

export const BoardWrapper = styled.div`
  height: 100%;
  flex: 1;
  overflow-x: scroll;

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 0;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 3px;
  }
`;

export const LaneWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column;
`;

export const LaneHeader = styled.div`
  flex: 0 1 auto;
`;

export const LaneContent = styled.div`
  /* height: calc(100vh - 190px); */
  flex: 1 1 auto;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 5px 5px 5px;

  &::-webkit-scrollbar-track {
    background-color: rgba(128, 128, 128, 0.1);
  }
  &::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(128, 128, 128, 0.2);
    border-radius: 3px;
  }
`;

export const CardWrapper = styled.div`
  margin: 10px 0;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
`;

export default {
  BoardWrapper,
  CardWrapper,
};
