import styled from 'styled-components';

export const Name = styled.h3`
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 22px;
  font-weight: bold;
  color: #172b4d;
  margin-bottom: 7px;
`;

export const InfoItem = styled.p`
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #172b4d;
  margin-bottom: 0;
  text-transform: ${(props) => props.textTransform || 'none'};
`;

export default {
  Name,
  InfoItem,
};
