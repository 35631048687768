import React from 'react';

import Typography from 'ui/Typography';

const NextStage = (value, record) => (
  <Typography
    variant="title3"
    tag="p"
    color="focus"
    style={{
      textTransform: 'capitalize',
    }}
  >
    {value}
  </Typography>
);

export default NextStage;
