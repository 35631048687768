import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import { NakedButton } from '@symplete/symplete-ui';

import { noOp } from 'shared/utility';

import ClickAwayListener from 'ui/ClickAwayListener';
import Typography from 'ui/Typography';
import Tooltip from 'ui/Tooltip';

import useEditMode from 'ui/hooks/useEditMode';

import PlacesAutocomplete from 'shared/components/UI/PlacesAutocompleteV2';

const displayWrapperStyle = {
  // height: 44,
  width: '100%',
};

const AddressInput = (props) => {
  const {
    onBlur,
    onChange,
    value,
    label,
    placeholder,
    focusOnRender,
    inline,
    defaultView,
    displayTypographyProps,
  } = props;

  const {
    mode,
    setEditMode,
    setDisplayMode,
  } = useEditMode(defaultView);

  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [
    value,
  ]);

  const handleChange = useCallback((location) => {
    setCurrentValue(location?.formatted_address);
    onChange({
      address: location?.formatted_address,
    });
  }, [
    onChange,
  ]);

  return (
    <>
      {
        mode === 'edit' && (
          <ClickAwayListener onClickAway={setDisplayMode}>
            <div>
              <PlacesAutocomplete
                label={label}
                placeholder={placeholder}
                variant="outlined"
                defaultValue={currentValue}
                onSelect={handleChange}
                onChange={handleChange}
                selectedValue={currentValue}
                value={currentValue}
                onBlur={onBlur}
                focusOnRender={focusOnRender || defaultView === 'display'}
                inline={inline}
              />
            </div>
          </ClickAwayListener>
        )
      }
      {
        mode === 'display' && (
          <NakedButton
            onClick={setEditMode}
            style={displayWrapperStyle}
          >
            {
              value
                ? (
                  <Tooltip title={value}>
                    <Typography
                      variant="heading"
                      noMargin
                      tag="p"
                      align="left"
                      ellipsis
                      {...displayTypographyProps} // eslint-disable-line react/jsx-props-no-spreading
                      color={value ? 'main-text' : 'placeholder'}
                      fontStyle={value ? 'normal' : 'italic'}
                    >
                      { value || placeholder }
                    </Typography>
                  </Tooltip>
                  )
                : (
                  <Typography
                    variant="heading"
                    noMargin
                    tag="p"
                    align="left"
                    ellipsis
                    {...displayTypographyProps} // eslint-disable-line react/jsx-props-no-spreading
                    color={value ? 'main-text' : 'placeholder'}
                    fontStyle={value ? 'normal' : 'italic'}
                  >
                    { value || placeholder }
                  </Typography>
                )
            }
          </NakedButton>
        )
      }
    </>

  );
};

AddressInput.defaultProps = {
  value: null,
  label: 'Address',
  placeholder: 'Address',
  onBlur: noOp,
  focusOnRender: false,
  inline: false,
  defaultView: 'edit',
  displayTypographyProps: {},
};

const {
  bool,
  func,
  oneOf,
  string,
  shape,
} = PropTypes;

AddressInput.propTypes = {
  onBlur: func,
  value: string,
  label: string,
  placeholder: string,
  focusOnRender: bool,
  inline: bool,
  defaultView: oneOf(['edit', 'display']),
  onChange: func.isRequired,
  displayTypographyProps: shape({}),
};

export default AddressInput;
