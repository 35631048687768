import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import TabContext from '@material-ui/lab/TabContext';
import TabPanel from '@material-ui/lab/TabPanel';

import { makeStyles } from '@material-ui/core/styles';

import Tabs from 'ui/Tabs';
import Tab from 'ui/Tab';
import Typography from 'ui/Typography';
import Elevation from 'ui/Elevation';

import CompleteTransactionStageButton from 'sections/Transactions/modules/CompleteTransactionStageButton';
import TransactionExpiryDateInput from 'sections/Transactions/modules/TransactionExpiryDateInput';

import TabComponent from './TabComponent';

const useStyles = makeStyles({
  tabs: {
    borderBottom: 'solid 1px #D8E5FF',
    // borderTopLeftRadius: '20px',
    // borderTopRightRadius: '20px',
  },
  tab: {
    paddingTop: 10,
    paddingBottom: 15,
    backgroundColor: '#F2F6FE',
    alignItems: 'flex-start',
    position: 'relative',
    zIndex: 1,
  },
  tabSelected: {
    paddingTop: 10,
    paddingBottom: 15,
    backgroundColor: '#fff',
    alignItems: 'flex-start',
    position: 'relative',
    zIndex: 1,
  },
});

const TransactionTabs = (props) => {
  const {
    selectedTab,
    tabsCollection,
    transactionId,
    stagesStatuses,
  } = props;

  const classes = useStyles();

  const [tab, setTab] = useState(null);

  const handleTabChange = useCallback((event, value) => {
    setTab(value);
  }, []);

  useEffect(() => {
    setTab(selectedTab);
  }, [
    selectedTab,
  ]);

  return (
    <Elevation rounded hideOverflow>
      <TabContext value={tab}>
        <Tabs
          variant="fullWidth"
          value={tab}
          onChange={handleTabChange}
          TabIndicatorProps={{
            style: {
                display: 'none',
            },
          }}
          className={classes.tabs}
          id="transaction-tabs"
        >
          {
            tabsCollection.map((item) => (
              <Tab
                label={(
                  item.customTabComponent || (
                    <TabComponent
                      title={item.displayName || item.name}
                      subtitle={stagesStatuses?.[item.name].subtitle}
                      iconName={stagesStatuses?.[item.name].status === 'success' ? 'check' : item.iconName}
                      status={stagesStatuses?.[item.name].status}
                      statusSubtitle={stagesStatuses?.[item.name].statusSubtitle}
                      isToday={item.isToday}
                    />
                  )
                )}
                value={item.name}
                key={item.name}
                className={tab === item.name ? classes.tabSelected : classes.tab}
                id={`${item.name} transaction tab`}
              />
            ))
          }
        </Tabs>
        {
          tabsCollection.map((item, index) => (
            <TabPanel
              value={item.name}
              index={index}
              key={item.name}
              style={{ paddingTop: 40 }}
            >
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Grid container justify="space-between">
                    <Grid item>
                      <Typography
                        variant="h2"
                        weight="bold"
                        style={{ textTransform: 'capitalize' }}
                      >
                        {item.displayName || item.name}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          <TransactionExpiryDateInput
                            transactionId={transactionId}
                            stage={item.name}
                          />
                        </Grid>
                        <Grid item>
                          <CompleteTransactionStageButton
                            transactionId={transactionId}
                            stage={item.name}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {item.content ? item.content(transactionId) : 'Under construction'}
                </Grid>
              </Grid>
            </TabPanel>
          ))
        }
      </TabContext>
    </Elevation>
  );
};

TransactionTabs.defaultProps = {
  stagesStatuses: {},
  selectedTab: 'initial documents',
};

const {
  string,
  arrayOf,
  number,
  shape,
  element,
  objectOf,
} = PropTypes;

TransactionTabs.propTypes = {
  selectedTab: string,
  transactionId: number.isRequired,
  tabsCollection: arrayOf(shape({
    name: string,
    iconName: string,
    status: string,
    customTabComponent: element,
    displayName: string,
  })),
  stagesStatuses: objectOf(shape({
    status: string,
    subtitle: string,
  })),
};

export default TransactionTabs;
