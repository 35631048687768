import styled from 'styled-components';

export const Root = styled.div`
  width: 350px;
  padding: 15px;
  border: 1px solid transparent;
  ${(props) => props.type == 'email' && `
    background-color: #F2F6FE;
    border: 1px solid #367BED;
    border-radius: 20px;
    &:before {
      content: "";
      position: absolute;
      top: -webkit-calc(100% - 20px);
      top: calc(100% - 20px);
      left: 0;
      right: 0;
      height: 40px;
      margin: 0 auto;
      width: 40px;
      background: #F2F6FE;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      border-bottom: inherit;
      border-right: inherit;
      box-shadow: inherit;
    }
  `}
  &:hover {
    background-color: ${(props) => props.theme?.palette?.['background-flat']};
    border: 1px solid #367BED;
    border-radius: 20px;
    &:before {
      content: "";
      position: absolute;
      top: -webkit-calc(100% - 20px);
      top: calc(100% - 20px);
      left: 0;
      right: 0;
      height: 40px;
      margin: 0 auto;
      width: 40px;
      background: #F2F6FE;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      border-bottom: inherit;
      border-right: inherit;
      box-shadow: inherit;
    }
  }
`;

export const ActionButtonSection = styled.div`
  width: 100%;
`;
