import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { ThemeContext } from 'styled-components';

import moment from 'moment';

import Icon from 'ui/Icon';
import Typography from 'ui/Typography';

import {
  Wrapper,
  IconContainer,
  TodayMarker,
} from './styledItems';

const TabComponent = (props) => {
  const {
    iconName,
    status,
    title,
    subtitle,
    statusSubtitle,
    // isToday,
  } = props;

  const theme = useContext(ThemeContext);

  return (
    <>
      <Wrapper>
        {
          iconName && (
            <IconContainer backgroundColor={theme.palette[status]}>
              <Icon
                name={iconName}
                color="main-text-inversed"
                size="s"
              />
            </IconContainer>
          )
        }

        {
          title && (
            <Typography
              variant="h4"
              weight={600}
              style={{ marginBottom: 6, textTransform: 'capitalize' }}
            >
              {title}
            </Typography>
          )
        }
        {
          // !isToday && (
          true && (
            <>
              {
                subtitle && (
                  <Typography
                    weight={400}
                    color="disabled"
                    variant="body2"
                    style={{ textTransform: 'none' }}
                  >
                    {subtitle}
                  </Typography>
                )
              }
              {
                statusSubtitle && (
                  <Typography
                    noMargin
                    weight="bold"
                    variant="body2"
                    style={{ textTransform: 'none' }}
                    color={status}
                  >
                    {statusSubtitle}
                  </Typography>
                )
              }
            </>
        )
      }
      </Wrapper>
      {
        // isToday && (
        false && (
          <TodayMarker>
            <Typography
              variant="caption2"
              color="main-text"
              noWrap
              noMargin
              style={{ textTransform: 'none' }}
            >
              Today:
              {' '}
              {moment().format('MMM D, YYYY')}
            </Typography>
          </TodayMarker>
        )
      }
    </>
  );
};

TabComponent.defaultProps = {
  title: null,
  subtitle: null,
  iconName: null,
  status: 'in progress',
  statusSubtitle: null,
};

const {
  string,
  oneOf,
} = PropTypes;

TabComponent.propTypes = {
  title: string,
  subtitle: string,
  iconName: string,
  status: oneOf(['in progress', 'done', 'attention']),
  statusSubtitle: string,
};

export default TabComponent;
