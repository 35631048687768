import { connect } from 'react-redux';
import { postNewReminder, toDoListSetViewedAction } from 'store/actions/to-do-list';

import TitleForm from './TitleForm';

const mapStateToProps = (state) => ({
  remindersInf: state.todolist.remindersInf,
});

const mapDispatchToProps = {
  toDoListSetViewedAction,
  postNewReminder,
};

export default connect(mapStateToProps, mapDispatchToProps)(TitleForm);
