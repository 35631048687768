import React from 'react';

import ColorfulLabel from 'ui/ColorfulLabel';

const statuses = {
  7: {
    label: 'Setting up',
    variant: 'warning',
  },
  3: {
    label: 'In progress',
    variant: 'focus',
  },
  6: {
    label: 'Completed',
    variant: 'success',
  },
};

const Status = (value, record) => (
  <ColorfulLabel
    variant={statuses[value].variant}
    title={statuses[value].label}
  />
);

export default Status;
