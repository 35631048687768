import { connect } from 'react-redux';

import { min, max } from 'shared/utility';

import selectors from 'store/selectors';

import { openSearchMLSModal } from 'store/actions/searchMLSModal';

import {
  updateMatchingProperty,
  resetMatchingPropertyFilters,
} from 'store/actions/realtorMatchingProperties';

// viewings
import {
  openNewPropertyViewingModal,
} from 'store/actions/newRealtorPropertyViewingModal';

import {
  setNewPropertyViewingFormField,
} from 'store/actions/newRealtorPropertyViewingForm';

// offers
import {
  openLogOfferModal,
} from 'store/actions/realtorLogOfferModal';

// match rejection
import {
  openRejectionModal,
} from 'store/actions/realtorMatchingPropertyRejectionModal';

import {
  openAcceptCounterModal,
} from 'store/actions/realtorAcceptCounterModal';

import { getProspectById } from 'store/actions/realtorProspects';

import MatchingPropertiesView from './MatchingPropertiesView';

const getOffersIds = (match) => {
  const rootOfferId = match.offers
    ? min(match.offers)
    : null;

  const counterId = match.offers
    ? max(match.offers)
    : null;

  return [counterId, rootOfferId];
};

const mapStateToProps = (state, ownProps) => {
  const {
    prospectId,
  } = ownProps;

  const prospect = selectors.realtorProspects.byId(state, prospectId);
  const getMatchingProperty = (matchId) => selectors.realtorMatchingProperties.byId(matchId)(state);

  return {
    prospectContactId: prospect?.contact_id,
    getMatchingProperty,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  openSearchMLSModal: () => {
    dispatch(openSearchMLSModal());
  },

  onCreateViewing: ({ contactId, propertyId, onSave }) => {
    dispatch(setNewPropertyViewingFormField('buyer')(contactId));
    dispatch(setNewPropertyViewingFormField('property_id')(propertyId));
    dispatch(setNewPropertyViewingFormField('onSave')(onSave));

    dispatch(openNewPropertyViewingModal(null, { property: true, sellerAgent: true }));
  },

  onLogOffer: ({
    propertyId,
    buyerContactId,
    onAfterSubmit,
  }) => {
    dispatch(openLogOfferModal(
      {
        // callbacks
        onAfterSubmit,

        // defaultValues
        type: 'prospect',
        prospectId: ownProps.prospectId,
        propertyId,
        buyerContactId,
      },
    ));
  },

  updateMatchingProperty: (matchId) => async (updateData) => {
    await dispatch(updateMatchingProperty(matchId)(updateData));

    dispatch(getProspectById(ownProps.prospectId));

    dispatch(resetMatchingPropertyFilters());
  },

  onRejectProperty: ({ matchId, onSave }) => {
    dispatch(openRejectionModal(matchId, onSave));
  },

  onWon: ({
    rootOfferId,
    offerId,
    onSave,
  }) => {
    dispatch(openAcceptCounterModal({
      offerId,
      rootOfferId,
      onAfterSubmit: onSave,
    }));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,

  updateMatchingProperty: (matchId) => async (updateData, currentData) => {
    if (currentData?.status < updateData?.status) {
      if (updateData?.status === 2) { // -> viewings
        const {
          realtor_property_id: propertyId,
        } = stateProps.getMatchingProperty(matchId);

        dispatchProps.onCreateViewing({
          contactId: stateProps.prospectContactId,
          propertyId,
          onSave: () => dispatchProps.updateMatchingProperty(matchId)(updateData),
        });
      }

      if (updateData?.status === 3) { // -> offers
        const {
          realtor_property_id: propertyId,
          realtor_contact_id: buyerContactId,
        } = stateProps.getMatchingProperty(matchId);

        dispatchProps.onLogOffer({
          propertyId,
          buyerContactId,
          onAfterSubmit: () => dispatchProps.updateMatchingProperty(matchId)(updateData),
        });
      }

      if (updateData?.status === 4) { // -> won
        if (currentData?.status === 3) {
          const match = stateProps.getMatchingProperty(matchId);

          const [
            counterId,
            rootOfferId,
          ] = getOffersIds(match);

          if (rootOfferId) {
            dispatchProps.onWon({
              rootOfferId,
              offerId: counterId,
              onSave: () => dispatchProps.updateMatchingProperty(matchId)(updateData),
            });
          }
        }
      }

      if (updateData?.status === 5) { // -> rejected
        dispatchProps.onRejectProperty({
          matchId,
          onSave: () => dispatchProps.updateMatchingProperty(matchId)(updateData),
        });
      }
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(MatchingPropertiesView);
