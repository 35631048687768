import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'ui/Button';

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 10px 30px;
  background-image: linear-gradient(to bottom, rgba(61,91,144,0.31), rgba(255,255,255,0.31));
  z-index: 10;
`;

const ButtonsGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const ButtonsRow = (props) => {
  const {
    onDownload,
    onPrint,
    onDelete,
  } = props;

  return (
    <Root>
      <ButtonsGroup>
        <Button
          variant="secondary"
          icon="download-cloud"
          onClick={onDownload}
        >
          Download
        </Button>
        <Button
          variant="secondary"
          icon="printer"
          onClick={onPrint}
        >
          Print
        </Button>
      </ButtonsGroup>
      <Button
        variant="secondary"
        icon="trash2"
        onClick={onDelete}
      />
    </Root>
  );
};

const { func } = PropTypes;

ButtonsRow.propTypes = {
  onDownload: func.isRequired,
  onPrint: func.isRequired,
  onDelete: func.isRequired,
};

export default ButtonsRow;
