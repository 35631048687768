import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  margin-top: -4px;
  .range-date-picker-text {
    margin-top: auto;
    margin-bottom: auto;
    padding: 0 20px;
  }
`;

export default {
  Wrapper,
};
