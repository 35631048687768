import React, {
  useMemo,
} from 'react';

import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Tag from 'ui/Tag';

import Image from 'shared/components/UI/TableCells/RealtorCells/PropertyImageCell';
import Address from 'shared/components/UI/TableCells/RealtorCells/PropertyLocationCell';
import Parameters from 'shared/modules/PropertyParametersIndicators';

const DetailsCell = (props) => {
  const {
    propertyId,
    property,
  } = props;

  const tagItems = useMemo(() => {
    const tags = [];

    if (property?.is_reo) tags.push('reo');
    if (property?.is_pocket_listing) tags.push('off-market listing');

    return (
      <Flex flexDirection="column" spacing={1}>
        {
          tags.map((tag) => (
            <Tag
              variant={tag}
              size="s"
            >
              {tag.toUpperCase()}
            </Tag>
          ))
        }
      </Flex>
    );
  }, [
    property?.is_reo,
    property?.is_pocket_listing,
  ]);

  return (
    <Flex spacing={2}>
      <FlexItem xs={6}>
        <Image propertyId={propertyId} />
      </FlexItem>
      <FlexItem xs={14}>
        <Flex
          spacing={1}
          flexDirection="column"
        >
          <Address addressId={property.address_id} />
          <Parameters propertyId={propertyId} />
        </Flex>
      </FlexItem>
      <FlexItem xs={4}>
        {tagItems}
      </FlexItem>
    </Flex>
  );
};

DetailsCell.defaultProps = {

};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

DetailsCell.propTypes = {

};

export default DetailsCell;
