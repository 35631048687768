import styled from 'styled-components';

import { NakedButton } from '@symplete/symplete-ui';

export const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const StyledButton = styled(NakedButton)`
&& {
  width: 22px;
  height: 22px;
  text-align: center;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  border-radius: 3px;

  ${(props) => (
    props.color ? (
      `
        background-color: ${props.color};
        color: #fff;
        border: none;
      `
    ) : (
      `
        background-color: transparent;
        color: #a1bdcb;
        border: 1px solid #a1bdcb;
      `
    ))}
}
`;
