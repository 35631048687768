const setNavbarColor = (currentTheme, value) => {
    const newTheme = {
      ...currentTheme,
    };

    if (newTheme.colors[value]) {
      newTheme.palette.navColorPrimary = newTheme.colors[value].primary;
      newTheme.palette.navColorSecondary = newTheme.colors[value].secondary;
      newTheme.palette.navColorTertiary = newTheme.colors[value].tertiary;
      newTheme.palette.navColorDivider = newTheme.colors[value].divider;
      newTheme.palette.navColorActiveFont = newTheme.colors[value].activeFont;
    }

    return newTheme;
};

export default setNavbarColor;
