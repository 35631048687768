/* eslint-disable react/prop-types, react/destructuring-assignment */
import React, {
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import InfiniteScroll from 'react-infinite-scroller';

import { Spin } from 'antd';
import CenterBox from 'shared/components/UI/CenterBox';
import LaneHeader from 'shared/components/LaneHeader';
import MatchingCard from 'shared/modules/MatchingBuyerCard';
import MatchingPropertiesCard from 'shared/modules/MatchingPropertiesCard';
import ShowingCard from 'shared/modules/ShowingCard';

import ColumnSelector from 'sections/MLS/components/ColumnSelector';

import {
  Root,
} from './styledItems';

const objectComponents = {
  matched: (matchingId, updateList) => (
    <MatchingCard
      id={matchingId}
      updateList={updateList}
    />
  ),
  arrangeShowing: (matchingId, updateList) => (
    <MatchingPropertiesCard
      id={matchingId}
      updateList={updateList}
    />
  ),
  showing: (showingId, updateList) => (
    <ShowingCard
      id={showingId}
      updateList={updateList}
    />
  ),
  offers: () => null,
};

const ListingFocusColumn = (props) => {
  const {
    title,
    items,
    objectKey,
    loadMore,
    itemsCount,
    filterItems,
    onHeaderButtonClick,
  } = props;

  const [isLoad, setLoad] = useState(false);
  const [selectedFilter, setFilter] = useState(filterItems[0].filter);
  const [selectedValue, setFilterValue] = useState(filterItems[0].value);

  const updateList = useCallback(() => {
    loadMore(0, 20, true, selectedFilter);
  }, [
    loadMore,
    filterItems,
    selectedFilter,
  ]);

  const selectFilter = useCallback((value, filter) => {
    setFilter(filter);
    setFilterValue(value);
    setLoad(true);
    window.setTimeout(() => {
      setLoad(false);
    }, 500);
    loadMore(0, 20, true, filter);
  }, [loadMore]);

  return (
    <Root>
      <LaneHeader
        title={title}
        onAddClick={onHeaderButtonClick(selectedFilter)}
      />

      <ColumnSelector
        items={filterItems}
        onSelect={selectFilter}
        selectedValue={selectedValue}
      />

      {
        isLoad ? (
          <CenterBox
            style={{
              marginTop: '20px',
            }}
          >
            <Spin />
          </CenterBox>
        ) : (
          <InfiniteScroll
            initialLoad
            className="items-container"
            pageStart={0}
            loadMore={loadMore(items?.length, 20, false, selectedFilter)} // eslint-disable-line react/jsx-no-bind
            hasMore={itemsCount > items?.length}
            loader={<CenterBox><Spin /></CenterBox>}
            useWindow={false}
          >
            {
              items?.map((itemId) => objectComponents[objectKey](itemId, updateList))
            }
          </InfiniteScroll>
        )
      }
    </Root>
  );
};

const {
  arrayOf,
  func,
  number,
  oneOfType,
  shape,
  string,
} = PropTypes;

ListingFocusColumn.propTypes = {
  title: string.isRequired,
  filterItems: arrayOf(shape()).isRequired,
  items: oneOfType([
    arrayOf(string),
    arrayOf(shape({
      id: number,
      type: string,
      leadId: number,
    })),
    arrayOf(number),
  ]).isRequired,
  objectKey: string.isRequired,
  loadMore: func.isRequired,
  itemsCount: number.isRequired,
};

export default ListingFocusColumn;
