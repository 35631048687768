import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateTransaction,
} from 'store/actions/realtorTransactions';

import CompleteButton from 'sections/Transactions/components/CompleteButton';

const stageNameDict = {
  'initial documents': 'initial_documents_completed_at',
  'seller disclosure': 'seller_disclosure_completed_at',
  inspections: 'inspections_completed_at',
  appraisal: 'appraisal_completed_at',
  loan: 'loan_completed_at',
  'final walkthrough': 'final_walkthrough_completed_at',
  'close escrow': 'close_escrow_completed_at',
  'move out date': 'move_out_date_completed_at',
};

const mapStateToProps = (state, ownProps) => {
  const { stage, transactionId } = ownProps;

  const transaction = selectors.realtorTransactions.byId(state, transactionId);

  const transactionPropName = stageNameDict[stage];

  const stageCompletedValue = transaction[transactionPropName];

  return {
    isComplete: !!stageCompletedValue,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleStageComplete: (isComplete) => {
    const transactionPropName = stageNameDict[ownProps.stage];

    dispatch(updateTransaction(ownProps.transactionId)({
      [transactionPropName]: isComplete ? null : new Date(),
    }));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onClick: () => {
    dispatchProps.toggleStageComplete(stateProps.isComplete);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CompleteButton);
