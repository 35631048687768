import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import {
  Checkbox,
  Rate,
  Icon,
} from 'antd';
import { callAlert } from 'ui/Alert';
import { isEmpty } from 'shared/utility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classnames from 'classnames';

import AddUserButton from 'shared/components/ButtonAvatar';
import createHtmlMarkup from 'shared/utils/createHtmlMarkup';
import ReminderTextEditor from '../../ReminderTextEditor';
import ReminderAssignedAgents from '../ReminderAssignedAgents';

import {
  EditableTextContainer,
} from './styledItems';

const portal = document.createElement('div');

portal.classList.add('my-todolist-portal');

document.body.appendChild(portal);

class ReminderItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isReminderEditMode: false,
    };
  }

  changeReminderTitle = async (value) => {
    const {
      toDoListSetViewedAction,
      reminderChangeTitleAction,
      element,
    } = this.props;

    const {
      reminderID,
    } = element;

    const reminderClearTitle = value.slice(3, -4);

    const isSpaceText = !reminderClearTitle.trim().length;

    if (isEmpty(reminderClearTitle)) {
      callAlert.warning('Text is empty');
      return;
    }
    if (isSpaceText) {
      callAlert.warning('Incorrect reminder name!');
      return;
    }
    await reminderChangeTitleAction(reminderID, value);
    toDoListSetViewedAction();

    this.setState({ isReminderEditMode: false });
  }

  onClickCancel = () => {
    this.setState({ isReminderEditMode: false });
  }

  onReminderClick = (event) => {
    const linkObjectButton = document.getElementsByClassName('link');
    const sympleteObjectButton = document.getElementsByClassName('object-link');
    // Checking whether the link button is clicked

    const isOnLinkClick = linkObjectButton[0] !== undefined && linkObjectButton[0].contains(event.target);
    const isOnSympleteObjectClick = (
      sympleteObjectButton[0] !== undefined
      && sympleteObjectButton[0].contains(event.target)
    );

    if (isOnLinkClick) {
      return;
    }

    if (isOnSympleteObjectClick) {
      return;
    }

    this.setState({ isReminderEditMode: true });
  }

  render() {
    const {
      element,
      isShowAssignedBy,
      isShowImportantStar,
      assigneeByIconWithoutUser,
      EmployeeInf,
      openAgentAssignModal,
      changeImportance,
      deleteReminder,
      checkBoxIndex,
      changeFulfillment,
      provided,
      snapshot,
    } = this.props;

    const {
      isReminderEditMode,
    } = this.state;

    const usePortal = snapshot.isDragging;

    const styleForReminder = {
      borderStyle: (snapshot.isDragging && 'solid'),
      borderWidth: (snapshot.isDragging && 2),
      borderColor: (snapshot.isDragging && '#2a9afc'),
      backgroundColor: (snapshot.isDragging && '#f7f9fa'),
      ...provided.draggableProps.style,
    };

    const reminder = (
      <div
        ref={provided.innerRef}
        // the react-beautiful-dnd documentation assumes using spreading at this point
        // https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/api/droppable.md
        {...provided.draggableProps} // eslint-disable-line react/jsx-props-no-spreading
        style={styleForReminder}
        className="reminder-item"
      >
        <Checkbox
          className="reminder-item__checkbox"
          defaultChecked={element.isCompleted}
          onChange={changeFulfillment(element.reminderID, !element.isCompleted)}
        />
        <div
          className="reminder-item__content"
        >
          <EditableTextContainer
            showEditor={isReminderEditMode}
            className={classnames(
              'reminder-item-text',
              {
                'item-text': !element.isCompleted,
                'item-completed-text': element.isCompleted,
              },
            )}
          >
            <div
              aria-hidden
              onClick={this.onReminderClick}
              onKeyPress={this.onReminderClick}
              className="displayed-value"
            >
              <span className="reminder-item__item-name-number">
                {`${checkBoxIndex}.`}
              </span>
              <div>
                {createHtmlMarkup(element.reminderTitle)}
              </div>
            </div>

            <ReminderTextEditor
              onConfirm={this.changeReminderTitle}
              defaultValue={element.reminderTitle}
              onClickCancel={this.onClickCancel}
            />
          </EditableTextContainer>
          <br />
          {isShowAssignedBy && (
            <div className="reminder-item__creator">
              {`Assigned by ${element.Name} ${element.Surname}`}
            </div>
          )}
        </div>
        <div className="reminder-item__item-condition">
          {isShowImportantStar && (
            <Icon
              theme="filled"
              type="star"
              className="reminder-item__icon-star"
            />
          )}
          <ReminderAssignedAgents
            className="reminder-item__assigned-agents"
            showButton={false}
            agents={assigneeByIconWithoutUser || null}
          />
        </div>
        <div
          className="reminder-item__managment"
        >
          <AddUserButton
            showButton
            color="#6b88a4"
            backgroundColor="#d5d7d8"
            className="add-user button"
            onClick={openAgentAssignModal(
              element.reminderID,
              EmployeeInf.EmployeeID,
              assigneeByIconWithoutUser,
            )}
          />
          <Rate
            className="star"
            count={1}
            defaultValue={element.isImportant}
            onChange={changeImportance(element.reminderID, !element.isImportant)}
          />
          <FontAwesomeIcon
            className="delete button"
            icon="times"
            onClick={deleteReminder(element.reminderID)}
          />
          <button
            type="button"
            className="control-lever button"
            {...provided.dragHandleProps} // eslint-disable-line react/jsx-props-no-spreading
          >
            <FontAwesomeIcon
              className="control-lever-icon"
              icon="bars"
            />
          </button>
        </div>
      </div>
    );

    if (!usePortal) {
      return reminder;
    }

    return ReactDOM.createPortal(reminder, portal);
  }
}

ReminderItem.defaultProps = {
  isShowAssignedBy: false,
  isShowImportantStar: false,
};

const {
  shape,
  func,
  string,
  number,
  bool,
  arrayOf,
  object,
} = PropTypes;

ReminderItem.propTypes = {
  element: shape({
    isCompleted: bool,
    isImportant: bool,
    reminderID: number.isRequired,
    reminderTitle: string.isRequired,
    Name: string.isRequired,
    Surname: string.isRequired,
  }).isRequired,
  EmployeeInf: shape({
    EmployeeID: number,
  }).isRequired,
  isShowAssignedBy: bool,
  isShowImportantStar: bool,
  assigneeByIconWithoutUser: arrayOf({
    id: number,
  }).isRequired,
  openAgentAssignModal: func.isRequired,
  changeImportance: func.isRequired,
  deleteReminder: func.isRequired,
  toDoListSetViewedAction: func.isRequired,
  reminderChangeTitleAction: func.isRequired,
  checkBoxIndex: number.isRequired,
  changeFulfillment: func.isRequired,
  provided: object.isRequired, // eslint-disable-line react/forbid-prop-types
  snapshot: object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default ReminderItem;
