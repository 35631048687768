import { connect } from 'react-redux';

import selectors from 'store/selectors';

import Breadcrumbs from './Breadcrumbs';

const mapStateToProps = (state) => {
  const transaction = selectors.realtorTransactions.selected(state);

  let propertyId;
  let property;
  let address;

  if (transaction.realtor_property_id) {
    propertyId = transaction.realtor_property_id;
  } else if (transaction.realtor_client_id) {
    const client = selectors.realtorClients.byId(state, transaction.realtor_client_id);
    propertyId = client?.realtor_property_id;
  }

  if (propertyId) {
    property = selectors.properties.byId(state, propertyId);

    address = selectors.addresses.byId(state, property.address_id);
  }

  return {
    name: address?.address,
  };
};

export default connect(mapStateToProps)(Breadcrumbs);
