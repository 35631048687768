import datetime from '@symplete/symplete-utils/datetime';

import formats from 'shared/formats';

const dateFormat = (date) => datetime(date).format(formats.dateTime.full);

const startOfDay = (date) => datetime(date).startOf('day').utc();
const endOfDay = (date) => datetime(date).endOf('day').utc();

const getFilterByToday = (date) => {
  const dateFrom = dateFormat(startOfDay(date));
  const dateTo = dateFormat(endOfDay(date));

  return {
    date_from: dateFrom,
    date_to: dateTo,
    scheduledDate: [dateFrom, dateTo],
  };
};

export default getFilterByToday;
