import { connect } from 'react-redux';

import {
  optimizedRealtorLeadsActions,
} from 'store/actions/realtorLeads';

import DateRangePicker from 'shared/components/UI/DateRangePicker';

const mapStateToProps = (state) => {
  const {
    realtorLeads: {
      overallLeadsSummary,
    },
  } = state;

  if (!overallLeadsSummary) {
    optimizedRealtorLeadsActions.getOverallLeadsSummary();
  }

  const dateFrom = overallLeadsSummary ? overallLeadsSummary.min_lead_date : null;
  const dateTo = overallLeadsSummary ? overallLeadsSummary.max_lead_date : null;

  return {
    dateFrom,
    dateTo,
    fromLabel: 'Date create from',
    toLabel: 'Date create to',
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(DateRangePicker);
