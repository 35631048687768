import React from 'react';

import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import OurThemeProvider from 'shared/themes/OurThemeProvider';
import withTheme from 'shared/hocs/withTheme';

import routes from 'sections/Offers/routes';

import OffersList from 'sections/Offers/pages/OffersList';

import { defaultTheme } from '@symplete/symplete-ui';
import theme from './theme';

const Offers = () => {
  const Theme = withTheme(defaultTheme, theme)(OurThemeProvider);

  return (
    <Theme>
      <Switch>
        <Route
          exact
          path={routes.sectionRoot}
          component={OffersList}
        />

        <Redirect to={routes.sectionRoot} />
      </Switch>
    </Theme>
  );
};

export default Offers;
