import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { NakedButton } from '@symplete/symplete-ui';

import SearchItemClient from 'shared/components/UI/SearchItemClient';
import SearchCategoryBlock from 'shared/components/UI/SearchCategoryBlock';

import withClientData from 'sections/Clients/hocs/withClientData';

const SearchItemClientModule = withClientData(SearchItemClient);

const Item = ({ client, handleClick }) => (handleClick
  ? (
    <NakedButton
      onClick={handleClick(client.id)}
      key={client.id}
    >
      <SearchItemClientModule
        clientId={client.id}
      />
    </NakedButton>
  )
  : (
    <Link
      to={`/clients/${client.id}`}
      key={client.id}
    >
      <SearchItemClientModule
        clientId={client.id}
      />
    </Link>
  )
);

const ClientsSearch = (props) => {
  const [searchResult, setSearchResult] = useState([]);

  const {
    search,
    onSearch,
    onSelect,
  } = props;

  useEffect(() => {
    const fetchData = async () => {
      const data = await onSearch(search);
      setSearchResult(data);
    };

    fetchData();
  }, [search]);

  const handleClick = onSelect
    ? useCallback((clientId) => (event) => {
        event.preventDefault();
        onSelect(29)(clientId);
      })
    : null;

  return (
    <SearchCategoryBlock
      title="clients"
      iconName="star"
    >
      {
        searchResult && searchResult.map((client) => (
          <Item
            key={client?.id}
            client={client}
            handleClick={handleClick}
          />
        ))
      }
    </SearchCategoryBlock>
  );
};

ClientsSearch.defaultProps = {
  search: '',
};

const {
  func,
  string,
} = PropTypes;

ClientsSearch.propTypes = {
  search: string,
  onSearch: func.isRequired,
};

export default ClientsSearch;
