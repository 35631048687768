import styled from 'styled-components';

import { NakedButton } from '@symplete/symplete-ui';

export const Root = styled(NakedButton)`
&& {
  padding-left: 10px;
  border-left: 2px solid #6b88a4;
  
}
`;

export default {
  Root,
};
