import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import { currency } from '@symplete/symplete-utils/numbers';

import withInlineLabel from 'shared/hocs/withInlineLabelV2';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';

import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';

import {
  Root,
  InnerWrapper,
} from './styledItems';

const Field = withInlineLabel(Typography);

const CompletedTransactionInfo = (props) => {
  const {
    initialPrice,
    finalPrice,
    completedDate,
  } = props;

  return (
    <Root>
      <DecoratedFieldWrapper
        iconName="check-circle"
        iconType="feather"
        variant="success"
        align="flex-start"
        noMargin
      >
        <InnerWrapper>
          <Typography
            variant="title2"
            weight="bold"
          >
            Transaction completed!
          </Typography>

          <Flex
            spacing={4}
            flexWrap="wrap"
          >
            <Field
              variant="title4"
              color="main-text"
              label="Final Price:"
              labelProps={{
                  variant: 'title4',
                  weight: 'bold',
                  noWrap: true,
                  noMargin: true,
                }}
              noMargin
            >
              <Typography
                variant="title4"
                color="main-text"
                tag="span"
                textDecoration="line-through"
              >
                {currency.full(initialPrice)}
              </Typography>
              &nbsp;
              {currency.full(finalPrice)}
            </Field>

            <Field
              variant="title4"
              color="main-text"
              label="Escrow Closed:"
              labelProps={{
                  variant: 'title4',
                  weight: 'bold',
                  noWrap: true,
                  noMargin: true,
                }}
              noMargin
            >
              {completedDate ? moment(completedDate).format('MMMM D, YYYY') : '—'}
            </Field>
          </Flex>
        </InnerWrapper>
      </DecoratedFieldWrapper>
    </Root>
  );
};

CompletedTransactionInfo.defaultProps = {
  initialPrice: 0,
  finalPrice: 0,
  completedDate: null,
};

const {
  string,
  number,
} = PropTypes;

CompletedTransactionInfo.propTypes = {
  initialPrice: number,
  finalPrice: number,
  completedDate: string,
};

export default CompletedTransactionInfo;
