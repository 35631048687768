import styled from 'styled-components';

import isNumber from 'lodash/isNumber';

import HouseDefault from 'assets/icons/property.svg';

const rounded = {
  m: 8,
};

export const Root = styled.div`
  position: relative;
  height: ${(props) => (isNumber(props.height) ? `${props.height }px` : props.height)};
`;

export const Image = styled.div`
  width: 100%;
  height: ${(props) => (isNumber(props.height) ? `${props.height }px` : props.height)};
  border-radius: ${(props) => rounded[props.rounded] || 0}px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("${(props) => props.src || HouseDefault}");
  background-color: ${(props) => props.theme.palette?.['background-flat-dark'] || 'lightgray'};
  background-size: ${(props) => (props.src ? 'cover' : '20%')};
`;

export const IconWrapper = styled.div`
  width: 100%;
  height: ${(props) => (isNumber(props.height) ? `${props.height }px` : props.height)};
  border-radius: ${(props) => rounded[props.rounded] || 0}px;
  display: flex;
  justify-content: space-evenly;
  background-color: ${(props) => props.theme.palette?.['background-flat-dark'] || 'lightgray'};
`;

export const Label = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
  color: ${(props) => props.theme.palette['main-text-inversed']};
  background: ${(props) => props.theme.palette[props.status]};
  border-bottom-right-radius: ${(props) => rounded[props.rounded] || 0}px;
  border-bottom-left-radius: ${(props) => rounded[props.rounded] || 0}px;
  text-align: center;
  text-transform: uppercase;
  font-family: ${(props) => props.theme.typography.fontFamily.primary};
  letter-spacing: 0.05em;
  font-size: 11px;
  line-height: 20px;
  font-weight: 500;
`;

export default {
  Image,
  IconWrapper,
};
