import { connect } from 'react-redux';

import {
  createAddress,
} from 'store/actions/addresses';

import PreferredLocationInput from './PreferredLocationInput';

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleChange: async (addressData) => {
    const addressId = await dispatch(createAddress(addressData));
    ownProps.handleAdd([addressId]);
  },
});

export default connect(null, mapDispatchToProps)(PreferredLocationInput);
