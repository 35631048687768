import { connect } from 'react-redux';

import selectors from 'store/selectors';

import BuyerNeeds from './BuyerNeeds';

const mapStateToProps = (state, ownProps) => {
  const prospect = selectors.realtorProspects.byId(state, ownProps.prospectId);

  return {
    propertyRequirementId: prospect.realtor_property_requirement_id,
    contactId: prospect.contact_id,
  };
};

export default connect(mapStateToProps)(BuyerNeeds);
