import styled from 'styled-components';

import { NakedButton } from '@symplete/symplete-ui';

export const Root = styled(NakedButton)`
  && {
    text-align: center;
    padding: 10px 5px;
    width: 130px;
  }
  border-radius: 20px;
  border: ${
    (props) => (props.selected
      ? `1px solid ${props?.theme?.palette?.focus || 'green'} !important`
      : 'none')
  };
`;

export const Tile = styled.div`
  width: 105px;
  height: 105px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 10px;
  border-radius: 10px;
  background-color: ${(props) => props.theme?.palette?.backgroundFlat || 'grey'};
  margin: 0 auto 8px;
`;
