import {
  useState,
  useEffect,
} from 'react';

import requests from 'api/requests';

const useEmail = ({ tokenId, messageId }) => {
  const [email, setEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getEmail = async (data) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await requests.integrations.emails.message(data.messageId)(data.tokenId);

      setEmail(response?.data?.message);
    } catch (newError) {
      setError(newError);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (tokenId && messageId) {
      getEmail({ tokenId, messageId });
    }
  }, [
    tokenId,
    messageId,
  ]);

  return {
    email,
    isLoading,
    error,
    getEmail,
  };
};

export default useEmail;
