import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import {
  without,
  difference,
} from 'shared/utility';

import noOp from 'shared/utils/noOp';

import Button from 'ui/Button';
import Grid from 'ui/Grid';
import DropdownTextfield from 'ui/DropdownTextfield';
import Typography from 'ui/Typography';
import Flex from 'ui/Flex';

import Modal from 'ui/Modal';

import { REALTOR_ROLES_ARRAY } from 'constants/realtor.constants';

import Search from './Search';
import ContactsList from './ContactsList';
import AddContact from './AddContact';

const AssignInterestedPartyModal = (props) => {
  const {
    isVisible,
    closeModal,
    oneContactSelect,
    title,
    theme,
    selectedContacts: selectedContactsProps,
    onSave,
    withRoleSelection,
    rolesOptions,
    contactDealRole,
    withAddContact,
    onNewContactCreation,
    rolesFilled,
  } = props;

  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);

  const rolesAvailable = rolesOptions.filter((option) => !rolesFilled.includes(option.label.toLowerCase()));

  useEffect(() => {
    setSelectedContacts(selectedContactsProps || []);
  }, [
    JSON.stringify(selectedContactsProps),
  ]);

  const addContact = useCallback((contactId) => () => {
    if (oneContactSelect) {
      setSelectedContacts([
        contactId,
      ]);
    } else {
      setSelectedContacts([
        ...selectedContacts,
        contactId,
      ]);
    }
  }, [
    selectedContacts,
    oneContactSelect,
  ]);

  const removeContact = useCallback((contactId) => () => {
    setSelectedContacts(
      without(selectedContacts, contactId),
    );
  }, [
    selectedContacts,
  ]);

  const onOk = useCallback(() => {
    const addedContacts = difference(selectedContacts, selectedContactsProps);
    const removedContacts = difference(selectedContactsProps, selectedContacts);

    onSave(
      {
        addedContacts,
        removedContacts,
      },
      selectedRole,
    );
  }, [
    selectedContacts,
    JSON.stringify(selectedContactsProps),
    onSave,
    selectedRole,
  ]);

  const onClose = useCallback(() => {
    setSelectedContacts([]);
    closeModal();
  }, [
    closeModal,
  ]);

  return (
    <Modal
      theme={theme}
      width={600}
      visible={isVisible}
      onCancel={onClose}
      title={title}
      footer={[
        <Button
          onClick={onClose}
          variant="secondary"
        >
          Cancel
        </Button>,
        <Button
          variant="primary"
          onClick={onOk}
          disabled={withRoleSelection ? !selectedRole : false}
        >
          Done
        </Button>,
      ]}
      noCloseIcon={!title}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Flex spacing={2} fullWidth>
            <Search />
            {
              withAddContact && <AddContact onSuccess={onNewContactCreation} />
            }
          </Flex>
        </Grid>

        <Grid item xs={12}>
          {/* TODO: replace with better logic like AssignContactModal */}
          <ContactsList
            selectedContacts={selectedContacts}
            oneContactSelect={oneContactSelect}
            onAddContact={addContact}
            onRemoveContact={removeContact}
            contactDealRole={contactDealRole}
          />
        </Grid>

        {
          withRoleSelection && (
            <Grid item xs={12}>
              <Typography
                variant="title2"
                weight={500}
              >
                Select Role
              </Typography>
              <DropdownTextfield
                value={selectedRole}
                onChange={setSelectedRole}
                options={rolesAvailable}
              />
            </Grid>
          )
        }

      </Grid>

    </Modal>
  );
};

AssignInterestedPartyModal.defaultProps = {
  isVisible: false,
  oneContactSelect: false,
  theme: null,
  selectedContacts: [],
  title: 'Assign this property to your buyer leads or prospects.',
  withRoleSelection: false,
  withAddContact: false,
  rolesOptions: REALTOR_ROLES_ARRAY,
  contactDealRole: null,
  onNewContactCreation: noOp,
  rolesFilled: [],
};

const {
  func,
  bool,
  shape,
  string,
  number,
  arrayOf,
  oneOfType,
  oneOf,
} = PropTypes;

AssignInterestedPartyModal.propTypes = {
  closeModal: func.isRequired,
  onSave: func.isRequired,
  oneContactSelect: bool,
  title: string,
  isVisible: bool,
  theme: shape(),
  selectedContacts: arrayOf(number),
  withRoleSelection: bool,
  rolesOptions: arrayOf(shape({
    value: oneOfType([string, number]).isRequired,
    label: string.isRequired,
  })),
  contactDealRole: oneOf(['buyer', 'seller']),
  withAddContact: bool,
  onNewContactCreation: func,
  rolesFilled: arrayOf(string),
};

export default AssignInterestedPartyModal;
