import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

import Notification from 'rc-notification';
import ThemeProvider from 'shared/themes/OurThemeProvider';
import { defaultTheme } from '@symplete/symplete-ui';

import Icon from 'ui/Icon';
import Flex from 'ui/Flex';

import {
  Box,
} from './styledItems';

let notification = null;

Notification.newInstance({}, (n) => notification = n);

const variantIcons = {
  error: {
    name: 'alert-triangle',
    color: 'error',
  },
  warning: {
    name: 'info',
    color: 'warning',
  },
  success: {
    name: 'check-circle',
    color: 'success',
  },
};

const Alert = (props) => {
  const {
    variant,
    message,
  } = props;

  return (
    <ThemeProvider
      theme={defaultTheme}
    >
      <Box color={variantIcons[variant].color}>
        <Flex
          alignItems="center"
          spacing={2}
        >
          <Icon
            name={variantIcons[variant].name}
            type="feather"
            color={variantIcons[variant].color}
            size="l"
          />

          <Typography
            variant="body"
            noMargin
          >
            {message}
          </Typography>

        </Flex>
      </Box>
    </ThemeProvider>
  );
};

/**
 * Call alert action
 * @param {(string | Element)} content Notification content text or element
 * @param {( 'error' | 'warning' | 'success')} variant Notification type
 * @param {object} options Notifications props details are taken from the rc-notification module. Link to the documentation https://notification-react-component.vercel.app/
 * */
const call = (content, variant, options) => {
  if (typeof content === 'string') {
    notification.notice({
      ...options,
      content: (
        <Alert
          variant={variant}
          message={content}
        />
      ),
    });
  } else if (typeof content === 'object') {
    notification.notice({
      ...options,
      content,
    });
  }
};

const callAlert = {
  error: (content, options) => {
    call(content, 'error', options);
  },
  warning: (content, options) => {
    call(content, 'warning', options);
  },
  success: (content, options) => {
    call(content, 'success', options);
  },
};

Alert.defaultProps = {
  variant: 'success',
  message: 'Alert text',
};

const {
  oneOf,
  string,
} = PropTypes;

Alert.propTypes = {
  variant: oneOf([
    'error',
    'warning',
    'success',
  ]),
  message: string,
};

export { Alert };
export { callAlert };
