import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import ImprovedDrawer from 'shared/components/ImprovedDrawer';
import { DragDropContext } from 'react-beautiful-dnd';
import changeReminderPosition from 'shared/utils/changeReminderPosition';
import ToDoListHeader from './ToDoListHeader';
import ToDoListBody from './ToDoListBody';
import './ToDoList.scss';

const DRAWER_WIDTH = 570;

const ToDoList = (props) => {
  const {
    toDoListDrawerHideAction,
    changeNumberInListAction,
    todolist: {
      toDoListDrawerIsVisible,
      remindersInf,
    },
  } = props;

  const drawerEl = useRef(null);

  const openButton = document.getElementsByClassName('todolist-icon');
  const toDoListSettingsElement = document.getElementsByClassName('to-do-list-settings');
  const settingsAddUserElement = document.getElementsByClassName('to-do-list-add-user-settings-modal');
  const reminderAddUserElement = document.getElementsByClassName('to-do-list-add-user-modal');

  const [stateReminderList, setReminderList] = useState(remindersInf);

  useEffect(() => {
    setReminderList(remindersInf);
  }, [remindersInf]);

  const titleJSX = (
    <ToDoListHeader />
  );

  const outsideToDoListClick = useCallback(async (event) => {
    const isOpenToDoListButtonClick = openButton[0].contains(event.target);
    const isToDoListDrawerClick = drawerEl.current ? (
      drawerEl.current.parentElement.parentElement.contains(event.target)
    ) : (
      false
    );

    const isToDoListSettingsClick = toDoListSettingsElement.length !== 0
      ? (
        toDoListSettingsElement[0].parentElement.contains(event.target)
      ) : (
        false
      );

    const isToDoListSettingsAddUserClick = settingsAddUserElement.length !== 0
      ? (
        settingsAddUserElement[0].parentElement.contains(event.target)
      ) : (
        false
      );

    const isToDoListReminderAddUserClick = reminderAddUserElement.length !== 0
      ? (
        reminderAddUserElement[0].parentElement.contains(event.target)
      ) : (
        false
      );

    if (
      !isOpenToDoListButtonClick
      && !isToDoListDrawerClick
      && !isToDoListSettingsClick
      && !isToDoListSettingsAddUserClick
      && !isToDoListReminderAddUserClick
    ) {
      toDoListDrawerHideAction();
    }
  }, []);

  const onDragEnd = useCallback(async (result) => {
    const {
      destination,
      source,
      draggableId,
    } = result;

    if (!destination) {
      return;
    }

    if (
      destination.draggableId === source.draggableId
      && destination.index === source.index
    ) {
      return;
    }

    const targetReminderID = Number(draggableId);

    const prevNumberInList = source.index;

    const newNumberInList = destination.index;

    const newTodoReminderPosition = changeReminderPosition(
      stateReminderList,
      prevNumberInList,
      newNumberInList,
    );

    setReminderList(newTodoReminderPosition);

    changeNumberInListAction(prevNumberInList, newNumberInList, targetReminderID);
  });

  return (
    <ImprovedDrawer
      ref={drawerEl}
      title={titleJSX}
      width={DRAWER_WIDTH}
      onClose={toDoListDrawerHideAction}
      outsideDrawerClick={outsideToDoListClick}
      visible={toDoListDrawerIsVisible}
      mask={false}
      closable={false}
      placement="right"
      className="to-do-list-drawer"
    >
      <DragDropContext
        onDragEnd={onDragEnd}
      >
        <ToDoListBody
          todoReminderList={stateReminderList}
        />
      </DragDropContext>
    </ImprovedDrawer>
  );
};

const {
  bool,
  shape,
  func,
} = PropTypes;

ToDoList.propTypes = {
  todolist: shape({
    toDoListDrawerIsVisible: bool,
  }).isRequired,
  toDoListDrawerHideAction: func.isRequired,
  changeNumberInListAction: func.isRequired,
};

export default ToDoList;
