import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  openNewPropertyViewingModal,
} from 'store/actions/newRealtorPropertyViewingModal';

import {
  setNewPropertyViewingFormField,
} from 'store/actions/newRealtorPropertyViewingForm';

import ViewingsView from './ViewingsView';

const mapStateToProps = (state) => {
  const prospect = selectors.realtorProspects.selected(state);

  return {
    contactId: prospect?.contact_id,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCreateViewingClick: (contactId) => {
    dispatch(setNewPropertyViewingFormField('buyer')(contactId));
    dispatch(openNewPropertyViewingModal(null, { property: true, sellerAgent: true }));
  },
});

const mergeProps = (stateProps, dispatchProps) => ({
  onCreateViewingClick: () => {
    const { contactId } = stateProps;
    dispatchProps.onCreateViewingClick(contactId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ViewingsView);
