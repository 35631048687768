import { connect } from 'react-redux';

import { openViewingNotesModal } from 'store/actions/propertyViewings';

import NoteCell from './NoteCell';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: () => {
    dispatch(openViewingNotesModal(ownProps.viewingId));
  },
});

export default connect(null, mapDispatchToProps)(NoteCell);
