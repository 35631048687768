import React from 'react';

import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import getFeatureVersion from 'shared/utils/getFeatureVersion';

import OurThemeProvider from 'shared/themes/OurThemeProvider';
import withTheme from 'shared/hocs/withTheme';

import Properties from 'sections/MLS/pages/Properties';
import PropertyItem from 'sections/MLS/pages/PropertyItem';

import Property from 'sections/Properties/pages/Property';

import routes from 'sections/MLS/routes';

import { defaultTheme } from '@symplete/symplete-ui';
import theme from './theme';

const detailsVersion = getFeatureVersion('property page');

const MLS = () => {
  const Theme = withTheme(defaultTheme, theme)(OurThemeProvider);

  return (
    <Theme>
      <Switch>
        <Route
          exact
          path={routes.sectionRoot}
          component={Properties}
        />
        {
          detailsVersion === 1 && (
            <Route
              path={routes.propertyItem}
              component={PropertyItem}
            />
          )
        }
        {
          detailsVersion !== 1 && (
            <Route
              path={routes.propertyItem}
              component={Property}
            />
          )
        }

        <Redirect to={routes.sectionRoot} />
      </Switch>
    </Theme>
  );
};

export default MLS;
