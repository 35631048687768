import {
  useState,
} from 'react';

const useView = () => {
  const [view, setView] = useState('zip');

  return {
    view,
    setView,
  };
};

export default useView;
