import { connect } from 'react-redux';

import {
  postComment,
} from 'store/actions/comments';

import selectors from 'store/selectors';

import CommentsArea from 'shared/components/CommentsArea';

const mapStateToProps = (state) => {
  const {
    realtorLeads: {
      selectedLead,
    },
  } = state;

  const lead = selectors.realtorLeads.byId(state, selectedLead);

  return {
    objectId: lead?.contact_id,
    objectType: 'realtor contact',
  };
};

const mapDispatchToProps = (dispatch) => ({
  addComment: (data) => {
    const comment = {
      Comment: data.comment,
      CommentType: data.commentType,
      IsPublic: data.isVisibleToCustomer,
    };

    return dispatch(postComment(data.objectType)(data.objectId)(comment));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CommentsArea);
