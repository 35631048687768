import React from 'react';
import PropTypes from 'prop-types';

import { currency } from '@symplete/symplete-utils/numbers';

import withTags from 'shared/hocs/withTags';
import withMainPropertyImage from 'sections/Properties/hocs/withMainPropertyImage';

import Grid from 'ui/Grid';

import Typography from 'ui/Typography';

import PropertyPhoto from 'shared/components/UI/RealtorPropertyPhoto';

import {
  FlexContainer,
  FullHeightContainer,
  CenteredWrapper,
} from './styledItems';

const tagContents = {
  client: 'CLIENT',
  prospect: 'PROSPECT',
};

const MainTransactionInfo = (props) => {
  const {
    propertyId,
    address,
    addendumRequest,
    repairRequest,
    type,
    fullCardInformation,
    expectedPrice,
  } = props;

  const PropertyPhotoWithTag = withTags({
    tags: [{
      content: tagContents[type],
      color: 'background-app',
    }],
    position: 'bottom',
    fullWidth: true,
    isBordered: false,
    borderRadius: {
      topLeft: 0,
      topRight: 0,
      bottomLeft: 8,
      bottomRight: 8,
    },
  })(withMainPropertyImage(PropertyPhoto));

  return (
    <Grid
      item
      xs={12}
      container
      spacing={1}
    >
      <Grid
        item
        style={{
          display: 'flex',
        }}
      >
        <CenteredWrapper>
          <PropertyPhotoWithTag
            propertyId={propertyId}
            size={78}
          />
        </CenteredWrapper>
      </Grid>
      <Grid
        container
        item
        xs
      >
        <FullHeightContainer>
          <div>
            <Typography
              variant="body1"
              weight={500}
              className="card-text"
              noMargin
            >
              {address}
            </Typography>
          </div>
          {fullCardInformation && (
            <div>
              <Typography
                variant="body1"
                weight="bold"
                color="success"
                noMargin
              >
                {
                  fullCardInformation
                    ? currency.full(expectedPrice)
                    : currency.short(expectedPrice)
                }
              </Typography>
            </div>
          )}
          <FlexContainer>
            <FlexContainer>
              <Typography
                variant="title1"
                className="title-text"
                weight={400}
                noMargin
              >
                {addendumRequest}
              </Typography>
              <Typography
                variant="body"
                className="description-text"
                color="descriptor-text"
                noMargin
              >
                Addendum
                change
              </Typography>
            </FlexContainer>

            <FlexContainer>
              <Typography
                variant="title1"
                className="title-text"
                weight={400}
                noMargin
              >
                {repairRequest}
              </Typography>
              <Typography
                variant="body"
                className="description-text"
                color="descriptor-text"
                noMargin
              >
                Repair
                Requests
              </Typography>
            </FlexContainer>
          </FlexContainer>
        </FullHeightContainer>
      </Grid>
    </Grid>
  );
};

const {
  number,
  string,
  bool,
} = PropTypes;

MainTransactionInfo.propTypes = {
  propertyId: number.isRequired,
  address: string.isRequired,
  addendumRequest: number.isRequired,
  repairRequest: number.isRequired,
  fullCardInformation: bool.isRequired,
  type: string.isRequired,
  expectedPrice: number.isRequired,
};

export default MainTransactionInfo;
