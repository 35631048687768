import { connect } from 'react-redux';
import styled from 'styled-components';

import { NakedButton } from '@symplete/symplete-ui';

import {
  openModal,
} from 'store/actions/modals';

const ClickableEventWrapper = styled(NakedButton)`
  text-align: left;
`;

const objectTypesOnClickFunctions = {
  followUpReminders: (objectId, dispatch) => {
    dispatch(openModal('editFollowUpReminder')({}, { reminderId: objectId }));
  },
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    objectType,
    objectId,
    onClick: onClickOwnProps,
  } = ownProps;

  if (onClickOwnProps) {
    return {
      onClick: (event) => {
        onClickOwnProps({ objectType, objectId }, event);
      },
    };
  }

  let onClick = () => {};

  if (objectTypesOnClickFunctions[objectType]) {
    onClick = () => {
      objectTypesOnClickFunctions[objectType](objectId, dispatch);
    };
  }

  return {
    onClick,
  };
};

export default connect(null, mapDispatchToProps)(ClickableEventWrapper);
