import requests from 'api/requests';

import setToken from './setExternalToken';

const getExternalToken = () => async (dispatch) => {
  try {
    const response = await requests.external.general.getToken();
    const token = response.data?.token;
    dispatch(setToken(token));
    return token;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export default getExternalToken;
