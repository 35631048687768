import {
  useState,
  useEffect,
} from 'react';

import requests from 'api/requests';

const useThread = ({ tokenId, threadId }) => {
  const [thread, setThread] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const getThread = async (data) => {
    setIsLoading(true);
    setError(null);

    if (!data.threadId || !data.tokenId) {
      setIsLoading(false);
      return;
    }

    let result;

    try {
      const response = await requests.integrations.emails.thread(data.threadId)(data.tokenId);

      setThread(response?.data?.thread);
      result = response?.data?.thread;
    } catch (newError) {
      setError(newError);
    } finally {
      setIsLoading(false);
    }
    return result; // eslint-disable-line consistent-return
  };

  useEffect(() => {
    if (tokenId && threadId) {
      getThread({ tokenId, threadId });
    }
  }, [
    tokenId,
    threadId,
  ]);

  return {
    thread,
    isLoading,
    error,
    getThread,
  };
};

export default useThread;
