import styled from 'styled-components';

import { palette } from 'shared/components/CRM/UI/variables';

const size = 30;

export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${size}px;
  height: ${size}px;
  background-color: ${palette.avatarBackground};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("${(props) => props.src}");
  border-radius: 3px;
  text-transform: uppercase;
`;

export const Initials = styled.div`
  color: white;
  font-size: 12px;
  line-height: 12px;
  font-weight: bold;
  font-family: Roboto, Arial, Helvetica, sans-serif;
`;

export default {
  Root,
  Initials,
};
