import styled from 'styled-components';

const PROPERTY_STATUS_COLORS = {};

export const Root = styled.div`
  border-radius: 3px;
  padding: 5px 16px;
  text-transform: uppercase;
  color: white;
  background: ${(props) => PROPERTY_STATUS_COLORS[props.status] || '#2ecc71'};
  text-align: center;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  margin: 2px 0;
  width: ${(props) => props.width || 'auto'};
`;

export default {
  Root,
};
