import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import PropTypes from 'prop-types';

import stableStringify from 'json-stable-stringify';

import withLeadData from 'sections/Leads/hocs/withLeadData';

import { makeStyles } from '@material-ui/core/styles';

import Tab from 'ui/Tab';
import Tabs from 'ui/Tabs';
import Button from 'ui/Button';

import PropertyTab from 'sections/Leads/modules/PropertyTab';
import PropertyRequirementTab from 'sections/Leads/modules/PropertyRequirementTab';

const useStyles = makeStyles({
  tabs: {
    borderBottom: 'solid 1px #D8E5FF',
    backgroundColor: '#F2F6FE',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  tab: {
    alignItems: 'center',
    height: 64,
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 32,
    paddingLeft: 14,
    backgroundColor: '#F2F6FE',
    position: 'relative',
    zIndex: 1,
    textTransform: 'none',
    opacity: 1,
    maxWidth: 'initial !important',
    borderRight: '1px solid #D8E5FF',
  },
  tabSelected: {
    alignItems: 'center',
    height: 64,
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 32,
    paddingLeft: 14,
    backgroundColor: '#fff',
    position: 'relative',
    zIndex: 1,
    textTransform: 'none',
    maxWidth: 'initial !important',
    borderRight: '1px solid #D8E5FF',
  },
});

const TabComponents = {
  properties: PropertyTab,
  property_requirements: PropertyRequirementTab,
};

const LeadPropertiesSelector = (props) => {
  const {
    selectedProperty,
    leadData,
    onSelect,
  } = props;

  const classes = useStyles();

  const [tab, setTab] = useState(null);

  const handleTabChange = useCallback((event, value) => {
    setTab(value);

    const {
      type,
      id,
    } = JSON.parse(value);

    onSelect(type)(id);
  }, [
    onSelect,
  ]);

  useEffect(() => {
    setTab(stableStringify({
      id: selectedProperty?.id,
      type: selectedProperty?.type,
    }));
  }, [
    stableStringify(selectedProperty),
  ]);

  const leadItems = useMemo(() => {
    const properties = leadData.properties || [];
    const propertyRequirements = leadData.property_requirements || [];

    return properties
      .map((item) => ({ id: item, type: 'properties' }))
      .concat(propertyRequirements.map((item) => ({ id: item, type: 'property_requirements' })));
  }, [
    stableStringify(leadData),
  ]);

  const tabs = useMemo(() => (
    leadItems.map((item) => {
      const name = stableStringify(item);

      const TabComponent = TabComponents[item.type];

      return (
        <Tab
          label={(
            <TabComponent
              itemId={item.id}
            />
          )}
          value={name}
          key={name}
          className={tab === name ? classes.tabSelected : classes.tab}
          disableRipple
        />
      );
    })
  ), [
    leadItems,
    classes,
    tab,
  ]);

  return (
    <>
      <Tabs
        value={tab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        TabIndicatorProps={{
          style: {
            display: 'none',
          },
        }}
        className={classes.tabs}
        ScrollButtonComponent={(props) => {
          const {
            onClick,
            disabled,
            direction,
          } = props;

          return (
            <Button
              onClick={onClick}
              disabled={disabled}
              variant="tertiary"
              icon={direction === 'right' ? 'chevron-right' : 'chevron-left'}
              style={{ position: 'relative', top: 8 }}
            />
          );
        }}
      >
        { tabs }
      </Tabs>
    </>
  );
};

LeadPropertiesSelector.defaultProps = {
  selectedProperty: null,
  showProperties: true,
  showRequirements: true,
  fullWidth: false,
};

const {
  func,
  number,
  oneOf,
  shape,
  bool,
} = PropTypes;

LeadPropertiesSelector.propTypes = {
  leadId: number.isRequired,
  onSelect: func.isRequired,
  selectedProperty: shape({
    id: number,
    type: oneOf(['properties', 'property_requirements']),
  }),
  showProperties: bool,
  showRequirements: bool,
  fullWidth: bool,
};

export default withLeadData(LeadPropertiesSelector);
