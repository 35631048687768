import React from 'react';
import { Form, Icon, Upload } from 'antd';
import './UploadFile.scss';

class UploadFileInput extends React.Component {
  state = {
    fileList: [],
  };

  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    this.changeFileList(e.fileList);
    return e && e.fileList;
  };

  changeFileList = (data) => {
    if (data.hasOwnProperty('fileList')) {
      this.setState({ fileList: data.fileList });
    }

    if (this.props.hasOwnProperty('onAdd') && data.hasOwnProperty('file')) {
      this.props.onAdd(data.file);
    }
  };

  beforeUpload = (file) => {
    this.setState((state) => ({
      fileList: [...state.fileList, file],
    }));

    return false;
  };

  render() {
    const {
      getFieldDecorator,
      extra = '',
      labelCol = 3,
      wrapperCol = 4,
      label = '',
      inputName = 'upload',
      fileLimit = 5,
      initialValue = null,
      onRemove = () => { },
    } = this.props;

    const formItemLayout = {
      labelCol: { span: labelCol },
      wrapperCol: { span: wrapperCol },
    };

    const uploadButton = (
      <div>
        <Icon type="plus" />
      </div>
    );

    return (
      <Form.Item
        {...formItemLayout}
        label={label}
        extra={extra}
      >
        {getFieldDecorator(inputName, {
          valuePropName: 'fileList',
          getValueFromEvent: this.normFile,
          initialValue: initialValue ? [{ ...initialValue }] : null,
        })(
          <Upload
            className="upload"
            listType="picture-card"
            name="logo"
            beforeUpload={this.beforeUpload}
            onChange={this.changeFileList}
            onRemove={onRemove}
          >

            {this.state.fileList.length >= fileLimit ? null : uploadButton}
          </Upload>,
        )}
      </Form.Item>
    );
  }
}

export default UploadFileInput;
