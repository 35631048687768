import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import { currency } from '@symplete/symplete-utils/numbers';

import Grid from 'ui/Grid';

import withFieldDecorator from 'shared/hocs/withFieldDecorator';

import withContactDataBasic from 'sections/Contacts/hocs/withContactDataBasic';
import withMainPropertyImage from 'sections/Properties/hocs/withMainPropertyImage';

import Typography from 'ui/Typography';

import PropertyParametersIndicators from 'shared/modules/PropertyParametersIndicators';
import AvatarComponent from 'sections/Prospects/components/Avatar';
import AdditionalParameters from 'sections/Prospects/components/AdditionalParameters';

import {
  Root,
  AvatarContainer,
  PropertyPhoto,
  PhotoContainer,
  Label,
  Notice,
} from './styledItems';

const formatPrice = (value) => currency.short(value);

const OfferCount = withFieldDecorator({
  iconName: 'tag',
  variant: 'warm',
  size: 'xs',
  noMargin: true,
})(Typography);

const SellerPhoto = withContactDataBasic(AvatarComponent);

const PropertyImage = withMainPropertyImage(PropertyPhoto);

const PropertyCard = (props) => {
  const {
    price,
    expiryDate,
    address,
    sellerId,
    offersCount,
    type,
    viewingsCount,
    createdAt,
    propertyId,
    label,
    notice,
  } = props;

  const daysOld = moment().diff(moment(createdAt), 'days');
  const weeksToExpire = moment(expiryDate).diff(moment(), 'weeks') < 1
    ? `${moment(expiryDate).diff(moment(), 'days')} D`
    : `${moment(expiryDate).diff(moment(), 'weeks')} W`;

  return (
    <Root>
      {
        notice && <Notice variant={notice.variant}>{notice.message}</Notice>
      }

      <PhotoContainer>
        <AvatarContainer>
          <SellerPhoto
            contactId={sellerId}
            size="sm"
          />
        </AvatarContainer>

        <Label>
          Seller
          {label && `| ${label}`}
        </Label>

        <PropertyImage
          propertyId={propertyId}
        />
      </PhotoContainer>

      <Grid
        container
        spacing={1}
        alignItems="baseline"
        style={{ padding: 5 }}
      >
        <Grid item xs={4}>
          <Typography
            variant="h2"
            color="success"
            tag="p"
            weight="bold"
            noMargin
          >
            {formatPrice(price)}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          {offersCount && (
            <OfferCount
              variant="h3"
              tag="p"
              color="warm"
              weight="bold"
              noMargin
              decorated
            >
              {offersCount}
&nbsp;Offers
            </OfferCount>
          )}
        </Grid>

        <Grid item xs={4}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Typography
              variant="h4"
              tag="p"
              color="attention"
              noMargin
            >
              <b>EXP:</b>
              {' '}
              {weeksToExpire}
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="h3"
            tag="p"
            weight="bold"
            noMargin
          >
            {address}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <PropertyParametersIndicators
            propertyId={propertyId}
          />
        </Grid>

        <Grid item xs={12}>
          <AdditionalParameters
            data={[
              {
                title: <span style={{ textTransform: 'capitalize' }}>{type}</span>,
              },
              {
                count: viewingsCount || 0,
                title: viewingsCount > 1 || viewingsCount === 0 || !viewingsCount
                  ? 'Showings'
                  : 'Showing',
              },
              {
                count: daysOld,
                title: 'days old',
              },
            ]}
          />
        </Grid>
      </Grid>
    </Root>
  );
};

PropertyCard.defaultProps = {
  price: null,
  expiryDate: null,
  address: null,
  sellerId: null,
  offersCount: null,
  type: null,
  viewingsCount: null,
  createdAt: null,
  propertyId: null,
  label: null,
  notice: null,
};

const {
  number,
  string,
  shape,
} = PropTypes;

PropertyCard.propTypes = {
  price: number,
  expiryDate: string,
  address: string,
  sellerId: number,
  offersCount: number,
  type: string,
  viewingsCount: number,
  createdAt: string,
  propertyId: number,
  label: string,
  notice: shape({
    message: string,
    variant: string,
  }),
};

export default PropertyCard;
