import {
  useState,
  useEffect,
  useCallback,
} from 'react';

import requests from 'api/requests';

const getSampleMessage = (onSuccess, onError) => async (campaignId) => {
  try {
    const response = await requests.integrations.emailCampaigns.getSampleMessage(campaignId);

    onSuccess(response?.data);
  } catch (newError) {
    onError(newError);
  }
};

const useCampaignSampleMessage = (campaignId) => {
  const [currentCampaignId, setCurrentCampaignId] = useState(null);
  const [message, setMessage] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [getError, setGetError] = useState(null);

  const get = useCallback(async () => {
    setGetError(null);
    setIsLoading(true);

    await getSampleMessage(setMessage, setGetError)(campaignId);

    setIsLoading(false);
  }, [
    campaignId,
  ]);

  useEffect(() => {
    if (campaignId && campaignId !== currentCampaignId) {
      setCurrentCampaignId(campaignId);
      get();
    }
  }, [
    campaignId,
  ]);

  return {
    message,

    isLoading,

    get,

    error: getError,
  };
};

export default useCampaignSampleMessage;
