import React from 'react';

import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import getFeatureVersion from 'shared/utils/getFeatureVersion';

import List from 'sections/Prospects/pages/List';
import ProspectItem from 'sections/Prospects/pages/ProspectItem';
import ProspectItemV2 from 'sections/Prospects/pages/ProspectItemV2';

import routes from 'sections/Prospects/routes';

const version = getFeatureVersion('client buyer page');

const Prospects = () => (
  <Switch>
    <Route
      exact
      path={routes.sectionRoot}
      component={List}
    />
    {
        version === 1 && (
          <Route
            path={routes.prospect}
            component={ProspectItem}
          />
        )
      }
    {
        version !== 1 && (
          <Route
            path={routes.prospect}
            component={ProspectItemV2}
          />
        )
      }

    <Redirect to={routes.sectionRoot} />
  </Switch>
  );

export default Prospects;
