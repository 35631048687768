import { connect } from 'react-redux';

import {
  openAssignInterestedPartyModal,
} from 'store/actions/realtorAssignInterestedPartyModal';

import Button from 'ui/Button';

const mapStateToProps = (state) => ({
  children: 'Add Buyer',
  variant: 'primary',
  icon: 'investment',
  iconType: 'custom',
});

const mapDispatchToProps = (dispatch) => ({
  onClick: () => {
    dispatch(openAssignInterestedPartyModal({ contactDealRole: 'buyer' }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Button);
