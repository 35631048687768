import React from 'react';
import PropTypes from 'prop-types';

import FormTextfield from 'shared/inputs/FormTextfield';

import withDebouncedCallbacks from 'shared/hocs/withDebouncedCallbacks';

const TextInput = withDebouncedCallbacks(FormTextfield, [[
  'onChange',
  1000,
]]);

const PropertyDescription = (props) => {
  const {
    onChange,
    url,
  } = props;

  return (
    <TextInput
      variant="inline"
      label="Property URL:"
      placeholder="www.propertyhere.com"
      onChange={onChange}
      value={url}
    />
  );
};

const {
  func,
  string,
} = PropTypes;

PropertyDescription.propTypes = {
  onChange: func.isRequired,
  url: string.isRequired,
};

export default PropertyDescription;
