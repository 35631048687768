import React, {
  useState,
  useCallback,
} from 'react';

import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import ProspectsTable from 'sections/Prospects/modules/ProspectsTable';
import ProspectsKanban from 'sections/Prospects/modules/ProspectsKanban';
import ProspectsTableSearchFilter from 'sections/Prospects/modules/ProspectsTableSearchFilter';
// import ProspectsTableFilterModal from 'sections/Prospects/modals/ProspectsTableFilterModal';
// import BulkProspectActions from 'sections/Prospects/modules/BulkProspectActions';
import OverallProspectsSummary from 'sections/Prospects/modules/OverallProspectsSummary';
// import ActiveFilters from 'sections/Prospects/components/ActiveFilters';

import TableKanbanSwitch from 'shared/components/UI/TableKanbanSwitch';

import UnderConstructionPlaceholder from 'shared/components/UI/UnderConstructionPlaceholder';

import { Root } from './styledItems';

const Prospects = (props) => {
  const [isProspectTableFilterModalVisible, setProspectTableFilterModalVisibility] = useState(false);

  const [view, setView] = useState('table');

  const openProspectTableFilterModal = useCallback(() => {
    setProspectTableFilterModalVisibility(true);
  }, [
    setProspectTableFilterModalVisibility,
  ]);

  // const closeProspectTableFilterModal = useCallback(() => {
  //   setProspectTableFilterModalVisibility(false);
  // }, [
  //   setProspectTableFilterModalVisibility,
  // ]);

  const handleViewChange = useCallback((newView) => {
    setView(newView);
  });

  return (
    <>
      <Root>
        <Grid
          container
          spacing={2}
          alignItems="center"
        >
          <Grid item xs={5}>
            <UnderConstructionPlaceholder>
              <ProspectsTableSearchFilter
                onFilterClick={openProspectTableFilterModal}
              />
            </UnderConstructionPlaceholder>
          </Grid>
          <Grid item xs={1}>
            <TableKanbanSwitch
              onChange={handleViewChange}
              currentView={view}
            />
          </Grid>
          <Grid item xs={1}>
            {/* <BulkProspectActions /> */}
            {/* <UnderConstructionPlaceholder>
              Bulk actions
            </UnderConstructionPlaceholder> */}
          </Grid>
          <Grid item xs={5}>
            <Grid container justify="flex-end">
              <UnderConstructionPlaceholder>
                <OverallProspectsSummary />
              </UnderConstructionPlaceholder>
            </Grid>
          </Grid>
          {/* <Grid item xs={12}>
            <Grid container justify="space-between">
              <ActiveFilters />
              <UnderConstructionPlaceholder>
                Active filters
              </UnderConstructionPlaceholder>
            </Grid>
          </Grid> */}
          <Grid item xs={12}>
            {
              view === 'table' && (
                <ProspectsTable />
              )
            }
            {
              view === 'kanban' && (
                <ProspectsKanban />
              )
            }
          </Grid>
        </Grid>
      </Root>
      {/* <ProspectsTableFilterModal
        isVisible={isProspectTableFilterModalVisible}
        closeModal={closeProspectTableFilterModal}
      /> */}
    </>
  );
};

const {
  // func,
} = PropTypes;

Prospects.propTypes = {
};

export default Prospects;
