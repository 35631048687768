import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  openNewPropertyViewingModal,
} from 'store/actions/newRealtorPropertyViewingModal';

import ViewingsView from './ViewingsView';

const mapStateToProps = (state) => {
  const client = selectors.realtorClients.selected(state);

  return {
    propertyId: client?.realtor_property_id,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCreateViewingClick: (propertyId) => {
    dispatch(openNewPropertyViewingModal(propertyId, { buyer: true, buyerAgent: true }));
  },
});

const mergeProps = (stateProps, dispatchProps) => ({
  onCreateViewingClick: () => {
    const { propertyId } = stateProps;
    dispatchProps.onCreateViewingClick(propertyId);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ViewingsView);
