import React, {
  Fragment,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';
import Tooltip from 'ui/Tooltip';

import MetricsItem from './MetricsItem';

const metricsItems = [
  {
    key: 'openRate',
    title: 'Open Rate',
  },
  {
    key: 'bounceRate',
    title: 'Bounce Rate',
  },
  {
    key: 'replyRate',
    title: 'Reply Rate',
  },
  {
    key: 'linkClickRate',
    title: 'Link Click Rate',
  },
];

const Metrics = (props) => {
  const {
    value,
  } = props;

  const getTooltipContent = useCallback((key, title) => {
    const countValue = value[key]?.count || 0;

    return (
      <div>
        <Typography
          variant="body2"
          weight={500}
          color="contrast"
          align="center"
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          color="contrast"
          align="center"
        >
          {`${countValue} contact${countValue === 1 ? '' : 's'}`}
        </Typography>
      </div>
    );
  }, [
    JSON.stringify(value),
  ]);

  return (
    <Flex
      spacing={1}
      alignItems="center"
    >
      {
        metricsItems.map(({ key, title }) => (
          <Fragment key={key}>
            <Tooltip
              title={getTooltipContent(key, title)}
            >
              <div>
                <MetricsItem
                  metricType={key}
                  value={value ? value[key]?.percentage || '—' : '—'}
                />
              </div>
            </Tooltip>
          </Fragment>
        ))
      }
    </Flex>
  );
};

const { shape } = PropTypes;

Metrics.propTypes = {
  value: shape({}).isRequired,
};

export default Metrics;
