import openModalWithContentAction from 'store/actions/modals/openModalWithContentAction';

import {
  SET_ACCEPT_COUNTER_FORM_STATE,
} from 'store/actionTypes/realtorOpportunities';

const openAcceptCounterModal = openModalWithContentAction('acceptCounterModal')(
  SET_ACCEPT_COUNTER_FORM_STATE,
);

export default openAcceptCounterModal;
