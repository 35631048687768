import styled from 'styled-components';

import IconButton from 'shared/components/IconButton';

export const Button = styled(IconButton)`
  padding: 5px;
  border-radius: 3px;
  background-color: ${(props) => props.backgroundColor || '#f1f2f4'};
  min-width: 25px;
`;

export default {
  Button,
};
