import setFilterItem from './setFilterItem';

const setAll = () => (dispatch) => {
  dispatch(setFilterItem('status')(null));
  dispatch(setFilterItem('stage')(null));
};

const setArchived = () => (dispatch) => {
  dispatch(setFilterItem('status')(2));
};

const setActive = () => (dispatch) => {
  dispatch(setFilterItem('status')(1));
};

const setNew = () => (dispatch) => {
  dispatch(setFilterItem('status')(7));
};

const setPrecontingencies = () => (dispatch) => {
  dispatch(setFilterItem('stage')('precontingencies'));
};

const setPostcontingencies = () => (dispatch) => {
  dispatch(setFilterItem('stage')('postcontingencies'));
};

const keyHandlers = {
  all: setAll,
  new: setNew,
  active: setActive,
  archived: setArchived,
  precontingencies: setPrecontingencies,
  postcontingencies: setPostcontingencies,
};

const handleCustomFilter = (key) => (dispatch) => {
  if (keyHandlers[key]) dispatch(keyHandlers[key]());
};

export default handleCustomFilter;
