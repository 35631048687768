/* eslint-disable camelcase */
import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  closeModal,
} from 'store/actions/modals';

import {
  createMatching,
  deleteMatching,
} from 'store/actions/realtorMatched';

import AssignInterestedPartyModal from 'shared/modals/AssignInterestedPartyModal';

const mapStateToProps = (state) => {
  const {
    modals: {
      matchedParty,
    },
    properties: {
      selectedProperty,
    },
  } = state;

  const matches = selectors.realtorMatched.matchesContacts(state, selectedProperty);

  return {
    isVisible: matchedParty?.isVisible,
    selectedContacts: matches.map(({ realtor_contact_id }) => realtor_contact_id),
    propertyId: selectedProperty,
    onAfterClose: matchedParty?.onAfterClose,
    matches,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModal('matchedParty')()),

  onSave: async ({
    propertyId,
    addedContacts,
    removedContacts,
    matches,
  }) => {
    const addContacts = addedContacts.map(async (contactId) => {
      await dispatch(createMatching({
        realtor_property_id: propertyId,
        realtor_contact_id: contactId,
      }));
    });

    const removeContacts = removedContacts.map(async (contactId) => {
      const match = matches.find((item) => item.realtor_contact_id === contactId);

      await dispatch(deleteMatching(match?.id));
    });

    await Promise.all(addContacts);
    await Promise.all(removeContacts);
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onSave: async ({ addedContacts, removedContacts }) => {
    await dispatchProps.onSave({
      addedContacts,
      removedContacts,
      propertyId: stateProps.propertyId,
      matches: stateProps.matches,
    });
    dispatchProps.closeModal();

    if (stateProps.onAfterClose) {
      await stateProps.onAfterClose();
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AssignInterestedPartyModal);
