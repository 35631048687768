import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'shared/utility';
import { callAlert } from 'ui/Alert';

import WriteNewButton from 'shared/components/WriteNewButtonV2';
import ReminderTextEditor from '../ReminderTextEditor';

import { ReminderInputContainer } from './styledItems';

class TitleForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showEditor: false,
    };

    this.inputRef = React.createRef();
  }

  toggleEditor = () => {
    this.setState({ showEditor: true });
  };

  onCancel = () => {
    this.setState({ showEditor: false });
  }

  newReminder = async (reminderTitle) => {
    const {
      toDoListSetViewedAction,
      remindersInf,
      postNewReminder,
    } = this.props;

    const reminderClearTitle = reminderTitle.slice(3, -4);

    const isSpaceText = !reminderClearTitle.trim().length;
    const numberInList = remindersInf.length;

    if (isEmpty(reminderClearTitle)) {
      callAlert.warning('Text is empty');
      return;
    }
    if (isSpaceText) {
      callAlert.warning('Incorrect reminder name!');
      return;
    }

    await postNewReminder(reminderTitle, numberInList);

    this.setState(({ showEditor }) => ({ showEditor: !showEditor }));

    toDoListSetViewedAction();
  }

  render() {
    const {
      showEditor,
    } = this.state;

    return (
      <ReminderInputContainer
        className="title-input"
        showEditor={showEditor}
      >
        <WriteNewButton
          onClick={this.toggleEditor}
        >
          Add a new item and press enter
        </WriteNewButton>

        <ReminderTextEditor
          onConfirm={this.newReminder}
          onClickCancel={this.onCancel}
          isAutoFocus
          visibility={showEditor}
          isClearEditorOnSubmit
        />

      </ReminderInputContainer>

    );
  }
}

const {
  func,
  arrayOf,
  number,
} = PropTypes;

TitleForm.propTypes = {
  remindersInf: arrayOf({
    EmployeeID: number,
    reminderID: number,
  }).isRequired,
  postNewReminder: func.isRequired,
  toDoListSetViewedAction: func.isRequired,
};

export default TitleForm;
