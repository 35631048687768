import { connect } from 'react-redux';

import selectors from 'store/selectors';

import PropertyLastEndedViewingCell from './PropertyLastEndedViewingCell';

const mapStateToProps = (state, ownProps) => {
  const {
    propertyId,
  } = ownProps;

  const viewing = selectors.properties.propertyLastEndedViewing(state, propertyId);

  return {
    dateTime: viewing?.date_time,
  };
};

export default connect(mapStateToProps)(PropertyLastEndedViewingCell);
