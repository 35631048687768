import React, {
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import useRangeValues from 'ui/hooks/useRangeValues';

import Flex from 'ui/Flex';
import DropdownTextfield from 'ui/DropdownTextfield';
import Typography from 'ui/Typography';

const defaultSeparator = (
  <Typography
    variant="title2"
    tag="p"
    weight="bold"
    noMargin
  >
    to
  </Typography>
);

const getOptions = (options) => options.map((item) => ({
  label: item,
  value: item,
}));

const sizes = {
  s: {
    width: 90,
  },
  m: {
    width: 180,
  },
  l: {
    width: 360,
  },
};

const styles = {
  ...sizes,
  sm: sizes.s,
  md: sizes.m,
  lg: sizes.l,
};

const menuItemStyle = {
  textAlign: 'center',
  minWidth: 30,
  padding: 5,
}

const DropdownRange = (props) => {
  const {
    value,
    onChange,
    minOptions,
    maxOptions,
    separator,
    size,
  } = props;

  const currentMinOptions = useMemo(() => getOptions(minOptions), [minOptions]);
  const currentMaxOptions = useMemo(() => getOptions(maxOptions), [maxOptions]);

  const {
    min,
    max,
    handleChange,
  } = useRangeValues(
    value || [
      currentMinOptions[0].value,
      currentMaxOptions[currentMaxOptions.length - 1].value,
    ],
    onChange,
  );

  const textfieldStyle = useMemo(() => styles[size] || {}, [
    size,
  ]);

  return (
    <Flex spacing={1} alignItems="baseline">
      <DropdownTextfield
        options={currentMinOptions}
        onChange={handleChange('min')}
        value={min}
        type="number"
        hideNumberArrows
        style={textfieldStyle}
        menuItemStyle={menuItemStyle}
      />
      {separator}
      <DropdownTextfield
        options={currentMaxOptions}
        onChange={handleChange('max')}
        value={max}
        type="number"
        hideNumberArrows
        style={textfieldStyle}
        menuItemStyle={menuItemStyle}
      />
    </Flex>
  );
};

DropdownRange.defaultProps = {
  value: null,
  separator: defaultSeparator,
  size: null,
};

const {
  arrayOf,
  element,
  func,
  number,
  oneOf,
} = PropTypes;

DropdownRange.propTypes = {
  value: arrayOf(number),
  separator: element,
  minOptions: arrayOf(number).isRequired,
  maxOptions: arrayOf(number).isRequired,
  onChange: func.isRequired,
  size: oneOf([
    's',
    'sm',
    'm',
    'md',
    'l',
    'lg',
  ]),
};

export default DropdownRange;
