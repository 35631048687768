import React from 'react';
import PropTypes from 'prop-types';

import {
  Root,
  InnerWrapper,
  Title,
} from './styledItems';

const typeToText = {
  myListings: 'My Listings',
  featuredProperties: 'Featured Properties',
};

const typeToColor = {
  myListings: '#fff5e6',
  featuredProperties: 'background-flat',
};

const ListingsDivider = (props) => {
  const {
    type,
  } = props;

  return (
    <Root className="dividerRow">
      <InnerWrapper>
        <Title
          variant="caption"
          backgroundColor={typeToColor[type]}
        >
          {typeToText[type]}
        </Title>
      </InnerWrapper>
    </Root>
  );
};

const { oneOf } = PropTypes;

ListingsDivider.propTypes = {
  type: oneOf(['myListings', 'featuredProperties']).isRequired,
};

export default ListingsDivider;
