import { createSelector } from 'reselect';

import getFullName from 'shared/utils/getFullName';

export default createSelector(
  (state) => state.myCompany.employees,
  (state) => state.auth.loggedUser.EmployeeID,
  (items, itemId) => {
    const employee = items.find((item) => item.EmployeeID === itemId);

    if (!employee) return null;

    return {
      first_name: employee.Name,
      last_name: employee.Surname,
      fullName: getFullName({
        first_name: employee.Name,
        last_name: employee.Surname,
      }),
      avatar: employee.avatar,
    };
  },
);
