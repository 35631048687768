/* eslint-disable consistent-return */
import requests from 'api/requests';
import { callAlert } from 'ui/Alert/Alert';

const onSubmit = async (formData) => {
  const {
    propertyId,

    firstName,
    lastName,
    phone,
    email,

    type,
    financing,

    companyName,
    buyerFirstName,
    buyerLastName,

    requestDate,
    requestTime,

  } = formData;

  const requestData = {
    request_type: type,
    financing_type: financing,

    realtor_property_id: propertyId,

    company_name: companyName,
    buyer_first_name: buyerFirstName,
    buyer_last_name: buyerLastName,

    request_date: requestDate,
    request_time: requestTime,

    ex_submission_first_name: firstName,
    ex_submission_last_name: lastName,
    ex_submission_phone: phone,
    ex_submission_email: email,
  };

  try {
    const response = await requests.external.realtor.request.create(requestData);

    callAlert.success('Thank you! Your request has been submitted.');
    return response.data;
  } catch (error) {
    callAlert.error('Something went wrong!');
  }
};

export default onSubmit;
