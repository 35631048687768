import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateSelectedProspect,
} from 'store/actions/realtorProspects';

import PinnedNote from 'shared/components/PinnedNote';

const mapStateToProps = (state) => {
  const selectedItem = selectors.realtorProspects.selected(state);

  return {
    content: selectedItem.pinned_note,
    selectedObjectId: selectedItem.id,
    objectTypeName: 'prospect',
  };
};

const mapDispatchToProps = (dispatch) => ({
  onNoteChange: async (content) => {
    await dispatch(updateSelectedProspect({ pinned_note: content }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PinnedNote);
