import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@material-ui/core/Checkbox';

const ConfirmationCell = (props) => {
  const {
    onChange,
    value,
  } = props;

  const handleClick = useCallback((event) => {
    event.stopPropagation();
    onChange(event.target.checked);
  }, [
    onChange,
  ]);

  return (
    <Checkbox
      color="primary"
      // size="small"
      checked={value}
      onChange={handleClick}
    />
  );
};

const {
  bool,
  func,
} = PropTypes;

ConfirmationCell.propTypes = {
  onChange: func.isRequired,
  value: bool.isRequired,
};

export default ConfirmationCell;
