import { connect } from 'react-redux';

import {
  openNewPropertyModal,
} from 'store/actions/newRealtorPropertyModal';

import Properties from './Properties';

const mapStateToProps = (state) => {
  const {

  } = state;

  return {

  };
};

const mapDispatchToProps = (dispatch) => ({
  openNewPropertyModal: () => dispatch(openNewPropertyModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Properties);
