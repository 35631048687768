import { connect } from 'react-redux';

import selectors from 'store/selectors';

// import {
//  PROPERTY_TYPES,
// } from 'constants/realtor.constants';

import PropertyCard from 'sections/Prospects/components/MatchingPropertyCard';

const mapStateToProps = (state, ownProps) => {
  const {
    id,
    // propertyId,
  } = ownProps;

  const matchingProperty = selectors.realtorMatchingProperties.byId(id)(state);

  const {
    realtor_property_id: propertyId,
    created_at: createdAt,
  } = matchingProperty;

  // const client = selectors.realtorClients.byId(state, id);

  // const {
  //   contact_id: sellerId,
  //   realtor_property_id: propertyId,
  // } = client;

  const property = selectors.properties.byId(state, propertyId);

  const address = selectors.addresses.byId(state, property?.address_id);

  return {
    // sellerId,
    propertyId,

    price: property?.expected_price,
    // expiryDate: client.listing_end_date,
    // createdAt: client.created_at,

    // viewingsCount: property.viewings_summary?.total_viewings,
    // type: PROPERTY_TYPES[property.property_type],
    address: address?.address,

    createdAt,
    // offersCount,
    // viewingsCount,
    // label,
    // notice,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyCard);
