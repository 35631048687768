import { createSelector } from 'reselect';

import { // eslint-disable-line import/no-cycle
  optimizedRealtorContactsActions,
} from 'store/actions/realtorContacts';

import {
  optimizedEmailsActions,
} from 'store/actions/emails';

export default createSelector(
  (state) => state.realtorContacts.contacts,
  (state) => state.emails.emails,
  (_, contactId) => contactId,
  (
    contacts,
    emails,
    id,
  ) => {
    const contact = contacts[id];

    if (!contact) {
      if (id) {
        optimizedRealtorContactsActions.getContactById(id);
      }

      return [];
    }

    const contactEmailsRaw = contact.emails || [];

    const contactEmails = contactEmailsRaw.reduce((accum, item) => {
      const {
        email_id,
        is_primary,
      } = item;

      const email = emails[email_id];

      if (!email) {
        if (email_id) {
          optimizedEmailsActions.getEmailById(email_id);
        }

        return accum;
      }

      accum.push({
        ...email,
        email_id,
        is_primary,
      });
      return accum;
    }, []);

    if (contact.email_primary) {
      contactEmails.unshift({ email: contact.email_primary, email_id: null, is_primary: true });
    }

    return contactEmails;
  },
);
