import React from 'react';
import PropTypes from 'prop-types';

import datetime, { since } from '@symplete/symplete-utils/datetime';

import Flex from 'ui/Flex';

import Typography from 'ui/Typography';
import DateInput from 'shared/inputs/DateInput';

const Birthday = ({
  birthday,
  updateDateOfBirth,
  placeholder: placeholderProps,
}) => {
  const placeholder = birthday ? null : placeholderProps;
  const value = birthday ? datetime(birthday) : null;

  return (
    <Flex
      spacing={1}
      alignItems="baseline"
      fullHeight
    >
      <DateInput
        variant="inline"
        view="date"
        handleChange={updateDateOfBirth}
        label={null}
        placeholder={placeholder}
        value={value}
        disableFuture
        autoOk
        customFormat="MMM DD, YYYY"
      />

      <Typography
        tag="p"
        noMargin
      >
        {value && `(${since(value, 'year')} y.o.)`}
      </Typography>
    </Flex>
  );
};

Birthday.defaultProps = {
  birthday: null,
  placeholder: 'Birthday',
};

const {
  string,
  func,
} = PropTypes;

Birthday.propTypes = {
  birthday: string,
  updateDateOfBirth: func.isRequired,
  placeholder: string,
};

export default Birthday;
