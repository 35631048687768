/* eslint-disable react/jsx-no-bind, react/jsx-props-no-spreading */
import React, {
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';

import { Spin } from 'antd';

import Grid from 'ui/Grid';

import Typography from 'ui/Typography';
import SpaceBetweenBox from 'shared/components/UI/SpaceBetweenBox';
import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';

import ReasonMultiSelect from './ReasonMultiSelect';
import RejectionNotesInput from './RejectionNotesInput';

import {
  SectionWrapper,
} from './styledItems';

const MatchingPropertyRejectionForm = (props) => {
  const {
    onSubmit: propsOnSubmit,
    formId,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onTouched',
  });

  const onSubmit = useCallback(async (data) => {
    setIsLoading(true);
    await propsOnSubmit(data);
    reset();
    setIsLoading(false);
  }, [
    propsOnSubmit,
    reset,
  ]);

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <Spin spinning={isLoading} delay={600}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DecoratedFieldWrapper
              iconName="link"
              variant="highlight"
              noMargin
            >
              <SectionWrapper>
                <ReasonMultiSelect
                  control={control}
                  errors={errors}
                />
              </SectionWrapper>
            </DecoratedFieldWrapper>
          </Grid>

          <Grid item xs={12}>
            <DecoratedFieldWrapper
              iconName="file-alt"
              variant="highlight"
              noMargin
              align="flex-start"
              iconMarginTop="15px"
            >
              <SectionWrapper>
                <RejectionNotesInput
                  control={control}
                  errors={errors}
                />
              </SectionWrapper>
            </DecoratedFieldWrapper>
          </Grid>

        </Grid>
      </Spin>
    </form>
  );
};

MatchingPropertyRejectionForm.defaultProps = {
};

const {
  func,
  string,
} = PropTypes;

MatchingPropertyRejectionForm.propTypes = {
  onSubmit: func.isRequired,
  formId: string.isRequired,
};

export default MatchingPropertyRejectionForm;
