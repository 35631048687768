import React from 'react';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

import Placeholder1 from '../../assets/images/placeholders/1.jpg';
import Placeholder2 from '../../assets/images/placeholders/2.jpg';

import './index.scss';

const mockup = [
  {
    originalName: 'Placeholder 1',
    attachmentId: '1',
    url: Placeholder1,
  },
  {
    originalName: 'Placeholder 2',
    attachmentId: '2',
    url: Placeholder2,
  },
];

const PostCard = ({
 header, footer, propertyImages, children,
}) => {
  const pagination = {
    clickable: true,
    horizontalClass: 'postcard-custom-swiper',
    renderBullet(index, className) {
      return `<span class="${classNames(
        className,
        'postcard-hero-swiper-pagination',
      )}"> </span>`;
    },
  };

  const images = propertyImages && propertyImages.length > 0 ? propertyImages : mockup;

  return (
    <div className="postcard-container">
      <div className="postcard-hero-section">
        <Swiper
          pagination={pagination}
          modules={[Pagination]}
          className="postcard-hero-swiper"
        >
          {images.map((image) => (
            <SwiperSlide key={image.attachmentId}>
              <img src={image.url} alt={image.originalName} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="postcard-hero-header">{header}</div>
        <div className="postcard-hero-footer">{footer}</div>
      </div>
      {children}
    </div>
  );
};

export default PostCard;
