import React, {
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { Popover, Button } from 'antd';
import Typography from 'ui/Typography';
import Avatar from '@material-ui/core/Avatar';

import AssignInterestedPartyModal from 'shared/modals/AssignInterestedPartyModal';

import {
  Root,
  Row,
  Data,
} from './styledItems';

const buttonStyle = { padding: '0 0 0 3px', fontSize: 11, height: 'auto' };

const InspectorContactCell = ({
  contact,
  changeContact,
}) => {
  const {
    email_primary,
    phone_primary,
    first_name,
    last_name,
  } = contact;

  const [popoverVisibility, setPopoverVisibility] = useState(false);
  const [assignContactModal, setAssignContactModal] = useState(false);

  const getPopoverContent = useCallback(() => (
    <Root>
      <Row>
        {
          (first_name || last_name) && (
            <Avatar>
              {first_name[0].toUpperCase() + last_name[0]?.toUpperCase()}
            </Avatar>
)
        }
        <Data>
          <Typography noMargin>{`${first_name || ''} ${last_name || ''}`}</Typography>
          <Typography noMargin>{`Email: ${email_primary}`}</Typography>
          <Typography noMargin>{`Phone: ${phone_primary}`}</Typography>
        </Data>
      </Row>
    </Root>
  ), [
    phone_primary,
    email_primary,
    first_name,
    last_name,
  ]);

  const setContactModalVisibility = useCallback((isVisible) => () => {
    setAssignContactModal(isVisible);
    setPopoverVisibility(false);
  }, []);

  const setPopover = useCallback((isVisible) => () => {
    setPopoverVisibility(isVisible);
  }, []);

  const onChangeContact = useCallback((newContactIds) => {
    setAssignContactModal(false);
    changeContact(newContactIds.addedContacts[0]);
  }, []);

  return (
    <Popover
      content={getPopoverContent()}
      visible={popoverVisibility}
      onMouseEnter={setPopover(true)}
      onMouseLeave={setPopover(false)}
    >
      <Button
        type="link"
        style={buttonStyle}
        onClick={setContactModalVisibility(true)}
      >
        <Typography
          variant="body"
          textDecoration="underline"
          color="link"
          noMargin
        >
          {`${first_name || ''} ${last_name || ''}`}
        </Typography>
      </Button>

      <AssignInterestedPartyModal
        title="Choose a new inspector for this inspection."
        isVisible={assignContactModal}
        oneContactSelect
        closeModal={setContactModalVisibility(false)}
        selectedContacts={[contact.id]}
        onSave={onChangeContact}
      />
    </Popover>
  );
};

const {
  number,
  string,
  func,
  shape,
} = PropTypes;

InspectorContactCell.defaultProps = {
  contact: {},
};

InspectorContactCell.propTypes = {
  contact: shape({
    id: number,
    email_primary: string,
    first_name: string,
    last_name: string,
  }),
  changeContact: func.isRequired,
};

export default InspectorContactCell;
