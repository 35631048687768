import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  updateProperty,
} from 'store/actions/properties';

import Players from 'shared/modules/Players';

const mapStateToProps = (state, ownProps) => {
  const {
    propertyId,
  } = ownProps;

  const property = selectors.properties.byId(state, propertyId);

  return {
    playersData: [
      {
        key: 'listing_agent',
        role: 'listing agent',
        contactId: property.listing_agent,
      },
    ],
    withRoleSelection: false,
    preset: true,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAdd: (contactId) => {
    dispatch(updateProperty(ownProps.propertyId)({ listing_agent: contactId }));
  },
  onRemove: (contactId) => {
    dispatch(updateProperty(ownProps.propertyId)({ listing_agent: null }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Players);
