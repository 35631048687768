import React from 'react';

// import { isEmpty } from 'shared/utility';

import Typography from 'ui/Typography';
import TableHead from 'ui/TableHead';

// import PropertyParametersIndicators from 'shared/modules/PropertyParametersIndicators';
import ActionButtons from 'sections/MLS/modules/PropertiesTable/ActionButtons';
import DetailsCell from 'sections/MLS/modules/PropertiesTable/DetailsCell';

// import PropertyLocationCell from 'shared/components/UI/TableCells/RealtorCells/PropertyLocationCell';
import PropertyListingAgentCell from 'shared/components/UI/TableCells/RealtorCells/PropertyListingAgentCell';
// import PropertyStatusCell from 'shared/components/UI/TableCells/RealtorCells/PropertyStatusCell';
import PropertyViewingCell from 'shared/components/UI/TableCells/RealtorCells/PropertyViewingCell';
import PropertyPriceCell from 'shared/components/UI/TableCells/RealtorCells/PropertyPriceCell';

import formatOnMarketTime from './formatOnMarketTime';

const columns = ({
  hoveredRecord,
  onSort,
}) => ([
  // {
  //   title: '',
  //   dataIndex: 'id',
  //   key: 'image',
  //   className: 'realtor-properties-table__xs-padding',
  //   width: 1,
  //   align: 'center',
  //   render: (value) => <PropertyImageCell propertyId={value} />,
  // },
  {
    title: () => (
      <TableHead onSort={onSort('address')}>
        Address
      </TableHead>
    ),
    dataIndex: 'address_id',
    width: 5,
    key: 'address',
    // sorter: true,
    render: (value, record) => (
      <DetailsCell propertyId={record.id} />
    ),
    align: 'center',
  },
  // {
  //   // title: 'Status',
  //   title: () => (
  //     <TableHead onSort={onSort('status')}>
  //       Status
  //     </TableHead>
  //   ),
  //   width: 120,
  //   dataIndex: 'status',
  //   key: 'status',
  //   align: 'center',
  //   // sorter: true,
  //   render: (value) => <PropertyStatusCell status={value} />,
  // },
  {
    // title: 'Price',
    title: () => (
      <TableHead onSort={onSort('expectedPrice')}>
        Price
      </TableHead>
    ),
    dataIndex: 'expected_price',
    key: 'expectedPrice',
    width: 2,
    align: 'right',
    // sorter: true,
    render: (value) => <PropertyPriceCell value={value} />,
  },
  {
    // title: 'Listing Agent',
    title: () => (
      <TableHead onSort={onSort('listingAgent')}>
        Listing Agent
      </TableHead>
    ),
    dataIndex: 'listing_agent',
    key: 'listing_agent',
    width: 4,
    // sorter: true,
    render: (value) => <PropertyListingAgentCell listingAgent={value} />,
  },
  {
    // title: 'On Market',
    title: () => (
      <TableHead onSort={onSort('onMarket')}>
        DOM
      </TableHead>
    ),
    width: 2,
    key: 'onMarket',
    dataIndex: 'on_market_since',
    align: 'right',
    // sorter: true,
    render: (value) => (
      <Typography variant="cell">
        {value ? `${formatOnMarketTime(value)} ${formatOnMarketTime(value) > 1 ? 'days' : 'day'}` : '—'}
      </Typography>
    ),
  },
  // {
  //   // title: 'Details',
  //   title: () => (
  //     <TableHead onSort={onSort('propertyDetails')}>
  //       Details
  //     </TableHead>
  //   ),
  //   dataIndex: 'id',
  //   key: 'propertyDetails',
  //   width: 5,
  //   // sorter: true,
  //   render: (value) => <PropertyParametersIndicators propertyId={value} />,
  // },
  {
    // title: 'Viewings',
    title: () => (
      <TableHead onSort={onSort('viewing')}>
        Showings
      </TableHead>
    ),
    width: 3,
    dataIndex: 'id',
    key: 'viewing',
    // sorter: true,
    render: (value, record) => {
      if (hoveredRecord === record.key) {
        return (
          <ActionButtons propertyId={value} />
        );
      }

      return <PropertyViewingCell propertyId={value} />;
    },
  },
]);

export default columns;
