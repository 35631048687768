import { connect } from 'react-redux';

import selectors from 'store/selectors';

import Kanban from 'ui/Kanban';

import MatchingPropertyCard from 'sections/Prospects/modules/MatchingPropertyCardV2';

const mapStateToProps = (state, ownProps) => {
  const {

  } = state;

  const {
    prospectId,
  } = ownProps;

  const lanes = selectors.realtorMatchingProperties.kanbanProspect(prospectId)(state);

  return {
    lanes,
    CardComponent: MatchingPropertyCard,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(Kanban);
