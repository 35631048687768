import { connect } from 'react-redux';

import selectors from 'store/selectors';

import PropertyRejectionReasonCell from './PropertyRejectionReasonCell';

const mapStateToProps = (state, ownProps) => {
  const {
    reasonIds,
  } = ownProps;

  if (!reasonIds) {
    return {
      reason: '—',
    };
  }

  const rejectionReasons = selectors.dictionary.rejectedPropertiesReasons(state);

  const reason = reasonIds.reduce((acc, id) => {
    const reasonItem = rejectionReasons.find((item) => item.id === id);

    if (reasonItem) {
      acc.push(reasonItem.name);
    }

    return acc;
  }, []);

  return {
    reason: reason.length > 0
      ? reason.join(', ')
      : '—',
  };
};

export default connect(mapStateToProps)(PropertyRejectionReasonCell);
