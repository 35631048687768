/* eslint-disable react/jsx-no-bind */

import React, { useState, useCallback } from 'react';

import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { Popconfirm } from 'antd';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';
import Icon from 'ui/Icon';
import Button from 'ui/Button';

import ContactAvatar from 'shared/modules/RealtorContactAvatar';

import EmailDrawer from 'sections/Contacts/modules/EmailDrawer';
import Call from 'sections/Contacts/modules/Call';

import {
  NoContactContainer,
  HoverContainer,
} from './styledItems';

const PlayerItemV2 = (props) => {
  const {
    onClick,
    contactId,
    name,
    role,
    onDelete,
  } = props;

  const [hovered, setHovered] = useState(false);
  const [popconfirmVisible, setPopconfirmVisible] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const history = useHistory();

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const toggleHover = (state) => {
    setHovered(state);
  };

  const handleClick = useCallback((event) => {
    if (contactId) {
      history.push(`/contacts/${contactId}`);
    } else {
      onClick();
    }
  }, [
    contactId,
    onClick,
  ]);

  const handleDelete = (event) => {
    event.stopPropagation();
    onDelete(contactId, role);
    setPopconfirmVisible(false);
  };

  const togglePopconfirm = (event) => {
    event.stopPropagation();
    setPopconfirmVisible(true);
  };

  return (
    <>
      <Flex
        flexDirection="column"
        spacing={1}
        onMouseEnter={() => toggleHover(true)}
        onMouseLeave={() => toggleHover(false)}
      >
        <Flex
          fullWidth
        >
          {
            contactId
            ? (
              <div>
                {
                    (hovered || popconfirmVisible) && (
                      <HoverContainer>
                        <Flex
                          justifyContent="space-between"
                        >
                          <Popconfirm
                            title="Are you sure you want to remove this key user?"
                            onConfirm={handleDelete}
                            onCancel={() => setPopconfirmVisible(false)}
                            okText="Confirm"
                            cancelText="Cancel"
                            visible={popconfirmVisible}
                          >
                            <Button
                              variant="tertiary"
                              icon="trash"
                              iconSize="xs"
                              iconColor="contrast"
                              onClick={togglePopconfirm}
                            />
                          </Popconfirm>

                          <Button
                            variant="tertiary"
                            icon="external-link"
                            iconSize="xs"
                            iconColor="contrast"
                            onClick={handleClick}
                          />
                        </Flex>
                        <Flex
                          style={{ marginTop: 'auto' }}
                          justifyContent="space-between"
                          fullWidth
                          spacing={1}
                        >
                          <Call
                            contactId={contactId}
                            transparentButton
                          />

                          <Button
                            variant="tertiary"
                            icon="mail"
                            iconSize="xs"
                            iconColor="contrast"
                            onClick={toggleDrawer}
                          />
                        </Flex>
                      </HoverContainer>
                    )
                  }
                <ContactAvatar contactId={contactId} />
              </div>
              )
            : (
              <NoContactContainer onClick={handleClick}>
                <Icon
                  name="plus"
                  size="l"
                  color="focus"
                />
                <Typography
                  variant="subtitle1"
                  textTransform="uppercase"
                  color="focus"
                  align="center"
                  noMargin
                >
                  { role }
                </Typography>
              </NoContactContainer>
              )
          }
        </Flex>

        {
          contactId
          ? (
            <Flex
              fullWidth
              flexDirection="column"
            >
              <Flex
                fullWidth
                justifyContent="center"
              >
                <Typography
                  variant="title3"
                  align="center"
                  noMargin
                >
                  { name }
                </Typography>
              </Flex>

              <Flex
                fullWidth
                justifyContent="center"
              >
                <Typography
                  variant="subtitle1"
                  textTransform="uppercase"
                  color="focus"
                  align="center"
                  noMargin
                >
                  { role }
                </Typography>
              </Flex>
            </Flex>
            )
          : null
        }
      </Flex>
      <EmailDrawer
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        contactId={contactId}
      />
    </>
  );
};

PlayerItemV2.defaultProps = {
  contactId: null,
  name: '',
  onClick: () => {},
  onDelete: () => {},
};

const {
  number,
  string,
  func,
} = PropTypes;

PlayerItemV2.propTypes = {
  onClick: func,
  onDelete: func,
  contactId: number,
  name: string,
  role: string.isRequired,
};

export default PlayerItemV2;
