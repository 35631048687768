import { connect } from 'react-redux';

import selectors from 'store/selectors';

import ContactCell from 'sections/Leads/modules/LeadsTable/ContactCell';

const roles = {
  'prospect': 1,
  'client': 2
}

const mapStateToProps = (state, ownProps) => {
  const {
    type,
    itemId,
  } = ownProps;

  const item = selectors.realtorOpportunities.byTypeAndId(state, type, itemId);

  return {
    contactId: item.contact_id,
    role: roles[type],
    type: 'Client'
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ContactCell);
