import { connect } from 'react-redux';

import CampaignDetails from './CampaignDetails';

const mapStateToProps = (state, ownProps) => {
  const campaignId = ownProps?.match?.params?.campaignId;

  return {
    campaignId: ownProps.campaignId || campaignId,
  };
};

export default connect(mapStateToProps)(CampaignDetails);
