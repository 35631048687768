import { connect } from 'react-redux';

import selectors from 'store/selectors';

import SellerDetails from './SellerDetails';

const mapStateToProps = (state, ownProps) => {
  const property = selectors.properties.byId(state, ownProps.itemId);
  const address = selectors.addresses.byId(state, property.address_id);

  return {
    propertyId: ownProps.itemId,
    address: address?.address,
  };
};

export default connect(mapStateToProps)(SellerDetails);
