import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import createHtmlMarkup from 'shared/utils/createHtmlMarkup';

import EditableTextField from 'shared/components/EditableTextField';

const ContactDescription = (props) => {
  const {
    onChange,
    content,
  } = props;

  const renderValue = useCallback((value) => (
    <div className="ticket-text__content">
      {createHtmlMarkup(value)}
    </div>
  ), []);

  const text = content === '<p></p>' ? '' : content;

  return (
    <EditableTextField
      richTextEditor
      onConfirm={onChange}
      value={text}
      placeholder={'<p style="cursor: pointer;">Enter contact description...</p>'}
      renderValue={renderValue}
    />
  );
};

ContactDescription.defaultProps = {
  content: '<p></p>',
};

const {
  string,
  func,
} = PropTypes;

ContactDescription.propTypes = {
  content: string,
  onChange: func.isRequired,
};

export default ContactDescription;
