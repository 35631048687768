import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import Typography from 'ui/Typography';
import Button from 'ui/Button';
import KPIItem from 'ui/KPIItem';

import ErrorBoundary from 'ui/ErrorBoundary';

import withModal from 'ui/hocs/withModal';

import OptionsV2 from 'sections/Prospects/modules/OptionsV2';

import RequirementsAddressList from 'sections/Properties/modules/RequirementsAddressList';
import RequirementsPriceRangeInput from 'sections/Properties/modules/RequirementsPriceRangeInput';
import RequirementsPriceRangeDisplay from 'sections/Properties/modules/RequirementsPriceRangeDisplay';
import RequirementsParametersMain from 'sections/Properties/modules/RequirementsParametersMain';
import RequirementsPropertyTypeSelect from 'sections/Properties/modules/RequirementsPropertyTypeSelect';
import RequirementsSaleTypeSelect from 'sections/Properties/modules/RequirementsSaleTypeSelect';
import RequirementsViewsFromHomeSelect from 'sections/Properties/modules/RequirementsViewsFromHomeSelect';
import RequirementsYearBuiltSelect from 'sections/Properties/modules/RequirementsYearBuiltSelect';
import RequirementsNumberOfStoriesSelect from 'sections/Properties/modules/RequirementsNumberOfStoriesSelect';

import ContactDescription from 'sections/Contacts/modules/ContactDescription';
import ContactLikes from 'sections/Contacts/modules/ContactLikes';
import ContactDislikes from 'sections/Contacts/modules/ContactDislikes';

import PreApprovalControl from 'sections/Properties/modules/RequirementsPreApproval/PreApprovalControl';

import AdditionalAmenities from 'shared/modules/AdditionalAmenities';

const BuyerNeeds = (props) => {
  const {
    prospectId,
    contactId,
    propertyRequirementId,

    // expansion props
    isExpanded,
    toggleModalVisibility,
  } = props;

  const mode = isExpanded ? 'edit' : 'display';

  return (
    <ErrorBoundary>
      <Flex spacing={isExpanded ? 4 : 2} flexWrap="wrap">
        <Flex
          spacing={isExpanded ? 4 : 2}
          flexWrap="wrap"
          alignItems="center"
        >
          <Flex
            spacing={2}
            fullWidth
            justifyContent="space-between"
          >
            <Flex
              flex={1}
              spacing={2}
            >
              {
                isExpanded && (
                  <RequirementsPriceRangeInput
                    itemId={propertyRequirementId}
                  />
                )
              }

              {
                !isExpanded && (
                  <div style={{ display: 'inline-block' }}>
                    <KPIItem
                      iconName="dollar-sign"
                      variant="flat"
                      tooltip="Budget"
                    >
                      <RequirementsPriceRangeDisplay
                        itemId={propertyRequirementId}
                      />
                    </KPIItem>
                  </div>
                )
              }

              {
                !isExpanded && (
                  <div style={{ display: 'inline-block' }}>
                    <KPIItem
                      iconName="bookmark"
                      variant="flat"
                      // tooltip="Pre-approval" // need to fix tooltip position
                    >
                      <PreApprovalControl
                        propertyRequirementId={propertyRequirementId}
                      />
                    </KPIItem>
                  </div>
                )
              }

              {
                !isExpanded && (
                  <FlexItem flex={1}>
                    <KPIItem
                      iconName="map-pin"
                      variant="flat"
                    >
                      <RequirementsAddressList
                        defaultView={mode}
                        itemId={propertyRequirementId}
                      />
                    </KPIItem>
                  </FlexItem>
                )
              }
            </Flex>

            {
              !isExpanded && (
                <FlexItem alignSelf="flex-start" keepSize>
                  <OptionsV2
                    prospectId={prospectId}
                    buttonProps={{
                      variant: 'secondary',
                    }}
                  />
                </FlexItem>
              )
            }
          </Flex>

          {
            isExpanded && (
              <FlexItem fullWidth>
                <RequirementsAddressList
                  defaultView={mode}
                  itemId={propertyRequirementId}
                />
              </FlexItem>
            )
          }

          <Flex
            spacing={isExpanded ? 4 : 2}
            fullWidth
            alignItems="center"
            flexWrap="wrap"
          >
            {
              !isExpanded
                ? (
                  <KPIItem
                    iconName="home"
                    variant="flat"
                  >
                    <RequirementsPropertyTypeSelect
                      itemId={propertyRequirementId}
                      defaultView={mode}
                      label={null}
                      dense
                      chipListProps={{
                        ellipsis: 1,
                      }}
                    />
                  </KPIItem>
                  )
                  : (
                    <RequirementsPropertyTypeSelect
                      itemId={propertyRequirementId}
                      defaultView={mode}
                    />
                )
            }
            <FlexItem flex={1}>
              {
                !isExpanded
                  ? (
                    <KPIItem
                      iconName="sliders"
                      variant="flat"
                    >
                      <RequirementsParametersMain
                        itemId={propertyRequirementId}
                        defaultView={mode}
                      />
                    </KPIItem>
                    )
                  : (
                    <RequirementsParametersMain
                      itemId={propertyRequirementId}
                      defaultView={mode}
                    />
                )
              }
            </FlexItem>
          </Flex>

          {
            isExpanded && (
              <Flex
                fullWidth
                spacing={4}
                flexWrap="wrap"
              >
                <FlexItem xs={12}>
                  <RequirementsYearBuiltSelect
                    itemId={propertyRequirementId}
                    defaultView={mode}
                  />
                </FlexItem>

                <FlexItem xs={12}>
                  <RequirementsNumberOfStoriesSelect
                    defaultView={mode}
                    itemId={propertyRequirementId}
                  />
                </FlexItem>

                <FlexItem xs={12}>
                  <RequirementsSaleTypeSelect
                    defaultView={mode}
                    itemId={propertyRequirementId}
                  />
                </FlexItem>

                <FlexItem xs={12}>
                  <RequirementsViewsFromHomeSelect
                    itemId={propertyRequirementId}
                    defaultView={mode}
                  />
                </FlexItem>
              </Flex>
            )
          }
        </Flex>

        {
          isExpanded && (
            <FlexItem fullWidth>
              <Typography
                variant="title2"
              >
                Property Features
              </Typography>
              <AdditionalAmenities
                itemId={propertyRequirementId}
                itemType="property_requirements"
              />
            </FlexItem>
          )
        }

        {
          !isExpanded && (
            <Flex
              spacing={4}
              fullWidth
              alignItems="flex-end"
            >
              <Flex
                flex={1}
                spacing={4}
              >
                <FlexItem xs={8}>
                  <Typography
                    variant="title3"
                  >
                    Notes
                  </Typography>
                  <ContactDescription contactId={contactId} />
                </FlexItem>

                <FlexItem xs={16}>
                  <Flex spacing={2}>
                    <FlexItem xs={12}>
                      <Typography
                        variant="title3"
                      >
                        Likes
                      </Typography>
                      <ContactLikes
                        contactId={contactId}
                        ellipsis={2}
                      />
                    </FlexItem>
                    <FlexItem xs={12}>
                      <Typography
                        variant="title3"
                      >
                        Dislikes
                      </Typography>
                      <ContactDislikes
                        contactId={contactId}
                        ellipsis={2}
                      />
                    </FlexItem>
                  </Flex>
                </FlexItem>
              </Flex>

              <Button
                onClick={toggleModalVisibility}
                variant="primary"
              >
                Show More
              </Button>
            </Flex>
          )
        }

        {
          isExpanded && (
            <Flex fullWidth justifyContent="flex-end">
              <Button onClick={toggleModalVisibility} variant="primary">
                Show Less
              </Button>
            </Flex>
          )
        }

      </Flex>
    </ErrorBoundary>
  );
};

BuyerNeeds.defaultProps = {
  isExpanded: false,
};

const {
  bool,
  func,
  number,
} = PropTypes;

BuyerNeeds.propTypes = {
  prospectId: number.isRequired,
  contactId: number.isRequired,
  propertyRequirementId: number.isRequired,
  isExpanded: bool,
  toggleModalVisibility: func.isRequired,
};

export default withModal(BuyerNeeds);
