import { connect } from 'react-redux';

import selectors from 'store/selectors';

import PropertyCell from './PropertyCell';

const mapStateToProps = (state, ownProps) => {
  const {
    propertyId,
  } = ownProps;

  const property = selectors.properties.byId(state, propertyId);

  const price = property?.expected_price;

  const address = selectors.addresses.byId(state, property?.address_id)?.address;

  const image = selectors.properties.mainPropertyImage(state, propertyId);

  return {
    image,
    address,
    price,
  };
};

export default connect(mapStateToProps)(PropertyCell);
