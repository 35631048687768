import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'ui/Grid';

import Typography from 'ui/Typography';

import CreatedDateRangePicker from 'sections/Leads/modules/CreatedDateRangePicker';
import PropertyValueRangeSelect from 'sections/Leads/modules/PropertyValueRangeSelect';
import SourceSelect from './SourceSelect';
import LeadTypeSelect from './LeadTypeSelect';
// import LeadStatusSelect from './LeadStatusSelect';
import TempSelect from './TempSelect';

import {
  StyledButton,
  PropertyValueWrapper,
} from './styledItems';

const LeadsTableFilterForm = (props) => {
  const {
    filter,
    clearAllFilter,
    setFilterItem,
  } = props;

  return (
    <form>
      <Typography
        variant="h1"
        noMargin
      >
        Filter Leads
      </Typography>
      <StyledButton
        onClick={clearAllFilter}
        style={{ marginTop: 15, marginLeft: 5 }}
      >
        <Typography
          weight={600}
          color="link"
          noMargin
        >
          Clear all
        </Typography>
      </StyledButton>

      <Grid container spacing={4}>

        <Grid item xs={12}>
          <Typography
            variant="h3"
            tag="h2"
            weight={500}
          >
            Lead Details
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <SourceSelect
                value={filter.source}
                onChange={setFilterItem('source')}
              />
            </Grid>

            <Grid item xs={6}>
              <LeadTypeSelect
                value={filter.leadType}
                onChange={setFilterItem('leadType')}
              />
            </Grid>

            {/* <Grid item xs={6}>
              <LeadStatusSelect
                value={filter.leadStatus}
                onChange={setFilterItem('leadStatus')}
              />
            </Grid> */}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="h3"
            tag="h2"
            weight={500}
          >
            Date Created
          </Typography>
          <CreatedDateRangePicker
            value={filter.createdAtRange}
            onChange={setFilterItem('createdAtRange')}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="h3"
            tag="h2"
            weight={500}
          >
            Status
          </Typography>
          <TempSelect
            onChange={setFilterItem('temp')}
            options={[
              {
                value: 1,
                name: 'Cold',
              },
              {
                value: 2,
                name: 'Warm',
              },
              {
                value: 3,
                name: 'Hot',
              },
            ]}
            value={filter.temp}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="h3"
            tag="h2"
            weight={500}
          >
            Property Value
          </Typography>
          <PropertyValueWrapper>
            <PropertyValueRangeSelect
              value={filter.propertyValueRange}
              onChange={setFilterItem('propertyValueRange')}
            />
          </PropertyValueWrapper>
        </Grid>
      </Grid>
    </form>
  );
};

LeadsTableFilterForm.defaultProps = {

};

const {
  arrayOf,
  func,
  number,
  shape,
  string,
} = PropTypes;

LeadsTableFilterForm.propTypes = {
  filter: shape({
    source: arrayOf(number),
    createdAtRange: arrayOf(string),
    temp: arrayOf(number),
    propertyValueRange: arrayOf(number),
  }).isRequired,
  clearAllFilter: func.isRequired,
  setFilterItem: func.isRequired,
};

export default LeadsTableFilterForm;
