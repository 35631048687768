import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import Flex from 'ui/Flex';
import Typography from 'ui/Typography';
import Elevation from 'ui/Elevation';
import Spin from 'ui/Spin';

const StyledElevation = styled(Elevation)`
  flex: 1 0 120px;
  background-color: ${(props) => props.theme?.palette?.['background-flat']};
`;

const MetricsItem = (props) => {
  const {
    value,
    title,
    extra,
  } = props;

  return (
    <StyledElevation
      rounded
      padding="s"
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        spacing={1}
      >
        {
          value
            ? (
              <Typography
                variant="title1"
                color="focus"
                weight={500}
                noMargin
              >
                {value}
              </Typography>
            )
            : (
              <Flex
                fullWidth
                justifyContent="center"
              >
                <Spin />
              </Flex>
            )
        }
        <Typography
          variant="body"
          noMargin
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          color="disabled"
          noMargin
        >
          {extra}
        </Typography>
      </Flex>
    </StyledElevation>
  );
};

const { string } = PropTypes;

MetricsItem.propTypes = {
  value: string.isRequired,
  title: string.isRequired,
  extra: string.isRequired,
};

export default MetricsItem;
