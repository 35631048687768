import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import Chip from 'ui/Chip';
import Flex from 'ui/Flex';

import BulletedList from 'ui/BulletedList';

import Tooltip from 'ui/Tooltip';

import useAddress from 'shared/hooks/addresses/useAddress';

const LocationCellMultiple = (props) => {
  const {
    addresses,
  } = props;

  if (!addresses) return '—';

  const addressList = addresses.map((addressId) => useAddress(addressId));

  if (addressList.length < 1) return null;

  const more = addressList.length - 1;

  return (
    <Tooltip title={<BulletedList content={addressList} />}>
      <Flex
        spacing={1}
        alignItems="baseline"
        fullWidth
      >
        <Typography
          ellipsis
          variant="cellLarge"
        >
          {addressList[0]}
        </Typography>
        {more > 0 && (
          <Chip variant="subtle">
            +
            {more}
          </Chip>
        )}
      </Flex>
    </Tooltip>
  );
};

LocationCellMultiple.defaultProps = {
  addresses: [],
};

const {
  arrayOf,
  string,
} = PropTypes;

LocationCellMultiple.propTypes = {
  addresses: arrayOf(string),
};

export default LocationCellMultiple;
