import { connect } from 'react-redux';

import selectors from 'store/selectors';

import actions from 'sections/Clients/actions';
import showingActions from 'sections/Showings/actions';

// viewings
import {
  closeNewPropertyViewingModal,
} from 'store/actions/newRealtorPropertyViewingModal';

import {
  resetNewPropertyViewingForm,
} from 'store/actions/newRealtorPropertyViewingForm';

import MatchingBuyerView from './MatchingBuyerView';

const getViewingType = (lane) => {
  if (lane === 'showingsUpcoming') return 'upcoming';
  if (lane === 'showingsCompleted') return 'completed';
  return null;
};

const mapStateToProps = (state, ownProps) => {
  const {
    realtorClients: {
      selectedClient,
    },
  } = state;

  const clientId = ownProps.clientId || selectedClient;
  const client = selectors.realtorClients.byId(state, clientId);

  const getMatchingBuyer = (matchId) => selectors.realtorMatchingProperties.byId(matchId)(state);

  return {
    getMatchingBuyer,
    clientId: clientId || selectedClient,
    propertyId: client?.realtor_property_id,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onRejectBuyer: ({ matchId, onSave }) => {

  },

  closeNewPropertyViewingModal: () => {
    dispatch(closeNewPropertyViewingModal());
    dispatch(resetNewPropertyViewingForm());
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,

  updateMatchingBuyer: (matchId) => async (updateData, currentData, boardData) => {
    if (currentData?.status < updateData?.status) {
      if (updateData?.status === 2) { // -> viewings
        const {
          realtor_property_id: propertyId,
          realtor_contact_id: contactId,
        } = stateProps.getMatchingBuyer(matchId);

        const viewingType = getViewingType(boardData?.destination?.droppableId);

        showingActions.onCreateShowing({
          contactId,
          propertyId,
          type: viewingType,
          onSave: () => actions.kanban.updateMatch(matchId, stateProps.clientId)(updateData),
        });
      }

      if (updateData?.status === 5) { // -> rejected

      }
    }
  },

  onCreateShowing: (type) => () => {
    showingActions.onCreateShowing({
      propertyId: stateProps.propertyId,
      type,
      onSave: (showingData) => {
        actions.kanban.createMatch(
          {
            realtor_contact_id: showingData.contacts,
            realtor_property_id: stateProps.propertyId,
            status: 2,
          },
          ownProps.clientId,
        );

        dispatchProps.closeNewPropertyViewingModal();
      },
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(MatchingBuyerView);
