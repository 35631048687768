import styled from 'styled-components';
import { lighten } from 'polished';

import Flex from 'ui/Flex';

const getBg = (theme, color) => {
  if (color === 'background-flat') {
    return theme?.palette?.[color] || 'grey';
  }
  if (color === 'warning') {
    return lighten(0.43, theme?.palette?.[color] || 'grey');
  }
  return lighten(0.33, theme?.palette?.[color] || 'grey');
};

export const Root = styled(Flex)`
  padding: 10px 17px;
  box-shadow: 0px 2px 9px rgba(9, 55, 83, 0.1);
  border-radius: 10px;
  background-color: ${({
    theme,
    color,
  }) => getBg(theme, color)};
`;

export default {
  Root,
};
