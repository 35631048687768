import {
  SET_EXTERNAL_TOKEN,
} from 'store/actionTypes/socialIntegrations';

const setExternalToken = (value) => ({
  type: SET_EXTERNAL_TOKEN,
  payload: {
    value,
  },
});

export default setExternalToken;
