import {
  optimizedRealtorInspectionsActions,
} from 'store/actions/realtorInspections';

import withAttachmentsList from 'shared/hocs/withAttachmentsList';

const withInspectionsAttachments = (Component) => withAttachmentsList(34)({
    callbacks: {
      onAfterUpload: optimizedRealtorInspectionsActions.getInspectionAttachments,
      onAfterRemove: optimizedRealtorInspectionsActions.getInspectionAttachments,
    },
    selectorPath: 'realtorInspections.attachments',
  })(Component);

export default withInspectionsAttachments;
