import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { currency } from '@symplete/symplete-utils/numbers';

import { NakedButton } from '@symplete/symplete-ui';
// import {
//   Button,
//   Icon,
// } from 'antd';

import Grid from 'ui/Grid';

import Typography from 'ui/Typography';

import DecoratedFieldWrapper from 'ui/DecoratedFieldWrapper';

import ImageCarousel from 'sections/MLS/modules/ImageCarousel';

import PropertyParametersArea from 'sections/Clients/modules/PropertyParametersArea';
import ContactInfo from 'sections/Clients/modules/ContactInfo';
import AgreementDetails from 'sections/Clients/modules/AgreementDetails';
import ListingRemarks from 'sections/Clients/modules/ListingRemarks';
// import ShowPropertyDetailsButton from 'sections/Clients/modules/ShowPropertyDetailsButton';
// import NotablePoints from 'sections/Clients/modules/NotablePoints';
// import Disclosures from 'sections/Clients/modules/Disclosures';

import ClientItemContext from 'sections/Clients/pages/ClientItem/context';

const Sidebar = (props) => {
  const {
    propertyId,
    address,
    price,
  } = props;

  const context = useContext(ClientItemContext);

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={4}
      >
        <ImageCarousel propertyId={propertyId} />
      </Grid>

      <Grid
        item
        container
        xs={8}
        spacing={2}
      >
        <Grid
          item
          container
          xs={12}
          justify="space-between"
          alignItems="baseline"
        >
          <Grid item>
            <Typography
              variant="h1"
              weight={600}
              noMargin
              style={{ marginBottom: 5 }}
            >
              {address}
            </Typography>
          </Grid>
          <Grid item>
            <NakedButton onClick={() => { context.setTab('property details'); }}>
              <Typography variant="link" color="link" tag="span">
                View all property details
              </Typography>
            </NakedButton>
            {/* <Button type="primary">
              Actions
              <Icon type="down" style={{ fontSize: '10px' }} />
            </Button> */}
          </Grid>
        </Grid>

        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12}>
            <DecoratedFieldWrapper
              iconName="dollar-sign"
              iconType="feather"
              variant="focus"
            >
              <Typography
                variant="title1"
                noMargin
                tag="p"
              >
                {
                  price && currency.full(price)
                }
              </Typography>
            </DecoratedFieldWrapper>
          </Grid>
          <Grid item xs={12}>
            <PropertyParametersArea />
          </Grid>
        </Grid>

        <Grid item container xs={12} spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h5" color="ghost">
              SELLER
            </Typography>
            <ContactInfo />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h4">
              Listing Agreement Details
            </Typography>
            <AgreementDetails />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h4">
            Listing Remarks
          </Typography>
          <ListingRemarks />
        </Grid>
      </Grid>
    </Grid>
  );
};

const {
  number,
  string,
} = PropTypes;

Sidebar.propTypes = {
  propertyId: number.isRequired,
  address: string.isRequired,
  price: string.isRequired,
};

export default Sidebar;
