import React, {
  useCallback,
  useState,
  useMemo,
} from 'react';

import PropTypes from 'prop-types';

import datetime from '@symplete/symplete-utils/datetime';

import { NakedButton } from '@symplete/symplete-ui';

import Button from 'ui/Button';
import Selection from 'ui/Selection';
import Modal from 'ui/Modal';
import Flex from 'ui/Flex';

const extensionOptions = [
  {
    id: 'csv',
    label: 'CSV',
    iconName: 'file',
  },
  {
    id: 'tsv',
    label: 'TSV',
    iconName: 'file',
  },
];

const SaveAsFileButton = (props) => {
  const {
    children,
    buttonProps,
    fetchRequest,
    query,
    fileName,
    extension,
    variant,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [selectedExtension, setSelectedExtension] = useState(Array.isArray(extension) ? null : extension);

  const [isExtensionSelectModalOpen, setIsExtensionSelectModalOpen] = useState(false);

  const download = useCallback(async () => {
    setIsLoading(true);
    const result = await fetchRequest({ ...query, exportFormat: selectedExtension });
    setIsLoading(false);

    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement('a');

    link.href = url;

    const name = `${fileName}_${datetime().format('MM.DD.YY.HH.mm.ss')}.${selectedExtension}`;

    link.setAttribute('download', name);
    // Append to html page
    document.body.appendChild(link);
    // Force download
    link.click();
    // Clean up and remove the link
    link.parentNode.removeChild(link);
  }, [
    fetchRequest,
    query,
    selectedExtension,
  ]);

  const onClick = useCallback(() => {
    if (Array.isArray(extension)) {
      setIsExtensionSelectModalOpen(true);
    } else {
      download();
    }
  }, [
    extension,
    download,
  ]);

  const icon = useMemo(() => {
    if (isLoading) return 'loader';
    return 'download';
  }, [
    isLoading,
  ]);

  const closeExtensionSelectModal = useCallback(() => {
    setIsExtensionSelectModalOpen(false);
  });

  return (
    <>
      {
        variant === 'naked' && (
          <NakedButton onClick={onClick}>
            { children }
          </NakedButton>
        )
      }

      {
        variant !== 'naked' && (
          <Button
            icon={icon}
            variant={variant}
            onClick={onClick}
            {...buttonProps} // eslint-disable-line react/jsx-props-no-spreading
          >
            { children }
          </Button>
        )
      }

      <Modal
        visible={isExtensionSelectModalOpen}
        footer={(
          <Button
            icon={icon}
            variant="primary"
            onClick={download}
          >
            Download
          </Button>
        )}
        onCancel={closeExtensionSelectModal}
      >
        <Flex justifyContent="center">
          <Selection
            variant="vertical"
            radio
            options={extensionOptions}
            value={selectedExtension}
            onChange={setSelectedExtension}
          />
        </Flex>
      </Modal>

    </>
  );
};

SaveAsFileButton.defaultProps = {
  children: 'Save',
  buttonProps: {},
  query: {},
  fileName: '',
  extension: 'csv',
  variant: 'primary',
};

const {
  arrayOf,
  func,
  oneOf,
  shape,
  string,
} = PropTypes;

SaveAsFileButton.propTypes = {
  children: string,
  buttonProps: shape(),
  query: shape(),
  fetchRequest: func.isRequired,
  fileName: string,
  extension: arrayOf(oneOf(['csv', 'tsv'])),
  variant: string,
};

export default SaveAsFileButton;
