import { connect } from 'react-redux';

import selectors from 'store/selectors';

import Kanban from 'ui/Kanban';
import MatchingBuyerCard from 'sections/Clients/modules/MatchingBuyerCardV2';

const mapStateToProps = (state, ownProps) => {
  const lanes = selectors.realtorMatchingProperties.kanbanBuyers(ownProps.propertyId)(state);

  return {
    lanes,
    CardComponent: MatchingBuyerCard,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(Kanban);
