import { connect } from 'react-redux';

import ToDoCountColumn from './ToDoCountColumn';

const mapStateToProps = (state, ownProps) => {
  const {
    newQuestionsCount,
    newRepairRequestsCount,
    newDocumentRequestCount,
    newExtensionRequestsCount,
    newAddendumRequestsCount,
  } = ownProps;

  return {
    toDoCount:
      newQuestionsCount
      + newRepairRequestsCount
      + newDocumentRequestCount
      + newExtensionRequestsCount
      + newAddendumRequestsCount,
  };
};

export default connect(mapStateToProps, null)(ToDoCountColumn);
