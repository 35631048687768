import { connect } from 'react-redux';

import { debounce } from 'shared/utility';

import selectors from 'store/selectors';

import {
  updateTransaction,
  getTransactionAttachments,
} from 'store/actions/realtorTransactions';

import Appraisal from './Appraisal';

const mapStateToProps = (state, ownProps) => {
  const {
    transactionId,
  } = ownProps;

  const transaction = selectors.realtorTransactions.byId(state, transactionId);

  const appraisalAmount = transaction?.appraisal_amount;

  const attachments = transaction?.attachments?.['realtor transaction appraisal'] || [];

  return {
    appraisalAmount,
    attachments,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppraisalAmountChange: debounce((value) => {
    dispatch(updateTransaction(ownProps.transactionId)({
      appraisal_amount: value,
    }));
  }, 1000),
  refreshAttachments: () => {
    dispatch(getTransactionAttachments(ownProps.transactionId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Appraisal);
