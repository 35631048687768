import React from 'react';

import PropTypes from 'prop-types';

import Elevation from 'ui/Elevation';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import KPIBar from 'sections/Transactions/modules/KPIBarOverall';
import TransactionsTable from 'sections/Transactions/modules/TransactionsTable';

import { Root } from './styledItems';

const TransactionsList = (props) => {
  const {

  } = props;

  return (
    <Root>
      <Flex spacing={2} flexWrap="wrap">
        <FlexItem fullWidth>
          <KPIBar interactive />
        </FlexItem>
        <Elevation
          rounded="m"
          hideOverflow
        >
          <TransactionsTable />
        </Elevation>
      </Flex>
    </Root>
  );
};

const {

} = PropTypes;

TransactionsList.propTypes = {

};

export default TransactionsList;
