import React from 'react';

import ErrorBoundary from 'ui/ErrorBoundary';

import Icon from 'ui/Icon';

import ContactCell from 'sections/Leads/modules/LeadsTable/ContactCell';
import PropertyCell from 'shared/modules/PropertyCell';
import Metrics from 'sections/Leads/modules/LeadsTable/Metrics';

import TableHead from 'ui/TableHead';
import Typography from 'ui/Typography';

import getPriceRangeDisplayValue from 'sections/Leads/utils/getPriceRangeDisplayValue';

import formatValue from './formatValue';

// const priorities = {
//   1: 'Contact/Pitch',
//   2: 'Follow Up',
//   3: 'Gather Information',
//   4: 'Sign Agreement',
//   5: 'Convert to Client',
  // 6: 'Gather Property Details',
  // 7: 'Sign Listing Agreement',
// };

const metrics = {
  properties: [
    {
      key: 'daysSinceLastContact',
      iconName: 'phone-call',
      tooltip: 'Days Since Last Contact',
    },
    // {
    //   key: 'listingPitchArranged',
    //   iconName: 'investment',
    //   iconType: 'custom',
    //   tooltip: 'Listing Pitch Arranged',
    // },
    {
      key: 'daysUntilClose',
      iconName: 'file-text',
      tooltip: 'Days Until Close',
    },
  ],
  property_requirements: [
    {
      key: 'daysSinceLastContact',
      iconName: 'phone-call',
      tooltip: 'Days Since Last Contact',
    },
    // {
    //   key: 'meetingArranged',
    //   iconName: 'calendar',
    //   tooltip: 'Meeting Arranged',
    // },
    {
      key: 'daysUntilClose',
      iconName: 'file-text',
      tooltip: 'Days Until Close',
    },
  ],
};

const getTags = (leadEntry) => {
  const tags = [];

  if (leadEntry?.isNew) {
    tags.push('new');
  }

  return tags;
};

const columns = ({
  hoveredRecord,
  onSort,
}) => ([
  {
    title: () => (
      <TableHead onSort={onSort('leadName')}>
        Lead Name
      </TableHead>
      ),
    dataIndex: 'personId',
    key: 'leadName',
    width: 5,
    render: (value, entry) => {
      const tags = getTags(entry);

      return (
        <ErrorBoundary>
          {
            value
              ? (
                <ContactCell
                  contactId={value}
                  tags={tags}
                  role={entry?.leadType}
                  type="Lead"
                />
              )
              : null
          }
        </ErrorBoundary>
      );
    },
  },
  {
    title: () => (
      <TableHead onSort={onSort('status')}>
        Temp
      </TableHead>
      ),
    width: 1,
    dataIndex: 'status',
    key: 'status',
    render: (variant) => (
      <ErrorBoundary>
        {
          variant
            ? (
              <Icon
                name={variant}
                color={variant}
                type="custom"
                size="m"
              />
            )
            : null
        }
      </ErrorBoundary>
    ),
    align: 'center',
  },
  {
    title: () => (
      <TableHead>
        Property
      </TableHead>
    ),
    width: 5,
    render: (value, entry) => (
      <ErrorBoundary>
        <PropertyCell
          itemType={entry?.connectedItemType}
          itemId={entry?.connectedItemId}
        />
      </ErrorBoundary>
    ),
  },
  {
    title: () => (
      <TableHead onSort={onSort('budgetPrice')}>
        Budget/Price
      </TableHead>
      ),
    dataIndex: 'type',
    key: 'budgetPrice',
    width: 2,
    render: (value, entry) => {
      if (value === 2) {
        return (
          <ErrorBoundary>
            <Typography variant="cellLarge">
              {entry?.expectedSalePrice ? formatValue(entry?.expectedSalePrice) : '–'}
            </Typography>
          </ErrorBoundary>
        );
      }

      if (value === 1) {
        return (
          <ErrorBoundary>
            <Typography variant="cellLarge">
              {
                getPriceRangeDisplayValue(
                  entry?.expectedPurchasePrice?.[0],
                  entry?.expectedPurchasePrice?.[1],
                )
              }
            </Typography>
          </ErrorBoundary>
        );
      }

      return (
        <ErrorBoundary>
          <Typography variant="cellLarge">
            zero
          </Typography>
        </ErrorBoundary>
      );
    },
  },
  {
    title: () => (
      <TableHead>
        Metrics
      </TableHead>
    ),
    dataIndex: 'type',
    key: 'budgetPrice',
    width: 3.5,
    render: (value, entry) => (
      <ErrorBoundary>
        <Metrics
          data={entry}
          metrics={metrics[entry?.connectedItemType]}
        />
      </ErrorBoundary>
    ),
  },
  {
    title: () => (
      <TableHead>
        Next priority
      </TableHead>
    ),
    dataIndex: 'type',
    key: 'budgetPrice',
    width: 2.5,
    render: (value, entry) => (
      <ErrorBoundary>
        <Typography
          noMargin
          weight="bold"
          color="focus"
          noWrap
        >
          COMING SOON
        </Typography>
      </ErrorBoundary>
    ),
  },
]);

export default columns;
