import styled from 'styled-components';

import { lighten } from 'polished';

import HouseDefault from 'assets/icons/property.svg';

export const Root = styled.div`
  border-radius: 10px;
  box-shadow: ${(props) => props.theme.shadows?.elevated};
  border: 1px solid ${(props) => props.theme.palette?.['background-flat'] || 'lightgray'};;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const BuyerContainer = styled.div`
  display: flex;
`;

export const BuyerDetailsContainer = styled.div`
  flex: 1;
  padding: 5px;
`;

export const AvatarContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid white;
`;

export const PhotoContainer = styled.div`
  position: relative;
`;

export const PropertyPhoto = styled.div`
  width: 100%;
  height: 105px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("${(props) => props.src || HouseDefault}");
  background-color: ${(props) => props.theme.palette?.['background-flat-dark'] || 'lightgray'};
  background-size: ${(props) => (props.src ? 'cover' : '20%')};
`;

export const Label = styled.div`
  border-radius: 15px;
  border: 1px white solid;
  color: white;
  background-color: ${(props) => props.theme.palette?.buyer};
  text-transform: uppercase;
  padding: 0px 10px;
  width: 85px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: bold;
  margin: 5px 0;
`;

export const Notice = styled.div`
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border: 1px solid ${(props) => props.theme?.palette[props.variant] || 'green'};
  background: ${(props) => (props.theme?.palette[props.variant]
    ? lighten(0.3, props.theme?.palette[props.variant])
    : lighten(0.3, '#00FF00'))
};
  text-transform: uppercase;
  height: 27px;
  font-size: 14px;
  line-height: 15px;
  color: ${(props) => props.theme?.palette[props.variant] || 'green'};
  padding: 6px;
  font-weight: 500;
`;

export default {
  Root,
  AvatarContainer,
  PropertyPhoto,
  Label,
  Notice,
  PhotoContainer,
};
