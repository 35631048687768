import React from 'react';

import moment from 'moment';

// import { Tooltip } from 'antd';

// import BulletedList from 'ui/BulletedList';

import ContactCell from 'sections/Leads/modules/LeadsTable/ContactCell';
// import LocationCell from 'sections/Leads/modules/LeadsTable/LocationCell';
// import LocationCellMultiple from 'sections/Leads/modules/LeadsTable/LocationCellMultiple';
// import LeadPropertyParametersIndicators from 'sections/Leads/modules/LeadPropertyParametersIndicators';
import RoleLabel from 'sections/Leads/components/RoleLabel';

import Typography from 'ui/Typography';

import UnderConstructionPlaceholder from 'shared/components/UI/UnderConstructionPlaceholder';

import ActionButtons from './ActionButtons';

import PropertyPrice from './PropertyPrice';
import PropertyType from './PropertyType';
import PropertyViewings from './PropertyViewings';
import Location from './Location';
import NextViewing from './NextViewing';

// import formatValue from './formatValue';
// import formatCreationTime from './formatCreationTime';

const columns = ({
  hoveredRecord,
}) => ([
  {
    title: 'Name',
    dataIndex: 'contact_id',
    key: 'contact_id',
    width: 150,
    render: (value, entry) => (value ? (
      <ContactCell
        contactId={value}
      />
    ) : null),
  },
  {
    title: 'Status',
    width: 75,
    dataIndex: 'status',
    key: 'status',
    render: (value, entry) => (
      <Typography variant="cell" style={{ textTransform: 'capitalize' }}>
        {value || '—'}
      </Typography>
    ),
    align: 'left',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'role',
    width: 150,
    render: (value, entry) => <RoleLabel role={value === 'client' ? 2 : 1} />,
  },
  {
    title: 'Budget / Price',
    dataIndex: 'type',
    key: 'budgetPrice',
    width: 150,
    // sorter: true,
    render: (value, entry) => {
      if (value === 'client') {
        return (
          <PropertyPrice
            propertyId={entry.realtor_property_id}
          />
        );
      }

      return null;

      // if (value === 1) {
      //   return (
      //     <Typography variant="cell">
      //       {`${formatValue(entry.expectedPurchasePrice[0])}–${formatValue(entry.expectedPurchasePrice[1])}`}
      //     </Typography>
      //   );
      // }

      // return (
      //   <Typography variant="cell">
      //     zero
      //   </Typography>
      // );
    },
  },
  {
    title: 'Property Type',
    dataIndex: 'type',
    key: 'propertyType',
    render: (value, entry) => {
      if (value === 'client') {
        return (
          <PropertyType
            propertyId={entry.realtor_property_id}
          />
        );
      }

      return null;

      // if (!value || !Array.isArray(value)) {
      //   return '';
      // }
      // if (value.length > 1) { // eslint-disable-line react/destructuring-assignment
      //   return (
      //     <Tooltip title={<BulletedList content={value} />}>
      //       <Typography variant="cell">
      //         {`${value[0]} + ${value.length - 1}`}
      //       </Typography>
      //     </Tooltip>
      //   );
      // }
      // return (
      //   <Typography variant="cell">
      //     {/* eslint-disable-next-line react/destructuring-assignment */}
      //     {value[0]}
      //   </Typography>
      // );
    },
  },
  {
    title: 'Location',
    dataIndex: 'type',
    width: 240,
    key: 'location',
    render: (value, entry) => {
      if (value === 'client') {
        return (
          <Location
            propertyId={entry.realtor_property_id}
          />
        );
      }

      return null;
    },
  },
  {
    title: 'Reviewed',
    width: 120,
    dataIndex: 'reviewed',
    key: 'reviewed',
    render: (value, entry) => (
      <UnderConstructionPlaceholder>
        <Typography variant="cell">
          3
        </Typography>
      </UnderConstructionPlaceholder>
    ),
    align: 'left',
  },
  {
    title: 'Showings',
    width: 120,
    dataIndex: 'viewings',
    key: 'viewings',
    render: (value, entry) => (
      <PropertyViewings
        propertyId={entry.realtor_property_id}
      />
    ),
    align: 'left',
  },
  {
    title: 'Next showing',
    width: 120,
    dataIndex: 'next_viewing_id',
    key: 'nextViewing',
    render: (value, entry) => (
      <NextViewing viewingId={value} />
    ),
    align: 'left',
  },
  {
    title: 'Age',
    dataIndex: 'created_at',
    key: 'created_at',
    // render: formatCreationTime,
    sorter: true,
    className: 'realtor-leads-table__sm-padding',
    render: (value, record) => {
      if (hoveredRecord === record.key) {
        // const {
        //   leadId,
        //   connectedItemType,
        //   connectedItemId,
        // } = record;

        // return null

        return (
          <ActionButtons />
        );
      }

      return (
        <Typography variant="cell">
          {moment.duration(moment(value).diff()).humanize(true)}
        </Typography>
      );
    },
  },
]);

export default columns;
