import React from 'react';
import PropTypes from 'prop-types';

import htmlParser from 'react-html-parser';
import { noOp } from 'shared/utility';

import Modal from 'ui/Modal';
import Spin from 'ui/Spin';
import Typography from 'ui/Typography';

import useCampaignSampleMessage from 'sections/Communications/hooks/useCampaignSampleMessage';

const CampaignSampleMessageModal = (props) => {
  const {
    isVisible,
    closeModal,
    campaignId,
  } = props;

  const {
    message,
    isLoading,
  } = useCampaignSampleMessage(isVisible ? campaignId : null);

  return (
    <Modal
      width={900}
      visible={isVisible}
      onCancel={closeModal}
      footer={null}
      title="Preview email"
    >
      <Spin spinning={isLoading}>
        <div>
          {
            !message && <Typography>No email preview for the campaign.</Typography>
          }
          {
            message && htmlParser(message)
          }
        </div>
      </Spin>
    </Modal>
  );
};

CampaignSampleMessageModal.defaultProps = {
  isVisible: false,
  closeModal: noOp,
};

const {
  bool,
  func,
  number,
} = PropTypes;

CampaignSampleMessageModal.propTypes = {
  isVisible: bool,
  closeModal: func,
  campaignId: number.isRequired,
};

export default CampaignSampleMessageModal;
