import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  padding-top: 31px;
`;

export const LabelContainer = styled.div`
  position: absolute;
  width: ${({ width }) => width || '100%'};
  top: ${({ top }) => top || '0'};
  left: ${({ left }) => left || '0'};
`;

export const LabelBody = styled.div`
  border-bottom: 5px solid;
  border-color: ${({ theme, color }) => theme?.palette?.[color] || 'green'};
  border-radius: 5px;
  display: flex;
  justify-content: center;
`;

export const LabelContent = styled.div`
  display: inline-block;
  background: ${({ theme, color }) => theme?.palette?.[color] || 'green'};
  margin: 0 40px;
  padding: 3px 20px 0;
  position: relative;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;

  &&:after, &&:before {
    content: '';
    height: 10px;
    width: 25px;
    background: ${({ theme, color }) => theme?.palette?.[color] || 'green'};
    position: absolute;
    bottom: 0;
    right: -20px;
    z-index: 1;
  }

  &&:before {
    right: auto;
    left: -20px;
  }

  && span:after, && span:before {
    content: '';
    height: 24px;
    width: 42px;
    background: ${({ theme }) => theme?.palette?.background || 'white'};
    position: absolute;
    top: 0;
    right: -40px;
    z-index: 2;
    border-radius: 50%;
  }

  && span:before {
    right: auto;
    left: -40px;
  }
`;
