import { connect } from 'react-redux';

import selectors from 'store/selectors';

import {
  history,
} from 'store';

import {
  updateClient,
  deleteClient,
} from 'store/actions/realtorClients';

import Options from './Options';

const mapStateToProps = (state, ownProps) => {
  const client = selectors.realtorClients.byId(state, ownProps.clientId);

  return {
    status: client.status,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onStatusChange: (status) => {
    dispatch(updateClient(ownProps.clientId)({ status }));
  },
  onDelete: () => {
    dispatch(deleteClient(ownProps.clientId));
    history.push('/clients');
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Options);
