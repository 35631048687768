import React from 'react';
import PropTypes from 'prop-types';

import { NakedButton } from '@symplete/symplete-ui';
import ColorfulLabel from 'ui/ColorfulLabel';

const TemplateItemLabel = (props) => {
  const {
    onClick,
    children,
    variant,
  } = props;

  return (
    <NakedButton onClick={onClick}>
      <ColorfulLabel
        variant={variant}
        title={children}
      />
    </NakedButton>
  );
};

TemplateItemLabel.defaultProps = {

};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

TemplateItemLabel.propTypes = {

};

export default TemplateItemLabel;
