import React, {
  useContext,
} from 'react';

import Flex from 'ui/Flex';

import OpportunitiesProvider from 'sections/OpportunitiesListing/OpportunitiesProvider';
import OpportunitiesContext from 'sections/OpportunitiesListing/context/OpportunitiesContext';

import ZipInput from 'sections/OpportunitiesListing/modules/ZipInput';
import SymScoreRange from 'sections/OpportunitiesListing/modules/SymScoreRange';
import Filter from 'sections/OpportunitiesListing/modules/Filter';

import AVMRange from 'sections/OpportunitiesListing/modules/AVMRange';
import OwnershipDuration from 'sections/OpportunitiesListing/modules/OwnershipDuration';

import ViewReserved from 'sections/OpportunitiesListing/modules/ViewReserved';

import OpportunitiesTable from 'sections/OpportunitiesListing/modules/OpportunitiesTable';

const Opportunities = () => {
  const {
    propertiesCount,
    cartList,
    view,
  } = useContext(OpportunitiesContext);

  return (
    <Flex
      spacing={2}
      flexDirection="column"
      fullWidth
    >
      <Flex
        fullWidth
        justifyContent="space-between"
      >
        <Flex
          spacing={2}
          flexDirection="column"
        >
          {
            view === 'zip' && <ZipInput />
          }
          {
            propertiesCount > 0 && (
              <Flex
                spacing={2}
                flexDirection="column"
              >
                <Filter />
                <SymScoreRange />
                {
                  view === 'cart' && (
                    <Flex spacing={4}>
                      <AVMRange />
                      <OwnershipDuration />
                    </Flex>
                  )
                }
              </Flex>
            )
          }
        </Flex>
        {
          cartList?.length > 0 && (
            <ViewReserved />
          )
        }
      </Flex>
      <OpportunitiesTable />
    </Flex>
  );
};

export default () => (
  <OpportunitiesProvider>
    <Opportunities />
  </OpportunitiesProvider>
);
